import React, { useEffect, useReducer } from 'react';
import {Empty , Card, Col, Row, Typography, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import BlockOutlined from '@ant-design/icons';
import tlpalarmdata from './reducer';
import { TLP_ALARM, TLP_ALARM_ERROR, TLP_ALARM_SUCCESS } from './constants';
import moment from 'moment';


export default function TLPLogs(props) {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const navigate = useNavigate();
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const initalState = {tlp_alarm: [], details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(tlpalarmdata, initalState);
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	// eslint-disable-next-line react/prop-types
	const pipelineChainageId = props.pipelineConfigId;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	let current;
	let next;
	let previous;

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		
		getTlpData();
      

	},[authtoken]);

	const getTlpData = () => {

		dispatch({ type : TLP_ALARM});

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			// eslint-disable-next-line react/prop-types
			url: `${api}/nearby-tlp?pipelineChainageId=${pipelineChainageId}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
  
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : TLP_ALARM_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : TLP_ALARM_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : TLP_ALARM_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	data && data.tlp_alarm.map((item,index)=>{
		// eslint-disable-next-line react/prop-types
		// eslint-disable-next-line react/prop-types
		if(item.pipelineChainageId == pipelineChainageId){
			current=index;
			
		}
		if(item.pipelineChainageId > pipelineChainageId){
			next = index;

		}
		if(item.pipelineChainageId < pipelineChainageId)
			previous = index;

	});

	
	return(
		data && data.tlp_alarm.length > 0 ?
			<Row gutter={16}>
				{data && data.tlp_alarm.length > 1 && (previous ==0 || previous) ? 
					<Col span={8}>
						<Card title='Previous Chainage' bordered={true} >
							<Typography>Chainage (Kms): {parseFloat(data.tlp_alarm[previous ? previous : 0].pipelineChainage)/100	}</Typography>
							<Typography >Device id: {data.tlp_alarm[previous ? previous : 0].deviceId}</Typography>
							<Typography> Installaton date: {data.tlp_alarm[previous ? previous : 0].installedDate ?   moment.utc(data.tlp_alarm[previous ? previous : 0].installedDate).local().format(settingsdateformat) + ' ' + moment.utc(data.tlp_alarm[previous ? previous : 0].installedDate).local().format(settingstimeformat) : '' }</Typography>
							<Typography> Last Sync: {data.tlp_alarm[previous ? previous : 0].lastSync ?   moment.utc(data.tlp_alarm[previous ? previous : 0].lastSync).local().format(settingsdateformat) + ' ' + moment.utc(data.tlp_alarm[previous ? previous : 0].lastSync).local().format(settingstimeformat) : '' }</Typography>
							<Typography> AC PICKUP (V):{data.tlp_alarm[previous ? previous : 0].vac2}</Typography>
							<Typography> PSP (V): {data.tlp_alarm[previous ? previous : 0].vdc2 ? data.tlp_alarm[previous ? previous : 0].vdc2  : 0 }</Typography>
							<Typography> CASING AC PICKUP (V): {data.tlp_alarm[previous ? previous : 0].vac1 ? data.tlp_alarm[previous ? previous : 0].vac1 :0 }</Typography>
							<Typography>CSP (V):{data.tlp_alarm[previous ? previous : 0].vdc1 ? data.tlp_alarm[previous ? previous : 0].vdc1 : 0}</Typography>
							<Typography> Battery (V): {data.tlp_alarm[previous ? previous : 0].vbat ? data.tlp_alarm[previous ? previous : 0].vbat : 0}</Typography>
							<Typography> Current (mA): {data.tlp_alarm[previous ? previous : 0].previous ? data.tlp_alarm[previous ? previous : 0].previous : 0}</Typography>
							<Typography>Temp: {data.tlp_alarm[previous ? previous : 0].temp ? data.tlp_alarm[previous ? previous : 0].temp : 0}</Typography>
							<Typography>Solar panel available: {data.tlp_alarm[previous ? previous : 0].hasSolarPanel == true ? 'YES' : 'NO' }</Typography>
							<Typography>Anomaly detected: {data.tlp_alarm[previous ? previous : 0].anomaly == true ? 'YES' : 'NO'}</Typography>
							<Typography>Is vulnerable: {data.tlp_alarm[previous ? previous : 0].isVulnerable == true ? 'YES' : 'NO' }</Typography>
							<Typography>Is TRU: {data.tlp_alarm[previous ? previous : 0].isTRU == true ? 'YES' : 'NO'}</Typography>
							<Typography> TRU voltage (V): {data.tlp_alarm[previous ? previous : 0].truVoltage ? data.tlp_alarm[previous ? previous : 0].truVoltage : 0 }</Typography>
							<Typography> TRU correction voltage (V): {data.tlp_alarm[previous ? previous : 0].truCorrectionVoltage ? data.tlp_alarm[previous ? previous : 0].truCorrectionVoltage  : 0 }</Typography>
							<Typography> Latittude: {data.tlp_alarm[previous ? previous : 0].latitude ? data.tlp_alarm[previous ? previous : 0].latitude:0}</Typography>
							<Typography> Longitude: {data.tlp_alarm[previous ? previous : 0].longitude ? data.tlp_alarm[previous ? previous : 0].longitude : 0}</Typography>
						</Card> 
					</Col>
					: null}
				
				{data && data.tlp_alarm.length > 0 && (current ==0 || current)   ? 

					<Col span={8}>
						<Card title="Current Chainage" bordered={true} >
							<Typography>Chainage (Kms): {parseFloat(data.tlp_alarm[current ? current : 0].pipelineChainage)/100	}</Typography>
							<Typography >Device id: {data.tlp_alarm[current ? current : 0].deviceId}</Typography>
							<Typography> Installaton date: {data.tlp_alarm[current ? current : 0].installedDate ?   moment.utc(data.tlp_alarm[current ? current : 0].installedDate).local().format(settingsdateformat) + ' ' + moment.utc(data.tlp_alarm[current ? current : 0].installedDate).local().format(settingstimeformat) : '' }</Typography>
							<Typography> Last Sync: {data.tlp_alarm[current ? current : 0].lastSync ?   moment.utc(data.tlp_alarm[current ? current : 0].lastSync).local().format(settingsdateformat) + ' ' + moment.utc(data.tlp_alarm[current ? current : 0].lastSync).local().format(settingstimeformat) : '' }</Typography>
							<Typography> AC PICKUP (V):{data.tlp_alarm[current ? current : 0].vac2 ? data.tlp_alarm[current ? current : 0].vac2 : 0 }</Typography>
							<Typography> PSP (V): {data.tlp_alarm[current ? current : 0].vdc2 ? data.tlp_alarm[current ? current : 0].vdc2 : 0}</Typography>
							<Typography> CASING AC PICKUP (V): {data.tlp_alarm[current ? current : 0].vac1 ? data.tlp_alarm[current ? current : 0].vac1 :0}</Typography>
							<Typography>CSP (V):{data.tlp_alarm[current ? current : 0].vdc1 ? data.tlp_alarm[current ? current : 0].vdc1 :0}</Typography>
							<Typography> Battery (V): {data.tlp_alarm[current ? current : 0].vbat ? data.tlp_alarm[current ? current : 0].vbat : 0}</Typography>
							<Typography> Current (mA): {data.tlp_alarm[current ? current : 0].current ? data.tlp_alarm[current ? current : 0].current :0}</Typography>
							<Typography>Temp: {data.tlp_alarm[current ? current : 0].temp ? data.tlp_alarm[current ? current : 0].temp :0}</Typography>
							<Typography>Solar panel available: {data.tlp_alarm[current ? current : 0].hasSolarPanel == true ? 'YES' : 'NO' }</Typography>
							<Typography>Anomaly detected: {data.tlp_alarm[current ? current : 0].anomaly == true ? 'YES' : 'NO'}</Typography>
							<Typography>Is vulnerable: {data.tlp_alarm[current ? current : 0].isVulnerable == true ? 'YES' : 'NO' }</Typography>
							<Typography>Is TRU: {data.tlp_alarm[current ? current : 0].isTRU == true ? 'YES' : 'NO'}</Typography>
							<Typography> TRU voltage (V): {data.tlp_alarm[current ? current : 0].truVoltage ? data.tlp_alarm[current ? current : 0].truVoltage : 0 }</Typography>
							<Typography> TRU correction voltage (V): {data.tlp_alarm[current ? current : 0].truCorrectionVoltage ? data.tlp_alarm[current ? current : 0].truCorrectionVoltage  : 0 }</Typography>
							<Typography> Latittude: {data.tlp_alarm[current ? current : 0].latitude ? data.tlp_alarm[current ? current : 0].latitude :0}</Typography>
							<Typography> Longitude: {data.tlp_alarm[current ? current : 0].longitude ? data.tlp_alarm[current ? current : 0].longitude :0}</Typography>
						</Card>
					</Col>
					: null}

				{data && data.tlp_alarm.length > 1 && (next ==0 || next) ? 
					<Col span={8}>
						<Card title="Next Chainage" bordered={true} >
							<Typography>Chainage (Kms): {parseFloat(data.tlp_alarm[next ? next : 0].pipelineChainage)/100	}</Typography>
							<Typography >Device id: {data.tlp_alarm[next ? next : 0].deviceId}</Typography>
							<Typography> Installaton date: {data.tlp_alarm[next ? next : 0].installedDate ?   moment.utc(data.tlp_alarm[next ? next : 0].installedDate).local().format(settingsdateformat) + ' ' + moment.utc(data.tlp_alarm[next ? next : 0].installedDate).local().format(settingstimeformat) : '' }</Typography>
							<Typography> Last Sync: {data.tlp_alarm[next ? next : 0].lastSync ?   moment.utc(data.tlp_alarm[next ? next : 0].lastSync).local().format(settingsdateformat) + ' ' + moment.utc(data.tlp_alarm[next ? next : 0].lastSync).local().format(settingstimeformat) : '' }</Typography>
							<Typography> AC PICKUP (V):{data.tlp_alarm[next ? next : 0].vac2 ? data.tlp_alarm[next ? next : 0].vac2 :0}</Typography>
							<Typography> PSP (V): {data.tlp_alarm[next ? next : 0].vdc2 ? data.tlp_alarm[next ? next : 0].vdc2 :0}</Typography>
							<Typography> CASING AC PICKUP (V): {data.tlp_alarm[next ? next : 0].vac1 ? data.tlp_alarm[next ? next : 0].vac1 :0}</Typography>
							<Typography>CSP (V):{data.tlp_alarm[next ? next : 0].vdc1 ? data.tlp_alarm[next ? next : 0].vdc1 :0}</Typography>
							<Typography> Battery (V): {data.tlp_alarm[next ? next : 0].vbat ? data.tlp_alarm[next ? next : 0].vbat :0}</Typography>
							<Typography> Current (mA): {data.tlp_alarm[next ? next : 0].next ? data.tlp_alarm[next ? next : 0].next :0}</Typography>
							<Typography>Temp: {data.tlp_alarm[next ? next : 0].temp ? data.tlp_alarm[next ? next : 0].temp :0}</Typography>
							<Typography>Solar panel available: {data.tlp_alarm[next ? next : 0].hasSolarPanel == true ? 'YES' : 'NO' }</Typography>
							<Typography>Anomaly detected: {data.tlp_alarm[next ? next : 0].anomaly == true ? 'YES' : 'NO'}</Typography>
							<Typography>Is vulnerable: {data.tlp_alarm[next ? next : 0].isVulnerable == true ? 'YES' : 'NO' }</Typography>
							<Typography>Is TRU: {data.tlp_alarm[next ? next : 0].isTRU == true ? 'YES' : 'NO'}</Typography>
							<Typography> TRU voltage (V): {data.tlp_alarm[next ? next : 0].truVoltage ? data.tlp_alarm[next ? next : 0].truVoltage : 0 }</Typography>
							<Typography> TRU correction voltage (V): {data.tlp_alarm[next ? next : 0].truCorrectionVoltage ? data.tlp_alarm[next ? next : 0].truCorrectionVoltage  : 0 }</Typography>
							<Typography> Latittude: {data.tlp_alarm[next ? next : 0].latitude ? data.tlp_alarm[next ? next : 0].latitude:0}</Typography>
							<Typography> Longitude: {data.tlp_alarm[next ? next : 0].longitude ? data.tlp_alarm[next ? next : 0].longitude:0}</Typography>
						</Card>
					</Col>
					: null}

			</Row>:
			<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
	);
}
