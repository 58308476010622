import { LINEWALKER_DETAILS, LINEWALKER_LIST, LINEWALKER_LIST_ERROR, LINEWALKER_LIST_SUCCESS } from './constants';

export default function tracklinewalker(state, action) {

	switch (action.type) {
      
	case LINEWALKER_LIST:
		return { ...state, pending: true };
	case LINEWALKER_LIST_SUCCESS:
		return { ...state, linewalker_data: action.payload, pending: false };
	case LINEWALKER_LIST_ERROR:
		return { ...state, linewalker_data:[], error: true, pending: false };
	case LINEWALKER_DETAILS:
		return { ...state, details:action.payload[0], error: true, pending: false };

      
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}