import { Breadcrumb, Button, Col, Empty, Form, Input, Modal, Row, Select, Space, Table, Tooltip, Typography, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useReducer, useState } from 'react';
import { SplitCellsOutlined, HomeOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import channelInfoReducer from './reducer';
import { CHANNELWIDTH_DETAILS } from './constants';
import { useAuth } from '../../Context';
import axios from 'axios';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

const { Title } = Typography;

export default function ChannelWidth () {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const { state } = useLocation();
	const navigate = useNavigate();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalAddOpen, setIsModalAddOpen] = useState(false);
	const [widthdetails, setWidthDetails] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteActivity, setDeleteActivity] = useState();

	const initalState = {channelWidth : [], pending: false, error: false};
	const [data, dispatch] = useReducer(channelInfoReducer, initalState);
	const {getAllEvents, eventsData} = useAuth();

	const id = parseInt(state.id);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const eventData = [];
	eventsData && eventsData.map((item) => {
		eventData.push({
			value : item.eventType,
			label : item.eventType
		});
	});

	useEffect(() => {
		getAllEvents(authtoken);
	},[authtoken]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const width = [];

	for (const [key, value] of Object.entries(widthdetails ? widthdetails : state.data)) {
		width.push({
			type : key,
			width : value
		});
	}

	const columns = [
		{
			title: langdata && langdata.Activity ? langdata.Activity : 'Activity',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: langdata && langdata.Width ? langdata.Width : 'Width',
			dataIndex: 'width',
			key: 'width',
		},
		{
			title:  langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (record) => (
				<Space size="middle">
					<a title={langdata && langdata.Editchannelwidth ? langdata.Editchannelwidth :'Edit channel width'} onClick={showModal}><EditOutlined /></a>
					<a title={langdata && langdata.Deletechannelwidth ? langdata.Deletechannelwidth :'Delete channel width'} onClick={() => {openDeleteModal(record);}}><DeleteOutlined /></a>
				</Space>
			)
		}
	];

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const showAddModal = () => {
		setIsModalAddOpen(true);
	};

	const handleAddOk = () => {
		setIsModalAddOpen(false);
	};

	const handleAddCancel = () => {
		setIsModalAddOpen(false);
	};

	const openDeleteModal = (value) => {
		setDeleteActivity(value.type);
		setShowDeleteModal(true);
	};

	const handleDeleteOk = () => {
		setShowDeleteModal(false);
	};

	const handleDelete = () => {
		let widthData = {};

		for (const [key, value] of Object.entries(widthdetails ? widthdetails : state.data)){
			if(key != deleteActivity) {
				widthData[key] = value;
			}
		}

		let data = JSON.stringify({
			'id': id,
			'width': widthData
		});

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/channel-profile-width`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 ){
					Modal.success({ title: langdata && langdata.Channelprofilewidthsuccessfullydeleted ? langdata.Channelprofilewidthsuccessfullydeleted :'Channel profile width successfully deleted' });
					setWidthDetails(response.data.width);
				} else {
					Modal.warning({ title: langdata && langdata.Channelprofilewidthnotdeleted ? langdata.Channelprofilewidthnotdeleted : 'Channel profile width not deleted' });
				}
				console.log(JSON.stringify(response.data));
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
		setShowDeleteModal(false);
	};


	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const loadChannelWidthDetails = ((details) => {
		dispatch({ type : CHANNELWIDTH_DETAILS, payload : details });
	
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			activity: data.channelWidth && data.channelWidth.type ? data.channelWidth.type : '-' ,
			width: data.channelWidth && data.channelWidth.width,
		},
		onSubmit: (values) => {
			const {activity, width} = values;

			let widthData = {};

			for (const [key, value] of Object.entries(widthdetails ? widthdetails : state.data)){
				if(key == activity) {
					widthData[key] = width;
				}else {
					widthData[key] = value;

				}
			}

			let data = JSON.stringify({
				'id': id,
				'width': widthData
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/channel-profile-width`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 ){
						Modal.success({ title: langdata && langdata.Channelprofilewidthupdatedsuccessfully ? langdata.Channelprofilewidthupdatedsuccessfully :'Channel profile width updated successfully' });
						setWidthDetails(response.data.width);
					} else {
						Modal.warning({ title: langdata && langdata.Channelprofilewidthnotupdated ? langdata.Channelprofilewidthnotupdated : 'Channel profile width not updated' });
					}
					console.log(JSON.stringify(response.data));
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
			setIsModalOpen(false);
		}
	});

	const addFormik = useFormik({
		// enableReinitialize: true,
		initialValues: {
			activity: null,
			width: null,
		},
		onSubmit: (values) => {
			const {activity, width} = values;
			if(activity == null || width == null){
				Modal.warning({title : langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields : 'Please fill all the mandatory fields'});
				return true;
			} else {
				let widthData = {};
				for (const [key, value] of Object.entries(widthdetails ? widthdetails : state.data)){
					if(key == activity) {
						Modal.warning({title : langdata && langdata.Alreadyexist ? langdata.Alreadyexist :  'Already exist'});
						return true;
					}else {
						widthData[key] = value;	
					}
				}
				widthData[activity] = width;

				let data = JSON.stringify({
					'id': id,
					'width': widthData
				});

				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: `${api}/channel-profile-width`,
					timeout: timeout,
					headers: { 
						'Content-Type': 'application/json', 
						'Authorization': `Bearer ${authtoken}`
					},
					data : data
				};

				axios.request(config)
					.then((response) => {
						if(response.status == 200 ){
							Modal.success({ title: langdata && langdata.Channelprofilewidthsuccessfullyadded ? langdata.Channelprofilewidthsuccessfullyadded : 'Channel profile width successfully added' });
							setWidthDetails(response.data.width);
							addFormik.values.activity = null;
							addFormik.values.width = null;
						} else {
							Modal.warning({ title: langdata && langdata.Channelprofilewidthnotadded ? langdata.Channelprofilewidthnotadded :'Channel profile width not added' });
						}
						console.log(JSON.stringify(response.data));
					})
					.catch((error) => {
						console.log(error);
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
							});
						}
					});
			}
			setIsModalAddOpen(false);
		}
	});

	return (
		<Content>
			<Row 
				style={{
					marginLeft:'10px',
					marginTop: '10px'
				}}            
			>
				<Breadcrumb
					items={[
						{
							href: '/dashboard',
							title: <HomeOutlined />,
						},
						{
							title:  <a onClick={()=>{navigate(-1);}}>{ langdata && langdata.ChannelInfo ? langdata.ChannelInfo : 'Channel Info'} / {state.channel}</a> 
						},
						{
							title: langdata && langdata.ChannelWidth ? langdata.ChannelWidth : 'Channel Width',
						},
					]}
				/>
			</Row>
			<Row style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center',
			}}
			>

				<Col span={8}>
					<Row>
						<Space>
							<SplitCellsOutlined style={{fontSize:'30px'}}/>
							{/* </Col> */}
						</Space>
						<Space>&nbsp;&nbsp;</Space>
					
						{/* <Col style={{marginLeft:'4px'}} span={19}> */}
						<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.ChannelWidth ? langdata.ChannelWidth : 'Channel Width'}</Title>
					</Row>
				</Col>
				<Col style={{ display : 'flex', justifyContent : 'right'}} span={16} >
					<Row>
						<Col>								
							<Tooltip placement="bottom" title={langdata && langdata.Newchannelwidth ? langdata.Newchannelwidth : 'New channel width'}>
								<Space>

									<Button
										style={{ margin:'5px'}}
										type='primary'
										onClick={showAddModal}
									><PlusOutlined/>{langdata && langdata.New ? langdata.New : 'New'}</Button>
								</Space>
							</Tooltip>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row span={24} style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center'
			}} >
				<Col span={24}>
					{ width.length > 0 ? (
						<Table
							scroll={{
								x: 900,
							}} 
							columns={columns} 
							dataSource={width
							} 
							onRow = {(record) => {
								return {
									onClick: () => { 
										loadChannelWidthDetails(record);
									}
								};
							}}
						/>
					)
						:
						(
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						)}
				</Col>
			</Row>
			<Modal title={langdata && langdata.EditChannelWidth ? langdata.EditChannelWidth : 'Edit Channel Width'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} destroyOnClose= {true} maskClosable={false}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={formik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}>
				<Form layout="vertical" 
					onSubmit={formik.handleSubmit} 
					// form={form} 
				// onValuesChange={handleFormValuesChange}
				>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Activity ? langdata.Activity : 'Activity'} name="activity" rules={[{ required: true }]}>
								<Select
									// name="activity" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Activity ? langdata.Activity : 'Activity'}
									optionFilterProp="children"
									options={eventData}
									defaultValue={data.channelWidth && data.channelWidth.type}
									onChange={(value) => formik.setFieldValue('activity', value)}
								/>												
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Width ? langdata.Width :'Width'} name="width" rules={[{ required: true }]}>
								<Input type='number' defaultValue={data.channelWidth && data.channelWidth.width} min={0} onChange={formik.handleChange}/>											
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			<Modal title={langdata && langdata.AddChannelWidth ? langdata.AddChannelWidth : 'Add Channel Width'} open={isModalAddOpen} onOk={handleAddOk} onCancel={handleAddCancel} destroyOnClose= {true} maskClosable={false}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleAddCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={addFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}>
				<Form layout="vertical" 
					onSubmit={addFormik.handleSubmit} 
					// form={form} 
				// onValuesChange={handleFormValuesChange}
				>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Activity ? langdata.Activity : 'Activity'} name="activity" rules={[{ required: true }]}>
								<Select
									// name="activity" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Activity ? langdata.Activity : 'Activity'}
									optionFilterProp="children"
									options={eventData}
									// defaultValue={data.channelWidth && data.channelWidth.type}
									onChange={(value) => addFormik.setFieldValue('activity', value)}
								/>												
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Width ? langdata.Width : 'Width'} name="width" rules={[{ required: true }]}>
								<Input type='number' placeholder={langdata && langdata.Width ? langdata.Width : 'Width'} min={0} onChange={addFormik.handleChange}/>											
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={showDeleteModal} onOk={handleDelete} onCancel={handleDeleteOk}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleDeleteOk}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={handleDelete}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>
						{langdata && langdata.Areyousureyouwanttodeletethischannelwidth ? langdata.Areyousureyouwanttodeletethischannelwidth : 'Are you sure you want to delete this channel width?'}
					</Col>
				</Row>
			</Modal>
		</Content>
	);
}