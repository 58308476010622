import { Button, Col, Row, Space, Tabs, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { ToolOutlined, PlusOutlined } from '@ant-design/icons';
import TabPane from 'antd/es/tabs/TabPane';
import { useAuth } from '../../Context';
import SandIngress from './sand-Ingress';
import FlowMonitoring from './flow-monitoring';
import { useEffect } from 'react';
import LeakDetection from './leak-detection';
import FractureMonitoring from './fracture-monitoring';
import MicroSeismic from './micro-seismic';
// import wellGraphsReducer from './reducer';
// import { OPEN_MODAL } from './constants';

const { Title } = Typography;

export default function WellGraphs () {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	// const initalState = { openModal : false };
	// const [data, dispatch] = useReducer(wellGraphsReducer, initalState);

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	useAuth();

	const [openModal, setOpenModal] = useState(false);
	const [tabValue, setTabValue] = useState(1);

	const handleOpenAddModal = () => {
		// dispatch({ type : OPEN_MODAL, payload : true });
		setOpenModal(true);
	};

	const onChangeTab = (value) => {
		setTabValue(value);
		setOpenModal(false);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	},[]);

	return (
		<Content>
			<Row style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center',
			}}
			>
				<Col span={8}>
					<Row>
						<Space>
							<ToolOutlined style={{fontSize:'30px'}}/>
						</Space>
						<Space>&nbsp;&nbsp;</Space>
						<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.WellGraphs ? langdata.WellGraphs : 'Well Graphs'}</Title>
					</Row>
				</Col>
				<Col span={16}>
					<Row justify="end">
						<Col>
							{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>User Type:</Text> */}
							{ permission==='superadmin' || permission === 'admin' ? 
								<Space>
									<Space>&nbsp;</Space>
									<Button style={{margin:'5px'}} type="primary" onClick={handleOpenAddModal} ><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button>
								</Space>
								: null }
						</Col>
					</Row>
				</Col>
			</Row>
			<Tabs 
				tabValue={tabValue}
				onChange={onChangeTab}
				style={{
					margin: '10px',
					paddingLeft: '10px',	
					paddingRight: '10px',
					background: '#FFFFFF',
				}}>
				<TabPane tab={langdata && langdata.SandIngress ? langdata.SandIngress : 'Sand Ingress'} key={1}>
					{tabValue == 1 ? <SandIngress modal = {openModal} setOpenModal = {setOpenModal}/> : null }
				</TabPane>
				<TabPane tab={langdata && langdata.FlowMonitoring ? langdata.FlowMonitoring : 'Flow Monitoring'} key={2}>
					{tabValue == 2 ? <FlowMonitoring modal = {openModal} setOpenModal = {setOpenModal}/> : null }
				</TabPane>
				<TabPane tab={langdata && langdata.LeakDetection ? langdata.LeakDetection : 'Leak Detection'} key={3}>
					{tabValue == 3 ? <LeakDetection modal = {openModal} setOpenModal = {setOpenModal}/> : null }
				</TabPane>
				<TabPane tab={langdata && langdata.FractureMonitoring ? langdata.FractureMonitoring : 'Fracture Monitoring'} key={4}>
					{tabValue == 4 ? <FractureMonitoring modal = {openModal} setOpenModal = {setOpenModal}/> : null }
				</TabPane>
				<TabPane tab={langdata && langdata.MicroseismicMonitoring ? langdata.MicroseismicMonitoring : 'Microseismic Monitoring'} key={5}>
					{tabValue == 5 ? <MicroSeismic modal = {openModal} setOpenModal = {setOpenModal}/> : null }
				</TabPane>
			</Tabs>
			{/* </Row> */}
		</Content>
	);
}