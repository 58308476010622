/* eslint-disable no-restricted-globals */
import multiplewhite from '../images/markerimages/multiwhite.png';
import multiplegreen from '../images/markerimages/multigreen.png';
import multipleamber from '../images/markerimages/multiiamber.png';
import multiplered from '../images/markerimages/multired.png';

import machinewhite from '../images/markerimages/machinewhite.png';
import machinegreen from '../images/markerimages/machinegreen.png';
import machineamber from '../images/markerimages/machineamber.png';
import machinered from '../images/markerimages/machinered.png';

import manuelwhite from '../images/markerimages/manuelwhite.png';
import manuelgreen from '../images/markerimages/manuelgreen.png';
import manuelamber from '../images/markerimages/manuelamber.png';
import manuelred from '../images/markerimages/manuelred.png';

import vechilewhite from '../images/markerimages/vechilewhite.png';
import vechilegreen from '../images/markerimages/vechilegreen.png';
import vechileamber from '../images/markerimages/vechileamber.png';
import vechilered from '../images/markerimages/vechilered.png';

import drumswhite from '../images/markerimages/drumswhite.png';
import drumsgreen from '../images/markerimages/drumsgreen.png';
import drumsamber from '../images/markerimages/drumsamber.png';
import drumsred from '../images/markerimages/drumsred.png';

import manueldeepwhite from '../images/markerimages/manueldeepwhite.png';
import manueldeepgreen from '../images/markerimages/manueldeepgreen.png';
import manueldeepamber from '../images/markerimages/manueldeepamber.png';
import manueldeepred from '../images/markerimages/manueldeepred.png';

import manwhite from '../images/markerimages/manwhite.png';
import mangreen from '../images/markerimages/mangreen.png';
import manamber from '../images/markerimages/manamber.png';
import manred from '../images/markerimages/manred.png';

import tunellingwhite from '../images/markerimages/tunellingwhite.png';
import tunellingreen from '../images/markerimages/tunnelinggreen.png';
import tunnelingamber from '../images/markerimages/tunnelingamber.png';
import tunnelingred from '../images/markerimages/tunnelingred.png';

import agreewhite from '../images/markerimages/agreewhite.png';
import agreegreen from '../images/markerimages/agreegreen.png';
import agreeamber from '../images/markerimages/aggreamber.png';
import agreered from '../images/markerimages/agreered.png';

import defaultwhite from '../images/markerimages/suspeciouswhite.png';
import defaultred from '../images/markerimages/suspeciousred.png';
import defaultgreen from '../images/markerimages/suspeciousgreen.png';
import defaultamber from '../images/markerimages/suspeciousamber.png';

import suspeciouswhite from '../images/markerimages/suspeciouswhite.png';
import suspeciousred from '../images/markerimages/suspeciousred.png';
import suspeciousgreen from '../images/markerimages/suspeciousgreen.png';
import suspeciousamber from '../images/markerimages/suspeciousamber.png';

import roadwhite from '../images/markerimages/roadwhite.png';
import roadred from '../images/markerimages/roadred.png';
import roadgreen from '../images/markerimages/roadgreen.png';
import roadamber from '../images/markerimages/roadamber.png';

import clampingwhite from '../images/markerimages/clampingwhite.png';
import clampingred from '../images/markerimages/clampingred.png';
import clampinggreen from '../images/markerimages/clampinggreen.png';
import clampingamber from '../images/markerimages/clampingamber.png';

import sleevingwhite from '../images/markerimages/sleevingwhite.png';
import sleevingred from '../images/markerimages/sleevingred.png';
import sleevinggreen from '../images/markerimages/sleevinggreen.png';
import sleevingamber from '../images/markerimages/sleevingamber.png';

import trainwhite from '../images/markerimages/trainwhite.png';
import trainred from '../images/markerimages/trainred.png';
import traingreen from '../images/markerimages/traingreen.png';
import trainamber from '../images/markerimages/trainamber.png';


// eslint-disable-next-line no-global-assign
export default event = [
	{
		type: 'Multiple Activities',
		severity: [multiplewhite, multiplegreen, multipleamber, multiplered],
	},
	{
		type: 'Machine Digging',
		severity: [machinewhite, machinegreen, machineamber, machinered],
	},
	{
		type: 'Manual Digging',
		severity: [manuelwhite, manuelgreen, manuelamber, manuelred],
	},
	{
		type: 'Vehicle Movement',
		severity: [vechilewhite, vechilegreen, vechileamber, vechilered],
	},
	{
		type: 'Drums Beating',
		severity: [drumswhite, drumsgreen, drumsamber, drumsred],
	},
	{
		type: 'Manual Digging - Deeper (1.5 to 3 feets)',
		severity: [manueldeepwhite, manueldeepgreen, manueldeepamber, manueldeepred],
	},
	{
		type: 'Man Movement',
		severity: [manwhite, mangreen, manamber, manred],
	},
	{
		type: 'Tunneling',
		severity: [tunellingwhite, tunellingreen, tunnelingamber, tunnelingred],
	},
	{
		type: 'Aggregate Activity',
		severity: [agreewhite, agreegreen, agreeamber, agreered],
	},
	{
		type: 'Unclassified',
		severity: [defaultwhite, defaultgreen, defaultamber, defaultred],
	},
	{
		type: 'Suspicious Activity',
		severity: [suspeciouswhite, suspeciousgreen, suspeciousamber, suspeciousred],
	},
	{
		type: 'Road Crossing',
		severity: [roadwhite, roadgreen, roadamber, roadred],
	},
	{
		type: 'Nala Flowing',
		severity: [defaultwhite, defaultgreen, defaultamber, defaultred],
	},
	{
		type: 'Clamping',
		severity: [clampingwhite, clampinggreen, clampingamber, clampingred],
	},
	{
		type: 'Sleeving',
		severity: [sleevingwhite, sleevinggreen, sleevingamber, sleevingred],
	},
	{
		type: 'Train Movement',
		severity: [trainwhite, traingreen, trainamber, trainred],
	},
	{
		type: 'Manual Augering',
		severity: [defaultwhite, defaultgreen, defaultamber, defaultred],
	},
	{
		type: 'Machine Augering',
		severity: [defaultwhite, defaultgreen, defaultamber, defaultred],
	},
	{
		type: 'Ploughing Activity',
		severity: [defaultwhite, defaultgreen, defaultamber, defaultred],
	},
	{
		type: 'Ploughing',
		severity: [defaultwhite, defaultgreen, defaultamber, defaultred],
	},
	{
		type: 'Water Pump',
		severity: [defaultwhite, defaultgreen, defaultamber, defaultred],
	},
];

