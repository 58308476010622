import { WELL_ZONE_INFO, WELL_ZONE_INFO_CREATE, WELL_ZONE_INFO_CREATE_ERROR, WELL_ZONE_INFO_CREATE_SUCCESS, WELL_ZONE_INFO_DELETE, WELL_ZONE_INFO_DELETE_ERROR, WELL_ZONE_INFO_DELETE_SUCCESS, WELL_ZONE_INFO_ERROR, WELL_ZONE_INFO_SUCCESS, WELL_ZONE_INFO_UPDATE, WELL_ZONE_INFO_UPDATE_DATA, WELL_ZONE_INFO_UPDATE_ERROR, WELL_ZONE_INFO_UPDATE_SUCCESS } from './constants';

export default function wellZoneInfoReducer(state, action) {
	let wellZoneInfo = [];
	switch (action.type) {
	case WELL_ZONE_INFO :
		return { ...state, loading : true, error : false};
	case WELL_ZONE_INFO_SUCCESS :
		action.payload.data && action.payload.data.map((item) => {			
			wellZoneInfo.push({
				id : item.id,
				name : item.zoneName,
				zoneFrom : item.zoneFrom,
				zoneTo : item.zoneTo,
				wellinfo : item.wellinfo.name,	
				wellinfoId : item.wellinfoId			
			});
		});
		return { ...state, loading : false, error : false, wellZoneInfo : wellZoneInfo};
	case WELL_ZONE_INFO_ERROR :
		return { ...state, loading : false, error : true };
	case WELL_ZONE_INFO_UPDATE_DATA :
		return { ...state, zoneUpdateData : action.payload };
	case WELL_ZONE_INFO_UPDATE :
		return { ...state, loading : true, error : false};
	case WELL_ZONE_INFO_UPDATE_SUCCESS :
		return { ...state, loading : false, error : false};
	case WELL_ZONE_INFO_UPDATE_ERROR : 
		return { ...state, loading : false, error : true};
	case WELL_ZONE_INFO_CREATE:
		return { ...state, loading : true, error : false };
	case WELL_ZONE_INFO_CREATE_SUCCESS :
		return { ...state, loading : false, error : false};
	case WELL_ZONE_INFO_CREATE_ERROR :
		return { ...state, loading : true, error : true };
	case WELL_ZONE_INFO_DELETE :
		return { ...state, loading : true, error : false};
	case WELL_ZONE_INFO_DELETE_SUCCESS :
		return { ...state, loading : false, error : false };
	case WELL_ZONE_INFO_DELETE_ERROR :
		return { ...state, loading : false, error : true };
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}