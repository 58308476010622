import { AUTHENTICATION_LIST, AUTHENTICATION_LIST_ERROR, AUTHENTICATION_LIST_SUCCESS, COMPANY_LIST, COMPANY_LIST_ERROR, COMPANY_LIST_SUCCESS, COMPANY_MAP, COMPANY_MAP_ERROR, COMPANY_MAP_SUCCESS, SETTINGS_LIST, SETTINGS_LIST_ERROR, SETTINGS_LIST_SUCCESS } from './constants';

export default function logindata(state, action) {

	switch (action.type) {
      
	case AUTHENTICATION_LIST:
		return { ...state, pending: true };
	case AUTHENTICATION_LIST_SUCCESS:
		return { ...state, logindata: action.payload, pending: false };
	case AUTHENTICATION_LIST_ERROR:
		return { ...state, error: true, pending:false };
      
	case COMPANY_LIST:
		return { ...state, pending: true };
	case COMPANY_LIST_SUCCESS:        
		return { ...state, companydata: action.payload , pending: false };
	case COMPANY_LIST_ERROR:
		return { ...state, error: true, pending:false };

	case COMPANY_MAP:
		return { ...state, pending: true };
	case COMPANY_MAP_SUCCESS:        
		return { ...state, company_mapdata: action.payload , pending: false };
	case COMPANY_MAP_ERROR:
		return { ...state, error: true, pending:false };

	case SETTINGS_LIST:
		return { ...state, pending: true };
	case SETTINGS_LIST_SUCCESS:        
		return { ...state, settings: action.payload , pending: false };
	case SETTINGS_LIST_ERROR:
		return { ...state, error: true, pending:false };
      
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}