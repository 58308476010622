import { React, useEffect, useReducer, useState } from 'react';

import { Row, Col, Typography, Space, Table, Tag, Tooltip, Button, Modal, Form, Select, Input, Spin, Divider, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PlusOutlined, DeleteOutlined, InfoOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { MODEL_DATA, MODEL_DATA_ERROR, MODEL_DATA_SUCCESS, MODEL_DETAILS, MODEL_DETAILS_ERROR, MODEL_DETAILS_SUCCESS, MODEL_INFO_DETAILS } from './constants';
import axios from 'axios';
import modelReducer from './reducer';
import { datetimeCovertertolocal } from '../datetime-converter';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context';
import { useFormik } from 'formik';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';


const { Title } = Typography;

let model_data_pagination = {};


export default function RetrainingData() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = { modeldata: [], details: null, loading: false, error: false, modelDetails : null, modelInfoDetails : null};
	const [data, dispatch] = useReducer(modelReducer, initalState);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const navigate = useNavigate();

	const [showAddModel, setShowAddModel] = useState(false);
	const [showEditModel, setShowEditModel] = useState(false);
	// const [pidsId, setPidsId] = useState('all');
	const [showDeleteModel, setShowDeleteModel] = useState(false);
	const [pidsId, setPidsId] = useState('all');

	const { getAllEvents, eventsData, getPids, pidsData } = useAuth();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const pidsList = [];
	const pidsListFilter = [];
	const eventsList = [];

	let model_data= [];

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	pidsListFilter.push({
		value : 'all',
		label : `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	},
	{
		value : 0,
		label : 'CENTRAL'
	});

	pidsData && pidsData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name.toUpperCase(),
		});
		pidsListFilter.push({
			value : item.id,
			label : item.name.toUpperCase(),
		});
	});

	pidsList.sort((a,b) => { return a.label - b.label; });
	pidsListFilter.sort((a,b) => { return a.label - b.label; });
	
	eventsData && eventsData.map((item) => {
		eventsList.push({
			value : item.eventType,
			label: item.eventType
		});
	});


	data && data.modeldata.map((item)=>{

		const datetime = datetimeCovertertolocal(item.deployedDate ?  item.deployedDate: '');
		const newdate = datetime.localdate + ' ' +  datetime.localtime;

		model_data.push({
			id:item.id,
			deployedOn:item.deployedDate ? newdate : '-',
			benchmarkAccuracy: item.benchmarkAccuracy ? item.benchmarkAccuracy.toFixed(4) : 0,
			benchmark_f1score:item.benchmark_f1score ? item.benchmark_f1score.toFixed(4) : 0,
			fromdate : item.fromdate ? item.fromdate : '',
			todate : item.todate ? item.todate : '',
			modelVersion: item.modelinfo.version ?  item.modelinfo.version : 'Version 1.0.0',
			validationPassed :item.modelinfo.validationPassed == true ? 'YES' : 'NO' ,
			datasetType : item.modelinfo.datasetType,
			modelDimension : item.modelinfo.modelDimension,
			totalSampleCount : item.modelinfo.totalSampleCount,
			trainAccuracy : item.modelinfo.trainAccuracy,
			trainF1Score : item.modelinfo.trainF1Score,
			testF1Score : parseFloat(item.modelinfo.testF1Score).toFixed(4),
			modelName : item.modelinfo.modelNameAlias,
			pidsinfo : item.pidsinfo ? item.pidsinfo.name.toUpperCase() : 'CENTRAL',
			testAccuracy : parseFloat(item.modelinfo.testAccuracy).toFixed(4),
			numOfClasses : item.modelinfo.numOfClasses,
			eventSampleCount : item.modelinfo.eventSampleCount,
		});
	});
  
	const columns = [
		{
			title: `${langdata && langdata.PIDSName ? langdata.PIDSName:'PIDS Name'}`,
			dataIndex: 'pidsinfo',
			key: 'pidsinfo',
		},
		{
			title: `${langdata && langdata.ModelName ? langdata.ModelName:'Model Name'}`,
			dataIndex: 'modelName',
			key: 'modelName',
			render: (_, record) => (
				<Space size="middle">
					<a title={langdata && langdata.ModelInfo ? langdata.ModelInfo:'Model Info'} onClick={()=>{key(record);}}>{record.modelName}</a>
				</Space>
			)
		},
		{
			title: `${langdata && langdata.MLAlgorithm ? langdata.MLAlgorithm:'ML Algorithm'}`,
			dataIndex: 'modelDimension',
			key: 'modelDimension',
		},
		{
			title: `${langdata && langdata.ModelVersion ? langdata.ModelVersion:'Model Version'}`,
			dataIndex: 'modelVersion',
			key: 'modelVersion',
		},
		{
			title: `${langdata && langdata.DeployedDate ? langdata.DeployedDate:'Deployed Date'}`,
			dataIndex: 'deployedOn',
			key: 'deployedOn',
		},
		{
			title: `${langdata && langdata.TotalSampleCount ? langdata.TotalSampleCount:'Total Sample Count'}`,
			dataIndex: 'totalSampleCount',
			key: 'totalSampleCount',
		},
		{
			title: `${langdata && langdata.TestAccuracy ? langdata.TestAccuracy:'Test Accuracy'}`,
			dataIndex: 'testAccuracy',
			key: 'testAccuracy',
		},
		{
			title: `${langdata && langdata.TestF1Score ? langdata.TestF1Score:'Test F1 Score'}`,
			dataIndex: 'testF1Score',
			key: 'testF1Score',
		},
		{
			title: `${langdata && langdata.ValidationPassed ? langdata.ValidationPassed:'Validation Passed'}`,
			dataIndex: 'validationPassed',
			key: 'validationPassed',
			render: (validationPassed) => (
				<>
					<Tag color={validationPassed == 'YES' ? 'green' : 'red'}>
						{validationPassed}
					</Tag>
				</>
			),
		},
	];
	
	const key = (value) => {
		setIsModalOpen(true);
		dispatch({ type : MODEL_INFO_DETAILS, payload : value});
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};


	useEffect(() => {
		if(featuresModule.retrainingModelInfoEnabled == false) {
			navigate('/dashboard');
		}
		if(!authtoken){
			navigate('/');
		}
		getAllEvents(authtoken);
		getPids(authtoken);
	}, [authtoken]);
	
	useEffect(() => {
		modelData(1);
	},[authtoken, showAddModel, showEditModel, pidsId]);

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 1134);

	}, []);

	const modelData = (pagination) => {

		let _pagination = { };
		_pagination.total = model_data_pagination.total ? model_data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);
		


		dispatch({ type : MODEL_DATA});

		let params = '';
		if(pidsId != 'all') {
			params = `&pidsinfoId=${pidsId}`;
		} 

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/modeldeploymentinfo?$limit=${_pagination.limit}&$skip=${_pagination.skip}${params}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					model_data_pagination.total = response.data.total ? response.data.total : 0;
					model_data_pagination.limit = response.data.limit ? response.data.limit : 10;
					model_data_pagination.skip = response.data.skip ? response.data.skip : 0;
					model_data_pagination.current = pagination.current ? pagination.current : 1;
					model_data_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;
					dispatch({ type : MODEL_DATA_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : MODEL_DATA_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : MODEL_DATA_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};
  
	const handleModelData = () =>{
		navigate('/model-history');
	};
	
	const closeAddModel = () => {
		setShowAddModel(false);
	};

	const closeEditModel = () => {
		setShowEditModel(false);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());	

	const loadModelDetails = ((id) =>  {
		let details = null;
	
		dispatch({ type: MODEL_DETAILS });
	
		if (data && data.modeldata) {
			data && data.modeldata.map((item) => {
				if (item.id === id) {
					details = item;
				}
	
				return true;
			});
	
			dispatch({ type: MODEL_DETAILS_SUCCESS, payload: details });
		} else {
			dispatch({ type: MODEL_DETAILS_ERROR });
		}
	});

	const closeDeleteModel = () => {
		setShowDeleteModel(false);
	};

	const addModelFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			pidsinfoId:  null,
			numOfClasses:  null,
			eventTypes:  null,
			datasetType:  null,
			sampleLength:  null,
			modelDimension:  null,
			totalSampleCount:  null,
			trainAccuracy:  null,
			testAccuracy:  null,
			trainF1Score:  null,
			testF1Score :  null,      
			eventSampleCount:  null,
			modelFilePath:  null,
			datasetFilePath:  null,
			pklFilePath:  null,
			benchmarkDataFramePath:  null,
			modelName:  null,
			version:  null,
			retrainType:  null,
			validationPassed:  null,
			isLatest:  null,
		},

		onSubmit: (values) => {

			const {pidsinfoId, numOfClasses, eventTypes, datasetType, sampleLength, modelDimension, 
				totalSampleCount, trainAccuracy, testAccuracy, trainF1Score, testF1Score, eventSampleCount, 
				modelFilePath, datasetFilePath, pklFilePath, benchmarkDataFramePath, modelName, version, 
				retrainType, validationPassed, isLatest} = values;
			console.log(pidsinfoId, numOfClasses, eventTypes, datasetType, sampleLength, modelDimension, totalSampleCount, trainAccuracy, testAccuracy, trainF1Score, testF1Score, eventSampleCount, modelFilePath, datasetFilePath, pklFilePath, benchmarkDataFramePath, modelName, version, retrainType, validationPassed, isLatest);
			if(pidsinfoId  == null || numOfClasses  == null || eventTypes  == null || datasetType  == null || sampleLength  == null || modelDimension  == null || totalSampleCount  == null || trainAccuracy  == null || testAccuracy  == null || trainF1Score  == null || testF1Score  == null || eventSampleCount  == null || modelFilePath  == null || datasetFilePath  == null || pklFilePath  == null || benchmarkDataFramePath  == null || modelName  == null || version  == null || retrainType  == null || validationPassed  == null || isLatest == null){
				Modal.warning({title : `${langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields:'Please fill all the mandatory fields'}`});
			}
			else {
				let data = JSON.stringify({
					'pidsinfoId': pidsinfoId,
					'numOfClasses': parseInt(numOfClasses),
					'eventTypes': eventTypes,
					'datasetType': datasetType,
					'sampleLength': parseInt(sampleLength),
					'modelDimension': modelDimension,
					'totalSampleCount': parseInt(totalSampleCount),
					'trainAccuracy': parseFloat(trainAccuracy),
					'testAccuracy':parseFloat(testAccuracy),
					'trainF1Score': parseFloat(trainF1Score),
					'testF1Score': parseFloat(testF1Score),
					'eventSampleCount': eventSampleCount,
					'modelFilePath': modelFilePath,
					'datasetFilePath': datasetFilePath,
					'pklFilePath': pklFilePath,
					'benchmarkDataFramePath': benchmarkDataFramePath,
					'modelName': modelName,
					'version': parseInt(version),
					'retrainType': retrainType,
					'validationPassed': validationPassed,
					'isLatest': isLatest
				});

				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: `${api}/modelinfo`,
					timeout: timeout,
					headers: { 
						'Content-Type': 'application/json', 
						'Authorization': `Bearer ${authtoken}`
					},
					data : data
				};

				axios.request(config)
					.then((response) => {
						if(response.status === 200 || response.status === 201){
							Modal.success({ title: `${langdata && langdata.Modeladdedsuccessfully ? langdata.Modeladdedsuccessfully:'Model added successfully.'}` });
							setShowAddModel(false);
						}
					})
					.catch((error) => {
						console.log(error);
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
							});
						}
					});
			}

		}
	});

	const editModelFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			pidsinfoId: data.modelDetails ? data.modelDetails.pidsinfoId : null,
			numOfClasses: data.modelDetails ? data.modelDetails.numOfClasses :  null,
			eventTypes: data.modelDetails ? data.modelDetails.eventTypes : null,
			datasetType: data.modelDetails ? data.modelDetails.datasetType : null,
			sampleLength: data.modelDetails ? data.modelDetails.sampleLength : null,
			modelDimension: data.modelDetails ? data.modelDetails.modelDimension : null,
			totalSampleCount: data.modelDetails ? data.modelDetails.totalSampleCount : null,
			trainAccuracy: data.modelDetails ? data.modelDetails.trainAccuracy : null,
			testAccuracy: data.modelDetails ? data.modelDetails.testAccuracy : null,
			trainF1Score: data.modelDetails ? data.modelDetails.trainF1Score : null,
			testF1Score : data.modelDetails ? data.modelDetails.testF1Score : null,      
			eventSampleCount: data.modelDetails ? data.modelDetails.eventSampleCount : null,
			modelFilePath: data.modelDetails ? data.modelDetails.modelFilePath : null,
			datasetFilePath: data.modelDetails ? data.modelDetails.datasetFilePath : null,
			pklFilePath: data.modelDetails ? data.modelDetails.pklFilePath : null,
			benchmarkDataFramePath: data.modelDetails ? data.modelDetails.benchmarkDataFramePath : null,
			modelName: data.modelDetails ? data.modelDetails.modelName : null,
			version: data.modelDetails ? data.modelDetails.version : null,
			retrainType: data.modelDetails ? data.modelDetails.retrainType : null,
			validationPassed: data.modelDetails ? data.modelDetails.validationPassed : null,
			isLatest: data.modelDetails ? data.modelDetails.isLatest : null,
		},

		onSubmit: (values) => {
			const id = data.modelDetails.id;
			const {pidsinfoId, numOfClasses, eventTypes, datasetType, sampleLength, modelDimension, 
				totalSampleCount, trainAccuracy, testAccuracy, trainF1Score, testF1Score, eventSampleCount, 
				modelFilePath, datasetFilePath, pklFilePath, benchmarkDataFramePath, modelName, version, 
				retrainType, validationPassed, isLatest} = values;

			if(pidsinfoId  == null || numOfClasses  == null || eventTypes  == null || datasetType  == null || sampleLength  == null || modelDimension  == null || totalSampleCount  == null || trainAccuracy  == null || testAccuracy  == null || trainF1Score  == null || testF1Score  == null || eventSampleCount  == null || modelFilePath  == null || datasetFilePath  == null || pklFilePath  == null || benchmarkDataFramePath  == null || modelName  == null || version  == null || retrainType  == null || validationPassed  == null || isLatest == null){
				Modal.warning({title : `${langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields:'Please fill all the mandatory fields'}`});
			}
			else {

				let data = JSON.stringify({
					'pidsinfoId': pidsinfoId,
					'numOfClasses': numOfClasses,
					'eventTypes': eventTypes,
					'datasetType': datasetType,
					'sampleLength': sampleLength,
					'modelDimension': modelDimension,
					'totalSampleCount': totalSampleCount,
					'trainAccuracy': trainAccuracy,
					'testAccuracy':testAccuracy,
					'trainF1Score': trainF1Score,
					'testF1Score': testF1Score,
					'eventSampleCount': eventSampleCount,
					'modelFilePath': modelFilePath,
					'datasetFilePath': datasetFilePath,
					'pklFilePath': pklFilePath,
					'benchmarkDataFramePath': benchmarkDataFramePath,
					'modelName': modelName,
					'version': version,
					'retrainType': retrainType,
					'validationPassed': validationPassed,
					'isLatest': isLatest
				});

				let config = {
					method: 'patch',
					maxBodyLength: Infinity,
					url: `${api}/modelinfo/${id}`,
					timeout: timeout,
					headers: { 
						'Content-Type': 'application/json', 
						'Authorization': `Bearer ${authtoken}`
					},
					data : data
				};

				axios.request(config)
					.then((response) => {
						if(response.status === 200 || response.status === 201){	
							Modal.success({ title: `${langdata && langdata.Successfullyupdatedmodel ? langdata.Successfullyupdatedmodel:'Successfully updated model.'}` });
							setShowEditModel(false);
						}
					})
					.catch((error) => {
						console.log(error);
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
							});
						}
					});
			}

		}
	});

	const deleteModel = () => {
		const id = data.modelDetails.id;

		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/modelinfo/${id}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then(() => {
				Modal.success({ title: `${langdata && langdata.Modeldeletedsuccessfully ? langdata.Modeldeletedsuccessfully:'Model deleted successfully.'}` });
				modelData(1);
				setShowDeleteModel(false);
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
				setShowDeleteModel(false);
			});
	};


	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.Modelinfocontent ? langdata.Modelinfocontent:'Module shows current model deployment status and details of machine learning models deployed at different location.'}</p>
		</Card>
	);

	return (

		<>
			<Content>
				{/* <Row 
						style={{
							// marginLeft:'15px',
							// marginTop: '10px'
							margin : '10px'
						}}            
					>
						<Breadcrumb
							items={[
								{
									href: '/dashboard',
									title: <HomeOutlined />,
								},
								{
									title:  <a onClick={()=>{navigate(-1);}}>Retraining History</a> 
								},
								{
									title: 'Model Configurations',
								},
							]}
						/>
					</Row> */}
				<Row 
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >

					<Col span={8}>
						<Row>

							<Space>
								<InfoCircleOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.ModelInfo ? langdata.ModelInfo:'Model Info'}</Title>
						</Row>
					</Col>


					<Col style={{ display : 'flex', justifyContent : 'right'}} span={16} >
						<Row>
							<Col>
								{/* <Tooltip placement="bottom" title="Filter by pids">
									<Space>
										<Select Item
											showSearch
											filterOption={filterOption}
											style={{ minWidth: '240px', maxWidth:'240px', margin:'5px'}}
											placeholder="Select Pids"
											optionFilterProp="children"
											// value={regionId}
											defaultValue={'ALL'}
											options={pidsListFilter}
											onSelect={(e)=>setPidsId(e)}
										/>
									</Space>
								</Tooltip> */}
								{permission==='superadmin' || permission==='admin' ?
									<>
										<Tooltip placement="bottom" title={langdata && langdata.Filterbypids ? langdata.Filterbypids:'Filter by pids'}>
											<Space>
												<Select Item
													showSearch
													filterOption={filterOption}
													style={{ minWidth: '240px', maxWidth:'240px', margin:'5px'}}
													placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS:'Select PIDS'}
													optionFilterProp="children"
													defaultValue={'all'}
													options={pidsListFilter}
													onSelect={(e)=>setPidsId(e)}
												/>
											</Space>
										</Tooltip>
										<Space>&nbsp;&nbsp;</Space>

	
										<Tooltip placement="bottom" title={langdata && langdata.AddModel ? langdata.AddModel:'Add Model'}>

											<Space>

												<Button
													style={{ margin:'5px'}}
													type='primary'
													onClick={() => setShowAddModel(true)}
												><PlusOutlined/>{langdata && langdata.AddModel ? langdata.AddModel:'Add New'}</Button>
											</Space>
										</Tooltip>
										<Space>&nbsp;&nbsp;</Space>

	
										<Tooltip placement="bottom" title={langdata && langdata.ModelConfigurations ? langdata.ModelConfigurations:'Model Configurations'}>

											<Space>

												<Button
													style={{ margin:'5px'}}
													type='primary'
													onClick={handleModelData}
												>{langdata && langdata.ModelHistory ? langdata.ModelHistory:'Model History'} </Button>
											</Space>
										</Tooltip>
										<Space>&nbsp;&nbsp;</Space>

									</>
									: null }

								<Space>
									<Typography>
										<Popover title={langdata && langdata.ModelInfo ? langdata.ModelInfo:'Model Info'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col> 


					
				</Row>
				
				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					// marginTop:'0px',
					background: '#FFFFFF',
					alignItems:'center'
				}}>
					<Col span={24}>
						<Table 
							scroll={{
								x: 900,
							}}
							columns={columns} dataSource={model_data} pagination = { model_data.length > 0 ? model_data_pagination : 0}
							onChange = { modelData }
							onRow={(record) => {
								return {
									onClick: () => { 
										loadModelDetails(record.id);
									}
								};
							}} 
						/>
					</Col>
				</Row>
						
					
			</Content>

			<Modal
				title={langdata && langdata.AddModel ? langdata.AddModel:'Add Model'}
				centered
				open={showAddModel}
				onOk={{closeAddModel}}
				onCancel={closeAddModel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeAddModel}>
							{langdata && langdata.CANCEL ? langdata.CANCEL:'CANCEL'}
						</Button>
						<Button key="submit" type="primary" onClick={addModelFormik.handleSubmit}>
							{langdata && langdata.SAVE ? langdata.SAVE:'SAVE'}
						</Button>
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" onSubmit={addModelFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.Pids ? langdata.Pids:'Pids'}  name="Pids" rules={[{ required: true }]} >
								<Select
									name="pidsinfoId" 
									showSearch
									placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS:'Select PIDS'}
									optionFilterProp="children"
									options={pidsList}
									onChange={(value) => addModelFormik.setFieldValue('pidsinfoId', value)}
									filterOption={filterOption}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.NumberOfClasses ? langdata.NumberOfClasses:'Number Of Classes'} name="Number Of Classes" rules={[{ required: true }]} >
								<Input 
									name="numOfClasses" 
									placeholder={langdata && langdata.Pleasespecifythenumberofclasses ? langdata.Pleasespecifythenumberofclasses:'Please specify the number of classes'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.numOfClasses}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ModelClasses ? langdata.ModelClasses:'Model Classes'} name="Model Classes" rules={[{ required: true }]} >
								<Select
									name="eventTypes" 
									showSearch
									placeholder={langdata && langdata.Selectmodelclasses ? langdata.Selectmodelclasses:'Select model classes'}
									optionFilterProp="children"
									options={eventsList}
									mode="multiple"
									onChange={(value) => addModelFormik.setFieldValue('eventTypes', value)}
									filterOption={filterOption}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.SampleType ? langdata.SampleType:'Sample Type'} name="Sample Type" rules={[{ required: true }]} >
								<Select
									name="datasetType" 
									showSearch
									placeholder={langdata && langdata.Selectsampletype ? langdata.Selectsampletype:'Select sample type'}
									optionFilterProp="children"
									options={[
										{

											value : 'Balanced',
											label : `${langdata && langdata.Balanced ? langdata.Balanced:'Balanced'}`
										},
										{
											value : 'Unbalanced',
											label :  `${langdata && langdata.Unbalanced ? langdata.Unbalanced:'Unbalanced'}`,
										}
									]}
									onChange={(value) => addModelFormik.setFieldValue('datasetType', value)}
									filterOption={filterOption}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.SampleLength ? langdata.SampleLength:'Sample Length'} name="Sample Length" rules={[{ required: true }]} >
								<Input 
									name="sampleLength" 
									placeholder={langdata && langdata.Pleasespecifythecellsamplelength ? langdata.Pleasespecifythecellsamplelength:'Please specify the cell sample length'} 
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.sampleLength}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.MLAlgorithm ? langdata.MLAlgorithm:'ML Algorithm'} name="ML Algorithm" rules={[{ required: true }]} >
								<Select
									name="modelDimension" 
									showSearch
									placeholder={langdata && langdata.SelectMLalgorithm ? langdata.SelectMLalgorithm:'Select ML algorithm'}
									optionFilterProp="children"
									options={[
										{

											value : 'CNN_1D',
											label : 'CNN_1D'
										},
										{
											value : 'CNN_2D',
											label : 'CNN_2D',
										}
									]}
									filterOption={filterOption}
									onChange={(value) => addModelFormik.setFieldValue('modelDimension', value)}
									value={editModelFormik.values.modelDimension}
									initialValues={data.modelDetails ? data.modelDetails.modelDimension : null}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.TotalSampleCount ? langdata.TotalSampleCount:'Total Sample Count'} name="Total Sample Count" rules={[{ required: true }]} >
								<Input 
									name="totalSampleCount" 
									placeholder={langdata && langdata.Pleasespecifythetotalsamplecount ? langdata.Pleasespecifythetotalsamplecount:'Please specify the total sample count'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.totalSampleCount} 
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.TrainAccuracy ? langdata.TrainAccuracy:'Train Accuracy'} name="Train Accuracy" rules={[{ required: true }]} >
								<Input 
									name="trainAccuracy"  
									placeholder={langdata && langdata.Pleaseconfirmthetrainaccuracy ? langdata.Pleaseconfirmthetrainaccuracy:'Please confirm the train accuracy'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.trainAccuracy} 
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.TestAccuracy ? langdata.TestAccuracy:'Test Accuracy'} name="Test Accuracy" rules={[{ required: true }]} >
								<Input 
									name="testAccuracy"  
									placeholder={langdata && langdata.Pleaseconfirmthetestaccuracy ? langdata.Pleaseconfirmthetestaccuracy:'Please confirm the test accuracy'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.testAccuracy} 
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.TrainF1Score ? langdata.TrainF1Score:'Train F1 Score'} name="Train F1 Score" rules={[{ required: true }]} >
								<Input 
									name="trainF1Score" 
									placeholder={langdata && langdata.PleasespecifythetrainF1score ? langdata.PleasespecifythetrainF1score:'Please specify the train F1 score'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.trainF1Score}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.TestF1Score ? langdata.TestF1Score:'Test F1 Score'} name="Test F1 Score" rules={[{ required: true }]} >
								<Input 
									name="testF1Score" 
									placeholder={langdata && langdata.PleasespecifythetestF1score ? langdata.PleasespecifythetestF1score:'Please specify the test F1 score'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.testF1Score}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.EventSampleCount ? langdata.EventSampleCount:'Event Sample Count'} name="Event Sample Count" rules={[{ required: true }]} >
								<Input 
									name="eventSampleCount" 
									placeholder={langdata && langdata.Pleasespecifytheeventsamplecount ? langdata.Pleasespecifytheeventsamplecount:'Please specify the event sample count'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.eventSampleCount}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ModelFilePath ? langdata.ModelFilePath:'Model File Path'} name="Model File Path" rules={[{ required: true }]} >
								<Input 
									name="modelFilePath" 
									placeholder={langdata && langdata.Pleasespecifythemodelfilepath ? langdata.Pleasespecifythemodelfilepath:'Please specify the model file path'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.modelFilePath}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.DatasetFilePath ? langdata.DatasetFilePath:'Dataset File Path'} name="Dataset File Path" rules={[{ required: true }]} >
								<Input 
									name="datasetFilePath" 
									placeholder={langdata && langdata.Pleasespecifythedatasetfilepath ? langdata.Pleasespecifythedatasetfilepath:'Please specify the dataset file path'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.datasetFilePath}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.PklFilePath ? langdata.PklFilePath:'Pkl File Path'} name="Pkl File Path" rules={[{ required: true }]} >
								<Input 
									name="pklFilePath" 
									placeholder={langdata && langdata.Pleasespecifythepklfilepath ? langdata.Pleasespecifythepklfilepath:'Please specify the pkl file path'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.pklFilePath}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.BenchMarkDataFramePath ? langdata.BenchMarkDataFramePath:'Bench Mark Data Frame Path'} name="BenchMark Data Frame Path" rules={[{ required: true }]} >
								<Input 
									name="benchmarkDataFramePath" 
									placeholder={langdata && langdata.Pleaseconfirmthebenchmarkdataframepath ? langdata.Pleaseconfirmthebenchmarkdataframepath:'Please confirm the benchmark data frame path'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.benchmarkDataFramePath}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.ModelName ? langdata.ModelName:'Model Name'} name="Model Name" rules={[{ required: true }]} >
								<Input 
									name="modelName" 
									placeholder={langdata && langdata.Pleaseconfirmthemodelname ? langdata.Pleaseconfirmthemodelname:'Please confirm the  model name'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.modelName}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ModelVersion ? langdata.ModelVersion:'Model Version'} name="Model Version" rules={[{ required: true }]} >
								<Input 
									name="version" 
									placeholder={langdata && langdata.Pleasespecifythemodelversion ? langdata.Pleasespecifythemodelversion:'Please specify the model version'}
									onChange={addModelFormik.handleChange} 
									value={addModelFormik.values.version}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.RetrainType ? langdata.RetrainType:'Retrain Type'} name="Retrain Type" rules={[{ required: true }]} >
								<Select
									name="retrainType" 
									showSearch
									placeholder={langdata && langdata.Selectretraintype ? langdata.Selectretraintype:'Select retrain type'}
									optionFilterProp="children"
									options={[
										{

											value : 'Primary',
											label : `${langdata && langdata.Primary ? langdata.Primary:'Primary'}`
										},
										{
											value : 'Secondary',
											label : `${langdata && langdata.Secondary ? langdata.Secondary:'Secondary'}`,
										}
									]}
									onChange={(value) => addModelFormik.setFieldValue('retrainType', value)}
									filterOption={filterOption}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ValidationPassed ? langdata.ValidationPassed:'Validation Passed'} name="Validation Passed" rules={[{ required: true }]} >
								<Select
									name="validationPassed" 
									showSearch
									placeholder={langdata && langdata.Selectvalidationpassed ? langdata.Selectvalidationpassed:'Select validation passed'}
									optionFilterProp="children"
									options={[
										{

											value : true,
											label : `${langdata && langdata.YES ? langdata.YES:'YES'}`
										},
										{
											value : false,
											label : `${langdata && langdata.NO ? langdata.NO:'NO'}`,
										}
									]}
									onChange={(value) => addModelFormik.setFieldValue('validationPassed', value)}
									filterOption={filterOption}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.IsLatest ? langdata.IsLatest:'Is Latest'} name="Is Latest" rules={[{ required: true }]} >
								<Select
									name="isLatest" 
									showSearch
									placeholder={langdata && langdata.Selectislatest ? langdata.Selectislatest:'Select is latest'}
									optionFilterProp="children"
									options={[
										{

											value : true,
											label :  `${langdata && langdata.YES ? langdata.YES:'YES'}`
										},
										{
											value : false,
											label : `${langdata && langdata.NO ? langdata.NO:'NO'}`,
										}
									]}
									onChange={(value) => addModelFormik.setFieldValue('isLatest', value)}
									filterOption={filterOption}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Modal
				title={langdata && langdata.EditModel ? langdata.EditModel:'Edit Model'}
				centered
				open={showEditModel}
				// onOk={{closeEditModel}}
				onCancel={closeEditModel}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeEditModel}>
							{langdata && langdata.CANCEL ? langdata.CANCEL:'CANCEL'}
						</Button>
						<Button key="submit" type="primary" onClick={editModelFormik.handleSubmit}>
							{langdata && langdata.SAVE ? langdata.SAVE:'SAVE'}
						</Button>
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" onSubmit={editModelFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.Pids ? langdata.Pids:'Pids'} rules={[{ message: `${langdata && langdata.Pleasespecifythepids ? langdata.Pleasespecifythepids:'Please specify the pids'}` }]} >
								<Select
									name="pidsinfoId" 
									showSearch
									placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS:'Select PIDS'}
									optionFilterProp="children"
									options={pidsList}
									onChange={(value) => editModelFormik.setFieldValue('pidsinfoId', value)}
									value={editModelFormik.values.pidsinfoId}
									initialValues={data.modelDetails ? data.modelDetails.pidsinfoId : null}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.NumberOfClasses ? langdata.NumberOfClasses:'Number Of Classes'} rules={[{ message: `${langdata && langdata.Pleasespecifythenumberofclasses ? langdata.Pleasespecifythenumberofclasses:'Please specify the number of classes' }` }]} >
								<Input 
									name="numOfClasses" 
									placeholder={langdata && langdata.Pleasespecifythenumberofclasses ? langdata.Pleasespecifythenumberofclasses:'Please specify the number of classes'}
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.numOfClasses}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ModelClasses ? langdata.ModelClasses:'Model Classes'} rules={[{ message: `${langdata && langdata.Pleasespecifythemodelclasses ? langdata.Pleasespecifythemodelclasses:'Please specify the model classes'}` }]} >
								<Select
									name="eventTypes" 
									showSearch
									placeholder={langdata && langdata.Selectmodelclasses ? langdata.Selectmodelclasses:'Select model classes'}
									optionFilterProp="children"
									options={eventsList}
									mode="multiple"
									onChange={(value) => editModelFormik.setFieldValue('eventTypes', value)}
									value={editModelFormik.values.eventTypes}
									initialValues={data.modelDetails ? data.modelDetails.eventTypes : null}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.SampleType ? langdata.SampleType:'Sample Type'} rules={[{ message: `${langdata && langdata.Pleasespecifythesampletype ? langdata.Pleasespecifythesampletype:'Please specify the sample type'}` }]} >
								<Select
									name="datasetType" 
									showSearch
									placeholder={langdata && langdata.Selectsampletype ? langdata.Selectsampletype:'Select sample type'}
									optionFilterProp="children"
									options={[
										{

											value : 'Balanced',
											label : `${langdata && langdata.Balanced ? langdata.Balanced:'Balanced'}`
										},
										{
											value : 'Unbalanced',
											label : `${langdata && langdata.Unbalanced ? langdata.Unbalanced:'Unbalanced'}`,
										}
									]}
									onChange={(value) => editModelFormik.setFieldValue('datasetType', value)}
									value={editModelFormik.values.datasetType}
									initialValues={data.modelDetails ? data.modelDetails.datasetType : null}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.SampleLength ? langdata.SampleLength:'Sample Length'} rules={[{ message: `${langdata && langdata.Pleasespecifythecellsamplelength ? langdata.Pleasespecifythecellsamplelength:'Please specify the cell sample length'}` }]} >
								<Input 
									name="sampleLength" 
									placeholder={langdata && langdata.Pleasespecifythecellsamplelength ? langdata.Pleasespecifythecellsamplelength:'Please specify the cell sample length'} 
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.sampleLength}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.MLAlgorithm ? langdata.MLAlgorithm:'ML Algorithm'} rules={[{ message: `${langdata && langdata.PleasespecifytheMLalgorithm ? langdata.PleasespecifytheMLalgorithm:'Please specify the ML algorithm'}` }]} >
								<Select
									name="modelDimension" 
									showSearch
									placeholder={langdata && langdata.SelectMLalgorithm ? langdata.SelectMLalgorithm:'Select ML algorithm'}
									optionFilterProp="children"
									options={[
										{

											value : 'CNN_1D',
											label : 'CNN_1D'
										},
										{
											value : 'CNN_2D',
											label : 'CNN_2D',
										}
									]}
									onChange={(value) => editModelFormik.setFieldValue('modelDimension', value)}
									value={editModelFormik.values.modelDimension}
									initialValues={data.modelDetails ? data.modelDetails.modelDimension : null}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.TotalSampleCount ? langdata.TotalSampleCount:'Total Sample Count'}  rules={[{ message: `${langdata && langdata.Pleasespecifythetotalsamplecount ? langdata.Pleasespecifythetotalsamplecount:'Please specify the total sample count'}` }]} >
								<Input 
									name="totalSampleCount" 
									placeholder={langdata && langdata.Pleasespecifythetotalsamplecount ? langdata.Pleasespecifythetotalsamplecount:'Please specify the total sample count'}
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.totalSampleCount} 
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.TrainAccuracy ? langdata.TrainAccuracy:'Train Accuracy'} rules={[{ message: `${langdata && langdata.Pleaseconfirmthetrainaccuracy ? langdata.Pleaseconfirmthetrainaccuracy:'Please confirm the train accuracy'}` }]} >
								<Input 
									name="trainAccuracy"  
									placeholder={langdata && langdata.Pleaseconfirmthetrainaccuracy ? langdata.Pleaseconfirmthetrainaccuracy:'Please confirm the train accuracy'}
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.trainAccuracy} 
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.TestAccuracy ? langdata.TestAccuracy:'Test Accuracy'} rules={[{ message: `${langdata && langdata.Pleaseconfirmthetestaccuracy ? langdata.Pleaseconfirmthetestaccuracy:'Please confirm the test accuracy'}` }]} >
								<Input 
									name="testAccuracy"  
									placeholder={langdata && langdata.Pleaseconfirmthetestaccuracy ? langdata.Pleaseconfirmthetestaccuracy:'Please confirm the test accuracy'}
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.testAccuracy} 
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.TrainF1Score ? langdata.TrainF1Score:'Train F1 Score'} rules={[{ message: `${langdata && langdata.PleasespecifythetrainF1score ? langdata.PleasespecifythetrainF1score:'Please specify the train F1 score'}` }]} >
								<Input 
									name="trainF1Score" 
									placeholder={langdata && langdata.PleasespecifythetrainF1score ? langdata.PleasespecifythetrainF1score:'Please specify the train F1 score'}
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.trainF1Score}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.TestF1Score ? langdata.TestF1Score:'Test F1 Score'} rules={[{ message: `${langdata && langdata.PleasespecifythetestF1score ? langdata.PleasespecifythetestF1score:'Please specify the test F1 score'}` }]} >
								<Input 
									name="testF1Score" 
									placeholder={langdata && langdata.PleasespecifythetestF1score ? langdata.PleasespecifythetestF1score:'Please specify the test F1 score'}
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.testF1Score}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.EventSampleCount ? langdata.EventSampleCount:'Event Sample Count'} rules={[{ message: `${langdata && langdata.Pleasespecifytheeventsamplecount ? langdata.Pleasespecifytheeventsamplecount:'Please specify the event sample count'}` }]} >
								<Input 
									name="eventSampleCount" 
									placeholder={langdata && langdata.Pleasespecifytheeventsamplecount ? langdata.Pleasespecifytheeventsamplecount:'Please specify the event sample count'}
									onChange={editModelFormik.handleChange} 
									value={JSON.stringify(editModelFormik.values.eventSampleCount)}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ModelFilePath ? langdata.ModelFilePath:'Model File Path'} rules={[{ message: `${langdata && langdata.Pleasespecifythemodelfilepath ? langdata.Pleasespecifythemodelfilepath:'Please specify the model file path'}` }]} >
								<Input 
									name="modelFilePath" 
									placeholder={langdata && langdata.Pleasespecifythemodelfilepath ? langdata.Pleasespecifythemodelfilepath:'Please specify the model file path'}
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.modelFilePath}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.DatasetFilePath ? langdata.DatasetFilePath:'Dataset File Path'} rules={[{ message: `${langdata && langdata.Pleasespecifythedatasetfilepath ? langdata.Pleasespecifythedatasetfilepath:'Please specify the dataset file path'}` }]} >
								<Input 
									name="datasetFilePath" 
									placeholder={langdata && langdata.Pleasespecifythedatasetfilepath ? langdata.Pleasespecifythedatasetfilepath:'Please specify the dataset file path'}
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.datasetFilePath}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.PklFilePath ? langdata.PklFilePath:'Pkl File Path'} rules={[{ message: `${langdata && langdata.Pleasespecifythepklfilepath ? langdata.Pleasespecifythepklfilepath:'Please specify the pkl file path'}` }]} >
								<Input 
									name="pklFilePath" 
									placeholder={langdata && langdata.Pleasespecifythepklfilepath ? langdata.Pleasespecifythepklfilepath:'Please specify the pkl file path'}
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.pklFilePath}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.BenchMarkDataFramePath ? langdata.BenchMarkDataFramePath:'Bench Mark Data Frame Path'} rules={[{ message: `${langdata && langdata.Pleaseconfirmthebenchmarkdataframepath ? langdata.Pleaseconfirmthebenchmarkdataframepath:'Please confirm the benchmark data frame path'}` }]} >
								<Input 
									name="benchmarkDataFramePath" 
									placeholder={langdata && langdata.Pleaseconfirmthebenchmarkdataframepath ? langdata.Pleaseconfirmthebenchmarkdataframepath:'Please confirm the benchmark data frame path'}
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.benchmarkDataFramePath}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.ModelName ? langdata.ModelName:'Model Name'} rules={[{ message: `${langdata && langdata.Pleasespecifythemodelname ? langdata.Pleasespecifythemodelname:'Please specify the model name'}` }]} >
								<Input 
									name="modelName" 
									placeholder={langdata && langdata.Pleaseconfirmthemodelname ? langdata.Pleaseconfirmthemodelname:'Please confirm the  model name'}
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.modelName}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ModelVersion ? langdata.ModelVersion:'Model Version'} rules={[{ message: `${langdata && langdata.Pleasespecifythemodelversion ? langdata.Pleasespecifythemodelversion:'Please specify the model version'}` }]} >
								<Input 
									name="version" 
									placeholder={langdata && langdata.Pleasespecifythemodelversion ? langdata.Pleasespecifythemodelversion:'Please specify the model version'}
									onChange={editModelFormik.handleChange} 
									value={editModelFormik.values.version}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.RetrainType ? langdata.RetrainType:'Retrain Type'} rules={[{ message: `${langdata && langdata.Pleasespecifytheretraintype ? langdata.Pleasespecifytheretraintype:'Please specify the retrain type'}` }]} >
								<Select
									name="retrainType" 
									showSearch
									placeholder={langdata && langdata.Selectretraintype ? langdata.Selectretraintype:'Select retrain type'}
									optionFilterProp="children"
									options={[
										{

											value : 'Primary',
											label : `${langdata && langdata.Primary ? langdata.Primary:'Primary'}`
										},
										{
											value : 'Secondary',
											label : `${langdata && langdata.Secondary ? langdata.Secondary:'Secondary'}`,
										}
									]}
									onChange={(value) => editModelFormik.setFieldValue('retrainType', value)}
									value={editModelFormik.values.retrainType}
									initialValues={data.modelDetails ? data.modelDetails.retrainType : null}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ValidationPassed ? langdata.ValidationPassed:'Validation Passed'} rules={[{ message: `${langdata && langdata.Pleasespecifythevalidationpassed ? langdata.Pleasespecifythevalidationpassed:'Please specify the validation passed'}` }]} >
								<Select
									name="validationPassed" 
									showSearch
									placeholder={langdata && langdata.Selectvalidationpassed ? langdata.Selectvalidationpassed:'Select validation passed'}
									optionFilterProp="children"
									options={[
										{

											value : true,
											label : `${langdata && langdata.YES ? langdata.YES:'YES'}`
										},
										{
											value : false,
											label : `${langdata && langdata.NO ? langdata.NO:'NO'}`,
										}
									]}
									onChange={(value) => editModelFormik.setFieldValue('validationPassed', value)}
									value={editModelFormik.values.validationPassed}
									initialValues={data.modelDetails ? data.modelDetails.validationPassed : null}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.IsLatest ? langdata.IsLatest:'Is Latest'} rules={[{ message: `${langdata && langdata.Pleasespecifytheislatest ? langdata.Pleasespecifytheislatest : 'Please specify the is latest'}` }]} >
								<Select
									name="isLatest" 
									showSearch
									placeholder={langdata && langdata.Selectislatest ? langdata.Selectislatest:'Select is latest'}
									optionFilterProp="children"
									options={[
										{

											value : true,
											label : `${langdata && langdata.YES ? langdata.YES:'YES'}`
										},
										{
											value : false,
											label : `${langdata && langdata.NO ? langdata.NO:'NO'}`,
										}
									]}
									onChange={(value) => editModelFormik.setFieldValue('isLatest', value)}
									value={editModelFormik.values.isLatest}
									initialValues={data.modelDetails ? data.modelDetails.isLatest : null}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete:'Confirm delete'} open={showDeleteModel} onOk={deleteModel} onCancel={closeDeleteModel}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>
						{langdata && langdata.Areyousureyouwanttodeletethismodel ? langdata.Areyousureyouwanttodeletethismodel:'Are you sure you want to delete this model?'}
					</Col>
				</Row>
			</Modal>

			<Modal 
				title={langdata && langdata.ModelDetails ? langdata.ModelDetails:'Model Details'}
				open={isModalOpen} 
				onCancel={handleCancel}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button type='primary' onClick={handleCancel}>
							{langdata && langdata.CLOSE ? langdata.CLOSE:'CLOSE'}
						</Button>

					</Space>
				]}
				width={500}
			>
				<Divider/>
				<Row
					gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}>
					<Col span={24}>
						<Typography><b>{langdata && langdata.PIDSName ? langdata.PIDSName:'PIDS Name'} : </b>{data.modelInfoDetails && data.modelInfoDetails.pidsinfo}</Typography>
						<Typography><b>{langdata && langdata.ModelName ? langdata.ModelName:'Model Name'} : </b>{data.modelInfoDetails && data.modelInfoDetails.modelName}</Typography>
						<Typography><b>{langdata && langdata.ModelVersion ? langdata.ModelVersion:'Model Version'} : </b>{data.modelInfoDetails && data.modelInfoDetails.modelVersion}</Typography>
						<Typography><b>{langdata && langdata.MLAlgorithm ? langdata.MLAlgorithm:'ML Algorithm'} : </b>{data.modelInfoDetails && data.modelInfoDetails.modelDimension}</Typography>
						<Typography><b>{langdata && langdata.TotalSampleCount ? langdata.TotalSampleCount:'Total Sample Count'} : </b>{data.modelInfoDetails && data.modelInfoDetails.totalSampleCount}</Typography>
						<Typography><b>{langdata && langdata.TestAccuracy ? langdata.TestAccuracy:'Test Accuracy'} : </b>{data.modelInfoDetails && data.modelInfoDetails.testAccuracy}</Typography>
						<Typography><b>{langdata && langdata.TrainAccuracy ? langdata.TrainAccuracy:'Train Accuracy'} : </b>{data.modelInfoDetails && data.modelInfoDetails.trainAccuracy}</Typography>
						<Typography><b>{langdata && langdata.TestF1Score ? langdata.TestF1Score:'Test F1 Score'} : </b>{data.modelInfoDetails && data.modelInfoDetails.testF1Score}</Typography>
						<Typography><b>{langdata && langdata.TrainF1Score ? langdata.TrainF1Score:'Train F1 Score'} : </b>{data.modelInfoDetails && data.modelInfoDetails.trainF1Score}</Typography>
						<Typography><b>{langdata && langdata.SampleType ? langdata.SampleType:'Sample Type'} : </b>{data.modelInfoDetails && data.modelInfoDetails.datasetType}</Typography>
						<Typography><b>{langdata && langdata.NumberOfClasses ? langdata.NumberOfClasses:'Number Of Classes'} : </b>{data.modelInfoDetails && data.modelInfoDetails.numOfClasses}</Typography>
						<Typography><b>{langdata && langdata.EventSampleCount ? langdata.EventSampleCount:'Event Sample Count'} : </b>{ JSON.stringify(data.modelInfoDetails && data.modelInfoDetails.eventSampleCount).replaceAll('{', '').replaceAll('}', '').replaceAll(':', '\t:\t').replaceAll(',', ',\t').replaceAll('"', '')}</Typography>
						<Typography><b>{langdata && langdata.ValidationPassed ? langdata.ValidationPassed:'Validation Passed'} : </b>{data.modelInfoDetails && data.modelInfoDetails.validationPassed}</Typography>						
					</Col>
				</Row>
			</Modal>

			<Spin spinning={data.loading} fullscreen />
		</> 
	);
}