import { PIG_LIST, PIG_LIST_DETAILS, PIG_LIST_ERROR, PIG_LIST_SUCCESS, PIG_LOCATION, PIG_LOCATION_ERROR, PIG_LOCATION_SUCCESS, PIG_PID_LIST_SUCCESS, PIG_UPDATE, PIG_UPDATE_ERROR, PIG_UPDATE_SUCCESS } from './constants';

export default function trackpig(state, action) {

	let pig_data = [];
	let pig_location = [];
	let pid_data = [];

	switch (action.type) {
      
	case PIG_LIST:
		return { ...state, pending: true };
	case PIG_LIST_SUCCESS:

		action.payload && action.payload.map((item)=>{
			if(item.isPigEnabled==true){
				pig_data.push(item.pidsinfoId);
			}
		});
		return { ...state, pig_data:pig_data, pending: false };
	case PIG_LIST_ERROR:
        
		return { ...state, pig_data:[], error: true, pending: false};
	case PIG_LIST_DETAILS:
		return { ...state, details:action.payload[0], error: true, pending: false };

	case PIG_PID_LIST_SUCCESS:
		action.payload && action.payload.map((item)=>{
			pid_data.push(item.pidsinfoId);
		});
		return { ...state, pid_data:pid_data, error: true, pending: false };

	case PIG_UPDATE:
		return { ...state, pending: false, loading: true };
	case PIG_UPDATE_SUCCESS:
		return { ...state, pig_update:action.payload, pending: false, loading: false };
	case PIG_UPDATE_ERROR:
		return { ...state, pig_data:[], error: true, pending: false, loading: false};

	case PIG_LOCATION:
		return { ...state, pending: true };
	case PIG_LOCATION_SUCCESS:
		action.payload.payload && action.payload.payload.map((item)=>{
			action.payload.pigenabled && action.payload.pigenabled.map((inneritem)=>{
          
				if(item.pidsinfoId==inneritem){      
					pig_location.push({
						geocode:[item.latitude,item.longitude],
						id:item.id + item.id,
						phone:item.cellPhone,
						status:item.status,
						Latitude:item.latitude ? item.latitude : '',
						Longitude:item.longitude ? item.longitude : '',
						channelId:item.channelId,
						pidsinfoId:item.pidsinfoId,
						piplineconfigId: item.piplineconfigId,
						regioninfoId:item.regioninfoId
					});        
					// eslint-disable-next-line no-undef
				}
        
			});
		});
		return { ...state, pig_location:pig_location, pending: false };
	case PIG_LOCATION_ERROR:
		return { ...state, pig_data:[], error: true, pending: false};

      
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}