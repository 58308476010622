/* eslint-disable react/react-in-jsx-scope */
import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
	const error = useRouteError();
	console.error(error);
  
	return (
		<div id="error-page">
			<h1>The requested page was not found on the server</h1>
		</div>
	);
}