import React, { useState, useEffect, useReducer } from 'react';
import { useAuth } from '../Context';

import { Button, Form, Input, Empty, DatePicker, Row, Col, Select, Typography, Tooltip, Space, Table, Modal, Spin, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { EyeOutlined, DeleteOutlined, InfoOutlined } from '@ant-design/icons';

import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR }  from './constants';
import { NOTIFICATIONS_LIST, NOTIFICATIONS_LIST_SUCCESS, NOTIFICATIONS_LIST_ERROR  } from './constants';
import { NOTIFICATION_DETAILS, NOTIFICATION_DETAILS_SUCCESS, NOTIFICATION_DETAILS_ERROR  } from './constants';
import { DELETE_NOTIFICATION, DELETE_NOTIFICATION_SUCCESS, DELETE_NOTIFICATION_ERROR } from './constants';

import {} from './constants';
import notificationsReducer from './reducer.js';

import axios from 'axios';
import dayjs from 'dayjs';
import { ColumnWidthOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;
const { TextArea } = Input;

const dateFormat = 'YYYY/MM/DD';

export default function Notifications() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const [regionId, setRegionId] = useState(null);
	const [tlpId, setTLPId] = useState(null);
	const initalState = {tlps: [], notifications : [], pagination: { total: 0, limit: 10, skip: 0, current:1 }, details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(notificationsReducer, initalState);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [allocationId, setAllocationId] = useState(null);
	// const [startCh, setStartCh] = useState(null);
	// const [endCh, setEndCh] = useState(null);

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
  
	const today = dayjs();
	const [date, setDate] = useState(today);

	const [showNotificationModal, setShowNotificationModal] = useState(false);
	const [openDeleteNotificationModal, setOpenDeleteNotificationModal] = useState(false);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const navigate = useNavigate();

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const ruleTypes = [
		{ value: 1, label: langdata && langdata.CASINGACPICKUP ? langdata.CASINGACPICKUP : 'CASING AC PICKUP' },
		{ value: 2, label: langdata && langdata.ACPICKUP ? langdata.ACPICKUP : 'AC PICKUP' },
		{ value: 3, label: langdata && langdata.CSPDC ? langdata.CSPDC : 'CSP DC' },
		{ value: 4, label: langdata && langdata.PSPDC ? langdata.PSPDC : 'PSP DC' },
		{ value: 5, label: langdata && langdata.BATTERY ? langdata.BATTERY : 'BATTERY' },
		{ value: 6, label: langdata && langdata.TEMPERATURE ? langdata.TEMPERATURE : 'TEMPERATURE' },
		{ value: 7, label: langdata && langdata.CURRENT ? langdata.CURRENT : 'CURRENT' }
	];

	const getRuleType = (index) => {
		let ruleType = ruleTypes.find( (ruleType) => ruleType.value === index);

		return ruleType ? ruleType.label : '<Not Set>';
	};

	const getChainage = (deviceId) => {
		let tlp = data.tlps.find( (tlp) => tlp.deviceId === deviceId);

		return tlp ? tlp.pipelineChainage : '<Not Set>';
	};

	

	const columns = [
		{
			title: langdata && langdata.Device ? langdata.Device : 'Device',
			dataIndex: 'deviceId',
			key: 'deviceId',
			render: (text) => <span onClick={openNotificationModal}>{text}</span>,
		},
		{
			title:  langdata && langdata.NotificationType ? langdata.NotificationType : 'Notification Type',
			dataIndex: 'notificationType',
			key: 'notificationType',
			render: (_, { notificationType }) => (
				<>
					{ruleTypes.map((type) => {
						if (notificationType ===  type.value) {
							return (
								// eslint-disable-next-line react/jsx-key
								<div>
									{type.label}
								</div>
							); 
						} else {
							return '';
						}
					})}
				</>
			)
		},
		{
			title:  langdata && langdata.Message ? langdata.Message : 'Message',
			dataIndex: 'message',
			key: 'message',
		},
		{
			title: langdata && langdata.Date ? langdata.Date : 'Date',
			dataIndex: 'createdAt',
			key: 'createdAt'
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: () => (
				<Space size="middle">
					<a title={langdata && langdata.ViewNotification ? langdata.ViewNotification : 'View Notification'} onClick={openNotificationModal}><EyeOutlined /></a>
					<a title={langdata && langdata.DeleteNotification ? langdata.DeleteNotification : 'Delete Notification'} onClick={showDeleteNotificationModal}><DeleteOutlined /></a>
				</Space>
			)
		}
	];

	const openNotificationModal = () => {
		setShowNotificationModal(true);
	};

	const closeNotificationModal = () => {
		setShowNotificationModal(false);
	};
  
	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		if(permission == 'superadmin' || permission == 'admin'){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
		}
		loadTLPs();
	}, [regionId, allocationId]);

	useEffect(() => {
		if(featuresModule.notificationsEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		loadNotifications(1);
	}, [regionId, tlpId, date]);

	let regions = [ { value: null, label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`} ];
	let tlps = [ { value: null, label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`} ];

	if(permission == 'superadmin' || permission == 'admin'){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regioninfo.regionName +'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	data && data.tlps && data.tlps.map((tlp) => {
		return tlps.push({ value: tlp.deviceId, label: tlp.pipelineChainage.toString() });
	});

	const handleChangeRegion = (e,options) => {
		if(permission == 'superadmin' || permission == 'admin') {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
			// setStartCh(options.pipelineConfigFrom);
			// setEndCh(options.pipelineConfigTo);
		}
	};

	const handleChangeTLP = (e) => {
		localStorage.setItem(`${dashboardtype}_TLP_ID`, e);
		setTLPId(e);
	};

	const handleDateChange = (date, dateString) => {
		try {
			let newDate = dayjs(dateString);

			if (!dateString || dateString.length === 0) {
				newDate = dayjs();

				setDate(newDate);
			} else {
				setDate(newDate);
			}
		} catch(e) {
			console.log('# New date is invalid');
		}
	};

	const loadTLPs = () => {
		dispatch({ type: TLPS_LIST });

		const limit = 10000;
		const skip = 0;
    
		let allocationParams = '';
		if(admin_roles.includes(permission)){
			allocationParams = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			allocationParams = allocationId ? `&allocationId=${allocationId}` : '';
		}

		let url = `${api}/smarttlp-list?${allocationParams}&$limit=${limit}&$skip=${skip}&$sort[pipelineChainage]=-1`;

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: TLPS_LIST_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: TLPS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: TLPS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadNotifications = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		let url = `${api}/notification-list?$limit=${_pagination.limit}&$skip=${_pagination.skip}`;

		if (tlpId !== null) {
			url += `&deviceId=${tlpId}`;
		}

		if (date) {
			let utc = require('dayjs/plugin/utc');

			dayjs.extend(utc);

			let startDate = dayjs(date).utc(true).format('YYYY-MM-DD 00:00:00');
			let enddDate = dayjs(date).utc(true).format('YYYY-MM-DD 23:59:59');

			url += `&createdAt[$gt]=${startDate}&&createdAt[$lt]=${enddDate}`;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			}
		};
    
		dispatch({ type: NOTIFICATIONS_LIST });
    
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type:NOTIFICATIONS_LIST_SUCCESS, payload: response.data, page:pagination.current});
				} else {
					dispatch({type:NOTIFICATIONS_LIST_ERROR});  
				}
			})
			.catch((error) => {
				dispatch({type:NOTIFICATIONS_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const loadNotificationDetails = ((id) => {
		let details = null;

		dispatch({ type: NOTIFICATION_DETAILS });
    
		if (data && data.notifications) {
			data.notifications && data.notifications.map((item) => {
				if (item.id === id) {
					details = item;
				}
				return true;
			});
      
			dispatch({ type: NOTIFICATION_DETAILS_SUCCESS, payload: details });
		} else {
			dispatch({ type: NOTIFICATION_DETAILS_ERROR });
		}
	});

	const showDeleteNotificationModal = () => {
		setOpenDeleteNotificationModal(true);
	};

	const cancelDeleteNotification = () => {
		setOpenDeleteNotificationModal(false);
	};

	const deleteNotification = () => {
		const id = parseInt(data.details.id);
    
		dispatch({ type: DELETE_NOTIFICATION });

		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/notification/${id}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			}
		};

		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type: DELETE_NOTIFICATION_SUCCESS, payload:response});
					setOpenDeleteNotificationModal(false);  
					loadNotifications(1);        
				} else {
					dispatch({type: DELETE_NOTIFICATION_ERROR});  
				}
			})
			.catch((error) => {
				dispatch({type: DELETE_NOTIFICATION_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.SmartTLPnotificationsbasedontheTLPRuleenginewillbedisplayedhere ? langdata.SmartTLPnotificationsbasedontheTLPRuleenginewillbedisplayedhere : 'Smart TLP notifications based on the TLP Rule engine will be displayed here.'}</p>
		</Card>
	);
  
	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={8}>
						<Row>
							<Space>
								<ColumnWidthOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> { langdata && langdata.Notifications ? langdata.Notifications : 'Notifications' }</Title>
						</Row>
					</Col>
					
					<Col span={16}>
						<Row justify="end">
							<Col>
								{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Region:</Text> */}
								<Tooltip placement="bottom" title={ langdata && langdata.Filterbyregion ? langdata.Filterbyregion : 'Filter by region'}>
									<Space>
										<Select
											style={{ width: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={ langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
											optionFilterProp="children"
											defaultValue={null}
											// value={regionId}
											options={regions}
											onSelect={(e, options)=>handleChangeRegion(e, options)}
										/>
									</Space>
								</Tooltip>
								{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Chainage:</Text> */}
								<Tooltip placement="bottom" title={ langdata && langdata.Filterbychainage ? langdata.Filterbychainage : 'Filter by chainage'}>
									<Space>
										<Select
											style={{ width: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={ langdata && langdata.SelectChainage ? langdata.SelectChainage : 'Select Chainage'}
											optionFilterProp="children"
											defaultValue={'ALL'}
											value={tlpId}
											options={ tlps }
											onSelect={handleChangeTLP}
										/>
									</Space>

								</Tooltip>
								{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Date:</Text> */}
								<Tooltip placement="bottom" title={ langdata && langdata.Filterbydate ? langdata.Filterbydate : 'Filter by date'}>
									<Space>
										<DatePicker
											allowClear={false}
											style={{margin:'5px'}} 
											format={dateFormat}
											value={date}
											defaultValue={dayjs()}
											onChange={handleDateChange}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={ langdata && langdata.Notifications ? langdata.Notifications : 'Notifications'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data.notifications && data.notifications.length > 0 ? (
							<Table 
								scroll={{
									x: 900,
								}}
								columns={columns} 
								dataSource={data.notifications} 
								pagination = {data.notifications.length > 0 ? data.pagination : 0 }
								onChange = { loadNotifications }
								onRow = {(record) => {
									return {
										onClick: () => { 
											loadNotificationDetails(record.id);
										}
									};
								}} />
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>
			</Content>

			<Spin fullscreen spinning={data.pending} />

			{/* Notification Details Modal Starts */}
			<Modal
				title={ langdata && langdata.NotificationDetails ? langdata.NotificationDetails : 'Notification Details'}
				centered
				destroyOnClose={true}
				open={showNotificationModal}
				onOk={{closeNotificationModal}}
				onCancel={closeNotificationModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button key="submit" type="primary" onClick={closeNotificationModal}>
              CLOSE
						</Button>
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical">
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item name="chainageId" label={ langdata && langdata.Chainage ? langdata.Chainage : 'Chainage'} >
								<Input disabled={true} placeholder={ langdata && langdata.Chainage ? langdata.Chainage : 'Chainage'}  defaultValue={ data.details && data.details.deviceId && getChainage(data.details.deviceId)} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="deviceId" label={ langdata && langdata.DeviceID ? langdata.DeviceID : 'Device ID'} >
								<Input disabled={true} placeholder={ langdata && langdata.DeviceID ? langdata.DeviceID : 'Device ID'} defaultValue={ data.details && data.details.deviceId} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item name="notificationType" label={ langdata && langdata.NotificationType ? langdata.NotificationType : 'Notification Type'} >
								<Input disabled={true} placeholder={ langdata && langdata.NotificationType ? langdata.NotificationType : 'Notification Type'} defaultValue={ data.details && data.details.notificationType && getRuleType(data.details.notificationType)} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="createdAt" label={ langdata && langdata.Date ? langdata.Date : 'Date' }>
								<Input disabled={true} placeholder={ langdata && langdata.Date ? langdata.Date : 'Date' }  defaultValue={ data.details && data.details.createdAt} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item name="message" label={ langdata && langdata.Message ? langdata.Message : 'Message'} >
								<TextArea disabled={true} rows="5" placeholder={ langdata && langdata.Message ? langdata.Message : 'Message'} defaultValue={ data.details && data.details.message} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* Notification Details Modal Ends */}
            
			{/* Delete Modal Starts */}
			<Modal title={ langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={openDeleteNotificationModal} onOk={deleteNotification} onCancel={cancelDeleteNotification}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>
						{ langdata && langdata.Areyousureyouwanttodeletethisnotification ? langdata.Areyousureyouwanttodeletethisnotification : 'Are you sure you want to delete this notification?'}
					</Col>
				</Row>
			</Modal>
			{/* Delete Modal Ends */}
		</>
	);
}