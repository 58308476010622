import { DAS_SOCKET_DATA, DTS_SOCKET_DATA, LEAK_DETECTION_DAS_ZONE, LEAK_DETECTION_DAS_ZONE_ERROR, LEAK_DETECTION_DAS_ZONE_SUCCESS, LEAK_DETECTION_DTS_ZONE, LEAK_DETECTION_DTS_ZONE_ERROR, LEAK_DETECTION_DTS_ZONE_SUCCESS, LEAK_DETECTION_GRAPH_CONFIG } from './constants';


export default function leakDatareducer(state, action) {

	switch (action.type) {
	case DTS_SOCKET_DATA : 	
		return { ...state, dtsData: action.payload };
	case DAS_SOCKET_DATA : 	
		return { ...state, dasData: action.payload };
	case LEAK_DETECTION_DAS_ZONE :
		return { ...state, loading : true, error : false};
	case LEAK_DETECTION_DAS_ZONE_SUCCESS :
		return { ...state, loading : false, error : false, das_data : action.payload};
	case LEAK_DETECTION_DAS_ZONE_ERROR :
		return { ...state, loading : false, error : true};
	case LEAK_DETECTION_DTS_ZONE :
		return { ...state, loading : true, error : false};
	case LEAK_DETECTION_DTS_ZONE_SUCCESS :
		return { ...state, loading : false, error : false, dts_data : action.payload};
	case LEAK_DETECTION_DTS_ZONE_ERROR :
		return { ...state, loading : false, error : true};
	case LEAK_DETECTION_GRAPH_CONFIG :
		return { ...state, graphConfig : action.payload && action.payload.data ? action.payload.data[0] : {} };
	default: 
		throw Error('Unknown action: ' + action.type);
      
	}
}