
import React, { useEffect, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';

import { Layout, Menu, theme, Typography, Row, Col, Modal, notification, Space, Tooltip, Button, Select} from 'antd';
import { DashboardOutlined, PicRightOutlined, FileProtectOutlined,
	FileTextOutlined, RedoOutlined, FundProjectionScreenOutlined, ProfileOutlined,
	AlertOutlined, SecurityScanOutlined, UserOutlined, LogoutOutlined, FundViewOutlined, LineChartOutlined, 
	TableOutlined, BlockOutlined, ToolOutlined, TeamOutlined, BellOutlined, SettingOutlined, NodeIndexOutlined, SplitCellsOutlined, FileSearchOutlined, InfoCircleOutlined, AreaChartOutlined,
	HistoryOutlined, AimOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import LoginContextProvider from './pages/Context';

import logo from '../src/images/logo.png';
import smallLogo from '../src/images/small_logo.png';
import axios from 'axios';
import NotificationSound from './Notification.mp3';
import englan from '../src/english.json';
import germanlan from '../src/german.json';
import arabiclan from '../src/arabic.json';
import frenchlan from '../src/french.json';
import hindilan from '../src/hindi.json';
import malayalamlan from '../src/malayalam.json';


const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;

function getItem(label, key, icon, children) {
	return {
		key,
		icon,
		children,
		label
	};
}

const options=[
	{
		value: 1,
		label: 'عربي',
	},
	{
		value: 2,
		label: 'English',
	},
	{
		value: 3,
		label: 'français',
	},
	{
		value: 4,
		label: 'Deutsch',
	},
	
	{
		value: 5,
		label: 'हिंदी',
	},
	// {
	// 	value: 6,
	// 	label: 'മലയാളം',
	// },
	

];

const App = () => {
	
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const [collapsed, setCollapsed] = useState(false);
	const [activeMenu, setActiveMenu] = useState(null);
	const [openConfirmLogout, setOpenConfirmLogout] = useState(false);
	const navigate = useNavigate();
	const lastsync = localStorage.getItem(`${dashboardtype.toUpperCase()}_LAST_ALARM_NOTIFICATION`);
	const [newNotification, setNewNotification] = useState();
	// const lastAlarmTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_LAST_ALARM_TIME`);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const getregionId = localStorage.getItem(`${dashboardtype.toUpperCase()}_REGION_ID`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envtlpEnable = process.env.REACT_APP_TLP == undefined || process.env.REACT_APP_TLP == 'undefined' ? 'disabled' : process.env.REACT_APP_TLP;
	const tlpEnable = localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`) == 'null' ? envtlpEnable : localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_TLP`);
	// console.log('getregionId', getregionId);
	const alarmNavigation =()=>{
		navigate('/realtime-alarm');
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 8);
	};

	const envRefreshTime = process.env.REACT_APP_REFRESH_TIME == undefined || process.env.REACT_APP_REFRESH_TIME == 'undefined' ? 180000 : process.env.REACT_APP_REFRESH_TIME;
	const refreshTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`) == null ? parseInt(envRefreshTime) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`));
	const envstrgeedge = process.env.REACT_APP_EDGE == undefined || process.env.REACT_APP_EDGE == 'undefined' ? 'false' : process.env.REACT_APP_EDGE;
	const isEdge = localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_EDGE`) == 'null' ? envstrgeedge : localStorage.getItem(`${dashboardtype.toUpperCase()}_APP_EDGE`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const [statelangid, setLangId] = useState(langid);
	const activemenuid = localStorage.getItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM');

	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));
	const [stateOpenKeys, setStateOpenKeys] = useState([]);

	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';

	const btn = (
		<Space>
			<Button type="link" size="small" onClick={() =>alarmNavigation()}>
				{langdata && langdata ? langdata.ShowAll :  'Show All'}
			</Button>
		</Space>
	);

	const items = [
		getItem(`${langdata && langdata ? langdata.Dashboard : 'Dashboard'}`, '1', <Link to={'dashboard'}><DashboardOutlined /></Link>),
	
		(dashboardtype!=='oneview' || featuresModule.realtimeEventsEnabled == false ? '' : 
			getItem(`${langdata && langdata ? langdata.RealtimeEvents : 'Realtime Events'}`, '120', <Link to={'realtime-events'}> <SecurityScanOutlined /></Link>)),
	
		(dashboardtype=='tlp' || featuresModule.realtimeAlarmsEnabled == false ? '' : 
			getItem(`${langdata && langdata ? langdata.RealtimeAlarms : 'Realtime Alarms'}`, '8', <Link to={'realtime-alarm'}><AlertOutlined /></Link>)),
	
	
		(dashboardtype=='tlp' || (featuresModule.trackMyPIGEnabled == false && featuresModule.trackMyLWEnabled == false) || oneviewType == 'well' ? '' : 
			getItem(`${langdata && langdata ? langdata.Track : 'Track'}`, '3',<FundProjectionScreenOutlined />,[
				(dashboardtype!=='oneview' || featuresModule.trackMyPIGEnabled == false || oneviewType == 'border' || oneviewType =='airport' ? '' : 
					getItem(`${langdata && langdata ? langdata.MyPIG : 'My PIG'}`, '31',<Link to={'myPig'}><FundProjectionScreenOutlined /></Link>)),
				(featuresModule.trackMyLWEnabled == false  ? '' : 
					getItem(`${langdata && langdata ? langdata.MyLinewalker : 'My Linewalker'}`, '32', <Link to={'mylinewalker'}><FundProjectionScreenOutlined /></Link>)),
			])),
	
		(dashboardtype=='tlp' || (featuresModule.reportEventsEnabled == false && featuresModule.reportAlarmsEnabled == false && featuresModule.reportPatchCoverageEnabled == false && featuresModule.reportVulnerableEnabled == false && featuresModule.reportGradeSystemEnabled == false ) ? '' : 
			getItem(`${langdata && langdata ? langdata.Report : 'Report'}`, '6', <ProfileOutlined />,[
				(dashboardtype!=='oneview'|| featuresModule.reportEventsEnabled == false ? '' : 
					getItem(`${langdata && langdata ? langdata.Events : 'Events'}`, '61', <Link to={'events-report'}><FileSearchOutlined /></Link>)),
				(featuresModule.reportAlarmsEnabled == false ? '' : 
					getItem(`${langdata && langdata ? langdata.Alarms : 'Alarms'}`, '62', <Link to={'alarm-report'}><FileSearchOutlined /></Link>)),
				(featuresModule.reportPatchCoverageEnabled == false || oneviewType == 'well' ? '' :
					getItem(`${langdata && langdata ? langdata.PatchCoverage : 'Patch Coverage'}`, '63', <Link to={'patch'}><FileSearchOutlined /></Link>)),
				(featuresModule.reportVulnerableEnabled == false || oneviewType == 'well'? '' :
					getItem(`${langdata && langdata ? langdata.Vulnerable : 'Vulnerable'}`, '64', <Link to={'vulnerable'}><FileSearchOutlined /></Link>)),
				(featuresModule.reportGradeSystemEnabled == false || oneviewType == 'well' ? '' :
					getItem(`${langdata && langdata ? langdata.GradeSystem : 'Grade System'}`, '65', <Link to={'grade-system'}><FileSearchOutlined /></Link>))
			] )),
	
		(dashboardtype =='oneview' && (featuresModule.retrainingDataEnabled == true || featuresModule.retrainingSimulationsEnabled == true || featuresModule.retrainingHistoryEnabled == true || featuresModule.retrainingModelInfoEnabled == true || featuresModule.retrainingModelConfigEnabled == true ) ? 
			getItem(`${langdata && langdata ? langdata.Retraining : 'Retraining'}`, '113', <RedoOutlined />,[
				featuresModule.retrainingDataEnabled == false ? '' :
					getItem(`${langdata && langdata ? langdata.RetrainingData : 'Retraining Data'}`, '1131', <Link to={'retraining-data'}><RedoOutlined /></Link>),
				featuresModule.retrainingSimulationsEnabled == false ? '' :
					getItem(`${langdata && langdata ? langdata.Simulations : 'Simulations'}`, '1133', <Link to={'simulations'}><AimOutlined /></Link>),
				featuresModule.retrainingHistoryEnabled == false ? '' :
					getItem(`${langdata && langdata ? langdata.RetrainingHistory : 'Retraining History'}`, '1132', <Link to={'retrain-history'}><HistoryOutlined /></Link>), 
				permission==='superadmin' &&  featuresModule.retrainingModelInfoEnabled == true ?
					getItem(`${langdata && langdata ? langdata.ModelInfo : 'Model Info'}`, '1134', <Link to={'model-config'}><InfoCircleOutlined/></Link>)
					: '',
				permission==='superadmin' &&  featuresModule.retrainingModelConfigEnabled == true ?
					getItem(`${langdata && langdata ? langdata.ModelConfig : 'Model Config'}`, '1135', <Link to={'model-configuration'}><ToolOutlined /></Link>)
					: '',
				// permission==='superadmin' ?
				// 	getItem('Model Classification', '1136', <Link to={'model-classification'}><RedoOutlined/></Link>)
				// 	: '',
			] )

			: null
		),
	
		(dashboardtype=='tlp' || featuresModule.inspectionsEnabled == false || oneviewType == 'well' ? '' : 
			getItem(`${langdata && langdata ? langdata.Inspections : 'Inspections'}`, '66', <Link to={'inspections'}><FundViewOutlined /></Link>)),
	
		(dashboardtype!=='oneview' || (featuresModule.eventAnalyticsEnabled == false && featuresModule.alarmAnalyticsEnabled == false) ? '' : 
			getItem(`${langdata && langdata ? langdata.Analytics : 'Analytics'}`, '15',<FileTextOutlined />,[
				featuresModule.eventAnalyticsEnabled == false ? '' :
					getItem(`${langdata && langdata ? langdata.EventAnalytics : 'Event Analytics'}`, '152', <Link to={'event-analytics'}><FileTextOutlined /></Link>),
				featuresModule.alarmAnalyticsEnabled == false ? '' :
					getItem(`${langdata && langdata ? langdata.AlarmAnalytics : 'Alarm Analytics'}`, '151',<Link to={'alarm-analytics'}><FileTextOutlined /></Link>),
			])),
	
		(dashboardtype=='tlp' || (featuresModule.chainageFilterEnabled == false && featuresModule.vulnerablePointsEnabled == false && featuresModule.snoozedPointsEnabled == false && featuresModule.terrainsEnabled == false) ? '' : 
			getItem(`${langdata && langdata ? langdata.Chainage : 'Chainage'}`, '9', <FileProtectOutlined />,
				[
					featuresModule.chainageFilterEnabled == false ? '' :
						getItem(`${langdata && langdata ? langdata.ChainageFilter : 'Chainage Filter'}`, '91', <Link to={'chainage-filter'}><FileProtectOutlined /></Link>),
					featuresModule.vulnerablePointsEnabled == false ? '' :
						getItem(`${langdata && langdata ? langdata.VulnerablePoints : 'Vulnerable Points'}`, '92', <Link to={'vulnerable-points'}><FileProtectOutlined /></Link>),
					(dashboardtype === 'sts' || featuresModule.snoozedPointsEnabled == false ? '' :
						getItem(`${langdata && langdata ? langdata.SnoozedPoints : 'Snoozed Points'}`, '93', <Link to={'snoozed-points'}><FileProtectOutlined /></Link>)),
					(dashboardtype === 'sts' || featuresModule.terrainsEnabled == false ? '' :
						getItem(`${langdata && langdata ? langdata.Terrains : 'Terrains'}`, '94', <Link to={'terrain-classification'}><FileProtectOutlined /></Link>))
				])),
	
		(((dashboardtype==='oneview' && tlpEnable==='enabled') || dashboardtype === 'tlp' ) && (featuresModule.realtimeCPSEnabled == true || featuresModule.fullCPSEnabled == true || featuresModule.observeCPSEnabled == true || featuresModule.dailyCPSEnabled == true ||featuresModule.cpsReportEnabled == true ) && oneviewType != 'well'? 
			getItem(`${langdata && langdata ? langdata.SmartCP : 'Smart CP'}`, '2', <BlockOutlined />, [
				featuresModule.realtimeCPSEnabled == false ? '' :
					getItem(`${langdata && langdata ? langdata.RealtimeCPS : 'Realtime CPS'}`, '21', <Link to={'realtime-cps'}><FundViewOutlined /></Link>),
				featuresModule.fullCPSEnabled == false ? '' :
					getItem(`${langdata && langdata ? langdata.FullCPS : 'Full CPS'}`, '22', <Link to={'full-cps-report'}><LineChartOutlined /></Link>),
				featuresModule.observeCPSEnabled == false ? '' :
					getItem(`${langdata && langdata ? langdata.ObserveCPS : 'Observe CPS'}`, '23', <Link to={'observe-cps-report'}><LineChartOutlined /></Link>),
				featuresModule.dailyCPSEnabled == false ? '' :
					getItem(`${langdata && langdata ? langdata.DailyCPS : 'Daily CPS'}`, '24', <Link to={'daily-cps-report'}><LineChartOutlined /></Link>),
				featuresModule.cpsReportEnabled == false ? '' :
					getItem(`${langdata && langdata ? langdata.CPSReport : 'CPS Report'}`, '25', <Link to={'cps-report'}><TableOutlined /></Link>),
			]):''),
		
		featuresModule.allocationsEnabled == false ? '' :
			getItem(`${langdata && langdata ? langdata.Allocations : 'Allocations'}`, '5', <Link to={'allocations'}><UserOutlined /></Link>),

		featuresModule.wellVisualizationEnabled == false || oneviewType !== 'well' ? '' :
			getItem(`${langdata && langdata ? langdata.WellVisualization : 'Well Visualization'}`, '116', <Link to={'well-visualization'}><AreaChartOutlined /></Link>),
	
		(featuresModule.userMgmtEnabled == true || featuresModule.speedClassificationEnabled == true ||  featuresModule.tlpMgmtEnabled == true ||
		featuresModule.tlpRuleEngineEnabled == true || featuresModule.mailConfigEnabled == true || featuresModule.escalationMatrixEnabled == true ||
		featuresModule.pidsMgmtEnabled == true || featuresModule.alarmRuleEngineEnabled == true || featuresModule.eventTypesEnabled == true ||
		featuresModule.fiberSplicingEnabled == true || featuresModule.channelInfoEnabled == true || featuresModule.regionMgmtEnabled == true ||
		featuresModule.gradeMgmtEnabled == true || featuresModule.edgeMgmtEnabled == true || featuresModule.wellVisualizationEnabled == true || featuresModule.wellTypeManagementEnabled == true ?
			getItem(`${langdata && langdata ? langdata.Configuration : 'Configuration'}`, '4', <ToolOutlined />, [
				featuresModule.userMgmtEnabled == false ? '' :
					getItem(`${langdata && langdata ? langdata.UserManagement : 'User Management'}`, '41', <Link to={'user-management'}><TeamOutlined /></Link>),
				((dashboardtype === 'sts' || dashboardtype === 'oneview') && (permission==='superadmin' || permission === 'admin') && featuresModule.speedClassificationEnabled == true && oneviewType != 'well' ?
					getItem(`${langdata && langdata ? langdata.SpeedClassification : 'Speed Classification'}`, '44', <Link to={'speed-classification'}><ToolOutlined /></Link>):'') ,
		
				(dashboardtype === 'tlp' || (dashboardtype==='oneview' && tlpEnable==='enabled') && featuresModule.tlpMgmtEnabled == true && oneviewType != 'well' ? 
					getItem(`${langdata && langdata ? langdata.TLPManagement : 'TLP Management'}`, '42', <Link to={'tlp-management'}><ToolOutlined /></Link>) : ''
				),
	
				(dashboardtype === 'tlp' || (dashboardtype==='oneview' && tlpEnable==='enabled')  && featuresModule.tlpRuleEngineEnabled == true && oneviewType != 'well' ? 
					getItem(`${langdata && langdata ? langdata.TLPRuleEngine : 'TLP Rule Engine'}`, '43', <Link to={'tlp-rules'}><ToolOutlined /></Link>) : ''
				),

				((dashboardtype === 'sts' || dashboardtype === 'oneview') && (permission==='superadmin' || permission === 'admin') && featuresModule.mailConfigEnabled == true ? 
					getItem(`${langdata && langdata ? langdata.MailConfiguration : 'Mail Configuration'}`, '45', <Link to={'mail-configuration'}><ToolOutlined /></Link>)
					: ''),
			
				(!admin_roles.includes(permission) || dashboardtype == 'tlp' || featuresModule.escalationMatrixEnabled == false ? '' : 
					getItem(`${langdata && langdata ? langdata.EscalationMatrix : 'Escalation Matrix'}`, '10', <Link to={'escalationmatrix'}><PicRightOutlined /></Link>)),

				(dashboardtype !== 'oneview' || featuresModule.pidsMgmtEnabled == false ? '' :  
					getItem(`${langdata && langdata ? langdata.PIDSManagement : 'PIDS Management'}`, '46', <Link to={'pidsmanagement'}> <ToolOutlined /></Link>) 
				),
				(dashboardtype !== 'oneview' || featuresModule.alarmRuleEngineEnabled == false ? '' :  
					getItem(`${langdata && langdata ? langdata.AlarmRuleEngine : 'Alarm Rule Engine'}`, '47', <Link to={'alarmrule'}><ToolOutlined /></Link>) 
				),
				(dashboardtype !== 'oneview' || featuresModule.eventTypesEnabled == false ? '' :  
					permission==='superadmin' ?
						getItem(`${langdata && langdata ? langdata.EventTypes : 'Event Types'}`, '48', <Link to={'eventtypes'}><ToolOutlined /></Link>) : ''
				),
				(dashboardtype !== 'oneview' || featuresModule.fiberSplicingEnabled == false ? '' : 
					getItem(`${langdata && langdata ? langdata.FiberSplicing : 'Fiber Splicing'}`, '49', <Link to={'fiber-management'}><NodeIndexOutlined /></Link>)
				),
				(dashboardtype=='tlp' || dashboardtype === 'sts' || featuresModule.channelInfoEnabled == false ? '' :
					permission==='superadmin' ?
						getItem(`${langdata && langdata ? langdata.ChannelInfo : 'Channel Info'}`, '401', <Link to={'channel-info'}><SplitCellsOutlined /></Link>) : ''),
				featuresModule.regionMgmtEnabled == false ? '' :
					getItem(`${langdata && langdata ? langdata.RegionManagement : 'Region Management'}`, '402', <Link to={'region-management'}><ToolOutlined /></Link>) ,
				((dashboardtype === 'sts' || dashboardtype === 'oneview') && (permission==='superadmin' || permission === 'admin') && featuresModule.gradeMgmtEnabled == true && oneviewType != 'well' ?
					getItem(`${langdata && langdata ? langdata.GradeManagement : 'Grade Management'}`, '403', <Link to={'grademanagement'}><ToolOutlined /></Link>):'') ,
				(dashboardtype =='oneview' &&  isEdge=='true' && featuresModule.edgeMgmtEnabled == true ? 
					getItem(`${langdata && langdata ? langdata.EdgeManagement : 'Edge Management'}`, '115', <Link to={'edgemanagement'}><ToolOutlined /></Link>) : null
				),
				(dashboardtype =='oneview' && featuresModule.wellTypeManagementEnabled == true && oneviewType == 'well' ? 
					getItem(`${langdata && langdata ? langdata.WellTypeManagement : 'Well Type Management'}`, '404', <Link to={'well-type-management'}><ToolOutlined /></Link>) : null
				),
				(dashboardtype =='oneview' && featuresModule.wellTypeManagementEnabled == true && oneviewType == 'well' ? 
					getItem(`${langdata && langdata ? langdata.WellInfo : 'Well Info'}`, '405', <Link to={'well-info'}><ToolOutlined /></Link>) : null
				),
				(dashboardtype =='oneview' && featuresModule.wellTypeManagementEnabled == true && oneviewType == 'well' ? 
					getItem(`${langdata && langdata ? langdata.WellZoneInfo : 'Well Zone Info'}`, '406', <Link to={'well-zone-info'}><ToolOutlined /></Link>) : null
				),
				(dashboardtype =='oneview' && featuresModule.wellTypeManagementEnabled == true && oneviewType == 'well' ? 
					getItem(`${langdata && langdata ? langdata.WellSeismicTypes : 'Well Seismic Types'}`, '407', <Link to={'seismic-type'}><ToolOutlined /></Link>) : null
				),
				(dashboardtype =='oneview' && featuresModule.wellGraphsEnabled == true && oneviewType == 'well' ? 
					getItem(`${langdata && langdata ? langdata.WellGraphs : 'Well Graphs'}`, '408', <Link to={'well-graphs'}><ToolOutlined /></Link>) : null
				),
				(dashboardtype =='oneview' && featuresModule.wellAlgorithmEnabled == true && oneviewType == 'well' ? 
					permission==='superadmin' ?
						getItem(`${langdata && langdata && langdata.WellAlgorithm ? langdata.WellAlgorithm : 'Well Algorithm'}`, '409', <Link to={'well-algorithm'}><ToolOutlined /></Link>) : null : null
				),
			]) : '' ),
	
		// getItem('Notifications', '111', <Link to={'notifications'}><BellOutlined /></Link>) 
		
		(permission==='superadmin' || permission === 'admin' ? 
			getItem(`${langdata && langdata ? langdata.Settings : 'Settings'}`, '112', <Link to={'settings'}><SettingOutlined /></Link>) : '' ),
	
		// getItem('My Profile','114', <Link to={'my-profile'}><UserOutlined /></Link>)
	];

	const {
		token: {  colorBgContainer },
	} = theme.useToken();

	let allocationParams = '';
	if(admin_roles.includes(permission)){
		allocationParams = '&regioninfoId';
	}
	else{
		allocationParams = '&allocationId';
	}

	useEffect(() => {
		const interval = setInterval(() => {
			loadNewlarm();
		}, refreshTime ); 
		return () => clearInterval(interval);
	}, []);

	const selectMenu = (item) => {
		if(item && item.keyPath && item.keyPath.length == 1) {
			setStateOpenKeys([]);
		}

		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , item.key);
		setActiveMenu(item.key);
		if(item.key==61 || item.key==62){
			let keysToRemove = [
				`${dashboardtype.toUpperCase()}_REPORT_ALLOCATION_ID`, `${dashboardtype.toUpperCase()}_REPORT_START_DATE`, `${dashboardtype.toUpperCase()}_REPORT_END_DATE`, `${dashboardtype.toUpperCase()}_REPORT_ACTIVITY`,
				`${dashboardtype.toUpperCase()}_REPORT_PIDS`, `${dashboardtype.toUpperCase()}_REPORT_CHANNEL`, `${dashboardtype.toUpperCase()}_REPORT_START_TIME`, `${dashboardtype.toUpperCase()}_REPORT_END_TIME`,
				`${dashboardtype.toUpperCase()}_REPORT_ANOMALY_FREQUENCY`,`${dashboardtype.toUpperCase()}_REPORT_SEVERITY`, `${dashboardtype.toUpperCase()}_REPORT_RELATED_ALARM`, 
				`${dashboardtype.toUpperCase()}_REPORT_ANOMALY_STATUS`, `${dashboardtype.toUpperCase()}_REPORT_REGION_ID`, `${dashboardtype.toUpperCase()}_REPORT_SEARCH_ALLOCATION_ID`, 
				`${dashboardtype.toUpperCase()}_REPORT_CHANNEL_ID` 
		
			];

			// for (const key of keysToRemove) localStorage.removeItem(key);


			keysToRemove.forEach(key =>
				localStorage.removeItem(key));
		}
		loadNewlarm();
	};

	const showConfirmLogoutModal = () => {
		setOpenConfirmLogout(true);
	};

	const confirmLogout = () => {
		setOpenConfirmLogout(false);
    
		// localStorage.removeItem("companyId")

		let keysToRemove = [`${dashboardtype.toUpperCase()}_companyId`,`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`,`${dashboardtype.toUpperCase()}_pipelineId`, 
			`${dashboardtype.toUpperCase()}_REGION_ID`, `${dashboardtype.toUpperCase()}_USER_ID`, `${dashboardtype.toUpperCase()}_PERMISSION`, `${dashboardtype.toUpperCase()}_ACTIVE_MENU_ITEM`, 
			`${dashboardtype.toUpperCase()}_FEATURES`, `${dashboardtype.toUpperCase()}_ALLOCATION_ID`, `${dashboardtype.toUpperCase()}_MAP_TYPE`, `${dashboardtype.toUpperCase()}_PIDS`,`${dashboardtype.toUpperCase()}_PAGE`,
			`${dashboardtype.toUpperCase()}_PAGE_SIZE`, `${dashboardtype.toUpperCase()}_API_URL`, `${dashboardtype.toUpperCase()}_PIPELINE_NAME`, `${dashboardtype.toUpperCase()}_APP_IDENTIFIER`,
			`${dashboardtype.toUpperCase()}_BATCH_SIZE`, `${dashboardtype.toUpperCase()}_DATE_FORMAT`, `${dashboardtype.toUpperCase()}_TIME_FORMAT`, `${dashboardtype.toUpperCase()}_APP_EDGE`,
			`${dashboardtype.toUpperCase()}_APP_TLP`, `${dashboardtype.toUpperCase()}_DASHBOARD_VERSION`, `${dashboardtype.toUpperCase()}_API_VERSION`, `${dashboardtype.toUpperCase()}_TIMEOUT`, 
			`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`, `${dashboardtype.toUpperCase()}_REFRESH_TIME`,
			`${dashboardtype.toUpperCase()}_REPORT_ALLOCATION_ID`, `${dashboardtype.toUpperCase()}_REPORT_START_DATE`, `${dashboardtype.toUpperCase()}_REPORT_END_DATE`, `${dashboardtype.toUpperCase()}_REPORT_ACTIVITY`,
			`${dashboardtype.toUpperCase()}_REPORT_PIDS`, `${dashboardtype.toUpperCase()}_REPORT_CHANNEL`, `${dashboardtype.toUpperCase()}_REPORT_START_TIME`, `${dashboardtype.toUpperCase()}_REPORT_END_TIME`,
			`${dashboardtype.toUpperCase()}_REPORT_ANOMALY_FREQUENCY`, `${dashboardtype.toUpperCase()}_REPORT_SEVERITY`, `${dashboardtype.toUpperCase()}_REPORT_RELATED_ALARM`, 
			`${dashboardtype.toUpperCase()}_REPORT_ANOMALY_STATUS`, `${dashboardtype.toUpperCase()}_CHANNEL_COUNT`, `${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`, `${dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA'}`,
			`${dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_CARD_INDEX_ARRAY'}`, `${dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA_OBJECT' }`
		
		];

		// for (const key of keysToRemove) localStorage.removeItem(key);


		keysToRemove.forEach(key =>
			localStorage.removeItem(key));

		navigate('/');
    
	};

	useEffect(() => {
		const currentMenuItem = localStorage.getItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM');

		if (currentMenuItem) {
			setActiveMenu(currentMenuItem);
		} else {
			setActiveMenu('1');
		}
	},[langid,langdata, statelangid, activeMenu, activemenuid]);


	useEffect(() => {
		if(dashboardtype!='tlp'){

			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/newalarmnotification?astsync=${lastsync}${allocationParams}=${getregionId}`,
				// timeout: process.env.REACT_APP_TIMEOUT,
				headers: { 
					Authorization:`Bearer ${authtoken}`  
				}
			};
		
			axios.request(config)
				.then((response) => {
					if(response.status === 200) {
						setNewNotification(response.data);
						localStorage.setItem(`${dashboardtype.toUpperCase()}_LAST_ALARM_TIME`, response.data && response.data[0].datetime);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	},[authtoken]);

	const loadNewlarm = () => {

		if(dashboardtype!='tlp'){

			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/newalarmnotification?astsync=${lastsync}${allocationParams}=${getregionId}`,
				// timeout: process.env.REACT_APP_TIMEOUT,
				headers: { 
					Authorization:`Bearer ${authtoken}`  
				}
			};
		
			axios.request(config)
				.then((response) => {
					if(response.status === 200) {
						setNewNotification(response.data);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
		
	};

	useEffect(() => {
		const lastAlarmTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_LAST_ALARM_TIME`);
		if(newNotification){
			if(newNotification && newNotification[0].datetime > lastAlarmTime) {
				notification.warning({
					message: `${langdata && langdata ? langdata.Newalarmsinlast30minutes :  'New alarms in last 30 minutes'}`,
					description: `${newNotification.length} ${langdata && langdata ? langdata.newalarms:'new alarms'}`,
					btn
				});
				document.getElementById('notification').muted = false;
				document.getElementById('notification').play();
				localStorage.setItem(`${dashboardtype.toUpperCase()}_LAST_ALARM_TIME`, newNotification && newNotification[0].datetime);
			} 
		}
	},[newNotification]);

	const cancelLogout = () => {
		setOpenConfirmLogout(false);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handleLang = (val)=>{
		setLangId(val);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_LANGUAGE_ID' , val); 
	};

	const getLevelKeys = (items1) => {
		const key = {};
		const func = (items2, level = 1) => {
			items2.forEach((item) => {
				if (item && item.key) {
					key[item.key] = level;
				}
				if (item && item.children) {
					func(item.children, level + 1);
				}
			});
		};
		func(items1);
		return key;
	};
	const levelKeys = getLevelKeys(items);

	const onOpenChange = (openKeys) => {
		const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
		// open
		if (currentOpenKey !== undefined) {
			const repeatIndex = openKeys
				.filter((key) => key !== currentOpenKey)
				.findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
			setStateOpenKeys(
				openKeys
				// remove repeat key
					.filter((_, index) => index !== repeatIndex)
				// remove current level all child
					.filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
			);
		} else {
		// close
			setStateOpenKeys(openKeys);
		}
	};
	
	// console.log('stateOpenKeys', stateOpenKeys);
	return (
		<>
			<LoginContextProvider>
				<Layout
					style={{
						minHeight: '100vh',
					}}
				>
					{/* LHS Starts */}
					<Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} width={220} breakpoint="xl">
						<div onClick={() => { navigate('/dashboard');
							localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '1'); 
						}} className="demo-logo-vertical" style={{ paddingTop: '20px', paddingBottom: '20px', textAlign: 'center'}}>
							{collapsed === false ?
								<img style={{ cursor: 'pointer'}} src={logo} width="120px"/>
								: 
								<img style={{ cursor: 'pointer'}} src={smallLogo} width="50px"/>
							}
						</div>
						<Menu theme="dark" selectedKeys={[activeMenu]} mode="inline" items={items} onClick={selectMenu} openKeys={stateOpenKeys} onOpenChange={onOpenChange}/>
						<audio id="notification" src={NotificationSound} muted></audio>
					</Sider>
					{/* LHS Ends */}
					<Layout>
						{/* Header Starts */}
						<Header
							style={{
								padding: 0,
								background: colorBgContainer,
							}}
						>
							<Content
								style={{
									background: colorBgContainer,
								}}
							>
								<Row style={{
									paddingLeft: '10px',
									paddingRight: '10px',
									background: '#FFFFFF',
									alignItems:'center'
								}} >
									<Col span={8} style={{border: '0px solid red'}}>
									</Col>
									<Col span={8} style={{border: '0px solid red'}}>
										<Title level={3} style={{ marginTop: '5px'}}>
											<center>{langdata && langdata ? langdata.OneViewPro: 'OneView Pro'}</center>
										</Title>
									</Col>
									<Col span={8} style={{border: '0px solid red'}}>
										<Row justify="end" >
											<Tooltip placement="bottom" title={langdata && langdata ? langdata.Languages: 'Languages'}>
												<Space>
													<Col style={{ paddingRight: '10px'}}>
														<Select Item
															style={{width:'180px'}}
															showSearch
															filterOption={filterOption}
															placeholder="Select Language"
															optionFilterProp="children"
															onSelect={(e)=>handleLang(e)}
															defaultValue={langid}
															options={options}
														/>															
													</Col>
												</Space>
											</Tooltip>
											<Space>&nbsp;&nbsp;</Space>
											<Space>&nbsp;&nbsp;</Space>
											{((dashboardtype==='oneview' && tlpEnable==='enabled') || dashboardtype === 'tlp') && (featuresModule.notificationsEnabled == true) ?
												<Tooltip placement="bottom" title={langdata && langdata ? langdata.Notifications:'Notifications'}>
													<Space>
														<Col style={{ paddingRight: '10px' }}>
															<a>
																<BellOutlined style={{ fontSize: '1.5em'}} onClick={() => { navigate('/notifications');
																	localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '0'); 
																}}/>
																{/* <Menu items={menuItems} style={{ fontSize: '1.5em'}}/> */}
															</a>
														</Col>
													</Space>
												</Tooltip>
												: null }
											<Space>&nbsp;&nbsp;</Space>
											<Space>&nbsp;&nbsp;</Space>
											<Tooltip placement="bottom" title={langdata && langdata ? langdata.Profile:'Profile'}>
												<Space>
													<Col style={{ paddingRight: '10px' }}>
														<a>
															<UserOutlined style={{ fontSize: '1.5em'}} onClick={() => { navigate('/my-profile'); 
																localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '0');
															}}/>
															{/* <Menu items={menuItems} style={{ fontSize: '1.5em'}}/> */}
														</a>
													</Col>
												</Space>
											</Tooltip>
											<Space>&nbsp;&nbsp;</Space>
											<Space>&nbsp;&nbsp;</Space>
											<Tooltip placement="bottom" title={langdata && langdata ? langdata.Logout:'Logout'}>
												<Col >
													<a onClick={showConfirmLogoutModal}>
														<LogoutOutlined style={{ fontSize: '1.5em', paddingRight:'15px'}}/>
													</a>
												</Col>
											</Tooltip>
										</Row>
									</Col>
								</Row>
								
							</Content>
						</Header>
						{/* Header Ends */}

						{/* Content Starts */}
						<Outlet />
						{/* Content Ends */}
        
						{/* Footer Starts */}
						<Footer
							style={{
								textAlign: 'center',
							}}
						>
							<div>
								<span style={{ fontSize: '15px', display: 'inline-flex', textAlign: 'center', alignItems: 'center' }}>
									{langdata && langdata ? langdata.Poweredby: 'Powered by'} &nbsp;
									<img src="https://tlp.tranzmeo.com/static/media/logo.86b1848d747a5c7f098c.png" width="120px" />
								</span>
							</div>
							<small>{langdata && langdata ? langdata.OneViewProVersion :'OneView Pro Version'} : {process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '1.0.0'} | {langdata && langdata ? langdata.APIVersion : 'API Version'} : {process.env.REACT_APP_API_VERSION ? process.env.REACT_APP_API_VERSION : '1.0.0'}</small>
						</Footer>
						{/* Footer Ends */}
					</Layout>
				</Layout>

				{/* Confirm Logout  Modal Starts */}
				<Modal title={langdata && langdata ? langdata.ConfirmLogout : 'Confirm Logout'} open={openConfirmLogout} onOk={confirmLogout} onCancel={cancelLogout}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Button onClick={cancelLogout}>
							{langdata && langdata ? langdata.Cancel : 'Cancel'}
						</Button>,
						// eslint-disable-next-line react/jsx-key
						<Button type="primary" onClick={confirmLogout}>
							{langdata && langdata ? langdata.OK : 'OK'}
						</Button>,
					// <Button type="primary" onClick={()=>handleModelOk(data.realtime_events_details.id)}>
					//   More info
					// </Button>,
					// eslint-disable-next-line react/jsx-key
					// 				<Button type="primary" onClick={() => {navigate(( '/eventDetails'),{ state: { data: data.realtime_events_details, path: 'realtime-events' } });}}>
					//   More info
					// 				</Button>,
					]}
				>
					<Row style={{
						marginTop: '10px',
						marginBottom: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						
						<Col span={24}>{langdata && langdata ? langdata.LogoutMesssage : 'Are you sure you want to logout of the system?'}</Col>
					</Row>
				</Modal>
				{/* Confirm Logout  Modal Ends */}
			</LoginContextProvider>
		</>
    
	);
};

export default App;