/* eslint-disable react/react-in-jsx-scope */

import { React, useState, useEffect, useReducer } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import { Button, Col, Form, Input, Row, Space, Typography, Modal, Spin, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';

import myProfileReducer from './reducer.js';
import { CHANGE_PASSWORD, CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_SUCCESS, MY_PROFILE, MY_PROFILE_ERROR, MY_PROFILE_SUCCESS } from './constants.js';
import { LockOutlined, UserOutlined, InfoOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';
import { useAuth } from '../Context.jsx';

const { Title } = Typography;

export default function Settings() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER.toUpperCase();
	const authtoken = localStorage.getItem(`${dashboardtype}_ACCESS_TOKEN`);
	const userId = localStorage.getItem(`${dashboardtype}_USER_ID`);

	const initalState = {profile :  null, pending: false, error: false };

	const [data, dispatch] = useReducer(myProfileReducer, initalState);
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const navigate = useNavigate();

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	useAuth();

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		dispatch({ type : MY_PROFILE });
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/users/${userId}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};
  
		axios.request(config)
			.then((response) => {
				if(response.status === 200){
					dispatch({ type : MY_PROFILE_SUCCESS, payload : response.data});
				} else {
					dispatch({ type : MY_PROFILE_ERROR });
				}
			})
			.catch((error) => {
				dispatch({ type : MY_PROFILE_ERROR });
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	}, [authtoken]);

	const openChangePasswordModal = () => {
		setShowChangePasswordModal(true);
	};

	const closeChangePasswordModal = () => {
		setShowChangePasswordModal(false);
	};

	const changePasswordFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			currentPassword : '',
			newPassword: '',
			confirmPassword: '',
			email : data.profile && data.profile.email ? data.profile.email : null
		},
    
		onSubmit: (values) => {
			dispatch({ type : CHANGE_PASSWORD });
			setShowChangePasswordModal(false);
			// const id = data.details ? data.details.id : 0;
			const newPassword = values.newPassword ? values.newPassword : '';
			const confirmPassword = values.confirmPassword ? values.confirmPassword : '';
			const currentPassword = values.currentPassword ? values.currentPassword : '';
			const emailId = changePasswordFormik.values.email;

			if (!newPassword || newPassword.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythenewpassword ? langdata.Pleasespecifythenewpassword :  'Please specify the new password.' });
				dispatch({type : CHANGE_PASSWORD_ERROR });
				return false;
			}
        
			if (!newPassword || newPassword.length < 8) {
				Modal.warning({ title: langdata && langdata.Pleasespecifyastrongpassword ? langdata.Pleasespecifyastrongpassword : 'Please specify a strong password.' });
				dispatch({type : CHANGE_PASSWORD_ERROR });
				return false;
			}
        
			if (!confirmPassword || confirmPassword.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleaseconfirmthenewpassword ? langdata.Pleaseconfirmthenewpassword : 'Please confirm the new password.' });
				dispatch({type : CHANGE_PASSWORD_ERROR });
				return false;
			}
        
			if (newPassword  !== confirmPassword) {
				Modal.warning({ title: langdata && langdata.Newpasswordandconfirmpasswordshouldmatch ? langdata.Newpasswordandconfirmpasswordshouldmatch : 'New password and confirm password should match.' });
				dispatch({type : CHANGE_PASSWORD_ERROR });
				return false;
			}

			if (emailId === null) {
				Modal.warning({ title: langdata && langdata.EmailIdnotfound ? langdata.EmailIdnotfound : 'Email Id not found' });
				dispatch({type : CHANGE_PASSWORD_ERROR });
				return false;
			}
        
			let data = JSON.stringify({
				'action': 'passwordChange',
				'value': {
					'user': {
						'email': emailId
					},
					'oldPassword': currentPassword,
					'password': newPassword
				}
			});
            
			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/authmanagement`,
				timeout: extendTimeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
            
			axios.request(config)
				.then((response) => {
					if(response.status === 200 || response.status === 201){
						dispatch({ type : CHANGE_PASSWORD_SUCCESS });
						Modal.success({ title: langdata && langdata.PasswordupdatedSuccessfully ? langdata.PasswordupdatedSuccessfully : 'Password updated Successfully' });
					} else {
						dispatch({ type : CHANGE_PASSWORD_ERROR});
						Modal.error({ title: langdata && langdata.Passwordnotupdated ? langdata.Passwordnotupdated : 'Password not updated' });
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({type : CHANGE_PASSWORD_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
					if(error.response.status == 400) {
						dispatch({ type : CHANGE_PASSWORD_ERROR});
						Modal.warning({ title: langdata && langdata.Currentpasswordisincorrect ? langdata.Currentpasswordisincorrect : 'Current password is incorrect.' });
					}
				});
		}
	});

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.DetailsoftheloggedinuserisdisplayedUsercanchangetheirloginpasswordusingtheChangePasswordbutton ? langdata.DetailsoftheloggedinuserisdisplayedUsercanchangetheirloginpasswordusingtheChangePasswordbutton : 'Details of the logged in user is displayed. User can change their login password using the Change Password button.'}</p>
		</Card>
	);
  
	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={8}>
						<Row>
							<Space>
								<UserOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}>{ langdata && langdata.MyProfile ? langdata.MyProfile : 'My Profile'}</Title>
						</Row>
					</Col>
					<Col span={16}>
						<Row justify="end">
							<Space>
								<Typography>
									<Popover title={ langdata && langdata.MyProfile ? langdata.MyProfile : 'My Profile'}  content={infoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover>
								</Typography>
							</Space>
						</Row>
					</Col>
					
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24} style={{ marginTop: '20px'}}>
						<Form layout="vertical">
							<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}}>
								<Col span={12}>
									<Form.Item label={ langdata && langdata.FirstName ? langdata.FirstName : 'First Name'} >
										<Input name="name" placeholder={ langdata && langdata.Pleaseenterthefirstname ? langdata.Pleaseenterthefirstname : 'Please enter the first name'} value={data.profile && data.profile.firstName ? data.profile.firstName : null} disabled />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={ langdata && langdata.LastName ? langdata.LastName : 'Last Name'} >
										<Input name="name" placeholder={ langdata && langdata.Pleaseenterthelastname ? langdata.Pleaseenterthelastname : 'Please enter the last name'} value={data.profile && data.profile.lastName ? data.profile.lastName : null} disabled/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={ langdata && langdata.EmailId ? langdata.EmailId : 'Email Id' }>
										<Input name="name" placeholder={ langdata && langdata.Pleaseentertheemailid ? langdata.Pleaseentertheemailid : 'Please enter the email id'} value={data.profile && data.profile.email ? data.profile.email : null} disabled/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={ langdata && langdata.ContactNumber ? langdata.ContactNumber : 'Contact Number'} >
										<Input name="name" placeholder={ langdata && langdata.Pleaseenterthecontactnumber ? langdata.Pleaseenterthecontactnumber : 'Please enter the contact number'} value={data.profile && data.profile.cellPhone ? data.profile.cellPhone : null} disabled/>
									</Form.Item>
								</Col>
								{/* <Col className="gutter-row" span={10} style={{ textAlign : 'right',marginTop : '25px', marginBottom : '25px'}} >
                        <Button type='primary' >Save</Button>
                    </Col> */}
								<Col className="gutter-row" span={24} style={{display : 'flex', justifyContent : 'center', marginTop : '25px', marginBottom : '25px'}} >
									<Button  type='primary' onClick={openChangePasswordModal}>
										{ langdata && langdata.ChangePassword ? langdata.ChangePassword : 'Change Password '}
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Spin fullscreen spinning={data.loading} />
			</Content>{/* Change Password Modal Starts */}
			<Modal
				title={ langdata && langdata.ChangePassword ? langdata.ChangePassword : 'Change Password'}
				centered
				open={showChangePasswordModal}
				onOk={{closeChangePasswordModal}}
				onCancel={closeChangePasswordModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeChangePasswordModal}>
							{ langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}
						</Button>
						<Button key="submit" type="primary" onClick={changePasswordFormik.handleSubmit}>
							{ langdata && langdata.CHANGEPASSWORD ? langdata.CHANGEPASSWORD : 'CHANGE PASSWORD' }
						</Button>
					</Space>
				]}
				width={640}
			>
				<Form layout="vertical" onSubmit={changePasswordFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item  label={ langdata && langdata.Currentpassword ? langdata.Currentpassword : 'Current password'} name="currentPassword" rules={[{ required : true, message: langdata && langdata.Pleasespecifythecurrentpassword ? langdata.Pleasespecifythecurrentpassword : 'Please specify the current password' }]} >
								{/* <Input name="currentPassword" type='password' placeholder="Please specify the current password" onChange={changePasswordFormik.handleChange} value={changePasswordFormik.values.currentPassword} /> */}
								<Input.Password placeholder={ langdata && langdata.Pleasespecifythecurrentpassword ? langdata.Pleasespecifythecurrentpassword : 'Please specify the current password'} onChange={changePasswordFormik.handleChange}  visibilityToggle={{
									visible: passwordVisible,
									onVisibleChange: setPasswordVisible,
								}} prefix={<LockOutlined /> } />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item  label={ langdata && langdata.Newpassword ? langdata.Newpassword : 'New password'} name="newPassword" rules={[{ required : true, message: langdata && langdata.Pleasespecifythenewpassword ? langdata.Pleasespecifythenewpassword : 'Please specify the new password' }]} >
								{/* <Input name="newPassword" type='password' placeholder="Please specify the new password" onChange={changePasswordFormik.handleChange} value={changePasswordFormik.values.newPassword} /> */}
								<Input.Password placeholder={ langdata && langdata.Pleasespecifythenewpassword ? langdata.Pleasespecifythenewpassword : 'Please specify the new password'} onChange={changePasswordFormik.handleChange}  visibilityToggle={{
									visible: passwordVisible,
									onVisibleChange: setPasswordVisible,
								}} prefix={<LockOutlined /> } />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label={ langdata && langdata.Confirmpassword ? langdata.Confirmpassword : 'Confirm password'} name="confirmPassword" rules={[{ required : true, message: langdata && langdata.Pleaseconfirmthenewpassword ? langdata.Pleaseconfirmthenewpassword : 'Please confirm the new password' }]} >
								{/* <Input name="confirmPassword" type='password' placeholder="Please confirm the new password" onChange={changePasswordFormik.handleChange} value={changePasswordFormik.values.confirmPassword}/> */}
								<Input.Password placeholder={ langdata && langdata.Pleaseconfirmthenewpassword ? langdata.Pleaseconfirmthenewpassword : 'Please confirm the new password'} onChange={changePasswordFormik.handleChange}  visibilityToggle={{
									visible: passwordVisible,
									onVisibleChange: setPasswordVisible,
								}} prefix={<LockOutlined /> } />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* Change Password Modal Modal Ends */}
		</>
	);
}