import { CHAINAGES_LIST, CHAINAGES_LIST_SUCCESS, CHAINAGES_LIST_ERROR } from './constants';
import { AVAILABLE_CHAINAGES_LIST, AVAILABLE_CHAINAGES_LIST_SUCCESS, AVAILABLE_CHAINAGES_LIST_ERROR } from './constants';
import { SNOOZED_POINTS_LIST, SNOOZED_POINTS_LIST_SUCCESS, SNOOZED_POINTS_LIST_ERROR } from './constants';
import { SNOOZED_POINT_DETAILS, SNOOZED_POINT_DETAILS_SUCCESS, SNOOZED_POINT_DETAILS_ERROR } from './constants';
import { SAVE_SNOOZED_POINT, SAVE_SNOOZED_POINT_SUCCESS, SAVE_SNOOZED_POINT_ERROR } from './constants';
import { UPDATE_SNOOZED_POINT, UPDATE_SNOOZED_POINT_SUCCESS, UPDATE_SNOOZED_POINT_ERROR } from './constants';
import { DELETE_SNOOZED_POINT, DELETE_SNOOZED_POINT_SUCCESS, DELETE_SNOOZED_POINT_ERROR } from './constants';

export default function snoozedPointsReducer(state, action) {
	let chainages = [];
	let availableChainages = [];
	let snoozedPoints = [];
	let pagination = {};

	switch (action.type) {
	case CHAINAGES_LIST:
		return { ...state, loading: true, error: false };

	case CHAINAGES_LIST_SUCCESS:
		
      
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.pipelineChainage = item.pipelineChainage || item.pipelineChainage === 0 ? item.pipelineChainage : '';
          
				return chainages.push(data);
			});

			let sortedChainages = chainages.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, chainages: sortedChainages, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, chainages: chainages, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case CHAINAGES_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case AVAILABLE_CHAINAGES_LIST:
		return { ...state, loading: true, error: false };

	case AVAILABLE_CHAINAGES_LIST_SUCCESS:
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.pipelineChainage = item.pipelineChainage || item.pipelineChainage === 0 ? item.pipelineChainage : '';
			
				return availableChainages.push(data);
			});

			let sortedChainages = availableChainages.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, availableChainages: sortedChainages, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, availableChainages: availableChainages, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case AVAILABLE_CHAINAGES_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case SNOOZED_POINTS_LIST:
		return { ...state, loading: true, error: false };

	case SNOOZED_POINTS_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
			pagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.pipelineChainage = item.pipelineChainage || item.pipelineChainage === 0 ? item.pipelineChainage : '';
				data.ODMeter = item.ODMeter;
				data.latitude = item.latitude ? parseFloat(item.latitude) : null;
				data.longitude = item.longitude ? parseFloat(item.longitude) : null;
				data.rouMarker = item.rouMarker ? item.rouMarker : '';
				data.terrainClassification = item.terrainClassification ? item.terrainClassification  : '';
				data.vulnerablePoint = item.vulnerablePoint ? item.vulnerablePoint : false;
				data.isSnoozed = item.isSnoozed ? item.isSnoozed : false;
				data.createdAt = item.createdAt ? item.createdAt : null;
				data.updatedAt = item.updatedAt ? item.updatedAt : null;

				return snoozedPoints.push(data);
			});

			let sortedSnoozedPoints = snoozedPoints.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, snoozedPoints: sortedSnoozedPoints, pagination: pagination, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, snoozedPoints: snoozedPoints, pagination: pagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case SNOOZED_POINTS_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case SNOOZED_POINT_DETAILS:
		return { ...state, loading: true, error: false };

	case SNOOZED_POINT_DETAILS_SUCCESS:
		return { ...state, details: action.payload, loading: false, error: false };

	case SNOOZED_POINT_DETAILS_ERROR:
		return { ...state, loading: false, error: true };

	case SAVE_SNOOZED_POINT:
		return { ...state, loading: true, error: false };

	case SAVE_SNOOZED_POINT_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case SAVE_SNOOZED_POINT_ERROR:
		return { ...state, loading: false, error: true };

	case UPDATE_SNOOZED_POINT:
		return { ...state, loading: true, error: false };

	case UPDATE_SNOOZED_POINT_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case UPDATE_SNOOZED_POINT_ERROR:
		return { ...state, loading: false, error: true };

	case DELETE_SNOOZED_POINT:
		return { ...state, loading: true, error: false };

	case DELETE_SNOOZED_POINT_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case DELETE_SNOOZED_POINT_ERROR:
		return { ...state, loading: false, error: true };

	default: 
		throw Error('Unknown action: ' + action.type);
      
	}
}