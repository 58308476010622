import { Breadcrumb, Button, Col, Empty, Form, Input, Modal, Row, Select, Space, Table, Tooltip, Typography, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useReducer, useState } from 'react';
import { SplitCellsOutlined, HomeOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import channelInfoReducer from './reducer';
import axios from 'axios';
import { useFormik } from 'formik';
import { CHANNEL_MATRICS_DETAILS } from './constants';

const { Title } = Typography;

export default function Metrics () {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const navigate = useNavigate();
	const { state } = useLocation();

	const initalState = {channelMatrics : [], pending: false, error: false};
	const [data, dispatch] = useReducer(channelInfoReducer, initalState);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalAddOpen, setIsModalAddOpen] = useState(false);
	const [matricsDetails, setMatreicsDetails] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteActivity, setDeleteActivity] = useState();

	const id = parseInt(state.id);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const metrics = [];

	for (const [key, value] of Object.entries(matricsDetails ? matricsDetails : state.data)) {
		metrics.push({
			severity : key,
			confidence : `${value[0]} - ${value[1]}`,
			min : value[0],
			max : value[1]
		});
	}

	const severity = [];

	severity.push({
		value : 'Red', label : 'Red'
	},{
		value : 'Amber', label : 'Amber'
	},{
		value : 'Clear', label : 'Clear'
	},{
		value : 'Green', label : 'Green'
	},{
		value : 'Unclassified', label : 'Unclassified'
	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const columns = [
		{
			title: 'Alarm severity',
			dataIndex: 'severity',
			key: 'severity',
		},
		{
			title: 'Event confidence',
			dataIndex: 'confidence',
			key: 'confidence',
		},
		{
			title: 'Actions',
			key: 'action',
			render: (record) => (
				<Space size="middle">
					<a title="Edit alarm escalation metrics" onClick={showModal}><EditOutlined /></a>
					<a title="Delete alarm escalation metrics" onClick={() => {openDeleteModal(record);}}><DeleteOutlined /></a>
				</Space>
			)
		}
	];

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const showAddModal = () => {
		setIsModalAddOpen(true);
	};

	const handleAddOk = () => {
		setIsModalAddOpen(false);
	};

	const handleAddCancel = () => {
		setIsModalAddOpen(false);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const loadChannelConvertionDetails = ((details) => {
		dispatch({ type : CHANNEL_MATRICS_DETAILS, payload : details });		
	});

	const openDeleteModal = (value) => {
		setDeleteActivity(value.severity);
		setShowDeleteModal(true);
	};

	const handleDeleteOk = () => {
		setShowDeleteModal(false);
	};

	const handleDelete = () => {
		let matricsData = {};

		for (const [key, value] of Object.entries(matricsDetails ? matricsDetails : state.data)){
			if(key != deleteActivity) {
				matricsData[key] = [value[0],value[1]];
			}
		}

		let data = JSON.stringify({
			'id': id,
			'escalation metrics': matricsData
		});

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/channel-profile-escalation-metrics`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 ){
					Modal.success({ title: 'Channel profile alarm escalation successfully deleted' });
					setMatreicsDetails(response.data['escalation metrics']);
				} else {
					Modal.warning({ title: 'Channel profile alarm escalation not deleted' });
				}
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
		setShowDeleteModal(false);
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			severity: data.channelMatrics && data.channelMatrics.severity,
			min: data.channelMatrics && data.channelMatrics.min,
			max: data.channelMatrics && data.channelMatrics.max,
		},
		onSubmit: (values) => {
			const {severity, min, max} = values;
			let matricsData = {};

			for (const [key, value] of Object.entries(matricsDetails ? matricsDetails : state.data)){
				if(key == severity) {
					matricsData[key] = [min,max];
				}else {
					matricsData[key] = [value[0],value[1]];

				}
			}

			let data = JSON.stringify({
				'id': id,
				'escalation metrics': matricsData
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/channel-profile-escalation-metrics`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 ){
						Modal.success({ title: 'Channel profile alarm escalation updated successfully' });
						setMatreicsDetails(response.data['escalation metrics']);
					} else {
						Modal.warning({ title: 'Channel profile alarm escalation not updated' });
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
			setIsModalOpen(false);
		}
	});

	const addFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			severity: null,
			min: null,
			max: null,
		},
		onSubmit: (values) => {
			const {severity, min, max} = values;
			if(severity == null || min == null || max  == null) {
				Modal.warning({title : 'Please fill all the mandatory fields'});
				return true;
			} else {

				let matricsData = {};

				for (const [key, value] of Object.entries(matricsDetails ? matricsDetails : state.data)){
					if(key == severity) {
						Modal.warning({title : 'Already exist'});
						return true;
					}else {
						matricsData[key] = [value[0],value[1]];
						
					}
					matricsData[severity] = [min,max];
				}

				let data = JSON.stringify({
					'id': id,
					'escalation metrics': matricsData
				});

				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: `${api}/channel-profile-escalation-metrics`,
					timeout: timeout,
					headers: { 
						'Content-Type': 'application/json', 
						'Authorization': `Bearer ${authtoken}`
					},
					data : data
				};

				axios.request(config)
					.then((response) => {
						if(response.status == 200 ){
							Modal.success({ title: 'Channel profile alarm escalation successfully added' });
							setMatreicsDetails(response.data['escalation metrics']);
							addFormik.values.severity = null;
							addFormik.values.min = null;
							addFormik.values.max = null;
						} else {
							Modal.warning({ title: 'Channel profile alarm escalation not added' });
						}
					})
					.catch((error) => {
						console.log(error);
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content:  'It seems the network is unreachable. Please refresh the application.',
							});
						}
					});
			}
			setIsModalAddOpen(false);
		}
	});

	return (
		<Content>
			<Row 
				style={{
					marginLeft:'10px',
					marginTop: '10px'
				}}            
			>
				<Breadcrumb
					items={[
						{
							href: '/dashboard',
							title: <HomeOutlined />,
						},
						{
							title:  <a onClick={()=>{navigate(-1);}}>Channel Info</a> 
						},
						{
							title: 'Alarm Escalation',
						},
					]}
				/>
			</Row>
			<Row style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center',
			}}
			>

				<Col span={8}>
					<Row>
						<Space>
							<SplitCellsOutlined style={{fontSize:'30px'}}/>
							{/* </Col> */}
						</Space>
						<Space>&nbsp;&nbsp;</Space>
					
						{/* <Col style={{marginLeft:'4px'}} span={19}> */}
						<Title level={4} style={{ paddingTop:'10px'}}>Alarm Escalation</Title>
					</Row>
				</Col>
				<Col style={{ display : 'flex', justifyContent : 'right'}} span={16} >
					<Row>
						<Col>								
							<Tooltip placement="bottom" title="New alarm escalation">
								<Space>

									<Button
										style={{ margin:'5px'}}
										type='primary'
										onClick={showAddModal}
									><PlusOutlined/>New</Button>
								</Space>
							</Tooltip>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row span={24} style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center'
			}} >
				<Col span={24}>
					{ metrics.length > 0 ? (
						<Table
							scroll={{
								x: 900,
							}} 
							columns={columns} 
							dataSource={metrics} 
							onRow = {(record) => {
								return {
									onClick: () => { 
										loadChannelConvertionDetails(record);
									}
								};
							}}
						/>
					)
						:
						(
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						)}
				</Col>
			</Row>
			<Modal title="Edit Channel Escalation Metrics" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} destroyOnClose= {true}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>CLOSE</Button>
						<Button type="primary" onClick={formik.handleSubmit}>SAVE</Button>
					</Space>
				]}>
				<Form layout="vertical" 
					onSubmit={formik.handleSubmit} 
					// form={form} 
				// onValuesChange={handleFormValuesChange}
				>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label="Severity" name="severity" rules={[{ required: true }]}>
								<Select
									// name="activity" 
									showSearch
									filterOption={filterOption}
									placeholder="Severity"
									optionFilterProp="children"
									options={severity}
									defaultValue={data.channelMatrics && data.channelMatrics.severity}
									onChange={(value) => formik.setFieldValue('severity', value)}
									disabled
								/>												
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label="Minimum Event Confidence" name="min" rules={[{ required: true }]}>
								<Input type='number' defaultValue={data.channelMatrics && data.channelMatrics.min} onChange={formik.handleChange}/>											
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label="Maximum Event Confidence" name="max" rules={[{ required: true }]}>
								<Input type='number' defaultValue={data.channelMatrics && data.channelMatrics.max} onChange={formik.handleChange}/>											
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			<Modal title="Add Channel Escalation Metrics" open={isModalAddOpen} onOk={handleAddOk} onCancel={handleAddCancel} destroyOnClose= {true}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleAddCancel}>CLOSE</Button>
						<Button type="primary" onClick={addFormik.handleSubmit}>SAVE</Button>
					</Space>
				]}>
				<Form layout="vertical" 
					onSubmit={addFormik.handleSubmit} 
					// form={form} 
				// onValuesChange={handleFormValuesChange}
				>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label="Severity" name="severity" rules={[{ required: true }]}>
								<Select
									// name="activity" 
									showSearch
									filterOption={filterOption}
									placeholder="Severity"
									optionFilterProp="children"
									options={severity}
									defaultValue={data.channelMatrics && data.channelMatrics.severity}
									onChange={(value) => addFormik.setFieldValue('severity', value)}
								/>												
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label="Minimum Event Confidence" name="min" rules={[{ required: true }]}>
								<Input type='number' placeholder="Minimum event confidence" defaultValue={data.channelMatrics && data.channelMatrics.min} onChange={addFormik.handleChange}/>											
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label="Maximum Event Confidence" name="max" rules={[{ required: true }]}>
								<Input type='number' placeholder="Maximum event confidence" defaultValue={data.channelMatrics && data.channelMatrics.max} onChange={addFormik.handleChange}/>											
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			<Modal title="Confirm delete" open={showDeleteModal} onOk={handleDelete} onCancel={handleDeleteOk}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>
            Are you sure you want to delete this alarm escalation?
					</Col>
				</Row>
			</Modal>
		</Content>
	);
}