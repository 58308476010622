export const DTS_GRAPH = 'DTS_GRAPH';
export const DAS_GRAPH = 'DAS_GRAPH';

export const DAS_GRAPH_DATA = 'DAS_GRAPH_DATA';
export const DAS_GRAPH_DATA_SUCCESS = 'DAS_GRAPH_DATA_SUCCESS';
export const DAS_GRAPH_DATA_ERROR = 'DAS_GRAPH_DATA_ERROR';

export const DAS_GRAPH_TIME_DATA = 'DAS_GRAPH_TIME_DATA';
export const DAS_GRAPH_TIME_SUCCESS = 'DAS_GRAPH_TIME_SUCCESS';
export const DAS_GRAPH_TIME_ERROR = 'DAS_GRAPH_TIME_ERROR';


export const DAS_GRAPH_TIME_EVENT = 'DAS_GRAPH_TIME_EVENT';

export const DTS_GRAPH_TIME_EVENT = 'DTS_GRAPH_TIME_EVENT';


export const DTS_GRAPH_DATA = 'DTS_GRAPH_DATA';
export const DTS_GRAPH_DATA_SUCCESS = 'DTS_GRAPH_DATA_SUCCESS';
export const DTS_GRAPH_DATA_ERROR = 'DTS_GRAPH_DATA_ERROR';

export const DTS_GRAPH_TIME_DATA = 'DTS_GRAPH_TIME_DATA';
export const DTS_GRAPH_TIME_SUCCESS = 'DTS_GRAPH_TIME_SUCCESS';
export const DTS_GRAPH_TIME_ERROR = 'DTS_GRAPH_TIME_ERROR';


export const DAS_GRAPH_AMP_DATA = 'DAS_GRAPH_AMP_DATA';
export const DAS_GRAPH_AMP_SUCCESS = 'DAS_GRAPH_AMP_SUCCESS';
export const DAS_GRAPH_AMP_ERROR = 'DAS_GRAPH_AMP_ERROR';

export const DAS_GRAPH_AMP_TIME_DATA = 'DAS_GRAPH_AMP_TIME_DATA';

export const DAS_GRAPH_AMP_DEPTH_DATA = 'DAS_GRAPH_AMP_DEPTH_DATA';


export const DTS_GRAPH_TEMP_DATA = 'DTS_GRAPH_TEMP_DATA';
export const DTS_GRAPH_TEMP_SUCCESS = 'DTS_GRAPH_TEMP_SUCCESS';
export const DTS_GRAPH_TEMP_ERROR = 'DTS_GRAPH_TEMP_ERROR';


export const DTS_GRAPH_TEMP_TIME_DATA = 'DTS_GRAPH_TEMP_TIME_DATA';

export const DTS_GRAPH_TEMP_DEPTH_DATA = 'DTS_GRAPH_TEMP_DEPTH_DATA';






