import { CHANNEL_INFO_LIST, CHANNEL_INFO_LIST_ERROR, CHANNEL_INFO_LIST_SUCCESS, PIDS_INFO_DETAILS, PIDS_INFO_LIST, PIDS_INFO_LIST_ERROR, PIDS_INFO_LIST_SUCCESS, PIDS_INFO_UPDATE, PIDS_INFO_UPDATE_ERROR, PIDS_INFO_UPDATE_SUCCESS } from './constants';

export default function pidsdata(state, action) {

	let pid_data =[];
	let channel_data =[];


	switch (action.type) {
      
	case PIDS_INFO_LIST:
		return { ...state, pending: true };
	case PIDS_INFO_LIST_SUCCESS:
		action.payload && action.payload.map((item)=>{
			pid_data.push({
				id: item.id,
				pidsName:item.name,
				ip:item.ipaddress,
				channelcount:item.channelId.length,
				latitude:item.latitude,
				longitude:item.longitude,
				channelId:item.channelId,
				parentAlarmType:item.parentAlarmType ? item.parentAlarmType : 'last'
			});

		});
		return { ...state, pid_data: pid_data, pending: false };
	case PIDS_INFO_LIST_ERROR:
		return { ...state, error: true };
	case PIDS_INFO_DETAILS:
		return { ...state, details:action.payload, error: true, pending: false };


	case CHANNEL_INFO_LIST:
		return { ...state, pending: true };
	case CHANNEL_INFO_LIST_SUCCESS:
		action.payload && action.payload.map((item)=>{
			channel_data.push({
				id: item.id,
				name:item.name,
				binSize:item.binSize,
				startPos:item.startPos,
				amplitudeNumber:item.amplitudeNumber,
				channelId:item.channelId,
				profile: item.profile
			});
		});
		return { ...state, channel_data: channel_data, pending: false };
	case CHANNEL_INFO_LIST_ERROR:
		return { ...state, error: true, pending: false };

	
	case PIDS_INFO_UPDATE:
		return { ...state, pending: true };
	case PIDS_INFO_UPDATE_SUCCESS:
		return { ...state, update_data: action.payload, pending: false };
	case PIDS_INFO_UPDATE_ERROR:
		return { ...state, error: true, pending: false };
      
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}