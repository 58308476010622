import React, { useEffect, useState, useReducer } from 'react';
import { useAuth } from '../../Context';

import { Empty, Row, Col, Typography, Button, Space, Table, Form, Input, Modal, Spin, Tooltip, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PlusOutlined, EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';

import { SPEED_CLASSIFICATIONS_LIST, SPEED_CLASSIFICATIONS_LIST_SUCCESS, SPEED_CLASSIFICATIONS_LIST_ERROR } from './constants';
import { SPEED_CLASSIFICATION_DETAILS, SPEED_CLASSIFICATION_DETAILS_SUCCESS, SPEED_CLASSIFICATION_DETAILS_ERROR } from './constants';
import { ADD_SPEED_CLASSIFICATION_DETAILS, ADD_SPEED_CLASSIFICATION_DETAILS_SUCCESS, ADD_SPEED_CLASSIFICATION_DETAILS_ERROR } from './constants';
import { UPDATE_SPEED_CLASSIFICATION, UPDATE_SPEED_CLASSIFICATION_SUCCESS, UPDATE_SPEED_CLASSIFICATION_ERROR } from './constants';
import { DELETE_SPEED_CLASSIFICATION, DELETE_SPEED_CLASSIFICATION_SUCCESS, DELETE_SPEED_CLASSIFICATION_ERROR } from './constants';

import speedClassificationReducer from './reducer';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

import { useFormik } from 'formik';
import axios from 'axios';
import { ToolOutlined, InfoOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

export default function SpeedClassification() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = {speedClassifications : [], details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(speedClassificationReducer, initalState);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [showAddSpeedClassificationModal, setShowAddSpeedClassificationModal] = useState(false);
	const [showEditSpeedClassificationModal, setShowEditSpeedClassificationModal] = useState(false);
	const [showDeleteSpeedClassificationModal, setShowDeleteSpeedClassificationModal] = useState(false);

	const {getRegion, regiondata} = useAuth();

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));
	const navigate = useNavigate();

	const columns = [
		{
			title: langdata && langdata.Activityname ? langdata.Activityname : 'Activity Name',
			dataIndex: 'activityName',
			key: 'activityName',
			render: (text) => <span onClick={openEditSpeedClassificationModal}>{text}</span>,
		},
		{
			title: langdata && langdata.UpperSpeedLimit ? langdata.UpperSpeedLimit : 'Upper Speed Limit',
			dataIndex: 'upperspeedLimit',
			key: 'upperspeedLimit',
		},
		{
			title: langdata && langdata.LowerSpeedLimit ? langdata.LowerSpeedLimit : 'Lower Speed Limit',
			dataIndex: 'lowerspeedLimit',
			key: 'lowerspeedLimit',
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: () => (
				permission === 'superadmin' || permission === 'admin' ? (
					<Space size="middle">
						<a title={langdata && langdata.EditSpeedClassification ? langdata.EditSpeedClassification : 'Edit Speed Classification'} onClick={openEditSpeedClassificationModal}><EditOutlined/></a>
						<a title={langdata && langdata.DeleteSpeedClassification ? langdata.DeleteSpeedClassification :'Delete Speed Classification'} onClick={openDeleteSpeedClassificationModal}><DeleteOutlined /></a>
					</Space>
				) : (
					<Space size="middle">
						<a title={langdata && langdata.EditSpeedClassification ? langdata.EditSpeedClassification : 'Edit Speed Classification'} onClick={openEditSpeedClassificationModal}><EyeOutlined/></a>
					</Space>
				)
			)
		}
	];

	const openAddSpeedClassificationModal = () => {
		setShowAddSpeedClassificationModal(true);
	};

	const cloaseAddSpeedClassificationModal = () => {
		setShowAddSpeedClassificationModal(false);
	};

	const openEditSpeedClassificationModal = () => {
		setShowEditSpeedClassificationModal(true);
	};

	const closeEditSpeedClassificationModal = () => {
		setShowEditSpeedClassificationModal(false);
	};

	const openDeleteSpeedClassificationModal = () => {
		setShowDeleteSpeedClassificationModal(true);
	};

	const deleteSpeedClassification = () => {
		const id = parseInt(data.details.id);
    
		dispatch({ type: DELETE_SPEED_CLASSIFICATION });

		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/speedclassification/${id}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			}
		};

		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type: DELETE_SPEED_CLASSIFICATION_SUCCESS, payload: response});
					setShowDeleteSpeedClassificationModal(false);   
					loadSpeedClassification();       
				} else {
					dispatch({type:DELETE_SPEED_CLASSIFICATION_ERROR});  
				}
			})
			.catch((error) => {
				dispatch({type:DELETE_SPEED_CLASSIFICATION_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const closeDeleteSpeedClassificationModal = () => {
		setShowDeleteSpeedClassificationModal(false);
	};

	useEffect(() => {
		if(featuresModule.speedClassificationEnabled == false) {
			navigate('/dashboard');
		}
		
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 44);
	}, []);

	useEffect(() => {
		dispatch( { type: SPEED_CLASSIFICATIONS_LIST });
      
		getRegion(authtoken);
		loadSpeedClassification();
	}, []);

	const loadSpeedClassification = () => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/speedclassification`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type:SPEED_CLASSIFICATIONS_LIST_SUCCESS, payload: response.data});
				}
			})
			.catch((error) => {
				dispatch({type:SPEED_CLASSIFICATIONS_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	let regions = [
		{ value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'}
	];

	regiondata && regiondata.map((region) => {
		return regions.push({ value: region.id, label: region.regionName.toUpperCase()});
	});

	const loadSpeedClassificationDetails = ((id) => {
		let details = null;

		if (data && data.speedClassifications) {
			data.speedClassifications && data.speedClassifications.map((item) => {
				if (item.id === id) {
					details = item;
				}

				return true;
			});
      
			dispatch( { type: SPEED_CLASSIFICATION_DETAILS });
    
			if (!details) {
				dispatch({ type: SPEED_CLASSIFICATION_DETAILS_ERROR });
			} else {
				dispatch({ type: SPEED_CLASSIFICATION_DETAILS_SUCCESS, payload: details });

			}
		} else {
			dispatch({ type: SPEED_CLASSIFICATION_DETAILS_ERROR }); 
		}
	});

	const addSpeedClassificationFormik = useFormik({
		initialValues: {
			addSpeedClassificationActivityName: '',
			addSpeedClassificationLowerLimit: 0,
			addSpeedClassificationUpperLimit: 0
		},
		onSubmit: (values) => {
			const activityName = values.addSpeedClassificationActivityName ? values.addSpeedClassificationActivityName : '';
			const lowerspeedLimit = values.addSpeedClassificationLowerLimit ? values.addSpeedClassificationLowerLimit : 0;
			const upperspeedLimit = values.addSpeedClassificationUpperLimit ? values.addSpeedClassificationUpperLimit : 0;

			if (!activityName || activityName == null || activityName === '') {
				Modal.warning({ title: langdata && langdata.Pleaseprovideavalidactivityname ? langdata.Pleaseprovideavalidactivityname : 'Please provide a valid activity name.' });
				return false;
			}

			if(upperspeedLimit < lowerspeedLimit){
				Modal.warning({ title: langdata && langdata.Upperlimitmustbegreaterthanlowerlimit ? langdata.Upperlimitmustbegreaterthanlowerlimit : 'Upper limit must be greater than lower limit' });
				return false;
			}

			if((upperspeedLimit <= 0 || lowerspeedLimit <= 0)){
				Modal.warning({ title: langdata && langdata.Upperorlowerlimitcannotbelessthanzero ? langdata.Upperorlowerlimitcannotbelessthanzero : 'Upper or lower limit cannot be less than zero' });
				return false;
			}

			if((upperspeedLimit >= 450 || lowerspeedLimit >= 450)){
				Modal.warning({ title: langdata && langdata.Upperorlowerlimitcannotbegreaterthan450 ? langdata.Upperorlowerlimitcannotbegreaterthan450 : 'Upper or lower limit cannot be greater than 450' });
				return false;
			}

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/speedclassification`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					activityName: activityName,
					lowerspeedLimit: lowerspeedLimit,
					upperspeedLimit: upperspeedLimit
				}
			};

			dispatch( { type: ADD_SPEED_CLASSIFICATION_DETAILS });
      
			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: ADD_SPEED_CLASSIFICATION_DETAILS_SUCCESS, payload: response});
						Modal.success({ title: langdata && langdata.Speedclassificationsavedsuccessfully ? langdata.Speedclassificationsavedsuccessfully : 'Speed classification saved successfully.' });
						setShowAddSpeedClassificationModal(false);
						loadSpeedClassification();
					} else {
						dispatch({type: ADD_SPEED_CLASSIFICATION_DETAILS_ERROR});
						Modal.warning({ title: langdata && langdata.Unabletosavethespeedclassification ? langdata.Unabletosavethespeedclassification : 'Unable to save the speed classification.' });  
					}
				})
				.catch((error) => {
					dispatch({type: ADD_SPEED_CLASSIFICATION_DETAILS_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
	});

	const updateSpeedClassificationFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			updateSpeedClassificationActivityName: data.details ? data.details.activityName : '',
			updateSpeedClassificationLowerLimit: data.details ? data.details.lowerspeedLimit : 0,
			updateSpeedClassificationUpperLimit: data.details ? data.details.upperspeedLimit : 0
		},
		onSubmit: (values) => {

			const id = data.details.id;
			const activityName = values.updateSpeedClassificationActivityName ? values.updateSpeedClassificationActivityName : '';
			const lowerspeedLimit = values.updateSpeedClassificationLowerLimit ? values.updateSpeedClassificationLowerLimit : 0;
			const upperspeedLimit = values.updateSpeedClassificationUpperLimit ? values.updateSpeedClassificationUpperLimit : 0;

			if (!activityName || activityName == null || activityName === '') {
				Modal.warning({ title: langdata && langdata.Pleaseprovideavalidactivityname ? langdata.Pleaseprovideavalidactivityname : 'Please provide a valid activity name.' });
				return false;
			}

			if(upperspeedLimit < lowerspeedLimit){
				Modal.warning({ title: langdata && langdata.Upperlimitmustbegreaterthanlowerlimit ? langdata.Upperlimitmustbegreaterthanlowerlimit : 'Upper limit must be greater than lower limit' });
				return false;
			}

			if((upperspeedLimit <= 0 || lowerspeedLimit <= 0)){
				Modal.warning({ title: langdata && langdata.Upperorlowerlimitcannotbelessthanzero ? langdata.Upperorlowerlimitcannotbelessthanzero : 'Upper or lower limit cannot be less than zero' });
				return false;
			}

			if((upperspeedLimit >= 450 || lowerspeedLimit >= 450)){
				Modal.warning({ title: langdata && langdata.Upperorlowerlimitcannotbegreaterthan450 ? langdata.Upperorlowerlimitcannotbegreaterthan450 : 'Upper or lower limit cannot be greater than 450' });
				return false;
			}

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/speedclassification/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					activityName: activityName,
					lowerspeedLimit: lowerspeedLimit,
					upperspeedLimit: upperspeedLimit
				}
			};

			dispatch( { type: UPDATE_SPEED_CLASSIFICATION });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: UPDATE_SPEED_CLASSIFICATION_SUCCESS, payload:response});
						Modal.success({ title: langdata && langdata.Speedclassificationupdatedsuccessfully ? langdata.Speedclassificationupdatedsuccessfully : 'Speed classification updated successfully.' });
						setShowEditSpeedClassificationModal(false);
						loadSpeedClassification();
					} else {
						dispatch({type: UPDATE_SPEED_CLASSIFICATION_ERROR});
						Modal.warning({ title: langdata && langdata.Unabletoupdatethespeedclassification ? langdata.Unabletoupdatethespeedclassification : 'Unable to update the speed classification.' });  
					}
				})
				.catch((error) => {
					dispatch({type: UPDATE_SPEED_CLASSIFICATION_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
	});

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{ langdata && langdata.SpeedClassificationContent1 ? langdata.SpeedClassificationContent1 :  'The Speed Classification display and manage of various speed ranges for different types of vehicles. This classification helps in categorizing inspections conducted by Linewalker/NPV based on the vehicle&apos;s speed in the Security Tracking System (STS) application.'}</p>
		</Card>
	);

	return (
		permission==='superadmin' || permission === 'admin' ? 

			<>
				<Content>
					<Row style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
					>

						<Col span={12}>
							<Row>
								<Space>
									<ToolOutlined style={{fontSize:'30px'}}/>
									{/* </Col> */}
								</Space>
								<Space>&nbsp;&nbsp;</Space>
								<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.STSSpeedClassification ? langdata.STSSpeedClassification : 'STS Speed Classification' }</Title>
							</Row>
						</Col>
					
						<Col span={12}>
							<Row justify="end">
								<Col>
									<Tooltip placement="bottom" title={langdata && langdata.Add ? langdata.Add : 'Add'}>
										<Space>
											{ permission === 'superadmin' || permission === 'admin' ? <Button type="primary" onClick={openAddSpeedClassificationModal} style={{margin:'5px'}} ><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New' }</Button> : null }
										</Space>
									</Tooltip>
									<Space>&nbsp;&nbsp;</Space>
									<Space>
										<Typography>
											<Popover title={langdata && langdata.STSSpeedClassification ? langdata.STSSpeedClassification : 'STS Speed Classification' }  content={infoContent} trigger="click">
												<Avatar size="small"><InfoOutlined /></Avatar>
											</Popover>
										</Typography>
									</Space>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24}>
							{ data && data.speedClassifications.length > 0 ? (
								<Table
									scroll={{
										x: 900,
									}} 
									columns={columns} 
									dataSource={data && data.speedClassifications} 
									onRow = {(record) => {
										return {
											onClick: () => { 
												loadSpeedClassificationDetails(record.id);
											}
										};
									}} />
							)
								:
								(
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
								)}
						</Col>
					</Row>
				</Content>

				<Spin fullscreen spinning={data.pending} />
      
				{/* Add Speed Classification Starts */}
				<Modal
					title={langdata && langdata.AddSpeedClassification ? langdata.AddSpeedClassification : 'Add Speed Classification' }
					centered
					destroyOnClose={true}
					maskClosable={false}
					open={showAddSpeedClassificationModal}
					onOk={{cloaseAddSpeedClassificationModal}}
					onCancel={cloaseAddSpeedClassificationModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={cloaseAddSpeedClassificationModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE' }</Button>
							{ permission === 'superadmin' || permission === 'admin' ? <Button type="primary" onClick={addSpeedClassificationFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE' }</Button> : null }
						</Space>
					]}
					width={800}
				>
					<Form layout="vertical" onSubmit={addSpeedClassificationFormik.handleSubmit}>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="name" label={langdata && langdata.Activityname ? langdata.Activityname : 'Activity Name'} rules={[{ required: true, message: langdata && langdata.Pleaseenteractivityname ? langdata.Pleaseenteractivityname : 'Please enter activity name' }]} >
									<Input name="addSpeedClassificationActivityName" onChange={addSpeedClassificationFormik.handleChange} placeholder={langdata && langdata.Pleaseenteractivityname ? langdata.Pleaseenteractivityname : 'Please enter activity name'} />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="lowerLimit" label={langdata && langdata.LowerLimit ? langdata.LowerLimit : 'Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthelowerlimit ? langdata.Pleaseenterthelowerlimit : 'Please enter the lower limit' }]} >
									<Input type="number" min={0} max={450} name="addSpeedClassificationLowerLimit" onChange={addSpeedClassificationFormik.handleChange} placeholder={langdata && langdata.Pleaseenterthelowerlimit ? langdata.Pleaseenterthelowerlimit : 'Please enter the lower limit'} />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="upperLimit" label={langdata && langdata.UpperLimit ? langdata.UpperLimit : 'Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheupperlimit ? langdata.Pleaseentertheupperlimit : 'Please enter the upper limit' }]} >
									<Input type="number" min={0} max={450} name="addSpeedClassificationUpperLimit" onChange={addSpeedClassificationFormik.handleChange} placeholder={langdata && langdata.Pleaseentertheupperlimit ? langdata.Pleaseentertheupperlimit : 'Please enter the upper limit'} />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
				{/* Add Speed Classification Ends */}

				{/* Edit Speed Classification Starts */}
				<Modal
					title="Edit Speed Classification"
					centered
					open={showEditSpeedClassificationModal}
					onOk={{closeEditSpeedClassificationModal}}
					onCancel={closeEditSpeedClassificationModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeEditSpeedClassificationModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE' }</Button>
							{ permission === 'superadmin' || permission === 'admin' ? <Button type="primary" onClick={updateSpeedClassificationFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE' }</Button> : null }
						</Space>
					]}
					width={800}
				>
					<Form layout="vertical" onSubmit={updateSpeedClassificationFormik.handleSubmit}>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.Activityname ? langdata.Activityname : 'Activity Name'} rules={[{ required: true, message: langdata && langdata.Pleaseenteractivityname ? langdata.Pleaseenteractivityname : 'Please enter activity name' }]} required>
									<Input name="updateSpeedClassificationActivityName" onChange={updateSpeedClassificationFormik.handleChange} placeholder={langdata && langdata.Pleaseenteractivityname ? langdata.Pleaseenteractivityname : 'Please enter activity name'} value={updateSpeedClassificationFormik.values.updateSpeedClassificationActivityName} />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.LowerLimit ? langdata.LowerLimit : 'Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthelowerlimit ? langdata.Pleaseenterthelowerlimit : 'Please enter the lower limit' }]} required>
									<Input name="updateSpeedClassificationLowerLimit" min={0} max={450}  onChange={updateSpeedClassificationFormik.handleChange} type="number" placeholder={langdata && langdata.Pleaseenterthelowerlimit ? langdata.Pleaseenterthelowerlimit : 'Please enter the lower limit'} value={updateSpeedClassificationFormik.values.updateSpeedClassificationLowerLimit} />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.UpperLimit ? langdata.UpperLimit : 'Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheupperlimit ? langdata.Pleaseentertheupperlimit : 'Please enter the upper limit' }]} required>
									<Input name="updateSpeedClassificationUpperLimit" min={0} max={450} onChange={updateSpeedClassificationFormik.handleChange} type="number" placeholder={langdata && langdata.Pleaseentertheupperlimit ? langdata.Pleaseentertheupperlimit : 'Please enter the upper limit'} value={updateSpeedClassificationFormik.values.updateSpeedClassificationUpperLimit} />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
				{/* Edit Speed Classification Ends */}

				{/* Delete Speed Classification Starts */}
				<Modal title={langdata && langdata.Confimdelete ? langdata.Confimdelete : 'Confirm delete'} open={showDeleteSpeedClassificationModal} onOk={deleteSpeedClassification} onCancel={closeDeleteSpeedClassificationModal}>
					<Row style={{
						marginTop: '10px',
						marginBottom: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={1}>
							<DeleteOutlined  style={{ fontSize: '14px'}}/>
						</Col>
						<Col span={23}> {langdata && langdata.Areyousureyouwanttodeletethisspeedclassification ? langdata.Areyousureyouwanttodeletethisspeedclassification : 'Are you sure you want to delete this speed classification?' }</Col>
					</Row>
				</Modal>
				{/* Delete Speed Classification Ends */}
			</> : null
	);
}