/* eslint-disable react/react-in-jsx-scope */
import { Row, Col, Select, Typography, Tooltip, Button, Space, Table, Spin, Modal, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useReducer, useState } from 'react';
import { useAuth } from '../../Context';
import axios from 'axios';
import mailTemplateReducer from './reducer';
import { useNavigate } from 'react-router-dom';
import { MAIL_TEMPLATE, MAIL_TEMPLATE_DELETE, MAIL_TEMPLATE_DELETE_ERROR, MAIL_TEMPLATE_DELETE_SUCCESS, MAIL_TEMPLATE_ERROR, MAIL_TEMPLATE_SUCCESS } from './constants';
import { EditOutlined, DeleteOutlined, ToolOutlined, InfoOutlined, PlusOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

const { Title } = Typography;

export default function MailTemplate() {
	const {getUserTypes, userTypeData} = useAuth();

	const initalState = { loading: false, error: false, mailTemplates : [], pagination: { total: 0, limit: 10, skip: 0, current:1 }};
	const [data, dispatch] = useReducer(mailTemplateReducer, initalState);

	const navigate = useNavigate();

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const [userRole, setUserRoll] = useState(2);
	const [modalOpen, setModalOpen] = useState(false);
	const [deleteId, setDeleteId] = useState();
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';
	const oneviewtypeLabel = oneviewType && oneviewType.toLowerCase() == 'pipeline' ? langdata && langdata.Pipeline : oneviewType.toLowerCase() == 'airport' ? langdata && langdata.Airport : oneviewType.toLowerCase() == 'border' ? langdata && langdata.Border : 'pipeline';

	useEffect(() => {
		if(featuresModule.mailConfigEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 45);
	}, []);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getUserTypes(authtoken);
	},[authtoken]);
 
	useEffect(() => {
		loadMailConfig(1);
	},[userRole]);

	const loadMailConfig = (pagination) => {

		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		dispatch({ type : MAIL_TEMPLATE});
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/mailconfigurations?usertypeId=${userRole}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : MAIL_TEMPLATE_SUCCESS , payload : response.data, page:pagination.current});
				} else {
					dispatch ({ type : MAIL_TEMPLATE_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch ({ type : MAIL_TEMPLATE_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});

	};

	const columns = [
		{
			title :  langdata && langdata.MailType ? langdata.MailType : 'Mail Type',
			dataIndex : 'mailType',
			key : 'mailType',
			render: (_, record) => (
				<Space size="middle">
					<a title={langdata && langdata.MailType ? langdata.MailType : 'mailType'} onClick={()=>{key(record);}}>{record.mailType}</a>
				</Space>
			)
		},
		{
			title : langdata && langdata.Subject ? langdata.Subject :  'Subject',
			dataIndex : 'subject',
			key : 'subject'
		},
		{
			title:  langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			align: 'center' ,
			render: (_, record) => (
				<Space size="middle">
					<a title={langdata && langdata.Edit ? langdata.Edit : 'Edit'} onClick={() => {navigate(('/edit-mail-configuration'), { state : { data : record }});}}><EditOutlined/></a>
					<a title={langdata && langdata.Delete ? langdata.Delete : 'Delete'} onClick={() => {handleDelete(record.id);}}><DeleteOutlined/></a>
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)
		}
	];

	const userType = [];
	userTypeData && userTypeData.data.map((item) => {
		// There is no mail template for the TLP role.
		if( oneviewtypeLabel.toLowerCase() == 'pipeline') {
			if (item.userType !== 'tlp') {
				userType.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			}
		} else {
			if (item.userType !== 'tlp' && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 9 ) {
				userType.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			}
		}
	});

	const AddMailTemplate = () => {
		navigate(('/add-mail-configuration'),{state : { data : userRole}});
	};

	const handleUserRole = (value) => {
		setUserRoll(value);
	};

	const handleDelete = (value) => {
		setDeleteId(value);
		setModalOpen(true);
	};

	const handleDeleteConfirm = () => {
		dispatch({ type : MAIL_TEMPLATE_DELETE });
		setModalOpen(false);

		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url:`${api}/mailconfigurations/${deleteId}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
		};

		axios.request(config)
			.then((response) => {
				if ( response.status === 200 || response.status === 201 ) {
					dispatch({ type : MAIL_TEMPLATE_DELETE_SUCCESS });
					Modal.success({ title: langdata && langdata.Mailconfigurationdeletedsuccessfully ? langdata.Mailconfigurationdeletedsuccessfully : 'Mail configuration deleted successfully.' });
					loadMailConfig({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
				} else {
					dispatch ({ type : MAIL_TEMPLATE_DELETE_ERROR });
					Modal.error({ title:  langdata && langdata.Mailconfigurationnotdeleted ? langdata.Mailconfigurationnotdeleted :'Mail configuration not deleted.' });
				}
			})
			.catch((error) => {
				dispatch ({ type : MAIL_TEMPLATE_DELETE_ERROR });
				Modal.error({ title: langdata && langdata.Mailconfigurationnotdeleted ? langdata.Mailconfigurationnotdeleted : 'Mail configuration not deleted.' });
				console.log(error);
			});
	};

	const key = (value) => {
		navigate(('/mail-configuration-details'),{state : { data : value}});
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.MailConfigurationInfo1 ? langdata.MailConfigurationInfo1 : 'The mail templates for different features are displayed in this module. Existing mail templates can be edited using the edit button in the Actions.'}</p>
			{ permission === 'superadmin' || permission === 'admin' ?
				<>
					< br/>
					<p>{langdata && langdata.MailConfigurationInfo2 ? langdata.MailConfigurationInfo2 : 'New templates can be added using the Add New button.'}</p> </> : null }
		</Card>
	);

	return (
		permission==='superadmin' || permission === 'admin' ? 
			<>
				<Content>
					<Row style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}}>
						<Col span={8}>
							<Row>
								<Space>
									<ToolOutlined style={{fontSize:'30px'}}/>
									{/* </Col> */}
								</Space>
								<Space>&nbsp;&nbsp;</Space>
					
								{/* <Col style={{marginLeft:'4px'}} span={19}> */}
								<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.MailConfiguration ? langdata.MailConfiguration : 'Mail Configuration'}</Title>
							</Row>
						</Col>

						<Col span={16}>
							<Row justify="end">
								<Col>
									{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>User Type:</Text> */}
									<Tooltip placement="bottom" title={langdata && langdata.Filterbyusertype ? langdata.Filterbyusertype : 'Filter by user type'}>
										<Space>
											<Select
												style={{ minWidth: '200px', margin:'5px'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectuserType ? langdata.SelectuserType : 'Select user Type'}
												optionFilterProp="children"
												options={userType}
												onSelect={handleUserRole}
												value={userRole}
											/>
										</Space>
									</Tooltip>
									<Space>
										<Space>&nbsp;</Space>
										<Button style={{margin:'5px'}} type="primary" onClick = {AddMailTemplate}><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button>
									</Space>
									<Space>&nbsp;&nbsp;</Space>
									<Space>
										<Typography>
											<Popover title={langdata && langdata.MailConfiguration ? langdata.MailConfiguration : 'Mail Configuration'}  content={infoContent} trigger="click">
												<Avatar size="small"><InfoOutlined /></Avatar>
											</Popover>
										</Typography>
									</Space>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24}>
							{/* <Table columns={columns} dataSource={data.users} pagination = { data.pagination }/> */}
							<Table scroll={{
								x: 900,
							}} columns={columns} dataSource={data.mailTemplates} pagination = {data.mailTemplates.length > 0 ? data.pagination : 0 } onChange={loadMailConfig} />
						</Col>
					</Row>
					<Spin fullscreen spinning={data.loading} />
				</Content>
				<Modal
					title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'}
					centered
					open={modalOpen}
					onOk={handleDeleteConfirm}
					onCancel={() => setModalOpen(false)}
					footer={[
						// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={() => setModalOpen(false)}>
								{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}
							</Button>
							<Button onClick={handleDeleteConfirm} type="primary">
								{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}
							</Button>
						</Space>
					]}
				>
					<p>{langdata && langdata.AreyousureyouwanttodeletethisMailConfiguration ? langdata.AreyousureyouwanttodeletethisMailConfiguration : 'Are you sure you want to delete this Mail Configuration?'}</p>
				</Modal>
			</> :
			null
	);
}