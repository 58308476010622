import React, { useEffect, useReducer, useState } from 'react';
import { useAuth } from '../../Context';

import { Empty, Row, Col, Select, Typography, Space, Spin,  Tooltip, Card, Breadcrumb, Collapse,theme, Input, Button, Modal, Divider, Avatar, Slider, Switch, message, Form, Progress } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { AreaChartOutlined,HomeOutlined,FullscreenOutlined,FullscreenExitOutlined,CaretRightOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons';

import Plot from 'react-plotly.js';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../App.css';
// import * as d3 from 'd3';
import flowMonitoringData from './reducer';
import { DAS_SOCKET_DATA, DTS_AVGZONE_DATA_SUCCESS, DTS_SOCKET_DATA, FLOW_MONITORING_GRAPH_CONFIG, PRESS_SOCKET_DATA } from './constants';
import { useFormik } from 'formik';
import axios from 'axios';
import WellDiagram from './wellDiagram';


const { Title } = Typography;

// const unpack = (rows, key) => rows.map(row => row[key]);

const tempColors = {
	'0%': '#87d068',
	'50%': '#faf323',
	'100%': '#a60a02',
};


export default function FlowMonitoring() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	// const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const { wellinfo, getWellInfo, wellzoneinfo,getWellZoneInfo, getAllEvents, eventsData} = useAuth();
	// const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const { state } = useLocation();
	const navigate = useNavigate();
	const [spin, setSpin] = useState(false);
	const [fullsize, setfullSize] = useState(false);
	const [graph, setGraph] = useState(null);

	// const [websocketdasdata, setWebsocketDASData] =useState({});
	const [wellId, setWellId] = useState();
	const [dassocketurl, setDasSocketUrl] = useState(null);
	const [dtssocketurl, setDtsSocketUrl] = useState(null);

	const [depthRangeFrom, setDepthRangeFrom] = useState(null);
	const [depthRangeTo, setDepthRangeTo] = useState(null);
	const [zoneId, setZoneId] = useState(null);
	const [zonefrom, setZoneFrom] = useState(null);
	const [zoneto, setZoneTo] = useState(null);

	// const [websocketdtsdata, setWebsocketDTSData] =useState({});
	const [tempTh, setTempTH] =useState({'maxTH':null, 'minTH':null});
	const [presTh, setPresTH] =useState({'maxTH':null, 'minTH':null});
	const [ampTh, setAmpTH] =useState({'maxTH':null, 'minTH':null});

	const [wellFrom, setWellFrom] = useState(null);
	const [wellTo, setWellTo] = useState(null);
	const [pressocketurl, setPressSocketUrl] = useState(null);
	// const [websocketpressdata, setWebsocketPressData] =useState({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [drawerDepthFrom, setDrawerDepthFrom] = useState();
	const [drawerDepthTo, setDrawerDepthTo] = useState();
	const [dasSocket, setDasSocket] = useState();
	const [dtsSocket, setDtsSocket] = useState();
	const [pressSocket, setPressSocket] = useState();

	const[dtsClickableData, setDTSClickableData] = useState();
	const [isDTSDataModalOpen, setIsDTSDataModalOpen] = useState(false);

	// const [annotations, setAnnotations] = useState([]);

	const [selectdActivity, setSelectedActivity] = useState();
	const [channelAndPidsData, setChannelAndPidsData] = useState();
	const [trainSystem, setTrainSystem] = useState();
	const [graphScroll, setGraphScroll] = useState(false);

	const [dtsHoverValue, setDTSHovervalue] = useState();
	const [dasHoverValue, setDASHovervalue] = useState();

	let pressEnabled1 =false;
	let dtsEnabled1 =false;
	let dasEnabled1 =false;


	const initalState = {details: null, pending: false, error: false, dtsData:null, dasData:null, pressData:null, graphConfig : {}, avgdtszonedata:null };
	const [data, dispatch] = useReducer(flowMonitoringData, initalState);		

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	let tempWellId;
	let tempdasurl;
	let tempdtsurl;

	let tempstartDepth;
	let tempendDepth;

	let tempdtsminth; 
	let tempdtsmaxth;
	let tempdasminth;
	let tempdasmaxth; 
	let temppresminth;
	let temppresmaxth;

	let temppressurl;

	let socketDTS = null;
	let socketDAS = null;
	let socketPress = null;	

	const allevents = [];
	eventsData && eventsData.map((item) => {
		allevents.push({
			value : item.id,
			label: item.eventType
		});
	});

	const welldata =[];
	
	wellinfo && wellinfo.map((item,index) => {
		
		
		if(item&&item.flowmonitoringconfig){

		
			welldata.push({
				value : item.id,
				label : item.name.toUpperCase(),
				startDepth : item.startDepth,
				endDepth : item.endDepth,
				dasip: item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dasip,
				dasport : item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dasport,
				dtsip: item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dtsip,
				dtsport : item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dtsport,
				dtsminth : item && item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.minThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.defaultminThreshold ,
				dtsmaxth : item && item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.maxThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.defaultmaxThreshold,
				dasminth : item && item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.minThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.defaultminThreshold,
				dasmaxth : item && item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.maxThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.defaultmaxThreshold,
				presminth :item && item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.minThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.defaultminThreshold,
				presmaxth : item && item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.maxThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.defaultmaxThreshold,
				pressip: item && item.flowmonitoringconfig &&item.flowmonitoringconfig.pressureip,
				pressport : item && item.flowmonitoringconfig &&item.flowmonitoringconfig.pressureport,
				pressEnabled : item.pressureEnabled,
				dtsEnabled : item.dtsEnabled,
				dasEnabled : item.dasEnabled,
				wellname : item.name.toUpperCase()
			});
			if (index === 0) {			
				tempWellId = item.id;
				tempstartDepth = item.startDepth;
				tempendDepth = item.endDepth;
				tempdasurl = `ws://${item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dasip}:${item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dasport}`;
				tempdtsurl = `ws://${item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dtsip}:${item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dtsport}`;
				tempdtsminth = item && item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.minThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.defaultminThreshold;
				tempdtsmaxth = item && item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.maxThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.defaultmaxThreshold;
				tempdasminth = item && item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.minThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.defaultminThreshold;
				tempdasmaxth = item && item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.maxThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.defaultmaxThreshold;
				temppresminth = item && item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.minThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.defaultminThreshold;
				temppresmaxth = item && item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.maxThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.defaultmaxThreshold;
				temppressurl = `ws://${item && item.flowmonitoringconfig &&item.flowmonitoringconfig.pressureip}:${item && item.flowmonitoringconfig &&item.flowmonitoringconfig.pressureport}`;
				pressEnabled1 = item.pressureEnabled;
				dtsEnabled1 = item.dtsEnabled;
				dasEnabled1 = item.dasEnabled;
			// setpressEnabled(item.pressureEnabled);
			// setdtsEnabled(item.dtsEnabled);
			// setdasEnabled(item.dasEnabled);
			}
	
		}

	});
	
	const [pressEnabled, setpressEnabled] = useState();
	const [dtsEnabled, setdtsEnabled] = useState();
	const [dasEnabled, setdasEnabled] = useState();	

	useEffect(() => {
		setpressEnabled(pressEnabled1);
		setdtsEnabled(dtsEnabled1);
		setdasEnabled(dasEnabled1);
		setDepthRangeFrom(tempstartDepth);
		setDepthRangeTo(tempendDepth);
	},[pressEnabled1, dtsEnabled1, dasEnabled1, tempstartDepth, tempendDepth]);	

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	

	const { token } = theme.useToken();
	const customPanelStyle = {
		// marginBottom: 12,
		// marginTop: 12,
		background: token.colorFillAlter,
		borderRadius: token.borderRadiusLG,
		border: 'none',
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.removeItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA');
		localStorage.removeItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_CARD_INDEX_ARRAY');
		localStorage.removeItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA_OBJECT' );
	}, [fullsize]);
	
	const handleSetWell = (e , options) =>{				

		dispatch({type: DAS_SOCKET_DATA, payload:[]});
		dispatch({type: DTS_SOCKET_DATA, payload:[]});
		dispatch({type: PRESS_SOCKET_DATA, payload:[]});
		
		if(dasSocket){
			dasSocket.close();
		}
		if(dtsSocket) {
			dtsSocket.close();
		}
		if(pressSocket) {
			pressSocket.close();
		}

		tempWellId=e;
		setWellId(e);
		// setWebsocketDASData([]);
		// setWebsocketDTSData([]);

		setDasSocketUrl(`ws://${options.dasip}:${options.dasport}`);
		setDtsSocketUrl(`ws://${options.dtsip}:${options.dtsport}`);
		setPressSocketUrl(`ws://${options.pressip}:${options.pressport}`);

		setDepthRangeFrom(options.startDepth);
		setDepthRangeTo(options.endDepth);
		setZoneId(null);
		setZoneFrom(null);
		setZoneTo(null);
		setWellFrom(options.startDepth);
		setWellTo(options.endDepth);

		setTempTH({'maxTH':options.dtsmaxth, 'minTH':options.dtsminth});
		setPresTH({'maxTH':options.presmaxth, 'minTH':options.presminth});
		setAmpTH({'maxTH':options.dasmaxth, 'minTH':options.dasminth});
		
		setpressEnabled(options.pressEnabled);
		setdtsEnabled(options.dtsEnabled);
		setdasEnabled(options.dasEnabled);

		setDrawerDepthFrom(options.startDepth);
		setDrawerDepthTo(options.endDepth);
	};
	
	

	const zonelist =[{
		value:null,label:'ALL',zoneFrom:wellFrom ,zoneTo:wellTo	}];

	wellzoneinfo && wellzoneinfo.map((item)=>{
		zonelist.push({
			value : item.id,
			label : item.zoneName.toUpperCase(),
			zoneFrom:item.zoneFrom,
			zoneTo:item.zoneTo
		});
	});

	// const getAvgDASData = ()=>{
		

	// 	let params = '';		
	// 	if(wellId || tempWellId) {
	// 		params += `wellinfoId=${wellId ? wellId : tempWellId}`;
	// 	}
	// 	let config = {
	// 		method: 'get',
	// 		maxBodyLength: Infinity,
	// 		url: `${api}/avgdaszone-amp?${params}`,
	// 		headers: { 
	// 			'Content-Type': 'application/json', 
	// 			'Authorization': `Bearer ${token}`
	// 		},
	// 	};

	// 	axios.request(config)
	// 		.then((response) => {
	// 			if(response.status == 200 ){
	// 				dispatch({ type : DAS_AVGZONE_DATA_SUCCESS, payload : response.data });
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 		});
	// };

	const getAvgDTSData = ()=>{
		

		let params = '';		
		if(wellId || tempWellId) {
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/avgdtszone-temp?${params}`,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 ){
					dispatch({ type : DTS_AVGZONE_DATA_SUCCESS, payload : response.data });
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleSetZone = (e,options)=>{
				
		setZoneId(e);
		setZoneFrom(options.zoneFrom);
		setDepthRangeFrom(options.zoneFrom || options.zoneFrom == 0 ? options.zoneFrom : tempstartDepth);
		setZoneTo(options.zoneTo);
		setDepthRangeTo(options.zoneTo || options.zoneTo == 0 ? options.zoneTo : tempendDepth);
		setDrawerDepthFrom(options.zoneFrom || options.zoneFrom == 0 ? options.zoneFrom : tempstartDepth);
		setDrawerDepthTo(options.zoneTo || options.zoneTo == 0 ? options.zoneTo : tempendDepth);
	};
	
	useEffect(() => {
		getWellInfo(authtoken);
		getWellZoneInfo(authtoken,wellId ? wellId : tempWellId);
		// setWellId(tempWellId);
		// setSocketUrl(tempurl);
		// getAvgDASData();
		getAvgDTSData();
		getAllEvents(authtoken);
	},[wellId,dassocketurl,dtssocketurl,tempWellId,pressEnabled,dasEnabled,dtsEnabled, tempTh,ampTh,presTh,tempdtsminth, tempdtsmaxth, tempdasminth, tempdasmaxth, temppresminth,temppresmaxth]);

	useEffect(() => {
		let params = '';		
		if(wellId || tempWellId) {
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/flowmonitoringconfig?${params}`,
				headers: { 
					Authorization:`Bearer ${authtoken}`	
				}
			};
		
			axios.request(config)
				.then((response) => {
					if(response.status == 200) {
						dispatch({ type : FLOW_MONITORING_GRAPH_CONFIG, payload : response.data });
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	},[wellId, tempWellId]);

	const webSocketDataDAS = () =>{			
		
		// const socket1 = new WebSocket(dassocketurl ? dassocketurl : tempdasurl); // Replace with your WebSocket URL
		// socket1.close();
		
		if(socketDAS == null){

		
			socketDAS = new WebSocket(dassocketurl ? dassocketurl : tempdasurl); // Replace with your WebSocket URL
			// console.log('socket.readyState',socket.readyState,WebSocket.OPEN);
			
			// if(socket.readyState == WebSocket.OPEN){
			// 	console.log('inn');
			// 	socket.close();
			// }
		
			setDasSocket(socketDAS);
			setSpin(true);		
	
			socketDAS.onopen = () => {
				console.log('WebSocket connection opened');
				setSpin(true);
			};
	
			socketDAS.onmessage = (event) => {
				try {
					const newData = JSON.parse(event.data);
					dispatch({type: DAS_SOCKET_DATA, payload:newData});
				
					// setWebsocketDASData(newData);
					setSpin(false);
	
				} catch (error) {

					setSpin(false);
					console.error('Error parsing data:', error);
				}
			};
	
			socketDAS.onerror = (error) => {
				setSpin(false);
				console.error('WebSocket error:', error);
			};
	
			socketDAS.onclose = () => {
			// setTimeout(setSpin(false), 5000);	
			// setTimeout(()=> {
			// 	webSocketDataDAS();
			// }, 10000);	
				setSpin(false);
				dispatch({type: DAS_SOCKET_DATA, payload:[]});
				console.log('WebSocket connection closed');
			};		
	
			const handlePopState = () => {			
				socketDAS.close();
				dispatch({type: DAS_SOCKET_DATA, payload:[]});
			};

			const handleUrlChange = () => {
				if (socketDAS) {
					socketDAS.close();
					dispatch({type: DAS_SOCKET_DATA, payload:[]});
				}
			};
		
			window.addEventListener('popstate', handlePopState);

			const originalPushState = history.pushState;
			const originalReplaceState = history.replaceState;

			history.pushState = function (...args) {
				originalPushState.apply(this, args);
				handleUrlChange(); // Close socket on push
			};

			history.replaceState = function (...args) {
				originalReplaceState.apply(this, args);
				handleUrlChange(); // Close socket on replace
			};
		
			return () => {
				window.removeEventListener('popstate', handlePopState);
				socketDAS.close();
				dispatch({type: DAS_SOCKET_DATA, payload:[]});
				history.pushState = originalPushState; // Restore original pushState
				history.replaceState = originalReplaceState; // Restore original replaceState
			};
		}


	};

	useEffect(() => {

		if((dassocketurl || tempdasurl) && (dasEnabled == true || dasEnabled1 == true)){				

			webSocketDataDAS();
		}
	
	}, [dassocketurl,tempdasurl, wellId]);
	
	
	useEffect(() => {

		if((dtssocketurl || tempdtsurl) && (dtsEnabled == true || dtsEnabled1 == true)){				

			webSocketDataDTS();
		}
	
	}, [dtssocketurl,tempdtsurl, wellId]);
	


	const webSocketDataDTS =()=>{
		// const socket = new WebSocket('ws://192.168.1.41:7891'); // Replace with your WebSocket URL
		// const socket1 = new WebSocket(dtssocketurl ? dtssocketurl : tempdtsurl); // Replace with your WebSocket URL
		// socket1.close();

		if(socketDTS==null){

			socketDTS = new WebSocket(dtssocketurl ? dtssocketurl : tempdtsurl);
			// if(socket.readyState == WebSocket.OPEN){
			// 	console.log('inn');
			// 	socket.close();
			// }
			setDtsSocket(socketDTS);
			setSpin(true);		

			socketDTS.onopen = () => {
				console.log('WebSocket connection opened');
				setSpin(true);
			};

			socketDTS.onmessage = (event) => {
				try {
					const newData = JSON.parse(event.data);
					// console.log('new Data', newData);
					dispatch({type: DTS_SOCKET_DATA, payload:newData});

				
			
					// setWebsocketDTSData(newData);
					setSpin(false);

				} catch (error) {

					setSpin(false);
					console.error('Error parsing data:', error);
				}
			};

			socketDTS.onerror = (error) => {
				setSpin(false);
				console.error('WebSocket error:', error);
			};

			socketDTS.onclose = () => {
				// setTimeout(setSpin(false), 5000);
			// setTimeout(()=> {
			// 	webSocketDataDTS();
			// }, 10000);		
				setSpin(false);
				dispatch({type: DTS_SOCKET_DATA, payload:[]});
				console.log('WebSocket connection closed');
			};		

			const handlePopState = () => {			
				socketDTS.close();
				dispatch({type: DTS_SOCKET_DATA, payload:[]});
			};

			const handleUrlChange = () => {
				if (socketDTS) {
					socketDTS.close();
					dispatch({type: DTS_SOCKET_DATA, payload:[]});
				}
			};
		
			window.addEventListener('popstate', handlePopState);

			const originalPushState = history.pushState;
			const originalReplaceState = history.replaceState;

			history.pushState = function (...args) {
				originalPushState.apply(this, args);
				handleUrlChange(); // Close socket on push
			};

			history.replaceState = function (...args) {
				originalReplaceState.apply(this, args);
				handleUrlChange(); // Close socket on replace
			};
		
			return () => {
				window.removeEventListener('popstate', handlePopState);
				socketDTS.close();
				dispatch({type: DTS_SOCKET_DATA, payload:[]});
				history.pushState = originalPushState; // Restore original pushState
				history.replaceState = originalReplaceState; // Restore original replaceState
			};

		}

	};



	useEffect(() => {

		if((pressocketurl || temppressurl) && (pressEnabled1==true || pressEnabled ==true)){						

			webSocketDataPress();
		}
	
	}, [pressocketurl,temppressurl, wellId]);



	const webSocketDataPress =()=>{
		// const socket = new WebSocket('ws://192.168.1.41:7891'); // Replace with your WebSocket URL
		// const socket1 = new WebSocket(pressocketurl ? pressocketurl : temppressurl); // Replace with your WebSocket URL
		// socket1.close();

		if(socketPress==null){

		
			socketPress = new WebSocket(pressocketurl ? pressocketurl : temppressurl);
			// if(socket.readyState == WebSocket.OPEN){
			// 	console.log('inn');
			// 	socket.close();
			// }

			setPressSocket(socketPress);
			setSpin(true);		

			socketPress.onopen = () => {
				console.log('WebSocket connection opened');
				setSpin(true);
			};

			socketPress.onmessage = (event) => {
				try {
					const newData = JSON.parse(event.data);					
					dispatch({type: PRESS_SOCKET_DATA, payload:newData});

				
			
					// setWebsocketPressData(newData);

					setSpin(false);

				} catch (error) {

					setSpin(false);
					console.error('Error parsing data:', error);
				}
			};

			socketPress.onerror = (error) => {
				setSpin(false);
				console.error('WebSocket error:', error);
			};

			socketPress.onclose = () => {
				// setTimeout(setSpin(false), 5000);
			// setTimeout(()=> {
			// 	webSocketDataDTS();
			// }, 10000);		
				setSpin(false);
				dispatch({type: PRESS_SOCKET_DATA, payload:[]});
				console.log('WebSocket connection closed');
			};		

			const handlePopState = () => {			
				socketPress.close();
				dispatch({type: PRESS_SOCKET_DATA, payload:[]});
			};

			const handleUrlChange = () => {
				if (socketPress) {
					socketPress.close();
					dispatch({type: PRESS_SOCKET_DATA, payload:[]});
				}
			};
		
			window.addEventListener('popstate', handlePopState);

			const originalPushState = history.pushState;
			const originalReplaceState = history.replaceState;

			history.pushState = function (...args) {
				originalPushState.apply(this, args);
				handleUrlChange(); // Close socket on push
			};

			history.replaceState = function (...args) {
				originalReplaceState.apply(this, args);
				handleUrlChange(); // Close socket on replace
			};
		
			return () => {
				window.removeEventListener('popstate', handlePopState);
				socketPress.close();
				dispatch({type: PRESS_SOCKET_DATA, payload:[]});
				history.pushState = originalPushState; // Restore original pushState
				history.replaceState = originalReplaceState; // Restore original replaceState
			};

		}
	};
	

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());
	

	const dasData = [{
		type: 'scatter',
		mode: 'lines',
		x: data && data.dasData && data.dasData.line_data,
		y: data && data.dasData && data.dasData.depths,
		line: { color: '#17BECF' },
		name: 'Amplitude',
		orientation: graphScroll == true ? 'h' : '',
		
	}];


	const dtsData = [{
		type: 'scatter',
		mode: 'lines',
		x: data && data.dtsData && data.dtsData.line_data,
		y: data && data.dtsData && data.dtsData.depths,
		line: { color: '#b32d1e' },
		name: 'Temperature',
		orientation: graphScroll == true ? 'h' : '',
		
	}];	

	const pressureData = [{
		type: 'scatter',
		mode: 'lines',
		x: data && data.pressData && data.pressData.times,
		y: data && data.pressData && data.pressData.depths,
		line: { color: '#17873a' },
		name: 'Pressure 1',
		orientation: graphScroll == true ? 'h' : '',
		
	},
		// {
		// 	type: 'scatter',
		// 	mode: 'lines',
		// 	x: data && data.pressData && data.pressData.times,
		// 	y: data && data.pressData && data.pressData.depths,
		// 	name: 'Pressure 2',
		// }

	];

	const plotDtsData = [{

		z:data && data.dtsData && data.dtsData.data,
		y:data && data.dtsData && data.dtsData.depths,
		// y:websocketdata && websocketdata.data ? [...websocketdata.data.keys()]: [10],
		// // colorscale: customColorscale, // Use custom color scale
		x: data && data.dtsData && data.dtsData.times,
		// z: data.z,
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Hot',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap',
		reversescale:true,
		hovertemplate : 'Time : %{x} <br> Depth : %{y} <br> Temperature : %{z} <extra></extra>',
		colorbar: {x: 1,   thickness: 10,
			// thicknessmode: 'pixels',
			// lenmode: 'fraction',
			outlinewidth: 0},

		orientation: graphScroll == true ? 'h' : '',
	},
	];

	let dtsshapes=[];

	data && data.dtsData && data.dtsData.anomaly_times  && data.dtsData.anomaly_times.map((item, index) => {
		dtsshapes.push(

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: data && data.dtsData && data.dtsData.anomaly_times ? data && data.dtsData && data.dtsData.anomaly_times[index] : [],
				y0: 0,
				x1: data && data.dtsData && data.dtsData.anomaly_times ? data && data.dtsData && data.dtsData.anomaly_times[index] : [],
				y1: 1,
				line:{
					color: 'black',
					width: 2,
					dash:'dot'
				},
			},

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: data && data.dtsData && data.dtsData.anomaly_times ? data && data.dtsData && data.dtsData.anomaly_times[index] : [],
		
				y0: 0,
		
				x1: data && data.dtsData && data.dtsData.anomaly_times ? data && data.dtsData && data.dtsData.anomaly_times[index] : [],
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text:  data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '',
		
					font: { size: 12, color: 'black', margin:'20px' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'bottom',
		
				},
		
			},

			// {
			// 	// type: 'scatter',
			// 	// z:websocketdata.data,
			// 	x: data && data.dtsData && data.dtsData.anomaly_times ? [data && data.dtsData && data.dtsData.anomaly_times[index], data && data.dtsData && data.dtsData.anomaly_times[index]] : [],
			// 	y: data && data.dtsData && data.dtsData.anomaly_times ? [0, depthRangeTo] : [],
			// 	// text: data && data.dtsData && data.dtsData.anomaly_type ? [data.dtsData.anomaly_type] : '', 
			// 	mode: 'lines',
			// 	line: {
			// 		color: 'blue',
			// 		width: 2,
			// 		dash: 'dot',
			// 	},				
			// 	// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
				
						
			// 	// hoverlabel: {
			// 	// 	bgcolor: 'white', // Set the tooltip background color
			// 	// // font: {
			// 	// //   color: 'white', // Set the text color
			// 	// // }
			// 	// }
			// },
			// {
			// 	// type: 'scatter',
			// 	// z:websocketdata.data,
			// 	x: data && data.dtsData && data.dtsData.anomaly_times ? [data && data.dtsData && data.dtsData.anomaly_times[index], data && data.dtsData && data.dtsData.anomaly_times[index]] : [],
			// 	y: data && data.dtsData && data.dtsData.anomaly_times ? [depthRangeFrom + 50, depthRangeTo] : [],
			// 	text: data && data.dtsData && data.dtsData.anomaly_type ? [data.dtsData.anomaly_type] : '', 
			// 	mode: 'text',			
			// 	// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
				
						
			// 	// hoverlabel: {
			// 	// 	bgcolor: 'white', // Set the tooltip background color
			// 	// // font: {
			// 	// //   color: 'white', // Set the text color
			// 	// // }
			// 	// }
			// }
		);});

	const plotDasData = [{

		z:data && data.dasData && data.dasData.data,
		y:data && data.dasData && data.dasData.depths,
		// y:websocketdata && websocketdata.data ? [...websocketdata.data.keys()]: [10],
		// // colorscale: customColorscale, // Use custom color scale
		x: data && data.dasData && data.dasData.times,
		// z: data.z,
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Jet',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap',
		hovertemplate : 'Time : %{x} <br> Depth : %{y} <br> Amplitude : %{z} <extra></extra>',
		colorbar: {x: 1,   thickness: 10,
			// thicknessmode: 'pixels',
			// lenmode: 'fraction',
			outlinewidth: 0},
		orientation: graphScroll == true ? 'h' : '',
	},{
		type: 'scatter',
		// z:websocketdata.data,
		// x: websocketdata.times,
		// y: websocketdata.depths,
		mode: 'markers',
		transforms: [{
			type: 'filter',
			target: 'y',
			operation: '>',
		// value: 4
		}],
		name: langdata && langdata.DistanceandDepth ? langdata.DistanceandDepth :  'Distance and Depth', // Legend entry name for scatter plot
		marker: {
			size: 10, // Increase the size of the points
			color: 'brown', // Optional: Change point color if needed
			symbol : 'star-square'
		},
	//   hoverlabel: {
	//     bgcolor: 'white', // Set the tooltip background color
	//     // font: {
	//     //   color: 'white', // Set the text color
	//     // }
	//   }
	}];

	let dasshapes=[];

	data && data.dasData && data.dasData.anomaly_times  && data.dasData.anomaly_times.map((item, index) => {
		dasshapes.push(

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: data && data.dasData && data.dasData.anomaly_times ? data && data.dasData && data.dasData.anomaly_times[index] : [],
				y0: 0,
				x1: data && data.dasData && data.dasData.anomaly_times ? data && data.dasData && data.dasData.anomaly_times[index] : [],
				y1: 1,
				line:{
					color: 'black',
					width: 2,
					dash:'dot'
				},
			},

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: data && data.dasData && data.dasData.anomaly_times ? data && data.dasData && data.dasData.anomaly_times[index] : [],
		
				y0: 0,
		
				x1: data && data.dasData && data.dasData.anomaly_times ? data && data.dasData && data.dasData.anomaly_times[index] : [],
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text:  data && data.dasData && data.dasData.anomaly_type ? data.dasData.anomaly_type : '',
		
					font: { size: 12, color: 'black', margin:'20px' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'bottom',
		
				},
		
			},
			// {
			// 	// type: 'scatter',
			// 	// z:websocketdata.data,
			// 	x: data && data.dasData && data.dasData.anomaly_times ? [data && data.dasData && data.dasData.anomaly_times[index], data && data.dasData && data.dasData.anomaly_times[index]] : [],
			// 	y: data && data.dasData && data.dasData.anomaly_times ? [0, depthRangeTo] : [],
			// 	// text: data && data.dtsData && data.dtsData.anomaly_type ? [data.dtsData.anomaly_type] : '', 
			// 	mode: 'lines',
			// 	line: {
			// 		color: 'blue',
			// 		width: 2,
			// 		dash: 'dot',
			// 	},				
			// 	// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
				
						
			// 	// hoverlabel: {
			// 	// 	bgcolor: 'white', // Set the tooltip background color
			// 	// // font: {
			// 	// //   color: 'white', // Set the text color
			// 	// // }
			// 	// }
			// },
			// {
			// 	// type: 'scatter',
			// 	// z:websocketdata.data,
			// 	x: data && data.dasData && data.dasData.anomaly_times ? [data && data.dtsData && data.dasData.anomaly_times[index], data && data.dasData && data.dasData.anomaly_times[index]] : [],
			// 	y: data && data.dasData && data.dasData.anomaly_times ? [depthRangeFrom + 50, depthRangeTo] : [],
			// 	text: data && data.dasData && data.dasData.anomaly_type ? [data.dasData.anomaly_type] : '', 
			// 	mode: 'text',			
			// 	// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
				
						
			// 	// hoverlabel: {
			// 	// 	bgcolor: 'white', // Set the tooltip background color
			// 	// // font: {
			// 	// //   color: 'white', // Set the text color
			// 	// // }
			// 	// }
			// }
		);});

	const daslayout = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.DASSoundWaves ? langdata.DASSoundWaves :  'DAS Sound Waves',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
		yaxis: { title: langdata && langdata.Depth ? langdata.Depth :'Depth',  
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
		},
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
		shapes:dasshapes,
		height: graphScroll == true ? 10000 :0,
	};


	const dtslayout = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.DTSTemperature ? langdata.DTSTemperature : 'DTS Temperature',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
		yaxis: { title: langdata && langdata.Depth ? langdata.Depth :'Depth', 
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
		},
		showlegend:false,
		// annotations: annotations,
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
		shapes:dtsshapes,
		height: graphScroll == true ? 10000 :0,
	};



	//line graphs

	const dtslinelayout = {
		title: {
			text: langdata && langdata.Temperature ? langdata.Temperature : 'Temperature vs ' + langdata && langdata.Depth ? langdata.Depth :'Depth',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			title: langdata && langdata.Temperature ? langdata.Temperature : 'Temperature',
			type: 'linear'
		},
		yaxis: {
			title: langdata && langdata.Depth ? langdata.Depth :'Depth',
			// autorange: true,
			// range: [70, 160],
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
			type: 'linear',
			// tickmode: 'linear', // Ensure all ticks are displayed
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
		},
		autosize: true,
		height: graphScroll == true ? 10000 :0,
		shapes: [
			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
				y0: 0,
				x1: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
				y1: 1,
				line:{
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash:'dot'
				},
			},

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
				y0: 0,
				x1: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
				y1: 1,
				line:{
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash:'dot'
				}
			},

			//max threhold

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
		
				y0: 0,
		
				x1: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text:  langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
					font: { size: 10, color: 'red' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'top',
		
				},
		
			},

			// {

			// 	type: 'rect',
		
			// 	xref: 'x',
		
			// 	yref: 'paper',
		
			// 	x0: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
		
			// 	y0: 0,
		
			// 	x1: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
		
			// 	y1: 1,
		
			// 	fillcolor: 'transparent',
		
			// 	opacity: 0.2,
		
			// 	editable: true,
		
			// 	line: {
		
			// 		width: 0,
		
			// 	},
		
			// 	label: {
		
			// 		text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
			// 		font: { size: 10, color: 'red' },
		
			// 		textposition: 'top center',
		
			// 	},
		
			// },


			//min threshold

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
		
				y0: 0,
		
				x1: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',
		
					font: { size: 10, color: 'red' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'bottom',
		
				},
		
			},

			// {

			// 	type: 'rect',
		
			// 	xref: 'x',
		
			// 	yref: 'paper',
		
			// 	x0: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
		
			// 	y0: 0,
		
			// 	x1:tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
		
			// 	y1: 1,
		
			// 	fillcolor: 'transparent',
		
			// 	opacity: 0.2,
		
			// 	editable: true,
		
			// 	line: {
		
			// 		width: 0,
		
			// 	},
		
			// 	label: {
		
			// 		text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',
		
			// 		font: { size: 10, color: 'red' },
		
			// 		textposition: 'top center',
		
			// 	},
		
			// },

		],
		showlegend:true
	};

	const daslinelayout = {
		title: {
			text: langdata && langdata.Amplitude ? langdata.Amplitude : 'Amplitude',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			title: langdata && langdata.Amplitude ? langdata.Amplitude : 'Amplitude',
			type: 'linear'
		},
		yaxis: {
			title: langdata && langdata.Depth ? langdata.Depth :'Depth',
			// autorange: true,
			// range: [86.8700008333, 138.870004167],
			// range: [160, 70],
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
			type: 'linear',
			// tickmode: 'linear', // Ensure all ticks are displayed
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
			// type: 'category',
		},
		autosize: true,
		height: graphScroll == true ? 10000 :0,

		shapes: [
			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
				y0: 0,
				x1: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
				y1: 1,
				line:{
					color: '#14877c',
					width: 2,
					dash:'dot'
				}
			},

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
				y0: 0,
				x1: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
				y1: 1,
				line:{
					color: '#14877c',
					width: 2,
					dash:'dot'
				}
			},



			//max threhold

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
		
				y0: 0,
		
				x1: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text:  langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
					font: { size: 10, color: 'red' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'top',
		
				},
		
			},

			// {

			// 	type: 'rect',
		
			// 	xref: 'x',
		
			// 	yref: 'paper',
		
			// 	x0: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
		
			// 	y0: 0,
		
			// 	x1: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
		
			// 	y1: 1,
		
			// 	fillcolor: 'transparent',
		
			// 	opacity: 0.2,
		
			// 	editable: true,
		
			// 	line: {
		
			// 		width: 0,
		
			// 	},
		
			// 	label: {
		
			// 		text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
			// 		font: { size: 10, color: 'red' },
		
			// 		textposition: 'top center',
		
			// 	},
		
			// },


			//min threshold

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
		
				y0: 0,
		
				x1: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',
		
					font: { size: 10, color: 'red' },
		
					// textposition: 'bottom center',

					textangle: 90,
					yanchor: 'bottom',
		
				},
				
		
			},

			// {

			// 	type: 'rect',
		
			// 	xref: 'x',
		
			// 	yref: 'paper',
		
			// 	x0: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
		
			// 	y0: 0,
		
			// 	x1:ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
		
			// 	y1: 1,
		
			// 	fillcolor: 'transparent',
		
			// 	opacity: 0.2,
		
			// 	editable: true,
		
			// 	line: {
		
			// 		width: 0,
		
			// 	},
		
			// 	label: {
		
			// 		text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',
		
			// 		font: { size: 10, color: 'red' },
		
			// 		textposition: 'top center',
		
			// 	},
		
			// },

		],
		showlegend:true
	};


	const pressurelinelayout = {
		
		title: {
			text: langdata && langdata.Pressure ? langdata.Pressure : 'Pressure',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			title: langdata && langdata.Time ? langdata.Time : 'Time',
			type: 'date'
		},
		yaxis: {
			title: langdata && langdata.Pressure ? langdata.Pressure : 'Pressure',
			// autorange: true,
			// range: [86.8700008333, 138.870004167],
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeFrom , depthRangeTo ] : [ tempstartDepth , tempendDepth ],
			type: 'linear',
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
		},
		autosize: true,
		height: graphScroll == true ? 10000 :0,

		shapes: [
			{
				type: 'line',
				xref: 'paper',
				yref: 'x',
				x0: 0,
				y0: presTh.minTH || presTh.minTH == 0 ? presTh.minTH :temppresminth,
				x1: 1,
				y1: presTh.minTH || presTh.minTH == 0 ? presTh.minTH :temppresminth,
				line:{
					color: '#19b083',
					width: 2,
					dash:'dot'
				}
			},

			{
				type: 'line',
				xref: 'paper',
				yref: 'x',
				x0: 0,
				y0:presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresmaxth,
				x1: 1,
				y1: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresmaxth,
				line:{
					color: '#19b083',
					width: 2,
					dash:'dot'
				}
			},

			//max threhold

			{

				type: 'rect',
		
				yref: 'x',
		
				xref: 'paper',
		
				x0: 0,
		
				y0: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresmaxth,
		
				x1: 1,
		
				y1: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresmaxth,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text:  langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
					font: { size: 10, color: 'red' },
		
					textposition: 'bottom center',
		
				},
		
			},

			// {

			// 	type: 'rect',
		
			// 	yref: 'x',
		
			// 	xref: 'paper',
		
			// 	x0: 0,
		
			// 	y0: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresmaxth,
		
			// 	x1: 0,
		
			// 	y1: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresmaxth,
		
			// 	fillcolor: 'transparent',
		
			// 	opacity: 0.2,
		
			// 	// editable: true,
		
			// 	line: {
		
			// 		width: 0,
		
			// 	},
		
			// 	label: {
		
			// 		text:  langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
			// 		font: { size: 10, color: 'red', marginLeft:'100px' },
		
			// 		textposition: 'left center',
		
			// 	},
		
			// },


			//min threshold


			{

				type: 'rect',
		
				yref: 'x',
		
				xref: 'paper',
		
				x0: 0,
		
				y0: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresminth,
		
				x1: 1,
		
				y1: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresminth,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text:  langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',
		
					font: { size: 10, color: 'red' },
		
					textposition: 'bottom center',
		
				},
		
			},

		

		],
		showlegend:true
	};


	const handlefullSize = (size,graph) =>{
		setfullSize(size);
		setGraph(graph);
	};

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
		setIsDTSDataModalOpen(false);
		setTrainSystem(null);
		formik.resetForm();
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setIsDTSDataModalOpen(false);
		setTrainSystem(null);
		formik.resetForm();
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues : {
			id : data.graphConfig && data.graphConfig.id,
			WellId : data.graphConfig && data.graphConfig.wellinfoId,
			minAmpThreshold : data.graphConfig && data.graphConfig.ampThresholds && data.graphConfig.ampThresholds.minThreshold,
			maxAmpThreshold : data.graphConfig && data.graphConfig.ampThresholds && data.graphConfig.ampThresholds.maxThreshold,
			minAmpSNThresholds : data.graphConfig && data.graphConfig.ampSNThresholds && data.graphConfig.ampSNThresholds.minThreshold,
			maxAmpSNThresholds : data.graphConfig && data.graphConfig.ampSNThresholds && data.graphConfig.ampSNThresholds.maxThreshold,
			minAmpFreqThresholds : data.graphConfig && data.graphConfig.ampFreqThresholds && data.graphConfig.ampFreqThresholds.minThreshold,
			maxAmpFreqThresholds : data.graphConfig && data.graphConfig.ampFreqThresholds && data.graphConfig.ampFreqThresholds.maxThreshold,
			minTempThreshold : data.graphConfig && data.graphConfig.tempThresholds && data.graphConfig.tempThresholds.minThreshold,
			maxTempThreshold : data.graphConfig && data.graphConfig.tempThresholds && data.graphConfig.tempThresholds.maxThreshold,
			minTempSNThresholds : data.graphConfig && data.graphConfig.tempSNThresholds && data.graphConfig.tempSNThresholds.minThreshold,
			maxTempSNThresholds : data.graphConfig && data.graphConfig.tempSNThresholds && data.graphConfig.tempSNThresholds.maxThreshold,
			minTempFreqThresholds : data.graphConfig && data.graphConfig.tempFreqThresholds && data.graphConfig.tempFreqThresholds.minThreshold,
			maxTempFreqThresholds : data.graphConfig && data.graphConfig.tempFreqThresholds && data.graphConfig.tempFreqThresholds.maxThreshold,
			minPressureThreshold : data.graphConfig && data.graphConfig.pressThresholds && data.graphConfig.pressThresholds.minThreshold,
			maxPressureThreshold : data.graphConfig && data.graphConfig.pressThresholds && data.graphConfig.pressThresholds.maxThreshold,
			minPressSNThresholds : data.graphConfig && data.graphConfig.pressSNThresholds && data.graphConfig.pressSNThresholds.minThreshold,
			maxPressSNThresholds : data.graphConfig && data.graphConfig.pressSNThresholds && data.graphConfig.pressSNThresholds.maxThreshold,
			minPressFreqThresholds : data.graphConfig && data.graphConfig.pressFreqThresholds && data.graphConfig.pressFreqThresholds.minThreshold,
			maxPressFreqThresholds : data.graphConfig && data.graphConfig.pressFreqThresholds && data.graphConfig.pressFreqThresholds.maxThreshold,
			ampThresholdsOverride : data.graphConfig && data.graphConfig.ampThresholdsOverride,
			tempThresholdsOverride : data.graphConfig && data.graphConfig.tempThresholdsOverride,
			pressThresholdsOverride : data.graphConfig && data.graphConfig.pressThresholdsOverride,
			ampSNThresholdsOverride : data.graphConfig && data.graphConfig.ampSNThresholdsOverride,
			tempSNThresholdsOverride : data.graphConfig && data.graphConfig.tempSNThresholdsOverride,
			pressSNThresholdsOverride : data.graphConfig && data.graphConfig.pressSNThresholdsOverride,
			ampFreqThresholdsOverride : data.graphConfig && data.graphConfig.ampFreqThresholdsOverride,
			tempFreqThresholdsOverride : data.graphConfig && data.graphConfig.tempFreqThresholdsOverride,
			pressFreqThresholdsOverride : data.graphConfig && data.graphConfig.pressFreqThresholdsOverride,
			defaultminAmpThreshold : data.graphConfig && data.graphConfig.ampThresholds && data.graphConfig.ampThresholds.defaultminThreshold,
			defaultmaxAmpThreshold : data.graphConfig && data.graphConfig.ampThresholds && data.graphConfig.ampThresholds.defaultmaxThreshold,
			defaultminAmpSNThresholds : data.graphConfig && data.graphConfig.ampSNThresholds && data.graphConfig.ampSNThresholds.defaultminThreshold,
			defaultmaxAmpSNThresholds : data.graphConfig && data.graphConfig.ampSNThresholds && data.graphConfig.ampSNThresholds.defaultmaxThreshold,
			defaultminAmpFreqThresholds : data.graphConfig && data.graphConfig.ampFreqThresholds && data.graphConfig.ampFreqThresholds.defaultminThreshold,
			defaultmaxAmpFreqThresholds : data.graphConfig && data.graphConfig.ampFreqThresholds && data.graphConfig.ampFreqThresholds.defaultmaxThreshold,
			defaultminTempThreshold : data.graphConfig && data.graphConfig.tempThresholds && data.graphConfig.tempThresholds.defaultminThreshold,
			defaultmaxTempThreshold : data.graphConfig && data.graphConfig.tempThresholds && data.graphConfig.tempThresholds.defaultmaxThreshold,
			defaultminTempSNThresholds : data.graphConfig && data.graphConfig.tempSNThresholds && data.graphConfig.tempSNThresholds.defaultminThreshold,
			defaultmaxTempSNThresholds : data.graphConfig && data.graphConfig.tempSNThresholds && data.graphConfig.tempSNThresholds.defaultmaxThreshold,
			defaultminTempFreqThresholds : data.graphConfig && data.graphConfig.tempFreqThresholds && data.graphConfig.tempFreqThresholds.defaultminThreshold,
			defaultmaxTempFreqThresholds : data.graphConfig && data.graphConfig.tempFreqThresholds && data.graphConfig.tempFreqThresholds.defaultmaxThreshold,
			defaultminPressureThreshold : data.graphConfig && data.graphConfig.pressThresholds && data.graphConfig.pressThresholds.defaultminThreshold,
			defaultmaxPressureThreshold : data.graphConfig && data.graphConfig.pressThresholds && data.graphConfig.pressThresholds.defaultmaxThreshold,
			defaultminPressSNThresholds : data.graphConfig && data.graphConfig.pressSNThresholds && data.graphConfig.pressSNThresholds.defaultminThreshold,
			defaultmaxPressSNThresholds : data.graphConfig && data.graphConfig.pressSNThresholds && data.graphConfig.pressSNThresholds.defaultmaxThreshold,
			defaultminPressFreqThresholds : data.graphConfig && data.graphConfig.pressFreqThresholds && data.graphConfig.pressFreqThresholds.defaultminThreshold,
			defaultmaxPressFreqThresholds : data.graphConfig && data.graphConfig.pressFreqThresholds && data.graphConfig.pressFreqThresholds.defaultmaxThreshold,
		},
		onSubmit: () => {
			
			const id = parseInt(formik.values.id);
			let data = JSON.stringify({
				'ampThresholds': {
					'maxThreshold': formik.values.maxAmpThreshold,
					'minThreshold': formik.values.minAmpThreshold,
					'defaultmaxThreshold': formik.values.defaultmaxAmpThreshold,
					'defaultminThreshold': formik.values.defaultminAmpThreshold
				},
				'ampThresholdsOverride': formik.values.ampThresholdsOverride ,
				'tempThresholds': {
					'maxThreshold': formik.values.maxTempThreshold,
					'minThreshold': formik.values.minTempThreshold,
					'defaultmaxThreshold': formik.values.defaultmaxTempThreshold,
					'defaultminThreshold': formik.values.defaultminTempThreshold
				},
				'tempThresholdsOverride': formik.values.tempThresholdsOverride ,
				'pressThresholds': {
					'maxThreshold': formik.values.maxPressureThreshold,
					'minThreshold': formik.values.minPressureThreshold,
					'defaultmaxThreshold': formik.values.defaultmaxPressureThreshold,
					'defaultminThreshold': formik.values.defaultminPressureThreshold
				},
				'pressThresholdsOverride': formik.values.pressThresholdsOverride ,	
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/flowmonitoringconfig/${id}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`	
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201) {
						getWellInfo(authtoken);
						Modal.success({ title: langdata && langdata.Graphdetailssuccessfullyupdated ? langdata.Graphdetailssuccessfullyupdated : 'Graph details successfully updated.' });
						setIsModalOpen(false);
						setDepthRangeFrom(drawerDepthFrom);
						setDepthRangeTo(drawerDepthTo);
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdategraphdetails ? langdata.Unabletoupdategraphdetails : 'Unable to update graph details' });

					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdategraphdetails ? langdata.Unabletoupdategraphdetails : 'Unable to update graph details' });
					}
				});
		}
	});			
	
	const depthMarks = {
		[wellFrom ? wellFrom : tempstartDepth] : langdata && langdata.Min ? langdata.Min : 'Min',
		[wellTo ? wellTo : tempendDepth] : langdata && langdata.Max ? langdata.Max : 'Max',
		// [drawerDepthFrom] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{drawerDepthFrom}</span>},
		// [drawerDepthTo] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{drawerDepthTo}</span>}
	};	

	const ampMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		// [formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold}</span>},
		// [formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold}</span>}
	};		

	const tempMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		// [formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold}</span>},
		// [formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold}</span>}
	};	

	const pressureMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		// [formik.values.pressThresholdsOverride == true ? formik.values.minPressureThreshold : formik.values.defaultminPressureThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.pressThresholdsOverride == true ? formik.values.minPressureThreshold : formik.values.defaultminPressureThreshold}</span>},
		// [formik.values.pressThresholdsOverride == true ? formik.values.maxPressureThreshold : formik.values.defaultmaxPressureThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.pressThresholdsOverride == true ? formik.values.maxPressureThreshold : formik.values.defaultmaxPressureThreshold}</span>}
	};

	const handleReset = () => {
		formik.resetForm();
		setDrawerDepthFrom(wellFrom ? wellFrom : tempstartDepth);
		setDrawerDepthTo(wellTo ? wellTo : tempendDepth);
	};

	const handleDepthChange = (value) => {
		setDrawerDepthFrom(value[0]);
		setDrawerDepthTo(value[1]);
	};

	const handleInputDepthRangefrom = (e) => {
		setDrawerDepthFrom(parseInt(e.target.value));
		setDrawerDepthTo(drawerDepthTo ? drawerDepthTo : depthRangeTo);
	};

	const handleInputDepthRangeTo = (e) => {
		setDrawerDepthTo(parseInt(e.target.value));	
		setDrawerDepthFrom(drawerDepthFrom ? drawerDepthFrom : depthRangeFrom);
	};

	const genExtra = () => (
		<Row>
			<Col span={24} >
				<Tooltip title={langdata && langdata.CrossWellMonitoring ? langdata.CrossWellMonitoring : 'Cross Well Monitoring'}>
					<Button type='primary' onClick={handleDTSCrossWell}>							
						{langdata && langdata.CrossWell ? langdata.CrossWell : 'Cross Well'}
					</Button>
				</Tooltip>
			</Col>
		</Row>
	);

	const genExtraDAS = () => (
		<Row>
			<Col span={24} >
				<Tooltip title={langdata && langdata.CrossWellMonitoring ? langdata.CrossWellMonitoring : 'Cross Well Monitoring'}>
					<Button type='primary' onClick={handleDASCrossWell}>							
						{langdata && langdata.CrossWell ? langdata.CrossWell : 'Cross Well'}
					</Button>
				</Tooltip>
			</Col>
		</Row>
	);

	

	const handleDTSCrossWell = () => {
		navigate(( '/dts-cross-well-flowmonitoring'),{ state: { data: {wellId:wellId ? wellId : tempWellId}, path: 'flow-monitoring' } });
	};

	const handleDASCrossWell = () => {
		navigate(( '/das-cross-well-flowmonitoring'),{ state: { data: {wellId:wellId ? wellId : tempWellId}, path: 'flow-monitoring' } });
	};

	const handleDTSClick = (value, system) => {		
		setTrainSystem(system);
		setDTSClickableData( value.points[0]);
		setIsDTSDataModalOpen(true);
		if(system == 'dts'){
			setChannelAndPidsData({
				channel_id : data && data.dtsData && data.dtsData.channel_id,
				channel_name : data && data.dtsData && data.dtsData.channel_name,
				pids_id : data && data.dtsData && data.dtsData.pids_id,
				pids_name : data && data.dtsData && data.dtsData.pids_name,
				wellinfoName : data && data.dtsData && data.dtsData.well_name
			});
		} else {
			setChannelAndPidsData({
				channel_id : data && data.dasData && data.dasData.channel_id,
				channel_name : data && data.dasData && data.dasData.channel_name,
				pids_id : data && data.dasData && data.dasData.pids_id,
				pids_name : data && data.dasData && data.dasData.pids_name,
				wellinfoName : data && data.dasData && data.dasData.well_name
			});
		}
		// if (!value.points || value.points.length === 0) return;

		// const point = value.points[0];

		// // Check if the clicked point is an existing annotation
		// const existingAnnotation = annotations.find(annotation =>
		// 	annotation.x === point.x && annotation.y === point.y
		// );

		// if (existingAnnotation) {
		// 	// Remove the annotation if it exists
		// 	setAnnotations((prevAnnotations) =>
		// 		prevAnnotations.filter(annotation => annotation !== existingAnnotation)
		// 	);
		// } else {
		// 	// Create a new annotation if it does not exist
		// 	const newAnnotation = {
		// 		text: `Time : ${point.x}<br>Depth : ${point.y.toPrecision(4)}<br> Temperature : ${point.z.toPrecision(4)}`,
		// 		x: point.x,
		// 		y: point.y,
		// 		showarrow: false, // Disable arrow
		// 		ax: 0,
		// 		ay: 0,
		// 		font: { size: 12 },
		// 		bgcolor: 'rgba(255, 255, 255, 0.5)', // Background color for text
		// 		bordercolor: 'black', // Border color for the circle
		// 		borderwidth: 2,
		// 		borderpad: 4,
		// 		opacity: 0.8,
		// 	};

		// 	setAnnotations((prevAnnotations) => [...prevAnnotations, newAnnotation]);
		// }
	};		

	// useEffect(() => {
	// 	if(data && data.dtsData) {
	// 		const currentPoints = new Set(
	// 			Object.values(data && data.dtsData).flatMap(trace => trace.y) // Get current plot data points
	// 		);
	// 		// console.log('currentPoints', currentPoints);
		
	// 		// Remove annotations that no longer match the current data
	// 		setAnnotations(prevAnnotations =>
	// 			prevAnnotations.filter(annotation => currentPoints.has(annotation.y))
	// 		);
	// 	}
	// }, [data && data.dtsData]);

	const handleDTSHoverClick = (value) => {		
		setDTSHovervalue(value.points[0].y);
	};	

	const handleDASHoverClick = (value) => {		
		setDASHovervalue(value.points[0].y);
	};

	const handleSelectEvent = (value) => {
		setSelectedActivity(value);
	};

	const handleNavigateSimulation = () => {
		navigate(( '/add-simulations'),{ state: {time : dtsClickableData && dtsClickableData.x, depth : Math.trunc(dtsClickableData && dtsClickableData.y ), 
			activity : selectdActivity, wellId : wellId ? wellId : tempWellId, system : trainSystem, channelAndPidsData : channelAndPidsData} });
	};	
		
	let dtsflowMonitoring = (
		<>

			<Row gutter={{
				xs: 8,
				sm: 16,
				md: 24,
				lg: 32,
			}} style={{
			}}
			>
				<Col span={24}>
					<Space justify="center" span={4} style={{marginBottom:'15px'}}>
						<Tooltip title={langdata && langdata ? langdata.Upperzonetemperature: 'Upper zone temperature'}>
							<Card justify="center" className="card-full-width" hoverable style= {{display:'flex',width:'200px',height:'210px', justifyContent:'center'}} >

								<Progress className='ant-progress-text' style= {{display:'flex',width:'100%', justifyContent:'center'}} type="dashboard"  percent={data && data.avgdtszonedata && data.avgdtszonedata[0] ? (data.avgdtszonedata[0].AvgUpperZoneTemp * 100)/200 : 0} format={() => data && data.avgdtszonedata && data.avgdtszonedata[0] ? data.avgdtszonedata[0].AvgUpperZoneTemp.toFixed(2) + ' °C' : 0 + ' °C'} strokeColor={tempColors}/>
								<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center', marginTop:'10px' }}>
									<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.UpperZone: 'Upper Zone'}</Typography>
								</Col>
						
							</Card>
						</Tooltip>
					</Space>
					<Space>&nbsp;&nbsp;&nbsp;&nbsp;</Space>
					<Space justify="center" span={4} style={{marginBottom:'15px'}}>
						<Tooltip title={langdata && langdata ? langdata.Intermediatezonetemperature: 'Intermediate zone temperature'}>
							<Card justify="center" className="card-full-width" hoverable style= {{display:'flex',width:'200px',height:'210px', justifyContent:'center'}} >

								<Progress style= {{display:'flex',width:'100%', justifyContent:'center'}} type="dashboard"  percent={data && data.avgdtszonedata && data.avgdtszonedata[0]  ? (data.avgdtszonedata[0].AvgIntermediateZoneTemp*100)/200 : 0} format={() => data && data.avgdtszonedata && data.avgdtszonedata[0] ? data.avgdtszonedata[0].AvgIntermediateZoneTemp.toFixed(2) + ' °C' : 0 + ' °C'} strokeColor={tempColors}/>
								<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center', marginTop:'10px' }}>
									<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.IntermediateZone: 'Intermediate Zone'}</Typography>
								</Col>
						
							</Card>
						</Tooltip>
					</Space>
					<Space>&nbsp;&nbsp;&nbsp;&nbsp;</Space>

					<Space justify="center" span={4} style={{marginBottom:'15px'}}>
						<Tooltip title={langdata && langdata ? langdata.Reservoirzonetemperature: 'Reservoir zone temperature'}>
							<Card justify="center" className="card-full-width" hoverable style= {{display:'flex',width:'200px',height:'210px', justifyContent:'center'}} >

								<Progress style= {{display:'flex',width:'100%', justifyContent:'center'}} type="dashboard" percent={data && data.avgdtszonedata && data.avgdtszonedata[0]  ? (data.avgdtszonedata[0].AvgReservoirZoneTemp*100)/200 : 0} format={() => data && data.avgdtszonedata && data.avgdtszonedata[0] ? data.avgdtszonedata[0].AvgReservoirZoneTemp.toFixed(2) + ' °C' : 0 + ' °C'} strokeColor={tempColors}/>
								<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center', marginTop:'10px' }}>
									<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.ReservoirZone: 'Reservoir Zone'}</Typography>
								</Col>
						
							</Card>
						</Tooltip>
					</Space>

					{pressEnabled == true ? 
						<>
							<Space>&nbsp;&nbsp;&nbsp;&nbsp;</Space>

							<Space justify="center" span={4} style={{marginBottom:'15px'}}>
								<Tooltip title={langdata && langdata ? langdata.Headpressure: 'Head pressure'}>
									<Card justify="center" className="card-full-width" hoverable style= {{display:'flex',width:'200px',height:'210px', justifyContent:'center'}} >

										<Progress style= {{display:'flex',width:'100%', justifyContent:'center'}} type="dashboard" percent={data && data.pressData && data.pressData.pwh ? data.pressData.pwh.toFixed(2) : 0} format={() => data && data.pressData && data.pressData.pwh ? data.pressData.pwh.toFixed(2) + ' psi' : 0 + ' psi'} strokeColor={tempColors}/>
										<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center', marginTop:'10px' }}>
											<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.Pwh: 'Pwh'}</Typography>
										</Col>
						
									</Card>
								</Tooltip>
							</Space>
							<Space>&nbsp;&nbsp;&nbsp;&nbsp;</Space>

							<Space justify="center" span={4} style={{marginBottom:'15px'}}>
								<Tooltip title={langdata && langdata ? langdata.Boreholepressure: 'Bore hole pressure'}>
									<Card justify="center" className="card-full-width" hoverable style= {{display:'flex',width:'200px',height:'210px', justifyContent:'center'}} >

										<Progress style= {{display:'flex',width:'100%', justifyContent:'center'}} type="dashboard" percent={data && data.pressData && data.pressData.pbh ? data.pressData.pbh.toFixed(2) : 0} format={() => data && data.pressData && data.pressData.pbh ? data.pressData.pbh.toFixed(2)  + ' psi' : 0 + ' psi'} strokeColor={tempColors}/>
										<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center', marginTop:'10px' }}>
											<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.Pbh: 'Pbh'}</Typography>
										</Col>
						
									</Card>
								</Tooltip>
							</Space> 
						</>:
				
						null}
				</Col>
				
			</Row>

			<Card style={{ width: '100%', height: '700px' }}>
				<Row>
					{/* <Card style={{ width : '100%', height : '600px'}}>
									<Card.Grid 
										style={{ width : '100%', height : '600px'}}> */}
					
					<Col span={3}>
						<p style={{marginTop:'-15px',marginLeft:'-15px', fontSize : '10px'}}
							// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
							className="wellInfo" >
							{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
							<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
							<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {data && data.dtsData && data.dtsData.channel_name} <br />
						</p>
						<WellDiagram startDepth = {depthRangeFrom ? depthRangeFrom : tempstartDepth} endDepth = {depthRangeTo ? depthRangeTo : tempendDepth} dtsHoverValue = {dtsHoverValue} />
					</Col>		
					<Col span={13} style={{padding:'10px'}}>
						<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
 
							{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
							
							<button
								onClick={()=>{handlefullSize(fullsize === false ? true:false,'dts');}} 
								className="graphZoomButton" ><FullscreenOutlined /></button>
							<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
								<Plot
									data={plotDtsData}
									layout={dtslayout}
									style={{ width: '100%', height: '600px' }}
									onClick={(value) => {handleDTSClick(value, 'dts');}}
									onHover={(value) => {handleDTSHoverClick(value);
									}}
									config={{
									// displayModeBar: true,
										displaylogo : false,
										modeBarButtonsToRemove: [
											'pan2d',
											'zoom2d',
											'select2d',
											'zoomIn2d',
											'zoomOut2d',
											'autoScale2d',
											'resetScale2d',
											'hoverClosestCartesian',
											'hoverCompareCartesian',
											'zoom3d',
											'pan3d',
											'resetViews3d',
											'hoverClosest3d',
											'hoverCompare3d',
											'orbitRotation',
											'tableRotation',
											'resetCameraDefault3d',
											'resetCameraLastSave3d',
											'lasso2d'
										],
						
									}}
								/>
							</Col>
								
							{/* </div> */}
							{/* <Spin tip="Loading..." style={{display:'flex', justifyContent:'center',alignItems:'center'}} spinning={spin} /> */}
						</Spin>
					</Col>

					<Col span={8} style={{padding:'10px'}}> 
						<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
											
							
							<button
								onClick={()=>{handlefullSize(fullsize === false ? true:false,'dtsline');}} 
								className="graphZoomButton" ><FullscreenOutlined /></button>

							<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
								<Plot
									data={dtsData}
									layout={dtslinelayout}
									// config={{  }}
									style={{ width: '100%', height: '600px' }}
									onHover={(value) => {handleDTSHoverClick(value);
									}}
									config={{
									// displayModeBar: true,
										responsive: true,
										displaylogo : false,
										modeBarButtonsToRemove: [
											'pan2d',
											'zoom2d',
											'select2d',
											'zoomIn2d',
											'zoomOut2d',
											'autoScale2d',
											'resetScale2d',
											'hoverClosestCartesian',
											'hoverCompareCartesian',
											'zoom3d',
											'pan3d',
											'resetViews3d',
											'hoverClosest3d',
											'hoverCompare3d',
											'orbitRotation',
											'tableRotation',
											'resetCameraDefault3d',
											'resetCameraLastSave3d',
											'lasso2d'
										],
						
									}}
								/>
							</Col>
														
								
						</Spin>
					</Col>
				</Row>
			</Card>

		</>
	);


	let dasflowMonitoring = (
		<>

			{dtsEnabled == false  && pressEnabled == true ?
				<Row gutter={{
					xs: 8,
					sm: 16,
					md: 24,
					lg: 32,
				}} 
				>
					<Col span={24}>
						<Space justify="center" span={4} style={{marginBottom:'15px'}}>
							<Tooltip title={langdata && langdata ? langdata.Headpressure: 'Head pressure'}>
								<Card justify="center" className="card-full-width" hoverable style= {{display:'flex',width:'200px',height:'210px', justifyContent:'center'}} >

									<Progress style= {{display:'flex',width:'100%', justifyContent:'center'}} type="dashboard" percent={data && data.pressData && data.pressData.pwh ? data.pressData.pwh.toFixed(2) : 0} format={() => data && data.pressData && data.pressData.pwh ? data.pressData.pwh.toFixed(2) + ' psi' : 0 + ' psi'} strokeColor={tempColors}/>
									<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center', marginTop:'10px' }}>
										<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.Pwh: 'Pwh'}</Typography>
									</Col>
						
								</Card>
							</Tooltip>
						</Space>
						<Space>&nbsp;&nbsp;&nbsp;&nbsp;</Space>

						<Space justify="center" span={4} style={{marginBottom:'15px'}}>
							<Tooltip title={langdata && langdata ? langdata.Boreholepressure: 'Bore hole pressure'}>
								<Card justify="center" className="card-full-width" hoverable style= {{display:'flex',width:'200px',height:'210px', justifyContent:'center'}} >

									<Progress style= {{display:'flex',width:'100%', justifyContent:'center'}} type="dashboard" percent={data && data.pressData && data.pressData.pbh ? data.pressData.pbh.toFixed(2) : 0} format={() => data && data.pressData && data.pressData.pbh ? data.pressData.pbh.toFixed(2)  + ' psi' : 0 + ' psi'} strokeColor={tempColors}/>
									<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center', marginTop:'10px' }}>
										<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.Pbh: 'Pbh'}</Typography>
									</Col>
						
								</Card>
							</Tooltip>
						</Space> 
					</Col>
				
				</Row> : null}

			<Card style={{ width: '100%', height: '700px' }}>
				<Row>

					<Col span={3}>
						<p style={{marginTop:'-15px',marginLeft:'-15px', fontSize : '10px'}}
							// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
							className="wellInfo" >
							{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
							<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
							<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {data && data.dasData && data.dasData.channel_name} <br />
						</p>
						<WellDiagram startDepth = {depthRangeFrom ? depthRangeFrom : tempstartDepth} endDepth = {depthRangeTo ? depthRangeTo : tempendDepth} dasHoverValue = {dasHoverValue}/>
					</Col>		

					<Col span={13} style={{padding:'10px'}}> 
						<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

											
												
							{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
							
							<button
								onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
								className="graphZoomButton" ><FullscreenOutlined /></button>
							<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
								<Plot
									data={plotDasData}
									layout={daslayout}
									style={{ width: '100%', height: '600px' }}
									onClick={(value) => {handleDTSClick(value, 'das');}}
									onHover={(value) => {handleDASHoverClick(value);
									}}
									config={{
									// displayModeBar: true,
										displaylogo : false,
										modeBarButtonsToRemove: [
											'pan2d',
											'zoom2d',
											'select2d',
											'zoomIn2d',
											'zoomOut2d',
											'autoScale2d',
											'resetScale2d',
											'hoverClosestCartesian',
											'hoverCompareCartesian',
											'zoom3d',
											'pan3d',
											'resetViews3d',
											'hoverClosest3d',
											'hoverCompare3d',
											'orbitRotation',
											'tableRotation',
											'resetCameraDefault3d',
											'resetCameraLastSave3d',
											'lasso2d'
										],
						
									}}
								/>
							</Col>
								
						</Spin>
											
					</Col>

										


					<Col span={8} style={{padding:'10px'}}> 

						<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
							
							<button
								onClick={()=>{handlefullSize(fullsize === false ? true:false,'dasline');}} 
								className="graphZoomButton" ><FullscreenOutlined /></button>
							<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>			
								<Plot
									data={dasData}
									layout={daslinelayout}
									// config={{ responsive: true }}
									style={{ width: '100%', height: '600px' }}
									onHover={(value) => {handleDASHoverClick(value);
									}}
									config={{
									// displayModeBar: true,
										responsive: true,
										displaylogo : false,
										modeBarButtonsToRemove: [
											'pan2d',
											'zoom2d',
											'select2d',
											'zoomIn2d',
											'zoomOut2d',
											'autoScale2d',
											'resetScale2d',
											'hoverClosestCartesian',
											'hoverCompareCartesian',
											'zoom3d',
											'pan3d',
											'resetViews3d',
											'hoverClosest3d',
											'hoverCompare3d',
											'orbitRotation',
											'tableRotation',
											'resetCameraDefault3d',
											'resetCameraLastSave3d',
											'lasso2d'
										],
						
									}}
								/>
							</Col>
								
							{/* </div> */}
						</Spin>

					</Col>
				</Row>
				{/* <Space>&nbsp;</Space> */}
			</Card>
		
		</>
	);


	let pressureflowMonitoring = (
		<>

			<Card style={{ width: '100%', height: '700px' }}>
				<Row>

					<Col span={24} style={{padding:'10px'}}> 

						<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
							
							<button
								onClick={()=>{handlefullSize(fullsize === false ? true:false,'pressureline');}} 
								className="graphZoomButton" ><FullscreenOutlined /></button>
							<p style={{marginTop:'-15px',marginLeft:'-15px', fontSize : '10px'}}
								// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
								className="wellInfo" >
								{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
								<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
								<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {data && data.pressData && data.pressData.channel_name} <br />
							</p>
							<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
		
								<Plot
									data={pressureData}
									layout={pressurelinelayout}
									// config={{ responsive: true }}
									style={{ width: '100%', height: '600px' }}
									config={{
									// displayModeBar: true,
										responsive: true,
										displaylogo : false,
										modeBarButtonsToRemove: [
											'pan2d',
											'zoom2d',
											'select2d',
											'zoomIn2d',
											'zoomOut2d',
											'autoScale2d',
											'resetScale2d',
											'hoverClosestCartesian',
											'hoverCompareCartesian',
											'zoom3d',
											'pan3d',
											'resetViews3d',
											'hoverClosest3d',
											'hoverCompare3d',
											'orbitRotation',
											'tableRotation',
											'resetCameraDefault3d',
											'resetCameraLastSave3d',
											'lasso2d'
										],
						
									}}
								/>
							</Col>
								
							{/* </div> */}
						</Spin>

					</Col>
				</Row>
				{/* <Space>&nbsp;</Space> */}
			</Card>
		
		</>
	);

	const handleRangefrom = (e) => {				
		if(e.target.value) {
			setDepthRangeFrom(parseInt(e.target.value));
			setDrawerDepthFrom(parseInt(e.target.value));
		} 
		else {
			setDepthRangeFrom();
			setDrawerDepthFrom();
		}
	};

	const handleRangeTo = (e) => {
		if(e.target.value) {
			setDepthRangeTo(e.target.value);
			setDrawerDepthTo(e.target.value);
		} else {
			setDepthRangeTo();
			setDrawerDepthTo();
		}
	};
	
	let collapseItem = [];
	
	if(dtsEnabled == true ) {
		collapseItem.push({
			key: '1',
			label: <b>{langdata && langdata ? langdata.DTSFlowMonitoring: 'DTS Flow Monitoring'}</b>,
			children: dtsflowMonitoring,
			style: customPanelStyle,
			extra: genExtra(),
		});
	}
	if(dasEnabled == true) {
		collapseItem.push({
			key: '2',
			label: <b>{langdata && langdata ? langdata.DASFlowMonitoring: 'DAS Flow Monitoring'}</b>,
			children: dasflowMonitoring,
			style: customPanelStyle,
			extra: genExtraDAS(),

		});
	}
	if(pressEnabled == true) {
		collapseItem.push({
			key: '3',
			label: <b>{langdata && langdata ? langdata.PressureMonitoring: 'Pressure Monitoring'}</b>,
			children: pressureflowMonitoring,
			style: customPanelStyle
		});	
	}

	const handleSelectGraphScroll = (value) => {
		setGraphScroll(value);
	};
	
	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={8}>
						<Row>
							<Space>
								<AreaChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata ? langdata.FlowMonitoring: 'Flow Monitoring'}</Title>
						</Row>
					</Col>
					
					<Col span={16}>
						<Row justify="end">
							<Col>
								<Tooltip placement="bottom" title={langdata && langdata.FilterbyWell ? langdata.FilterbyWell : 'Filter by well'}>
									<Space>
										<Select
											style={{ width: '180px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectWell ? langdata.SelectWell : 'Select Well'}
											optionFilterProp="children"
											value={wellId ? wellId : tempWellId}
											options={welldata}
											onSelect={(e, options)=>handleSetWell(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyzone ? langdata.Filterbyzone : 'Filter by zone'}>
									<Space>
										<Select
											style={{ width: '180px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectZone ? langdata.SelectZone : 'Select Zone'}
											optionFilterProp="children"
											defaultValuevalue={null}
											value={zoneId}
											options={zonelist}
											onSelect={(e, options)=>handleSetZone(e, options)}
										/>
									</Space>
								</Tooltip>
								{dasEnabled == true || dtsEnabled == true || pressEnabled == true ?
									<>
										<Space>&nbsp;</Space>
										<Tooltip placement="bottom" title={langdata && langdata.EnterDepthRange ? langdata.EnterDepthRange:'Enter Depth Range'}>
											<Space.Compact size="middle" style={{ width : '180px', margin:'5px'}}>
												<Input type='number' value={depthRangeFrom} max={zonefrom} min={zoneto} placeholder={langdata && langdata.DepthRangeFrom ? langdata.DepthRangeFrom: 'Depth Range From'} onChange={handleRangefrom} />
												<Input type='number' value={depthRangeTo} max={zonefrom} min={zoneto} placeholder={langdata && langdata.DepthRangeTo ? langdata.DepthRangeTo: 'Depth Range To'} onChange={handleRangeTo}/>
											</Space.Compact>
										</Tooltip>
										<Space>&nbsp;</Space>
										<Space>
											<Button style ={{margin:'5px'}} type='primary' onClick={showModal}><FilterOutlined /></Button>
										</Space>	
									</>
									: null }							
							</Col>
						</Row>
					</Col>
				</Row>
				{/* <Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					// background: '#FFFFFF',
					alignItems:'center',
					width : '100%'
				}} > */}
				<Row style={{
					// margin: '20px',
					marginTop : '5px',
					// paddingLeft: '10px',
					// paddingRight: '10px',
					// background: '#FFFFFF',
					alignItems:'center',
				}} >
					<Col span={24}>
						<Row >
							<Col span={18}>
								<Breadcrumb
									items={[
										{
											href: '/dashboard',
											title: <HomeOutlined />,
										},
										{
											title: ( state.path === 'well-visualization' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a> :  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a>)
										},
										{
											title: `${langdata && langdata ? langdata.FlowMonitoring: 'Flow Monitoring'}`,
										},
									]}
									style={{ marginBottom : '10px',marginLeft:'20px'}}
								/>
							</Col>
							<Col span={6} style={{ display : 'flex', justifyContent : 'flex-end' , marginLeft:'-20px'}}>
								<Tooltip title={langdata && langdata.Graphlayout ? langdata.Graphlayout:'Graph layout'}>
									<Switch style={{backgroundColor: graphScroll == false ? '' : 'green'}} checkedChildren={langdata && langdata.Scroll ? langdata.Scroll:'Scroll'} unCheckedChildren={langdata && langdata.Fixed ? langdata.Fixed:'Fixed'} checked={graphScroll} onClick={handleSelectGraphScroll}/>
								</Tooltip>
							</Col>
							{fullsize==false ?
								<>


									<Collapse
										// accordion
										// className="ant-collapse-content-box" 
										// collapsible="header"
										// defaultActiveKey={dashboardtype==='tlp' ? ['1'] : ['0']}
										// style={customPanelStyle}
										// size="large"
										bordered={false}
										expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
										style={{
											background: token.colorBgContainer,
											width:'100%'
										}}
										defaultActiveKey={['1','2','3']}
										items={collapseItem}
									/>
									

									

								</>	
									
								:
								graph =='dts' ? 
									<Col span={24} style={{padding:'10px'}}>
										<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

											{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
											
											<button
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'dts');}} 
												className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
											<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
												// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
												className="wellInfo" >
												{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
												<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
												<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {data && data.dtsData && data.dtsData.channel_name}<br />
											</p>
											<Plot
												data={plotDtsData}
												layout={dtslayout}
												style={{ width: '100%', height: '800px' }}
												onClick={(value) => {handleDTSClick(value, 'dts');}}
												config={{
													// displayModeBar: true,
													displaylogo : false,
													modeBarButtonsToRemove: [
														'pan2d',
														'zoom2d',
														'select2d',
														'zoomIn2d',
														'zoomOut2d',
														'autoScale2d',
														'resetScale2d',
														'hoverClosestCartesian',
														'hoverCompareCartesian',
														'zoom3d',
														'pan3d',
														'resetViews3d',
														'hoverClosest3d',
														'hoverCompare3d',
														'orbitRotation',
														'tableRotation',
														'resetCameraDefault3d',
														'resetCameraLastSave3d',
														'lasso2d'
													],
				
												}}
											/>
												
											{/* </div> */}
											{/* <Spin tip="Loading..." style={{display:'flex', justifyContent:'center',alignItems:'center'}} spinning={spin} /> */}
										</Spin>
									</Col>
									:	
									graph =='das' ? 
										<Col span={24} style={{padding:'10px'}}> 
											<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

											
												
												{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
												
												<button
													onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
													className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
												<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
												// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
													className="wellInfo" >
													{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
													<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
													<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {data && data.dasData && data.dasData.channel_name} <br />
												</p>
												<Plot
													data={plotDasData}
													layout={daslayout}
													style={{ width: '100%', height: '800px' }}
													onClick={(value) => {handleDTSClick(value, 'das');}}
													config={{
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
						
													}}
												/>
													
											</Spin>
											
										</Col>
										: graph =='dtsline' ? 
											<Col span={24} style={{padding:'10px'}}> 
												<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
											
													
													<button
														onClick={()=>{handlefullSize(fullsize === false ? true:false,'dtsline');}} 
														className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
													<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
														// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
														className="wellInfo" >
														{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
														<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
														<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {data && data.dtsData && data.dtsData.channel_name} <br />
													</p>
													<Plot
														data={dtsData}
														layout={dtslinelayout}
														// config={{  }}
														style={{ width: '100%', height: '800px' }}
														config={{
															// displayModeBar: true,
															responsive: true,
															displaylogo : false,
															modeBarButtonsToRemove: [
																'pan2d',
																'zoom2d',
																'select2d',
																'zoomIn2d',
																'zoomOut2d',
																'autoScale2d',
																'resetScale2d',
																'hoverClosestCartesian',
																'hoverCompareCartesian',
																'zoom3d',
																'pan3d',
																'resetViews3d',
																'hoverClosest3d',
																'hoverCompare3d',
																'orbitRotation',
																'tableRotation',
																'resetCameraDefault3d',
																'resetCameraLastSave3d',
																'lasso2d'
															],
						
														}}
													/>
														
														
												</Spin>
											</Col>
											: graph =='dasline' ? 

												<Col span={24} style={{padding:'10px'}}> 

													<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
														{ dasData && dasData.length > 0 ? 
															<>
																<button
																	onClick={()=>{handlefullSize(fullsize === false ? true:false,'dasline');}} 
																	className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
																<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
																	// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
																	className="wellInfo" >
																	{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
																	<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
																	<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {data && data.dasData && data.dasData.channel_name} <br />
																</p>
											
																<Plot
																	data={dasData}
																	layout={daslinelayout}
																	// config={{ responsive: true }}
																	style={{ width: '100%', height: '800px' }}
																	config={{
																		// displayModeBar: true,
																		responsive: true,
																		displaylogo : false,
																		modeBarButtonsToRemove: [
																			'pan2d',
																			'zoom2d',
																			'select2d',
																			'zoomIn2d',
																			'zoomOut2d',
																			'autoScale2d',
																			'resetScale2d',
																			'hoverClosestCartesian',
																			'hoverCompareCartesian',
																			'zoom3d',
																			'pan3d',
																			'resetViews3d',
																			'hoverClosest3d',
																			'hoverCompare3d',
																			'orbitRotation',
																			'tableRotation',
																			'resetCameraDefault3d',
																			'resetCameraLastSave3d',
																			'lasso2d'
																		],
						
																	}}
																/>
															</>
												
															: 
										
															<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }}/>
														}
														{/* </div> */}
													</Spin>

												</Col>

												: graph =='pressureline' ? 

													<Col span={24} style={{padding:'10px'}}> 

														<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
															
															<button
																onClick={()=>{handlefullSize(fullsize === false ? true:false,'pressureline');}} 
																className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
															<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
																// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
																className="wellInfo" >
																{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
																<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
																<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {data && data.pressData && data.pressData.channel_name} <br />
															</p>
															<Plot
																data={pressureData}
																layout={pressurelinelayout}
																// config={{ responsive: true }}
																style={{ width: '100%', height: '800px' }}
																config={{
																	// displayModeBar: true,
																	responsive: true,
																	displaylogo : false,
																	modeBarButtonsToRemove: [
																		'pan2d',
																		'zoom2d',
																		'select2d',
																		'zoomIn2d',
																		'zoomOut2d',
																		'autoScale2d',
																		'resetScale2d',
																		'hoverClosestCartesian',
																		'hoverCompareCartesian',
																		'zoom3d',
																		'pan3d',
																		'resetViews3d',
																		'hoverClosest3d',
																		'hoverCompare3d',
																		'orbitRotation',
																		'tableRotation',
																		'resetCameraDefault3d',
																		'resetCameraLastSave3d',
																		'lasso2d'
																	],

																}}
															/>

																
															{/* </div> */}
														</Spin>

													</Col>

													: null}
							{/* </Card.Grid>

								</Card> */}


						</Row>
								
							
						
					</Col>
				</Row>
					
				{/* </Row> */}

				<Modal
					title={langdata && langdata.GraphConfigurations ? langdata.GraphConfigurations: 'Graph Configurations' }
					open={isModalOpen} 
					onOk={handleOk} 
					onCancel={handleCancel}
					maskClosable={false}
					footer={[
						<>
							<Row style={{
								margin: '10px',
								paddingLeft: '10px',
								paddingRight: '10px',
								background: '#FFFFFF',
								alignItems: 'center',
								marginTop : '10px'
							}}
							>
								<Col span={8}>
									{/* <Row>
										<Space>
											<Button onClick={handleReset}>RESET</Button>
										</Space>
									</Row> */}
								</Col>
								<Col span={16}>
									<Row justify="end">
										<Col>
											{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>User Type:</Text> */}
											<Space>
												<Button style={{ margin : '5px'}} onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
											</Space>
											<Space>&nbsp;</Space>
											<Space>
												<Button onClick={formik.handleSubmit} type="primary" style={{ margin : '5px'}} >{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
											</Space>
										</Col>
									</Row>
								</Col>
							</Row>
						</>
					]}
					width={800}
				>		
					<Divider/>
					<Row gutter={24}>
						<Col span={23}>
							{dasEnabled == true ?
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DAS ? langdata.DAS:'DAS'}</b></Typography>
								: 
								dtsEnabled == true ?
									<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS:'DTS'}</b></Typography>
									:
									<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.Pressure ? langdata.Pressure:'Pressure'}</b></Typography>
							}
						</Col>
						<Col  justify="end"  span={1}>
							<Tooltip placement="bottom" title={langdata && langdata.Reset ? langdata.Reset : 'Reset'}>
								<Avatar size="small" style={{ backgroundColor : '#1677FF'}}>							
									<ReloadOutlined onClick={handleReset} />	
								</Avatar>
							</Tooltip>
						</Col>
					</Row>
					{dasEnabled == true ? 
						<>
							<Row gutter={24} >
								<Col span={24}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.AmplitudeV ? langdata.AmplitudeV: 'Amplitude (V)'}  &nbsp;&nbsp;&nbsp;
										<Tooltip placement="bottom" title={langdata && langdata.Enteramplituderange ? langdata.Enteramplituderange:'Enter amplitude range'}>
											<Input 
												type='number'
												style={{width : '15%', height  :'25px'}}
												disabled = {formik.values.ampThresholdsOverride == true ? false : true}
												value={formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold}
												onChange={(e) => {formik.setFieldValue('minAmpThreshold', e.target.value);
												}}
											/>
											{' '} <span > -  </span>{' '}
											<Input  
												type='number'
												style={{width : '15%', height  :'25px'}}
												disabled = {formik.values.ampThresholdsOverride == true ? false : true}
												value={formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold}
												onChange={(e) => {formik.setFieldValue('maxAmpThreshold', e.target.value);
												}}
											/>
										</Tooltip>
									</Typography>
									<Slider
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minAmpThreshold', value[0]);formik.setFieldValue('maxAmpThreshold', value[1]);}} 
										marks={ampMarks} 
										disabled = {formik.values.ampThresholdsOverride == true ? false : true}
										value={[formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold, formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold]}/>
								</Col>								
								<Col span={24}>
									<Row>
										<Space>
											<Tooltip  placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch
													size="small" 
													onClick={(value) => {formik.setFieldValue('ampThresholdsOverride', value);}}
													checked={formik.values.ampThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>								
							</Row>
							<Divider/>
						</>
						: null }
					{dtsEnabled == true ?
						<>
							{dasEnabled == true ?
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS:'DTS'}</b></Typography>
								: null }
							<Row gutter={24} >
								<Col span={24}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.TemperatureC ? langdata.TemperatureC: 'Temperature (°C)'} &nbsp; &nbsp;
										<Tooltip placement="bottom" title={langdata && langdata.Entertemperaturerange ? langdata.Entertemperaturerange:'Enter temperature range'}>
											<Input 
												type='number'
												style={{width : '15%', height  :'25px'}}
												disabled = {formik.values.tempThresholdsOverride == true ? false : true}
												value={formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold}
												onChange={(e) => {formik.setFieldValue('minTempThreshold', e.target.value);
												}}
											/>
											{' '} <span > -  </span>{' '}
											<Input  
												type='number'
												style={{width : '15%', height  :'25px'}}
												disabled = {formik.values.tempThresholdsOverride == true ? false : true}
												value={formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold}
												onChange={(e) => {formik.setFieldValue('maxTempThreshold', e.target.value);
												}}
											/>
										</Tooltip>
									</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minTempThreshold', value[0]);formik.setFieldValue('maxTempThreshold', value[1]);}} 
										marks={tempMarks}
										disabled = {formik.values.tempThresholdsOverride == true ? false : true}
										value={[formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold, formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold]}
									/>
								</Col>					
								<Col span={24}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch size="small"
													onClick={(value) => {formik.setFieldValue('tempThresholdsOverride', value);}}
													checked={formik.values.tempThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>								
							</Row>
							<Divider/>
						</>
						: null }
					{pressEnabled == true ? 
						<>
							{dasEnabled == true || dtsEnabled == true ?
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.Pressure ? langdata.Pressure:'Pressure'}</b></Typography>
								: 
								null
							}
							<Row gutter={24} >
								<Col span={24}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.Pressure ? langdata.Pressure: 'Pressure'}&nbsp;&nbsp;&nbsp;
										<Tooltip placement="bottom" title={langdata && langdata.Enterpressurerange ? langdata.Enterpressurerange:'Enter pressure range'}>
											<Input 
												type='number'
												style={{width : '15%', height  :'25px'}}
												disabled = {formik.values.pressThresholdsOverride == true ? false : true}
												value={formik.values.pressThresholdsOverride == true ? formik.values.minPressureThreshold : formik.values.defaultminPressureThreshold}
												onChange={(e) => {formik.setFieldValue('minPressureThreshold', e.target.value);
												}}
											/>
											{' '} <span > -  </span>{' '}
											<Input  
												type='number'
												style={{width : '15%', height  :'25px'}}
												disabled = {formik.values.pressThresholdsOverride == true ? false : true}
												value={formik.values.pressThresholdsOverride == true ? formik.values.maxPressureThreshold : formik.values.defaultmaxPressureThreshold}
												onChange={(e) => {formik.setFieldValue('maxPressureThreshold', e.target.value);
												}}
											/>
										</Tooltip>
									</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minPressureThreshold', value[0]);formik.setFieldValue('maxPressureThreshold', value[1]);}} 
										marks={pressureMarks}
										disabled = {formik.values.pressThresholdsOverride == true ? false : true}
										value={[formik.values.pressThresholdsOverride == true ? formik.values.minPressureThreshold : formik.values.defaultminPressureThreshold, formik.values.pressThresholdsOverride == true ? formik.values.maxPressureThreshold : formik.values.defaultmaxPressureThreshold]}
									/>
								</Col>					
								<Col span={24}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch size="small"
													onClick={(value) => {formik.setFieldValue('pressThresholdsOverride', value);}}
													checked={formik.values.pressThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>								
							</Row>
							<Divider/>
						</>
						: null 
					}
					<Row gutter={24} >
						<Col span={24}>
							<Typography style={{ marginTop : '15px'}}>{langdata && langdata.Depth ? langdata.Depth +' (m)' : 'Depth (m)'}&nbsp;&nbsp;&nbsp;
								<Tooltip placement="bottom" title={langdata && langdata.Enterdepthrange ? langdata.Enterdepthrange:'Enter depth range'}>
									<Input 
										type='number' 
										style={{width : '15%', height  :'25px'}}
										value={drawerDepthFrom ? drawerDepthFrom : depthRangeFrom} 
										max={zonefrom} 
										min={zoneto} 
										placeholder={langdata && langdata.DepthRangeFrom ? langdata.DepthRangeFrom: 'Depth Range From'} 
										onChange={handleInputDepthRangefrom} 
									/>
									{' '} <span > -  </span>{' '}
									<Input 
										type='number' 
										style={{width : '15%', height  :'25px'}}
										value={drawerDepthTo ? drawerDepthTo : depthRangeTo} 
										max={zonefrom} min={zoneto} 
										placeholder={langdata && langdata.DepthRangeTo ? langdata.DepthRangeTo: 'Depth Range To'} 
										onChange={handleInputDepthRangeTo}
									/>
								</Tooltip>
							</Typography>
							<Slider style={{marginTop:'20px'}} range max={wellFrom ? wellFrom : tempendDepth} onChange={handleDepthChange} marks={depthMarks} value={[drawerDepthFrom ? drawerDepthFrom : depthRangeFrom, drawerDepthTo ? drawerDepthTo : depthRangeTo]} />
						</Col>
					</Row>
				</Modal>


				<Modal
					title={langdata && langdata.SaveforTraining ? langdata.SaveforTraining : 'Save for Training'}
					open={isDTSDataModalOpen} 
					onOk={handleOk} 
					onCancel={handleCancel}
					maskClosable={false}
					footer={[
						<Space key ='options'>
							<Button style={{ margin : '5px'}} onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
							<Button  type="primary" onClick={handleNavigateSimulation} style={{ margin : '5px'}} >{langdata && langdata.NEXT ? langdata.NEXT : 'NEXT'}</Button>
						</Space>
					]}
					// width={600}
				>
					<Row gutter={24}>
						<Col span={24}>
							{/* <Form.Item label={langdata && langdata.Time ? langdata.Time :  'Time'} >
									<Input value={dtsClickableData && dtsClickableData.x} disabled/>
								</Form.Item> */}
							<b>Time : </b>{dtsClickableData && dtsClickableData.x}
						</Col>
						<Col span={24} style={{ marginTop : '5px'}}>
							{/* <Form.Item label={langdata && langdata.Depth ? langdata.Depth :  'Depth'} >
									<Input value={dtsClickableData && dtsClickableData.y} disabled/>
								</Form.Item> */}
							<b>Depth : </b>{dtsClickableData && dtsClickableData.y}
						</Col>
						<Col span={24} style={{ marginTop : '5px'}}>
							{/* <Form.Item label={langdata && langdata.Temperature ? langdata.Temperature :  'Temperature' + ':'} >
									<Input value={dtsClickableData && dtsClickableData.z} disabled/>
								</Form.Item> */}
							<b>Temperature : </b>{dtsClickableData && dtsClickableData.z}
						</Col>
						<Col span={24} style={{ marginTop : '10px'}}>
							<Form.Item label={langdata && langdata.Label ? langdata.Label :  'Label'} >
								<Select Item
									style={{ width: '360px'}}
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.SelectEvent ? langdata.SelectEvent :'Select Event'}
									optionFilterProp="children"
											
									// defaultValue={['all']}
									options={allevents}
									onSelect={(value, options) => {handleSelectEvent(options);}}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Modal>
			</Content>

			<Spin fullscreen spinning={false} />

			{/* Add Vulnerable Point Details Modal Starts */}
			
			{/* Delete User Modal Ends */}
		</>
	);
}