export const MARKED_FOR_RETRAINING_LIST = 'MARKED_FOR_RETRAINING_LIST';
export const MARKED_FOR_RETRAINING_LIST_SUCCESS = 'MARKED_FOR_RETRAINING_LIST_SUCCESS';
export const MARKED_FOR_RETRAINING_LIST_ERROR = 'MARKED_FOR_RETRAINING_LIST_ERROR';

export const MARKED_FOR_RETRAINING_DETAILS = 'MARKED_FOR_RETRAINING_DETAILS';
export const MARKED_FOR_RETRAINING_DETAILS_SUCCESS = 'MARKED_FOR_RETRAINING_DETAILS_SUCCESS';
export const MARKED_FOR_RETRAINING_DETAILS_ERROR = 'MARKED_FOR_RETRAINING_DETAILS_ERROR';

export const APPROVED_LIST = 'APPROVED_LIST';
export const APPROVED_LIST_SUCCESS = 'APPROVED_LIST_SUCCESS';
export const APPROVED_LIST_ERROR = 'APPROVED_LIST_ERROR';

export const APPROVED_DETAILS = 'APPROVED_DETAILS';
export const APPROVED_DETAILS_SUCCESS = 'APPROVED_DETAILS_SUCCESS';
export const APPROVED_DETAILS_ERROR = 'APPROVED_DETAILS_ERROR';

export const REJECTED_LIST = 'REJECTED_LIST';
export const REJECTED_LIST_SUCCESS = 'REJECTED_LIST_SUCCESS';
export const REJECTED_LIST_ERROR = 'REJECTED_LIST_ERROR';

export const REJECTED_DETAILS = 'REJECTED_DETAILS';
export const REJECTED_DETAILS_SUCCESS = 'REJECTED_DETAILS_SUCCESS';
export const REJECTED_DETAILS_ERROR = 'REJECTED_DETAILS_ERROR';

export const MODEL_DATA = 'MODEL_DATA';
export const MODEL_DATA_SUCCESS = 'MODEL_DATA_SUCCESS';
export const MODEL_DATA_ERROR = 'MODEL_DATA_ERROR';

export const RETRAIN_HISTORY_DATA = 'RETRAIN_HISTORY_DATA';
export const RETRAIN_HISTORY_DATA_SUCCESS = 'RETRAIN_HISTORY_DATA_SUCCESS';
export const RETRAIN_HISTORY_DATA_ERROR = 'RETRAIN_HISTORY_DATA_ERROR';
export const RETRAIN_HISTORY_DATA_DETAILS = 'RETRAIN_HISTORY_DATA_DETAILS';

export const RETAINED_LIST = 'RETAINED_LIST';
export const RETAINED_LIST_SUCCESS = 'RETAINED_LIST_SUCCESS';
export const RETAINED_LIST_ERROR = 'RETAINED_LIST_ERROR';
export const RETRAINED_DETAILS_SUCCESS = 'RETRAINED_DETAILS_SUCCESS';

export const INPROGRESS_LIST = 'INPROGRESS_LIST';
export const INPROGRESS_LIST_SUCCESS = 'INPROGRESS_LIST_SUCCESS';
export const INPROGRESS_LIST_ERROR = 'INPROGRESS_LIST_ERROR';

export const MODEL_DETAILS = 'MODEL_DETAILS';
export const MODEL_DETAILS_SUCCESS = 'MODEL_DETAILS_SUCCESS';
export const MODEL_DETAILS_ERROR = 'MODEL_DETAILS_ERROR';

export const MODELCONFIG_LIST = 'MODELCONFIG_LIST';
export const MODELCONFIG_LIST_SUCCESS = 'MODELCONFIG_LIST_SUCCESS';
export const MODELCONFIG_LIST_ERROR = 'MODELCONFIG_LIST_ERROR';

export const MODELCONFIG_DETAILS = 'MODELCONFIG_DETAILS';
export const MODELCONFIG_DETAILS_SUCCESS = 'MODELCONFIG_DETAILS_SUCCESS';
export const MODELCONFIG_DETAILS_ERROR = 'MODELCONFIG_DETAILS_ERROR';

export const RELATED_ALARMS = 'RELATED_ALARMS';
export const RELATED_ALARMS_SUCCESS = 'RELATED_ALARMS_SUCCESS';
export const RELATED_ALARMS_ERROR = 'RELATED_ALARMS_ERROR';

export const RETRAIN_TYPE_DETAILS = 'RETRAIN_TYPE_DETAILS';

export const MODEL_HISTORY = 'MODEL_HISTORY';
export const MODEL_HISTORY_SUCCESS = 'MODEL_HISTORY_SUCCESS';
export const MODEL_HISTORY_ERROR = 'MODEL_HISTORY_ERROR';

export const MODEL_INFO_DETAILS = 'MODEL_INFO_DETAILS';

export const MODEL_DEPLOYMENT_INFO = 'MODEL_DEPLOYMENT_INFO';

export const MODEL_CLASSIFICATIONS = 'MODEL_CLASSIFICATIONS';
export const MODEL_CLASSIFICATIONS_SUCCESS = 'MODEL_CLASSIFICATIONS_SUCCESS';
export const MODEL_CLASSIFICATIONS_ERROR = 'MODEL_CLASSIFICATIONS_ERROR';

export const RETRAIN_HISTORY_BATCH = 'RETRAIN_HISTORY_BATCH';
export const RETRAIN_HISTORY_BATCH_SUCCESS = 'RETRAIN_HISTORY_BATCH_SUCCESS';
export const RETRAIN_HISTORY_BATCH_ERROR = 'RETRAIN_HISTORY_BATCH_ERROR';

export const RETRAIN_MODEL_TYPE = 'RETRAIN_MODEL_TYPE';
export const RETRAIN_MODEL_TYPE_SUCCESS = 'RETRAIN_MODEL_TYPE_SUCCESS';
export const RETRAIN_MODEL_TYPE_ERROR = 'RETRAIN_MODEL_TYPE_ERROR';