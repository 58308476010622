import moment from 'moment';
import { FIBER_SPLICE_ADD, FIBER_SPLICE_ADD_ERROR, FIBER_SPLICE_ADD_SUCCESS, FIBER_SPLICE_DELETE, FIBER_SPLICE_DELETE_ERROR, FIBER_SPLICE_DELETE_SUCCESS, FIBER_SPLICE_LIST, FIBER_SPLICE_LIST_ERROR, FIBER_SPLICE_LIST_SUCCESS } from './constants';


export default function fiberManagementReducer(state, action) {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);
	let fiber_splice_data = [];
	let fiber_splice_data_pagination = {};
	switch (action.type) {
	case FIBER_SPLICE_LIST :
		return { ...state, pending: true };
	case FIBER_SPLICE_LIST_SUCCESS :
		if (action && action.payload) {
			fiber_splice_data_pagination.total = action.payload.total ? action.payload.total : 0;
			fiber_splice_data_pagination.limit = action.payload.limit ? action.payload.limit : 10;
			fiber_splice_data_pagination.skip = action.payload.skip ? action.payload.skip : 0;
			fiber_splice_data_pagination.current = action.page ? action.page : 1;
			fiber_splice_data_pagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}
		action.payload && action.payload.data.map((item) => {
			const date = moment.utc(item.spliceDate).toDate();
			date.toString();
			const newdate = moment(date, 'YYYY-MM-DD').format(settingsdateformat);
			const newTime = moment(date, 'HH:mm:ss').format(settingstimeformat);
			fiber_splice_data.push({
				id : item.id,
				pidsName : item.pidsinfo ? item.pidsinfo.name : '-',
				ODMeter: item.ODMeter,
				channelName : item.channelinfo ? item.channelinfo.name : '-',
				date : item.spliceDate ?  newdate + ' ' + newTime : '-',
				status : item.status ? item.status : '-',
				lengthDifferenceInMetre : item.lengthDifferenceInMetre,
				pipelineChainageFrom : item.pipelineChainageFrom,
				pipelineChainageTo : item.pipelineChainageTo
			});
		});
		return { ...state, fiber_splice_data: fiber_splice_data, fiber_splice_data_pagination : fiber_splice_data_pagination, pending: false };
	case FIBER_SPLICE_LIST_ERROR :
		return { ...state, error: true, pending: false };
	case FIBER_SPLICE_ADD :
		return { ...state, pending: true };
	case FIBER_SPLICE_ADD_SUCCESS :
		return { ...state, pending: false };
	case FIBER_SPLICE_ADD_ERROR :
		return { ...state, error: true, pending: false };
	case FIBER_SPLICE_DELETE :
		return { ...state, pending: true };
	case FIBER_SPLICE_DELETE_SUCCESS :
		return { ...state, pending: false };
	case FIBER_SPLICE_DELETE_ERROR :
		return { ...state, error: true, pending: false };
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}