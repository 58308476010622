import { USER_TYPES_LIST, USER_TYPES_LIST_SUCCESS, USER_TYPES_LIST_ERROR }  from './constants';
import { USERS_LIST, USERS_LIST_SUCCESS, USERS_LIST_ERROR }  from './constants';
import { USER_DETAILS, USER_DETAILS_SUCCESS, USER_DETAILS_ERROR }  from './constants';
import { SAVE_USER, SAVE_USER_SUCCESS, SAVE_USER_ERROR } from './constants';
import { UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR } from './constants';
import { DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_ERROR } from './constants';
import { UPDATE_USER_NOTIFICATION, UPDATE_USER_NOTIFICATION_SUCCESS, UPDATE_USER_NOTIFICATION_ERROR } from './constants';
import { USER_RESET_PASSWORD, USER_RESET_PASSWORD_SUCCESS, USER_RESET_PASSWORD_ERROR } from './constants';
import { USER_CHANGE_PASSWORD, USER_CHANGE_PASSWORD_SUCCESS, USER_CHANGE_PASSWORD_ERROR } from './constants';

export default function usersReducer(state, action) {
	let users = [];
	let pagination = {};
	let userTypes = [];
  
	switch (action.type) {
	case USER_TYPES_LIST:
		return { ...state, loading: true };

	case USER_TYPES_LIST_SUCCESS:
		

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.userType = item.userType ? item.userType : '<Not Set>';
				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';

				return userTypes.push(data);
			});

			return { ...state, userTypes: userTypes, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, userTypes: userTypes, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case USER_TYPES_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case USERS_LIST:
		return { ...state, loading: true };
      
	case USERS_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
			pagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.usertypeId = item.usertypeId ? item.usertypeId : 0;
				data.employeeId = item.employeeId ? item.employeeId : '';
				data.firstName = item.firstName ? item.firstName : '';
				data.lastName = item.lastName ? item.lastName : '';
				data.cellPhone = item.cellPhone ? item.cellPhone : '';
				data.email = item.email ? item.email : '';
				data.isVerified = item.isVerified ? item.isVerified : false;
				data.isDeleted = item.isDeleted ? item.isDeleted : false;
				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';

				return users.push(data);
			});
          
			return { ...state, users: users, pagination: pagination, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, users: users, pagination: pagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}
      
	case USERS_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case USER_DETAILS:
		return { ...state, loading: true, error: false };

	case USER_DETAILS_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case USER_DETAILS_ERROR:
		return { ...state, loading: false, error: true };

	case SAVE_USER:
		return { ...state, loading: true, error: false };

	case SAVE_USER_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case SAVE_USER_ERROR:
		return { ...state, loading: false, error: true };

	case UPDATE_USER:
		return { ...state, loading: true, error: false };

	case UPDATE_USER_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case UPDATE_USER_ERROR:
		return { ...state, loading: false, error: true };
        
	case DELETE_USER:
		return { ...state, loading: true, error: false };

	case DELETE_USER_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case DELETE_USER_ERROR:
		return { ...state, loading: false, error: true };

	case UPDATE_USER_NOTIFICATION:
		return { ...state, loading: true, error: false };

	case UPDATE_USER_NOTIFICATION_SUCCESS:
		return { ...state, loading: false };

	case UPDATE_USER_NOTIFICATION_ERROR:
		return { ...state, loading: false, error: true };

	case USER_RESET_PASSWORD:
		return { ...state, loading: true, error: false };

	case USER_RESET_PASSWORD_SUCCESS:
		return { ...state, loading: false };

	case USER_RESET_PASSWORD_ERROR:
		return { ...state, loading: false, error: true };
      
	case USER_CHANGE_PASSWORD:
		return { ...state, loading: true, error: false };

	case USER_CHANGE_PASSWORD_SUCCESS:
		return { ...state, loading: false };

	case USER_CHANGE_PASSWORD_ERROR:
		return { ...state, loading: false, error: true };

	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}