export const REALTIME_EVENTS_LIST = 'REALTIME_EVENTS_LIST';
export const REALTIME_EVENTS_SUCCESS = 'REALTIME_EVENTS_SUCCESS';
export const REALTIME_EVENTS_ERROR = 'REALTIME_EVENTS_ERROR';
export const REALTIME_EVENTS_DETAILS = 'REALTIME_EVENTS_DETAILS';

export const GRAPH_DATA = 'GRAPH_DATA';
export const GRAPH_DATA_SUCCESS = 'GRAPH_DATA_SUCCESS';
export const GRAPH_DATA_ERROR = 'GRAPH_DATA_ERROR';


export const EVENT_VIEW = 'EVENT_VIEW';
export const EVENT_VIEW_SUCCESS = 'EVENT_VIEW_SUCCESS';
export const EVENT_VIEW_ERROR = 'EVENT_VIEW_ERROR';
