import { WELL_SEISMIC_TYPE_LIST, WELL_SEISMIC_TYPE_LIST_ERROR, WELL_SEISMIC_TYPE_LIST_SUCCESS, WELL_SEISMIC_TYPE_UPDATE_DATA } from './constants';

export default function seismicTypeReducer(state, action) {
	switch (action.type) {
	case WELL_SEISMIC_TYPE_LIST :
		return { ...state, loading : true, error : false };
	case WELL_SEISMIC_TYPE_LIST_SUCCESS :
		return { ...state,wellSeisemicType : action.payload, loading : false, error : false};
	case WELL_SEISMIC_TYPE_LIST_ERROR : 
		return { ...state, loading : false, error : true };
	case WELL_SEISMIC_TYPE_UPDATE_DATA :
		return { ...state, updateData : action.payload };
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}