import { OPEN_MODAL, WELL_GRAPH_LIST, WELL_GRAPH_LIST_ERROR, WELL_GRAPH_LIST_SUCCESS, WELL_GRAPH_UPDATE_DATA } from './constants';

export default function wellGraphsReducer(state, action) {
	// let sandIngressData = [];
	switch (action.type) {
	case OPEN_MODAL :
		return { ...state, openModal : action.payload };
	case WELL_GRAPH_LIST :
		return { ...state, loading : true, error : false};
	case WELL_GRAPH_LIST_SUCCESS :
		return { ...state, loading : false, error : false, sandIngressData : action.payload};
	case WELL_GRAPH_LIST_ERROR : 
		return { ...state, loading : false, error : true};
	case WELL_GRAPH_UPDATE_DATA:
		return { ...state, updateData : action.payload };
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}