import React, { useEffect, useReducer, useState } from 'react';


import { Empty, Row, Col, Typography, Space, Tooltip, Button, Table, Select,DatePicker, message, Spin, Popover, Avatar, Card} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, EditOutlined, EyeOutlined, InfoOutlined, AimOutlined} from '@ant-design/icons';
import axios from 'axios';
import simulationdata from './reducer';
import { SIMULATION_LIST, SIMULATION_LIST_ERROR, SIMULATION_LIST_SUCCESS } from './constants';
import { useAuth } from '../Context';
import moment from 'moment';
import dayjs from 'dayjs';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';


const { Title } = Typography;
const { RangePicker } = DatePicker;


export default function Simulations() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const navigate = useNavigate();

	const initalState = {simulation_data : [], pending: true, error: false};
	const [data, dispatch] = useReducer(simulationdata, initalState);
	const { getAllEvents, eventsData, getPids, pidsData } = useAuth();
	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';

	const [pidsId, setPidsId] = useState('all');
	const [activityId, setActivityId] = useState('all');
	const [status, setStatus] = useState(null);
	const [filterStartDate, setStartFilterDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate() - 30));
	const [filterEndDate, setEndFilterDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate()));

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const statuslist = [
		{
			value: null,
			label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`,
		},
		{
			value: 'New',
			label: `${langdata && langdata.New ? langdata.New:'New'}`,
		},
		{
			value: 'Inprogress',
			label: `${langdata && langdata.Inprogress ? langdata.Inprogress:'Inprogress'}`,
		},
		{
			value: 'Submitted',
			label: `${langdata && langdata.Submitted ? langdata.Submitted:'Submitted'}`,
		},
		{
			value: 'Backup Initiated',
			label: `${langdata && langdata.BackupInitiated ? langdata.BackupInitiated:'Backup Initiated'}`,
		},
		{
			value: 'Backup Started',
			label: `${langdata && langdata.BackupStarted ? langdata.BackupStarted:'Backup Started'}`,
		},
		{
			value: 'Backup Completed',
			label: `${langdata && langdata.BackupCompleted ? langdata.BackupCompleted:'Backup Completed'}`,
		},
		{
			value: 'Backup Failed',
			label: `${langdata && langdata.BackupFailed ? langdata.BackupFailed:'Backup Failed'}`,
		},
		{
			value: 'No Data',
			label: `${langdata && langdata.NotEnoughDataSamples ? langdata.NotEnoughDataSamples:'Not Enough Data Samples'}`,
		},
		{
			value: 'Scp Initiated',
			label: `${langdata && langdata.DataCopyingInitiated ? langdata.DataCopyingInitiated:'Data Copying Initiated'}`,
		},
		{
			value: 'Scp Started',
			label: `${langdata && langdata.DataCopyingStarted ? langdata.DataCopyingStarted:'Data Copying Started'}`,
		},
		{
			value: 'Scp Completed',
			label: `${langdata && langdata.DataCopyingCompleted ? langdata.DataCopyingCompleted:'Data Copying Completed'}`,
		},
		{
			value: 'Scp Failed',
			label: `${langdata && langdata.DataCopyingFailed ? langdata.DataCopyingFailed:'Data Copying Failed'}`,
		}
	];
	
	const pidsList = [];
	const eventsList = [];

	useEffect(() => {
		if(featuresModule.retrainingSimulationsEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 1133);

	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		getAllEvents(authtoken);
		getPids(authtoken);
	},[authtoken]);

	useEffect(() => {
		loadSimulation();
	},[authtoken, pidsId, activityId, status,filterEndDate, filterStartDate]);

	const loadSimulation = () => {
		dispatch({ type : SIMULATION_LIST });
		let data = '';

		// let params = `?&startTime[$gte]=${startdatetime}&endTime[$lte]=${enddatetime}&activityDate=${null}`;
		let params = '';

		// let params = '?';


		if(pidsId !== 'all') {
			params+=`&pidsinfoId=${pidsId}`;
		}
		if(activityId !== 'all') {
			params+=`&activityType=${activityId}`;
		}
		if(status){
			params+=`&status=${status}`;
		}
		if(filterStartDate && filterEndDate) {
			const enddatetime = moment.utc(filterEndDate.setHours(23,59,59,999)).toISOString();
			const startdatetime = moment.utc(filterStartDate.setHours(0,0,0,0)).toISOString();
			params += `&createdAt[$gte]=${startdatetime}&createdAt[$lte]=${enddatetime}`;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/simulation-list?$sort[createdAt]=-1${params}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if(response.status === 200) {
					dispatch({ type : SIMULATION_LIST_SUCCESS, payload : response.data});
				} else {
					dispatch({ type : SIMULATION_LIST_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : SIMULATION_LIST_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};

	pidsList.push({
		value : 'all',
		label : `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	});

	pidsData && pidsData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name.toUpperCase(),
		});
	});

	pidsList.sort((a,b) => { return a.label - b.label; });

	eventsList.push({
		value : 'all',
		label : `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	});

	eventsData && eventsData.map((item) => {
		eventsList.push({
			value : item.id,
			label: item.eventType
		});
	});

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const columns = [
		{
			title: `${langdata && langdata.PidsName ? langdata.PidsName:'Pids Name'}`,
			dataIndex: 'pidsinfoName',
			key: 'pidsinfoName',
		},
		{
			title: `${langdata && langdata.ChannelName ? langdata.ChannelName:'Channel Name'}`,
			dataIndex: 'channelinfoName',
			key: 'channelinfoName',
		},
		{
			title: `${langdata && langdata.ActivityDate ? langdata.ActivityDate:'Activity Date'}`,
			dataIndex: 'activityDate',
			key: 'activityDate',
		},
		{
			title: `${langdata && langdata.ActivityName ? langdata.ActivityName:'Activity Name'}`,
			dataIndex: 'activityName',
			key: 'activityName',
		},
		{
			title: `${langdata && langdata.DurationinMinutes ? langdata.DurationinMinutes:'Duration in Minutes'}`,
			dataIndex: 'duration',
			key: 'duration',
		}
	];

	if(oneviewType.toLowerCase() == 'well' ) {
		columns.push(
			{
				title: `${langdata && langdata.WellName ? langdata.WellName:'Well Name'}`,
				dataIndex: 'wellinfoName',
				key: 'wellinfoName'
			},{
				title: `${langdata && langdata.System ? langdata.System:'System'}`,
				dataIndex: 'system',
				key: 'system'
			});
	}

	columns.push({
		title: `${langdata && langdata.Status ? langdata.Status:'Status'}`,
		dataIndex: 'status',
		key: 'status'
	},
	{
		title: `${langdata && langdata.Actions ? langdata.Actions:'Actions'}`,
		key: 'action',
		align:'center',
		render: (_, record) => (
			<Space style={{justifyContent:'center',display:'flex'}} size="middle">
				{record.step != 4 ?
					<a title={langdata && langdata.EditSimulation ? langdata.EditSimulation:'Edit Simulation'}  onClick={() => {navigate(('/edit-simulation'), { state: { simulationId : record.id, simulation_data : record} });}}><EditOutlined/></a>
					:
					<a title={langdata && langdata.ViewSimulation ? langdata.ViewSimulation:'View Simulation'}  onClick={() => {navigate(('/view-simulation'), { state: { simulationId : record.id, simulation_data : record} });}}><EyeOutlined/></a>
				}
				{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
			</Space>
		)
	});

	const handleDateChange = (date) => {

		setStartFilterDate(new Date(date && date[0] && date[0].$d));
		setEndFilterDate(new Date(date && date[1] && date[1].$d));

	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};


	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.simulationcontent ? langdata.simulationcontent:'This module helps to enter the details  of new simulation activities that are performed in the field.'}</p>
		</Card>
	);
  
	return (
		<>
			<Content>
				<Row
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
				>
					<Col span={4}>
						<Row>
							<Space>
								<AimOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.Simulations ? langdata.Simulations:'Simulations'}</Title>
						</Row>
					</Col>

					<Col style={{ display : 'flex', justifyContent : 'right'}} span={20} >
						<Row>
							<Col>
								<Tooltip placement="bottom" title= {langdata && langdata.Filterbypids ? langdata.Filterbypids:'Filter by pids'}>
									<Space>
										<Select Item
											showSearch
											filterOption={filterOption}
											style={{ minWidth: '150px', maxWidth:'150px', margin:'5px'}}
											placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS:'Select PIDS'}
											optionFilterProp="children"
											// value={regionId}
											defaultValue={'all'}
											options={pidsList}
											onSelect={(e)=>setPidsId(e)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyactivity ? langdata.Filterbyactivity:'Filter by activity'}>
									<Space>
										<Select Item
											showSearch
											filterOption={filterOption}
											style={{ minWidth: '150px', maxWidth:'150px', margin:'5px'}}
											placeholder={langdata && langdata.SelectActivity ? langdata.SelectActivity:'Select Activity'}
											optionFilterProp="children"
											// value={regionId}
											defaultValue={'all'}
											options={eventsList}
											onSelect={(e)=>setActivityId(e)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbystatus ? langdata.Filterbystatus:'Filter by status'}>
									<Space>
										<Select Item
											showSearch
											filterOption={filterOption}
											style={{ minWidth: '150px', maxWidth:'150px', margin:'5px'}}
											placeholder={langdata && langdata.SelectStatus ? langdata.SelectStatus:'Select Status'}
											optionFilterProp="children"
											// value={regionId}
											defaultValue={null}
											options={statuslist}
											onSelect={(e)=>setStatus(e)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								{/* <Tooltip placement="bottom" title={langdata && langdata.Filterbycreateddate ? langdata.Filterbycreateddate:'Filter by created date'}> */}
								<Space>
									<Tooltip title={moment(filterStartDate).format(settingsdateformat) + ' - ' + moment(filterEndDate).format(settingsdateformat)} placement='bottom'>

										<RangePicker 
											allowClear={false} format={settingsdateformat}
											style={{minWidth: '220px', maxWidth:'220px', margin:'5px'}}
											// defaultValue={[dayjs(new Date()),dayjs(new Date())]}
											onChange={handleDateChange} disabledDate={disabledDate}
											defaultValue={[dayjs(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate() - 30)),dayjs(new Date())]}
										/>
									</Tooltip>
								</Space>
								{/* </Tooltip> */}
								<Space>&nbsp;&nbsp;</Space>
	
								<Tooltip placement="bottom" title={langdata && langdata.NewSimulation ? langdata.NewSimulation:'New Simulation'}>

									<Space>

										<Button
											style={{ margin:'5px'}}
											type='primary'
											onClick={() => navigate('/add-simulations')}
										><PlusOutlined/>{langdata && langdata.New ? langdata.New:'New'}</Button>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.Simulations ? langdata.Simulations:'Simulations'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>
					
				</Row>

				{/* </Space> */}

				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data.simulation_data.length > 0 ? (
							<Table
								scroll={{
									x: 900,
								}}
								columns={columns} dataSource={data.simulation_data}  onChange = { loadSimulation }/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>
			</Content>            
			<Spin spinning={data.pending} fullscreen />
		</>
	);
}