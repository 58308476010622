import React, { useEffect, useReducer, useState } from 'react';

import { Row, Col, Typography, Space, Table, Empty, Select, Tooltip, Button, Modal, Form, Input, Spin, message, Divider, Popover, Avatar, Card} from 'antd';
import { Content } from 'antd/es/layout/layout';

import { SplitCellsOutlined, InfoOutlined } from '@ant-design/icons';
import axios from 'axios';

import channelInfoReducer from './reducer';
import { CHANNELINFO_DETAILS, CHANNELINFO_LIST, CHANNELINFO_LIST_ERROR, CHANNELINFO_LIST_SUCCESS, MODEL_NAMES } from './constants';
import { useAuth } from '../../Context';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

const { Title } = Typography;

export default function ChannelInfo() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	const initalState = {channelInfo : [], pending: false, error: false, channelDetails : [], modelNames : [] };
	const [data, dispatch] = useReducer(channelInfoReducer, initalState);
	const {getRegion, regiondata} = useAuth();
	const navigate = useNavigate();

	const [regionId, setRegionId] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedId, setSelectedId] = useState();
	const [modelPredictionLevel, setModelPredictionLevel] = useState();

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const modalData = [];
	const modalType = [];

	modalType.push({
		value : 'single',
		label : langdata && langdata.Single ? langdata.Single : 'Single'
	},
	{
		value : '2level_4model',
		label : langdata && langdata.level_4model ? langdata.level_4model : '2level_4model'
	},
	// Multiple 3 level with 6 model 
	// {
	// 	value : '3level_6model',
	// 	label : '3level_6model'
	// }
	);


	// modalData.push({
	// 	value : 'cnn1d_inter',
	// 	label : 'cnn1d_inter'
	// },
	// {
	// 	value : 'cnn3d_fft',
	// 	label : 'cnn3d_fft'
	// },
	// {
	// 	value : 'cnn2d_3position',
	// 	label : 'cnn2d_3position'
	// });

	useEffect(() => {

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/modeltype`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					dispatch({ type : MODEL_NAMES, payload : response.data });
				}
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});

	},[authtoken]);

	const level1Content = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.level1Content ? langdata.level1Content : 'This level predicts whether an observed activity represents a threat or not. It serves as the initial filter to identify potentially concerning actions or events.'}</p>
		</Card>
	);
	
	const level2Content = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.level2Content ? langdata.level2Content : 'Once a potential threat is identified, this level classifies it into one of the main categories: manual digging, machine digging, or vehicle movement. This step provides deeper insights into the nature of the detected activity.'}</p>
		</Card>
	);
	
	const level3Content = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.level3Content ? langdata.level3Content : 'After determining the main category, this level further refines the classification by providing sub-categories within each main category.'}</p>
		</Card>
	);
	
	const  modelTypeOverrideContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p><b>{langdata && langdata.level3Content ? langdata.level3Content : 'Default Mode'}</b>: {langdata && langdata.modelTypeOverrideContent1 ? langdata.modelTypeOverrideContent1 : 'In default mode, the system automatically selects the appropriate model based on the bin size of the default channels.'}</p>
			<p>{langdata && langdata.modelTypeOverrideContent2 ? langdata.modelTypeOverrideContent2 : 'If the bin size falls within the range of 0 to 3 meter, the system will automatically utilize the CNN2D3Position model for classification'}</p>
			<br/>
			<p><b>{langdata && langdata.ManualMode ? langdata.ManualMode : 'Manual Mode'}</b>: {langdata && langdata.modelTypeOverrideContent3 ? langdata.modelTypeOverrideContent3 : 'Manual mode allows users to manually select the desired model for analysis based on their specific requirements.'}</p>
			<p>{langdata && langdata.modelTypeOverrideContent4 ? langdata.modelTypeOverrideContent4 : 'Users can choose between two available models: CNN2D3Position or CNN1D1Position'}</p>
			<br/>
		</Card>
	);
	
	const  binsizeLowerContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.binsizeLowerContent ? langdata.binsizeLowerContent : 'The field allows users to specify the lower limit of binsize, guiding the underlying system to select the cnn2d_3position model by default.'}</p>
		</Card>
	);
	
	const  binsizeUpperContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.binsizeUpperContent ? langdata.binsizeUpperContent : 'The field allows users to specify the upper limit of binsize, guiding the underlying system to select the cnn2d_3position model by default.'}</p>
		</Card>
	);
	
	const modelTypeOverrideContentForModal = (
		<Space>
			<label>{langdata && langdata.ModelTypeOverride ? langdata.ModelTypeOverride : 'Model Type Override'}</label>
			<Popover title={langdata && langdata.ModelTypeOverride ? langdata.ModelTypeOverride : 'Model Type Override'} content={ modelTypeOverrideContent} trigger="click">
				<Avatar size="small"  style={{ width : '18px', height : '18px'}}><InfoOutlined /></Avatar>
			</Popover>
		</Space>
	);
	
	const modelBinsizeLower = (
		<Space>
			<label>{langdata && langdata.DefaultBinsizeLowerLimit ? langdata.DefaultBinsizeLowerLimit : 'Default Binsize Lower Limit'}</label>
			<Popover title={langdata && langdata.DefaultBinsizeLowerLimit ? langdata.DefaultBinsizeLowerLimit : 'Default Binsize Lower Limit'} content={ binsizeLowerContent} trigger="click">
				<Avatar size="small"  style={{ width : '18px', height : '18px'}}><InfoOutlined /></Avatar>
			</Popover>
		</Space>
	);
	
	const modelBinsizeUpper = (
		<Space>
			<label>{langdata && langdata.DefaultBinsizeUpperLimit ? langdata.DefaultBinsizeUpperLimit : 'Default Binsize Upper Limit'}</label>
			<Popover title={langdata && langdata.DefaultBinsizeUpperLimit ? langdata.DefaultBinsizeUpperLimit : 'Default Binsize Upper Limit'} content={ binsizeUpperContent} trigger="click">
				<Avatar size="small"  style={{ width : '18px', height : '18px'}}><InfoOutlined /></Avatar>
			</Popover>
		</Space>
	);

	data.modelNames.data && data.modelNames.data.map((item) => {
		modalData.push({
			value : item.name,
			label : item.name
		});
	});

	const columns = [
		{
			title: langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: langdata && langdata.RegionName ? langdata.RegionName : 'Region Name',
			dataIndex: 'regioninfoId',
			key: 'regioninfoId',
		},
		{
			title: langdata && langdata.PIDSName ? langdata.PIDSName : 'PIDS Name',
			dataIndex: 'pidsinfoId',
			key: 'pidsinfoId',
		},
		{
			title: langdata && langdata.Channel ? langdata.Channel : 'Channel',
			dataIndex: 'channelId',
			key: 'channelId',
		},
		{
			title: langdata && langdata.BinSize ? langdata.BinSize : 'Bin Size',
			dataIndex: 'binSize',
			key: 'binSize',
		},
		{
			title: langdata && langdata.AmplitudeNumber ? langdata.AmplitudeNumber : 'Amplitude Number',
			dataIndex: 'amplitudeNumber',
			key: 'amplitudeNumber',
		},
		{
			title:  langdata && langdata.StartPosition ? langdata.StartPosition : 'Start Position',
			dataIndex: 'startPos',
			key: 'startPos',
		},
		{
			title: langdata && langdata.Profile ? langdata.Profile : 'Profile',
			dataIndex: 'profile',
			key: 'profile',
			render: (_, record) => (
				<Space style={{justifyContent:'',display:'flex'}} size="middle">
					<Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(( '/channel-width'),{ state: { data : record.profile.width, id : record.id, channel : record.name } });}}>{langdata && langdata.ChannelWidth ? langdata.ChannelWidth : 'Channel Width'}</Button>
					| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(( '/convertion'),{ state: { data : record.profile.convertion, id : record.id, channel : record.name } });}}>{langdata && langdata.EventConversion ? langdata.EventConversion : 'Event Conversion'}</Button>
					| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(( '/prediction-convertion'),{ state: { data : record.predictedEventConfirmation, id : record.id, channel : record.name } });}}>{langdata && langdata.PredictionConversion ? langdata.PredictionConversion : 'Prediction Conversion'}</Button>
					{/* | <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(( '/metrics'),{ state: { data : record.profile['escalation metrics'], id : record.id } });}}>Alarm Escalation</Button> */}
					| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => showModal(record)}>{langdata && langdata.ModelDetails ? langdata.ModelDetails : 'Model Details'}</Button>
				</Space>

			)
		}
	];

	useEffect(() => {
		if(featuresModule.channelInfoEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 401);
		if(!authtoken){
			navigate('/');
		}
		getRegion(authtoken);
	}, [authtoken]);

	useEffect(() => {
		loadChnnelInfo();
	},[authtoken, regionId]);

	const loadChnnelInfo = () => {
		dispatch({ type : CHANNELINFO_LIST});

		let params = '';
		
		if(regionId !== null) {
			params+=`&regioninfoId=${regionId}`;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/channelinfo?$sort[id]=1${params}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					dispatch({ type : CHANNELINFO_LIST_SUCCESS, payload : response.data});
				} else {
					dispatch({ type : CHANNELINFO_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : CHANNELINFO_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	let regions=[{value:null,
		label:langdata && langdata.ALL ? langdata.ALL :'ALL'
	}];

	regiondata && regiondata.map((region)=>{
		regions.push({
			value:region.id,
			label:region.regionName.toUpperCase()});
	});

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());
	
	const showModal = (value) => {
		setModelPredictionLevel(value.modelPredictionLevel);
		setSelectedId(value.id);
		dispatch({ type : CHANNELINFO_DETAILS, payload : value });
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setModelPredictionLevel(null);
	};

	useEffect(() => {
		setModelPredictionLevel(data.channelDetails && data.channelDetails.modelPredictionLevel);
	},[data.channelDetails]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			model_name : data.channelDetails && data.channelDetails.profile && data.channelDetails.profile.model_name,
			normalisation : data.channelDetails && data.channelDetails.profile && data.channelDetails.profile.normalisation,
			max_threshold : data.channelDetails && data.channelDetails.profile && data.channelDetails.profile.max_threshold,
			default_threshold : data.channelDetails && data.channelDetails.profile && data.channelDetails.profile.default_threshold,
			normalisation_threshold : data.channelDetails && data.channelDetails.profile && data.channelDetails.profile.normalisation_threshold,
			alarm_grouping_distance : data.channelDetails && data.channelDetails.profile && data.channelDetails.profile.alarm_grouping_distance,
			modelTypeOverride : data.channelDetails && data.channelDetails.modelTypeOverride,
			modelPredictionLevel : data.channelDetails && data.channelDetails.modelPredictionLevel,
			predictionDecisionThreshold : data.channelDetails && data.channelDetails.predictionDecisionThreshold,
			l1Model : data.channelDetails && data.channelDetails.l1Model,
			l2ModelThreat : data.channelDetails && data.channelDetails.l2ModelThreat,
			l2ModelNonthreat : data.channelDetails && data.channelDetails.l2ModelNonthreat,
			l3ModelMachinedigging : data.channelDetails && data.channelDetails.l3ModelMachinedigging,
			l3ModelManualdigging : data.channelDetails && data.channelDetails.l3ModelManualdigging,
			l3ModelVehiclemovement : data.channelDetails && data.channelDetails.l3ModelVehiclemovement,
			overrideLowerLimit : data.channelDetails && data.channelDetails.overrideLowerLimit,
			overrideUpperLimit : data.channelDetails && data.channelDetails.overrideUpperLimit
		},
		onSubmit: (values) => {
			const {model_name, normalisation, max_threshold, default_threshold, normalisation_threshold, alarm_grouping_distance, modelTypeOverride, modelPredictionLevel, 
				predictionDecisionThreshold, l1Model, l2ModelThreat, l2ModelNonthreat, l3ModelMachinedigging, l3ModelManualdigging, l3ModelVehiclemovement, overrideLowerLimit, overrideUpperLimit} = values;
			const id = parseInt(selectedId);

			if(modelPredictionLevel == 'single'){
				if(!model_name || !normalisation ||	!modelPredictionLevel){
					Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
					return false;
				} 

			}else {
				if(!model_name || !normalisation || !max_threshold || !modelPredictionLevel || !l1Model || !l2ModelThreat || !l2ModelNonthreat || !l3ModelMachinedigging	|| !l3ModelManualdigging ||	!l3ModelVehiclemovement){
					Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
					return false;
				} 
			}

			let data;
			if(modelPredictionLevel == 'single'){

				data = JSON.stringify({
					'id': id,
					'model_name' : model_name,
					'max_threshold': max_threshold,
					'normalisation': normalisation,
					'default_threshold': default_threshold,
					'alarm_grouping_distance': alarm_grouping_distance,
					'normalisation_threshold': normalisation_threshold,
					'modelTypeOverride' : modelTypeOverride,
					'modelPredictionLevel' : modelPredictionLevel,
					'overrideLowerLimit' : overrideLowerLimit,
					'overrideUpperLimit' : overrideUpperLimit
				});
			} else {
				data = JSON.stringify({
					'id': id,
					'model_name' : model_name,
					'max_threshold': max_threshold,
					'normalisation': normalisation,
					'default_threshold': default_threshold,
					'alarm_grouping_distance': alarm_grouping_distance,
					'normalisation_threshold': normalisation_threshold,
					'modelTypeOverride' : modelTypeOverride,
					'modelPredictionLevel' : modelPredictionLevel,
					'predictionDecisionThreshold' : parseFloat(predictionDecisionThreshold),
					'l1Model' : l1Model,
					'l2ModelThreat' : l2ModelThreat,
					'l2ModelNonthreat' : l2ModelNonthreat,
					'l3ModelMachinedigging' : l3ModelMachinedigging,
					'l3ModelManualdigging' : l3ModelManualdigging,
					'l3ModelVehiclemovement' : l3ModelVehiclemovement,
					'overrideLowerLimit' : overrideLowerLimit,
					'overrideUpperLimit' : overrideUpperLimit
				});
			}

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/channel-profile`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 ){
						Modal.success({ title: langdata && langdata.Channelprofileupdatedsuccessfully ? langdata.Channelprofileupdatedsuccessfully : 'Channel profile updated successfully' });
						loadChnnelInfo();
					} else {
						Modal.warning({ title: langdata && langdata.Channelprofilenotupdated ? langdata.Channelprofilenotupdated : 'Channel profile not updated' });
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
			setIsModalOpen(false);
		}
	});

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p> {langdata && langdata.channelinfodescription1 ? langdata.channelinfodescription1 : 'The channel details of each of the PIDS controllers like Channel name, region name, PIDS name, \
				Channel number, Bin Size, Amplitude number, start position of the particular channel are displayed. \
				There is also a profile section for each channel which can be used to manage the following settings:'}</p>
			<br />
			<p><b>{langdata && langdata.ChannelWidth ? langdata.ChannelWidth : 'Channel Width'}</b> - {langdata && langdata.channelinfodescription2 ? langdata.channelinfodescription2 : 'This specify the minimum width for any particular event type to be confirmed and considered as event.'}</p>
			<br/>
			<p><b>{langdata && langdata.EventConversion ? langdata.EventConversion : 'Event Conversion'}</b> -  {langdata && langdata.channelinfodescription3 ? langdata.channelinfodescription3 : 'Helps to converts an event to any other event based on the event width.'}</p>
			<br/>
			<p><b>{langdata && langdata.PredictionConversion ? langdata.PredictionConversion : 'Prediction Conversion'}</b> - {langdata && langdata.channelinfodescription4 ? langdata.channelinfodescription4 : 'Provides a powerful module to convert the event predictions generated to possible \
					happening event type based on the  lower and upper event confidence values, terrain details and datetime.'}</p>
			<br/>
			<p><b>{langdata && langdata.ModelDetails ? langdata.ModelDetails : 'Model Details'}</b> - {langdata && langdata.channelinfodescription5 ? langdata.channelinfodescription5 : 'The setting to change the AI models,AI model architectures ,Default model selection and other \
					data parameters like bin size limits, alarm grouping distance, normalisation , event confidence threshold etc'}</p>
			<br/>
		</Card>
	);

	return (

		<>
			{permission === 'superadmin' ?
				<>
					<Content>
						<Row style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center',
						}}
						>

							<Col span={8}>
								<Row>
									<Space>
										<SplitCellsOutlined style={{fontSize:'30px'}}/>
										&nbsp;
										<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.ChannelInfo ? langdata.ChannelInfo : 'Channel Info'}</Title>
									</Space>
								</Row>
							</Col>
							<Col style ={{justifyContent:'right', display:'flex'}} span={16} >
								<Row >

									<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion : 'Filter by region'}>
										<Space>
											<Select Item
												style={{ minWidth: '200px', margin:'5px'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
												optionFilterProp="children"
												options={regions}
												defaultValue={null}
												onSelect={(e)=> setRegionId(e)}
											/>
										</Space>
									</Tooltip>
									<Space>&nbsp;&nbsp;</Space>
									<Space>
										<Typography>
											<Popover title={langdata && langdata.ChannelInfo ? langdata.ChannelInfo : 'Channel Info'}  content={infoContent} trigger="click">
												<Avatar size="small"><InfoOutlined /></Avatar>
											</Popover>
										</Typography>
									</Space>
								</Row>
							</Col>
						</Row>

						<Row span={24} style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col span={24}>
								{ data && data.channelInfo.length > 0 ? (
									<Table
										scroll={{
											x: 900,
										}} 
										columns={columns} 
										dataSource={data && data.channelInfo} 
									/>
								)
									:
									(
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
							</Col>
						</Row>
				
					</Content>

					<Modal title={langdata && langdata.ModelDetails ? langdata.ModelDetails : 'Model Details' }
						open={isModalOpen} 
						onOk={handleOk} 
						onCancel={handleCancel} 
						width={800} 
						destroyOnClose={true}
						maskClosable={false}
						footer={[
							// eslint-disable-next-line react/jsx-key
							<Space>
								<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
								<Button type="primary" onClick={formik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
							</Space>
						]}>
						<Form layout="vertical" onSubmit={formik.handleSubmit} >
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item  label={langdata && langdata.ModelLevel ? langdata.ModelLevel : 'Model Level'}  required>
										<Select
											name="modelPredictionLevel" 
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.ModelType ? langdata.ModelType : 'Model Type'}
											optionFilterProp="children"
											options={modalType}
											defaultValue={data.channelDetails && data.channelDetails.modelPredictionLevel}
											onChange={(value) => {formik.setFieldValue('modelPredictionLevel', value);setModelPredictionLevel(value);}}
											// value={formik.values.modelPredictionLevel}
										/>		
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={modelTypeOverrideContentForModal} required>
										<Select
											name="modelTypeOverride" 
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.ModelTypeOverride ? langdata.ModelTypeOverride : 'Model Type Override'}
											optionFilterProp="children"
											defaultValue={data.channelDetails && data.channelDetails.modelTypeOverride}
											onChange={(value) => formik.setFieldValue('modelTypeOverride', value)}
											options={[
												{ value: true, label: langdata && langdata.Manual ? langdata.Manual : 'Manual' },
												{ value: false, label: langdata && langdata.Default ? langdata.Default : 'Default' },
											]}
											value={formik.values.modelTypeOverride}
										/>		
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item label={modelBinsizeLower} required>
										<Input
											type='number'
											defaultValue={data.channelDetails && data.channelDetails.overrideLowerLimit}
											name = "overrideLowerLimit"
											onChange={formik.handleChange}
											disabled={formik.values.modelTypeOverride}
											min={0}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={modelBinsizeUpper} required>
										<Input
											type='number'
											defaultValue={data.channelDetails && data.channelDetails.overrideUpperLimit}
											name = "overrideUpperLimit"
											onChange={formik.handleChange}
											disabled={formik.values.modelTypeOverride}
											min={0}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item label={langdata && langdata.AlarmGroupingDistance ? langdata.AlarmGroupingDistance : 'Alarm Grouping Distance'} required>
										<Input
											type='number'
											defaultValue={data.channelDetails && data.channelDetails.profile && data.channelDetails.profile.alarm_grouping_distance}
											name = "alarm_grouping_distance"
											onChange={formik.handleChange}
											min={0}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={langdata && langdata.Normalisation ? langdata.Normalisation : 'Normalisation'} required>
										<Input
											type='text'
											defaultValue={data.channelDetails && data.channelDetails.profile && data.channelDetails.profile.normalisation}
											name = "normalisation"
											onChange={formik.handleChange}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item  label={langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold'} required>
										<Input
											type='number'
											defaultValue={data.channelDetails && data.channelDetails.profile && data.channelDetails.profile.max_threshold}
											name = "max_threshold"
											onChange={formik.handleChange}
											min={0}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={langdata && langdata.DefaultThreshold ? langdata.DefaultThreshold : 'Default Threshold'} required>
										<Input
											type='number'
											defaultValue={data.channelDetails && data.channelDetails.profile && data.channelDetails.profile.default_threshold}
											name = "default_threshold"
											onChange={formik.handleChange}
											min={0}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item  label={langdata && langdata.NormalisationThreshold ? langdata.NormalisationThreshold : 'Normalisation Threshold'} required>
										<Input
											type='number'
											defaultValue={data.channelDetails && data.channelDetails.profile && data.channelDetails.profile.normalisation_threshold}
											name = "normalisation_threshold"
											onChange={formik.handleChange}
											min={0}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
							
									{modelPredictionLevel == 'single' ? 
										<Form.Item  label={langdata && langdata.ModelType ? langdata.ModelType : 'Model Type'}  required>
											<Select
												name="model_name" 
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.ModelType ? langdata.ModelType : 'Model Type'}
												optionFilterProp="children"
												options={modalData}
												defaultValue={data.channelDetails && data.channelDetails.profile && data.channelDetails.profile.model_name}
												onChange={(value) => formik.setFieldValue('model_name', value)}
												disabled={formik.values.modelTypeOverride == true ? false : true}
											/>		
										</Form.Item>
										: 
										<Tooltip title={langdata && langdata.EventConfidence ? langdata.EventConfidence : 'Event Confidence'}>
											<Form.Item  label={langdata && langdata.Level1predictionDecisionThreshold ? langdata.Level1predictionDecisionThreshold : 'Level 1 prediction decision threshold'} required>
												<Input
													type='number'
													defaultValue={data.channelDetails && data.channelDetails.predictionDecisionThreshold}
													min={0}
													max={1}
													step={0.1}
													name = "predictionDecisionThreshold"
													onChange={formik.handleChange}
												/>
											</Form.Item>
										</Tooltip>
									}
							
								</Col>
							</Row>
							{modelPredictionLevel == '2level_4model' ? 
								<>
									<Divider orientation="left" orientationMargin="0">
										<Typography> <b>{langdata && langdata.Level1 ? langdata.Level1 : 'Level 1'} </b>
											<Popover title={langdata && langdata.Level1ThreatDetectionLevel ? langdata.Level1ThreatDetectionLevel : 'Level 1 : Threat Detection Level'} content={level2Content} trigger="hover">
												<Avatar size="small" style={{ width : '18px', height : '18px'}}><InfoOutlined /></Avatar>
											</Popover>
										</Typography>
									</Divider>
									<Row gutter={16}>
										<Col span={12}>
											<Form.Item  label={langdata && langdata.Class3MainModel ? langdata.Class3MainModel : '3 Class Main Model'}  required>
												<Select
													name="l2ModelThreat" 
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.Class3MainModel ? langdata.Class3MainModel : '3 Class Main Model'} 
													optionFilterProp="children"
													options={modalData}
													defaultValue={data.channelDetails && data.channelDetails.l2ModelThreat}
													// onChange={(value) => formik.setFieldValue('l2ModelThreat', value)}
													value={formik.values.l2ModelThreat}
													// disabled
													onChange={(value) => {formik.setFieldValue('l2ModelThreat', value);
														formik.setFieldValue('l1Model', value);formik.setFieldValue('l2ModelNonthreat', value);
														formik.setFieldValue('l3ModelMachinedigging', value);formik.setFieldValue('l3ModelManualdigging', value);
														formik.setFieldValue('l3ModelVehiclemovement', value);}}
													disabled={formik.values.modelTypeOverride == true ? false : true}
												/>		
											</Form.Item>
										</Col>
									</Row>
									<Divider orientation="left" orientationMargin="0">
										<Typography> <b>{langdata && langdata.Level2 ? langdata.Level2 : 'Level 2'} </b>
											<Popover title={langdata && langdata.Level2MainClassificationLevel ? langdata.Level2MainClassificationLevel : 'Level 2 : Main Classification Level'} content={level3Content} trigger="hover">
												<Avatar size="small" style={{ width : '18px', height : '18px'}}><InfoOutlined /></Avatar>
											</Popover>
										</Typography>
									</Divider>
									<Row gutter={16}>
										<Col span={12}>
											<Form.Item  label={langdata && langdata.MachineDiggingModel ? langdata.MachineDiggingModel : 'Machine Digging Model'}  required>
												<Select
													name="l3ModelMachinedigging" 
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.MachineDiggingModel ? langdata.MachineDiggingModel : 'Machine Digging Model'} 
													optionFilterProp="children"
													options={modalData}
													defaultValue={data.channelDetails && data.channelDetails.l3ModelMachinedigging}
													onChange={(value) => formik.setFieldValue('l3ModelMachinedigging', value)}
													value={formik.values.l3ModelMachinedigging}
													disabled
												/>		
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item  label={langdata && langdata.VehicleMovementModel ? langdata.VehicleMovementModel : 'Vehicle Movement Model'} required>
												<Select
													name="l3ModelVehiclemovement" 
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.VehicleMovementModel ? langdata.VehicleMovementModel : 'Vehicle Movement Model'}
													optionFilterProp="children"
													options={modalData}
													defaultValue={data.channelDetails && data.channelDetails.l3ModelVehiclemovement}
													onChange={(value) => formik.setFieldValue('l3ModelVehiclemovement', value)}
													value={formik.values.l3ModelVehiclemovement}
													disabled
												/>		
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={16}>
											
										<Col span={12}>
											<Form.Item  label={langdata && langdata.ManualDiggingModel ? langdata.ManualDiggingModel : 'Manual Digging Model'} required >
												<Select
													name="l3ModelManualdigging" 
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.ManualDiggingModel ? langdata.ManualDiggingModel : 'Manual Digging Model'}
													optionFilterProp="children"
													options={modalData}
													defaultValue={data.channelDetails && data.channelDetails.l3ModelManualdigging}
													onChange={(value) => formik.setFieldValue('l3ModelManualdigging', value)}
													value={formik.values.l3ModelManualdigging}
													disabled
												/>		
											</Form.Item>
										</Col>
									</Row>
									
								</>
								: modelPredictionLevel == '3level_6model' ? 
								
									<>
										<Divider orientation="left" orientationMargin="0">
											<Typography> <b>{langdata && langdata.Level1 ? langdata.Level1 : 'Level 1'} </b>
												<Popover title={langdata && langdata.Level1ThreatDetectionLevel ? langdata.Level1ThreatDetectionLevel : 'Level 1 : Threat Detection Level'} content={level1Content} trigger="hover">
													<Avatar size="small" style={{ width : '18px', height : '18px'}}><InfoOutlined /></Avatar>
												</Popover>
											</Typography>
										</Divider>
										<Row gutter={16}>
											<Col span={12}>
												<Form.Item  label={langdata && langdata.ModelType ? langdata.ModelType : 'Model Type'} required >
													<Select
														name="l1Model" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.ModelType ? langdata.ModelType : 'Model Type'}
														optionFilterProp="children"
														options={modalData}
														defaultValue={data.channelDetails && data.channelDetails.l1Model}
														onChange={(value) => {formik.setFieldValue('l1Model', value);
															formik.setFieldValue('l2ModelThreat', value);formik.setFieldValue('l2ModelNonthreat', value);
															formik.setFieldValue('l3ModelMachinedigging', value);formik.setFieldValue('l3ModelManualdigging', value);
															formik.setFieldValue('l3ModelVehiclemovement', value);}}
														disabled={formik.values.modelTypeOverride == true ? false : true}
													/>		
												</Form.Item>
											</Col>
											<Col span={12}>
											</Col>
										</Row>
										<Divider orientation="left" orientationMargin="0">
											<Typography> <b>{langdata && langdata.Level2 ? langdata.Level2 : 'Level 2'} </b>
												<Popover title={langdata && langdata.Level2MainClassificationLevel ? langdata.Level2MainClassificationLevel : 'Level 2 : Main Classification Level'} content={level2Content} trigger="hover">
													<Avatar size="small" style={{ width : '18px', height : '18px'}}><InfoOutlined /></Avatar>
												</Popover>
											</Typography>
										</Divider>
										<Row gutter={16}>
											<Col span={12}>
												<Form.Item  label={langdata && langdata.ThreatModelType ? langdata.ThreatModelType : 'Threat Model Type'}  required>
													<Select
														name="l2ModelThreat" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.ThreatModelType ? langdata.ThreatModelType : 'Threat Model Type'}
														optionFilterProp="children"
														options={modalData}
														defaultValue={data.channelDetails && data.channelDetails.l2ModelThreat}
														onChange={(value) => formik.setFieldValue('l2ModelThreat', value)}
														value={formik.values.l2ModelThreat}
														disabled
													/>		
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item  label={langdata && langdata.NonthreatModelType ? langdata.NonthreatModelType : 'Non-threat Model Type'}  required>
													<Select
														name="l2ModelNonthreat" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.NonthreatModelType ? langdata.NonthreatModelType : 'Non-threat Model Type'}
														optionFilterProp="children"
														options={modalData}
														defaultValue={data.channelDetails && data.channelDetails.l2ModelNonthreat}
														onChange={(value) => formik.setFieldValue('l2ModelNonthreat', value)}
														value={formik.values.l2ModelNonthreat}
														disabled
													/>		
												</Form.Item>
											</Col>
										</Row>
										<Divider orientation="left" orientationMargin="0">
											<Typography> <b>{langdata && langdata.Level3 ? langdata.Level3 : 'Level 3'} </b>
												<Popover title={langdata && langdata.Level3SubClassificationLevel ? langdata.Level3SubClassificationLevel : 'Level 3 : Sub-Classification Level'} content={level3Content} trigger="hover">
													<Avatar size="small" style={{ width : '18px', height : '18px'}}><InfoOutlined /></Avatar>
												</Popover>
											</Typography>
										</Divider>
										<Row gutter={16}>
											<Col span={12}>
												<Form.Item  label={langdata && langdata.MachineDiggingModel ? langdata.MachineDiggingModel : 'Machine Digging Model'} required >
													<Select
														name="l3ModelMachinedigging" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.MachineDiggingModel ? langdata.MachineDiggingModel : 'Machine Digging Model'}
														optionFilterProp="children"
														options={modalData}
														defaultValue={data.channelDetails && data.channelDetails.l3ModelMachinedigging}
														onChange={(value) => formik.setFieldValue('l3ModelMachinedigging', value)}
														value={formik.values.l3ModelMachinedigging}
														disabled
													/>		
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item  label={langdata && langdata.ManualDiggingModel ? langdata.ManualDiggingModel : 'Manual Digging Model'} required>
													<Select
														name="l3ModelManualdigging" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.ManualDiggingModel ? langdata.ManualDiggingModel : 'Manual Digging Model'}
														optionFilterProp="children"
														options={modalData}
														defaultValue={data.channelDetails && data.channelDetails.l3ModelManualdigging}
														onChange={(value) => formik.setFieldValue('l3ModelManualdigging', value)}
														value={formik.values.l3ModelManualdigging}
														disabled
													/>		
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={16}>
											<Col span={12}>
												<Form.Item  label={langdata && langdata.VehicleMovementModel ? langdata.VehicleMovementModel : 'Vehicle Movement Model'} required>
													<Select
														name="l3ModelVehiclemovement" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.VehicleMovementModel ? langdata.VehicleMovementModel : 'Vehicle Movement Model'}
														optionFilterProp="children"
														options={modalData}
														defaultValue={data.channelDetails && data.channelDetails.l3ModelVehiclemovement}
														onChange={(value) => formik.setFieldValue('l3ModelVehiclemovement', value)}
														value={formik.values.l3ModelVehiclemovement}
														disabled
													/>		
												</Form.Item>
											</Col>
											<Col span={12}>
											</Col>
										</Row>
									</> : null
							}
						</Form>
					</Modal>

					<Spin fullscreen spinning={data.pending} />
      
					{/* Add Fiber Management Starts */}
				</>
				:
				<Content>

					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24}>							
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						</Col>
					</Row>
				</Content>
			}
		</>
	);
}