export const PIG_LIST = 'PIG_LIST';
export const PIG_LIST_SUCCESS = 'PIG_LIST_SUCCESS';
export const PIG_LIST_ERROR = 'PIG_LIST_ERROR';
export const PIG_LIST_DETAILS = 'PIG_LIST_DETAILS';

export const PIG_UPDATE = 'PIG_UPDATE';
export const PIG_UPDATE_SUCCESS = 'PIG_UPDATE_SUCCESS';
export const PIG_UPDATE_ERROR = 'PIG_UPDATE_ERROR';

export const PIG_LOCATION = 'PIG_LOCATION';
export const PIG_LOCATION_SUCCESS = 'PIG_LOCATION_SUCCESS';
export const PIG_LOCATION_ERROR = 'PIG_LOCATION_ERROR';
export const PIG_PID_LIST_SUCCESS = 'PIG_PID_LIST_SUCCESS';


