export const AUTHENTICATION_LIST = 'AUTHENTICATION_LIST';
export const AUTHENTICATION_LIST_SUCCESS = 'AUTHENTICATION_LIST_SUCCESS';
export const AUTHENTICATION_LIST_ERROR = 'AUTHENTICATION_LIST_ERROR';

export const COMPANY_LIST = 'COMPANY_LIST';
export const COMPANY_LIST_SUCCESS = 'COMPANY_LIST_SUCCESS';
export const COMPANY_LIST_ERROR = 'COMPANY_LIST_ERROR';

export const COMPANY_MAP = 'COMPANY_MAP';
export const COMPANY_MAP_SUCCESS = 'COMPANY_MAP_SUCCESS';
export const COMPANY_MAP_ERROR = 'COMPANY_MAP_ERROR';

export const SETTINGS_LIST = 'SETTINGS_LIST';
export const SETTINGS_LIST_SUCCESS = 'SETTINGS_LIST_SUCCESS';
export const SETTINGS_LIST_ERROR = 'SETTINGS_LIST_ERROR';






