import { SETTINGS_LIST, SETTINGS_LIST_SUCCESS, SETTINGS_LIST_ERROR, FEATURES_LIST, FEATURES_LIST_SUCCESS, FEATURES_LIST_ERROR } from './constants';
import { UPDATE_SETTINGS, UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_ERROR } from './constants';
import { GET_TLP_HARDWARE_SETTINGS, GET_TLP_HARDWARE_SETTINGS_SUCCESS, GET_TLP_HARDWARE_SETTINGS_ERROR } from './constants';
import { UPDATE_TLP_HARDWARE_SETTINGS, UPDATE_TLP_HARDWARE_SETTINGS_SUCCESS, UPDATE_TLP_HARDWARE_SETTINGS_ERROR } from './constants.js';

export default function settingsReducer(state, action) {
	let settings = {};
	let tlpHardwareSettings = {};

	switch (action.type) {
	case SETTINGS_LIST:
		return { ...state, loading: true };
    
	case SETTINGS_LIST_SUCCESS:
		if (action && action.payload) {
			let item = action.payload;

			settings.id = item.id ? item.id : 0;
			settings.name = item.name ? item.name : '';
			settings.isEdge = item.isEdge;
			settings.tlpEnabled = item.tlpEnabled == 'disabled' ? false : true;
			settings.mapType = item.mapType ? item.mapType : 1;
			settings.downloadBatchSize = item.downloadBatchSize ? item.downloadBatchSize : 10000;
			settings.dateFormat = item.dateFormat ? item.dateFormat : null;
			settings.timeFormat = item.timeFormat ? item.timeFormat : null;
			settings.timeoutInterval = item.timeoutInterval ? item.timeoutInterval/1000 : 0;
			settings.extendedTimeoutInterval = item.extendedTimeoutInterval ? item.extendedTimeoutInterval/1000 : 0;
			settings.refreshInterval = item.refreshInterval ? item.refreshInterval/1000 : 0;
			
			settings.createdAt = item.createdAt ? item.createdAt : null;
			settings.updatedAt = item.updatedAt ? item.updatedAt : null;
			
			return { ...state, settings: settings, loading: false, error: false };
		} else {
			return { ...state, settings: settings, loading: false, error: false };
		}
    
	case SETTINGS_LIST_ERROR:
		return { ...state, loading: false, error: true };
      
	case UPDATE_SETTINGS:
		return { ...state, loading: true, error: false };

	case UPDATE_SETTINGS_SUCCESS:
		return { ...state, settings: action.payload, loading: false, error: false };

	case UPDATE_SETTINGS_ERROR:
		return { ...state, loading: false, error: true };

	case GET_TLP_HARDWARE_SETTINGS:
		return { ...state, loading: true };

	case GET_TLP_HARDWARE_SETTINGS_SUCCESS:
		if (action && action.payload) {
			let item = action.payload;

			tlpHardwareSettings.id = item.id ? item.id : 0;
			tlpHardwareSettings.companyId = item.companyId ? item.companyId : 0;
			tlpHardwareSettings.tlpHardwareUsername = item.tlpHardwareUsername ? item.tlpHardwareUsername : '';
			tlpHardwareSettings.tlpHardwarePassword = item.tlpHardwarePassword ? item.tlpHardwarePassword : 1;

			return { ...state, tlpHardwareSettings: tlpHardwareSettings, loading: false, error: false };
		} else {
			return { ...state, tlpHardwareSettings: tlpHardwareSettings, loading: false, error: false };
		}

	case GET_TLP_HARDWARE_SETTINGS_ERROR:
		return { ...state, loading: false, error: true };

	case UPDATE_TLP_HARDWARE_SETTINGS:
		return { ...state, loading: true, error: false };

	case UPDATE_TLP_HARDWARE_SETTINGS_SUCCESS:
		return { ...state, tlpHardwareSettings: action.payload, loading: false, error: false };

	case UPDATE_TLP_HARDWARE_SETTINGS_ERROR:
		return { ...state, loading: false, error: true };
	case FEATURES_LIST :
		return { ...state, loading: true, error: false };
	case FEATURES_LIST_SUCCESS :
		return { ...state, loading: false, error: false };
	case FEATURES_LIST_ERROR :
		return { ...state, loading: false, error: true };

	
	default: 
		throw Error('Unknown action: ' + action.type);
      
	}
}