import { React, useState, useEffect, useReducer } from 'react';
import { useAuth } from '../../Context';

import { Empty, Row, Col, Select, Typography, Button, Space, Table, Tooltip, Spin, Form, Input, Modal, Checkbox, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PlusOutlined, EditOutlined, DeleteOutlined, LockOutlined, VerifiedOutlined, TeamOutlined, InfoOutlined } from '@ant-design/icons';

import { USER_TYPES_LIST, USER_TYPES_LIST_SUCCESS, USER_TYPES_LIST_ERROR }  from './constants';
import { USERS_LIST, USERS_LIST_SUCCESS, USERS_LIST_ERROR } from './constants';
import { USER_DETAILS, USER_DETAILS_SUCCESS, USER_DETAILS_ERROR } from './constants';
import { SAVE_USER, SAVE_USER_SUCCESS, SAVE_USER_ERROR } from './constants';
import { UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR } from './constants';
import { DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_ERROR } from './constants';
import { UPDATE_USER_NOTIFICATION, UPDATE_USER_NOTIFICATION_SUCCESS, UPDATE_USER_NOTIFICATION_ERROR } from './constants';
import { USER_RESET_PASSWORD, USER_RESET_PASSWORD_SUCCESS, USER_RESET_PASSWORD_ERROR } from './constants';
import { USER_CHANGE_PASSWORD, USER_CHANGE_PASSWORD_SUCCESS, USER_CHANGE_PASSWORD_ERROR } from './constants';
import { useLocation, useNavigate } from 'react-router-dom';

import usersReducer from './reducer';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

import { useFormik } from 'formik';
import axios from 'axios';

const { Title } = Typography;
const { Search } = Input;

export default function UserManagement() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const { state } = useLocation();
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = {users : [], pagination: { total: 0, limit: 10, skip: 0, current:1 }, details: {}, userTypes: [], loading: true, error: false};
	const [data, dispatch] = useReducer(usersReducer, initalState);
	const [userTypeId, setUserTypeId] = useState(state && state.data.value ? state.data.value : null);
	
	const [search, setSearch] = useState('');
	useAuth();
	
	const [showAddUserModal, setShowAddUserModal] = useState(false);
	const [showEditUserModal, setShowEditUserModal] = useState(false);
	const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
	const [showNotificationManagementModal, setShowNotificationManagementModal] = useState(false);
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));
	const navigate = useNavigate();

	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';
	const oneviewtypeLabel = oneviewType && oneviewType.toLowerCase() == 'pipeline' ? langdata && langdata.Pipeline : oneviewType.toLowerCase() == 'airport' ? langdata && langdata.Airport : oneviewType.toLowerCase() == 'border' ? langdata && langdata.Border : 'pipeline';

	const columns = [
		{
			title: langdata && langdata.FirstName ? langdata.FirstName : 'First Name',
			dataIndex: 'firstName',
			key: 'firstName',
			render: (text) => <span>{text}</span>,
		},
		{
			title: langdata && langdata.LastName ? langdata.LastName : 'Last Name',
			dataIndex: 'lastName',
			key: 'lastName',
			render: (text) => <span>{text}</span>,
		},
		{
			title: langdata && langdata.EmailAddress ? langdata.EmailAddress : 'Email Address',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: langdata && langdata.PhoneNumber ? langdata.PhoneNumber : 'Phone Number',
			dataIndex: 'cellPhone',
			key: 'cellPhone',
		},
		{
			title: langdata && langdata.UserType ? langdata.UserType : 'User Type',
			dataIndex: 'usertypeId',
			key: 'usertypeId',
			render: (_, record) => (
				<>
					{data.userTypes && data.userTypes.map((item) => { if (record.usertypeId === item.id){ return item.userType.toUpperCase();}})}
				</>
			)
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: () => (
				<Space size="middle">
					<a title={langdata && langdata.EditUser ? langdata.EditUser : 'Edit User'} onClick={openEditUserModal}><EditOutlined /></a>
					{permission === 'superadmin' || permission === 'admin' ? 
						<a title={langdata && langdata.DeleteUser ? langdata.DeleteUser : 'Delete User'} onClick={openDeleteUserModal}><DeleteOutlined /></a>
						: null }
					<a title={langdata && langdata.ResetPassword ? langdata.ResetPassword : 'Reset Password'} onClick={openResetPasswordModal}><VerifiedOutlined /></a>
					<a title={langdata && langdata.ChangePassword ? langdata.ChangePassword : 'Change Password'} onClick={openChangePasswordModal}><LockOutlined /></a>
				</Space>
			)
		}
	];

	let userTypes = [{ value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'}];
	let filteredUserTypes = [];

	data.userTypes && data.userTypes.map((item) => {
		if( oneviewtypeLabel.toLowerCase() == 'pipeline') {
			if (permission === 'superadmin' && item.id !== 8) {
				userTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
				filteredUserTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			}else if(permission === 'admin' && item.id !== 2 && item.id !== 8) {
				userTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
				filteredUserTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			} else if (permission === 'HQO-HSE' && item.id !== 2 && item.id !==12 && item.id !== 8) {
				userTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
				filteredUserTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			} else if(permission === 'Pipeline-HO' && item.id !== 2 && item.id !== 12 && item.id !== 10 && item.id !== 8) {
				userTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
				filteredUserTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			} else if (permission === 'Pipeline-In-Charge' && item.id !== 2 && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 8) {
				userTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
				filteredUserTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			} else if (permission === 'Station-In-Charge' && item.id !== 2 && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 9 && item.id !== 8) {
				userTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
				filteredUserTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			} else if ( permission === 'ROU' && item.id !== 2 && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 9 && item.id !== 6 && item.id !== 8) {
				userTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
				filteredUserTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			} else if(permission === 'CRO' && item.id !== 2 && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 9 && item.id !== 6 && item.id !== 7 && item.id !== 3 && item.id !== 8) {
				userTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
				filteredUserTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			}
		} else {
			if (permission === 'superadmin' && item.id !== 8 && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 9) {
				userTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
				filteredUserTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			}else if(permission === 'admin' && item.id !== 2 && item.id !== 8 && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 9) {
				userTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
				filteredUserTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			} else if ( permission === 'ROU' && item.id !== 2 && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 9 && item.id !== 6 && item.id !== 8) {
				userTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
				filteredUserTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			} else if(permission === 'CRO' && item.id !== 2 && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 9 && item.id !== 6 && item.id !== 7 && item.id !== 3 && item.id !== 8) {
				userTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
				filteredUserTypes.push({
					label : item.userType.toUpperCase(),
					value : item.id
				});
			}
		}

		return true;
	});

	const handleSearch = (e) => {
		const searchString = e.target.value;

		setSearch(searchString);
	};

	const handleChangeUserType = (e) => {
		localStorage.setItem(`${dashboardtype}_USER_TYPE_ID`, e);
		setUserTypeId(e);
	};

	useEffect(() => {
		if(featuresModule.userMgmtEnabled == false) {
			navigate('/dashboard');
		}
		
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 41);
	}, []);

	useEffect(() => {
		loadUserTypes();
	}, [userTypeId, search]);

	const loadUserTypes = () => {
		let url = `${api}/usertype?id[$ne]=1&$sort[userType]=1`;

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		dispatch({ type: USER_TYPES_LIST });

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: USER_TYPES_LIST_SUCCESS, payload: response.data });
				loadUsers(1);
			} else {
				dispatch( { type: USER_TYPES_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: USER_TYPES_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadUsers = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		let params = '';
		if(oneviewtypeLabel.toLowerCase() != 'pipeline') {
			params = '&usertypeId[$nin][]=9&usertypeId[$nin][]=10&usertypeId[$nin][]=11&usertypeId[$nin][]=12';
		}

		let url = `${api}/users-list?isDeleted=false&usertypeId[$ne]=1&$limit=${_pagination.limit}&$skip=${_pagination.skip}${params}`;
		
		if (userTypeId && userTypeId !== null) {
			url = `${api}/users-list?isDeleted=false&usertypeId=${userTypeId}&$limit=${_pagination.limit}&$skip=${_pagination.skip}`;
		}

		if (search && search.length > 0) {
			url += `&email[$ilike]=%${search}%`;
		}
    
		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		dispatch({ type: USERS_LIST });

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: USERS_LIST_SUCCESS, payload: response.data, page:pagination.current, pageSize : pagination.pageSize  });
			} else {
				dispatch( { type: USERS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: USERS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadUserDetails = ((id) =>  {
		let details = null;

		dispatch({ type: USER_DETAILS });

		if (data && data.users) {
			data.users && data.users.map((item) => {
				if (item.id === id) {
					details = item;
				}

				return true;
			});

			dispatch({ type: USER_DETAILS_SUCCESS, payload: details });
		} else {
			dispatch({ type: USER_DETAILS_ERROR });
		}
	});

	const openAddUserModal = () => {
		setShowAddUserModal(true);
	};

	const closeAddUserModal = () => {
		setShowAddUserModal(false);
	};

	const openEditUserModal = () => {
		setShowEditUserModal(true);
	};

	const closeEditUserModal = () => {
		setShowEditUserModal(false);
	};

	const openDeleteUserModal = () => {
		setShowDeleteUserModal(true);
	};

	const closeDeleteUserModal = () => {
		setShowDeleteUserModal(false);
	};

	const closeNotificationManagementModal = () => {
		setShowNotificationManagementModal(false);
	};

	const openResetPasswordModal = () => {
		setShowResetPasswordModal(true);
	};

	const closeResetPasswordModal = () => {
		setShowResetPasswordModal(false);
	};

	const openChangePasswordModal = () => {
		setShowChangePasswordModal(true);
	};

	const closeChangePasswordModal = () => {
		setShowChangePasswordModal(false);
	};

	const deleteUser = () => {
		let id = data.details ? data.details.id : 0;
    
		if (!id || parseInt(id) <= 0) {
			Modal.warning({ title: langdata && langdata.TheuseridisinvalidPleasetryagain ? langdata.TheuseridisinvalidPleasetryagain : 'The user id is invalid. Please try again.' });
		} else {
			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/users/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					isDeleted: true
				}
			};

			dispatch( { type: DELETE_USER });
      
			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: DELETE_USER_SUCCESS, payload:response});
						Modal.success({ title: langdata && langdata.Userdeletedsuccessfully ? langdata.Userdeletedsuccessfully :  'User deleted successfully.' });
						loadUsers({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
						closeDeleteUserModal();
					} else {
						dispatch({ type: DELETE_USER_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletodeletetheuser ? langdata.Unabletodeletetheuser :  'Unable to delete the user.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: DELETE_USER_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
	};

	const resetPassword = () => {
		const id = data.details ? data.details.id : 0;
		const email = data.details ? data.details.email : '';
		const cellPhone = data.details ? data.details.cellPhone : '';

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/resetpassword`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: {
				usersId: id,
				email: email,
				cellPhone: cellPhone
			}
		};

		dispatch( { type: USER_RESET_PASSWORD });

		axios
			.request(config)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					dispatch({type: USER_RESET_PASSWORD_SUCCESS, payload:response});
					Modal.success({ title: langdata && langdata.Userpasswordresetsuccessfully ? langdata.Userpasswordresetsuccessfully : 'User password has been reset successfully.' });
					closeResetPasswordModal();
				} else {
					dispatch({ type: USER_RESET_PASSWORD_ERROR });
					Modal.warning({ title: langdata && langdata.Unabletoresettheuserpassword ? langdata.Unabletoresettheuserpassword : 'Unable to reset the user password.' });  
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type: USER_RESET_PASSWORD_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const addUserFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			usertypeId: 0,
			employeeId: '0',
			firstName: '',
			lastName: '',
			cellPhone: '',
			email: '',
			password: '',
			confirmPassword: ''
		},

		onSubmit: (values) => {
			const usertypeId = values.usertypeId ? values.usertypeId : 0;
			const employeeId = values.employeeId ? values.employeeId : '0';
			const firstName = values.firstName ? values.firstName : '';
			let lastName = values.lastName ? values.lastName : '';
			const cellPhone = values.cellPhone ? values.cellPhone : '';
			const email = values.email ? values.email : '';
			const password = values.password ? values.password : '';
			const confirmPassword = values.confirmPassword ? values.confirmPassword : '';

			if (!usertypeId || usertypeId.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheusertype ? langdata.Pleasespecifytheusertype : 'Please specify the user type.' });
				return false;
			}

			if (!firstName || firstName.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythefirstname ? langdata.Pleasespecifythefirstname : 'Please specify the first name.' });
				return false;
			}

			if (!cellPhone || cellPhone.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythecellphonenumber ? langdata.Pleasespecifythecellphonenumber : 'Please specify the cell phone number.' });
				return false;
			}

			if (!email || email.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheemailaddress ? langdata.Pleasespecifytheemailaddress : 'Please specify the email address.' });
				return false;
			}

			if (!password || password.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythepassword ? langdata.Pleasespecifythepassword : 'Please specify the password.' });
				return false;
			}

			if (!password || password.length < 8) {
				Modal.warning({ title: langdata && langdata.Pleasespecifyastrongpassword ? langdata.Pleasespecifyastrongpassword : 'Please specify a strong password.' });
				return false;
			}

			if (!confirmPassword || confirmPassword.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleaseconfirmthenewpassword ? langdata.Pleaseconfirmthenewpassword : 'Please confirm the new password.' });
				return false;
			}

			if (password  !== confirmPassword) {
				Modal.warning({ title: langdata && langdata.Passwordandconfirmpasswordshouldmatch ? langdata.Passwordandconfirmpasswordshouldmatch : 'Password and confirm password should match.' });
				return false;
			}

			let permission;

			if (usertypeId === 1) {
				permission = 'superadmin';
			} else if (usertypeId=== 2) {
				permission = 'admin';
			} else if (usertypeId === 3) {
				permission = 'CRO';
			} else if (usertypeId === 4) {
				permission = 'linewalker';
			} else if (usertypeId === 6) {
				permission = 'ROU';
			} else if (usertypeId === 5) {
				permission = 'supervisor';
			} else if (usertypeId === 7) {
				permission = 'nightpatrol';
			} else if (usertypeId === 8) {
				permission = 'tlp';
			} else if (usertypeId === 9) {
				permission = 'Station-In-Charge';
			} else if (usertypeId === 10) {
				permission = 'Pipeline-HO';
			} else if (usertypeId === 11) {
				permission = 'Pipeline-In-Charge';
			} else if (usertypeId === 12) {
				permission = 'HQO-HSE';
			}

			let config1 = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/check-email-pipelineid-unique`,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					email: email,
				}
			};

			axios
				.request(config1)
				.then((response) => {
					if(response.status === 200){
						let config = {
							method: 'post',
							maxBodyLength: Infinity,
							url: `${api}/users?isDeleted=false`,
							timeout: timeout,
							headers: {
								accept: 'application/json',
								'Content-Type': 'application/json',
								Authorization:`Bearer ${authtoken}`
							},
							data: {
								usertypeId: usertypeId,
								employeeId: employeeId,
								firstName: firstName,
								lastName: lastName,
								cellPhone: cellPhone,
								email: email,
								password: password,
								permissions: permission
							}
						};
			
						dispatch( { type: SAVE_USER });
			
						axios
							.request(config)
							.then((response) => {
								if (response.status === 400) {
									dispatch({ type: SAVE_USER_ERROR });
									Modal.warning({ title: langdata && langdata.Emailorphonenumbermustbeunique ? langdata.Emailorphonenumbermustbeunique : 'Email or phone number must be unique.' });
								} else if (response.status === 401) {
									dispatch({ type: SAVE_USER_ERROR });
									Modal.warning({ title: langdata && langdata.UnauthorizedPleasetryagain ? langdata.UnauthorizedPleasetryagain : 'Unauthorized. Please try again.' });
								} else if (response.status === 200 || response.status === 201 || response.status === 204) {
									dispatch({type: SAVE_USER_SUCCESS, payload:response});
									Modal.success({ title: langdata && langdata.Useraddedsuccessfully ? langdata.Useraddedsuccessfully : 'User added successfully.' });
									loadUsers({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
									closeAddUserModal();
								} else {
									dispatch({ type: SAVE_USER_ERROR });
									Modal.warning({ title: langdata && langdata.Unabletosavetheuserdetails ? langdata.Unabletosavetheuserdetails : 'Unable to save the user details.' });  
								}
							})
							.catch((error) => {
								console.log(error);
								dispatch({ type: SAVE_USER_ERROR });
								if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
									message.config({
										duration : 5,
										maxCount : 1,
									});
									message.open({
										type: 'error',
										content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
									});
								}
							});
					}
				})
				.catch((error) => {
					console.log(error);
					Modal.warning({ title: langdata && langdata.Emailalreadyexists ? langdata.Emailalreadyexists : 'Email already exists.' });  
				});

			
		}
	});

	const editUserFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			usertypeId: data.details ? data.details.usertypeId : 0,
			employeeId: data.details ? data.details.employeeId : '0',
			firstName: data.details ? data.details.firstName : '',
			lastName: data.details ? data.details.lastName : '',
			cellPhone: data.details ? data.details.cellPhone : '',
			email: data.details ? data.details.email : ''
		},

		onSubmit: (values) => {
			const id = data.details ? data.details.id : 0;
			const usertypeId = values.usertypeId ? values.usertypeId : 0;
			const employeeId = values.employeeId ? values.employeeId : '0';
			const firstName = values.firstName ? values.firstName : '';
			const lastName = values.lastName ? values.lastName : '';
			const cellPhone = values.cellPhone ? values.cellPhone : '';
			const email = values.email ? values.email : '';

			if (!usertypeId || usertypeId.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheusertype ? langdata.Pleasespecifytheusertype : 'Please specify the user type.' });
				return false;
			}

			if (!firstName || firstName.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythefirstname ? langdata.Pleasespecifythefirstname : 'Please specify the first name.' });
				return false;
			}

			if (!cellPhone || cellPhone.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythecellphonenumber ? langdata.Pleasespecifythecellphonenumber : 'Please specify the cell phone number.' });
				return false;
			}

			if (!email || email.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheemailaddress ? langdata.Pleasespecifytheemailaddress : 'Please specify the email address.' });
				return false;
			}

			let permission;

			if (usertypeId === 1) {
				permission = 'superadmin';
			} else if (usertypeId=== 2) {
				permission = 'admin';
			} else if (usertypeId === 3) {
				permission = 'CRO';
			} else if (usertypeId === 4) {
				permission = 'linewalker';
			} else if (usertypeId === 6) {
				permission = 'ROU';
			} else if (usertypeId === 5) {
				permission = 'supervisor';
			} else if (usertypeId === 7) {
				permission = 'nightpatrol';
			} else if (usertypeId === 8) {
				permission = 'tlp';
			} else if (usertypeId === 9) {
				permission = 'Station-In-Charge';
			} else if (usertypeId === 10) {
				permission = 'Pipeline-HO';
			} else if (usertypeId === 11) {
				permission = 'Pipeline-In-Charge';
			} else if (usertypeId === 12) {
				permission = 'HQO-HSE';
			}
      
			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/users/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					usertypeId: usertypeId,
					employeeId: employeeId,
					firstName: firstName,
					lastName: lastName,
					cellPhone: cellPhone,
					email: email,
					permissions: permission
				}
			};

			dispatch( { type: UPDATE_USER });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 409) {
						dispatch({ type: UPDATE_USER_ERROR });
						Modal.warning({ title: langdata && langdata.TheuserhasactiveallocationsPleasecorrecttheuserroletypeandtryagain ? langdata.TheuserhasactiveallocationsPleasecorrecttheuserroletypeandtryagain : 'The user has active allocations. Please correct the user role type and try again.' });
					} else if (response.status === 200 || response.status === 201) {
						dispatch({type: UPDATE_USER_SUCCESS, payload:response});
						Modal.success({ title: langdata && langdata.Userdetailsupdatedsuccessfully ? langdata.Userdetailsupdatedsuccessfully : 'User details updated successfully.' });
						loadUsers({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
						closeEditUserModal();
					} else {
						dispatch({ type: UPDATE_USER_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletoupdatetheuserdetails ? langdata.Unabletoupdatetheuserdetails : 'Unable to update the user details.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: UPDATE_USER_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
	});

	const changeNotificationManagementFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			sendPIDSDownReport: true,
			sendDailyReport: true
		},

		onSubmit: (values) => {
			const id = data.details ? data.details.id : 0;
			const sendPIDSDownReport = values.sendPIDSDownReport ? values.sendPIDSDownReport : false;
			const sendDailyReport = values.sendDailyReport ? values.sendDailyReport : false;

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/users/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					reportsent: sendPIDSDownReport,
					dailyreport: sendDailyReport
				}
			};

			dispatch( { type: UPDATE_USER_NOTIFICATION });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: UPDATE_USER_NOTIFICATION_SUCCESS, payload:response});
						Modal.success({ title: langdata && langdata.Usernotificationsettingsupdatedsuccessfully ? langdata.Usernotificationsettingsupdatedsuccessfully : 'User notification settings updated successfully.' });
						closeNotificationManagementModal();
					} else {
						dispatch({ type: UPDATE_USER_NOTIFICATION_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletoupdatetheusernotificationsettings ? langdata.Unabletoupdatetheusernotificationsettings : 'Unable to update the user notification settings.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: UPDATE_USER_NOTIFICATION_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}  
				});
		}
	});

	const changePasswordFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			newPassword: '',
			confirmPassword: ''
		},

		onSubmit: (values) => {
			const id = data.details ? data.details.id : 0;
			const newPassword = values.newPassword ? values.newPassword : '';
			const confirmPassword = values.confirmPassword ? values.confirmPassword : '';

			if (!newPassword || newPassword.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythenewpassword ? langdata.Pleasespecifythenewpassword : 'Please specify the new password.' });
				return false;
			}

			if (!newPassword || newPassword.length < 8) {
				Modal.warning({ title: langdata && langdata.Pleasespecifyastrongpassword ? langdata.Pleasespecifyastrongpassword : 'Please specify a strong password.' });
				return false;
			}

			if (!confirmPassword || confirmPassword.length <= 0) {
				Modal.warning({ title: langdata && langdata.Pleaseconfirmthenewpassword ? langdata.Pleaseconfirmthenewpassword : 'Please confirm the new password.' });
				return false;
			}

			if (newPassword  !== confirmPassword) {
				Modal.warning({ title: langdata && langdata.Newpasswordandconfirmpasswordshouldmatch ? langdata.Newpasswordandconfirmpasswordshouldmatch : 'New password and confirm password should match.' });
				return false;
			}

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/changepassword`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					id: id,
					password: newPassword
				}
			};

			dispatch( { type: USER_CHANGE_PASSWORD });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: USER_CHANGE_PASSWORD_SUCCESS, payload:response});
						Modal.success({ title: langdata && langdata.Userpasswordchangedsuccessfully ? langdata.Userpasswordchangedsuccessfully : 'User password changed successfully.' });
						closeChangePasswordModal();
					} else {
						dispatch({ type: USER_CHANGE_PASSWORD_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletochangetheuserpassword ? langdata.Unabletochangetheuserpassword : 'Unable to change the user password.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: USER_CHANGE_PASSWORD_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}  
				});
		}
	});

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.UserManagementContent1 ? langdata.UserManagementContent1 : 'All available users based on the current user role are displayed. Provides Actions to edit user details, reset password and change password. New users can be added by the Add New button.'}</p>
			<br />
			<p>{langdata && langdata.UserManagementContent2 ? langdata.UserManagementContent2 : 'Users can be searched by providing their email and filter option available to filter users by user type.'}</p>
		</Card>
	);

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={8}>
						<Row>
							<Space>
								<TeamOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.UserManagement ? langdata.UserManagement : 'User Managemente'}</Title>
						</Row>
					</Col>

					<Col span={16}>
						<Row justify="end">
							<Col>
								<Space>
									<Search 
										style={{margin:'5px', verticalAlign:'middle', marginTop:'2px',paddingRight:'10px'}}
										placeholder={langdata && langdata.Searchbyemail ? langdata.Searchbyemail : 'Search by email'}
										allowClear
										enterButton
										value = {search}
										onChange={handleSearch}
										onPressEnter={handleSearch}
									/>
								</Space>

								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyusertype ? langdata.Filterbyusertype : 'Filter by user type'}>
									<Space>
										<Select
											style={{ minWidth: '200px',margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.Filterbyusertype ? langdata.Filterbyusertype : 'Filter by user type'}
											optionFilterProp="children"
											defaultValue={'ALL'}
											value={userTypeId}
											options={userTypes}
											onSelect={handleChangeUserType}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Button style={{margin:'5px'}} type="primary" onClick={openAddUserModal} ><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button>
								</Space>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.UserManagement ? langdata.UserManagement : 'User Management'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data && data.users ? (
							<Table 
								scroll={{
									x: 900,
								}}
								columns = { columns } 
								dataSource = { data.users } 
								pagination = { data.users.length > 0 ? data.pagination  : 0}
								onChange = { loadUsers }
								onRow={(record) => {
									return {
										onClick: () => { 
											loadUserDetails(record.id);
										}
									};
								}} 
							/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>
			</Content>

			<Spin fullscreen spinning={data.loading} />

			{/* Add User Modal Starts */}
			<Modal
				title={langdata && langdata.AddUser ? langdata.AddUser : 'Add User'}
				centered
				open={showAddUserModal}
				onOk={{closeAddUserModal}}
				onCancel={closeAddUserModal}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeAddUserModal}>{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}</Button>
						<Button key="submit" type="primary" onClick={addUserFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" onSubmit={addUserFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.UserType ? langdata.UserType : 'User type'} required>
								<Select
									name="usertypeId" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.UserType ? langdata.UserType : 'User type'}
									optionFilterProp="children"
									onChange={(value) => addUserFormik.setFieldValue('usertypeId', value)}
									options={ filteredUserTypes }
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Employeeid ? langdata.Employeeid : 'Employee id'} rules={[{ message: langdata && langdata.Pleasespecifytheemployeeid ? langdata.Pleasespecifytheemployeeid : 'Please specify the employee id' }]} required>
								<Input name="employeeId" placeholder={langdata && langdata.Pleasespecifytheemployeeid ? langdata.Pleasespecifytheemployeeid : 'Please specify the employee id'} onChange={addUserFormik.handleChange} value={addUserFormik.values.employeeId}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.FirstName ? langdata.FirstName : 'First Name'} rules={[{ message: langdata && langdata.Pleasespecifythefirstname ? langdata.Pleasespecifythefirstname : 'Please specify the first name' }]} required>
								<Input name="firstName" placeholder={langdata && langdata.Pleasespecifythefirstname ? langdata.Pleasespecifythefirstname : 'Please specify the first name'} onChange={addUserFormik.handleChange} value={addUserFormik.values.firstName}/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.LastName ? langdata.LastName : 'Last Name'} rules={[{ message: langdata && langdata.Pleasespecifythelastname ? langdata.Pleasespecifythelastname : 'Please specify the last name' }]} required>
								<Input name="lastName" placeholder={langdata && langdata.Pleasespecifythelastname ? langdata.Pleasespecifythelastname : 'Please specify the last name'} onChange={addUserFormik.handleChange} value={addUserFormik.values.lastName}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.CellPhone ? langdata.CellPhone : 'Cell Phone'} rules={[{ message: langdata && langdata.Pleasespecifythecellphonenumber ? langdata.Pleasespecifythecellphonenumber : 'Please specify the cellphone number' }]} required>
								<Input name="cellPhone" placeholder={langdata && langdata.Pleasespecifythecellphonenumber ? langdata.Pleasespecifythecellphonenumber : 'Please specify the cellphone number'} onChange={addUserFormik.handleChange} value={addUserFormik.values.cellPhone}/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.EmailAddress ? langdata.EmailAddress : 'Email Address'} rules={[{ message: langdata && langdata.Pleasespecifytheemailaddress ? langdata.Pleasespecifytheemailaddress : 'Please specify the email address' }]} required>
								<Input name="email" placeholder={langdata && langdata.Pleasespecifytheemailaddress ? langdata.Pleasespecifytheemailaddress : 'Please specify the email address'} onChange={addUserFormik.handleChange} value={addUserFormik.values.email}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Password ? langdata.Password : 'Password'} rules={[{ message: langdata && langdata.Pleasespecifythepassword ? langdata.Pleasespecifythepassword : 'Please specify the password' }]} required>
								<Input name="password" type="password" placeholder={langdata && langdata.Pleasespecifythepassword ? langdata.Pleasespecifythepassword : 'Please specify the password'} onChange={addUserFormik.handleChange} value={addUserFormik.values.password}/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Confirmpassword ? langdata.Confirmpassword : 'Confirm Password'} rules={[{ message: langdata && langdata.Pleaseconfirmthepassword ? langdata.Pleaseconfirmthepassword : 'Please confirm the password' }]} required>
								<Input name="confirmPassword" type="password" placeholder={langdata && langdata.Pleaseconfirmthepassword ? langdata.Pleaseconfirmthepassword : 'Please confirm the password'} onChange={addUserFormik.handleChange} value={addUserFormik.values.confirmPassword}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* Add User Modal Modal Ends */}

			{/* Edit User Modal Starts */}
			<Modal
				title={langdata && langdata.EditUser ? langdata.EditUser : 'Edit User'}
				centered
				open={showEditUserModal}
				onOk={{closeEditUserModal}}
				onCancel={closeEditUserModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeEditUserModal}>{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}</Button>
						<Button key="submit" type="primary" onClick={editUserFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" onSubmit={editUserFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item  label={langdata && langdata.UserType ? langdata.UserType : 'User type'} required>
								<Select
									name="usertypeId" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.UserType ? langdata.UserType : 'User type'}
									optionFilterProp="children"
									initialValue= {editUserFormik.values.usertypeId}
									value= {editUserFormik.values.usertypeId}
									onChange={(value) => editUserFormik.setFieldValue('usertypeId', value)}
									options={ filteredUserTypes }
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Employeeid ? langdata.Employeeid : 'Employee id'} rules={[{ message: langdata && langdata.Pleasespecifytheemployeeid ? langdata.Pleasespecifytheemployeeid : 'Please specify the employee id' }]} required>
								<Input name="employeeId" placeholder={langdata && langdata.Pleasespecifytheemployeeid ? langdata.Pleasespecifytheemployeeid : 'Please specify the employee id'} onChange={editUserFormik.handleChange} value={editUserFormik.values.employeeId}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.FirstName ? langdata.FirstName : 'First Name'} rules={[{ message: langdata && langdata.Pleasespecifythefirstname ? langdata.Pleasespecifythefirstname : 'Please specify the first name' }]} required>
								<Input name="firstName" placeholder={langdata && langdata.Pleasespecifythefirstname ? langdata.Pleasespecifythefirstname : 'Please specify the first name'} onChange={editUserFormik.handleChange} value={editUserFormik.values.firstName}/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.LastName ? langdata.LastName : 'Last Name'} rules={[{ message: langdata && langdata.Pleasespecifythelastname ? langdata.Pleasespecifythelastname : 'Please specify the last name' }]} required>
								<Input name="lastName" placeholder={langdata && langdata.Pleasespecifythelastname ? langdata.Pleasespecifythelastname : 'Please specify the last name'} onChange={editUserFormik.handleChange} value={editUserFormik.values.lastName}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.CellPhone ? langdata.CellPhone : 'Cell Phone'} rules={[{ message: langdata && langdata.Pleasespecifythecellphonenumber ? langdata.Pleasespecifythecellphonenumber : 'Please specify the cellphone number' }]} required>
								<Input name="cellPhone" placeholder={langdata && langdata.Pleasespecifythecellphonenumber ? langdata.Pleasespecifythecellphonenumber : 'Please specify the cellphone number'} onChange={editUserFormik.handleChange} value={editUserFormik.values.cellPhone}/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.EmailAddress ? langdata.EmailAddress : 'Email Address'} rules={[{ message: langdata && langdata.Pleasespecifytheemailaddress ? langdata.Pleasespecifytheemailaddress : 'Please specify the email address' }]} required>
								<Input name="email" placeholder={langdata && langdata.Pleasespecifytheemailaddress ? langdata.Pleasespecifytheemailaddress : 'Please specify the email address'} onChange={editUserFormik.handleChange} value={editUserFormik.values.email}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* Edit User Modal Modal Ends */}

			{/* Delete User Modal Starts */}
			<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={showDeleteUserModal} onOk={deleteUser} onCancel={closeDeleteUserModal}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeDeleteUserModal}>{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}</Button>
						<Button key="submit" type="primary" onClick={deleteUser}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>{ langdata && langdata.Areyousureyouwanttodeletethisuser ? langdata.Areyousureyouwanttodeletethisuser :'Are you sure you want to delete this user?'}</Col>
				</Row>
			</Modal>
			{/* Delete User Modal Ends */}

			{/* Notification Management Modal Starts */}
			<Modal
				title={langdata && langdata.SendPIDSdownreport ? langdata.SendPIDSdownreport : 'Notification Management'}
				centered
				open={showNotificationManagementModal}
				onOk={{closeNotificationManagementModal}}
				onCancel={closeNotificationManagementModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeNotificationManagementModal}>{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}</Button>
						<Button key="submit" type="primary" onClick={changeNotificationManagementFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}
				width={640}
			>
				<Form layout="vertical" onSubmit={changeNotificationManagementFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item  label={langdata && langdata.SendPIDSdownreport ? langdata.SendPIDSdownreport : 'Send PIDS down report'}>
								<Checkbox name="sendPIDSDownReport" onChange={changeNotificationManagementFormik.handleChange} value={changeNotificationManagementFormik.values.sendPIDSDownReport} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Senddailyreport ? langdata.Senddailyreport : 'Send daily report'}>
								<Checkbox name="sendDailyReport" onChange={changeNotificationManagementFormik.handleChange} value={changeNotificationManagementFormik.values.sendDailyReport}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* Notification Management Modal Ends */}

			{/* Reset {Password Modal Starts */}
			<Modal title={langdata && langdata.Areyousureyouwanttoresetthepassword ? langdata.Areyousureyouwanttoresetthepassword : 'Reset password'} open={showResetPasswordModal} onOk={resetPassword} onCancel={closeResetPasswordModal}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeResetPasswordModal}>{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}</Button>
						<Button key="submit" type="primary" onClick={resetPassword}>{langdata && langdata.OK ? langdata.OK : 'OK'}</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}}
				>
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>{langdata && langdata.Areyousureyouwanttoresetthepassword ? langdata.Areyousureyouwanttoresetthepassword : 'Are you sure you want to reset the password?'}</Col>
				</Row>
			</Modal>
			{/* Reset Password Modal Ends */}

			{/* Change Password Modal Starts */}
			<Modal
				title={langdata && langdata.ChangePassword ? langdata.ChangePassword : 'Change Password'}
				centered
				open={showChangePasswordModal}
				onOk={{closeChangePasswordModal}}
				onCancel={closeChangePasswordModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeChangePasswordModal}>{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}</Button>
						<Button key="submit" type="primary" onClick={changePasswordFormik.handleSubmit}>{langdata && langdata.CHANGEPASSWORD ? langdata.CHANGEPASSWORD : 'CHANGE PASSWORD'}</Button>
					</Space>
				]}
				width={640}
			>
				<Form layout="vertical" onSubmit={changePasswordFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item  label={langdata && langdata.Newpassword ? langdata.Newpassword : 'New password'} rules={[{ message: langdata && langdata.Pleasespecifythenewpassword ? langdata.Pleasespecifythenewpassword : 'Please specify the new password' }]} >
								<Input name="newPassword" type='password' placeholder={langdata && langdata.Pleasespecifythenewpassword ? langdata.Pleasespecifythenewpassword : 'Please specify the new password'} onChange={changePasswordFormik.handleChange} value={changePasswordFormik.values.newPassword} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Confirmpassword ? langdata.Confirmpassword : 'Confirm password'} rules={[{ message: langdata && langdata.Pleaseconfirmthenewpassword ? langdata.Pleaseconfirmthenewpassword : 'Please confirm the new password' }]} >
								<Input name="confirmPassword" type='password' placeholder={langdata && langdata.Pleaseconfirmthenewpassword ? langdata.Pleaseconfirmthenewpassword : 'Please confirm the new password'} onChange={changePasswordFormik.handleChange} value={changePasswordFormik.values.confirmPassword}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* Change Password Modal Modal Ends */}
		</>
	);
}