import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR }  from './constants';
import { TLP_RULES_LIST, TLP_RULES_LIST_SUCCESS, TLP_RULES_LIST_ERROR }  from './constants';
import { TLP_RULES_DETAILS, TLP_RULES_DETAILS_SUCCESS, TLP_RULES_DETAILS_ERROR } from './constants';
import { SAVE_TLP_RULE, SAVE_TLP_RULE_SUCCESS, SAVE_TLP_RULE_ERROR } from './constants';
import { UPDATE_TLP_RULE, UPDATE_TLP_RULE_SUCCESS, UPDATE_TLP_RULE_ERROR } from './constants';
import { DELETE_TLP_RULE, DELETE_TLP_RULE_SUCCESS, DELETE_TLP_RULE_ERROR } from './constants';

export default function tlpRulesReducer(state, action) {
	let pagination = {};  
	let tlps = [];
	let rules = [];

	const ruleTypes = [
		{ value: 1, label: 'VAC1' },
		{ value: 2, label: 'VAC2' },
		{ value: 3, label: 'VDC1' },
		{ value: 4, label: 'VDC2' },
		{ value: 5, label: 'Battery' },
		{ value: 6, label: 'Temperature' },
		{ value: 7, label: 'Current' }
	];

	switch (action.type) {
	case TLPS_LIST:
		return { ...state, loading: true, error: false };

	case TLPS_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
		}

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? parseInt(item.id) : 0;
				data.regioninfoId = item.regioninfoId ? item.regioninfoId : 0;
				data.pipelineChainageId = item.pipelineChainageId ? item.pipelineChainageId : 0;
				data.pipelineChainageName = item.pipelineChainage ? item.pipelineChainage : '';
				data.pipelineChainage = item.pipelineChainage ?  parseFloat(item.pipelineChainage)/1000 : 0;
				data.deviceId = item.deviceId ?  item.deviceId : 0;
				data.deviceType = item.deviceType ? item.deviceType : 'tlp';
				data.isTRU = item.isTRU ? item.isTRU : false;
          
				return tlps.push(data);
			});

			return { ...state, tlps: tlps, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, tlps: tlps, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case TLPS_LIST_ERROR:
		return { ...state, loading: false, error: true };
    
	case TLP_RULES_LIST:
		return { ...state, loading: true };

	case TLP_RULES_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 0;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
		}

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.ruleName = item.ruleName ? item.ruleName : '<Not Set>';
				data.pipelineId = item.pipelineId ? item.pipelineId: 0;
				data.regioninfoId = item.regioninfoId ? item.regioninfoId : 0;
				data.pipelineChainageId = item.pipelineChainageId ? item.pipelineChainageId : 0;
				data.pipelineChainage = item.pipelineChainage ? item.pipelineChainage : 'ALL';
				data.deviceId = item.deviceId ? item.deviceId : 0;
				data.ruleType = item.ruleType;
				data.ruleTypeLabel = 'NA';
				data.ruleCategory = item.ruleCategory ? item.ruleCategory : 1;
				data.lowerLimit =  item.ruleCategory && (item.ruleCategory === 2 || item.ruleCategory === 3) ? 'NA' : item.lowerLimit ? item.lowerLimit : 0;
				data.upperLimit = item.ruleCategory &&  (item.ruleCategory === 2 || item.ruleCategory === 3) ? 'NA' : item.upperLimit ? item.upperLimit : 0;
				data.createdAt = item.createdAt ? item.createdAt : '<Not Set>';
				data.updatedAt = item.updatedAt ? item.updatedAt : '<Not Set>';

				ruleTypes.map((type) => {
					if (data.ruleType ===  type.value && item.ruleCategory == 1 || item.ruleCategory == 2) {
						data.ruleTypeLabel = type.label;
					}
				});

				return rules.push(data);
			});

			return { ...state, rules: rules, pagination: pagination, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, rules: rules, pagination: pagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case TLP_RULES_LIST_ERROR:
		return { ...state, loading: false, error: true };
    
	case TLP_RULES_DETAILS:
		return { ...state, loading: true };

	case TLP_RULES_DETAILS_SUCCESS:
		return { ...state, details: action.payload, loading: false };
    
	case TLP_RULES_DETAILS_ERROR:
		return { ...state, loading: false, error: true };

	case SAVE_TLP_RULE:
		return { ...state, loading: true, error: false };

	case SAVE_TLP_RULE_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case SAVE_TLP_RULE_ERROR:
		return { ...state, loading: false, error: true };

	case UPDATE_TLP_RULE:
		return { ...state, loading: true, error: false };

	case UPDATE_TLP_RULE_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case UPDATE_TLP_RULE_ERROR:
		return { ...state, loading: false, error: true };

	case DELETE_TLP_RULE:
		return { ...state, loading: true, error: false };

	case DELETE_TLP_RULE_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case DELETE_TLP_RULE_ERROR:
		return { ...state, loading: false, error: true };

	default:
		throw Error('Unknown action: ' + action.type);
      
	}
}