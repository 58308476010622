import React, { useEffect, useReducer, useState } from 'react';

import { Avatar, Popover, Card, Row, Col, Select, Typography, Space, Spin, Modal, Button, Divider, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Icon } from 'leaflet';
import '../../../App.css';
import 'leaflet/dist/leaflet.css';
import { LayersControl, MapContainer, Marker, Pane, Polyline, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useAuth } from '../../Context';
import { useNavigate } from 'react-router-dom';
import tracklinewalker from './reducer';
import { LINEWALKER_DETAILS, LINEWALKER_LIST, LINEWALKER_LIST_ERROR, LINEWALKER_LIST_SUCCESS } from './constants';
import axios from 'axios';
import moment from 'moment';
import { InfoOutlined, FundProjectionScreenOutlined } from '@ant-design/icons';
import {Tooltip as StyledTooltip} from 'antd';
import GoogleMap from '../../Map/map';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';



const { Title } = Typography;

// const linecolor = { color: 'green' };
const pipecolor = { color: 'black' };
const odmetercolor = { color: '#036CD6' };
const optionalcolor ={color:'orange'};
const bordercolor = { color: '#fc8383'};

const pidsicon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../../images/Refinery1.png'),
	iconSize: [30, 30],
	iconAnchor:[18, 30]
});
  
  
// const markericon = new Icon({
// 	iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
// 	// iconUrl:require("./location-pin.png"),
// 	iconSize:[45,50],
// 	iconAnchor:  [25, 50]  });

const linewalkericon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../../images/man.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]  });

const CHicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/ch.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]  });
const WBicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/wb.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]  });
const KMPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/kmp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]  });
const TLPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/tlp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]  });
const TPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/tp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]  });


export default function TrackLinewalker() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {getRegion, regiondata, handleGetMapdata, mapdata, optionalpipedata,handleOptionalPipelinedata, getUserAllocation ,getUserAllocationData} = useAuth();
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;
	const [regionId, setRegionId] = useState(null);
	const navigate = useNavigate();
	const initalState = {linewalker_data:[], details: null, pending: false, error: false };
	const [linewalker_data, dispatch] = useReducer(tracklinewalker, initalState);
	// const regionparams = `&regioninfoId=${regionId}`;
	const pipeline = [];
	const center=[];
	const odmeter=[];
	const optionalpath = [];
	const markers = [];
	// const points = [];
	// const pidsmarker=[];
	// const line = [];
	const KMP = [];
	const WB = [];
	const Turn = [];
	const TLP = [];
	const map_type= localStorage.getItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`);
	const linewalker_markers= [];
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [allocationId, setAllocationId] = useState(null);
	const [startCh, setStartCh] = useState(null);
	const [endCh, setEndCh] = useState(null);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];

	const [openLWmodal, setLinewalkerModal] =useState(false);

	const [linewalkerdetails, setLinewalkerData] = useState({phone:'', name:'', status:''});
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envRefreshTime = process.env.REACT_APP_REFRESH_TIME == undefined || process.env.REACT_APP_REFRESH_TIME == 'undefined' ? 180000 : process.env.REACT_APP_REFRESH_TIME;
	const refreshTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`) == 'null' ? parseInt(envRefreshTime) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`));
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';
	const oneviewtypeLabel = oneviewType &&  oneviewType.toLowerCase() == 'pipeline' ? langdata && langdata.Pipeline : oneviewType.toLowerCase() == 'airport' ? langdata && langdata.Airport : oneviewType.toLowerCase() == 'border' ? langdata && langdata.Border : 'pipeline';

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.tracklinewalkercontent1 ? langdata.tracklinewalkercontent1:'Linewalkers and Night Patrols performing inspections can be tracked once they have started the inspection in the STS application.'}</p>
			<br/>
			<p>{langdata && langdata.tracklinewalkercontent2 ? langdata.tracklinewalkercontent2:'Details of the linewalker like their name, phone number, status, latitude, longitude, current chainage, upcoming chainage, previous chainage, last updated position details and speed can be viewed.'}</p>
			<br/>
		</Card>
	);

	mapdata &&  mapdata.map((item,index)=>{
		if(index==0){
			center.push(item.latitude,item.longitude);
		}
		pipeline.push([item.latitude,item.longitude]);
	});

	mapdata &&  mapdata.map((item)=>{

		odmeter.push([item.fibreLatitude,item.fibreLongitude]);
	});
  
	optionalpipedata &&  optionalpipedata.map((item)=>{

		optionalpath.push([item.walkingLatitude,item.walkingLongitude]);
	});

	const tempMapArray = {};
	const teampOptionalMapArray = {};

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});

	optionalpipedata && optionalpipedata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!teampOptionalMapArray[fieldValue]) {
			teampOptionalMapArray[fieldValue] = [];
		}
		teampOptionalMapArray[fieldValue].push(item);
	});

	mapdata &&  mapdata.map((item)=>{

		if(item.rouMarker=='CH'){
			markers.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.CH ? langdata.CH :'CH'}`
			},);
		}
		else if(item.rouMarker=='WB'){
			WB.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.WB ? langdata.WB :'WB'}`
			},);
		}

		else if(item.rouMarker=='TLP'){
			TLP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TLP ? langdata.TLP :'TLP'}`
			},);
		}

		else if(item.rouMarker=='KMP'){
			KMP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.KMP ? langdata.KMP :'KMP'}`
			},);
		}

		else if(item.rouMarker=='TP'){
			Turn.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TP ? langdata.TP :'TP'}`
			},);
		}


	});

	linewalker_data.linewalker_data && linewalker_data.linewalker_data.map((item)=>{
      
		linewalker_markers.push({
			geocode:[item.currentLocation && item.currentLocation.latitude ? item.currentLocation.latitude : '' ,item.currentLocation && item.currentLocation.longitude ? item.currentLocation.longitude :''],
			pop:item.user.firstName + item.user.lastName,
			phone:item.user.cellPhone,
			status:item.status,
			Latitude:item.currentLocation.latitude ? item.currentLocation.latitude : '',
			Longitude:item.currentLocation.longitude ? item.currentLocation.longitude : '',
			allocationId:item.allocationId,
			userId:item.userId,
			Speed: item.velocity + ' ' + `${langdata && langdata.KmHr ? langdata.KmHr :'Km/Hr'}`,
			id:item.id
  
			// eslint-disable-next-line no-undef
  
		});
	});

	useEffect(() => {
		if(featuresModule.trackMyLWEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 32);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
		}
		if(map_type && map_type === 'leaflet'){
			handleGetMapdata(authtoken,regionId,startCh,endCh);
			handleOptionalPipelinedata(authtoken,pipelineId,regionId,startCh,endCh);
		}
		
		getLiveInspection();      
		const interval = setInterval(() => {
			getLiveInspection();      
			// navigate(0);
		}, refreshTime ); 
		return () => clearInterval(interval);
      

	},[authtoken, regionId, allocationId]);


	let regions=[{value:null,
		label:`${langdata && langdata.ALL ? langdata.ALL :'ALL'}`
	}];

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	let allPidsDatas =[];

	if(allPidsData.length>0) {
		allPidsData && allPidsData.map((item)=>{
			allPidsDatas.push({
				geocode:[item.latitude, item.longitude], 
				pop:item.label
			});
		});
	
	}

	const handleSetregion = (value, options) =>{
		localStorage.setItem(`${dashboardtype}_REGION_ID`,value);
		if(admin_roles.includes(permission)) {
			setRegionId(value);
		} else {
			setAllocationId(value);
			setRegionId(options.region);
			setStartCh(options.pipelineConfigFrom);
			setEndCh(options.pipelineConfigTo);
		}
	};

	const getLiveInspection = () =>{

		dispatch({ type : LINEWALKER_LIST});

		let allocationParams = '';
		if(admin_roles.includes(permission)){
			allocationParams = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			allocationParams = allocationId ? `&allocationId=${allocationId}` : '';
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/liveinspections-list?$skip=0&$limit=100&$sort[updatedAt]=-1${allocationParams}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : LINEWALKER_LIST_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : LINEWALKER_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : LINEWALKER_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};

	const handleLinewalkerDetails = (userid,allocationid) =>{
		dispatch({ type : LINEWALKER_LIST});

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/linewalkerlastlocation?userId=${userid}&allocationId=${allocationid}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
  
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : LINEWALKER_DETAILS, payload : response.data});
				}else {
					dispatch({ type : LINEWALKER_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : LINEWALKER_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};


	const handleOpenLWModal = (userId,allocationId,phone,name,status) =>{
		setLinewalkerData({phone:phone,name:name,status:status});
		handleLinewalkerDetails(userId,allocationId);
		setLinewalkerModal(true);
	};

	const handleCloseLWModal = () =>{
		setLinewalkerModal(false);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  
	return (
		<>
			<Content>


				<Row
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
				>
					<Col span={12}>
						<Row>
							<Space>
								<FundProjectionScreenOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>

							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.MyLinewalker ? langdata.MyLinewalker :'My Linewalker'}</Title>
						</Row>
					</Col>

					<Col style={{justifyContent:'right', display:'flex'}} span={12} >
						<Row>
							<Col span={24}>
								<StyledTooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion :'Filter by region'}>
									<Space>
										<Select Item
											style={{ minWidth: '240px', maxWidth:'240px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion :'Select Region'}
											optionFilterProp="children"
											// defaultValue={'ALL'}
											// onSearch={onSearch}
											// filterOption={filterOption}
											value={regionId}
											options={regions}
											onSelect={(e, options)=>handleSetregion(e, options)}
										/>
									</Space>
								</StyledTooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Popover title={langdata && langdata.MyLinewalker ? langdata.MyLinewalker :'My Linewalker'} content={infoContent} trigger="click">
									<Avatar size="small"><InfoOutlined /></Avatar>
								</Popover>
								<Space>&nbsp;&nbsp;</Space>
							</Col>
						</Row>
					</Col>
            
				</Row>

				{map_type && map_type !== 'leaflet' ?

					< GoogleMap linewalkerData = {linewalker_data.linewalker_data} regionId={regionId} startCh={startCh} endCh={endCh} onClick = {handleOpenLWModal}/>

					:


					mapdata && mapdata ? 

						<div className="App" style={{textAlign: 'left', margin : '10px'}}>
							<MapContainer center={center} zoom={14} scrollWheelZoom={false}>
								<TileLayer
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
								{/* {view && view==='view' ? 
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    :

    <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
          
          />
          } */}

								{/* <TileLayer
      attribution='Stamen Watercolor'
      url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
    /> */}

								<LayersControl position="topright" >

									<MarkerClusterGroup>
										{allPidsDatas && allPidsDatas.map((marker,index)=>
											<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
												<Popup>
													{marker.pop}
												</Popup>

											</Marker>

	

										)}       
									</MarkerClusterGroup>


									<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP :'KMP'}>
										<MarkerClusterGroup>

											{KMP && KMP.map((marker,index)=>
												<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>

    
									<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB :'WB'}>
										<MarkerClusterGroup>

											{WB && WB.map((marker,index)=>
												<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>




									<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP :'TP'}>
										<MarkerClusterGroup>

											{Turn && Turn.map((marker,index)=>
												<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>

  
									<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP :'TLP'}>
										<MarkerClusterGroup>

											{TLP && TLP.map((marker,index)=>
												<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>


									{/* <LayersControl.Overlay  name="POINTS">
									<MarkerClusterGroup>

										{points && points.map((marker,index)=>
											<Marker key={`points-${index}`} position={marker.geocode} icon={markericon}>
												<Popup>
													{marker.pop}
												</Popup>
												<Tooltip>{marker.pop}</Tooltip>
											</Marker>

										)}       
									</MarkerClusterGroup>

								</LayersControl.Overlay> */}



									<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH :'CH'}>
										<MarkerClusterGroup>
											{markers && markers.map((marker,index)=>
												<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}

										</MarkerClusterGroup>
									</LayersControl.Overlay>

									<MarkerClusterGroup>

										{linewalker_markers && linewalker_markers.map((marker,index)=>
											<Marker eventHandlers={{
												click: () => {
													handleLinewalkerDetails(marker.userId,marker.allocationId);
												},
											}} key={`alarm-${index}`} position={marker.geocode} icon={linewalkericon}>
												<Popup>
													{langdata && langdata.Id ? langdata.Id :'Id'}:{' '}{marker.id}<br/>
													{langdata && langdata.Name ? langdata.Name :'Name'}: {' '}{marker.pop}<br/>
													{langdata && langdata.Phone ? langdata.Phone :'Phone'}:{' '}{marker.phone}<br/>
													{langdata && langdata.Status ? langdata.Status :'Status'}:{' '}{marker.status}<br/>
													{langdata && langdata.Latitude ? langdata.Latitude :'Latitude'}:{' '}{marker.Latitude}<br/>
													{langdata && langdata.Longitude ? langdata.Longitude :'Longitude'}:{' '}{marker.Longitude}<br/>
													{langdata && langdata.Currentchainage ? langdata.Currentchainage :'Current chainage'}:{' '}{linewalker_data.details && linewalker_data.details.lastChainage}<br/>
													{langdata && langdata.Upcomingchainage ? langdata.Upcomingchainage :'Upcoming chainage'}:{' '}{linewalker_data.details && linewalker_data.details.upcomingChainage}<br/>
													{langdata && langdata.Previouschainage ? langdata.Previouschainage :'Previous chainage'}:{' '}{linewalker_data.details && linewalker_data.details.previousChainage}<br/>
													{langdata && langdata.Thepersonhasnotmovedfromthelocationsince ? langdata.Thepersonhasnotmovedfromthelocationsince :'Last Updated: The person has not moved from the location since'} <span style={{color:'#ff4d4f'}}>{moment(linewalker_data.details && linewalker_data.details.updatedAt).format(settingsdateformat + ' ' + settingstimeformat)} 
														{' '} {langdata && langdata.tillnow ? langdata.tillnow :'till now'}</span> / {langdata && langdata.Thepersoniscurrentlyinanoutcoveragearea ? langdata.Thepersoniscurrentlyinanoutcoveragearea :'The person is currently in an out-of-coverage area.'}<br/>
													{langdata && langdata.Speed ? langdata.Speed :'Speed'}:{' '}{marker.Speed}<br/>
												</Popup>
												{/* <Tooltip>{marker.pop}</Tooltip> */}
											</Marker>

										)}       
									</MarkerClusterGroup>

       
									{/* <LayersControl.Overlay  checked name="LINE">
									<Polyline pathOptions={linecolor} weight={7} positions={line} />
								</LayersControl.Overlay> */}

      
									<LayersControl.Overlay  checked name={oneviewtypeLabel ? oneviewtypeLabel :'Pipeline'}>
										<MarkerClusterGroup>
											<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
												{tempMapArray && Object.values(tempMapArray).map((item) => {
													let pipeline = [];
													item.map((data) => {
														pipeline.push({lat : data.latitude,lng : data.longitude});
													});
													return (
														<>
															<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
														</>
													);
												})}
											</Pane>
										</MarkerClusterGroup>
									</LayersControl.Overlay>


									<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder : 'Pipeline Border'}>
										<MarkerClusterGroup>
											{tempMapArray && Object.values(tempMapArray).map((item) => {
												let pipelineBorder = [];
												item.map((data) => {
													pipelineBorder.push({lat : data.latitude,lng : data.longitude});
												});
												return (
													<>
														<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
													</>
												);
											})}
										</MarkerClusterGroup>
									</LayersControl.Overlay>


									<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber : 'Fiber'}>
										<MarkerClusterGroup>
											{tempMapArray && Object.values(tempMapArray).map((item) => {
												let odmeter = [];
												item.map((data) => {
													odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
												});
												return (
													<>
														<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
													</>
												);
											})}
										</MarkerClusterGroup>
									</LayersControl.Overlay>


									<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath : 'Optional Path'}>
										<MarkerClusterGroup>
											{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
												let optionalpath = [];
												item.map((data) => {
													optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
												});
												return (
													<>
														<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
													</>
												);
											})}
										</MarkerClusterGroup>
									</LayersControl.Overlay>
    

								</LayersControl>
							</MapContainer>

						</div> : null
				}
				
				{/* Model Starts */}
				<Modal
					open={openLWmodal}
					title={langdata && langdata.LinewalkerDetails ? langdata.LinewalkerDetails : 'Linewalker Details'}
					// onOk={handleModelOk}
					onCancel={handleCloseLWModal}
					footer={[
						// eslint-disable-next-line react/jsx-key
						<Button type="primary" onClick={handleCloseLWModal}>
							{langdata && langdata.Close ? langdata.Close : 'Close'}
						</Button>,
						// <Button type="primary" onClick={()=>handleModelOk(data.realtime_events_details.id)}>
						//   More info
						// </Button>,
						// eslint-disable-next-line react/jsx-key
						// 				<Button type="primary" onClick={() => {navigate(( '/eventDetails'),{ state: { data: data.realtime_events_details, path: 'realtime-events' } });}}>
						//   More info
						// 				</Button>,
					]}
				>
					<Divider/>
					<Row
						gutter={{
							xs: 8,
							sm: 16,
							md: 24,
							lg: 32,
						}}>
						<Col span={24}>          
							{/* ID:{' '}{linewalker_data.details && linewalker_data.details.id}<br/> */}
							{langdata && langdata.Name ? langdata.Name : 'Name'}: {' '}{linewalkerdetails && linewalkerdetails.name}<br/>
							{langdata && langdata.Phone ? langdata.Phone : 'Phone'}:{' '}{linewalkerdetails && linewalkerdetails.phone}<br/>
							{langdata && langdata.Status ? langdata.Status : 'Status'}:{' '}{linewalkerdetails && linewalkerdetails.status}<br/>
							{langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'}:{' '}{linewalker_data.details && linewalker_data.details.lastLocation.latitude}<br/>
							{langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'}:{' '}{linewalker_data.details && linewalker_data.details.lastLocation.longitude}<br/>
							{langdata && langdata.Currentchainage ? langdata.Currentchainage : 'Current chainage'}:{' '}{linewalker_data.details && linewalker_data.details.lastChainage}<br/>
							{langdata && langdata.Upcomingchainage ? langdata.Upcomingchainage : 'Upcoming chainage'}:{' '}{linewalker_data.details && linewalker_data.details.upcomingChainage}<br/>
							{langdata && langdata.Previouschainage ? langdata.Previouschainage : 'Previous chainage'}:{' '}{linewalker_data.details && linewalker_data.details.previousChainage}<br/>
							{langdata && langdata.Thepersonhasnotmovedfromthelocationsince ? langdata.Thepersonhasnotmovedfromthelocationsince : 'Last Updated: The person has not moved from the location since'} <span style={{color:'#ff4d4f'}}>{moment(linewalker_data.details && linewalker_data.details.updatedAt).format(settingsdateformat + ' ' + settingstimeformat)} 
								{' '} {langdata && langdata.tillnow ? langdata.tillnow : 'till now'}</span> / {langdata && langdata.Thepersoniscurrentlyinanoutcoveragearea ? langdata.Thepersoniscurrentlyinanoutcoveragearea : 'The person is currently in an out-of-coverage area.'}<br/>
							{langdata && langdata.Speed ? langdata.Speed : 'Speed'}:{' '}{linewalker_data.details && linewalker_data.details.lastLocation.velocity + ' ' + `${langdata && langdata.KmHr ? langdata.KmHr :'Km/Hr'}`}<br/>
						</Col>
					</Row>
				</Modal>

			</Content>
			<Spin fullscreen spinning={linewalker_data.pending} />

			{/* Edit grade ends */}

		</>
	);
}