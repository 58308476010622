/* eslint-disable react/react-in-jsx-scope */
import { Breadcrumb, Button, Card, Col, Form, Input, Modal, Row, Select, Spin, Typography, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import reducer from './reducer';
import { ALLOCATION_PIPELINE_PATH, ALLOCATION_PIPELINE_PATH_ERROR, ALLOCATION_PIPELINE_PATH_SUCCESS, EDIT_ALLOCATION_PIPELINE_PATH, EDIT_ALLOCATION_PIPELINE_PATH_ERROR, EDIT_ALLOCATION_PIPELINE_PATH_SUCCESS } from './constants';
import { useAuth } from '../Context';
import { HomeOutlined } from '@ant-design/icons';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;

export default function EditAllocationPipelinePath () {
    
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
    
	const initalState = { pending: false, error: false, linewalkerDetails : [], linewalkerAllocation : []};
	const [data, dispatch] = useReducer(reducer, initalState);
	const navigate = useNavigate();
	const { state } = useLocation();
	const {handleGetMapdata, mapdata} = useAuth();

	const [editAllocationPipelinePathData, setEditAllocationPipelinePathData] = useState({});
	const [startChainage, setStartChainage] = useState();
	const [endChainage, setEndChainage]  = useState();
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const [walkingPaths, setWalkingPaths] = useState([]);

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const actionType = [{
		value : 'RESET',
		label : langdata && langdata.RESET ? langdata.RESET :  'RESET'
	},
	{
		value : 'REDEFINE',
		label :  langdata && langdata.REDEFINE ? langdata.REDEFINE : 'REDEFINE'
	},
	{
		value : 'CHANGE',
		label :  langdata && langdata.CHANGE ? langdata.CHANGE : 'CHANGE'
	}];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!state) {
			navigate(-1);
		}
	},[authtoken]);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		handleGetMapdata(authtoken,null,null,null);
		dispatch ({ type : ALLOCATION_PIPELINE_PATH });
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/userallocation?userId=${state && state.userId}`,
			timeout: extendTimeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`   
			}
		};

		axios.request(config)
			.then((response) => {
				if ( response.status === 200) {
					dispatch({ type : ALLOCATION_PIPELINE_PATH_SUCCESS, payload : response.data});
					response.data.data && response.data.data.map((item) => {
						setEditAllocationPipelinePathData({userName :  `${item.details.firstName} ${item.details.lastName}`,
							regioninfoId : item.regioninfoId,
							startChainage : item.pipelineConfigFrom,
							showStartChainage : item.zoneFromDetails.id,
							endChainage : item.pipelineConfigTo,
							showEndChainage : item.zoneToDetails.id,
							allocationId : item.id,
							userId : item.userId,
							apiCurrentPath : item.currentPath,
							selectActionType : 'RESET'});
						setStartChainage(item.pipelineConfigFrom);
						setEndChainage(item.pipelineConfigTo);
					});

				} else {
					dispatch({ type : ALLOCATION_PIPELINE_PATH_ERROR });
				}
			})
			.catch((error) => {
				dispatch({ type : ALLOCATION_PIPELINE_PATH_ERROR });
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});

	},[authtoken]);

	useEffect(() => {
		if(editAllocationPipelinePathData.selectActionType == 'CHANGE'){
			let data ={
				'allocationId': editAllocationPipelinePathData.allocationId,
				'pipelineId': localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`),
				'regioninfoId': editAllocationPipelinePathData.regioninfoId,
				'userId': editAllocationPipelinePathData.userId
			};	
	
			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/patchcoverage-walking-paths`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
	
			axios.request(config)
				.then((response) => {
					if(response.status === 200 || response.status === 201) {
						setWalkingPaths(response.data.data);
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : EDIT_ALLOCATION_PIPELINE_PATH_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}

	},[editAllocationPipelinePathData.selectActionType, editAllocationPipelinePathData.allocationId]);

	const walkPath = [];
	walkPath.push({ label : langdata && langdata.Default ? langdata.Default : 'Default', value  : 'default' });
	if(walkingPaths.length > 0){
		walkingPaths && walkingPaths.map((item) => {
			walkPath.push({ label : item.pathName, value  :item.pathName });
		});
	}

	const chainages = [];
	mapdata && mapdata.map((item) => {
		if (item.pipelineChainage >= startChainage && item.pipelineChainage <= endChainage) {
			chainages.push({
				value : item.id,
				label : `${item.pipelineChainage} ( ${item.rouMarker} )`
			});
		}
	});

	const handleSelectStartChainage = (value) => {
		mapdata && mapdata.map((item) => {
			if (item.id === value) {
				setEditAllocationPipelinePathData({...editAllocationPipelinePathData, 
					showStartChainage : item.id,
					startChainage : item.pipelineChainage});
			}
		});
	};

	const handlePathName = (e) => {
		setEditAllocationPipelinePathData({...editAllocationPipelinePathData, 
			currentPath : e.target.value});
	};

	const handlePathNameList = (value) => {
		setEditAllocationPipelinePathData({...editAllocationPipelinePathData, 
			currentPath : value});
	};

	const handleAllocation = (value, options) => {
		setEditAllocationPipelinePathData({...editAllocationPipelinePathData, 
			allocationId : value,
			showStartChainage : options.startChainageId,
			showEndChainage : options.endChainageId,
			startChainage : options.startChainage,
			endChainage : options.endChainage,
			regioninfoId : options.regioninfoId,
			currentPath : null
		});
		setStartChainage(options.startChainage);
		setEndChainage(options.endChainage);
	};

	const handleSelectEndChainage = (value) => {
		mapdata && mapdata.map((item) => {
			if (item.id === value) {
				setEditAllocationPipelinePathData({...editAllocationPipelinePathData, 
					showEndChainage : item.id,
					endChainage : item.pipelineChainage});
			}
		});
	};

	const handleChangeActionType = (value) => {
		// if(value == 'CHANGE') {
		// 	setEditAllocationPipelinePathData({...editAllocationPipelinePathData,
		// 		currentPath : editAllocationPipelinePathData.apiCurrentPath});
		// }
		setEditAllocationPipelinePathData({...editAllocationPipelinePathData,
			selectActionType : value,
			currentPath : value == 'CHANGE' ? editAllocationPipelinePathData.apiCurrentPath : null});
	};


	const handleSave = () => {
		let data ={
			'allocationId': editAllocationPipelinePathData.allocationId,
			'chainageFrom': editAllocationPipelinePathData.startChainage,
			'chainageTo': editAllocationPipelinePathData.endChainage,
			'pipelineId': localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`),
			'regioninfoId': editAllocationPipelinePathData.regioninfoId,
			'type': editAllocationPipelinePathData.selectActionType,
			'userId': editAllocationPipelinePathData.userId,
			'currentPath' : 'default'
		};
		if(editAllocationPipelinePathData.selectActionType == 'REDEFINE') {
			if(!editAllocationPipelinePathData.currentPath) {
				Modal.warning({title : langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields : 'Please fill all the mandatory fields'});
				return false;
			}
			data['currentPath'] = editAllocationPipelinePathData.currentPath;
		}

		if(editAllocationPipelinePathData.selectActionType == 'CHANGE') {
			data['currentPath'] = editAllocationPipelinePathData.currentPath;
		}
		
		let urlPath = '';
		if(editAllocationPipelinePathData.selectActionType == 'RESET'  || (editAllocationPipelinePathData.currentPath == 'default' && editAllocationPipelinePathData.selectActionType == 'CHANGE')) {
			urlPath = 'reset';
		} else if(editAllocationPipelinePathData.selectActionType == 'REDEFINE'){
			urlPath = 'redefine';
		} else if(editAllocationPipelinePathData.selectActionType == 'CHANGE'){
			urlPath = 'change';
		}
					
		dispatch({ type : EDIT_ALLOCATION_PIPELINE_PATH });

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/patchcoverage-${urlPath}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if(response.status === 200 || response.status === 201) {
					dispatch({ type : EDIT_ALLOCATION_PIPELINE_PATH_SUCCESS });
					Modal.success({ title: langdata && langdata.Pipelinepathupdatedsuccessfully ? langdata.Pipelinepathupdatedsuccessfully : 'Pipeline path updated successfully.' });					
					navigate('/allocations');
				} else {
					dispatch({ type : EDIT_ALLOCATION_PIPELINE_PATH_ERROR });
					Modal.warning({ title: langdata && langdata.Unabletoupdatepipelinepath ? langdata.Unabletoupdatepipelinepath : 'Unable to update pipeline path.' });					
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : EDIT_ALLOCATION_PIPELINE_PATH_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});

	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());
		
	return (
		<Content>
			{ state ?
				<>
					<Form>
						<Row 
							style={{
								marginLeft:'50px',
								marginTop: '10px'
							}}            
						>
							<Breadcrumb
								items={[
									{
										href: '/dashboard',
										title: <HomeOutlined />,
									},
									{
										title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'}</a> 
									},
									{
										title: langdata && langdata.EditAllocationPipelinePath ? langdata.EditAllocationPipelinePath : 'Edit Allocation Pipeline Path',
									},
								]}
							/>
						</Row>
						<Card  style={{
							marginLeft: '50px',
							marginRight: '50px',
							marginTop: '10px',
							padding: '20px',
							background: '#FFFFFF',
							display: 'flex',
						}}>
							<Row
								gutter={{
									xs: 8,
									sm: 16,
									md: 24,
									lg: 32,
								}}                
							>
								<Col className="gutter-row" span={24}>
									<Title level={4} style={{ paddingTop:'10px', justifyContent : 'center', display : 'flex'}}> {langdata && langdata.EditAllocationPipelinePath ? langdata.EditAllocationPipelinePath : 'Edit Allocation Pipeline Path'}</Title>

								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Actiontype ? langdata.Actiontype : 'Action type'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left', marginTop : '25px'}} >
									<Select
										style={{ width: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Actiontype ? langdata.Actiontype : 'Action type'}
										optionFilterProp="children"
										options={actionType}
										value={editAllocationPipelinePathData.selectActionType}
										onSelect={handleChangeActionType}
									/>
								</Col>
								{editAllocationPipelinePathData.selectActionType != 'RESET' ?
									<>
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.PathName ? langdata.PathName : 'Path Name'} :</Typography>
										</Col>
										{editAllocationPipelinePathData.selectActionType == 'REDEFINE' ? 
											<Col className="gutter-row" span={12} style={{ textAlign : 'left', marginTop : '25px'}} >
												<Input
													style={{ width: '100%'}}
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.PathName ? langdata.PathName : 'Path Name'}
													optionFilterProp="children"
													onChange={handlePathName}
													value={editAllocationPipelinePathData.currentPath}
												/>
											</Col>
											:
											<Col className="gutter-row" span={12} style={{ textAlign : 'left', marginTop : '25px'}} >
												<Select
													style={{ width: '100%'}}
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.PathName ? langdata.PathName : 'Path Name'}
													optionFilterProp="children"
													options={walkPath}
													onSelect={handlePathNameList}
													value={editAllocationPipelinePathData.currentPath}
												/>
											</Col>
										}
									</>
									: null  }
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ width: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'}
										optionFilterProp="children"
										options={data.linewalkerAllocation}
										value={editAllocationPipelinePathData.allocationId}
										onSelect={(value, options)=>{handleAllocation(value, options);}}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Linewalker ? langdata.Linewalker : 'Linewalker'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left', marginTop : '25px'}} >
									<Select
										style={{ width: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Linewalker ? langdata.Linewalker : 'Linewalker'}
										optionFilterProp="children"
										// options={userType}
										value={editAllocationPipelinePathData.userName}
										// onSelect={handleUserRole}
										disabled={true}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left', marginTop : '25px'}} >
									<Select
										style={{ width: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage'}
										optionFilterProp="children"
										options={chainages}
										value={editAllocationPipelinePathData.showStartChainage}
										onSelect={handleSelectStartChainage}
										disabled={true}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left', marginTop : '25px'}} >
									<Select
										style={{ width: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage'}
										optionFilterProp="children"
										options={chainages}
										value={editAllocationPipelinePathData.showEndChainage}
										onSelect={handleSelectEndChainage}
										disabled={true}
									/>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Button onClick={() => {navigate(-1);}}>{langdata && langdata.Cancel ? langdata.Cancel : 'Cancel'}</Button>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Button type='primary' onClick={handleSave}>{langdata && langdata.Save ? langdata.Save : 'Save'}</Button>
								</Col>
							</Row>       
						</Card>
					</Form>
					<Spin fullscreen spinning={data.pending} tip={langdata && langdata.largeloadingmsg ? langdata.largeloadingmsg : 'This process will take 2-3 minutes to complete. Please wait a moment.'}/>
				</> : '' }
		</Content>
	);
}