import { CHAINAGES_LIST, CHAINAGES_LIST_SUCCESS, CHAINAGES_LIST_ERROR } from './constants';
import { AVAILABLE_CHAINAGES_LIST, AVAILABLE_CHAINAGES_LIST_SUCCESS, AVAILABLE_CHAINAGES_LIST_ERROR } from './constants';
import { VULNERABLE_POINTS_LIST, VULNERABLE_POINTS_LIST_SUCCESS, VULNERABLE_POINTS_LIST_ERROR } from './constants';
import { VULNERABLE_POINT_DETAILS, VULNERABLE_POINT_DETAILS_SUCCESS, VULNERABLE_POINT_DETAILS_ERROR } from './constants';
import { SAVE_VULNERABLE_POINT, SAVE_VULNERABLE_POINT_SUCCESS, SAVE_VULNERABLE_POINT_ERROR } from './constants';
import { UPDATE_VULNERABLE_POINT, UPDATE_VULNERABLE_POINT_SUCCESS, UPDATE_VULNERABLE_POINT_ERROR } from './constants';
import { DELETE_VULNERABLE_POINT, DELETE_VULNERABLE_POINT_SUCCESS, DELETE_VULNERABLE_POINT_ERROR } from './constants';

export default function vulnerablePointsReducer(state, action) {
	let chainages = [];
	let availableChainages = [];
	let vulnerablePoints = [];
	let pagination = {};
  
	switch (action.type) {
	case CHAINAGES_LIST:
		return { ...state, loading: true, error: false };

	case CHAINAGES_LIST_SUCCESS:
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.pipelineChainage = item.pipelineChainage || item.pipelineChainage === 0 ? item.pipelineChainage : '';
          
				return chainages.push(data);
			});

			let sortedChainages = chainages.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, chainages: sortedChainages, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, chainages: chainages, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case CHAINAGES_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case AVAILABLE_CHAINAGES_LIST:
		return { ...state, loading: true, error: false };

	case AVAILABLE_CHAINAGES_LIST_SUCCESS:
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.pipelineChainage = item.pipelineChainage || item.pipelineChainage === 0 ? item.pipelineChainage : '';
          
				return availableChainages.push(data);
			});

			let sortedChainages = availableChainages.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, availableChainages: sortedChainages, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, availableChainages: availableChainages, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case AVAILABLE_CHAINAGES_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case VULNERABLE_POINTS_LIST:
		return { ...state, loading: true, error: false };

	case VULNERABLE_POINTS_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
			pagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.pipelineChainage = item.pipelineChainage || item.pipelineChainage === 0 ? item.pipelineChainage : '';
				data.ODMeter = item.ODMeter;
				data.latitude = item.latitude ? parseFloat(item.latitude) : null;
				data.longitude = item.longitude ? parseFloat(item.longitude) : null;
				data.rouMarker = item.rouMarker ? item.rouMarker : '';
				data.terrainClassification = item.terrainClassification ? item.terrainClassification  : '';
				data.vulnerablePoint = item.vulnerablePoint ? item.vulnerablePoint : false;
				data.isSnoozed = item.isSnoozed ? item.isSnoozed : false;
				data.createdAt = item.createdAt ? item.createdAt : null;
				data.updatedAt = item.updatedAt ? item.updatedAt : null;

				return vulnerablePoints.push(data);
			});

			let sortedVulnerablePoints = vulnerablePoints.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, vulnerablePoints: sortedVulnerablePoints, pagination: pagination, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, vulnerablePoints: vulnerablePoints, pagination: pagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case VULNERABLE_POINTS_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case VULNERABLE_POINT_DETAILS:
		return { ...state, loading: true, error: false };

	case VULNERABLE_POINT_DETAILS_SUCCESS:
		return { ...state, details: action.payload, loading: false, error: false };

	case VULNERABLE_POINT_DETAILS_ERROR:
		return { ...state, loading: false, error: true };

	case SAVE_VULNERABLE_POINT:
		return { ...state, loading: true, error: false };

	case SAVE_VULNERABLE_POINT_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case SAVE_VULNERABLE_POINT_ERROR:
		return { ...state, loading: false, error: true };

	case UPDATE_VULNERABLE_POINT:
		return { ...state, loading: true, error: false };

	case UPDATE_VULNERABLE_POINT_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case UPDATE_VULNERABLE_POINT_ERROR:
		return { ...state, loading: false, error: true };

	case DELETE_VULNERABLE_POINT:
		return { ...state, loading: true, error: false };

	case DELETE_VULNERABLE_POINT_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case DELETE_VULNERABLE_POINT_ERROR:
		return { ...state, loading: false, error: true };

	default: 
		throw Error('Unknown action: ' + action.type);
      
	}
}