import React, { useEffect, useReducer, useState } from 'react';

import { Empty, Row, Col, Select, Typography, Table, Breadcrumb, Card, Space, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Icon } from 'leaflet';
import '../../App.css';
import 'leaflet/dist/leaflet.css';

import { LayersControl, MapContainer, Marker, Pane, Polyline, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Context';
import inspectiondata from './Vulnerable/reducer';
import axios from 'axios';
import { VULNERABLE_INSPECTION_LIST, VULNERABLE_INSPECTION_LIST_ERROR, VULNERABLE_INSPECTION_LIST_SUCCESS } from './Vulnerable/constants';
import { FullscreenOutlined,FullscreenExitOutlined, HomeOutlined } from '@ant-design/icons';
import GoogleMap from '../Map/map';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;

const linecolor = { color: 'green' };
const pipecolor = { color: 'black' };
const odmetercolor = { color: '#036CD6' };
const optionalcolor ={color:'orange'};
const bordercolor = { color: '#fc8383'};

// const pidsicon = new Icon({
// 	iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
// 	// iconUrl:require('../../images/refinery.png'),
// 	iconSize:[45,50],
// 	iconAnchor:  [25, 50]});


// const markericon = new Icon({
// 	iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
// 	// iconUrl:require("./location-pin.png"),
// 	iconSize:[45,50],
// 	iconAnchor:  [25, 50]});
const CHicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/ch.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const WBicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/wb.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const KMPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/kmp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const TLPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/tlp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const TPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/tp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});

const PatchPoint = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/red-pin.png'),
	iconSize: [20, 30],
	iconAnchor:[18, 36]
});

const StartIcon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/start.png'),
	iconSize: [20, 30],
	iconAnchor:[18, 36]
});

const EndIcon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/stop.png'),
	iconSize: [20, 30],
	iconAnchor:[18, 36]
});

const pidsicon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../images/Refinery1.png'),
	iconSize: [30, 30],
	iconAnchor:[18, 30]
});



export default function VulnerablePatchDetails() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const { state } = useLocation();
	const navigate = useNavigate();
	const [size, setSize] = useState('200px');
	const {handleGetMapdata, mapdata, optionalpipedata,handleOptionalPipelinedata} = useAuth();
	const [patchsort, setPatchSort] = useState(null);
	const initalState = {inspection_data:[], details: null, pending: false, error: false };
	const [inspection_data, dispatch] = useReducer(inspectiondata, initalState);
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';
	const oneviewtypeLabel = oneviewType &&  oneviewType.toLowerCase() == 'pipeline' ? langdata && langdata.Pipeline : oneviewType.toLowerCase() == 'airport' ? langdata && langdata.Airport : oneviewType.toLowerCase() == 'border' ? langdata && langdata.Border : 'pipeline';

	const options = [
		{
			value: null,
			label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`,
		},
		{
			value: 1,
			label: `${langdata && langdata.COVERED ? langdata.COVERED:'COVERED'}`,
		},
		{
			value: 0,
			label: `${langdata && langdata.NOTCOVERED ? langdata.NOTCOVERED:'NOT COVERED'}`,
		},
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!state) {
			navigate(-1);
		}
	},[authtoken]);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}else{
			if(map_type && map_type === 'leaflet' && state) {
				handleGetMapdata(authtoken,state.data.regioninfoId,state.data.chainageFrom,state.data.chainageTo);
				handleOptionalPipelinedata(authtoken,state.data.pipelineId,state.data.chainageFrom,state.data.chainageTo);
			}

			dispatch({ type : VULNERABLE_INSPECTION_LIST});
			const ids = state && state.data.inspectionIds;
			var dataquery='';
			ids && ids.map((id) => {
				dataquery+= `&id[$in][]=${id}`;
			});
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/inspection?userId=${parseInt(state && state.data.userId)}${dataquery}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : ''
			};
      
			axios.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch({ type : VULNERABLE_INSPECTION_LIST_SUCCESS, payload : response.data.data});
					}else {
						dispatch({ type : VULNERABLE_INSPECTION_LIST_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : VULNERABLE_INSPECTION_LIST_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});

		}
 

	},[authtoken]);

	const pipeline = [];
	const center=[];
	const odmeter=[];
	const optionalpath = [];
	const markers = [];
	const points = [];
	const pidsmarker=[];
	const line = [];
	const KMP = [];
	const WB = [];
	const Turn = [];
	const TLP = [];
	const map_type= localStorage.getItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`);
	const startAllocation = [];
	const endAllocation = [];

	mapdata &&  mapdata.map((item,index)=>{
		if(index==0){
			center.push(item.latitude,item.longitude);
		}
		pipeline.push([item.latitude,item.longitude]);
	});

	mapdata &&  mapdata.map((item)=>{

		odmeter.push([item.fibreLatitude,item.fibreLongitude]);
	});
  
	optionalpipedata &&  optionalpipedata.map((item)=>{

		optionalpath.push([item.walkingLatitude,item.walkingLongitude]);
	});

	mapdata &&  mapdata.map((item)=>{

		if(item.rouMarker=='CH'){
			markers.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.CH ? langdata.CH:'CH'}`
			},);
		}
		else if(item.rouMarker=='WB'){
			WB.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.WB ? langdata.WB:'WB'}`
			},);
		}

		else if(item.rouMarker=='TLP'){
			TLP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TLP ? langdata.TLP:'TLP'}`
			},);
		}

		else if(item.rouMarker=='KMP'){
			KMP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.KMP ? langdata.KMP:'KMP'}`
			},);
		}

		else if(item.rouMarker=='TP'){
			Turn.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TP ? langdata.TP:'TP'}`
			},);
		}


	});
  
	mapdata &&  mapdata.map((item,index)=>{

		if(index==0){
			pidsmarker.push({
				geocode:[item.latitude,item.longitude],
				pop:'PID 1'
			},);
		}

	});

	inspection_data.inspection_data && inspection_data.inspection_data.map((item)=>{
		item.liveLocation.map((inneritem, index)=>{
			line.push([inneritem.latitude,inneritem.longitude]);
			if(index==0){
				center.length=0;
				center.push(parseFloat(inneritem.latitude), parseFloat(inneritem.longitude));
			}
		});
	});


	inspection_data.inspection_data && inspection_data.inspection_data.map((item)=>{
		startAllocation.push( {geocode : [parseFloat(item.startLocation.latitude), parseFloat(item.startLocation.longitude)],pop:`${langdata && langdata.START ? langdata.START:'START'}`});
		endAllocation.push({geocode : [item.endLocation.latitude && item.endLocation.latitude, item.endLocation.longitude && item.endLocation.longitude],pop:`${langdata && langdata.END ? langdata.END:'END'}`});
		item.liveLocation.map((inneritem, index)=>{
			points.push({
				geocode:[inneritem.latitude,inneritem.longitude],
				pop:`${langdata && langdata.COVERED ? langdata.COVERED:'COVERED'}`
			},);
			if(index==0){
				center.length=0;
				center.push(parseFloat(inneritem.latitude), parseFloat(inneritem.longitude));
			}
		});
	});


	const coveredChainages = [];
	const uncoveredchainages = [];
	const allocatedChainages = [];


	if(mapdata){

		let evts = mapdata;
		let evts2 = state.data.coveredChainages;
		for (let i = 0; i < evts.length; i++) {
			let status = `${langdata && langdata.NOTCOVERED ? langdata.NOTCOVERED:'NOT COVERED'}`;
			for (let j = 0; j < evts2.length; j++) {
				if(evts2[j]==evts[i].id){
					status = `${langdata && langdata.COVERED ? langdata.COVERED:'COVERED'}`;
					break;
				}
			}
			if(evts[i].vulnerablePoint==true){

				allocatedChainages.push({
					key: evts[i].id,
					id: evts[i].id,
					pipelinechainage: evts[i].pipelineChainage + ' ' +evts[i].rouMarker,
					status: status,
				});
			}
		}

	}

	if(mapdata && state.data.coveredChainages){
		let evts = mapdata;
		let evts2 = state.data.coveredChainages;
		for (let i = 0; i < evts.length; i++) {
			for (let j = 0; j < evts2.length; j++) {
          
				if(evts2[j]==evts[i].id){
            
					coveredChainages.push({
						key: evts[i].id,
						id: evts[i].id,
						pipelinechainage: evts[i].pipelineChainage + ' ' +evts[i].rouMarker,
						status:`${langdata && langdata.COVERED ? langdata.COVERED:'COVERED'}`,
					});
				}
			}
		}

	}

    
	let arr1 = mapdata && mapdata.map((item) => item.id);
	let arr2=  state && state.data.coveredChainages;
	let arrayDifference = arr1 && arr1.filter(x => !arr2.includes(x));

	if(mapdata && arrayDifference){
		let evts = mapdata;
		let evts2 = arrayDifference;
		for (let i = 0; i < evts.length; i++) {
			for (let j = 0; j < evts2.length; j++) {
          
				if(evts2[j]==evts[i].id && evts[i].vulnerablePoint==true){
            
					uncoveredchainages.push({
						key: evts[i].id,
						id: evts[i].id,
						pipelinechainage: evts[i].pipelineChainage + ' ' +evts[i].rouMarker,
						status:`${langdata && langdata.NOTCOVERED ? langdata.NOTCOVERED:'NOT COVERED'}`,
					});
				}
			}
		}

	}

	let allPidsDatas =[];

	if(allPidsData.length>0) {
		allPidsData && allPidsData.map((item)=>{
			allPidsDatas.push({
				geocode:[item.latitude, item.longitude], 
				pop:item.label
			});
		});
	
	}
    
	const tempMapArray = {};
	const teampOptionalMapArray = {};

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});

	optionalpipedata && optionalpipedata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!teampOptionalMapArray[fieldValue]) {
			teampOptionalMapArray[fieldValue] = [];
		}
		teampOptionalMapArray[fieldValue].push(item);
	});

	const columns = [
  
		{
			title: `${langdata && langdata.PipelineChainage ? langdata.PipelineChainage:'Pipeline Chainage'}`,
			dataIndex: 'pipelinechainage',
			key: 'pipelinechainage',
		},
   
		{
			title: `${langdata && langdata.Status ? langdata.Status:'Status'}`,
			dataIndex: 'status',
			key: 'status',
			// render: (_, { status }) => (
			// 	<>
			// 		<Tag color={status == 'COVERED' ? 'red' : status=='ALL' ? 'red' :'red'} key={status}>
			// 			{status}
			// 		</Tag>
			// 	</>
			// )
		},
	];

	const handlePatchSort=(e)=>{
		setPatchSort(e);
	};



	return (
		<>
			{state ? 
				<>
					<Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
					style={{
						margin: '20px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
						marginTop:'10px',
						marginBottom: '0px'
				
					}}
					>
						<Col style={{marginTop : '10px'}}span={12}>
							<Row>
								<Space>
									<Title level={5} style={{ lineHeight: '2', fontWeight:'1'}}> {langdata && langdata.LineWalkerNPV ? langdata.LineWalkerNPV:'Linewalker/NPV'}: <b>{state.data.name}</b> </Title>
								</Space>
								<Space>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Space>
								<Space>
									<Title level={5} style={{ lineHeight: '2', fontWeight:'1'}}> {langdata && langdata.Date ? langdata.Date:'Date'}: <b> {state.data.itemdate}</b> </Title>
								</Space>
					
							</Row>
						</Col>

					</Row>

					{size && size === '200px' ? 
						<Row gutter={{
							xs: 8,
							sm: 16,
							md: 24,
							lg: 32,
						}} style={{
							margin: '5px',
							// paddingLeft: '10px',
							// paddingRight: '10px',
							// background: '#FFFFFF',
							alignItems:'center',
							marginTop:'10px',
							marginBottom: '0px',
							// marginLeft : '0px',
							// marginRight  :'0px'
						}} >

							<Col className="gutter-row" span={24}>
								<Card>
									<Breadcrumb
										items={[
											{
												href: '/dashboard',
												title: <HomeOutlined />,
											},
											{
												title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.Vulnerable ? langdata.Vulnerable:'Vulnerable'}</a> 
											},
											{
												title: `${langdata && langdata.VulnerableDetails ? langdata.VulnerableDetails:'Vulnerable Details'}`,
											},
										]}
									/>
									{/* <Row style={{justifyContent:'right', display:'flex'}}>

<Button onClick={()=>{setSize(size === 'auto' ? '200px':'auto')}} style={{marginRight:'20px'}} >Full Size</Button>
    </Row> */}
									<Content>

										{/* map filter starts  */}
										{map_type && map_type !== 'leaflet' ?

											<GoogleMap height = {'30vh'} vulnerable_data = {inspection_data.inspection_data} regionId={state.data.regioninfoId} startCh={state.data.chainageFrom} endCh={state.data.chainageTo} />

											:

											mapdata && mapdata ? 
												<div className="App" style={{textAlign: 'left'}}>
        
													<MapContainer center={center} zoom={14} scrollWheelZoom={false} style={{height:size}}>
														<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenOutlined /></button>
														<TileLayer
															attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
															url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
														/>
														{/* {view && view==='view' ? 
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    :

    <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
          
          />
          } */}

														{/* <TileLayer
      attribution='Stamen Watercolor'
      url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
    /> */}

														<LayersControl position="topright" >

															<MarkerClusterGroup>
																{allPidsDatas && allPidsDatas.map((marker,index)=>
																	<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
																		<Popup>
																			{marker.pop}
																		</Popup>

																	</Marker>

	

																)}       
															</MarkerClusterGroup>


															<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP:'KMP'}>
																<MarkerClusterGroup>

																	{KMP && KMP.map((marker,index)=>
																		<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}       
																</MarkerClusterGroup>

															</LayersControl.Overlay>


															<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB:'WB'}>
																<MarkerClusterGroup>

																	{WB && WB.map((marker,index)=>
																		<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}       
																</MarkerClusterGroup>

															</LayersControl.Overlay>




															<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP:'TP'}>
																<MarkerClusterGroup>

																	{Turn && Turn.map((marker,index)=>
																		<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}       
																</MarkerClusterGroup>

															</LayersControl.Overlay>


															<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP:'TLP'}>
																<MarkerClusterGroup>

																	{TLP && TLP.map((marker,index)=>
																		<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}       
																</MarkerClusterGroup>

															</LayersControl.Overlay>


															<LayersControl.Overlay  name={langdata && langdata.POINTS ? langdata.POINTS:'POINTS'}>
																<MarkerClusterGroup>

																	{points && points.map((marker,index)=>
																		<Marker key={`points-${index}`} position={marker.geocode} icon={PatchPoint}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)} 
																	{startAllocation && startAllocation.map((marker,index)=>
																		<Marker key={`points-${index}`} position={marker.geocode} icon={StartIcon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}       
																	{endAllocation && endAllocation.map((marker,index)=>
																		<Marker key={`points-${index}`} position={marker.geocode} icon={EndIcon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}         
																</MarkerClusterGroup>

															</LayersControl.Overlay>



															<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH:'CH'}>
																<MarkerClusterGroup>
																	{markers && markers.map((marker,index)=>
																		<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}

																</MarkerClusterGroup>
															</LayersControl.Overlay>


															<LayersControl.Overlay  checked name={langdata && langdata.LINE ? langdata.LINE:'LINE'}>
																<Polyline pathOptions={linecolor} weight={7} positions={line} />
															</LayersControl.Overlay>


															<LayersControl.Overlay  checked name={oneviewtypeLabel ? oneviewtypeLabel:'Pipeline'}>
																<MarkerClusterGroup>
																	<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
																		{tempMapArray && Object.values(tempMapArray).map((item) => {
																			let pipeline = [];
																			item.map((data) => {
																				pipeline.push({lat : data.latitude,lng : data.longitude});
																			});
																			return (
																				<>
																					<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
																				</>
																			);
																		})}
																	</Pane>
																</MarkerClusterGroup>
															</LayersControl.Overlay>


															<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder:'Pipeline Border'}>
																<MarkerClusterGroup>
																	{tempMapArray && Object.values(tempMapArray).map((item) => {
																		let pipelineBorder = [];
																		item.map((data) => {
																			pipelineBorder.push({lat : data.latitude,lng : data.longitude});
																		});
																		return (
																			<>
																				<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
																			</>
																		);
																	})}
																</MarkerClusterGroup>
															</LayersControl.Overlay>


															<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber:'Fiber'}>
																<MarkerClusterGroup>
																	{tempMapArray && Object.values(tempMapArray).map((item) => {
																		let odmeter = [];
																		item.map((data) => {
																			odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
																		});
																		return (
																			<>
																				<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
																			</>
																		);
																	})}
																</MarkerClusterGroup>
															</LayersControl.Overlay>


															<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath:'Optional Path'}>
																<MarkerClusterGroup>
																	{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
																		let optionalpath = [];
																		item.map((data) => {
																			optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
																		});
																		return (
																			<>
																				<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
																			</>
																		);
																	})}
																</MarkerClusterGroup>
															</LayersControl.Overlay>
    

														</LayersControl>
													</MapContainer>

												</div>
												:null
										}

										<Row gutter={{
											xs: 8,
											sm: 16,
											md: 24,
											lg: 32,
										}}
										style={{
											margin: '10px',
											// paddingLeft: '10px',
											// paddingRight: '10px',
											background: '#FFFFFF',
											alignItems:'center',
										}}
										>
  
											<Col className="gutter-row" span={24}>

												<Select
													style={{ minWidth: '240px', maxWidth:'240px', marginTop:'10px'}}
													value={patchsort} defaultValue={patchsort} 
													onSelect={handlePatchSort} options={options} />

											</Col>
										</Row>

										<Row  style={{
											margin: '10px',
											paddingLeft: '10px',
											paddingRight: '10px',
											background: '#FFFFFF',
											alignItems:'center',
										}} span={24} 
										>
											<Col span={24}>
												{ allocatedChainages.length > 0 ? (
													<Table scroll={{
														x: 500,
													}} columns={columns} dataSource={patchsort && patchsort==null ? allocatedChainages : patchsort == 0 ?
														uncoveredchainages : patchsort==1 ? coveredChainages : allocatedChainages} />
												)
													:
													(
														<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
													)}
											</Col>
										</Row>




									</Content>
								</Card>
							</Col>
						</Row>
          
          
          
          
						: 
						<div className="App" style={{textAlign: 'left', margin : '10px'}}>
							<MapContainer center={center} zoom={14} scrollWheelZoom={false}>
								<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenExitOutlined /></button>
								<TileLayer
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
								{/* {view && view==='view' ? 
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    :

    <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
          
          />
          } */}

								{/* <TileLayer
      attribution='Stamen Watercolor'
      url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
    /> */}

								<LayersControl position="topright" >

									<MarkerClusterGroup>
										{allPidsDatas && allPidsDatas.map((marker,index)=>
											<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
												<Popup>
													{marker.pop}
												</Popup>

											</Marker>

	

										)}       
									</MarkerClusterGroup>


									<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP:'KMP'}>
										<MarkerClusterGroup>

											{KMP && KMP.map((marker,index)=>
												<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>

    
									<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB:'WB'}>
										<MarkerClusterGroup>

											{WB && WB.map((marker,index)=>
												<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>




									<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP:'TP'}>
										<MarkerClusterGroup>

											{Turn && Turn.map((marker,index)=>
												<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>

  
									<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP:'TLP'}>
										<MarkerClusterGroup>

											{TLP && TLP.map((marker,index)=>
												<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>


									<LayersControl.Overlay  name={langdata && langdata.POINTS ? langdata.POINTS:'POINTS'}>
										<MarkerClusterGroup>

											{points && points.map((marker,index)=>
												<Marker key={`points-${index}`} position={marker.geocode} icon={PatchPoint}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)} 
											{startAllocation && startAllocation.map((marker,index)=>
												<Marker key={`points-${index}`} position={marker.geocode} icon={StartIcon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
											{endAllocation && endAllocation.map((marker,index)=>
												<Marker key={`points-${index}`} position={marker.geocode} icon={EndIcon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}         
										</MarkerClusterGroup>

									</LayersControl.Overlay>



									<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH:'CH'}>
										<MarkerClusterGroup>
											{markers && markers.map((marker,index)=>
												<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}

										</MarkerClusterGroup>
									</LayersControl.Overlay>

       
									<LayersControl.Overlay  checked name={langdata && langdata.LINE ? langdata.LINE:'LINE'}>
										<Polyline pathOptions={linecolor} weight={7} positions={line} />
									</LayersControl.Overlay>

      
									<LayersControl.Overlay  checked name={oneviewtypeLabel ? oneviewtypeLabel:'Pipeline'}>
										<MarkerClusterGroup>
											<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
												{tempMapArray && Object.values(tempMapArray).map((item) => {
													let pipeline = [];
													item.map((data) => {
														pipeline.push({lat : data.latitude,lng : data.longitude});
													});
													return (
														<>
															<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
														</>
													);
												})}
											</Pane>
										</MarkerClusterGroup>
									</LayersControl.Overlay>


									<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder:'Pipeline Border'}>
										<MarkerClusterGroup>
											{tempMapArray && Object.values(tempMapArray).map((item) => {
												let pipelineBorder = [];
												item.map((data) => {
													pipelineBorder.push({lat : data.latitude,lng : data.longitude});
												});
												return (
													<>
														<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
													</>
												);
											})}
										</MarkerClusterGroup>
									</LayersControl.Overlay>


									<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber:'Fiber'}>
										<MarkerClusterGroup>
											{tempMapArray && Object.values(tempMapArray).map((item) => {
												let odmeter = [];
												item.map((data) => {
													odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
												});
												return (
													<>
														<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
													</>
												);
											})}
										</MarkerClusterGroup>
									</LayersControl.Overlay>


									<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath:'Optional Path'}>
										<MarkerClusterGroup>
											{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
												let optionalpath = [];
												item.map((data) => {
													optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
												});
												return (
													<>
														<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
													</>
												);
											})}
										</MarkerClusterGroup>
									</LayersControl.Overlay>

								</LayersControl>
							</MapContainer>

						</div>
       
					}

					{/* Edit grade ends */}

				</>
				: '' }
		</>
	);
}