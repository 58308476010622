import { MAIL_TEMPLATE, MAIL_TEMPLATE_ADD, MAIL_TEMPLATE_ADD_ERROR, MAIL_TEMPLATE_ADD_SUCCESS, MAIL_TEMPLATE_DELETE, MAIL_TEMPLATE_DELETE_ERROR, MAIL_TEMPLATE_DELETE_SUCCESS, MAIL_TEMPLATE_EDIT, MAIL_TEMPLATE_EDIT_ERROR, MAIL_TEMPLATE_EDIT_SUCCESS, MAIL_TEMPLATE_ERROR, MAIL_TEMPLATE_SUCCESS } from './constants';

export default function mailTemplateReducer(state, action)  {
	let pagination = {};
	switch(action.type){
	case MAIL_TEMPLATE :
		return { ...state, loading: true };
	case MAIL_TEMPLATE_SUCCESS :
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
		}
		return { ...state, mailTemplates: action.payload.data, pagination : pagination, loading: false, error: false };
	case MAIL_TEMPLATE_ERROR :
		return { ...state, loading: false, error: true };
	case MAIL_TEMPLATE_ADD :
		return { ...state, loading: true };
	case MAIL_TEMPLATE_ADD_SUCCESS:
		return { ...state, loading: false, error: false };
	case MAIL_TEMPLATE_ADD_ERROR:
		return { ...state, loading: false, error: false };
	case MAIL_TEMPLATE_EDIT :
		return { ...state, loading: true };
	case MAIL_TEMPLATE_EDIT_SUCCESS:
		return { ...state, loading: false, error: false };
	case MAIL_TEMPLATE_EDIT_ERROR:
		return { ...state, loading: false, error: false };
	case MAIL_TEMPLATE_DELETE :
		return { ...state, loading: true };
	case MAIL_TEMPLATE_DELETE_SUCCESS:
		return { ...state, loading: false, error: false };
	case MAIL_TEMPLATE_DELETE_ERROR:
		return { ...state, loading: false, error: false };
	default:
		throw Error('Unknown action: ' + action.type);
	}
}
