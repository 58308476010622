import React from 'react';
import { useAuth } from '../Context';

import { Row, Col, Typography, Space,  Popover, Avatar, Card, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import {  InfoOutlined,AreaChartOutlined } from '@ant-design/icons';

// import Plot from 'react-plotly.js';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';
import reportImg from '../../images/visualization/reports.gif';
import fractureReportImg from '../../images/visualization/fractureMonitoring.gif';
// import sandingressReportImg from '../../images/visualization/sandingress.gif';
import wellimg from '../../images/visualization/well-leak-detect.gif';
// import heatmap from '../../images/visualization/heatmap.gif';
import leakdetectReportImg from '../../images/visualization/leak.gif';
import { useNavigate } from 'react-router-dom';
import AmplitudeImg from '../../images/visualization/amplitude.gif';
import flowImg from '../../images/visualization/flowmonitoring.gif';

const { Title } = Typography;

// const content = <div style={contentStyle} />;

export default function WellVisualization() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	// const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	
	useAuth();	

	const navigate = useNavigate();

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;	

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.wellvisualizationcontent1 ? langdata.wellvisualizationcontent1 : 'Well visualization involves the graphical representation of subsurface data and reservoir characteristics, enhancing the understanding of oil reservoirs and production dynamics. It aids in identifying various events such as sand monitoring, microseismic monitoring, hydraulic fracture monitoring, and leak detection, ultimately optimizing extraction and ensuring safety.'}</p>
		</Card>
	);

	const leakgraphContent = (
		<Card size="small" bordered="false" type="inner">
			<b>{langdata && langdata.LeakContent1 ? langdata.LeakContent1 : 'Leak Detection in Oil Wells.'}</b>
			<br/>
			<p style={{marginTop:'10px'}}>{langdata && langdata.LeakContent2 ? langdata.LeakContent2 : 'Ensures operational safety and efficiency by identifying leaks.'}</p>
			<br/>
			<b style={{marginTop:'10px'}}>{langdata && langdata.LeakContent3 ? langdata.LeakContent3 : 'Heatmaps:'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.LeakContent4 ? langdata.LeakContent4 : 'DTS Temperature Heatmap: Shows temperature variations over time and depth.'}</li>
				<li>{langdata && langdata.LeakContent5 ? langdata.LeakContent5 : 'DAS  Heatmap: Highlights potential leaks based on acoustic data.'}</li>

			</ul>
			<b style={{marginTop:'10px'}}>{langdata && langdata.LeakContent6 ? langdata.LeakContent6 : 'Leakage Mapping:'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.LeakContent7 ? langdata.LeakContent7 : '3D graphical representation of leak locations and severity at specific depths.'}</li>
			</ul>
			<b style={{marginTop:'10px'}}>{langdata && langdata.LeakContent8 ? langdata.LeakContent8 : 'Anomaly Counts:'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.LeakContent9 ? langdata.LeakContent9 : 'Zone Leakage Event Counts: Bar graph displaying detected anomalies per zone, aiding in identifying leakages through DTS and DAS data.'}</li>
			</ul>

		</Card>
	);

	const flowMonitoringGraphContent = (
		<Card size="small" bordered="false" type="inner">
			{/* <b>{langdata && langdata.flowContent1 ? langdata.flowContent1 : 'Flow Monitoring includes:'}</b>
			<br/>
			<br/> */}
			<b style={{marginTop:'10px'}}>{langdata && langdata.flowContent4 ? langdata.flowContent4 : 'DAS and DTS Heat Map'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.flowContent2 ? langdata.flowContent2 : 'Displays real-time acoustic and temperature data from sensors at various depths, with color intensity indicating value levels. It helps monitor flow changes and highlights anomalies near the well.'}</li>
			</ul>
			<b style={{marginTop:'10px'}}>{langdata && langdata.flowContent5 ? langdata.flowContent5 : 'DAS and DTS Line Plot'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.flowContent3 ? langdata.flowContent3 : 'Continuously updates acoustic and temperature values at a specific time. The y-axis shows well depth, while the x-axis shows value magnitude, allowing for visual detection of significant flow changes.'}</li>
			</ul>
		</Card>
	);

	const sandIngressGraphContent = (
		<Card size="small" bordered="false" type="inner">
			<b>{langdata && langdata.sandIngressContent1 ? langdata.sandIngressContent1 : 'visualization methods for detecting sand ingress in oil wells.'}</b>
			<br/>
			<br/>
			<b style={{marginTop:'10px'}}>{langdata && langdata.sandIngressContent2 ? langdata.sandIngressContent2 : 'Heat Map 1 (DAS Data)'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.sandIngressContent3 ? langdata.sandIngressContent3 : 'Visualizes sand migration in the reservoir, aiding in early detection and well optimization.'}</li>
			</ul>
			<b style={{marginTop:'10px'}}>{langdata && langdata.sandIngressContent4 ? langdata.sandIngressContent4 : 'Heat Map 2 (Filtered DAS Data)'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.sandIngressContent5 ? langdata.sandIngressContent5 : 'Applies frequency filters to reduce noise, enhancing sand ingress detection.'}</li>
			</ul>
			<b style={{marginTop:'10px'}}>{langdata && langdata.sandIngressContent6 ? langdata.sandIngressContent6 : 'Line Plots (Amplitude vs Depth)'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.sandIngressContent7 ? langdata.sandIngressContent7 : 'Show amplitude variations that may indicate sand ingress, complementing heatmap insights.'}</li>
			</ul>
			<b style={{marginTop:'10px'}}>{langdata && langdata.sandIngressContent8 ? langdata.sandIngressContent8 : 'SNR Graph (Signal-to-Noise Ratio)'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.sandIngressContent9 ? langdata.sandIngressContent9 : 'Assesses DAS data quality; higher SNR indicates clearer data for identifying sand ingress.'}</li>
			</ul>
			<p style={{marginTop:'10px'}}>{langdata && langdata.sandIngressContent10 ? langdata.sandIngressContent10 : 'Similar analyses are applied to DTS data, allowing for effective monitoring of sand ingress in oil and gas wells.'}</p>
		</Card>
	);

	const fractureGraphContent = (
		<Card size="small" bordered="false" type="inner">
			<b>{langdata && langdata.HydraulicFracturing ? langdata.HydraulicFracturing: 'Hydraulic Fracturing'}</b>
			<br />
			<p style={{marginTop:'10px'}}>{langdata && langdata.fracturingContent1 ? langdata.fracturingContent1 : 'Hydraulic fracturing visualization involves creating detailed graphical representations of the fracturing process within subsurface rock formations. By utilizing Distributed Temperature Sensing (DTS) and Distributed Acoustic Sensing (DAS), we can identify fracture formations and measure the distance of fracturing events. Integrating this data with pressure measurements enhances our understanding of the fracturing process, enabling more effective monitoring and optimization of hydraulic stimulation strategies.'}</p>
		</Card>
	);

	const microseismicGraphContent = (
		<Card size="small" bordered="false" type="inner">
			{/* <b>{langdata && langdata.sandIngressContent1 ? langdata.sandIngressContent1 : 'visualization methods for detecting sand ingress in oil wells.'}</b> */}
			<p>{langdata && langdata.MicroseismicContent1 ? langdata.MicroseismicContent1 : 'The Microseismic Monitoring Module offers a real-time view of seismic activity near wells with three key components.'}</p>
			<br/>
			<b style={{marginTop:'10px'}}>{langdata && langdata.MicroseismicContent2 ? langdata.MicroseismicContent2 : 'Heat Map'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.MicroseismicContent3 ? langdata.MicroseismicContent3 : 'Displays filtered seismic data from Distributed Acoustic Sensing (DAS), highlighting changes in seismic intensity and frequency.'}</li>
			</ul>
			<b style={{marginTop:'10px'}}>{langdata && langdata.MicroseismicContent4 ? langdata.MicroseismicContent4 : 'Line Graph'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.MicroseismicContent5 ? langdata.MicroseismicContent5 : 'Shows seismic wave progression over time, indicating the arrival times of primary (P) and secondary (S) waves.'}</li>
			</ul>
			<b style={{marginTop:'10px'}}>{langdata && langdata.MicroseismicContent6 ? langdata.MicroseismicContent6 : 'Fracture Location Mapping Graph'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.MicroseismicContent7 ? langdata.MicroseismicContent7 : 'Analyzes the distance from the well to fractures or seismic events, aiding in well integrity monitoring.'}</li>
			</ul>
			<p style={{marginTop:'10px'}}>{langdata && langdata.MicroseismicContent8 ? langdata.MicroseismicContent8 : 'This module enhances decision-making in subsurface management and well safety.'}</p>
		</Card>
	);

	const reportGraphContent = (
		<Card size="small" bordered="false" type="inner">
			<p>{langdata && langdata.ReportContent1 ? langdata.ReportContent1 : 'The Report Module analyzes well performance and historical data, focusing on key metrics for depth-wise and overall well behavior.'}</p>
			<br/>
			<b style={{marginTop:'10px'}}>{langdata && langdata.ReportContent2 ? langdata.ReportContent2 : 'Average Well Temperature'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.ReportContent3 ? langdata.ReportContent3 : 'Calculates average temperatures at various depths and overall, aiding in thermal analysis to identify anomalies.'}</li>
			</ul>
			<b style={{marginTop:'10px'}}>{langdata && langdata.ReportContent4 ? langdata.ReportContent4 : 'Total Event Count'}</b>
			<ul style={{margin:'10px'}}> 
				<li>{langdata && langdata.ReportContent5 ? langdata.ReportContent5 : 'Displays the number of seismic events detected per zone and cumulatively, helping to monitor seismic activity and potential subsurface issues.'}</li>
			</ul>
		</Card>
	);

	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));	


	let arr = [];

	for(let i=0; i<=350; ++i){
		arr.push(i);
	}

	const handleNaviagte = (e) => {
		if(e=='wellreports'){
			navigate(( '/report-well-visualization'),
				{ state: 
					{ 
						// data: {params:'', value:4, regionId : regionId}, 
						path: 'well-visualization' } 
				});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '116');
		}
		else if (e=='sandingress'){
			navigate(( '/sandingress-visualization'),
				{ state: 
					{ 
						// data: {params:'', value:4, regionId : regionId}, 
						path: 'well-visualization' } 
				});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '116');
			
		}
		else if (e=='leakvisualization'){
			navigate(( '/leak-visualization'),
				{ state: 
					{ 
						// data: {params:'', value:4, regionId : regionId}, 
						path: 'well-visualization' } 
				});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '116');
			
		}

		
		else if (e=='fractureMonitoring'){
			navigate(( '/fracture-monitoring-visualization'),
				{ state: 
					{ 
						// data: {params:'', value:4, regionId : regionId}, 
						path: 'well-visualization' } 
				});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '116');
			
		}

		else if (e=='flowmonitoring'){
			navigate(( '/flow-monitoring'),
				{ state: 
					{ 
						// data: {params:'', value:4, regionId : regionId}, 
						path: 'well-visualization' } 
				});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '116');
			
		}
		else if (e=='microseismic'){
			navigate(( '/microseismic-monitoring-visualization'),
				{ state: 
					{ 
						// data: {params:'', value:4, regionId : regionId}, 
						path: 'well-visualization' } 
				});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '116');
			
		}


		
		
	};

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={8}>
						<Row>
							<Space>
								<AreaChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.WellVisualization ? langdata.WellVisualization : 'Well Visualization'}</Title>
						</Row>
					</Col>
					
					<Col span={16}>
						<Row justify="end">
							<Col>
								
								<Space>&nbsp;&nbsp;</Space>
								<Popover title={langdata && langdata.WellVisualization ? langdata.WellVisualization : 'Well Visualizations'} content={infoContent} trigger="click">
									<Avatar size="small"><InfoOutlined /></Avatar>
								</Popover>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={{
					xs: 8,
					sm: 16,
					md: 24,
					lg: 32,
				}} style={{
					marginTop:'20px',
					marginLeft:'0px',
					marginRight:'0px',
					marginBottom: '20px',
				}}>
					{featuresModule.wellFlowMonitoringEnabled ==true ?
						<Space>
							<Col onClick={()=>{handleNaviagte('flowmonitoring');}}  style={{marginBottom:'20px'}} span={12}> 
								<Tooltip color='#FFFFFF' placement='bottom' overlayInnerStyle={{color: '#000000', width:'400px'}} title={flowMonitoringGraphContent}>
									<Card style={{ minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px', cursor: 'pointer'}} >
										<Card.Grid style={{minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px',}}>
											{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
											<Col style={{ display : 'flex', justifyContent : 'center' }}>
												<img shape="square" src={flowImg} alt='event image' style={{ width : '450px', height : '190px' }}/>
											</Col>
											<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center' }}>
												<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.FlowMonitoring: 'Flow Monitoring'}</Typography>
											</Col>
											{/* <Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
								<Typography style={{ fontSize : '25px'}}><b> </b> </Typography>
							</Col> */}
										</Card.Grid>
									</Card>
								</Tooltip>
							</Col>
						</Space>:null}

					{featuresModule.wellSandIngressEnabled ==true ?

						<Space>
							<Col onClick={()=>{handleNaviagte('sandingress');}}  style={{marginBottom:'20px'}} span={12}> 
								<Tooltip color='#FFFFFF' placement='bottom' overlayInnerStyle={{color: '#000000', width:'400px'}} title={sandIngressGraphContent}>
									<Card style={{ minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px', cursor: 'pointer'}} >
										<Card.Grid style={{minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px',}}>
											{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
											<Col style={{ display : 'flex', justifyContent : 'center' }}>
												<img shape="square" src={leakdetectReportImg} alt='event image' style={{ width : '450px', height : '190px' }}/>
											</Col>
											<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center' }}>
												<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.SandIngress: 'Sand Ingress'}</Typography>
											</Col>
											{/* <Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
								<Typography style={{ fontSize : '25px'}}><b> </b> </Typography>
							</Col> */}
										</Card.Grid>
									</Card>
								</Tooltip>
							</Col>
						</Space> :null}

					{featuresModule.wellLeakDetectionEnabled ==true ?

						<Space>
							<Col onClick={()=>{handleNaviagte('leakvisualization');}}  style={{marginBottom:'20px'}} span={12}> 
								<Tooltip color='#FFFFFF' placement='bottom' overlayInnerStyle={{color: '#000000', width:'400px'}} title={leakgraphContent}>
									<Card style={{ minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px', cursor: 'pointer'}} >
										<Card.Grid style={{minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px',}}>
											{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
											<Col style={{ display : 'flex', justifyContent : 'center' }}>
												<img shape="square" src={wellimg} alt='event image' style={{ width : '450px', height : '190px' }}/>
											</Col>
											<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center' }}>
												<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.LeakDetection: 'Leak Detection'}</Typography>
											</Col>
											{/* <Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
								<Typography style={{ fontSize : '25px'}}><b> </b> </Typography>
							</Col> */}
										</Card.Grid>
									</Card>
								</Tooltip>
							</Col>
						</Space>:null}

					{featuresModule.wellFracturingEnabled ==true ?

						<Space>
							<Col onClick={()=>{handleNaviagte('fractureMonitoring');}}  style={{marginBottom:'20px'}} span={12}> 
								<Tooltip color='#FFFFFF' placement='top' overlayInnerStyle={{color: '#000000', width:'400px'}} title={fractureGraphContent}>
									<Card style={{ minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px', cursor: 'pointer'}} >
										<Card.Grid style={{minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px',}}>
											{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
											<Col style={{ display : 'flex', justifyContent : 'center' }}>
												<img shape="square" src={fractureReportImg} alt='event image' style={{ width : '400px', height : '190px' }}/>
											</Col>
											<Col style={{ display : 'flex', justifyContent : 'center',textAlign : 'center' }}>
												<Typography style={{ fontWeight : 'bold'}}>{langdata && langdata ? langdata.FractureMonitoring: 'Fracture Monitoring'}</Typography>
											</Col>
											{/* <Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
								<Typography style={{ fontSize : '25px'}}><b> </b> </Typography>
							</Col> */}
										</Card.Grid>
									</Card>
								</Tooltip>
							</Col>
						</Space> : null}

					{featuresModule.wellMicroSeismicEnabled ==true ?

						<Space>
							<Col onClick={()=>{handleNaviagte('microseismic');}}  style={{marginBottom:'20px'}} span={12}> 
								<Tooltip color='#FFFFFF' placement='bottom' overlayInnerStyle={{color: '#000000', width:'400px'}} title={microseismicGraphContent}>
									<Card style={{ minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px', cursor: 'pointer'}} >
										<Card.Grid style={{minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px',}}>
											{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
											<Col style={{ display : 'flex', justifyContent : 'center' }}>
												<img shape="square" src={AmplitudeImg} alt='event image' 
													style={{ width : '400px', height : '190px' }}
												/>
											</Col>
											<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center' }}>
												<Typography style={{ fontWeight : 'bold'}}>{langdata && langdata.MicroseismicMonitoring ? langdata.MicroseismicMonitoring : 'Microseismic Monitoring'}</Typography>
											</Col>
											{/* <Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
								<Typography style={{ fontSize : '25px'}}><b> </b> </Typography>
							</Col> */}
										</Card.Grid>
									</Card>
								</Tooltip>
							</Col>
						</Space>:null}

					<Space>
						<Col onClick={()=>{handleNaviagte('wellreports');}}  style={{marginBottom:'20px'}} span={12}> 
							<Tooltip color='#FFFFFF' placement='bottom' overlayInnerStyle={{color: '#000000', width:'400px'}} title={reportGraphContent}>
								<Card style={{ minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px', cursor: 'pointer'}} >
									<Card.Grid style={{minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px',}}>
										{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
										<Col style={{ display : 'flex', justifyContent : 'center' }}>
											<img shape="square" src={reportImg} alt='event image' 
												style={{ width : '400px', height : '190px' }}
											/>
										</Col>
										<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center' }}>
											<Typography style={{ fontWeight : 'bold'}}>{langdata && langdata ? langdata.Reports: 'Reports'}</Typography>
										</Col>
										{/* <Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
								<Typography style={{ fontSize : '25px'}}><b> </b> </Typography>
							</Col> */}
									</Card.Grid>
								</Card>
							</Tooltip>
						</Col>
					</Space>

				
				</Row>
			</Content>

			

			{/* Add Vulnerable Point Details Modal Starts */}
			
			{/* Delete User Modal Ends */}
		</>
	);
}