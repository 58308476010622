export const TLPS_LIST = 'TLPS_LIST';
export const TLPS_LIST_SUCCESS = 'TLPS_LIST_SUCCESS';
export const TLPS_LIST_ERROR = 'TLPS_LIST_ERROR';

export const TLP_RULES_LIST = 'TLP_RULES_LIST';
export const TLP_RULES_LIST_SUCCESS = 'TLP_RULES_LIST_SUCCESS';
export const TLP_RULES_LIST_ERROR = 'TLP_RULES_LIST_ERROR';

export const TLP_RULES_DETAILS = 'TLP_RULES_DETAILS';
export const TLP_RULES_DETAILS_SUCCESS = 'TLP_RULES_DETAILS_SUCCESS';
export const TLP_RULES_DETAILS_ERROR = 'TLP_RULES_DETAILS_ERROR';

export const SAVE_TLP_RULE = 'SAVE_TLP_RULE';
export const SAVE_TLP_RULE_SUCCESS = 'SAVE_TLP_RULE_SUCCESS';
export const SAVE_TLP_RULE_ERROR = 'SAVE_TLP_RULE_ERROR';

export const UPDATE_TLP_RULE = 'UPDATE_TLP_RULE';
export const UPDATE_TLP_RULE_SUCCESS = 'UPDATE_TLP_RULE_SUCCESS';
export const UPDATE_TLP_RULE_ERROR = 'UPDATE_TLP_RULE_ERROR';

export const DELETE_TLP_RULE = 'DELETE_TLP_RULE';
export const DELETE_TLP_RULE_SUCCESS = 'DELETE_TLP_RULE_SUCCESS';
export const DELETE_TLP_RULE_ERROR = 'DELETE_TLP_RULE_ERROR';