import { CHANGE_PASSWORD, CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_SUCCESS, MY_PROFILE, MY_PROFILE_ERROR, MY_PROFILE_SUCCESS } from './constants';

export default function myProfileReducer(state, action) {
	switch(action.type){
	case MY_PROFILE :
		return { ...state, loading: true };
	case MY_PROFILE_SUCCESS :
		return { ...state, profile: action.payload, loading: false, error: false };
	case MY_PROFILE_ERROR :
		return { ...state, loading: false, error: true };
	case CHANGE_PASSWORD : 
		return { ...state, loading: true };
	case CHANGE_PASSWORD_SUCCESS :
		return { ...state, profile: action.payload, loading: false, error: false };
	case CHANGE_PASSWORD_ERROR :
		return { ...state, loading: false, error: true };
	default: 
		throw Error('Unknown action: ' + action.type);
	}
}