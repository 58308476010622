/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { CircleF, GoogleMap, MarkerF, PolylineF, useLoadScript } from '@react-google-maps/api';
import '../../App.css';
import { useEffect, useState} from 'react';
import { useAuth } from '../Context';
import WBicon from '../../images/wb.png';
import KMPicon from '../../images/kmp.png';
import TLPicon from '../../images/tlp.png';
import TPicon from '../../images/tp.png';
import CHicon from '../../images/ch.png';
import eventsicons from '../eventstypes';
import pids from '../../images/Refinery1.png';
import defaultimg from '../../images/default1.png';
import LWmarker from '../../images/man.png';
import point from '../../images/red-pin.png';
import StartIcon from '../../images/start.png';
import EndIcon from '../../images/stop.png';
import smartTLPicon from '../../images/tlpred.png';
import wellicon from '../../images/oil-well.png';


// import { route } from "./data";

const GMap = (props) => {
	// const [polygonPath, setPolygonPath] = useState();
	const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: GOOGLE_MAP_API_KEY,
		libraries: ['drawing']
	});

	const {onClick } = props;

	const {handleGetMapdata, mapdata, optionalpipedata, handleOptionalPipelinedata,wellinfo, getWellInfo,} = useAuth();
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const regionId = props.regionId;
	const startCh = props.startCh;
	const endCh = props.endCh;
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));
	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';


	const [stateCenter, setCenter] = useState();
	useEffect(() => {
		handleGetMapdata(authtoken,regionId,startCh,endCh);
		handleOptionalPipelinedata(authtoken,pipelineId,regionId,startCh,endCh);
		if(oneviewType=='well'){
			getWellInfo(authtoken);
		}
	},[regionId, startCh, endCh]);
  
	let center = stateCenter ? [stateCenter] : [];
	let zoom = 14;
	// let pipeline = [];
	// let pipelineBorder = [];
	// let odmeter = [];
	let markers = [];
	let KMP = [];
	let WB = [];
	let Turn = [];
	let TLP = [];
	// let optionalpipeline = [];
	let event_markers = [];
	let event_details_marker = [];
	let alarm_markers = [];
	let alarm_details_marker = [];
	let allPidsDatas = [];
	let pig_data = [];
	let linewalker_markers = [];
	let inspectionline = [];
	let startAllocation = [];
	let endAllocation = [];
	let inspectionPoints = [];
	let tlp_details_marker = [];
	let pigPidsData = [];

	let wellMarkers=[];

	const tempMapArray = {};
	const teampOptionalMapArray = {};

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});

	optionalpipedata && optionalpipedata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!teampOptionalMapArray[fieldValue]) {
			teampOptionalMapArray[fieldValue] = [];
		}
		teampOptionalMapArray[fieldValue].push(item);
	});

	mapdata &&  mapdata.map((item,index)=>{
		if(index==0){
			center.push({lat : item.latitude,lng : item.longitude});
		}

		// if (props.changePipe === true) {
		// 	pipeline.push({lat : item.latitude,lng : item.longitude});
		// } else {
		// 	pipeline = [];
		// }

		// if (props.changeFiber === true) {
		// 	odmeter.push({lat : item.fibreLatitude, lng : item.fibreLongitude});
		// } else {
		// 	odmeter = [];
		// }

		// if(props.changeBorder === true) {
		// 	pipelineBorder.push({lat : item.latitude,lng : item.longitude});
		// } else {
		// 	pipelineBorder = [];
		// }

		if(item.rouMarker=='CH' && props.changeCH === true){
			markers.push([item.latitude,item.longitude]);
		}
		else if(item.rouMarker=='WB' && props.changeWB === true){
			WB.push([item.latitude,item.longitude]);
		}

		else if(item.rouMarker=='TLP' && props.changeTLP === true){
			if(props.TLPData=='TLPData'){
				TLP.push([item.latitude,item.longitude,item.tlpId]);

			}else{
				TLP.push([item.latitude,item.longitude]);

			}
		}

		else if(item.rouMarker=='KMP' && props.changeKMP === true){
			KMP.push([item.latitude,item.longitude]);
		}

		else if(item.rouMarker=='TP' && props.changeTP === true){
			Turn.push([item.latitude,item.longitude]);
		}

	});

	wellinfo &&  wellinfo.map((item,index)=>{
		if(index==0){
			center.push({lat : item.latitude,lng : item.longitude});
		}
		if(props.changeWell === true){
			if(item && item.latitude && item && item.longitude){				
				wellMarkers.push(
					{
						location:[item.latitude,item.longitude], 
						name:item && item.name.toUpperCase(),
						id:item.id
					}
				);

			}
		}
		

	});
	

	// if (props.changeOptionalPipe===true) {
	// 	optionalpipedata && optionalpipedata.map((item)=>{
	// 		optionalpipeline.push({lat : item.walkingLatitude,lng : item.walkingLongitude});
	
	// 	});
	// } else {
	// 	optionalpipeline = [];
	// }
	
	if(props.changeEvent === true && props.eventData) {
		eventsicons && eventsicons.map((icondata)=>{
			props.eventData && props.eventData.map((item)=>{ 
				let severity = item.eventConfidence >= 0.9 &&item.eventConfidence <= 1 ? 3 : item.eventConfidence >= 0.8 && item.eventConfidence < 0.9 ? 2 : item.eventConfidence >= 0.5 && item.eventConfidence < 0.8 ? 1 : 0;
				if(item.eventType==icondata.type){
					event_markers.push([item.locationDetails.Lat,item.locationDetails.Long, icondata.severity[parseInt(severity)], item.eventType, item.id]);
				}
			});
		});
	} else {
		event_markers = [];
	}

	if(props.eventDetails) {
		center = [];
		eventsicons && eventsicons.map((icondata)=>{
			let severity = props.eventDetails.eventConfidence >= 0.9 && props.eventDetails.eventConfidence <= 1 ? 3 : props.eventDetails.eventConfidence >= 0.8 && props.eventDetails.eventConfidence < 0.9 ? 2 : props.eventDetails.eventConfidence >= 0.5 && props.eventDetails.eventConfidence < 0.8 ? 1 : 0;
			if(props.eventDetails.eventType==icondata.type){
				center.push({lat : props.eventDetails.latitude,lng : props.eventDetails.longitude});
				event_details_marker.push([props.eventDetails.latitude, props.eventDetails.longitude , icondata.severity[parseInt(severity)], props.eventDetails.eventType, props.eventDetails.id]);
			}
		});
	} else {
		event_details_marker = [];
	}

	if(props.changeAlarm === true && props.alarmData) {
		eventsicons && eventsicons.map((icondata)=>{
			props.alarmData && props.alarmData.map((item)=>{
				if(item.eventType==icondata.type){
					alarm_markers.push([item.locationDetails.Lat,item.locationDetails.Long, icondata.severity[item.alarmSeverity], item.eventType, item.newid]);
				}
			});
		});
	} else {
		alarm_markers = [];
	}

	if(props.alarmDetails) {
		center = [];
		eventsicons && eventsicons.map((icondata)=>{
			if(props.alarmDetails.eventType==icondata.type){
				center.push({lat : props.alarmDetails.locationDetails.Lat,lng : props.alarmDetails.locationDetails.Long});
				alarm_details_marker.push([props.alarmDetails.locationDetails.Lat, props.alarmDetails.locationDetails.Long, icondata.severity[props.alarmDetails.alarmSeverity], props.alarmDetails.eventType ]);
			}
		});
	}
	if(allPidsData.length>0 && props.pidsRegionData && props.pidsRegionData.length==0 || props.pidsRegionData==undefined ) {
		allPidsData && allPidsData.map((item)=>{
			allPidsDatas.push([parseFloat(item.latitude), parseFloat(item.longitude), item.value, item.label]);
		});
	} 
	else if(props.pidsRegionData.length > 0) {
		props.pidsRegionData && props.pidsRegionData.map((item)=>{
			center=[];
			allPidsDatas.push([parseFloat(item.latitude), parseFloat(item.longitude), item.value, item.label]);
			center.push({lat : parseFloat(item.latitude),lng : parseFloat(item.longitude)});
			zoom = 17;
		});
	} 
	else {
		allPidsDatas = [];
	}

	if(props.pigData) {
		props.pigData && props.pigData.map((item)=>{
			pig_data.push([parseFloat(item.Latitude), parseFloat(item.Longitude), item.id]);
		});
		
	} else {
		pig_data = [];
	}

	if(props.linewalkerData && oneviewType != 'well') {
		props.linewalkerData && props.linewalkerData.map((item)=>{
			linewalker_markers.push([parseFloat(item.currentLocation.latitude), parseFloat(item.currentLocation.longitude), item.userId, item.allocationId, item.user.cellPhone, item.user.firstName + item.user.lastName, item.status]);
		});
		
	} else {
		linewalker_markers = [];
	}


	if(props.patch_data && oneviewType != 'well'){
		props.patch_data && props.patch_data.map((item)=>{
			if (props.changeinspectionPoint===true) {
				startAllocation.push([parseFloat(item.startLocation.latitude), parseFloat(item.startLocation.longitude)]);
				endAllocation.push([parseFloat(item.endLocation.latitude), parseFloat(item.endLocation.longitude)]);
			} else {
				startAllocation = [];
				endAllocation = [];
			}
			item.liveLocation.map((inneritem)=>{
				if (props.changeinspectionLine===true) {
					center=[];
					inspectionline.push({lat : parseFloat(inneritem.latitude),lng : parseFloat(inneritem.longitude)});
					center.push({lat : parseFloat(inneritem.latitude),lng : parseFloat(inneritem.longitude)});
					zoom = 19;
				} else {
					inspectionline = [];
				}
				if (props.changeinspectionPoint===true) {
					center=[];
					inspectionPoints.push([parseFloat(inneritem.latitude),parseFloat(inneritem.longitude)]);
					center.push({lat : parseFloat(inneritem.latitude),lng : parseFloat(inneritem.longitude)});
					zoom = 19;
				} else {
					inspectionPoints = [];
				}
			});

		});
	}

	if(props.vulnerable_data && oneviewType != 'well') {
		props.vulnerable_data && props.vulnerable_data.map((item)=>{
			if (props.changeinspectionPoint===true) {
				startAllocation.push([parseFloat(item.startLocation.latitude), parseFloat(item.startLocation.longitude)]);
				endAllocation.push([parseFloat(item.endLocation.latitude), parseFloat(item.endLocation.longitude)]);
			} else {
				startAllocation = [];
				endAllocation = [];
			}
			item.liveLocation.map((inneritem)=>{
				if (props.changeinspectionLine===true) {
					center = [];
					inspectionline.push({lat : parseFloat(inneritem.latitude),lng : parseFloat(inneritem.longitude)});
					center.push({lat : parseFloat(inneritem.latitude),lng : parseFloat(inneritem.longitude)});
					zoom = 19;
				} else {
					inspectionline = [];
				}
				if (props.changeinspectionPoint===true) {
					center = [];
					inspectionPoints.push([parseFloat(inneritem.latitude),parseFloat(inneritem.longitude)]);
					center.push({lat : parseFloat(inneritem.latitude),lng : parseFloat(inneritem.longitude)});
					zoom = 19;
				} else {
					inspectionPoints = [];
				}
			});

		});
	}

	if(props.inspectionData && oneviewType != 'well') {
		props.inspectionData && props.inspectionData.map((item)=>{
			if (props.changeinspectionPoint===true) {
				startAllocation.push([parseFloat(item.startLocation.latitude), parseFloat(item.startLocation.longitude)]);
				endAllocation.push([parseFloat(item.endLocation.latitude), parseFloat(item.endLocation.longitude)]);
			} else {
				startAllocation = [];
				endAllocation = [];
			}
			item.liveLocation.map((inneritem)=>{
				if (props.changeinspectionLine===true) {
					center = [];
					inspectionline.push({lat : parseFloat(inneritem.latitude),lng : parseFloat(inneritem.longitude)});
					center.push({lat : parseFloat(inneritem.latitude),lng : parseFloat(inneritem.longitude)});
					zoom = 19;
				} else {
					inspectionline = [];
				}
				if (props.changeinspectionPoint===true) {
					center = [];
					inspectionPoints.push([parseFloat(inneritem.latitude),parseFloat(inneritem.longitude)]);
					center.push({lat : parseFloat(inneritem.latitude),lng : parseFloat(inneritem.longitude)});
					zoom = 19;
				} else {
					inspectionPoints = [];
				}
			});

		});
	}
	
	if(props.TLPDetails && oneviewType != 'well') {
		center=[];
		tlp_details_marker.push([props.TLPDetails.latitude,props.TLPDetails.longitude]);
		center.push({lat : parseFloat(props.TLPDetails.latitude),lng : parseFloat(props.TLPDetails.longitude)});
		zoom = 17;
	} else {
		tlp_details_marker = [];
	}

	if(props.pigPids && props.pigPids.length>0 && oneviewType != 'well'){
		props.pigPids.map((item)=>{
			pigPidsData.push(item);
		});
	}

	const handleOnclick = (value, type) => {
		if (type === 'event' || type === 'alarm') {
			onClick(value[4]);
		} else if(type === 'pig') {
			onClick(value[0], value[1], value[2]);
		} else if (type === 'lineWalker') {
			onClick(value[2], value[3], value[4], value[5], value[6]);
		}
		else if(type=='well'){
			
			onClick(value.id,'well',value.name);
		}
		setCenter({lat : value[0],lng : value[1]});
	};

	return (
		<div style={{ width: '100%', height: props.height ? props.height : '100vh' }}>
			{!isLoaded ? (
				<h1></h1>
			) : (
				<GoogleMap mapContainerClassName="map-container" center={center[0]} zoom={zoom}>
					
					{tempMapArray && Object.values(tempMapArray).map((item) => {
						let pipeline = [];
						let odmeter = [];
						let pipelineBorder = [];
						if(props.changePipe === true && oneviewType != 'well') {
							item.map((data) => {
								pipeline.push({lat : data.latitude,lng : data.longitude});
							});
						} else {
							pipeline = [];
						}
						if(props.changeFiber === true && oneviewType != 'well') {
							item.map((data) => {
								odmeter.push({lat : data.fibreLatitude, lng : data.fibreLongitude});
							});
						} else {
							odmeter = [];
						}
						if(props.changeBorder === true && oneviewType != 'well') {
							item.map((data) => {
								pipelineBorder.push({lat : data.latitude,lng : data.longitude});
							});
						} else {
							pipelineBorder = [];
						}
						return (
							<>
								<PolylineF path={pipeline} options={{ strokeOpacity: 0.8, strokeColor: '#000000', strokeWeight: 5}}/>
								<PolylineF path={odmeter} options={{ strokeOpacity: 0.8, strokeColor: '#036CD6', strokeWeight: 5}}/>
								<PolylineF path={pipelineBorder} options={{ strokeOpacity: 0.32, strokeColor: '#fc8383', strokeWeight: 25 }}/>
							</>
						);

					})}

					{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
						let optionalpipeline = [];
						if(props.changeOptionalPipe===true) {
							item.map((data) => {
								optionalpipeline.push({lat : data.walkingLatitude,lng : data.walkingLongitude});
							});
						} else {
							optionalpipeline = [];
						}
						return (
							<>
								<PolylineF path={optionalpipeline} options={{ strokeOpacity: 0.8, strokeColor: 'orange', strokeWeight: 5}}/>
							</>
						);
					})}

					<PolylineF path={inspectionline} options={{ strokeOpacity: 0.8, strokeColor: 'green', strokeWeight: 5}}/>

					{markers.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {CHicon}/>
					))}

					{WB.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {WBicon} />
					))}

					{TLP.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {item[2] ? smartTLPicon : TLPicon}/>
					))}

					{KMP.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {KMPicon}/>
					))}

					{Turn.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {TPicon}/>
					))}

					{event_markers.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {item[2]} title = {item[3]} onClick={() => {handleOnclick(item,'event');}} />
					))}

					{event_details_marker.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }}  icon = {item[2]} title = {item[3]}/>
					))}

					{alarm_markers.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {item[2]} title = {item[3]} onClick={() => {handleOnclick(item,'alarm');}} />
					))}

					{alarm_details_marker.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {item[2]} title = {item[3]} />
					))}

					{allPidsDatas.map((item, index) => (
						<>
							<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }}  icon = {pids} title = {item[3]} />
							{pigPidsData.map((inneritem)=>{
								if(inneritem==item[2]){
									return(
										<CircleF
											key={index}
											center={{
												lat: parseFloat(item[0]),
												lng: parseFloat(item[1])
											}}
											radius={300}
											options={{
												strokeColor: 'red'
											}}
										/>
									);
								}
							})}
						</>
					))}

					{pig_data.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {defaultimg} onClick={() => {handleOnclick(item, 'pig');}} />
					))}

					{linewalker_markers.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {LWmarker} onClick={() => {handleOnclick(item, 'lineWalker');}} />
					))}


					{startAllocation.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {StartIcon} title='START' />
					))}


					{endAllocation.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {EndIcon} title='END' />
					))}


					{inspectionPoints.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {point}  />
					))}
					
					{tlp_details_marker.map((item, index) => (
						<MarkerF key = {index} position={{ lat : item[0], lng : item[1] }} icon = {smartTLPicon}/>
					))}

					{wellMarkers && wellMarkers.map((item, index) => (						
						<MarkerF key = {index} position={{ lat : item && item.location[0], lng : item && item.location[1] }} title={item.name} onClick={() => {handleOnclick(item, 'well');}}
							icon={{
								url: wellicon, 
								scaledSize: new window.google.maps.Size(40, 40) 
							}}
						/>
					))}

					{/* <DrawingManager /> */}
				</GoogleMap>
			)}
		</div>
	);
};

export default GMap;