/* eslint-disable react/react-in-jsx-scope */

import { Avatar, Breadcrumb, Card, Col, DatePicker, Divider, Empty, Popover, Row, Select, Space, Spin, Tooltip, Typography, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Context';
// import Chart from 'react-apexcharts';
import axios from 'axios';
import moment from 'moment';
// import { Axis, Chart, Geom, Tooltip } from 'bizcharts';
// import { Axis, Chart, Geom, Tooltip } from 'bizcharts';
import dashboarddatareducer from './reducer';
import { PIDS_GRAPH, PIDS_GRAPH_ERROR, PIDS_GRAPH_SUCCESS } from './constants';
import useResizeObserver from 'use-resize-observer';
import Chart from 'react-apexcharts';
import { HomeOutlined, InfoOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import './style.css';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;



export default function AvailabilityDetailsPage()  {
    
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);


	const {getPids, pidsData } = useAuth();
	const navigate = useNavigate();
	const { state } = useLocation();

	const [pidsId, setPidsId] = useState(state && state.pidsId);
	const [fiberCount, setFiberCount] = useState();
	const [dailyLoss, setDailyLoss] = useState();
	const [hourlyLoss, sethourlyLoss] = useState();

	const [date, setDate] = useState(state && state.date);
	const [hour, setHour] = useState( state && state.hour);

	const initalState = { pending: false, error: false };
	const [data, dispatch] = useReducer(dashboarddatareducer, initalState);

	const { ref, width } = useResizeObserver();

	let minChartWidth = parseInt(100/70).toFixed(2);
	minChartWidth = (minChartWidth < 1) ? 1 : minChartWidth;

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const pidsList = [];
	const hours = [];
	const minutesArr = [];
	const secondsArr = [];
	// const newDate = new Date();
	const currentHour = state && state.hour;
	// let series = Array(60).fill({});
	let endtime = currentHour;

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	if (parseInt(localStorage.getItem('AVAILABILITY_CHART_CURRENT_HOUR')) == 0) {
		localStorage.setItem('AVAILABILITY_CHART_CURRENT_HOUR', state.hour);
	}
    
	if (new Date().getDate() !== dayjs(date, 'DD-MM-YYYY').$D) {
		endtime = 23 ;
	}

	for (let m = 0; m < 60; m++) {
		minutesArr.push(m);
		secondsArr.push(m);
	}

	for (let h = 0; h <= endtime; h++) {
		hours.push({
			value : h,
			label : `${h}:00 - ${h}:59`
		});
	}

	const generateData = (source)=> {
		let series = [];
		data.pending = true;

		for( let a = 0; a < 60; a++ ) {
			let data = [];

			for ( let b = 0; b < 60; b++) {
				data.push({
					x : b,
					y : 0,
				});
			}

			series.push({minute: a, data: data});
		}
		
		source.map((sourceItem, index) => {
			const minuteEntry = series.find((item) => item.minute === sourceItem.minute);
	
			if(minuteEntry) {
				if (sourceItem.value >= 0) {
					minuteEntry.data[sourceItem.second].y = sourceItem.value;
				}
			}

			if(source.length-1 == index){
				data.pending = false;
			}
		});
	
		return series;
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getPids(authtoken);
	},[authtoken]);

	useEffect(() => {
		if (date && pidsId) {
			dispatch({ type : PIDS_GRAPH });
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/fibreshotmonitoring?pidsinfoId=${pidsId}&date=${date.split('/').reverse().join('-')}`,
				timeout: extendTimeout,
				headers: { 
					Authorization:`Bearer ${authtoken}`
				}
			};

			axios.request(config)
				.then((response) => {
					if(response.data.data.length > 0) {
						response.data.data.map((item) => {
							setFiberCount(item.fiberCount);
							setDailyLoss(parseFloat(item.daily));
							sethourlyLoss(item.hourly);
							dispatch({ type : PIDS_GRAPH_SUCCESS });
						});
					} else {
						setFiberCount(null);
						setDailyLoss(null);
						sethourlyLoss(null);
						dispatch ({ type : PIDS_GRAPH_SUCCESS });
					}
				})
				.catch((error) => {
					console.log(error);dispatch ({ type : PIDS_GRAPH_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});
		}

	},[pidsId, date]);

	pidsData && pidsData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name.toUpperCase()
		});
	});

	const handlePids = (value) => {
		setPidsId(value);
		data.pending = true;
	};

	const handleSetDate = (e) =>{
		data.pending = true;
		setDate(new Date(e && e.$d).toLocaleDateString('en-UK', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric'
		}));
   
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	let valuesArray = [];

	fiberCount && fiberCount ? valuesArray = Object.entries(fiberCount) : [];
	
	const sortedArray1 = valuesArray.sort((a, b) => new Date(b.date) - new Date(a.date));
	const sortedArray = sortedArray1.filter((srt) => Number(moment(srt[0], 'YYYY-MM-DD HH:mm:ss').format('HH')) == hour);

	let source = sortedArray.map((arr) => {
		const valDate = arr && arr[0];

		return {
			datetime: valDate,
			minute: Number(moment(valDate).format('mm')),
			second: Number(moment(valDate).format('ss')),
			value: arr && arr[1],
				
		};
	});


	const handleSetHour = (value) => {
		console.log('# Hour changed to : ', value);
		
		setHour(value);
		localStorage.setItem('AVAILABILITY_CHART_CURRENT_HOUR', value);

		series = [];
		source = [];
	};

	let series = [];

	if (source && source.length > 0) {
		series = generateData(source);
	} 

	// console.log('series', series);

	
	var options = {
		chart: {
			height: 350,
			type: 'heatmap',
			zoom: {
				enabled: false,
			},
			toolbar: {
				show: false, 
			},
		},
		tooltip: {
			enabled: true,
			custom: function({ seriesIndex, dataPointIndex, series }) {
				let hourPart = localStorage.getItem('AVAILABILITY_CHART_CURRENT_HOUR');

				return '<div style="padding:5px">' +
				`<span> \t ${langdata && langdata.DateandTime ? langdata.DateandTime : 'Date and Time'}: ` + date + ' '+ hourPart + ':' + seriesIndex + ':' + dataPointIndex + '</span> <br>' +
				`<span> \t ${langdata && langdata.Datareceived ? langdata.Datareceived : 'Data received'}:` + series[seriesIndex][dataPointIndex] + '</span>' +
				'</div>';
			}
		},
		dataLabels: {
			enabled: true,
			color: '#000000',
			foreColor: '#000000'
		},
		
		plotOptions: {
			heatmap: {
				shadeIntensity: 1,
				colorScale: {
					ranges: [
						{
							from: 0,
							to: 0,
							name: `${langdata && langdata.DATALOSSOBSERVED ? langdata.DATALOSSOBSERVED : 'DATA LOSS OBSERVED'}`,
							color: '#FF0000',
							foreColor: '#ffffff'
						},
						{
							from: 1,
							to: 11,
							name: `${langdata && langdata.DATARECEIVED ? langdata.DATARECEIVED : 'DATA RECEIVED'}`,
							color: '#05fc11',
							foreColor: '#000000'
						},
					]
				}
			}
		},
		legend: {
			show: true,
			position: 'top',
			horizontalAlign: 'center',
			onItemClick: {
				toggleDataSeries: true
			},
			onItemHover: {
				highlightDataSeries: true
			}
		},
		yaxis: {
			title: {
				text: `${langdata && langdata.MINUTES ? langdata.MINUTES :'MINUTES'}`,
			},
			labels: {
				show: true,
				formatter: function (minutesArr, index) {
					if(index !== 60) {
						return index === 0 ? '0' : index;
					}
				},
			},
		},
		xaxis: {
			title: {
				text: `${langdata && langdata.SECONDS ? langdata.SECONDS :'SECONDS'}`,
			},
		},
		stroke: {
			colors: ['#c3ddeb'],
		},
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p><Avatar shape="square" size={14} style={{ backgroundColor: '#FF0000'}} /> <b>{langdata && langdata.DATALOSSOBSERVED ? langdata.DATALOSSOBSERVED:'DATA LOSS OBSERVED'}</b></p>
			<br />
			<p>{langdata && langdata.pidshealthcontent1 ? langdata.pidshealthcontent1: 'Data loss is an error condition in the OneView system in which information is not received. A value of "0" denotes a data loss and the system will mark those cells in RED color.'}</p>
			<Divider />
			<p><Avatar shape="square" size={14} style={{ backgroundColor: '#05fc11'}} /> <b>{langdata && langdata.DATARECEIVED ? langdata.DATARECEIVED:'DATA RECEIVED'}</b></p>
			<br />
			<p>{langdata && langdata.pidshealthcontent2 ? langdata.pidshealthcontent2 : 'A non-zero value indicates successful data reception, and the system will highlight those cells in varying shades of green. The more data packets received, the deeper the green shade. A minimum number of data samples, as defined in the configuration module, is required to mark a reception as successful.'}</p>
		</Card>
	);
	
	return (
		<>
			<Content>
				<Row 
					style={{
						marginLeft:'10px',
						marginTop: '5px'
					}}            
				>
					<Breadcrumb
						items={[
							{
								title: <HomeOutlined onClick={()=>{navigate(-1);}} />,
							},
							{
								title: `${langdata && langdata.PIDSHealthMonitor ? langdata.PIDSHealthMonitor:'PIDS Health Monitor'}`,
							},
						]}
					/>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={5}>
						{/* <Title level={3} style={{ lineHeight: '3'}}>PIDS Health Monitor</Title> */}
						<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.PIDSHealthMonitor ? langdata.PIDSHealthMonitor:'PIDS Health Monitor'}</Title>
					</Col>
					<Col span={19}>
						<Row justify="end">
							<Col>
								<Space>
									{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Pids:</Text> */}
									<Tooltip placement="bottom" title={langdata && langdata.Filterbypids ? langdata.Filterbypids:'Filter by pids'}>
										<Select
											style={{ width: '200px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS:'Select PIDS'}
											optionFilterProp="children"
											value={pidsId}
											options={pidsList}
											onSelect={handlePids}
										/>
									</Tooltip>
									{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Hours:</Text> */}
									<Tooltip placement="bottom" title={langdata && langdata.Filterbyhour ? langdata.Filterbyhour:'Filter by hour'}>
										<Select
											style={{ width: '200px'}}
											showSearch
											filterOption={filterOption}
											placeholder="Select Hour"
											optionFilterProp="children"
											value={hour}
											options={hours}
											onSelect={handleSetHour}
										/>
									</Tooltip>
									{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Date:</Text> */}
									<Tooltip placement="bottom" title={langdata && langdata.Filterbydate ? langdata.Filterbydate:'Filter by date'}>
										<DatePicker allowClear={false} onChange={handleSetDate} defaultValue={dayjs(date, 'DD-MM-YYYY')}  style={{ width: '150px'}} format={settingsdateformat} disabledDate={disabledDate} />
									</Tooltip>
									<Typography>
										<Popover title="" content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>								
							</Col>
						</Row>
					</Col>
				</Row>
				<Row >
					<Col span={24}>
						<div ref={ref}>
							<Card style={{overflow: 'hidden'}}>
								{hourlyLoss && dailyLoss ?
									<Typography style={{ textAlign: 'center' }}>
										<b>{langdata && langdata.Selectedhouraverage ? langdata.Selectedhouraverage: 'Selected hour average'}</b> : {hourlyLoss && hourlyLoss[hour] ? hourlyLoss[hour].toFixed(2) : 0} | &nbsp;
										<b>{langdata && langdata.Totaldayaverage ? langdata.Totaldayaverage: 'Total day average'}</b> : {dailyLoss ? dailyLoss.toFixed(2) : 0}
									</Typography>
									: null }
								{source && source.length > 0 ? (<div style={{ width: '100%' }}>
									<Chart height={1000} width={(width - 50) * minChartWidth} options={options} series={series} type="heatmap" />
								</div>) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
							</Card>
						</div>
					</Col>
				</Row>
			</Content>
			<Spin fullscreen spinning={data.pending} tip={langdata && langdata.largeloadingmsg ? langdata.largeloadingmsg: 'This process will take 2-3 minutes to complete. Please wait a moment.'}/>
		</>
	);
}