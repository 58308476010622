import React, { useEffect, useReducer, useState } from 'react';

import { useAuth } from '../../Context';
import { Content } from 'antd/es/layout/layout';
import { Button, Col, Divider, Empty, Form, Input, message, Modal, Row, Select, Space, Spin, Switch, Table, Typography } from 'antd';
import { ToolOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import axios from 'axios';
import wellInfoReducer from './reducer';
import { WELL_INFO, WELL_INFO_ERROR, WELL_INFO_SUCCESS, WELL_INFO_UPDATE, WELL_INFO_UPDATE_DATA, WELL_INFO_UPDATE_ERROR, WELL_INFO_UPDATE_SUCCESS } from './constants';
import { useFormik } from 'formik';

const { Title } = Typography;

export default function WellInfo() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = {wellInfo : [], pending: false, error: false, updateData : {}};
	const [data, dispatch] = useReducer(wellInfoReducer, initalState);
	const [dasModalOpen, setDASModalOpen] = useState(false);
	const [dtsModalOpen, setDTSModalOpen] = useState(false);
	const [pressureModalOpen, setPressureModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [updateId, setUpdateId] = useState();
	const {wellTypeinfo, getWellTypeInfo} = useAuth();
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	
	useEffect(() => {
		loadWellInfo();
		getWellTypeInfo(authtoken);
	},[]);

	const wellTypes = [];
	wellTypeinfo && wellTypeinfo.map((item) => {
		wellTypes.push({
			value : item.id,
			label : item.name
		});
	});	

	const loadWellInfo = () => {
		dispatch({ type : WELL_INFO });
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellinfo?$sort[id]=1`,
			timeout: timeout,
			headers: { 
				Authorization:`Bearer ${authtoken}`
			}
		};
		
		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					dispatch({ type : WELL_INFO_SUCCESS, payload : response.data });
				} 
				else {
					dispatch({ type : WELL_INFO_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : WELL_INFO_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const columns = [
		{
			title: langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: langdata && langdata.WellType ? langdata.WellType : 'Well Type',
			dataIndex: 'wellTypeName',
			key: 'wellTypeName',
		},
		{
			title: langdata && langdata.Length ? langdata.Length : 'Length',
			dataIndex: 'length',
			key: 'length',
		},
		{
			title: langdata && langdata.Width ? langdata.Width : 'Width',
			dataIndex: 'width',
			key: 'width',
		},
		{
			title: langdata && langdata.StartDepth ? langdata.StartDepth : 'Start Depth',
			dataIndex: 'startDepth',
			key: 'startDepth',
		},
		{
			title: langdata && langdata.EndDepth ? langdata.EndDepth : 'End Depth',
			dataIndex: 'endDepth',
			key: 'endDepth',
		},
		{
			title: langdata && langdata.System ? langdata.System : 'System',
			key: 'system',
			render: (_, record) => (
				<Space size="middle">
					<Space style={{justifyContent:'',display:'flex'}} size="middle">
						<>
							<Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {showDASModal(record);}}>{langdata && langdata.DAS ? langdata.DAS:'DAS'}</Button>
						| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {showDTSModal(record);}}>{langdata && langdata.DTS ? langdata.DTS:'DTS'}</Button>
						| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {showPressureModal(record);}}>{langdata && langdata.Pressure ? langdata.Pressure:'Pressure'}</Button>
						</>
					</Space>
				</Space>
			)
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (_, record) => (
				permission==='superadmin' || permission === 'admin' ?
					<Space size="middle">
						{ <a title={langdata && langdata.EditWellInfo ? langdata.EditWellInfo : 'Edit Well Info'} onClick={() => {showEditModal(record);}} ><EditOutlined/></a> }
					</Space>
					:
					<Space size="middle">
						{ <a title={langdata && langdata.ViewWellInfo ? langdata.ViewWellInfo : 'View Well Info'} onClick={() => {showEditModal(record);}} ><EyeOutlined/></a> }
					</Space>
			)
		}
	];
	
	const showDASModal = (value) => {
		setUpdateId(value.id);
		setDASModalOpen(true);
		dispatch({ type : WELL_INFO_UPDATE_DATA , payload : value});
	};
	const showDTSModal = (value) => {
		setUpdateId(value.id);
		setDTSModalOpen(true);
		dispatch({ type : WELL_INFO_UPDATE_DATA , payload : value});
	};
	const showPressureModal = (value) => {
		setUpdateId(value.id);
		setPressureModalOpen(true);
		dispatch({ type : WELL_INFO_UPDATE_DATA , payload : value});
	};
	const showEditModal = (value) => {
		setUpdateId(value.id);
		setEditModalOpen(true);
		dispatch({ type : WELL_INFO_UPDATE_DATA , payload : value});
	};
	const handleOk = () => {
		setDASModalOpen(false);
		setDTSModalOpen(false);
		setPressureModalOpen(false);
		setEditModalOpen(false);
	};
	const handleCancel = () => {
		setDASModalOpen(false);
		setDTSModalOpen(false);
		setPressureModalOpen(false);
		setEditModalOpen(false);
	};
		

	const dasFormik = useFormik({
		enableReinitialize: true,
		initialValues : {
			dasIp : data.updateData.dasip,
			dasPort : data.updateData.dasport,
			minAmpThreshold : data.updateData.minAmpThreshold,
			maxAmpThreshold : data.updateData.maxAmpThreshold,
			dasEnabled : data.updateData.dasEnabled
		},
		onSubmit: (values) => {
			const {dasIp, dasPort, minAmpThreshold, maxAmpThreshold, dasEnabled } = values;
			
			if(dasIp == '' || isNaN(dasPort) || isNaN(minAmpThreshold) || isNaN(maxAmpThreshold)){
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}
			
			dispatch({ type : WELL_INFO_UPDATE });
			let data = JSON.stringify({
				'dasip': dasIp,
				'dasport': parseInt(dasPort),
				'minAmpThreshold': parseInt(minAmpThreshold),
				'maxAmpThreshold' : parseInt(maxAmpThreshold),
				'dasEnabled' : dasEnabled
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/wellinfo/${updateId}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201){
						dispatch({ type : WELL_INFO_UPDATE_SUCCESS });
						Modal.success({ title: langdata && langdata.Wellinfoupdatedsuccessfully ? langdata.Wellinfoupdatedsuccessfully : 'Well info updated successfully.' });
						loadWellInfo();
					} else {
						Modal.warning({ title: langdata && langdata.Wellinfonotupdated ? langdata.Wellinfonotupdated : 'Well info not updated' });					
						dispatch({ type : WELL_INFO_UPDATE_ERROR });
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : WELL_INFO_UPDATE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Wellinfonotupdated ? langdata.Wellinfonotupdated : 'Well info not updated' });					
					}
				});

			setDASModalOpen(false);
		}
	});	

	const dtsFormik = useFormik({
		enableReinitialize: true,
		initialValues : {
			dtsIp : data.updateData.dtsip,
			dtsPort : data.updateData.dtsport,
			minTempThreshold : data.updateData.minTempThreshold,
			maxTempThreshold : data.updateData.maxTempThreshold,
			dtsEnabled : data.updateData.dtsEnabled
		},
		onSubmit: (values) => {
			const {dtsIp, dtsPort, minTempThreshold, maxTempThreshold, dtsEnabled } = values;
			
			if(dtsIp == '' || isNaN(dtsPort) || isNaN(minTempThreshold) || isNaN(maxTempThreshold)){
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}
			
			dispatch({ type : WELL_INFO_UPDATE });
			let data = JSON.stringify({
				'dtsip': dtsIp,
				'dtsport': parseInt(dtsPort),
				'minTempThreshold': parseInt(minTempThreshold),
				'maxTempThreshold' : parseInt(maxTempThreshold),
				'dtsEnabled' : dtsEnabled
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/wellinfo/${updateId}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201){
						dispatch({ type : WELL_INFO_UPDATE_SUCCESS });
						Modal.success({ title: langdata && langdata.Wellinfoupdatedsuccessfully ? langdata.Wellinfoupdatedsuccessfully : 'Well info updated successfully.' });
						loadWellInfo();
					} else {
						Modal.warning({ title: langdata && langdata.Wellinfonotupdated ? langdata.Wellinfonotupdated : 'Well info not updated' });					
						dispatch({ type : WELL_INFO_UPDATE_ERROR });
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : WELL_INFO_UPDATE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Wellinfonotupdated ? langdata.Wellinfonotupdated : 'Well info not updated' });					
					}
				});

			setDTSModalOpen(false);
		}
	});	

	const pressureFormik = useFormik({
		enableReinitialize: true,
		initialValues : {
			pressureip : data.updateData.pressureip,
			pressureport : data.updateData.pressureport,
			minPressureThreshold : data.updateData.minPressureThreshold,
			maxPressureThreshold : data.updateData.maxPressureThreshold,
			pressureEnabled : data.updateData.pressureEnabled
		},
		onSubmit: (values) => {
			const {pressureip, pressureport, minPressureThreshold, maxPressureThreshold, pressureEnabled } = values;
			
			if(pressureip == '' || isNaN(pressureport) || isNaN(minPressureThreshold) || isNaN(maxPressureThreshold)){
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}
			
			dispatch({ type : WELL_INFO_UPDATE });
			let data = JSON.stringify({
				'pressureip': pressureip,
				'pressureport': parseInt(pressureport),
				'minPressureThreshold': parseInt(minPressureThreshold),
				'maxPressureThreshold' : parseInt(maxPressureThreshold),
				'pressureEnabled' : pressureEnabled
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/wellinfo/${updateId}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201){
						dispatch({ type : WELL_INFO_UPDATE_SUCCESS });
						Modal.success({ title: langdata && langdata.Wellinfoupdatedsuccessfully ? langdata.Wellinfoupdatedsuccessfully : 'Well info updated successfully.' });
						loadWellInfo();
					} else {
						Modal.warning({ title: langdata && langdata.Wellinfonotupdated ? langdata.Wellinfonotupdated : 'Well info not updated' });					
						dispatch({ type : WELL_INFO_UPDATE_ERROR });
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : WELL_INFO_UPDATE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Wellinfonotupdated ? langdata.Wellinfonotupdated : 'Well info not updated' });					
					}
				});

			setPressureModalOpen(false);
		}
	});		

	const editFormik = useFormik({
		enableReinitialize: true,
		initialValues : {
			name : data.updateData.name,
			wellTypeId : data.updateData.wellTypeId,
			length : data.updateData.length,
			width : data.updateData.width,
			startDepth : data.updateData.startDepth,
			endDepth : data.updateData.endDepth,
			dasIp : data.updateData.dasip,
			dasPort : data.updateData.dasport,
			minAmpThreshold : data.updateData.minAmpThreshold,
			maxAmpThreshold : data.updateData.maxAmpThreshold,
			dasEnabled : data.updateData.dasEnabled,
			dtsIp : data.updateData.dtsip,
			dtsPort : data.updateData.dtsport,
			minTempThreshold : data.updateData.minTempThreshold,
			maxTempThreshold : data.updateData.maxTempThreshold,
			dtsEnabled : data.updateData.dtsEnabled,
			pressureip : data.updateData.pressureip,
			pressureport : data.updateData.pressureport,
			minPressureThreshold : data.updateData.minPressureThreshold,
			maxPressureThreshold : data.updateData.maxPressureThreshold,
			pressureEnabled : data.updateData.pressureEnabled,
			latitude : data.updateData.latitude,
			longitude : data.updateData.longitude,
			startUpperZoneDepth:data.updateData.startUpperZoneDepth,
			endUpperZoneDepth : data.updateData.endUpperZoneDepth,
			startInterZoneDepth:data.updateData.startInterZoneDepth,
			endInterZoneDepth : data.updateData.endInterZoneDepth,
			startReservoirZoneDepth:data.updateData.startReservoirZoneDepth,
			endReservoirZoneDepth : data.updateData.endReservoirZoneDepth,

		},
		onSubmit: (values) => {
			const {name, wellTypeId, length, width, startDepth, endDepth ,
				dasIp, dasPort, minAmpThreshold, maxAmpThreshold, dasEnabled,
				dtsIp, dtsPort, minTempThreshold, maxTempThreshold, dtsEnabled,
				pressureip, pressureport, minPressureThreshold, maxPressureThreshold, pressureEnabled, latitude, longitude, startUpperZoneDepth,endUpperZoneDepth,
				startInterZoneDepth,endInterZoneDepth, startReservoirZoneDepth, endReservoirZoneDepth } = values;
			
			if(name == '' || wellTypeId == '' || isNaN(length) || isNaN(width) || isNaN(startDepth) || isNaN(endDepth) ||
				dasIp == '' || isNaN(dasPort) || isNaN(minAmpThreshold) || isNaN(maxAmpThreshold) ||
				dtsIp == '' || isNaN(dtsPort) || isNaN(minTempThreshold) || isNaN(maxTempThreshold) ||
				pressureip == '' || isNaN(pressureport) || isNaN(minPressureThreshold) || isNaN(maxPressureThreshold) || isNaN(latitude) || isNaN(longitude) ||
				isNaN(startUpperZoneDepth) || isNaN(endUpperZoneDepth) || isNaN(startInterZoneDepth) || isNaN(endInterZoneDepth) || isNaN(startReservoirZoneDepth) || isNaN(endReservoirZoneDepth)
			) {
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}
			
			dispatch({ type : WELL_INFO_UPDATE });
			let data = JSON.stringify({
				'name': name,
				'wellTypeId': parseInt(wellTypeId),
				'length': parseInt(length),
				'width' : parseInt(width),
				'startDepth': parseInt(startDepth),
				'endDepth' : parseInt(endDepth),
				'dasip': dasIp,
				'dasport': parseInt(dasPort),
				'minAmpThreshold': parseInt(minAmpThreshold),
				'maxAmpThreshold' : parseInt(maxAmpThreshold),
				'dasEnabled' : dasEnabled,
				'dtsip': dtsIp,
				'dtsport': parseInt(dtsPort),
				'minTempThreshold': parseInt(minTempThreshold),
				'maxTempThreshold' : parseInt(maxTempThreshold),
				'dtsEnabled' : dtsEnabled,
				'pressureip': pressureip,
				'pressureport': parseInt(pressureport),
				'minPressureThreshold': parseInt(minPressureThreshold),
				'maxPressureThreshold' : parseInt(maxPressureThreshold),
				'pressureEnabled' : pressureEnabled,
				'latitude' : latitude,
				'longitude' : longitude,
				'startUpperZoneDepth': startUpperZoneDepth,
				'endUpperZoneDepth' : endUpperZoneDepth,
				'startInterZoneDepth': startInterZoneDepth,
				'endInterZoneDepth' : endInterZoneDepth,
				'startReservoirZoneDepth': startReservoirZoneDepth,
				'endReservoirZoneDepth' : endReservoirZoneDepth,

			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/wellinfo/${updateId}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201){
						dispatch({ type : WELL_INFO_UPDATE_SUCCESS });
						Modal.success({ title: langdata && langdata.Wellinfoupdatedsuccessfully ? langdata.Wellinfoupdatedsuccessfully : 'Well info updated successfully.' });
						loadWellInfo();
					} else {
						Modal.warning({ title: langdata && langdata.Wellinfonotupdated ? langdata.Wellinfonotupdated : 'Well info not updated' });					
						dispatch({ type : WELL_INFO_UPDATE_ERROR });
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : WELL_INFO_UPDATE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Wellinfonotupdated ? langdata.Wellinfonotupdated : 'Well info not updated' });					
					}
				});

			setEditModalOpen(false);
		}
	});	

	return (
		<Content>
			<Row style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center',
			}}
			>
				<Col span={8}>
					<Row>
						<Space>
							<ToolOutlined style={{fontSize:'30px'}}/>
						</Space>
						<Space>&nbsp;&nbsp;</Space>
						<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.WellInfo ? langdata.WellInfo : 'Well Info'}</Title>
					</Row>
				</Col>
				<Col span={16}>
					<Row justify="end">
						
					</Row>
				</Col>
			</Row>
			<Row span={24} style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center'
			}} >
				<Col span={24}>
					{ data && data.wellInfo.length > 0 ? (
						<Table
							scroll={{
								x: 900,
							}}
							columns = { columns } 
							dataSource = { data.wellInfo } 
						/>
					)
						:
						(
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						)}
				</Col>
			</Row>

			<Modal
				title={langdata && langdata.DAS ? langdata.DAS : 'DAS'}
				centered
				open={dasModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{permission==='superadmin' || permission === 'admin' ?
							<Button type="primary"  onClick={dasFormik.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
							: null }
					</Space>
				]}
				width={700}
			>
				<Form layout="vertical" >
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.DASIP ? langdata.DASIP : 'DAS IP'} 
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDASIP ? langdata.PleaseentertheDASIP : 'Please enter the DAS IP' }]} required>
								<Input 
									name="dasIp" 
									placeholder={langdata && langdata.PleaseentertheDASIP ? langdata.PleaseentertheDASIP : 'Please enter the DAS IP'}
									onChange={dasFormik.handleChange} 
									value={dasFormik.values.dasIp}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.DASPort ? langdata.DASPort : 'DAS Port'} 
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDASPort ? langdata.PleaseentertheDASPort : 'Please enter the DAS Port' }]} required>
								<Input 
									name="dasPort" 
									type='number'
									placeholder={langdata && langdata.PleaseentertheDASPort ? langdata.PleaseentertheDASPort : 'Please enter the DAS Port'}
									onChange={dasFormik.handleChange} 
									value={dasFormik.values.dasPort}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.MinimumAmplitudeThreshold ? langdata.MinimumAmplitudeThreshold : 'Minimum Amplitude Threshold'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumamplitudethreshold ? langdata.Pleaseentertheminimumamplitudethreshold : 'Please enter the minimum amplitude threshold' }]} required>
								<Input 
									name="minAmpThreshold" 
									type='number'
									placeholder={langdata && langdata.Pleaseentertheminimumamplitudethreshold ? langdata.Pleaseentertheminimumamplitudethreshold : 'Please enter the minimum amplitude threshold'}
									onChange={dasFormik.handleChange} 
									value={dasFormik.values.minAmpThreshold}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.MaximumAmplitudeThreshold ? langdata.MaximumAmplitudeThreshold : 'Maximum Amplitude Threshold'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumamplitudethreshold ? langdata.Pleaseenterthemaximumamplitudethreshold : 'Please enter the maximum amplitude threshold' }]} required>
								<Input 
									name="maxAmpThreshold" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthemaximumamplitudethreshold ? langdata.Pleaseenterthemaximumamplitudethreshold : 'Please enter the maximum amplitude threshold'}
									onChange={dasFormik.handleChange} 
									value={dasFormik.values.maxAmpThreshold}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Isenabled ? langdata.Isenabled : 'Is Enabled'} required>
								<Switch checked={dasFormik.values.dasEnabled} onChange={(value) => dasFormik.setFieldValue('dasEnabled', value)}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Modal
				title={langdata && langdata.DTS ? langdata.DTS : 'DTS'}
				centered
				open={dtsModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{permission==='superadmin' || permission === 'admin' ?
							<Button type="primary"  onClick={dtsFormik.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
							: null }
					</Space>
				]}
				width={700}
			>
				<Form layout="vertical" >
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.DASIP ? langdata.DASIP : 'DAS IP'} 
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDTSIP ? langdata.PleaseentertheDTSIP : 'Please enter the DTS IP' }]} required>
								<Input 
									name="dtsIp" 
									placeholder={langdata && langdata.PleaseentertheDTSIP ? langdata.PleaseentertheDTSIP : 'Please enter the DTS IP'}
									onChange={dtsFormik.handleChange} 
									value={dtsFormik.values.dtsIp}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.DASPort ? langdata.DASPort : 'DAS Port'} 
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDTSPort ? langdata.PleaseentertheDTSPort : 'Please enter the DTS Port' }]} required>
								<Input 
									name="dtsPort" 
									type='number'
									placeholder={langdata && langdata.PleaseentertheDTSPort ? langdata.PleaseentertheDTSPort : 'Please enter the DTS Port'}
									onChange={dtsFormik.handleChange} 
									value={dtsFormik.values.dtsPort}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.MinimumTemperatureThreshold ? langdata.MinimumTemperatureThreshold : 'Minimum Temperature Threshold'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumtemperaturethreshold ? langdata.Pleaseentertheminimumtemperaturethreshold : 'Please enter the minimum temperature threshold' }]} required>
								<Input 
									name="minTempThreshold" 
									type='number'
									placeholder={langdata && langdata.Pleaseentertheminimumtemperaturethreshold ? langdata.Pleaseentertheminimumtemperaturethreshold : 'Please enter the minimum temperature threshold'}
									onChange={dtsFormik.handleChange} 
									value={dtsFormik.values.minTempThreshold}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.MaximumTemperatureThreshold ? langdata.MaximumTemperatureThreshold : 'Maximum Temperature Threshold'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum temperature threshold' }]} required>
								<Input 
									name="maxTempThreshold" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum temperature threshold'}
									onChange={dtsFormik.handleChange} 
									value={dtsFormik.values.maxTempThreshold}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Isenabled ? langdata.Isenabled : 'Is Enabled'} required>
								<Switch checked={dtsFormik.values.dtsEnabled} onChange={(value) => dtsFormik.setFieldValue('dtsEnabled', value)}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Modal
				title={langdata && langdata.Pressure ? langdata.Pressure : 'Pressure'}
				centered
				open={pressureModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{permission==='superadmin' || permission === 'admin' ?
							<Button type="primary"  onClick={pressureFormik.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
							: null }
					</Space>
				]}
				width={700}
			>
				<Form layout="vertical" >
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.PressureIP ? langdata.PressureIP : 'Pressure IP'} 
								rules={[{ required: true, message: langdata && langdata.PleaseenterthepressureIP ? langdata.PleaseenterthepressureIP : 'Please enter the pressure IP' }]} required>
								<Input 
									name="pressureip" 
									placeholder={langdata && langdata.PleaseenterthepressureIP ? langdata.PleaseenterthepressureIP : 'Please enter the pressure IP'}
									onChange={pressureFormik.handleChange} 
									value={pressureFormik.values.pressureip}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.PressurePort ? langdata.PressurePort : 'Pressure Port'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthepressureport ? langdata.Pleaseenterthepressureport : 'Please enter the pressure port' }]} required>
								<Input 
									name="pressureport" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthepressureport ? langdata.Pleaseenterthepressureport : 'Please enter the pressure port'}
									onChange={pressureFormik.handleChange} 
									value={pressureFormik.values.pressureport}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.MinimumPressureThreshold ? langdata.MinimumPressureThreshold : 'Minimum Pressure Threshold'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumpressurethreshold ? langdata.Pleaseentertheminimumpressurethreshold : 'Please enter the minimum pressure threshold' }]} required>
								<Input 
									name="minPressureThreshold" 
									type='number'
									placeholder={langdata && langdata.Pleaseentertheminimumpressurethreshold ? langdata.Pleaseentertheminimumpressurethreshold : 'Please enter the minimum pressure threshold'}
									onChange={pressureFormik.handleChange} 
									value={pressureFormik.values.minPressureThreshold}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.MaximumPressureThreshold ? langdata.MaximumPressureThreshold : 'Maximum Pressure Threshold'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum pressure threshold' }]} required>
								<Input 
									name="maxPressureThreshold" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthemaximumpressurethreshold ? langdata.Pleaseenterthemaximumpressurethreshold : 'Please enter the maximum pressure threshold'}
									onChange={pressureFormik.handleChange} 
									value={pressureFormik.values.maxPressureThreshold}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Isenabled ? langdata.Isenabled : 'Is Enabled'} required>
								<Switch checked={pressureFormik.values.pressureEnabled} onChange={(value) => pressureFormik.setFieldValue('pressureEnabled', value)}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Modal
				title={ permission==='superadmin' || permission === 'admin' ? langdata && langdata.EditWellInfo ? langdata.EditWellInfo : 'Edit Well Info' : langdata && langdata.ViewWellInfo ? langdata.ViewWellInfo : 'View Well Info'}
				centered
				open={editModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{permission==='superadmin' || permission === 'admin' ?
							<Button type="primary"  onClick={editFormik.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
							: null }
					</Space>
				]}
				width={700}
			>
				<Form layout="vertical" >
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.Name ? langdata.Name : 'Name'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthename ? langdata.Pleaseenterthename : 'Please enter the name' }]} required>
								<Input 
									name="name" 
									placeholder={langdata && langdata.Pleaseenterthename ? langdata.Pleaseenterthename : 'Please enter the name'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.name}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.WellType ? langdata.WellType : 'Well Type'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseselectthewelltype ? langdata.Pleaseselectthewelltype : 'Please select the well type' }]} required>
								<Select 
									name="wellTypeId" 
									placeholder={langdata && langdata.Pleaseselectthewelltype ? langdata.Pleaseselectthewelltype : 'Please select the well type'}
									onChange={(value) => editFormik.setFieldValue('wellTypeId', value)}
									value={editFormik.values.wellTypeId}
									options={wellTypes}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.Length ? langdata.Length : 'Length'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthelength ? langdata.Pleaseenterthelength : 'Please enter the length' }]} required>
								<Input 
									name="length" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthelength ? langdata.Pleaseenterthelength : 'Please enter the length'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.length}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.Width ? langdata.Width : 'Width'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthewidth ? langdata.Pleaseenterthewidth : 'Please enter the width' }]} required>
								<Input 
									name="width" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthewidth ? langdata.Pleaseenterthewidth : 'Please enter the width'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.width}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.StartDepth ? langdata.StartDepth : 'Start Depth'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthestartdepth ? langdata.Pleaseenterthestartdepth : 'Please enter the start depth' }]} required>
								<Input 
									name="startDepth" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthestartdepth ? langdata.Pleaseenterthestartdepth : 'Please enter the start depth'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.startDepth}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.EndDepth ? langdata.EndDepth : 'End Depth'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheenddepth ? langdata.Pleaseentertheenddepth : 'Please enter the end depth' }]} required>
								<Input 
									name="endDepth" 
									type='number'
									placeholder={langdata && langdata.Pleaseentertheenddepth ? langdata.Pleaseentertheenddepth : 'Please enter the end depth'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.endDepth}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthelatitude ? langdata.Pleaseenterthelatitude : 'Please enter the latitude' }]} required>
								<Input 
									name="latitude" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthelatitude ? langdata.Pleaseenterthelatitude : 'Please enter the latitude'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.latitude}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthelongitude ? langdata.Pleaseenterthelongitude : 'Please enter the longitude' }]} required>
								<Input 
									name="longitude" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthelongitude ? langdata.Pleaseenterthelongitude : 'Please enter the longitude'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.longitude}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.Upperzonestartdepth ? langdata.Upperzonestartdepth : 'Upper zone start depth'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheupperzonestartdepth ? langdata.Pleaseentertheupperzonestartdepth : 'Please enter the upper zone start depth' }]} required>
								<Input 
									name="startUpperZoneDepth" 
									type='number'
									placeholder={langdata && langdata.Pleaseentertheupperzonestartdepth ? langdata.Pleaseentertheupperzonestartdepth : 'Please enter the upper zone start depth'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.startUpperZoneDepth}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.Upperzoneenddepth ? langdata.Upperzoneenddepth : 'Upper zone end depth'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheupperzoneenddepth ? langdata.Pleaseentertheupperzoneenddepth : 'Please enter the upper zone end depth' }]} required>
								<Input 
									name="endUpperZoneDepth" 
									type='number'
									placeholder={langdata && langdata.Pleaseentertheupperzoneenddepth ? langdata.Pleaseentertheupperzoneenddepth : 'Please enter the upper zone end depth'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.endUpperZoneDepth}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.Intermediatezonestartdepth ? langdata.Intermediatezonestartdepth : 'Intermediate zone start depth'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheintermediatezonestartdepth ? langdata.Pleaseentertheintermediatezonestartdepth : 'Please enter the intermediate zone start depth' }]} required>
								<Input 
									name="startInterZoneDepth" 
									type='number'
									placeholder={langdata && langdata.Pleaseentertheintermediatezonestartdepth ? langdata.Pleaseentertheintermediatezonestartdepth : 'Please enter the intermediate zone start depth'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.startInterZoneDepth}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.Intermediatezoneenddepth ? langdata.Intermediatezoneenddepth : 'Intermediate zone end depth'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheintermediatezoneenddepth ? langdata.Pleaseentertheintermediatezoneenddepth : 'Please enter the intermediate zone end depth' }]} required>
								<Input 
									name="endInterZoneDepth" 
									type='number'
									placeholder={langdata && langdata.Pleaseentertheintermediatezoneenddepth ? langdata.Pleaseentertheintermediatezoneenddepth : 'Please enter the intermediate zone end depth'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.endInterZoneDepth}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.Reservoirzonestartdepth ? langdata.Reservoirzonestartdepth : 'Reservoir zone start depth'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthereservoirzonestartdepth ? langdata.Pleaseenterthereservoirzonestartdepth : 'Please enter the reservoir zone start depth' }]} required>
								<Input 
									name="startReservoirZoneDepth" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthereservoirzonestartdepth ? langdata.Pleaseenterthereservoirzonestartdepth : 'Please enter the reservoir zone start depth'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.startReservoirZoneDepth}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.Reservoirzoneenddepth ? langdata.Reservoirzoneenddepth : 'Reservoir zone endd epth'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthereservoirzoneenddepth ? langdata.Pleaseenterthereservoirzoneenddepth : 'Please enter the reservoir zone end depth' }]} required>
								<Input 
									name="endReservoirZoneDepth" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthereservoirzoneenddepth ? langdata.Pleaseenterthereservoirzoneenddepth : 'Please enter the reservoir zone end depth'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.endReservoirZoneDepth}
								/>
							</Form.Item>
						</Col>

					</Row>
					<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DAS ? langdata.DAS:'DAS'}</b></Typography>
					<Divider/>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.DASIP ? langdata.DASIP : 'DAS IP'} 
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDASIP ? langdata.PleaseentertheDASIP : 'Please enter the DAS IP' }]} required>
								<Input 
									name="dasIp" 
									placeholder={langdata && langdata.PleaseentertheDASIP ? langdata.PleaseentertheDASIP : 'Please enter the DAS IP'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.dasIp}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.DASPort ? langdata.DASPort : 'DAS Port'} 
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDASPort ? langdata.PleaseentertheDASPort : 'Please enter the DAS Port' }]} required>
								<Input 
									name="dasPort" 
									type='number'
									placeholder={langdata && langdata.PleaseentertheDASPort ? langdata.PleaseentertheDASPort : 'Please enter the DAS Port'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.dasPort}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.MinimumAmplitudeThreshold ? langdata.MinimumAmplitudeThreshold : 'Minimum Amplitude Threshold'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumamplitudethreshold ? langdata.Pleaseentertheminimumamplitudethreshold : 'Please enter the minimum amplitude threshold' }]} required>
								<Input 
									name="minAmpThreshold" 
									type='number'
									placeholder={langdata && langdata.Pleaseentertheminimumamplitudethreshold ? langdata.Pleaseentertheminimumamplitudethreshold : 'Please enter the minimum amplitude threshold'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.minAmpThreshold}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.MaximumAmplitudeThreshold ? langdata.MaximumAmplitudeThreshold : 'Maximum Amplitude Threshold'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumamplitudethreshold ? langdata.Pleaseenterthemaximumamplitudethreshold : 'Please enter the maximum amplitude threshold' }]} required>
								<Input 
									name="maxAmpThreshold" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthemaximumamplitudethreshold ? langdata.Pleaseenterthemaximumamplitudethreshold : 'Please enter the maximum amplitude threshold'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.maxAmpThreshold}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Isenabled ? langdata.Isenabled : 'Is Enabled'} required>
								<Switch checked={editFormik.values.dasEnabled} onChange={(value) => editFormik.setFieldValue('dasEnabled', value)}/>
							</Form.Item>
						</Col>
					</Row>
					<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS:'DTS'}</b></Typography>
					<Divider/>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.DASIP ? langdata.DASIP : 'DAS IP'} 
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDTSIP ? langdata.PleaseentertheDTSIP : 'Please enter the DTS IP' }]} required>
								<Input 
									name="dtsIp" 
									placeholder={langdata && langdata.PleaseentertheDTSIP ? langdata.PleaseentertheDTSIP : 'Please enter the DTS IP'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.dtsIp}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.DASPort ? langdata.DASPort : 'DAS Port'} 
								rules={[{ required: true, message: langdata && langdata.PleaseentertheDTSPort ? langdata.PleaseentertheDTSPort : 'Please enter the DTS Port' }]} required>
								<Input 
									name="dtsPort" 
									type='number'
									placeholder={langdata && langdata.PleaseentertheDTSPort ? langdata.PleaseentertheDTSPort : 'Please enter the DTS Port'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.dtsPort}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.MinimumTemperatureThreshold ? langdata.MinimumTemperatureThreshold : 'Minimum Temperature Threshold'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumtemperaturethreshold ? langdata.Pleaseentertheminimumtemperaturethreshold : 'Please enter the minimum temperature threshold' }]} required>
								<Input 
									name="minTempThreshold" 
									type='number'
									placeholder={langdata && langdata.Pleaseentertheminimumtemperaturethreshold ? langdata.Pleaseentertheminimumtemperaturethreshold : 'Please enter the minimum temperature threshold'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.minTempThreshold}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.MaximumTemperatureThreshold ? langdata.MaximumTemperatureThreshold : 'Maximum Temperature Threshold'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum temperature threshold' }]} required>
								<Input 
									name="maxTempThreshold" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum temperature threshold'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.maxTempThreshold}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Isenabled ? langdata.Isenabled : 'Is Enabled'} required>
								<Switch checked={editFormik.values.dtsEnabled} onChange={(value) => editFormik.setFieldValue('dtsEnabled', value)}/>
							</Form.Item>
						</Col>
					</Row>
					<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.Pressure ? langdata.Pressure:'Pressure'}</b></Typography>
					<Divider/>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.PressureIP ? langdata.PressureIP : 'Pressure IP'} 
								rules={[{ required: true, message: langdata && langdata.PleaseenterthepressureIP ? langdata.PleaseenterthepressureIP : 'Please enter the pressure IP' }]} required>
								<Input 
									name="pressureip" 
									placeholder={langdata && langdata.PleaseenterthepressureIP ? langdata.PleaseenterthepressureIP : 'Please enter the pressure IP'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.pressureip}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.PressurePort ? langdata.PressurePort : 'Pressure Port'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthepressureport ? langdata.Pleaseenterthepressureport : 'Please enter the pressure port' }]} required>
								<Input 
									name="pressureport" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthepressureport ? langdata.Pleaseenterthepressureport : 'Please enter the pressure port'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.pressureport}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.MinimumPressureThreshold ? langdata.MinimumPressureThreshold : 'Minimum Pressure Threshold'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheminimumpressurethreshold ? langdata.Pleaseentertheminimumpressurethreshold : 'Please enter the minimum pressure threshold' }]} required>
								<Input 
									name="minPressureThreshold" 
									type='number'
									placeholder={langdata && langdata.Pleaseentertheminimumpressurethreshold ? langdata.Pleaseentertheminimumpressurethreshold : 'Please enter the minimum pressure threshold'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.minPressureThreshold}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item 
								label={langdata && langdata.MaximumPressureThreshold ? langdata.MaximumPressureThreshold : 'Maximum Pressure Threshold'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumtemperaturethreshold ? langdata.Pleaseenterthemaximumtemperaturethreshold : 'Please enter the maximum pressure threshold' }]} required>
								<Input 
									name="maxPressureThreshold" 
									type='number'
									placeholder={langdata && langdata.Pleaseenterthemaximumpressurethreshold ? langdata.Pleaseenterthemaximumpressurethreshold : 'Please enter the maximum pressure threshold'}
									onChange={editFormik.handleChange} 
									value={editFormik.values.maxPressureThreshold}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Isenabled ? langdata.Isenabled : 'Is Enabled'} required>
								<Switch checked={editFormik.values.pressureEnabled} onChange={(value) => editFormik.setFieldValue('pressureEnabled', value)}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Spin fullscreen spinning={data.loading} />
		</Content>
	);
}