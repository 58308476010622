export const FIBER_SPLICE_LIST = 'FIBER_SPLICE_LIST';
export const FIBER_SPLICE_LIST_SUCCESS = 'FIBER_SPLICE_LIST_SUCCESS';
export const FIBER_SPLICE_LIST_ERROR = 'FIBER_SPLICE_LIST_ERROR';

export const FIBER_SPLICE_ADD = 'FIBER_SPLICE_ADD';
export const FIBER_SPLICE_ADD_SUCCESS = 'FIBER_SPLICE_ADD_SUCCESS';
export const FIBER_SPLICE_ADD_ERROR = 'FIBER_SPLICE_ADD_ERROR';

export const FIBER_SPLICE_DELETE = 'FIBER_SPLICE_DELETE';
export const FIBER_SPLICE_DELETE_SUCCESS = 'FIBER_SPLICE_DELETE_SUCCESS';
export const FIBER_SPLICE_DELETE_ERROR = 'FIBER_SPLICE_DELETE_ERROR';