export const WELL_ZONE_INFO = 'WELL_ZONE_INFO';
export const WELL_ZONE_INFO_SUCCESS = 'WELL_ZONE_INFO_SUCCESS';
export const WELL_ZONE_INFO_ERROR = 'WELL_ZONE_INFO_ERROR';

export const WELL_ZONE_INFO_UPDATE_DATA = 'WELL_ZONE_INFO_UPDATE_DATA';

export const WELL_ZONE_INFO_UPDATE = 'WELL_ZONE_INFO_UPDATE';
export const WELL_ZONE_INFO_UPDATE_SUCCESS = 'WELL_ZONE_INFO_UPDATE_SUCCESS';
export const WELL_ZONE_INFO_UPDATE_ERROR = 'WELL_ZONE_INFO_UPDATE_ERROR';

export const WELL_ZONE_INFO_CREATE = 'WELL_ZONE_INFO_CREATE';
export const WELL_ZONE_INFO_CREATE_SUCCESS = 'WELL_ZONE_INFO_CREATE_SUCCESS';
export const WELL_ZONE_INFO_CREATE_ERROR = 'WELL_ZONE_INFO_CREATE_ERROR';

export const WELL_ZONE_INFO_DELETE = 'WELL_ZONE_INFO_DELETE';
export const WELL_ZONE_INFO_DELETE_SUCCESS = 'WELL_ZONE_INFO_DELETE_SUCCESS';
export const WELL_ZONE_INFO_DELETE_ERROR = 'WELL_ZONE_INFO_DELETE_ERROR';