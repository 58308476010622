/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Row, Col, Typography, Button, message, Tooltip } from 'antd';
import { DownloadOutlined, SaveOutlined } from '@ant-design/icons';
import CsvDownloader from 'react-csv-downloader';

import axios from 'axios';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';


export function BatchDownloader(props) {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const envstrgebatch = process.env.REACT_APP_BATCH_SIZE == undefined || process.env.REACT_APP_BATCH_SIZE == 'undefined' ? 1000 : process.env.REACT_APP_BATCH_SIZE;
	const BATCH_SIZE = localStorage.getItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`) == 'null' ?  parseInt(envstrgebatch) : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const TOTAL = props.total;
	const BATCHES = TOTAL / BATCH_SIZE;
	const batches = [];
    
	let startIndex = 1;
	let index = 0;
    
	for (let i=0; i< BATCHES; i++) {
		let start = startIndex;
		let end = (startIndex + BATCH_SIZE-1);
      
		end = (end >= TOTAL) ? TOTAL : end;
      
		let batch = { index: index, start: start, end: end, fetched: false };
  
		batches.push(batch);
      
		startIndex += BATCH_SIZE;
		index++;
	}
  
	return <>
		<Row gutter={16} style={{
			padding: '5px',
			marginTop: '10px',
			maxHeight: '250px',
			overflowY: 'scroll'
		}}>
			<Col span={24}>
				<Row style={{
					backgroundColor: '#d3d3d3',
					alignItems: 'center',
					marginTop: '5px',
					marginBottom: '5px',
					padding: '8px'
				}}>
					{batches.map((batch, index) =>
						<>
							<Col span={22} style={{ marginBottom: '6px' }}>
								<Typography>{langdata && langdata.Batch ? langdata.Batch:'Batch'} {index+1} : {langdata && langdata.Rows ? langdata.Rows:'Rows'} {batch.start} {langdata && langdata.to ? langdata.to:'to'} {batch.end}</Typography>
							</Col>
							<Col span={2} style={{ marginBottom: '6px' }}>
								<BatchDownloaderItem index={index} batch={batch} url={props.url} title={langdata && langdata.DownloadReport ? langdata.DownloadReport:'Download Report'} />
							</Col>
						</>
					)}
				</Row>
			</Col>
		</Row>
	</>;
}
  
export function BatchDownloaderItem(props) {
	const url = props.url ? props.url : null;
	const [loading, setLoading] = useState(false);
	const [fetched, setFetched] = useState(false);
	const [data, setData] = useState({});
	
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));
  
	const envstrgebatch = process.env.REACT_APP_BATCH_SIZE == undefined || process.env.REACT_APP_BATCH_SIZE == 'undefined' ? 1000 : process.env.REACT_APP_BATCH_SIZE;
	const BATCH_SIZE = localStorage.getItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`) == 'null' ?  parseInt(envstrgebatch) : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	

	const columns = [
		{
			id: 'name',
			displayName: `${langdata && langdata.Name ? langdata.Name:'Name'}`,
		},
		{
			id: 'totaldistance',
			displayName: `${langdata && langdata.TotalDistance ? langdata.TotalDistance:'Total Distance (km)'}`,
		},
		{
			id: 'covereddistance',
			displayName: `${langdata && langdata.CoveredDistance ? langdata.CoveredDistance:'Covered Distance (km)'}`,
		},
		{
			id: 'avgvelocity',
			displayName: `${langdata && langdata.AvgVelocity ? langdata.AvgVelocity:'Avg Velocity (km/h)'}`,
		},
		{
			id: 'coverage',
			displayName: `${langdata && langdata.Coverage ? langdata.Coverage:'Coverage'}`,
		},
		{
			id: 'idletime',
			displayName: `${langdata && langdata.IdleTime ? langdata.IdleTime:'Idle Time (min)'}`,
		},
		{
			id: 'speedviolation',
			displayName: `${langdata && langdata.SpeedViolation ? langdata.SpeedViolation:'Speed Violation'}`,
		},
		{
			id: 'totalTravelledDist',
			displayName: `${langdata && langdata.TotalTraveledDistance ? langdata.TotalTraveledDistance:'Total Traveled Distance (km)'}`,
		},
		{
			id: 'systemgrade',
			displayName: `${langdata && langdata.SystemGrade ? langdata.SystemGrade:'System Grade'}`,
		},
		{
			id: 'finalgrade',
			displayName: `${langdata && langdata.FinalGrade ? langdata.FinalGrade:'Final Grade'}`
		},
		{
			id: 'remarks',
			displayName: `${langdata && langdata.Remarks ? langdata.Remarks:'Remarks'}`,
		}
	];
  
	const fetchData = (index) => {
		setLoading(true);
  
		let skip =  (index>=1) ? (BATCH_SIZE * index) : (0);
  
		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url.base + `?$limit=${BATCH_SIZE}&$skip=${skip}&` + url.params,
			timeout: extendTimeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};
      
		axios.request(config) .then((response) => {

			if (response && response.data) {
				let datas = [];

				if (response.data && response.data.data) {
					response.data.data.map( (item) => {
						let data = {};
              
						data.id= item.id ? item.id :'';
						data.name= item.userName ? item.userName :'';
						data.totaldistance=item.totalDistance ? item.totalDistance :'' ;
						data.covereddistance=item.coveredDistance ? item.coveredDistance :'';
						data.avgvelocity= item.avgVelocity ?  parseFloat(item.avgVelocity).toFixed(2) : '';
						data.coverage= item.coverage ? parseFloat(item.coverage).toFixed(2): '';
						data.idletime= item.idleTime ? item.idleTime :0;
						data.speedviolation= item.speedViolation ? item.speedViolation :0;
						data.totalTravelledDist = item.totalTravelledDist ? item.totalTravelledDist : 0;
						data.systemgrade= item.systemGrade && item.systemGrade == 0 ? `${langdata && langdata.RED ? langdata.RED:'RED'}` : `${langdata && langdata.GREEN ? langdata.GREEN:'GREEN'}`;
						data.finalgrade=item.finalGrade && item.finalGrade == 0 ? `${langdata && langdata.RED ? langdata.RED:'RED'}` :`${langdata && langdata.GREEN ? langdata.GREEN:'GREEN'}`;
						data.remarks=item.remarks ? item.remarks : '';

						datas.push(data);
					});
  
					setFetched(true);
					setData(datas);
					setLoading(false);
				} else {
					setFetched(true);
					setData([]);
					setLoading(false);   
				}
			} else {
				setFetched(false);
				setData([]);
				setLoading(false);
			}
		}).catch((error) => {
			console.log(error);
        
			setFetched(false);
			setData([]);
			setLoading(false);
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
				});
			}
		});
	};
  
	return <>
		{ props && props.batch && fetched == false ? (
			<Tooltip title={langdata && langdata.FetchdatafromOneView ? langdata.FetchdatafromOneView:'Fetch data from OneView'}>
				<Button type="primary" shape="circle" loading = {loading} icon={<DownloadOutlined />} onClick={() => fetchData(props.index)}></Button>
			</Tooltip>
		) : (
			<>
				<CsvDownloader datas={data} filename={`${langdata && langdata.Gradereport ? langdata.Gradereport:'Grade-report'}-${props.index}`} extension=".csv" columns={columns}> 
					<Tooltip title={langdata && langdata.Downloadtomycomputer ? langdata.Downloadtomycomputer:'Download to my computer'}>
						<Button type="primary" shape="circle" icon={<SaveOutlined />} style={{ backgroundColor: '#008000', border: '1px solid #008000' }}></Button>
					</Tooltip>
				</CsvDownloader>
			</>
		)}
      
	</>;
}