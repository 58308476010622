import { PATCH_DETAILS, PATCH_INSPECTION_LIST, PATCH_INSPECTION_LIST_ERROR, PATCH_INSPECTION_LIST_SUCCESS, PATCH_LIST, PATCH_LIST_ERROR, PATCH_LIST_SUCCESS } from './constants';

export default function patchdata(state, action) {

	switch (action.type) {
      
	case PATCH_LIST:
		return { ...state, pending: true };
	case PATCH_LIST_SUCCESS:
		return { ...state, patch_data: action.payload, pending: false };
	case PATCH_DETAILS:
		return { ...state, details: action.payload, pending: false };
	case PATCH_LIST_ERROR:
		return { ...state, error: true, pending: false  };

	case PATCH_INSPECTION_LIST:
		return { ...state, pending: true };
	case PATCH_INSPECTION_LIST_SUCCESS:
		return { ...state, inspection_data: action.payload, pending: false };
	case PATCH_INSPECTION_LIST_ERROR:
		return { ...state, error: true, pending: false };

      
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}