import { WELL_ALGORITHM_INFO_CREATE, WELL_ALGORITHM_INFO_CREATE_ERROR, WELL_ALGORITHM_INFO_CREATE_SUCCESS, WELL_ALGORITHM_INFO_DELETE, WELL_ALGORITHM_INFO_DELETE_ERROR, WELL_ALGORITHM_INFO_DELETE_SUCCESS, WELL_ALGORITHM_INFO_UPDATE, WELL_ALGORITHM_INFO_UPDATE_DATA, WELL_ALGORITHM_INFO_UPDATE_ERROR, WELL_ALGORITHM_INFO_UPDATE_SUCCESS } from './constants';

export default function wellAlgorithmInfoReducer(state, action) {
	switch (action.type) {
	
	case WELL_ALGORITHM_INFO_UPDATE_DATA :
		return { ...state, wellalgorithmUpdateData : action.payload };
	case WELL_ALGORITHM_INFO_UPDATE :
		return { ...state, loading : true, error : false};
	case WELL_ALGORITHM_INFO_UPDATE_SUCCESS :
		return { ...state, loading : false, error : false};
	case WELL_ALGORITHM_INFO_UPDATE_ERROR : 
		return { ...state, loading : false, error : true};
	case WELL_ALGORITHM_INFO_CREATE:
		return { ...state, loading : true, error : false };
	case WELL_ALGORITHM_INFO_CREATE_SUCCESS :
		return { ...state, loading : false, error : false};
	case WELL_ALGORITHM_INFO_CREATE_ERROR :
		return { ...state, loading : false, error : true };
	case WELL_ALGORITHM_INFO_DELETE :
		return { ...state, loading : true, error : false};
	case WELL_ALGORITHM_INFO_DELETE_SUCCESS :
		return { ...state, loading : false, error : false };
	case WELL_ALGORITHM_INFO_DELETE_ERROR :
		return { ...state, loading : false, error : true };
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}