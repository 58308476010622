export const TLPS_LIST = 'TLPS_LIST';
export const TLPS_LIST_SUCCESS = 'TLPS_LIST_SUCCESS';
export const TLPS_LIST_ERROR = 'TLPS_LIST_ERROR';

export const NOTIFICATIONS_LIST = 'NOTIFICATIONS_LIST';
export const NOTIFICATIONS_LIST_SUCCESS = 'NOTIFICATIONS_LIST_SUCCESS';
export const NOTIFICATIONS_LIST_ERROR = 'NOTIFICATIONS_LIST_ERROR';

export const NOTIFICATION_DETAILS = 'NOTIFICATION_DETAILS';
export const NOTIFICATION_DETAILS_SUCCESS = 'NOTIFICATION_DETAILS_SUCCESS';
export const NOTIFICATION_DETAILS_ERROR = 'NOTIFICATION_DETAILS_ERROR';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_ERROR = 'DELETE_NOTIFICATION_ERROR';