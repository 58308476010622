import { ADD_ALARM_RULE, ADD_ALARM_RULE_ERROR, ADD_ALARM_RULE_SUCCESS, ALARM_RULE, ALARM_RULE_ERROR, ALARM_RULE_SUCCESS, DELETE_ALARM_RULE, DELETE_ALARM_RULE_ERROR, DELETE_ALARM_RULE_SUCCESS, UPDATE_ALARM_RULE, UPDATE_ALARM_RULE_ERROR, UPDATE_ALARM_RULE_SUCESS } from './constants';

export default function alarmRuleEngineReducer(state, action)  {
	let pagination = {};
	const alarmRules = [];
	switch(action.type){
	case ALARM_RULE :
		return { ...state, loading: true };
	case ALARM_RULE_SUCCESS : 
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
			pagination.pageSize =  action.pageSize ? action.pageSize : 10;

			action.payload.data.map((item) => {
				alarmRules.push({
					id : item.id,
					description : item.ruleDesc,
					eventType : item.eventtype && item.eventtype.eventType ? item.eventtype.eventType : '',	
					alarmType : item.alarmType,
					isEnabled : item.isEnabled == true ? 'True' : 'False'
				});
			});
		}
		return { ...state, alarmRules: alarmRules, pagination : pagination, loading: false, error: false };
	case ALARM_RULE_ERROR :
		return { ...state, loading: false, error: true };
	case ADD_ALARM_RULE :
		return { ...state, loading: true };
	case ADD_ALARM_RULE_SUCCESS :
		return { ...state, loading: false, error: false };
	case ADD_ALARM_RULE_ERROR :
		return { ...state, loading: false, error: true };
	case DELETE_ALARM_RULE :
		return { ...state, loading: true };
	case DELETE_ALARM_RULE_SUCCESS :
		return { ...state, loading: false, error: false };
	case DELETE_ALARM_RULE_ERROR :
		return { ...state, loading: false, error: true };
	case UPDATE_ALARM_RULE : 
		return { ...state, loading: true };
	case UPDATE_ALARM_RULE_SUCESS :
		return { ...state, loading: false, ruleDetails : action.payload };
	case UPDATE_ALARM_RULE_ERROR :
		return { ...state, loading: false, error: true };

	default:
		throw Error('Unknown action: ' + action.type);
	}
}
