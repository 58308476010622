export const ESCALATION_MATRIX_LIST = 'ESCALATION_MATRIX_LIST';
export const ESCALATION_MATRIX_LIST_SUCCESS = 'ESCALATION_MATRIX_LIST_SUCCESS';
export const ESCALATION_MATRIX_LIST_ERROR = 'ESCALATION_MATRIX_LIST_ERROR';
export const ESCALATION_MATRIX_DETAILS = 'ESCALATION_MATRIX_DETAILS';

export const USER_LIST = 'USER_LIST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_ERROR = 'USER_LIST_ERROR';


export const ESCALATION_MATRIX_UPDATE = 'ESCALATION_MATRIX_UPDATE';
export const ESCALATION_MATRIX_UPDATE_SUCCESS = 'ESCALATION_MATRIX_UPDATE_SUCCESS';
export const ESCALATION_MATRIX_UPDATE_ERROR = 'ESCALATION_MATRIX_UPDATE_ERROR';

export const ESCALATION_MATRIX_DELETE = 'ESCALATION_MATRIX_DELETE';
export const ESCALATION_MATRIX_DELETE_SUCCESS = 'ESCALATION_MATRIX_DELETE_SUCCESS';
export const ESCALATION_MATRIX_DELETE_ERROR = 'ESCALATION_MATRIX_DELETE_ERROR';

export const SMTP_LIST = 'SMTP_LIST';
export const SMTP_LIST_SUCCESS = 'SMTP_LIST_SUCCESS';
export const SMTP_LIST_ERROR = 'SMTP_LIST_ERROR';

export const SMTP_UPDATE = 'SMTP_UPDATE';
export const SMTP_UPDATE_SUCCESS = 'SMTP_UPDATE_SUCCESS';
export const SMTP_UPDATE_ERROR = 'SMTP_UPDATE_ERROR';

