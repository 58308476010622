import { GRADE_LIST, GRADE_LIST_ERROR, GRADE_LIST_SUCCESS, GRADE_UPDATE, GRADE_UPDATE_DETAILS, GRADE_UPDATE_ERROR, GRADE_UPDATE_SUCCESS } from './constants';

export default function gradedata(state, action) {

	switch (action.type) {
      
	case GRADE_LIST:
		return { ...state, pending: true };
	case GRADE_LIST_SUCCESS:
		return { ...state, grade_data: action.payload, pending: false };
	case GRADE_UPDATE_DETAILS:
		return { ...state, details: action.payload, pending: false };
	case GRADE_LIST_ERROR:
		return { ...state, error: true, pending: false };

	case GRADE_UPDATE:
		return { ...state, pending: true };
	case GRADE_UPDATE_SUCCESS:
		return { ...state, grade_update: action.payload, pending: false };
	case GRADE_UPDATE_ERROR:
		return { ...state, error: true, pending: false };

      
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}