import React, { useEffect, useReducer, useState } from 'react';

import { useAuth } from '../../Context';
import { Content } from 'antd/es/layout/layout';
import { Button, Col, Empty, Form, Input, message, Modal, Row,  Space, Spin, Table,  Typography } from 'antd';
import { ToolOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import axios from 'axios';
import wellAlgorithmInfoReducer from './reducer';
import { WELL_ALGORITHM_INFO_CREATE, WELL_ALGORITHM_INFO_CREATE_ERROR, WELL_ALGORITHM_INFO_CREATE_SUCCESS, WELL_ALGORITHM_INFO_DELETE, WELL_ALGORITHM_INFO_DELETE_ERROR, WELL_ALGORITHM_INFO_DELETE_SUCCESS, WELL_ALGORITHM_INFO_UPDATE, WELL_ALGORITHM_INFO_UPDATE_DATA, WELL_ALGORITHM_INFO_UPDATE_ERROR, WELL_ALGORITHM_INFO_UPDATE_SUCCESS } from './constants';
import { useFormik } from 'formik';

const { Title } = Typography;

export default function WellAlgoritham() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;
	const initalState = {wellZoneInfo : [], loading: false, error: false, wellalgorithmUpdateData : {}};
	const [data, dispatch] = useReducer(wellAlgorithmInfoReducer, initalState);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const {wellalgorithmdata,getWellAlgorithm} = useAuth();
	const [updateId, setUpdateId]= useState();
	const [showDeleteModal, setShowDeleteModal] = useState();
	const [deleteId, setDeleteId] = useState();
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;	
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	
	useEffect(() => {
		window.scrollTo(0, 0);
		getWellAlgorithm(authtoken);
	}, []);


	const columns = [
		{
			title: langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex: 'name',
			key: 'name',
		},
	];

	permission==='superadmin' || permission === 'admin' ? 
		columns.push({
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (_,record) => (
				<Space size="middle">
					<a title={langdata && langdata.EditWellAlgorithm ? langdata.EditWellAlgorithm : 'Edit Well Algorithm'} onClick={() => {showModal(record);}} ><EditOutlined/></a>
					<a title={langdata && langdata.Delete ? langdata.Delete : 'Delete'} onClick={() => {openUserModal(record);}} ><DeleteOutlined /></a>
				</Space>
			)
		}) : null;

	
	const showModal = (value) => {
		setIsEditModalOpen(true);
		setUpdateId(value.id);
		dispatch({ type : WELL_ALGORITHM_INFO_UPDATE_DATA , payload : value});
	};
	const handleOk = () => {
		setIsEditModalOpen(false);
		setIsAddModalOpen(false);
		setShowDeleteModal(false);

	};
	const handleCancel = () => {
		setIsEditModalOpen(false);
		setIsAddModalOpen(false);
		setShowDeleteModal(false);

	};	

	const updateFormik = useFormik({
		enableReinitialize: true,
		initialValues : {
			name : data.wellalgorithmUpdateData.name,
		},
		onSubmit: (values) => {
			const {name } = values;			
			if(name == null){
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}
			
			dispatch({ type : WELL_ALGORITHM_INFO_UPDATE });
			let data = JSON.stringify({
				'name': name,
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/wellalgorithm/${updateId}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`				
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201){
						getWellAlgorithm(authtoken);
						Modal.success({ title: langdata && langdata.Wellalgorithmupdatedsuccessfully ? langdata.Wellalgorithmupdatedsuccessfully : 'Well algorithm updated successfully.' });					
						dispatch({ type : WELL_ALGORITHM_INFO_UPDATE_SUCCESS });
		
					} else {
						dispatch({ type : WELL_ALGORITHM_INFO_UPDATE_ERROR});
						Modal.warning({ title: langdata && langdata.Unabletoupdatewellalgorithm ? langdata.Unabletoupdatewellalgorithm : 'Unable to update well algorithm' });					
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : WELL_ALGORITHM_INFO_UPDATE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdatewellalgorithm ? langdata.Unabletoupdatewellalgorithm : 'Unable to update well algorithm.' });											
					}
				});
			
			setIsEditModalOpen(false);
		}
	});	

	const createFormik = useFormik({
		// enableReinitialize: true,
		initialValues : {
			name : null,
			
		},
		onSubmit: (values) => {
			const {name } = values;
			
			if( name == null){
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}
		
			
			dispatch({ type : WELL_ALGORITHM_INFO_CREATE });
			let data = JSON.stringify({
				'name' : name,
				'pipelineId':parseInt(pipelineId)
				
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/wellalgorithm`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201){
						getWellAlgorithm(authtoken);		
						Modal.success({ title: langdata && langdata.Wellalgorithmsavedsuccessfully ? langdata.Wellalgorithmsavedsuccessfully : 'Well algorithm saved successfully.' });					
						dispatch({ type : WELL_ALGORITHM_INFO_CREATE_SUCCESS });
					} else {
						dispatch({ type : WELL_ALGORITHM_INFO_CREATE_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletosavewellalgorithm ? langdata.Unabletosavewellalgorithm : 'Unable to save well algorithm' });					
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : WELL_ALGORITHM_INFO_CREATE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Unabletosavewellalgorithm ? langdata.Unabletosavewellalgorithm : 'Unable to save well algorithm' });					
					}
				});
			createFormik.resetForm();
			setIsAddModalOpen(false);
		}
	});	
		
	const openUserModal = (value) => {
		setShowDeleteModal(true);
		setDeleteId(value.id);
	};

	const handleDelete = () => {
		dispatch({ type : WELL_ALGORITHM_INFO_DELETE });
		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/wellalgorithm/${deleteId}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				Authorization:`Bearer ${authtoken}`				},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 || response.status == 201){
					getWellAlgorithm(authtoken);					
					Modal.success({ title: langdata && langdata.Wellalgorithmsuccessfullydeleted ? langdata.Wellalgorithmsuccessfullydeleted : 'Well algorithm successfully deleted.' });					
					dispatch({ type : WELL_ALGORITHM_INFO_DELETE_SUCCESS });
				} else {
					Modal.warning({ title: langdata && langdata.Unabletodeletewellalgorithm ? langdata.Unabletodeletewellalgorithm : 'Unable to delete well algorithm.' });					
					dispatch ({ type : WELL_ALGORITHM_INFO_DELETE_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch ({ type : WELL_ALGORITHM_INFO_DELETE_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				} else {
					Modal.warning({ title: langdata && langdata.Unabletodeletewellalgorithm ? langdata.Unabletodeletewellalgorithm : 'Unable to delete well algorithm.' });
				}
			});
		setShowDeleteModal(false);
	};


	
	return (
		<Content>
			<Row style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center',
			}}
			>
				<Col span={8}>
					<Row>
						<Space>
							<ToolOutlined style={{fontSize:'30px'}}/>
						</Space>
						<Space>&nbsp;&nbsp;</Space>
						<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.WellAlgorithm ? langdata.WellAlgorithm : 'Well Algorithm'}</Title>
					</Row>
				</Col>
				<Col span={16}>
					<Row justify="end">
						<Col>
							{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>User Type:</Text> */}
							{ permission==='superadmin' || permission === 'admin' ? 
								<Space>
									<Space>&nbsp;</Space>
									<Button style={{margin:'5px'}} type="primary" onClick={() => {setIsAddModalOpen(true);}}><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button>
								</Space>
								: null }
						</Col>
					</Row>
				</Col>
			</Row>
			<Row span={24} style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center'
			}} >
				<Col span={24}>
					{ wellalgorithmdata && wellalgorithmdata.data && wellalgorithmdata.data.length > 0 ? (
						<Table
							scroll={{
								x: 900,
							}}
							columns = { columns } 
							dataSource = { wellalgorithmdata.data } 
						/>
					)
						:
						(
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						)}
				</Col>
			</Row>
			<Modal
				title={langdata && langdata.EditWellAlgorithm ? langdata.EditWellAlgorithm : 'Edit Well Algorithm'}
				centered
				open={isEditModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary"  onClick={updateFormik.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
					</Space>
				]}
				width={500}
			>
				<Form layout="vertical" >
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.WellAlgorithmName ? langdata.WellAlgorithmName : 'Well Algorithm Name'} rules={[{ required: false, message: langdata && langdata.Pleasefillthename ? langdata.Pleasefillthename : 'Please fill the name' }]} required>
								<Input 
									name="name" 
									onChange={updateFormik.handleChange} 
									value={updateFormik.values.name}
								/>
							</Form.Item>
						</Col>
						
					</Row>
				</Form>
			</Modal>


			<Modal
				title={langdata && langdata.AddWellAlgorithm ? langdata.AddWellAlgorithm : 'Add Well Algorithm'}
				centered
				open={isAddModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				destroyOnClose= {true}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary"  onClick={createFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}
				width={500}
			>
				<Form layout="vertical" >
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.WellAlgorithmName ? langdata.WellAlgorithmName : 'Well Algorithm Name'} rules={[{ required: true, message: langdata && langdata.Pleasefillthename ? langdata.Pleasefillthename : 'Please fill the name' }]} required>
								<Input 
									name="name" 
									placeholder={langdata && langdata.Pleasefillthename ? langdata.Pleasefillthename : 'Please fill the name'}
									onChange={createFormik.handleChange} 
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={showDeleteModal} onOk={handleOk} onCancel={handleCancel}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}</Button>
						<Button key="submit" type="primary" onClick={handleDelete}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>{ langdata && langdata.Areyousureyouwanttodeletethisalgorithm ? langdata.Areyousureyouwanttodeletethisalgorithm :'Are you sure you want to delete this algorithm?'}</Col>
				</Row>
			</Modal>

			<Spin fullscreen spinning={data.loading} />
		</Content>
	);
}