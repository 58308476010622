/* eslint-disable react/react-in-jsx-scope */
import { Breadcrumb, Card, Col, Divider, message, Modal, Row, Spin, Tabs, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import moment from 'moment';
import { LayersControl, MapContainer, Marker, Pane, Polyline, Popup, TileLayer } from 'react-leaflet';
// import MarkerClusterGroup from 'react-leaflet-cluster';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Context';
import { useEffect, useState } from 'react';
import { Icon } from 'leaflet';
import { FullscreenOutlined,FullscreenExitOutlined, HomeOutlined } from '@ant-design/icons';
import GoogleMap from '../Map/map';
import MarkerClusterGroup from 'react-leaflet-cluster';
// import VehicleModal from '../../images/vehicleModal.png';
// import DrumModal from '../../images/drumModal.png';
// import ClampingModal from '../../images/clampingModal.png';
// import mapImg from '../../images/mapImg.png';
// import mapImgDeep from '../../images/ManualDeep.png';
// import mapImgMachine from '../../images/JCBMap.png';
// import mapImgTunneling from '../../images/TunnelingMap.png';
// import modalDefault from '../../images/modalDefault.png';
// import modalMan from '../../images/ManWalking.png';
import eventsicons from '../eventstypes';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';
import Papa from 'papaparse';
import Plot from 'react-plotly.js';
import leak from '../WellVisualization/LeakDetection/leak_coordinates.csv';
import axios from 'axios';

const pipecolor = { color: 'black' };
const bordercolor = { color: '#fc8383'};
// const odmetercolor = { color: '#00FFFF' };
// const optionalcolor ={color:'orange'};
  
  
// const markericon = new Icon({
// 	iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
// 	iconSize:[38,38]
// });

// const pidsicon = new Icon({
// 	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
// 	iconUrl:require('../../images/refinery.png'),
// 	iconSize:[38,38]
// });

const defaulticon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/basic.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36],
});


const pidsicon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../images/Refinery1.png'),
	iconSize: [30, 30],
	iconAnchor:[18, 30]
});

export default function EventDetails() {

	const { state } = useLocation();
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {handleGetMapdata, mapdata, getRegion, regiondata, wellinfo, getWellInfo} = useAuth();
	const navigate = useNavigate();
	const [size, setSize] = useState('200px');
	const map_type= localStorage.getItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`);
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';
	const oneviewtypeLabel = oneviewType &&  oneviewType.toLowerCase() == 'pipeline' ? langdata && langdata.Pipeline : oneviewType.toLowerCase() == 'airport' ? langdata && langdata.Airport : oneviewType.toLowerCase() == 'border' ? langdata && langdata.Border : 'pipeline';
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const [wellId, setWellId] = useState({id:null,name:''});
	const [wellModal, setWellModal] = useState(false);
	const [wellCoordinatesurl, setWellCoordinatesurl] = useState(null);	
	const [meshData, setMeshData] = useState({ x: [], y: [], z: [], color: [] });
	const [leakData, setLeakData] = useState({ x: [], y: [], z: [] });
	const [meshSpin, setMeshSpin] = useState(false);	
	
	const items = [
		{
			key: '1',
			label: `${langdata && langdata.EventDetails ? langdata.EventDetails :'Event Details'}`,
		}
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		getRegion(authtoken);
		if (map_type && map_type === 'leaflet') {
			handleGetMapdata(authtoken,null,null,null);     
		}
		if(oneviewType.toLowerCase() == 'well'){
			getWellInfo(authtoken);
		}

	},[authtoken]);

	useEffect(() => {
		if(!state) {
			navigate(-1);
		}
	},[authtoken]);

	const pipeline = [];
	const center=[];
	const event_markers = [];
	// const odmeter=[];
	// const optionalpath = [];
	// const markers = [];
	// const pidsmarker=[];

	center.push(state && state.data && state.data.latitude, state && state.data && state.data.longitude);
	mapdata &&  mapdata.map((item)=>{
		if(item != null){
			pipeline.push([item.latitude,item.longitude]);
		}
	});

	const tempMapArray = {};

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});


	// mapdata &&  mapdata.map((item,index)=>{
	//   if(item != null) {
	//     odmeter.push([item.latitude,item.longitude])
	//   }

	// });
  
	// mapdata &&  mapdata.map((item,index)=>{
	//   if (item != null) {
	//     optionalpath.push([item.latitude,item.longitude])
	//   }
	// });

	// mapdata &&  mapdata.map((item,index)=>{
	//   if (item != null) {
	//     markers.push({
	//       geocode:[item.latitude,item.longitude],
	//       pop:"CH"
	//     },)
	//   }
	// });
  
	// mapdata &&  mapdata.map((item,index)=>{
	//   if (item != null) {
	//     if(index==0){
	//       pidsmarker.push({
	//         geocode:[item.latitude,item.longitude],
	//         pop:"PID 1"
	//       },)
	//     }
	//   }
	// });

	let allPidsDatas =[];

	if(allPidsData.length>0) {
		allPidsData && allPidsData.map((item)=>{
			allPidsDatas.push({
				geocode:[item.latitude, item.longitude], 
				pop:item.label
			});
		});
	
	}

	if(state && state.data){

		eventsicons && eventsicons.map((icondata)=>{
			if(state.data.eventType==icondata.type){
				let severity = state.data.eventConfidence >= 0.9 && state.data.eventConfidence <= 1 ? 3 : state.data.eventConfidence >= 0.8 && state.data.eventConfidence < 0.9 ? 2 : state.data.eventConfidence >= 0.5 && state.data.eventConfidence < 0.8 ? 1 : 0;
				event_markers.push({
					geocode:[state.data.latitude, state.data.longitude],
					pop:state.data.eventType,
					id:state.data.id,
					// alarmSeverity:item.alarmSeverity,
					// eslint-disable-next-line no-undef
					ic: new L.icon({
						iconUrl: icondata.severity[parseInt(severity)],
						iconSize: [30, 40],
						iconAnchor:[18, 36]
					})
				},);
			}
		});
	}
	
	const wells = [];

	wellinfo && wellinfo.map((item) => {
		if(item.latitude && item.longitude){			
			wells.push({
				id:item.id,
				geocode:[item.latitude,item.longitude],
				pop: item.name.toUpperCase()
			},);	
		}
	});
	const wellicon = new Icon({
		// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
		iconUrl:require('../../images/oil-well.png'),
		iconSize: [40, 40],
		iconAnchor:[18, 36]
	});

	const mapKey = (value, type,name) => {
		if(type=='well'){
			setWellModal(true);
			setWellId({id:value, name:name});
		}

	};

	const handleModelCancel = () => {
		setWellModal(false);

	};


	useEffect(() => {
		if(wellId.id){

			getWellCoordinates();
		}

		// setWellId(tempWellId);
		// setSocketUrl(tempurl);

	},[wellId]);

	const getWellCoordinates =()=>{		

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellcooridnatesdata?wellId=${wellId.id}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					
					setWellCoordinatesurl(response.data.url);
				}else{
					
					setWellCoordinatesurl(null);
				}
			})
			.catch((error) => {
				setWellCoordinatesurl(null);
				
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});
	};

	useEffect(() => {
		setMeshSpin(true);
		
		if(wellCoordinatesurl){

		
			// Load cylinder mesh coordinates with Jet colors
			Papa.parse(wellCoordinatesurl, {
				download: true,
				header: true,
				complete: (results) => {
					const data = results.data;
					setMeshData({
						x: data.map(row => parseFloat(row.x)),
						y: data.map(row => parseFloat(row.y)),
						z: data.map(row => parseFloat(row.z)),
						color: data.map(row => `rgb(${Math.round(parseFloat(row.r) * 255)}, ${Math.round(parseFloat(row.g) * 255)}, ${Math.round(parseFloat(row.b) * 255)})`)
					});
					setMeshSpin(false);
				}
			});
			Papa.parse(leak, {
				download: true,
				header: true,
				complete: (results) => {
					const data = results.data;
					setLeakData({
						x: data.map(row => parseFloat(row.x)),
						y: data.map(row => parseFloat(row.y)),
						z: data.map(row => parseFloat(row.z)),
					});
				}
			});
		}else{
			setMeshSpin(false);
			setMeshData({});
		}
	}, [wellCoordinatesurl,wellId]);

	const numPointsPerCircle = 100;
	const numCircles = 100;
	const i = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => Math.floor(index / numPointsPerCircle) * numPointsPerCircle + index % numPointsPerCircle);
	const j = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => Math.floor(index / numPointsPerCircle) * numPointsPerCircle + (index % numPointsPerCircle + 1) % numPointsPerCircle);
	const k = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => i[index] + numPointsPerCircle);
	// const l = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => j[index] + numPointsPerCircle);

	const wellData = [
		{
			type: 'mesh3d',
			x: meshData.x,
			y: meshData.y,
			z: meshData.z,
			i: i,
			j: j,
			k: k,
			facecolor: meshData.color,  // Apply color
			colorscale: 'Jet',  // Ensure this is the correct scale for your color data
			showscale: true,
			hoverinfo: 'none',  // Customize the hover info displayed
			text:meshData &&  meshData.z && meshData.z.map(Math.floor),
			// hoverlabel: {
			// 	bgcolor: meshData.color,  // Background color of the tooltip
			// 	font: {
			// 		color: 'white'  // Text color in the tooltip
			// 	}
			// },
			hovertemplate : 'Depth : %{text}<extra></extra>',
			// reversescale:true
		},
		{
			type: 'scatter3d',
			mode: 'markers',
			x: leakData.x,
			y: leakData.y,
			z: leakData.z,
			marker: { size: 5, color: 'red' },
			name: 'Leak Detection',
			hovertemplate : 'Leak Depth : %{z}<extra></extra>',
		}
		
		// {
		//   type: 'scatter3d',
		//   mode: 'markers',
		//   x: leakData.x,
		//   y: leakData.y,
		//   z: leakData.z,
		//   marker: { size: 5, color: 'red' },
		//   name: 'Leak Detection'
		// }
	];

	const wellLayout = {
		scene: {
			xaxis: { title: 'X' },
			yaxis: { title: 'Y' },
			zaxis: { title: 'Depth (m)', 
				autorange: 'reversed',
				tickmode: 'auto', tickvals:  'auto',
				// dtick : depthRangeFrom && depthRangeTo ? (depthRangeFrom - depthRangeTo)/10 : null,
				// nticks : 10,
				// ticktext: ['3000 m', '2000 m', '1000 m', '0 m'],
				// range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ 3000 , 0 ],
			},

			camera:{
				
				center: {
					'x': 0,
					'y': 0,
					'z': 0
				},
				eye: {
					'x': 1.4,
					'y': 1,
					'z': 0
				},
				up: {
					'x': 0,
					'y': 0,
					'z': 1
				}

			}
		},
		title: {
			text: wellId && wellId.name,
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	
	};

	const wellKey = (value) => {		
		setWellId({id:value.id, name:value.pop});
		setWellModal(true);
	};

	return (
		<>
			{state ?
				<>
					{/* <Row style={{justifyContent:'right', display:'flex'}}>

				<Button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} style={{marginRight:'20px'}} >Full Size</Button>
			</Row> */}
					{size && size === '200px' ? 
						<Content>
							<Row gutter={{
								xs: 8,
								sm: 16,
								md: 24,
								lg: 32,
							}} style={{margin:'10px'}} >

								<Col className="gutter-row" span={24}>
									<Card>
										<Breadcrumb
											items={[
												{
													href: '/dashboard',
													title: <HomeOutlined />,
												},
												{
													title: ( state.path === 'realtime-events' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.RealtimeEvents ? langdata.RealtimeEvents :'Realtime Events'}</a> :  state.path === 'event-analytics' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.EventAnalytics ? langdata.EventAnalytics :'Event Analytics'}</a> : <a onClick={() => {navigate(-1);}}>{langdata && langdata.EventsReport ? langdata.EventsReport :'Events Report'}</a> )
												},
												{
													title: `${langdata && langdata.EventDetails ? langdata.EventDetails :'Event Details'}`,
												},
											]}
											style={{ marginBottom : '10px'}}
										/>
										{map_type && map_type !== 'leaflet' ?
											< GoogleMap eventDetailsData = {state.data} height = {'30vh'} onClick = {mapKey} />
									
											: 
											mapdata && mapdata ? 
												<div className="App" style={{textAlign: 'left'}}>
													<MapContainer center={center} zoom={17} scrollWheelZoom={false} style={{height:size}}>
														<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenOutlined /></button>

														<TileLayer
															attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
															url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
														/>
														<LayersControl position="topright" >
															{oneviewType.toLowerCase() == 'well' ? 
																<>
																	<LayersControl.Overlay  checked name={langdata && langdata.WELL ? langdata.WELL :'WELL'}>
																		<MarkerClusterGroup>
			
																			{wells && wells.map((marker,index)=>
																				<Marker key={`well-${index}`} position={marker && marker.geocode} icon={wellicon}
																					eventHandlers={{
																						click: () => {
																							wellKey(marker);
																						},
																					}}>
																					<Popup>
																						{marker.pop}
																					</Popup>
																					{/* <Tooltip>{marker.pop}</Tooltip> */}
																				</Marker>
			
																			)}       
																		</MarkerClusterGroup>
			
																	</LayersControl.Overlay>

																	<MarkerClusterGroup>
																		{allPidsDatas && allPidsDatas.map((marker,index)=>
																			<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
																				<Popup>
																					{marker.pop}
																				</Popup>
																				{/* <Tooltip>{marker.pop}</Tooltip> */}
																			</Marker>

	

																		)}       
																	</MarkerClusterGroup>

																	{event_markers && event_markers.map((marker,index)=>
																		<Marker key={`eventmarker-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}  

																</>
																:
																<>
																	<MarkerClusterGroup>
																		{allPidsDatas && allPidsDatas.map((marker,index)=>
																			<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
																				<Popup>
																					{marker.pop}
																				</Popup>
																				{/* <Tooltip>{marker.pop}</Tooltip> */}
																			</Marker>

	

																		)}       
																	</MarkerClusterGroup>
																	{/* <Marker key={'eventmarker'} position={[state && state.data && state.data.latitude, state && state.data && state.data.longitude]} icon={defaulticon}>
														<Popup>
															{state && state.data && state.data.chainage}
														</Popup>
													</Marker> */}

																	{event_markers && event_markers.map((marker,index)=>
																		<Marker key={`eventmarker-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}     
              
																	<LayersControl.Overlay  checked name={oneviewtypeLabel ? oneviewtypeLabel :'Pipeline'}>
																		<MarkerClusterGroup>
																			<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
																				{tempMapArray && Object.values(tempMapArray).map((item) => {
																					let pipeline = [];
																					item.map((data) => {
																						pipeline.push({lat : data.latitude,lng : data.longitude});
																					});
																					return (
																						<>
																							<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
																						</>
																					);
																				})}
																			</Pane>
																		</MarkerClusterGroup>
																	</LayersControl.Overlay>


																	<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder :'Pipeline Border'}>
																		<MarkerClusterGroup>
																			{tempMapArray && Object.values(tempMapArray).map((item) => {
																				let pipelineBorder = [];
																				item.map((data) => {
																					pipelineBorder.push({lat : data.latitude,lng : data.longitude});
																				});
																				return (
																					<>
																						<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
																					</>
																				);
																			})}
																		</MarkerClusterGroup>
																	</LayersControl.Overlay>
																</>
															}
														</LayersControl>
													</MapContainer>
												</div>
												:null
										}
					
										<Tabs defaultActiveKey="1" items={items} />
										<Row gutter={{
											xs: 8,
											sm: 16,
											md: 24,
											lg: 32,
										}}
										style={{
											margin: '10px',
											padding:'10px',
											background: '#FFFFFF',
											alignItems:'center',
										}}
										>
											<Col span={12}>
												<Typography>{langdata && langdata.Id ? langdata.Id :'Id'}: {state.data.id}</Typography>
												<Typography>{langdata && langdata.Activity ? langdata.Activity :'Activity'}: <b>{state.data.eventType}</b></Typography>
												<Typography>{langdata && langdata.Date ? langdata.Date :'Date'}: <b>{moment(moment.utc( state.data.datetime).toDate(), 'YYYY-MM-DD HH:mm:ss').format(settingsdateformat + ' ' + settingstimeformat)}</b>
												</Typography>
												<Typography>{langdata && langdata.Region ? langdata.Region :'Region'}: {state.data && state.data.regioninfoId && regiondata && regiondata.map((region) => { if (state.data.regioninfoId === region.id) { return region.regionName.toUpperCase();} })}</Typography>
												<Typography>{langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}: {state.data && state.data.pidsinfoId && allPidsData && allPidsData.map((pids) => { if (state.data.pidsinfoId === pids.value){ return pids.label.toUpperCase();} }) }</Typography>
								
												<Typography>{langdata && langdata.Channel ? langdata.Channel :'Channel'}: {state.data.eventChannel}</Typography>
												<Typography>{langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}: {state.data.chainage}</Typography>
												<Typography>{langdata && langdata.Fiberlength ? langdata.Fiberlength :'Fiber Length'}: {state.data.odInMeter}{' m'}</Typography>
												<Typography>{langdata && langdata.Duration ? langdata.Duration :'Duration'}: {(state.data.duration / 60).toFixed(2)} Min</Typography>
												<Typography>{langdata && langdata.EventProbability ? langdata.EventProbability :'Event Probability'}: <b>{((state.data.eventConfidence)*100).toFixed(2)}%</b></Typography>
											</Col>
											
											<Col span={12}> 
												<img src={state && state.data.activityUrl} alt={`${state && state.data.eventType}`} align="right" />
											</Col>
											{/* <Col span={12}>

												{state && state.data.activityUrl === 'Manual Digging' ? 
													<img src={mapImg} alt="manual digging" align="right" /> : 
													state && state.data.eventType === 'Machine Digging' ? 
														<img src={mapImgMachine} alt="Machine" align="right" /> : 
														state && state.data.eventType === 'Tunneling' ? 
															<img src={mapImgTunneling} alt="Tunneling" align="right" /> : 
															state && state.data.eventType === 'Vehicle Movement' ? 
																<img src={VehicleModal} alt="Tunneling" align="right" /> : 
																state && state.data.eventType === 'Drums Beating' ? 
																	<img src={DrumModal} alt="Tunneling" align="right" /> : 
																	state && state.data.eventType === 'Clamping' ? 
																		<img src={ClampingModal} alt="Tunneling" align="right" /> : 
																		state && state.data.eventType === 'Manual Digging - Deeper (1.5 to 3 feets)' ? 
																			<img width="300px" src={mapImgDeep} alt="Manual Digging - Deeper (1.5 to 3 feets)" align="right" /> : 
																			state && state.data.eventType === 'Man Movement' ? 
																				<img src={modalMan} alt="Man Movement" align="right" /> : 
																				<img src={modalDefault} alt="Unclassified" align="right" /> }

											</Col> */}
										</Row>
									</Card>
								</Col>

							</Row>
						</Content>
						:
						mapdata && mapdata ?
							<div className="App" style={{textAlign: 'left'}}>
        
								<MapContainer center={center} zoom={17} scrollWheelZoom={false} >
									<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenExitOutlined /></button>
									<TileLayer
										attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
										url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									/>
									<LayersControl position="topright" >
										{oneviewType.toLowerCase() == 'well' ? 
											<>
												<LayersControl.Overlay  checked name={langdata && langdata.WELL ? langdata.WELL :'WELL'}>
													<MarkerClusterGroup>
			
														{wells && wells.map((marker,index)=>
															<Marker key={`well-${index}`} position={marker && marker.geocode} icon={wellicon}
																eventHandlers={{
																	click: () => {
																		wellKey(marker);
																	},
																}}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>
			
														)}       
													</MarkerClusterGroup>
			
												</LayersControl.Overlay>

												<MarkerClusterGroup>
													{allPidsDatas && allPidsDatas.map((marker,index)=>
														<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
															<Popup>
																{marker.pop}
															</Popup>
															{/* <Tooltip>{marker.pop}</Tooltip> */}
														</Marker>

	

													)}       
												</MarkerClusterGroup>

												{event_markers && event_markers.map((marker,index)=>
													<Marker key={`eventmarker-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
														<Popup>
															{marker.pop}
														</Popup>
														{/* <Tooltip>{marker.pop}</Tooltip> */}
													</Marker>

												)}  
																	
											</>
											:
											<>
												<MarkerClusterGroup>
													{allPidsDatas && allPidsDatas.map((marker,index)=>
														<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
															<Popup>
																{marker.pop}
															</Popup>
															{/* <Tooltip>{marker.pop}</Tooltip> */}
														</Marker>

	

													)}       
												</MarkerClusterGroup>
												{/* <Marker key={'eventmarker'} position={[state && state.data && state.data.latitude, state && state.data && state.data.longitude]} icon={defaulticon}>
									<Popup>
										{state && state.data && state.data.chainage}
									</Popup>
								</Marker> */}
												{event_markers && event_markers.map((marker,index)=>
													<Marker key={`eventmarker-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
														<Popup>
															{marker.pop}
														</Popup>
														{/* <Tooltip>{marker.pop}</Tooltip> */}
													</Marker>

												)}
												<LayersControl.Overlay  checked name={oneviewtypeLabel ? oneviewtypeLabel :'Pipeline'}>
													<MarkerClusterGroup>
														<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
															{tempMapArray && Object.values(tempMapArray).map((item) => {
																let pipeline = [];
																item.map((data) => {
																	pipeline.push({lat : data.latitude,lng : data.longitude});
																});
																return (
																	<>
																		<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
																	</>
																);
															})}
														</Pane>
													</MarkerClusterGroup>
												</LayersControl.Overlay>


												<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder :'Pipeline Border'}>
													<MarkerClusterGroup>
														{tempMapArray && Object.values(tempMapArray).map((item) => {
															let pipelineBorder = [];
															item.map((data) => {
																pipelineBorder.push({lat : data.latitude,lng : data.longitude});
															});
															return (
																<>
																	<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
																</>
															);
														})}
													</MarkerClusterGroup>
												</LayersControl.Overlay>
											</>
										}
									</LayersControl>
								</MapContainer>
  
							</div>:null
					}
				</>
				: '' }

			{/* well modal start */}

			<Modal
				open={wellModal}
				title={langdata && langdata.Well3d ? langdata.Well3d :'3d Well'}
				onOk={handleModelCancel}
				onCancel={handleModelCancel}
				footer={[
					// eslint-disable-next-line react/jsx-key
					// <Button type="primary" onClick={handleModelCancel}>
					// 	{langdata && langdata.Close ? langdata.Close :'Close'}
					// </Button>
				]}
				style={{backgroundColor:'transparent'}}
				width={800}
			>
				<Divider/>

				<Row
					gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}>
					<Col span={24} style={{ minHeight :'450px', display : 'flex', justifyContent : 'center' }}>          
									
						{/* <Card style={{width:'100%', height : '100%'}}> */}

						{/* {data3d && data3d && data3d.length > 0 ?
							
<> */}
						<Spin  spinning={meshSpin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
			
							{/* <button style={{marginTop:'10px', marginRight:'5px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'3dwell');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button> */}
							{(Object.keys(meshData).length !== 0) ?
								<Plot
									style={{ width: '750px', height: '600px',minHeight:'450px' }}
									config={{
										responsive: true,
										// displayModeBar: true,
										displaylogo : false,
										modeBarButtonsToRemove: [
											'pan2d',
											'zoom2d',
											'select2d',
											'zoomIn2d',
											'zoomOut2d',
											'autoScale2d',
											'resetScale2d',
											'hoverClosestCartesian',
											'hoverCompareCartesian',
											'zoom3d',
											'pan3d',
											'resetViews3d',
											'hoverClosest3d',
											'hoverCompare3d',
											'orbitRotation',
											'tableRotation',
											'resetCameraDefault3d',
											'resetCameraLastSave3d',
											'lasso2d'
										],

									}}
									data={wellData}
									layout={wellLayout}
								/>
								: 
											
								<Typography>{langdata && langdata.NoData ? langdata.NoData : 'No Data'}</Typography>
							}
						</Spin>
						{/* </>
:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '500px', minHeight:'500px', backgroundColor:'#FFFFFF' }} />} */}


						{/* </Card> */}

					</Col>
							
				</Row>
			</Modal>

			{/* well modal end */}
		</>
	);
}