export const MAIL_TEMPLATE = 'MAIL_TEMPLATE';
export const MAIL_TEMPLATE_SUCCESS = 'MAIL_TEMPLATE_SUCCESS';
export const MAIL_TEMPLATE_ERROR = 'MAIL_TEMPLATE_ERROR';

export const MAIL_TEMPLATE_ADD = 'MAIL_TEMPLATE_ADD';
export const MAIL_TEMPLATE_ADD_SUCCESS = 'MAIL_TEMPLATE_ADD_SUUCESS';
export const MAIL_TEMPLATE_ADD_ERROR = 'MAIL_TEMPLATE_ADD_ERROR';

export const MAIL_TEMPLATE_EDIT = 'MAIL_TEMPLATE_EDIT';
export const MAIL_TEMPLATE_EDIT_SUCCESS = 'MAIL_TEMPLATE_EDIT_SUCCESS';
export const MAIL_TEMPLATE_EDIT_ERROR = 'MAIL_TEMPLATE_EDIT_ERROR';

export const MAIL_TEMPLATE_DELETE = 'MAIL_TEMPLATE_DELETE';
export const MAIL_TEMPLATE_DELETE_SUCCESS = 'MAIL_TEMPLATE_DELETE_SUCCESS';
export const MAIL_TEMPLATE_DELETE_ERROR = 'MAIL_TEMPLATE_DELETE_ERROR';