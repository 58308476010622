import { ESCALATION_MATRIX_DELETE, ESCALATION_MATRIX_DELETE_ERROR, ESCALATION_MATRIX_DELETE_SUCCESS, ESCALATION_MATRIX_DETAILS, ESCALATION_MATRIX_LIST, ESCALATION_MATRIX_LIST_ERROR, ESCALATION_MATRIX_LIST_SUCCESS, ESCALATION_MATRIX_UPDATE, ESCALATION_MATRIX_UPDATE_ERROR, ESCALATION_MATRIX_UPDATE_SUCCESS, SMTP_LIST, SMTP_LIST_ERROR, SMTP_LIST_SUCCESS, SMTP_UPDATE, SMTP_UPDATE_ERROR, SMTP_UPDATE_SUCCESS, USER_LIST, USER_LIST_ERROR, USER_LIST_SUCCESS } from './constants';

export default function escalationmatrix(state, action) {

	let escalationlist =[];
	let users = [];

	switch (action.type) {
  
	case ESCALATION_MATRIX_LIST:
		return { ...state, pending: true };
	case ESCALATION_MATRIX_LIST_SUCCESS:
		action.payload && action.payload.map((item)=>{
			escalationlist.push({
				id:item.id,
				type:item.usertype,
				notificationtype:item.notificationType,
				pipeline:item.pipelineId,
				email:item.email,
				hours:item.hours,
				name:item.firstName ? item.firstName + ' ' + item.lastName : ''
			});
		});
		return { ...state, escalation_matrix: escalationlist, pending: false };
	case ESCALATION_MATRIX_LIST_ERROR:
		return { ...state, error: true, pending: false };
	case ESCALATION_MATRIX_DETAILS:
		return { ...state, details: action.payload, error: true };

	case USER_LIST:
		return { ...state, pending: true };
	case USER_LIST_SUCCESS:
		action.payload && action.payload.map((item)=>{
			users.push({
				value:item.id,
				type:item.permissions,
				firstName:item.firstName,
				pipeline:item.pipelineId,
				label:item.email,
				lastName:item.lastName,
				id:item.id
                
			});
		});
		return { ...state, users: users, pending: false };
	case USER_LIST_ERROR:
		return { ...state, error: true, pending: false };

	case ESCALATION_MATRIX_UPDATE:
		return { ...state, pending: true };
	case ESCALATION_MATRIX_UPDATE_SUCCESS:
		return { ...state, matrixupdate: action.payload, pending: false };
	case ESCALATION_MATRIX_UPDATE_ERROR:
		return { ...state, error: true, pending: false };

	case ESCALATION_MATRIX_DELETE:
		return { ...state, pending: true };
	case ESCALATION_MATRIX_DELETE_SUCCESS:
		return { ...state, matrixdelete: action.payload, pending: false };
	case ESCALATION_MATRIX_DELETE_ERROR:
		return { ...state, error: true, pending: false };

	case SMTP_LIST:
		return { ...state, pending: true };
	case SMTP_LIST_SUCCESS:
		return { ...state, smtp_data: action.payload[0], pending: false };
	case SMTP_LIST_ERROR:
		return { ...state, error: true, pending: false };

	case SMTP_UPDATE:
		return { ...state, pending: true };
	case SMTP_UPDATE_SUCCESS:
		return { ...state, smtp_update: action.payload, pending: false };
	case SMTP_UPDATE_ERROR:
		return { ...state, error: true, pending: false };
       

      
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}