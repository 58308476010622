/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { Row, Col, Typography, Button, message, Tooltip } from 'antd';
import { DownloadOutlined, SaveOutlined } from '@ant-design/icons';
import CsvDownloader from 'react-csv-downloader';

import axios from 'axios';
import { datetimeCovertertolocal } from '../../datetime-converter';
import { useAuth } from '../../Context';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';


export function BatchDownloader(props) {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const envstrgebatch = process.env.REACT_APP_BATCH_SIZE == undefined || process.env.REACT_APP_BATCH_SIZE == 'undefined' ? 1000 : process.env.REACT_APP_BATCH_SIZE;
	const BATCH_SIZE = localStorage.getItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`) == 'null' ?  parseInt(envstrgebatch) : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	// eslint-disable-next-line react/prop-types
	const TOTAL = props.total;
	const BATCHES = TOTAL / BATCH_SIZE;
	const batches = [];
    
	let startIndex = 1;
	let index = 0;
    
	for (let i=0; i< BATCHES; i++) {
		let start = startIndex;
		let end = (startIndex + BATCH_SIZE-1);
      
		end = (end >= TOTAL) ? TOTAL : end;
      
		let batch = { index: index, start: start, end: end, fetched: false };
  
		batches.push(batch);
      
		startIndex += BATCH_SIZE;
		index++;
	}
  
	return <>
		<Row gutter={16} style={{
			padding: '5px',
			marginTop: '10px',
			maxHeight: '250px',
			overflowY: 'scroll'
		}}>
			<Col span={24}>
				<Row style={{
					backgroundColor: '#d3d3d3',
					alignItems: 'center',
					marginTop: '5px',
					marginBottom: '5px',
					padding: '8px'
				}}>
					{batches.map((batch, index) =>
						<>
							<Col span={22} style={{ marginBottom: '6px' }}>
								<Typography>{langdata && langdata.Batch ? langdata.Batch:'Batch'} {index+1} : {langdata && langdata.Rows ? langdata.Rows:'Rows'} {batch.start} {langdata && langdata.to ? langdata.to:'to'} {batch.end}</Typography>
							</Col>
							<Col span={2} style={{ marginBottom: '6px' }}>
								<BatchDownloaderItem index={index} batch={batch} url={props.url} title={langdata && langdata.DownloadReport ? langdata.DownloadReport:'Download Report'}/>
							</Col>
						</>
					)}
				</Row>
			</Col>
		</Row>
	</>;
}
  
export function BatchDownloaderItem(props) {

	// eslint-disable-next-line react/prop-types
	const url = props.url ? props.url : null;
	const [loading, setLoading] = useState(false);
	const [fetched, setFetched] = useState(false);
	const [data, setData] = useState({});
  
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {getRegion, regiondata} = useAuth();

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));

	const envstrgebatch = process.env.REACT_APP_BATCH_SIZE == undefined || process.env.REACT_APP_BATCH_SIZE == 'undefined' ? 1000 : process.env.REACT_APP_BATCH_SIZE;
	const BATCH_SIZE = localStorage.getItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`) == 'null' ?  parseInt(envstrgebatch) : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_BATCH_SIZE`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	useEffect(() => {
		getRegion(authtoken);
	},[authtoken]);

  
	const columns = [
		{
			id: 'alarm_prm_id',
			displayName: `${langdata && langdata.Id ? langdata.Id :'Id'}`,
		},
		{
			id: 'eventType',  
			displayName: `${langdata && langdata.Activity ? langdata.Activity :'Activity'}`,
		},
		{
			id: 'region',
			displayName: `${langdata && langdata.Region ? langdata.Region :'Region'}`,
		},
		{
			id: 'pidsname',  
			displayName: `${langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}`,
		},
		{
			id: 'channel',
			displayName: `${langdata && langdata.Channel ? langdata.Channel :'Channel'}`,
		},
		{
			id: 'isparent',
			displayName: `${langdata && langdata.Type ? langdata.Type :'Type'}`,
		},

		{
			id: 'description',
			displayName: `${langdata && langdata.Description ? langdata.Description :'Description'}`,
		},
		
		{
			id: 'date',
			displayName: `${langdata && langdata.Date ? langdata.Date :'Date'}`,
		},
		{
			id: 'duration',
			displayName: `${langdata && langdata.Duration ? langdata.Duration :'Duration'}`,
		},
		
		{
			id: 'chainage',
			displayName: `${langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}`,
		},
		{
			id: 'fibrelength',
			displayName: `${langdata && langdata.FiberLength ? langdata.FiberLength :'Fiber Length (m)'}`,
		},
		{
			id: 'gps',
			displayName: `${langdata && langdata.GPSCoordinates ? langdata.GPSCoordinates :'GPS Coordinates'}`,
		},
		{
			id: 'status',
			displayName: `${langdata && langdata.Status ? langdata.Status :'Status'}`,
		},
		{
			id: 'severity',
			displayName: `${langdata && langdata.Severity ? langdata.Severity :'Severity'}`,
		}
	];
  
	const fetchData = (index) => {
		setLoading(true);
  
		let skip =  (index>=1) ? (BATCH_SIZE * index) : (0);
  
		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: url.base + `?limit=${BATCH_SIZE}&skip=${skip}&` + url.params,
			timeout: extendTimeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data: url.data

		};
      
		axios.request(config) .then((response) => {
			if (response && response.data) {
				let datas = [];

				if (response.data && response.data.data) {
					response.data.data.map( (item) => {
						let data = {};

						const datetime = datetimeCovertertolocal(item.datetime);
						const newdate = datetime.localdate + ' ' +  datetime.localtime;
						let region = regiondata && regiondata.find((region) => region.id == item.regioninfoId);

						const alarmSeverity = item.alarmSeverity ? item.alarmSeverity : 0;
						
						let severity = '';

						if (alarmSeverity == 3) {
							severity = 'Red';
						} else if (alarmSeverity == 2) {
							severity = 'Amber';
						} else if (alarmSeverity == 1) {
							severity = 'Green';
						} else {
							severity = 'Clear';
						}

						data.alarm_prm_id = item.alarm_prm_id ? parseInt(item.alarm_prm_id) : '';
						data.eventType = item.eventType ? item.eventType : '';
						data.pidsname = item.pidname.toUpperCase();
						data.description = item.ruleDesc ? item.ruleDesc.replaceAll(',',';') : '';
						data.date = newdate;   
						data.duration = item.duration ? (item.duration/60).toFixed(2) + ` ${langdata && langdata.Min ? langdata.Min:' Min'}` : 0 + ` ${langdata && langdata.Min ? langdata.Min:' Min'}`;
						data.channel = item.channel_name ? item.channel_name : '';
						data.chainage = item.locationDetails ? item.locationDetails.info : null;
						data.fibrelength = item.odMeter ? item.odMeter : '';
						data.severity = severity;
						data.status = item.status ? item.status : 0;
						data.region = region.regionName.toUpperCase(),
						data.gps = `Latitude: ${item.locationDetails.Lat} Longitude: ${item.locationDetails.Long}`,
						data.isparent = item.isParent ? 'Primary' : 'Related',


						datas.push(data);
					});
  
					setFetched(true);
					setData(datas);
					setLoading(false);
				} 
				else {
					setFetched(true);
					setData([]);
					setLoading(false);   
				}
			} else {
				setFetched(false);
				setData([]);
				setLoading(false);
			}
 
		}).catch((error) => {
			console.log(error);
        
			setFetched(false);
			setData([]);
			setLoading(false);
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
				});
			}
		});
	};
  
	return <>
		{ props && props.batch && fetched == false ? (
			<Tooltip title={langdata && langdata.FetchdatafromOneView ? langdata.FetchdatafromOneView:'Fetch data from OneView'}>
				<Button type="primary" shape="circle" loading = {loading} icon={<DownloadOutlined />} onClick={() => fetchData(props.index)}></Button>
			</Tooltip>
		) : (
			<>
				<CsvDownloader datas={data} filename={`${langdata && langdata.Alarmreport ? langdata.Alarmreport :'Alarm-report'}-${props.index}`} extension=".csv" columns={columns}> 
					<Tooltip title={langdata && langdata.Downloadtomycomputer ? langdata.Downloadtomycomputer:'Download to my computer'}>
						<Button type="primary" shape="circle" icon={<SaveOutlined />} style={{ backgroundColor: '#008000', border: '1px solid #008000' }}></Button>
					</Tooltip>
				</CsvDownloader>
			</>
		)}
      
	</>;
}