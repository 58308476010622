export const USER_TYPES_LIST = 'USER_TYPES_LIST';
export const USER_TYPES_LIST_SUCCESS = 'USER_TYPES_LIST_SUCCESS';
export const USER_TYPES_LIST_ERROR = 'USER_TYPES_LIST_ERROR';

export const USERS_LIST = 'USERS_LIST';
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
export const USERS_LIST_ERROR = 'USERS_LIST_ERROR';

export const USER_DETAILS = 'USER_DETAILS';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_ERROR = 'USER_DETAILS_ERROR';

export const SAVE_USER = 'SAVE_USER';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_ERROR = 'SAVE_USER_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const UPDATE_USER_NOTIFICATION = 'UPDATE_USER_NOTIFICATION';
export const UPDATE_USER_NOTIFICATION_SUCCESS = 'UPDATE_USER_NOTIFICATION_SUCCESS';
export const UPDATE_USER_NOTIFICATION_ERROR = 'UPDATE_USER_NOTIFICATION_ERROR';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_REST_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_ERROR = 'USER_REST_PASSWORD_ERROR';

export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_ERROR = 'USER_CHANGE_PASSWORD_ERROR';