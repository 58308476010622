
export const ADD_GRADE_MANAGEMENT_DETAILS = 'ADD_GRADE_MANAGEMENT_DETAILS';
export const ADD_GRADE_MANAGEMENT_DETAILS_SUCCESS = 'ADD_GRADE_MANAGEMENT_DETAILS_SUCCESS';
export const ADD_GRADE_MANAGEMENT_DETAILS_ERROR = 'ADD_GRADE_MANAGEMENT_DETAILS_ERROR';

export const UPDATE_GRADE_MANAGEMENT = 'UPDATE_GRADE_MANAGEMENT';
export const UPDATE_GRADE_MANAGEMENT_SUCCESS = 'UPDATE_GRADE_MANAGEMENT_SUCCESS';
export const UPDATE_GRADE_MANAGEMENT_ERROR = 'UPDATE_GRADE_MANAGEMENT_ERROR';

export const DELETE_GRADE_MANAGEMENT = 'DELETE_GRADE_MANAGEMENT';
export const DELETE_GRADE_MANAGEMENT_SUCCESS = 'DELETE_GRADE_MANAGEMENT_SUCCESS';
export const DELETE_GRADE_MANAGEMENT_ERROR = 'DELETE_GRADE_MANAGEMENT_ERROR';


export const GRADE_MANAGEMENT_LIST = 'GRADE_MANAGEMENT_LIST';
export const GRADE_MANAGEMENT_LIST_SUCCESS = 'GRADE_MANAGEMENT_LIST_SUCCESS';
export const GRADE_MANAGEMENT_LIST_ERROR = 'GRADE_MANAGEMENT_LIST_ERROR';
export const GRADE_MANAGEMENT_DETAILS = 'GRADE_MANAGEMENT_DETAILS';

