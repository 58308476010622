export const EVENT_TYPES_LIST = 'EVENT_TYPES_LIST';
export const EVENT_TYPES_LIST_SUCCESS = 'EVENT_TYPES_LIST_SUCCESS';
export const EVENT_TYPES_LIST_LIST_ERROR = 'EVENT_TYPES_LIST_LIST_ERROR';

export const EVENT_TYPE_DETAILS = 'EVENT_TYPE_DETAILS';
export const EVENT_TYPE_DETAILS_SUCCESS = 'EVENT_TYPE_DETAILS_SUCCESS';
export const EVENT_TYPE_DETAILS_ERROR = 'EVENT_TYPE_DETAILS_ERROR';

export const ADD_EVENT_TYPE_DETAILS = 'ADD_EVENT_TYPE_DETAILS';
export const ADD_EVENT_TYPE_DETAILS_SUCCESS = 'ADD_EVENT_TYPE_DETAILS_SUCCESS';
export const ADD_EVENT_TYPE_DETAILS_ERROR = 'ADD_EVENT_TYPE_DETAILS_ERROR';

export const UPDATE_EVENT_TYPE = 'UPDATE_EVENT_TYPE';
export const UPDATE_EVENT_TYPE_SUCCESS = 'UPDATE_EVENT_TYPE_SUCCESS';
export const UPDATE_EVENT_TYPE_ERROR = 'UPDATE_EVENT_TYPE_ERROR';

export const DELETE_EVENT_TYPE = 'DELETE_EVENT_TYPE';
export const DELETE_EVENT_TYPE_SUCCESS = 'DELETE_EVENT_TYPE_SUCCESS';
export const DELETE_EVENT_TYPE_ERROR = 'DELETE_EVENT_TYPE_ERROR';
