export const CHANNELINFO_LIST = 'CHANNELINFO_LIST';
export const CHANNELINFO_LIST_SUCCESS = 'CHANNELINFO_LIST_SUCCESS';
export const CHANNELINFO_LIST_ERROR = 'CHANNELINFO_LIST_ERROR';

export const CHANNELINFO_DETAILS = 'CHANNELINFO_DETAILS';

export const CHANNELWIDTH_DETAILS = 'CHANNELWIDTH_DETAILS';

export const CHANNEL_CONVERTION_DETAILS = 'CHANNEL_CONVERTION_DETAILS';

export const CHANNEL_MATRICS_DETAILS = 'CHANNEL_MATRICS_DETAILS';

export const MODEL_NAMES = 'MODEL_NAMES';

export const GET_TERRAIN = 'GET_TERRAIN';
export const GET_TERRAIN_SUCCESS = 'GET_TERRAIN_SUCCESS';
export const GET_TERRAIN_ERROR = 'GET_TERRAIN_ERROR';

export const GET_EVENT_RULES = 'GET_EVENT_RULES';
export const GET_EVENT_RULES_SUCCESS = 'GET_EVENT_RULES_SUCCESS';
export const GET_EVENT_RULES_ERROR = 'GET_EVENT_RULES_ERROR';
export const GET_EVENT_RULES_DETAILS = 'GET_EVENT_RULES_DETAILS';