/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Card, Col, Row, Space, Typography, message } from 'antd';
import { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Content } from 'antd/es/layout/layout';
// import eventsImg from '../../images/Events.png';
import dashboarddatareducer from './reducer';
import { STS_DASHBOARD_LIST, STS_DASHBOARD_LIST_ERROR, STS_DASHBOARD_LIST_SUCCESS } from './constants';
import moment from 'moment';
import lw from '../../images/person-walking-arrow-right-solid.svg';
import totallw from '../../images/people-group-solid.svg';
import totalnpv from '../../images/car-on-solid.svg';
import compinsp from '../../images/person-circle-check-solid.svg';
import tottalinsp from '../../images/people-arrows-solid.svg';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';


// import { TLP_DASHBOARD_LIST, TLP_DASHBOARD_LIST_ERROR, TLP_DASHBOARD_LIST_SUCCESS } from './constants';
// import moment from 'moment';


export default function StsContent (props) {
	
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const navigate = useNavigate();
	const initalState = {stsdashboarddata : null, details: null, pending: false, error: false };
	const [stsData, dispatch] = useReducer(dashboarddatareducer, initalState);
	const regionId = props.regionId;
	const date = props.date;
	// const data={};

	const startdatetime = moment.utc(date.setHours(0,0,0,0)).toISOString();
	const enddatetime = moment.utc(date.setHours(23,59,59,999)).toISOString();

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	const envRefreshTime = process.env.REACT_APP_REFRESH_TIME == undefined || process.env.REACT_APP_REFRESH_TIME == 'undefined' ? 180000 : process.env.REACT_APP_REFRESH_TIME;
	const refreshTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`) == 'null' ? parseInt(envRefreshTime) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`));

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	let allocationParams = '';
	if(admin_roles.includes(permission)){
		allocationParams = 'regionInfoId';
	}
	else{
		allocationParams = 'allocationId';
	}

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getStsData();
	},[authtoken, regionId, date]);

	useEffect(() => {
		const interval = setInterval(() => {
			// getStsData();
			navigate(0);
		}, refreshTime ); 
		return () => clearInterval(interval);
	}, []);

	const getStsData = () =>{

		dispatch({ type : STS_DASHBOARD_LIST});


		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/stsdashboard?${allocationParams}=${regionId}&startDatetime=${startdatetime}&endDatetime=${enddatetime}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: {
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type:STS_DASHBOARD_LIST_SUCCESS, payload:response.data});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({type:STS_DASHBOARD_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});
	};


	const handleNaviagte =(e)=>{

		if(e=='linewalker'){
			navigate(( '/allocations'),{ state: { data: {params:'', value:4, regionId : regionId}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '5');
		}
		else if(e=='inspections'){
			navigate(( '/inspections'),{ state: { data: {params:'', value:0, regionId : regionId, startdatetime : date, enddatetime : date}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '66');
		}
		else if(e=='npv'){
			navigate(( '/allocations'),{ state: { data: {params:'', value:7, regionId : regionId }, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '5');
		}
		else if(e=='Inprogress'){
			navigate(( '/inspections'),{ state: { data: {params:'&status=Inprogress', value:0, regionId : regionId, startdatetime : date, enddatetime : date}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '66');
		}
		else if(e=='Completed'){
			navigate(( '/inspections'),{ state: { data: {params:'&status=Completed', value:0, regionId : regionId, startdatetime : date, enddatetime : date}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '66');
		}
		
	};


	return(
		<>
			<Content>
				<Row gutter={{
					xs: 8,
					sm: 16,
					md: 24,
					lg: 32,
				}} style={{
					marginTop:'20px',
					marginLeft:'0px',
					marginRight:'0px',
					marginBottom: '20px',
				}}>
					<Space>
						<Col onClick={()=>{handleNaviagte('linewalker');}}  style={{marginBottom:'20px'}} span={6}> 
							<Card style={{ minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px',}}>
									{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={totallw} alt='event image' style={{ height : '35px' }}/>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.TOTALLINEWALKERS: 'TOTAL LINEWALKERS'}</Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {stsData && stsData.stsdashboarddata ? stsData.stsdashboarddata.totalLineWalkers : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>
					{ permission != 'CRO' ?
						<Space>
							<Col onClick={()=>{handleNaviagte('npv');}}  style={{marginBottom:'20px'}} span={6}> 
								<Card style={{ minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
									<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px',}}>
										{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
										<Col style={{ display : 'flex', justifyContent : 'center' }}>
											<img shape="square" src={totalnpv} alt='event image' style={{ height : '35px'}}/>
										</Col>
										<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
											<Typography>{langdata && langdata ? langdata.TOTALNPVS: 'TOTAL NPVS'}</Typography>
										</Col>
										<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
											<Typography style={{ fontSize : '25px'}}><b> {stsData && stsData.stsdashboarddata ? stsData.stsdashboarddata.totalNPVs : 0}</b> </Typography>
										</Col>
									</Card.Grid>
								</Card>
							</Col>
						</Space>
						: null }
					<Space>
						<Col onClick={()=>{handleNaviagte('inspections');}}  style={{marginBottom:'20px'}} span={6}>
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}}>
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px',}}>
									{/* <Statistic
								title="ACKNOWLEDGEMENTS"
								value={acknowledgementcount}
								precision={0}
								// valueStyle={{ color: 'crimson' }}
								prefix={<OneToOneOutlined />}
								suffix=""
							/> */}														
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={tottalinsp} alt='acknowledgement count' style={{ height : '35px' }}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.TOTALINSPECTIONS: 'TOTAL INSPECTIONS'}</Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {stsData && stsData.stsdashboarddata ? stsData.stsdashboarddata.totalInspections :0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>
					<Space>
						<Col onClick={()=>{handleNaviagte('Inprogress');}} style={{marginBottom:'20px'}} span={6}> 
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px',}}>
									{/* <Statistic
								title="RESOLUTIONS"
								value={totalResolved}
								precision={0}
								// valueStyle={{ color: 'blue' }}
								prefix={<BellOutlined />}
								suffix=""
							/> */}
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={lw} alt='resolved count' style={{height : '35px'}}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.ACTIVEINSPECTIONS: 'ACTIVE INSPECTIONS'}</Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {stsData && stsData.stsdashboarddata ? stsData.stsdashboarddata.activeInspections : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>
					<Space>
						<Col onClick={()=>{handleNaviagte('Completed');}} style={{marginBottom:'20px'}} span={6}> 
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}}>
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px',}}>
									{/* <Statistic
								title="DAY UNRESOLVED ALARMS"
								value={todayUnresolved}
								precision={0}
								// valueStyle={{ color: 'gold' }}
								prefix={<BellOutlined />}
								suffix=""
							/> */}
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={compinsp} alt='unresolved count' style={{ height : '35px'}}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.COMPLETEDINSPECTIONS: 'COMPLETED INSPECTIONS'}</Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {stsData && stsData.stsdashboarddata ? stsData.stsdashboarddata.completedInspections : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>
				</Row>



			</Content>
		</>
	);
}