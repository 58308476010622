export const WELL_TYPE_LIST = 'WELL_TYPE_LIST';
export const WELL_TYPE_LIST_SUCCESS = 'WELL_TYPE_LIST_SUCCESS';
export const WELL_TYPE_LIST_ERROR = 'WELL_TYPE_LIST_ERROR';

export const WELL_TYPE_DETAILS = 'WELL_TYPE_DETAILS';
export const WELL_TYPE_DETAILS_SUCCESS = 'WELL_TYPE_DETAILS_SUCCESS';
export const WELL_TYPE_DETAILS_ERROR = 'WELL_TYPE_DETAILS_ERROR';

export const UPDATE_WELL_TYPE = 'UPDATE_WELL_TYPE';
export const UPDATE_WELL_TYPE_SUCCESS = 'UPDATE_WELL_TYPE_SUCCESS';
export const UPDATE_WELL_TYPE_ERROR = 'UPDATE_WELL_TYPE_ERROR';