import moment from 'moment';
import { FRACTURING_LOCATION_DATA, FRACTURING_LOCATION_DATA_ERROR, FRACTURING_LOCATION_DATA_SUCCESS, MICRO_SEISMIC_INGRSS_GRAPH_CONFIG, MICROSEISMIC_GRAPH, MICROSEISMIC_WELL_FRACTURING_CREATE, MICROSEISMIC_WELL_FRACTURING_CREATE_ERROR, MICROSEISMIC_WELL_FRACTURING_CREATE_SUCCESS, MICROSEISMIC_WELL_FRACTURING_DELETE, MICROSEISMIC_WELL_FRACTURING_DELETE_ERROR, MICROSEISMIC_WELL_FRACTURING_DELETE_SUCCESS, MICROSEISMIC_WELL_FRACTURING_LIST, MICROSEISMIC_WELL_FRACTURING_LIST_ERROR, MICROSEISMIC_WELL_FRACTURING_LIST_SUCCESS } from './constants';

export default function microseismicData(state, action) {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);
	const microSeismic_data = [];
	switch (action.type) {
	case MICROSEISMIC_WELL_FRACTURING_LIST : 
		return { ...state, loading : true, error : false};
	case MICROSEISMIC_WELL_FRACTURING_LIST_SUCCESS : 
		action.payload && action.payload.data.map((item) => {
			const dateTimeFrom = moment.utc(item.dateTimeFrom).toDate();
			dateTimeFrom.toString();
			const newDateTimeFrom = moment(dateTimeFrom, 'YYYY-MM-DD HH:mm:ss').format(settingsdateformat + ' ' + settingstimeformat);
			const dateTimeTo = moment.utc(item.dateTimeTo).toDate();
			dateTimeFrom.toString();
			const newDateTimeTo = moment(dateTimeTo, 'YYYY-MM-DD HH:mm:ss').format(settingsdateformat + ' ' + settingstimeformat);
			microSeismic_data.push({
				id : item.id,
				name : item.name,
				wellinfo : item.wellinfo.name,
				wellseismictypes : item.wellseismictypes.name,
				dateTimeFrom : newDateTimeFrom,
				dateTimeTo : newDateTimeTo,
				depthFrom : item.depthFrom,
				depthTo : item.depthTo,
				status : item.status,
				channelName : item.channelinfo.name
			});
            
		});
		return { ...state, microSeismic_data : microSeismic_data, loading : false, error : false};
	case MICROSEISMIC_WELL_FRACTURING_LIST_ERROR : 
		return { ...state, loading : false, error : true };
	case MICROSEISMIC_WELL_FRACTURING_CREATE :
		return { ...state, loading : true, error : false};
	case MICROSEISMIC_WELL_FRACTURING_CREATE_SUCCESS :
		return { ...state, loading : false, error : false};
	case MICROSEISMIC_WELL_FRACTURING_CREATE_ERROR :
		return { ...state, loading : false, error : true };
	case MICROSEISMIC_WELL_FRACTURING_DELETE :
		return { ...state, loading : true, error : false};
	case MICROSEISMIC_WELL_FRACTURING_DELETE_SUCCESS :
		return { ...state, loading : false, error : false};
	case MICROSEISMIC_WELL_FRACTURING_DELETE_ERROR : 
		return { ...state, loading : false, error : true };
	case MICROSEISMIC_GRAPH:
		return {... state, graphData : action.payload};

	case FRACTURING_LOCATION_DATA :
		return { ...state, loading : true, error : false};
	case FRACTURING_LOCATION_DATA_SUCCESS :
		return { ...state, fracturedata:action.payload, loading : false, error : false};
	case FRACTURING_LOCATION_DATA_ERROR : 
		return { ...state, loading : false, error : true };
		
	case MICRO_SEISMIC_INGRSS_GRAPH_CONFIG:
		return { ...state, graphConfig : action.payload && action.payload.data ? action.payload.data[0] : {}};
			
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}