/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Card, Col, Row, Space, Typography, message } from 'antd';
import { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Content } from 'antd/es/layout/layout';
// import eventsImg from '../../images/Events.png';
import dashboarddatareducer from './reducer';
import { TLP_DASHBOARD_LIST, TLP_DASHBOARD_LIST_ERROR, TLP_DASHBOARD_LIST_SUCCESS } from './constants';
import moment from 'moment';

import totaltlp from '../../images/boxes-stacked-solid.svg';
import smarttlp from '../../images/box-archive-solid.svg';
import nosolartlp from '../../images/box-solid.svg';
import solartlp from '../../images/solar-panel-solid.svg';
import notsyncstlp from '../../images/wrench-solid.svg';
import tap from '../../images/arrows-rotate-solid.svg';
import lowbatery from '../../images/battery-quarter-solid.svg';
import nocoverage from '../../images/square-xmark-solid.svg';
import anomaly from '../../images/triangle-exclamation-solid.svg';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';



export default function TlpContent (props) {
	
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const navigate = useNavigate();
	const initalState = {tlpdashboarddata : null, details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(dashboarddatareducer, initalState);
	const regionId = props.regionId;
	const date = props.date;

	const startdatetime = moment.utc(date.setHours(0,0,0,0)).toISOString();
	const enddatetime = moment.utc(date.setHours(23,59,59,999)).toISOString();

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	const envRefreshTime = process.env.REACT_APP_REFRESH_TIME == undefined || process.env.REACT_APP_REFRESH_TIME == 'undefined' ? 180000 : process.env.REACT_APP_REFRESH_TIME;
	const refreshTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`) == 'null' ? parseInt(envRefreshTime) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`));

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
	let allocationParams = '';
	if(admin_roles.includes(permission)){
		allocationParams = 'regionInfoId';
	}
	else{
		allocationParams = 'allocationId';
	}

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getTlpData();
	},[authtoken, regionId, date]);

	useEffect(() => {
		const interval = setInterval(() => {
			// getTlpData();
			navigate(0);
		}, refreshTime ); 
		return () => clearInterval(interval);
	}, []);

	const getTlpData = () =>{

		dispatch({ type : TLP_DASHBOARD_LIST});


		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/tlpdashboard?${allocationParams}=${regionId}&startDatetime=${startdatetime}&endDatetime=${enddatetime}`,
			timeout: extendTimeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: {
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type:TLP_DASHBOARD_LIST_SUCCESS, payload:response.data});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({type:TLP_DASHBOARD_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});
	};


	const handleNaviagte =(e)=>{

		if(e=='realtime-cps'){
			navigate(( '/realtime-cps'),{ state: { data: {params:'', value:0, regionId : regionId}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '21');
		}
		else if(e=='smarttlp'){
			navigate(( '/realtime-cps'),{ state: { data: {params:'&isTRU=false', value:7, regionId : regionId}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '21');
		}
		else if(e=='withoutsolar'){
			navigate(( '/realtime-cps'),{ state: { data: {params:'&hasSolarPanel=false&isTRU=false', value:4, regionId : regionId}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '21');
		}
		else if(e=='withsolar'){
			navigate(( '/realtime-cps'),{ state: { data: {params:'&hasSolarPanel=true&isTRU=false', value:5, regionId : regionId}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '21');
		}
		else if(e=='Tru'){
			navigate(( '/realtime-cps'),{ state: { data: {params:'&isTRU=true', value:6, regionId : regionId}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '21');
		}
		else if (e=='lowbattery'){
			navigate(( '/realtime-cps'),{ state: { data: {params:'&batteryStatus=Low Battery', value:8, regionId : regionId}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '21');
		}
		else if (e=='anomaly'){
			navigate(( '/realtime-cps'),{ state: { data: {params:'&anomaly=true', value:3, regionId : regionId}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '21');
		}
		else if (e=='hardwareConfigNotSynced'){
			navigate(( '/realtime-cps'),{ state: { data: {value:13, regionId : regionId}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '21');
		}
		else if (e=='istampered'){
			navigate(( '/realtime-cps'),{ state: { data: {params:'&isTampered=true', value:15, regionId : regionId}, path: 'dashboard' } });
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '21');
		}
		
	};


	return(
		<>
			<Content>
				<Row gutter={{
					xs: 8,
					sm: 16,
					md: 24,
					lg: 32,
				}} style={{
					marginTop:'20px',
					marginLeft:'0px',
					marginRight:'0px',
					marginBottom: '20px',
				}}>
					<Space>
						<Col onClick={()=>{handleNaviagte('realtime-cps');}}  style={{marginBottom:'20px'}} span={6}> 
							<Card style={{ minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
									{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={totaltlp} alt='event image' style={{ height : '35px'}}/>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.TOTALNUMBEROFTLPS: 'TOTAL NUMBER OF TLPS'} </Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {data && data.tlpdashboarddata ? data.tlpdashboarddata.totalTlpCount : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>
					<Space>
						<Col onClick={()=>{handleNaviagte('smarttlp');}}  style={{marginBottom:'20px'}} span={6}>
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}}>
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
									{/* <Statistic
								title="ACKNOWLEDGEMENTS"
								value={acknowledgementcount}
								precision={0}
								// valueStyle={{ color: 'crimson' }}
								prefix={<OneToOneOutlined />}
								suffix=""
							/> */}														
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={smarttlp} alt='acknowledgement count' style={{ height : '35px'}}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.TOTALNUMBEROFSMARTTLPS: 'TOTAL NUMBER OF SMART TLPS'} </Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {data && data.tlpdashboarddata ? data.tlpdashboarddata.tlpCount :0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>
					<Space>
						<Col onClick={()=>{handleNaviagte('withoutsolar');}} style={{marginBottom:'20px'}} span={6}> 
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
									{/* <Statistic
								title="RESOLUTIONS"
								value={totalResolved}
								precision={0}
								// valueStyle={{ color: 'blue' }}
								prefix={<BellOutlined />}
								suffix=""
							/> */}
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={nosolartlp} alt='resolved count' style={{ height : '35px' }}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.TLPSWITHOUTSOLARPANEL: 'TLPS WITHOUT SOLAR PANEL'} </Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {data && data.tlpdashboarddata ? data.tlpdashboarddata.nonSolarCount : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>
					<Space>
						<Col onClick={()=>{handleNaviagte('withsolar');}} style={{marginBottom:'20px'}} span={6}> 
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}}>
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
									{/* <Statistic
								title="DAY UNRESOLVED ALARMS"
								value={todayUnresolved}
								precision={0}
								// valueStyle={{ color: 'gold' }}
								prefix={<BellOutlined />}
								suffix=""
							/> */}
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={solartlp} alt='unresolved count' style={{ height : '35px'}}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.TLPSWITHSOLARPANEL: 'TLPS WITH SOLAR PANEL'}</Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {data && data.tlpdashboarddata ? data.tlpdashboarddata.solarCount : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>

					
					{/* <Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}} style={{
						marginTop:'20px',
						marginLeft:'0px',
						marginRight:'0px',
						marginBottom: '20px',
					}}> */}

					<Space>

						<Col onClick={()=>{handleNaviagte('Tru');}}  style={{marginBottom:'20px'}} span={6}>
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
									{/* <Statistic
		title="TOTAL UNRESOLVED ALARMS"
		value={totalUnresolved}
		precision={0}
		// valueStyle={{ color: 'gold' }}
		// prefix={<BellOutlined />}
		suffix=""
	/> */}
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={nosolartlp} alt='unresolved count' style={{ height : '35px'}}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.TOTALNUMBEROFTRUs: 'TOTAL NUMBER OF TRUs'} </Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {data && data.tlpdashboarddata ? data.tlpdashboarddata.truCount : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>
					<Space>
						<Col onClick={()=>{handleNaviagte('hardwareConfigNotSynced');}} style={{marginBottom:'20px'}}  span={6}>
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>

									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={notsyncstlp} alt='unresolved count' style={{height : '35px'}}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.HARDWARECONFIGNOTSYNCED: 'HARDWARE CONFIG NOT SYNCED'}</Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {data && data.tlpdashboarddata ? data.tlpdashboarddata.hardwareConfigNotSyncedCount : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>
					<Space>
						<Col style={{marginBottom:'20px'}} span={6}>
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}} >
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
									{/* <Statistic
								title="TOTAL UNRESOLVED ALARMS"
								value={totalUnresolved}
								precision={0}
								// valueStyle={{ color: 'gold' }}
								// prefix={<BellOutlined />}
								suffix=""
							/> */}
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={tap} alt='unresolved count' style={{ height : '35px'}}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.YESTERDAYSTAPPING: 'YESTERDAYS TAPPING'} </Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {data && data.tlpdashboarddata ? data.tlpdashboarddata.yesterdaysTappingCount : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>
					<Space>
						<Col style={{marginBottom:'20px'}}  span={6}>
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px',}} >
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
									{/* <Statistic
								title="TOTAL UNRESOLVED ALARMS"
								value={totalUnresolved}
								precision={0}
								// valueStyle={{ color: 'gold' }}
								// prefix={<BellOutlined />}
								suffix=""
							/> */}
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={tap} alt='unresolved count' style={{ height : '35px'}}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.TODAYSTAPPING: 'TODAYS TAPPING'} </Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {data && data.tlpdashboarddata ? data.tlpdashboarddata.todaysTappingCount : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>

					<Space>
						<Col onClick={()=>{handleNaviagte('lowbattery');}} style={{marginBottom:'20px'}}  span={6}>
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>
									{/* <Statistic
								title="TOTAL UNRESOLVED ALARMS"
								value={totalUnresolved}
								precision={0}
								// valueStyle={{ color: 'gold' }}
								// prefix={<BellOutlined />}
								suffix=""
							/> */}
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={lowbatery} alt='unresolved count' style={{height : '35px'}}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.LOWBATTERY: 'LOW BATTERY'}</Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {data && data.tlpdashboarddata ? data.tlpdashboarddata.lowBatteryCount : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>

					<Space>
						<Col onClick={()=>{handleNaviagte('anomaly');}} style={{marginBottom:'20px'}}  span={6}>
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>

									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={anomaly} alt='unresolved count' style={{ height : '35px'}}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.TOTALANOMALY: 'TOTAL ANOMALY'}</Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {data && data.tlpdashboarddata ? data.tlpdashboarddata.totalAnomalyCount : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>

					<Space>
						<Col onClick={()=>{handleNaviagte('istampered');}} style={{marginBottom:'20px'}}  span={6}>
							<Card style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px', cursor: 'pointer'}} >
								<Card.Grid style={{minWidth:'245px', minHeight:'190px', maxWidth:'245px', maxHeight:'190px'}}>

									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={nocoverage} alt='tampered count' style={{height : '35px'}}/>

									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px', textAlign : 'center' }}>
										<Typography>{langdata && langdata ? langdata.TAMPEREDNOCOVERAGEDEVICES: 'TAMPERED / NO COVERAGE DEVICES'}</Typography>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
										<Typography style={{ fontSize : '25px'}}><b> {data && data.tlpdashboarddata ? data.tlpdashboarddata.tamperedCount : 0}</b> </Typography>
									</Col>
								</Card.Grid>
							</Card>
						</Col>
					</Space>

				</Row>



			</Content>
		</>
	);
}