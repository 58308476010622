/* eslint-disable no-case-declarations */
import moment from 'moment';
import { EVENT_VIEW, EVENT_VIEW_ERROR, EVENT_VIEW_SUCCESS, GRAPH_DATA, GRAPH_DATA_ERROR, GRAPH_DATA_SUCCESS, REALTIME_EVENTS_DETAILS, REALTIME_EVENTS_ERROR, REALTIME_EVENTS_LIST, REALTIME_EVENTS_SUCCESS } from './constants';
import activityicons from '../../activitytypes';


export default function eventData(state, action) {
	let data = [];
	let events = [];
	let pagination = {};
	let graph1PipelineChainage = [];
	let graph1EventsCount = [];
	let graph2Date = [];
	let graph2EventsCount = [];
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	switch (action.type) {
      
	case REALTIME_EVENTS_LIST:
		return { ...state, pending: true };
	case REALTIME_EVENTS_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
			pagination.pageSize =  action.pageSize ? action.pageSize : 10;
			action.payload.data.map((item) => {
				const date = moment.utc(item.datetime).toDate();
				date.toString();
				const newdate = moment(date, 'YYYY-MM-DD HH:mm:ss').format(settingsdateformat + ' ' + settingstimeformat);
				events.push({
					key: item.id,
					id: item.id,
					eventType: item.eventType,
					eventChannel: item.channel_name,
					eventDate: newdate,
					chainage: item.locationDetails ? item.locationDetails.info : null,
					odInMeter: item.odMeter,
					duration: item.duration,
					eventConfidence: item.eventConfidence,
					latitude : item.locationDetails ? item.locationDetails.Lat : null,
					longitude : item.locationDetails ? item.locationDetails.Long : null,
					datetime:item.datetime,
					regioninfoId : item.regioninfoId,
					pidsinfoId : item.pidsinfoId
				});
			});
		}
		return { ...state, relatimeEventdata: events,pagination: pagination, pending: false };
	case REALTIME_EVENTS_ERROR:
		return { ...state, error: true, pending:false };
	case REALTIME_EVENTS_DETAILS:
		state.relatimeEventdata.map((item) => {
			if (item.id == action.payload) {
				activityicons && activityicons.map((eventactivity)=>{
					if(item.eventType==eventactivity.type){
						let severity = item.eventConfidence >= 0.9 &&item.eventConfidence <= 1 ? 3 : item.eventConfidence >= 0.8 && item.eventConfidence < 0.9 ? 2 : item.eventConfidence >= 0.5 && item.eventConfidence < 0.8 ? 1 : 0;
						data = item;
						data.activityUrl= eventactivity.type && item.eventType==eventactivity.type ? eventactivity.severity[parseInt(severity)] : '-';
					}});
			}
		});
		return { ...state, realtime_events_details : data};
	case GRAPH_DATA :
		return { ...state, pending: true };
	case GRAPH_DATA_SUCCESS :
		let temp = '';
		let date;
		let count = 0;
		let length = 0;
		if(action.payload) {
			action.payload.graph1Data.map((item) => {
				graph1PipelineChainage.push(item.pipelineChainage);
				graph1EventsCount.push(item.eventsCount);
			});
			length = action.payload.graph2Data.length;
			action.payload.graph2Data.map((item, index) => {
				date = new Date(item.datetime).toLocaleDateString('en-UK', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric'
				});
				if(temp === date) {
					temp = date;
					count = count + parseInt(item.eventsCount);
					if (index === (length -1)) {
						graph2Date.push(date);
						graph2EventsCount.push(count);
					}
				}
				else {
					if ( index === 0) {
						count = parseInt(item.eventsCount);
						temp = date;
						if (index === (length -1)) {
							graph2Date.push(date);
							graph2EventsCount.push(count);
						}
					}
					else {
						graph2Date.push(temp);
						graph2EventsCount.push(count);
						temp = date;
						count = parseInt(item.eventsCount);
					}
				}
				
				
			});
		}
		return { ...state, graph1PipelineChainage: graph1PipelineChainage,graph1EventsCount : graph1EventsCount,graph2Date : graph2Date, graph2EventsCount : graph2EventsCount, pending: false };
	case GRAPH_DATA_ERROR :
		return { ...state, error: true, pending:false };

	case EVENT_VIEW:
		return { ...state, pending: true };
	case EVENT_VIEW_SUCCESS:
		return { ...state, event_view: action.payload, pending: false };
	case EVENT_VIEW_ERROR:
		return { ...state, error: true, pending:false };

	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}