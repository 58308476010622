/* eslint-disable react/react-in-jsx-scope */
import { Breadcrumb, Button, Card, Col, Form, Modal, Row, Select, Space, Spin, Typography, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useReducer, useState } from 'react';
import { useAuth } from '../Context';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import reducer from './reducer';
import { EDIT_USER_ALLOCATION, EDIT_USER_ALLOCATION_SUCCESS, EDIT_USER_ALLOCATION_ERROR, EDIT_ALLOCATION, EDIT_ALLOCATION_SUCCESS, EDIT_ALLOCATION_ERROR, ALLOCATION_USER_DETAILS, ALLOCATION_USER_DETAILS_SUCCESS, ALLOCATION_USER_DETAILS_ERROR } from './constants';
import { HomeOutlined } from '@ant-design/icons';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';


const { Title } = Typography;

export default function EditAllocation() {
	const {getUserTypes, userTypeData, getRegion, regiondata, handleGetMapdata, mapdata} = useAuth();

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const initalState = { pending: false, error: false, edit_details : [], edit_allocation : [], userAllocationDetails : []};
	const [data, dispatch] = useReducer(reducer, initalState);
	const { state } = useLocation();

	const [editAllocationData, setEditAllocationData]  = useState({});
	const [allocation, setAllocation] = useState();
	const [startChainage, setStartChainage] = useState(0);
	const [endChainage, setEndChainage] = useState(); 
	const [modalOpen, setModalOpen] = useState(false);
	const [updatemodalOpen, setUpdateModalOpen] = useState(false);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const navigate = useNavigate();

	const walkingType = [
		{
			value : 'UNIDIRECTIONAL',
			label : langdata && langdata.UNIDIRECTIONAL ? langdata.UNIDIRECTIONAL : 'UNIDIRECTIONAL'
		},
		{
			value : 'BIDIRECTIONAL',
			label : langdata && langdata.BIDIRECTIONAL ? langdata.BIDIRECTIONAL : 'BIDIRECTIONAL'
		}
	];
	
	const routeType = [
		{
			value : 'OPTIONAL',
			label : langdata && langdata.OPTIONAL ? langdata.OPTIONAL : 'OPTIONAL'
		},
		{
			value : 'MANDATORY',
			label : langdata && langdata.MANDATORY ? langdata.MANDATORY :  'MANDATORY'
		}
	];

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getUserTypes(authtoken);
		getRegion(authtoken);
		dispatch({ type : EDIT_USER_ALLOCATION });
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/userallocation?userId=${state && state.userId}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : EDIT_USER_ALLOCATION_SUCCESS , payload : response.data});
					response.data.data && response.data.data.map((item) => {
						setEditAllocationData({userName : `${item.details.firstName} ${item.details.lastName}`,
							regioninfoId : item.regioninfoId,
							startChainage : item.pipelineConfigFrom,
							// showStartChainage : `${item.pipelineConfigFrom} ( ${item.rouMarkerFrom} )`,
							showStartChainage : item.zoneFromDetails.id,
							endChainage : item.pipelineConfigTo,
							// showEndChainage : `${item.pipelineConfigTo} ( ${item.rouMarkerTo} )`,
							showEndChainage : item.zoneToDetails.id,
							allocationFor : item.usertypeId,
							allocationId : item.id,
							startChainageRouMarker : item.rouMarkerFrom,
							endChainageRouMarker : item.rouMarkerTo,
							parentAllocationId : item.parentAllocation,
							crmId : item.crmId,
							supervisorName : item.supervisorDetails ? `${item.supervisorDetails.firstName} ${item.supervisorDetails.lastName}` : null ,
							rouName : item.ROU ? `${item.ROU.firstName} ${item.ROU.lastName}` : null ,
							rouAllocation : item.ROU && item.ROU.userallocations ? item.ROU.userallocations.id : null,
							routeType : item.routeType,
							walkType : item.walkType}); 
						setAllocation( `${item.id} ${item.pipelineConfigFrom} ${item.pipelineConfigTo}`);
					});
				} else {
					dispatch({ type : EDIT_USER_ALLOCATION_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : EDIT_USER_ALLOCATION_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});

	},[authtoken]);

	useEffect(() => {
		handleGetMapdata(authtoken,editAllocationData.regioninfoId ? editAllocationData.regioninfoId : null, null, null);
	},[authtoken,editAllocationData.regioninfoId]);
    
	useEffect(() => {

		if(editAllocationData.allocationFor){
			let url = '';
			if (editAllocationData.allocationFor === 5) {
				url = `${api}/users?isDeleted=false&$and[0][permissions]=CRO`;
			}  else if (editAllocationData.allocationFor === 4) {
				url = `${api}/users?isDeleted=false&$and[0][permissions]=supervisor`;
			} else {
				url = `${api}/users?isDeleted=false&$and[0][permissions]=ROU`;
			}
			dispatch({ type : ALLOCATION_USER_DETAILS});
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: url,
				timeout: timeout,
				headers: { 
					'Authorization': `Bearer ${authtoken}`
				}
			};
            
			axios.request(config)
				.then((response) => {
					if (response.status === 200){
						dispatch({ type : ALLOCATION_USER_DETAILS_SUCCESS, payload : response.data});
					} else {
						dispatch({ type : ALLOCATION_USER_DETAILS_ERROR });
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : ALLOCATION_USER_DETAILS_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
        
	},[authtoken, editAllocationData.allocationFor]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!state) {
			navigate(-1);
		}
	},[authtoken]);

	useEffect(() => {
		if(editAllocationData.allocationFor != 6) {
			data.userAllocationDetails && data.userAllocationDetails.map((item) => {
				if (Array.isArray(item.allocationsList)){
					item.allocationsList && item.allocationsList.map((value) => {
						if(value.id === editAllocationData.parentAllocationId){
							setEditAllocationData({...editAllocationData, croName : `${item.firstName} ${item.lastName}` });
							setStartChainage(value.pipelineConfigFrom);
							setEndChainage(value.pipelineConfigTo);
						}
					});
				} else {
					if(item.allocationsList && item.allocationsList.id === editAllocationData.parentAllocationId){
						setEditAllocationData({...editAllocationData, croName : `${item.firstName} ${item.lastName}`});
						setStartChainage(item.allocationsList.pipelineConfigFrom);
						setEndChainage(item.allocationsList.pipelineConfigTo);
					}
				}
			});
		}
	}, [data.userAllocationDetails, editAllocationData.parentAllocationId]);

	const allocationList = [];
	if (data.userAllocationDetails != null && editAllocationData.allocationFor != 6) {

		data.userAllocationDetails && data.userAllocationDetails.map((item) => {
			if (Array.isArray(item.allocationsList) ){
				item.allocationsList && item.allocationsList.map((value) => {
					if(value.id === editAllocationData.parentAllocationId){
						allocationList.push({
							label : `${value.pipelineConfigFrom} ( ${value.rouMarkerFrom} ) - ${value.pipelineConfigTo} ( ${value.rouMarkerTo})`,
							value : value.id
						});
					}
				});
			} else {
				if (item.allocationsList !== null) {
					if(item.allocationsList.id === editAllocationData.parentAllocationId){
						allocationList.push({
							label : `${item.allocationsList.pipelineConfigFrom} ( ${item.allocationsList.rouMarkerFrom} ) - ${item.allocationsList.pipelineConfigTo} ( ${item.allocationsList.rouMarkerTo})`,
							value : item.allocationsList.id
						});
					}
				}
			}
		});
	}
    

	const userType = [];
	// eslint-disable-next-line array-callback-return
	userTypeData && userTypeData.data.map((item) => {
		userType.push({
			label : `Allot ${item.userType}`,
			value : item.id
		});
	});

	const regions = [];
	// eslint-disable-next-line array-callback-return
	regiondata && regiondata.map((item)=> {
		regions.push({
			value:item.id,
			label:item.regionName.toUpperCase()
		});
	});

	const rouChainage = [];
	// eslint-disable-next-line array-callback-return
	mapdata && mapdata.map((item) => {
		rouChainage.push({
			value : item.id,
			label : `${item.pipelineChainage} ( ${item.rouMarker} )`
		});
	});

	const chainages = [];
	mapdata && mapdata.map((item) => {
		if (item.pipelineChainage >= startChainage && item.pipelineChainage <= endChainage) {
			chainages.push({
				value : item.id,
				label : `${item.pipelineChainage} ( ${item.rouMarker} )`
			});
		}
	});

	const handleUserRole = (value) => {
		setEditAllocationData({...editAllocationData, allocationFor : value});
	};

	const handleRegion = (value) => {
		setEditAllocationData({...editAllocationData, regionId : value});
	};


	const handleStartChainage = (value) => {
		// eslint-disable-next-line array-callback-return
		mapdata && mapdata.map((item) => {
			if (item.id === value) {
				setEditAllocationData({...editAllocationData,startChainageRouMarker : item.rouMarker, 
					showStartChainage : item.id,
					startChainage : item.pipelineChainage});
			}
		});
	};

	const handleEndChainage = (value) => {
		mapdata && mapdata.map((item) => {
			if (item.id === value) {
				setEditAllocationData({...editAllocationData, endChainageRouMarker : item.rouMarker,
					showEndChainage : item.id,
					endChainage : item.pipelineChainage});
			}
		});
	};

	const handleAllocation = ( value) => {
		setEditAllocationData({...editAllocationData, parentAllocationId : value, startChainage : null, endChainage : null,
			showStartChainage : null, showEndChainage : null });
	};

	const handleAllocationChainage = (value) => {

		// split_value[0] = AllocationId, split_value[1] = Start chainage, split_value[2] = End chainage

		setAllocation(value);
		let split_value = value.split(' ');
		setEditAllocationData({...editAllocationData, allocationId : parseInt(split_value[0]),
			startChainageRouMarker : null,
			endChainageRouMarker : null,
			showStartChainage : null,
			showEndChainage : null,
			startChainage : null,
			endChainage : null});
	};


	const handleWalkingType = (value) => {
		setEditAllocationData({...editAllocationData, walkType : value});
	};

	const handleRoutetype = (value) => {
		setEditAllocationData({...editAllocationData, routeType : value});
	};

	const handleUpdate = () => {
		setUpdateModalOpen(false);
		dispatch({ type : EDIT_ALLOCATION });
		if( editAllocationData.startChainage === editAllocationData.endChainage) {
			Modal.warning({ title: langdata && langdata.Startchainageandendchainagecantbesame ? langdata.Startchainageandendchainagecantbesame : 'Start chainage and end chainage can\'t be same.' });
			dispatch({ type : EDIT_ALLOCATION_ERROR });
			return false;
		} else {
			let data = JSON.stringify({
				'parentAllocation': editAllocationData.parentAllocationId ? editAllocationData.parentAllocationId : null,
				'pipelineConfigFrom': editAllocationData.startChainage,
				'pipelineConfigTo': editAllocationData.endChainage,
				'pipelineId': localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`),
				'rouMarkerFrom': editAllocationData.startChainageRouMarker,
				'rouMarkerTo': editAllocationData.endChainageRouMarker,
				'routeType' : editAllocationData.routeType,
				'walkType' : editAllocationData.walkType
			});
	
			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/userallocation/${editAllocationData.allocationId}`,
				timeout: extendTimeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
	
			axios.request(config)
				.then((response) => {
					if ( response.status === 200 || response.status === 201 || response.status === 204 ) {
						dispatch({ type : EDIT_ALLOCATION_SUCCESS});
						Modal.success({ title: langdata && langdata.Allocationupdatedsuccessfully ? langdata.Allocationupdatedsuccessfully : 'Allocation updated successfully.' });
						navigate('/allocations');
					}else {
						dispatch ({ type :EDIT_ALLOCATION_SUCCESS });
						Modal.warning({ title: langdata && langdata.Allocationalreadypresent ? langdata.Allocationalreadypresent : 'Allocation already present' });
					}
				})
				.catch((error) => {
					dispatch ({ type : EDIT_ALLOCATION_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
					console.log(error);
				});
		}

	};

	const handleDelete = () => {
		setModalOpen(false);
		dispatch({ type : EDIT_ALLOCATION });
		let data = JSON.stringify({
			'isDeleted': true
		});

		let config = {
			method: 'patch',
			maxBodyLength: Infinity,
			url: `${api}/userallocation/${editAllocationData.allocationId}`,
			timeout: extendTimeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if ( response.status === 200 || response.status === 201 || response.status === 204 ) {
					dispatch({ type : EDIT_ALLOCATION_SUCCESS});
					Modal.success({ title: langdata && langdata.Allocationdeletedsuccessfully ? langdata.Allocationdeletedsuccessfully : 'Allocation deleted successfully.' });
					navigate('/allocations');
				}else {
					dispatch ({ type :EDIT_ALLOCATION_ERROR });
					Modal.error({ title: langdata && langdata.Allocationnotdeleted ? langdata.Allocationnotdeleted : 'Allocation not deleted.' });
				}
			})
			.catch((error) => {
				dispatch ({ type : EDIT_ALLOCATION_ERROR });
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});

	};

	const handleUpdateModal = () =>{
		setUpdateModalOpen(true);

	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		<Content>
			{state ?
				<>
					<Form>
						<Row 
							style={{
								marginLeft:'50px',
								marginTop: '10px'
							}}            
						>
							<Breadcrumb
								items={[
									{
										href: '/dashboard',
										title: <HomeOutlined />,
									},
									{
										title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'}</a> 
									},
									{
										title: langdata && langdata.EditAllocation ? langdata.EditAllocation : 'Edit Allocation',
									},
								]}
							/>
						</Row>
						<Card  style={{
							marginLeft: '50px',
							marginRight: '50px',
							marginTop: '10px',
							padding: '20px',
							background: '#FFFFFF',
							display: 'flex',
							// margin: '50px'
						}}>
							<Row
								gutter={{
									xs: 8,
									sm: 16,
									md: 24,
									lg: 32,
								}}                
							>
								<Col className="gutter-row" span={24}>
									<Title level={4} style={{ paddingTop:'10px', justifyContent : 'center', display : 'flex'}}> {langdata && langdata.EditUserAllocation ? langdata.EditUserAllocation : 'Edit User Allocation'}</Title>

								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Allotto ? langdata.Allotto : 'Allot to'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left', marginTop : '25px'}} >
									<Select
										style={{ width: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Allotto ? langdata.Allotto : 'Allot to'}
										optionFilterProp="children"
										options={userType}
										value={editAllocationData.allocationFor}
										onSelect={handleUserRole}
										disabled={true}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Region ? langdata.Region : 'Region'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ width: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Region ? langdata.Region : 'Region'}
										optionFilterProp="children"
										options={regions}
										value={editAllocationData.regioninfoId}
										onSelect={handleRegion}
										disabled={true}
									/>
								</Col>
								{editAllocationData.allocationFor === 6 ?
									<>
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.ROU ? langdata.ROU : 'ROU'} :</Typography>
										</Col>
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.ROU ? langdata.ROU : 'ROU'}
												optionFilterProp="children"
												// options={data.rou}
												value={editAllocationData.userName}
												disabled={true}
												// onSelect={handleROU}
											/>
										</Col>
									</>
									: editAllocationData.allocationFor === 5 || editAllocationData.allocationFor === 7 ?
										<>
											<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
												<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.ROU ? langdata.ROU : 'ROU'} :</Typography>
											</Col>
											<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
												<Select
													style={{ width: '100%'}}
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.ROU ? langdata.ROU : 'ROU'}
													optionFilterProp="children"
													// options={data.rou}
													value={editAllocationData.rouName}
													disabled={true}
													// onSelect={handleROU}
												/>
											</Col>
										</>
										: null }
								{editAllocationData.allocationFor === 3 || editAllocationData.allocationFor === 5 ?
									<>
										{ editAllocationData.allocationFor === 3 ?
											<>
												<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
													<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.CRO ? langdata.CRO : 'CRO'} :</Typography>
												</Col>
												<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
													<Select
														style={{ width: '100%'}}
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.CRO ? langdata.CRO : 'CRO'}
														optionFilterProp="children"
														// options={data.rou}
														value={editAllocationData.userName}
														disabled={true}
														// onSelect={handleROU}
													/>
												</Col>
											</> : <>
												<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
													<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.CRO ? langdata.CRO : 'CRO'} :</Typography>
												</Col>
												<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
													<Select
														style={{ width: '100%'}}
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.CRO ? langdata.CRO : 'CRO'}
														optionFilterProp="children"
														// options={data.rou}
														value={editAllocationData.croName}
														disabled={true}
														// onSelect={handleROU}
													/>
												</Col>
												<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
													<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Supervisor ? langdata.Supervisor : 'Supervisor'} :</Typography>
												</Col>
												<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
													<Select
														style={{ width: '100%'}}
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.Supervisor ? langdata.Supervisor : 'Supervisor'}
														optionFilterProp="children"
														// options={data.rou}
														value={editAllocationData.userName}
														disabled={true}
														// onSelect={handleROU}
													/>
												</Col>
											</> 
										}

										{ editAllocationData.allocationFor === 3 ?
											<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
												<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.ROUAllocations ? langdata.ROUAllocations : 'ROU Allocations'} :</Typography>
											</Col>
											: 
											<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
												<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.CROAllocations ? langdata.CROAllocations : 'CRO Allocations'} :</Typography>
											</Col>
										}
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'}
												optionFilterProp="children"
												value={editAllocationData.parentAllocationId}
												options={allocationList}
												onSelect={handleAllocation}
											/>
										</Col>
									</>
									: null }

								{editAllocationData.allocationFor === 4 ?
									<>
                    
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Supervisor ? langdata.Supervisor : 'Supervisor'} :</Typography>
										</Col>
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.Supervisor ? langdata.Supervisor : 'Supervisor'}
												optionFilterProp="children"
												// options={data.rou}
												value={editAllocationData.supervisorName}
												disabled={true}
												// onSelect={handleROU}
											/>
										</Col>
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Linewalker ? langdata.Linewalker : 'Linewalker'} :</Typography>
										</Col>
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.Linewalker ? langdata.Linewalker : 'Linewalker'}
												optionFilterProp="children"
												options={data.rou}
												value={editAllocationData.userName}
												disabled={true}
												// onSelect={handleROU}
											/>
										</Col>
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.SupervisorAllocations ? langdata.SupervisorAllocations : 'Supervisor Allocations'} :</Typography>
										</Col>
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'}
												optionFilterProp="children"
												value={editAllocationData.parentAllocationId}
												options={allocationList}
												onSelect={handleAllocation}
											/>
										</Col>
									</>
									: null }

								{editAllocationData.allocationFor === 7 ?
									<>
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.NPV ? langdata.NPV : 'NPV'} :</Typography>
										</Col>
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.NPV ? langdata.NPV : 'NPV'}
												optionFilterProp="children"
												options={data.rou}
												value={editAllocationData.userName}
												disabled={true}
												// onSelect={handleROU}
											/>
										</Col>
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.ROUAllocations ? langdata.ROUAllocations : 'ROU Allocations'} :</Typography>
										</Col>
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'}
												optionFilterProp="children"
												value={editAllocationData.parentAllocationId}
												options={allocationList}
												onSelect={handleAllocation}
											/>
										</Col>
									</>
									: null }
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ width: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'}
										optionFilterProp="children"
										value={allocation}
										options={data.edit_allocation}
										onSelect={handleAllocationChainage}
									/>
								</Col>
								{editAllocationData.allocationFor === 6 ?
									<>
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage'} :</Typography>
										</Col>
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage '}
												optionFilterProp="children"
												options={rouChainage}
												value={editAllocationData.showStartChainage}
												onSelect={handleStartChainage}
											/>
										</Col>
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage'} :</Typography>
										</Col>
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage '}
												optionFilterProp="children"
												options={rouChainage}
												value={editAllocationData.showEndChainage}
												onSelect={handleEndChainage}
											/>
										</Col>
									</>
									: 
									<>
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage'} :</Typography>
										</Col>
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage '}
												optionFilterProp="children"
												options={chainages}
												value={editAllocationData.showStartChainage}
												onSelect={handleStartChainage}
											/>
										</Col>
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage'} :</Typography>
										</Col>
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage '}
												optionFilterProp="children"
												options={chainages}
												value={editAllocationData.showEndChainage}
												onSelect={handleEndChainage}
												required
											/>
										</Col>
									</>
								}
								{editAllocationData.allocationFor === 4 ?
									<>
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.WalkingType ? langdata.WalkingType : 'Walking Type'} :</Typography>
										</Col>
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.WalkingType ? langdata.WalkingType : 'Walking Type '}
												optionFilterProp="children"
												options={walkingType}
												value={editAllocationData.walkType}
												onSelect={handleWalkingType}
											/>
										</Col>
										<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
											<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.RouteType ? langdata.RouteType : 'Route Type'} :</Typography>
										</Col>
										<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
											<Select
												style={{ width: '100%'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.RouteType ? langdata.RouteType : 'Route Type '}
												optionFilterProp="children"
												options={routeType}
												value={editAllocationData.routeType}
												onSelect={handleRoutetype}
											/>
										</Col>
									</>
									: null }
								<Col className="gutter-row" span={10} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Button type='primary' onClick={editAllocationData && editAllocationData.allocationFor == 4 || editAllocationData && editAllocationData.allocationFor == 7 ? handleUpdate : handleUpdateModal}>{langdata && langdata.Update ? langdata.Update : 'Update'}</Button>
								</Col>
								<Space>
									<Col className="gutter-row" span={2} style={{ marginTop : '25px'}} >
										<Button onClick={() => {navigate(-1);}}>{langdata && langdata.Cancel ? langdata.Cancel : 'Cancel'}</Button>
									</Col>
								</Space>
								<Col className="gutter-row" span={3} style={{textAlign : 'left', marginTop : '25px'}} >
									<Button danger onClick={() => setModalOpen(true)}>
										{langdata && langdata.Delete ? langdata.Delete : 'Delete'}
									</Button>
								</Col>
							</Row>       
						</Card>
					</Form>
					<Modal
						title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'}
						centered
						open={modalOpen}
						onOk={handleDelete}
						onCancel={() => setModalOpen(false)}
						footer={[
							// eslint-disable-next-line react/jsx-key
							<Space>
								<Button onClick={() => setModalOpen(false)}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
								<Button key="submit" type="primary" onClick={handleDelete}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
							</Space>
						]}
					>
						<Typography style={{color:'red', display:'flex', fontSize:'17px'}}>{langdata && langdata.Thisupdatewilldeleteallyourlowerrolesallocations ? langdata.Thisupdatewilldeleteallyourlowerrolesallocations : 'This update will delete all your lower roles allocations.'}</Typography>
						<Space>&nbsp;</Space>
						<p>{langdata && langdata.Wouldyouliketoproceedwiththesame ? langdata.Wouldyouliketoproceedwiththesame : 'Would you like to proceed with the same ?'}</p>
					</Modal>

					<Modal
						title={langdata && langdata.Confirmupdate ? langdata.Confirmupdate : 'Confirm update'}
						centered
						open={updatemodalOpen}
						onOk={handleUpdate}
						onCancel={() => setUpdateModalOpen(false)}
						footer={[
							// eslint-disable-next-line react/jsx-key
							<Space>
								<Button onClick={() => setUpdateModalOpen(false)}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
								<Button key="submit" type="primary" onClick={handleUpdate}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
							</Space>
						]}
					>
						<Typography style={{color:'red', display:'flex', fontSize:'17px'}}>{langdata && langdata.Thisupdatewilldeleteallyourlowerrolesallocations ? langdata.Thisupdatewilldeleteallyourlowerrolesallocations : 'This update will delete all your lower roles allocations.'}</Typography>
						<Space>&nbsp;</Space>
						<p>{langdata && langdata.Wouldyouliketoproceedwiththesame ? langdata.Wouldyouliketoproceedwiththesame : 'Would you like to proceed with the same?'}</p>
					</Modal>
					<Spin fullscreen spinning={data.pending} tip={langdata && langdata.largeloadingmsg ? langdata.largeloadingmsg : 'This process will take 2-3 minutes to complete. Please wait a moment.'}/>
				</> : '' }
		</Content>
	);
}