export const FRACTURING_LIST = 'FRACTURING_LIST';
export const FRACTURING_LIST_SUCCESS = 'FRACTURING_LIST_SUCCESS';
export const FRACTURING_LIST_ERROR = 'FRACTURING_LIST_ERROR';

export const FRACTURING_CREATE = 'FRACTURING_CREATE';
export const FRACTURING_CREATE_SUCCESS = 'FRACTURING_CREATE_SUCCESS';
export const FRACTURING_CREATE_ERROR = 'FRACTURING_CREATE_ERROR';

export const UPDATE_FRACTURING_DATA = 'UPDATE_FRACTURING_DATA';

export const FRACTURING_DELETE = 'FRACTURING_DELETE';
export const FRACTURING_DELETE_SUCCESS = 'FRACTURING_DELETE_SUCCESS';
export const FRACTURING_DELETE_ERROR = 'FRACTURING_DELETE_ERROR';

export const MICROSEISMIC_GRAPH_DAS = 'MICROSEISMIC_GRAPH_DAS';
export const MICROSEISMIC_GRAPH_DTS = 'MICROSEISMIC_GRAPH_DTS';
export const MICROSEISMIC_GRAPH_PRESSURE = 'MICROSEISMIC_GRAPH_PRESSURE';


export const FRACTURING_LOCATION_DATA = 'FRACTURING_LOCATION_DATA';
export const FRACTURING_LOCATION_DATA_SUCCESS = 'FRACTURING_LOCATION_DATA_SUCCESS';
export const FRACTURING_LOCATION_DATA_ERROR = 'FRACTURING_LOCATION_DATA_ERROR';

export const FRACTURE_GRAPH_CONFIG = 'FRACTURE_GRAPH_CONFIG';

