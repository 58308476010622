import React, { useEffect, useReducer, useState } from 'react';


import { Empty, Row, Col, Typography, Table, Space, Modal, Form, Input, Button, Select, message, Spin, Popover, Avatar, Card} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import edgedata from './reducer';
import { CHANNEL_INFO_LIST, CHANNEL_INFO_LIST_ERROR, CHANNEL_INFO_LIST_SUCCESS, PIDS_INFO_DETAILS, PIDS_INFO_LIST, PIDS_INFO_LIST_ERROR, PIDS_INFO_LIST_SUCCESS, PIDS_INFO_UPDATE, PIDS_INFO_UPDATE_ERROR, PIDS_INFO_UPDATE_SUCCESS } from './constants';
import axios from 'axios';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import { ToolOutlined, InfoOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useAuth } from '../../Context';

const { Title } = Typography;


let data_pagination = {};


export default function PidsManagement() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = {pid_data:[], channel_data:[], details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(edgedata, initalState);
	const navigate = useNavigate();
	const [openpidsEditDrawer, setOpenPidsEditDrawer] = useState(false);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	useAuth();

	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const alarmtypeOptions = [
		{value:'first',label: langdata && langdata.FIRST ? langdata.FIRST :'FIRST'},
		{value:'last', label: langdata && langdata.LAST ? langdata.LAST :'LAST'}
	];

	const columns = [

		{
			title: langdata && langdata.PIDSName ? langdata.PIDSName :'PIDS Name',
			dataIndex: 'pidsName',
			key: 'pidsName',
		},
		{
			title: langdata && langdata.IPAddress ? langdata.IPAddress : 'IP Address',
			dataIndex: 'ip',
			key: 'ip',
			//   render: (_,text) => <a onClick={showMap}>{text}</a>)

		},
		{
			title:  langdata && langdata.ChannelCount ? langdata.ChannelCount : 'Channel Count',
			dataIndex: 'channelcount',
			key: 'channelcount',
		},
		{
			title: langdata && langdata.ParentAlarmType ? langdata.ParentAlarmType : 'Parent Alarm Type',
			dataIndex: 'parentAlarmType',
			key: 'parentAlarmType',
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (_, record) => (
				<Space style={{justifyContent:'left',display:'flex'}} size="middle">
					<a title={langdata && langdata.View ? langdata.View : 'View'} onClick={()=>{handleView(record);}}><EyeOutlined/></a>
					{permission === 'superadmin' ?
						<a title={langdata && langdata.Edit ? langdata.Edit : 'Edit'} onClick={()=>{handleEdit(record);}}><EditOutlined/></a> : null }
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)
		}
		
	];


	useEffect(() => {
		if(featuresModule.pidsMgmtEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 46);
	}, []);

	
	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		else{
			pidsmanagementdata(1);
		}

	},[authtoken]);



	const pidsmanagementdata = (pagination) => {
		let _pagination = { };
		_pagination.total = data_pagination.total ? data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		dispatch({ type : PIDS_INFO_LIST});

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pidsinfo-list?$limit=${_pagination.limit}&$skip=${_pagination.skip}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					data_pagination.total = response.data.total ? response.data.total : 0;
					data_pagination.limit = response.data.limit ? response.data.limit : 10;
					data_pagination.skip = response.data.skip ? response.data.skip : 0;
					data_pagination.current = pagination.current ? pagination.current : 1;
					data_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;
					
					dispatch({ type : PIDS_INFO_LIST_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : PIDS_INFO_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : PIDS_INFO_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const handleView = (record) =>{
		navigate(( '/pid-details'),{ state: { data: record, path: 'pidsmanagement' } });
		dispatch({type:PIDS_INFO_DETAILS, payload:record});
	};

	const handleEdit = (record) =>{
		dispatch({type:PIDS_INFO_DETAILS, payload:record});

		dispatch({ type : CHANNEL_INFO_LIST});

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/channelinfo?pidsinfoId=${record.id}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : CHANNEL_INFO_LIST_SUCCESS, payload : response.data});
				}else {
					dispatch({ type : CHANNEL_INFO_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : CHANNEL_INFO_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});    


		setOpenPidsEditDrawer(true);
	};

	const onCloseEditDrawer = () =>{
		setOpenPidsEditDrawer(false);
	};



	const editPidsFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			pidsName : data.details && data.details.pidsName ?  data.details.pidsName : '',
			ip: data.details &&  data.details.ip ? data.details.ip : '',
			latitude: data.details &&  data.details.latitude ? data.details.latitude : '',
			longitude : data.details &&  data.details.longitude ? data.details.longitude : null,
			id : data.details &&  data.details.id ? data.details.id : null,
			parentAlarmType : data.details && data.details.parentAlarmType ? data.details.parentAlarmType : ''
			// channel : data.details && data.details.channel
		},
		onSubmit: (values) => {

			const pidsName = values.pidsName ? values.pidsName : '';
			const ip = values.ip ? values.ip : '';
			const latitude = values.latitude ? values.latitude : '';
			const longitude = values.longitude ? values.longitude : '';
			const id = editPidsFormik.values.id;
			const parentAlarmType = editPidsFormik.values.parentAlarmType;
			// const channel = values.channel ? values.channel : '';
			// const channel_name = data.channel_data && data.channel_data.map((item)=>(
			// 	'channel'+item.id
			// )
			// );
			// console.log("channel name",channel_name);

			if (id) {
				dispatch({ type : PIDS_INFO_UPDATE });
				let data = JSON.stringify({
					'name': pidsName,
					'ipaddress': ip,
					'latitude': latitude,
					'longitude': longitude,
					'parentAlarmType':parentAlarmType

				});

				let config = {
					method: 'patch',
					maxBodyLength: Infinity,
					url:`${api}/pidsupdate/${id}`,
					timeout: timeout,
					headers: { 
						'Content-Type': 'application/json', 
						'Authorization': `Bearer ${authtoken}`
					},
					data : data
				};

				axios.request(config)
					.then((response) => {
						if ( response.status === 200 || response.status === 201 ) {
							dispatch({ type : PIDS_INFO_UPDATE_SUCCESS });
							Modal.success({ title: langdata && langdata.Pidsinfoupdatedsuccessfully ? langdata.Pidsinfoupdatedsuccessfully : 'Pids info updated successfully.' });
							pidsmanagementdata({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
							setOpenPidsEditDrawer(false);

						} else {
							dispatch ({ type : PIDS_INFO_UPDATE_ERROR });
							Modal.error({ title: langdata && langdata.Pidsinfonotupdated ? langdata.Pidsinfonotupdated : 'Pids info not updated.' });
						}
					})
					.catch((error) => {
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
							});
						}
						dispatch({ type : PIDS_INFO_UPDATE_ERROR});
						console.log(error);
					});
			}

		}
	});

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());
	
	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.PidsmanagementInfo ? langdata.PidsmanagementInfo : 'The PIDS controller details like the IP address, number of channels and the \
				Parent Alarm type setting are displayed. The Parent Alarm type setting allows \
				to change the alarm which needs to be displayed as the latest alarm.\
				&apos;First&apos; will display the first alarm in the alarm list and \
				subsequent alarms as the related alarms. &apos;Last&apos; will display \
				the latest or last alarm in the alarm list and previous alarms as the related alarms.'}</p>
		</Card>
	);
  

	return (
		<>
			<Content>
				<Row gutter={{
					xs: 8,
					sm: 16,
					md: 24,
					lg: 32,
				}}
				style={{
					margin: '10px',
					// paddingLeft: '10px',
					// paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center',
				}}
				>
					<Col span={8}>
						<Row>
							<Space>
								<ToolOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.PIDSManagement ? langdata.PIDSManagement : 'PIDS Management'}</Title>
						</Row>
					</Col>
					<Col span={16}>
						<Row justify="end">
							<Space>
								<Typography>
									<Popover title={langdata && langdata.PIDSManagement ? langdata.PIDSManagement : 'PIDS Management'} content={infoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover>
								</Typography>
							</Space>
						</Row>
					</Col>
					{/* </Space> */}
				</Row>

				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data.pid_data.length > 0 ? (
							<Table
								scroll={{
									x: 900,
								}}
								columns={columns} dataSource={data.pid_data}
								pagination = { data.pid_data.length > 0 ? data_pagination : 0 }
								onChange = { pidsmanagementdata }
							/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>

				<Modal
					title={langdata && langdata.PIDSConfigurations ? langdata.PIDSConfigurations : 'PIDS Configurations'}
					width={720}
					open={openpidsEditDrawer}
					destroyOnClose={true}
					onOk={editPidsFormik.handleSubmit}
					onCancel={onCloseEditDrawer}

					styles={{
						body: {
						},
					}}					
					
					footer={[
						// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={onCloseEditDrawer}>
								{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}
							</Button>
							<Button onClick={editPidsFormik.handleSubmit} type="primary">
								{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE' }
							</Button>
						</Space>
					]}
				>
					<Form layout="vertical" onSubmit={editPidsFormik.handleSubmit}>

						<br />

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="pidsName" label={langdata && langdata.PIDSName ? langdata.PIDSName : 'PIDS Name'} rules={[{ required: true, message: langdata && langdata.Pleaseenterpidsname ? langdata.Pleaseenterpidsname : 'Please enter pids name' }]} >

									<Input disabled placeholder={langdata && langdata.PIDSName ? langdata.PIDSName :'PIDS Name'} onChange={editPidsFormik.handleChange} defaultValue={editPidsFormik.values.pidsName} name='pidsName' />
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="ip" label={langdata && langdata.IPAddress ? langdata.IPAddress : 'IP Address'} rules={[{ required: true, message: langdata && langdata.Pleaseenteripaddress ? langdata.Pleaseenteripaddress :  'Please enter ip address' }]} >

									<Input placeholder={langdata && langdata.IPAddress ? langdata.IPAddress : 'IP Address'} onChange={editPidsFormik.handleChange} defaultValue={editPidsFormik.values.ip} name='ip' />
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="latitude" label={langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'} rules={[{ required: true, message: langdata && langdata.Pleaseenterlatitude ? langdata.Pleaseenterlatitude : 'Please enter latitude' }]} >

									<Input placeholder={langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'} onChange={editPidsFormik.handleChange} defaultValue={editPidsFormik.values.latitude} name='latitude' />
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="longitude" label={langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'} rules={[{ required: true, message: langdata && langdata.Pleaseenteryourlongitude ? langdata.Pleaseenteryourlongitude : 'Please enter your longitude' }]} >

									<Input placeholder={langdata && langdata.Longitude ? langdata.Longitude :'Longitude'}  onChange={editPidsFormik.handleChange} defaultValue={editPidsFormik.values.longitude} name='longitude'  />
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="parentAlarmType" label={langdata && langdata.ParentAlarmType ? langdata.ParentAlarmType : 'Parent Alarm Type'} rules={[{ required: false, message: langdata && langdata.Pleaseenteralarmtype ? langdata.Pleaseenteralarmtype :  'Please enter alarm type' }]} >
									<Select style={{ width : '100%'}} placeholder={langdata && langdata.ParentAlarmType ? langdata.ParentAlarmType : 'Parent Alarm Type'} options={alarmtypeOptions} defaultValue={editPidsFormik.values.parentAlarmType} onChange={(value) => editPidsFormik.setFieldValue('parentAlarmType', value)} name = 'parentAlarmType' showSearch filterOption={filterOption}/>
								</Form.Item>
							</Col>
						</Row>

						{data.channel_data && data.channel_data.map((item)=>(

							<Row key={item.id} gutter={24}>
								<Col span={24} >
									<Form.Item name="Channel Name" label={`${langdata && langdata.Channel ? langdata.Channel : 'Channel'} ${+item.channelId} ${langdata && langdata.Name ? langdata.Name : 'Name'}`} rules={[{ required: true, message: langdata && langdata.Pleaseenterchannel ? langdata.ChanPleaseenterchannelnel : 'Please enter channel' }]} >
										<Input placeholder="Channel Name" onChange={editPidsFormik.handleChange} defaultValue={item.name} name={`${'channel'+item.channelId}`} disabled={true} />
									</Form.Item>
								</Col>

							</Row>

						))}						

					</Form>
				</Modal>


			</Content>
			<Spin spinning={data.pending} fullscreen />

		</>
	);
}