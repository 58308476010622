export const CPS_REPORT_LIST = 'CPS_REPORT_LIST';
export const CPS_REPORT_LIST_SUCCESS = 'CPS_REPORT_LIST_SUCCESS';
export const CPS_REPORT_LIST_ERROR = 'CPS_REPORT_LIST_ERROR';

export const TLPS_LIST = 'TLPS_LIST';
export const TLPS_LIST_SUCCESS = 'TLPS_LIST_SUCCESS';
export const TLPS_LIST_ERROR = 'TLPS_LIST_ERROR';

export const TLPS_FETCH_LIST = 'TLPS_FETCH_LIST';
export const TLPS_FETCH_LIST_SUCCESS = 'TLPS_FETCH_LIST_SUCCESS';
export const TLPS_FETCH_LIST_ERROR = 'TLPS_FETCH_LIST_ERROR';
