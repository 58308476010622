import { React, useEffect, useReducer, useState } from 'react';
import { useAuth } from '../Context';

import { Row, Col, Typography, Space, Table, Breadcrumb, Tooltip, Button, Input, Modal, Form, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { SearchOutlined, HomeOutlined, EditOutlined, DeleteOutlined,PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import terrainClassificationReducer from './reducer.js';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

import { ADD_TERRAIN, ADD_TERRAIN_ERROR, ADD_TERRAIN_SUCCESS, DELETE_TERRAIN, DELETE_TERRAIN_ERROR, DELETE_TERRAIN_SUCCESS, GET_TERRAIN, GET_TERRAIN_ERROR, GET_TERRAIN_SUCCESS, UPDATE_TERRAIN, UPDATE_TERRAIN_ERROR, UPDATE_TERRAIN_SUCCESS } from './constants';
import { useFormik } from 'formik';

const { Title } = Typography;

export default function TerrianData() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
	const initalState = {terrains: [], pagination: { total: 0, limit: 10, skip: 0, current:1 }, details: null, loading: false, error: false };
	const [data, dispatch] = useReducer(terrainClassificationReducer, initalState);

	const [showTerrainClassificationModal, setShowTerrainClassificationModal] = useState(false);
	const [showAddTerrainClassificationModal, setShowAddTerrainClassificationModal] = useState(false);
	const [deleteId, setDeleteId] = useState();
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [updateData, setUpdateData] = useState();
	
	const navigate = useNavigate();
	useAuth();

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	// const data=[];
  
	const columns = [
		{
			title: langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			align:'center',
			render: (_, record) => (
				<Space size="middle">
					<a title={langdata && langdata.EditTerrainClassification ? langdata.EditTerrainClassification : 'Edit Terrain Classification'} onClick={() => {handleEdit(record);}}><EditOutlined /></a>
					<a title={langdata && langdata.DeleteTerrainClassification ? langdata.DeleteTerrainClassification : 'Delete Terrain Classification'} onClick={() => {handleDelete(record.id);}}><DeleteOutlined /></a>
				</Space>
			)
		}
	];

	const handleEdit = (value) => {
		setShowTerrainClassificationModal(true);
		setUpdateData(value);

	};

	const closeTerrainClassificationModal = () => {
		setShowTerrainClassificationModal(false);
	};
	
	const closeSAddTerrainClassificationModal = () => {
		setShowAddTerrainClassificationModal(false);
	};

	const handleDelete = (value) => {
		setDeleteId(value);
		setShowDeleteModal(true);
	};

	const closeDeleteModal = () => {
		setShowDeleteModal(false);
	};

	useEffect(() => {
		loadTerrains(1);
	}, [authtoken]);

	const loadTerrains = (pagination) => {

		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		dispatch({ type : GET_TERRAIN });

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/terrains?$limit=${_pagination.limit}&$skip=${_pagination.skip}&$sort[name]=+1`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if(response.status === 200) {
					dispatch({ type : GET_TERRAIN_SUCCESS, payload : response.data.data, page:pagination.current});
				}
				else {
					dispatch({ type : GET_TERRAIN_ERROR});
				}
			})
			.catch((error) => {
				dispatch({ type : GET_TERRAIN_ERROR});
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const terrainData = [];
	data && data.terrains.map((item) => {
		terrainData.push({
			id : item.id,
			name : item.name
		});
	});

	const updateTerrainClassificationFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			id:  updateData && updateData.id ? updateData.id : null,
			name:  updateData && updateData.name ? updateData.name : null,
			
		},
		onSubmit: (values) => {
			const {id, name} = values;
			
			if(name) {
				let config = {
					method: 'patch',
					maxBodyLength: Infinity,
					url: `${api}/terrains/${id}`,
					timeout: timeout,
					headers: {
						accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization:`Bearer ${authtoken}`
					},
					data: {
						name: name
					}
				};

				dispatch( { type: UPDATE_TERRAIN });

				axios
					.request(config)
					.then((response) => {
						if (response.status === 200 || response.status === 201) {
							dispatch({type: UPDATE_TERRAIN_SUCCESS, payload: response});
							Modal.success({ title: langdata && langdata.Terrainclassificationdetailsupdatedsuccessfully ? langdata.Terrainclassificationdetailsupdatedsuccessfully : 'Terrain classification details updated successfully.' });
							closeTerrainClassificationModal();
							loadTerrains({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
						} else {
							dispatch({ type: UPDATE_TERRAIN_ERROR });
							Modal.warning({ title: langdata && langdata.Unabletoupdatetheterrainclassificationdetails ? langdata.Unabletoupdatetheterrainclassificationdetails : 'Unable to update the terrain classification details.' });  
						}
					})
					.catch((error) => {
						console.log(error);
						dispatch({ type: UPDATE_TERRAIN_ERROR });
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication : 'It seems the network is unreachable. Please refresh the application.',
							});
						}  
					});
			} else {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheterrainname ? langdata.Pleasespecifytheterrainname : 'Please specify the terrain name' });
			}
		}
	});


	const handleAddTerrainData = () => {
		setShowAddTerrainClassificationModal(true);
	};
  
	const addTerrainClassificationFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			name:  null,
		},
		onSubmit: (values) => {
			const {name} = values;
			
			if (name) {
				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: `${api}/terrains`,
					timeout: timeout,
					headers: {
						accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization:`Bearer ${authtoken}`
					},
					data: {
						name: name
					}
				};

				dispatch( { type: ADD_TERRAIN });

				axios
					.request(config)
					.then((response) => {
						if (response.status === 200 || response.status === 201) {
							dispatch({type: ADD_TERRAIN_SUCCESS, payload: response});
							Modal.success({ title: langdata && langdata.Terrainclassificationdetailsaddedsuccessfully ? langdata.Terrainclassificationdetailsaddedsuccessfully : 'Terrain classification details added successfully.' });
							closeSAddTerrainClassificationModal();
							loadTerrains({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
						} else {
							dispatch({ type: ADD_TERRAIN_ERROR });
							Modal.warning({ title: langdata && langdata.Unabletoaddtheterrainclassificationdetails ? langdata.Unabletoaddtheterrainclassificationdetails : 'Unable to add the terrain classification details.' });  
						}
					})
					.catch((error) => {
						console.log(error);
						dispatch({ type: ADD_TERRAIN_ERROR });
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
							});
						} 
					});
			} else {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheterrainname ? langdata.Pleasespecifytheterrainname : 'Please specify the terrain name' });  
			}
		}
	});

	const deleteTerrain = () => {

		dispatch( { type: DELETE_TERRAIN });

		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/terrains/${deleteId}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
		};


		axios
			.request(config)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					dispatch({type: DELETE_TERRAIN_SUCCESS , payload: response});
					Modal.success({ title: langdata && langdata.Terrainclassificationdetailsdeletedsuccessfully ? langdata.Terrainclassificationdetailsdeletedsuccessfully : 'Terrain classification details deleted successfully.' });
					// deleteTerrain();
					setShowDeleteModal(false);
					loadTerrains({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
				} else {
					dispatch({ type: DELETE_TERRAIN_ERROR });
					Modal.warning({ title: langdata && langdata.Unabletodeletedtheterrainclassificationdetails ? langdata.Unabletodeletedtheterrainclassificationdetails : 'Unable to deleted the terrain classification details.' });  
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type: DELETE_TERRAIN_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				} 
			});
	};

	return (
		permission==='superadmin' || permission === 'admin' ? 

			<>
				<Content>
					<Row 
						style={{
							margin : '10px'
						}}            
					>
						<Breadcrumb
							items={[
								{
									href: '/dashboard',
									title: <HomeOutlined />,
								},
								{
									title:  <a onClick={()=>{navigate(-1);}}> {langdata && langdata.TerrainClassification ? langdata.TerrainClassification : 'Terrain Classification'}</a> 
								},
								{
									title: langdata && langdata.TerrainManagement ? langdata.TerrainManagement : 'Terrain Management',
								},
							]}
						/>
					</Row>
					<Row 
						style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center'
						}} >

						<Col span={12}>
							<Row>
								<Space>
									<SearchOutlined style={{fontSize:'30px'}}/>
								</Space>
								<Space>&nbsp;&nbsp;</Space>
								<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.TerrainManagement ? langdata.TerrainManagement : 'Terrain Management'}</Title>
							</Row>
						</Col>
						<Col span={12}>
							<Row  style={{ display : 'flex', justifyContent : 'right'}}>
								<Col>
									<Tooltip placement="bottom" title={langdata && langdata.AddTerrain ? langdata.AddTerrain : 'Add Terrain'}>
										<Space>&nbsp;</Space>
										<Space>
												
											<Button 
												style={{ margin:'5px'}}
												type='primary'
												onClick={() => {handleAddTerrainData();}}
											><PlusOutlined/> {langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button>
										</Space>
									</Tooltip> 
								</Col>
							</Row>
						</Col>
					</Row>
	
					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}}>
						<Col span={24}>
							<Table 
								scroll={{
									x: 900,
								}}
								columns={columns} dataSource={terrainData} />
						</Col>
					</Row>
				</Content>

				<Modal
					title={langdata && langdata.UpdateTerrainClassification ? langdata.UpdateTerrainClassification : 'Update Terrain Classification' }
					centered
					destroyOnClose={true}
					open={showTerrainClassificationModal}
					onOk={{closeTerrainClassificationModal}}
					onCancel={closeTerrainClassificationModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeTerrainClassificationModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
							{ permission === 'superadmin' || permission === 'admin' ? <Button key="submit" type="primary" onClick={updateTerrainClassificationFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
						</Space>
					]}
					width={800}
				>
					<Form layout="vertical" onSubmit={updateTerrainClassificationFormik.handleSubmit}>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.TerrainClassificationName ? langdata.TerrainClassificationName : 'Terrain Classification Name'}  rules={[{ required: true, message: langdata && langdata.TerrainClassificationName ? langdata.TerrainClassificationName : 'Terrain Classification Name' }]} required>
									<Input name="name" placeholder={langdata && langdata.TerrainClassificationName ? langdata.TerrainClassificationName : 'Terrain Classification Name'} defaultValue={ updateTerrainClassificationFormik.values.name } onChange={updateTerrainClassificationFormik.handleChange} />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>

				<Modal
					title={langdata && langdata.AddTerrainClassification ? langdata.AddTerrainClassification : 'Add Terrain Classification'}
					centered
					destroyOnClose={true}
					maskClosable={false}
					open={showAddTerrainClassificationModal}
					onOk={{closeSAddTerrainClassificationModal}}
					onCancel={closeSAddTerrainClassificationModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeSAddTerrainClassificationModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
							{ permission === 'superadmin' || permission === 'admin' ? <Button key="submit" type="primary" onClick={addTerrainClassificationFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
						</Space>
					]}
					width={800}
				>
					<Form layout="vertical" onSubmit={addTerrainClassificationFormik.handleSubmit}>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item name="name" label= {langdata && langdata.TerrainClassificationName ? langdata.TerrainClassificationName : 'Terrain Classification Name'}  rules={[{ required: true, message: langdata && langdata.TerrainClassificationName ? langdata.TerrainClassificationName : 'Terrain Classification Name' }]} >
									<Input placeholder={langdata && langdata.TerrainClassificationName ? langdata.TerrainClassificationName : 'Terrain Classification Name'} onChange={addTerrainClassificationFormik.handleChange} />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>

				{/* Delete User Modal Starts */}
				<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'}  open={showDeleteModal} onOk={deleteTerrain} onCancel={closeDeleteModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeDeleteModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
							<Button key="submit" type="primary" onClick={deleteTerrain}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
						</Space>
					]}>
					<Row style={{
						marginTop: '10px',
						marginBottom: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={1}>
							<DeleteOutlined  style={{ fontSize: '14px'}}/>
						</Col>
						<Col span={23}>{langdata && langdata.TerrainClassificationName ? langdata.TerrainClassificationName : 'Are you sure you want to delete this terrain?'}</Col>
					</Row>
				</Modal>
				{/* Delete User Modal Ends */}
			

				{/* <Spin spinning={data.loading} fullscreen /> */}
			</> : null
	);
}