/* eslint-disable react/react-in-jsx-scope */
import { Row, Col, Typography, Button, Tag, Spin, Card, Input, Form, Modal, Alert, Breadcrumb, Select, Space, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useReducer } from 'react';
import axios from 'axios';
import mailTemplateReducer from './reducer';
import { useLocation, useNavigate } from 'react-router-dom';
import { MAIL_TEMPLATE_ADD, MAIL_TEMPLATE_ADD_ERROR, MAIL_TEMPLATE_ADD_SUCCESS} from './constants';
import TextArea from 'antd/es/input/TextArea';
import { useFormik } from 'formik';
import useResizeObserver from 'use-resize-observer';
import { MailOutlined, HomeOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useAuth } from '../../Context';

const { Title } = Typography;

export default function AddMailTemplate() {
	const { ref, height } = useResizeObserver();

	const initalState = { loading: false, error: false, mailTemplates : [], pagination: { total: 0, limit: 10, skip: 0, current:1 }};
	const [data, dispatch] = useReducer(mailTemplateReducer, initalState);

	const navigate = useNavigate();

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const { state } = useLocation();
	useAuth();
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;


	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!state) {
			navigate(-1);
		}
	},[authtoken]);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
	},[authtoken]);

	const addMailTemplateFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			mailType : '',
			subject: '',
			content: '',
			usertypeId : state && state.data ? state.data : null,
			pipelineId : pipelineId
		},
		onSubmit: (values) => {

			const mailType = values.mailType ? values.mailType : '';
			const subject = values.subject ? values.subject : '';
			const content = values.content ? values.content : '';
			const usertypeId = addMailTemplateFormik.values.usertypeId;
			const pipelineId = addMailTemplateFormik.values.pipelineId;

			if(!mailType) {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheemailtype ? langdata.Pleasespecifytheemailtype : 'Please specify the email type.' });
				return false;
			}
			if(!subject) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythesubject ? langdata.Pleasespecifythesubject : 'Please specify the subject.' });
				return false;
			}
			if(!content) {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheconrent ? langdata.Pleasespecifytheconrent : 'Please specify the conrent.' });
				return false;
			}

			if (usertypeId) {
				dispatch({ type : MAIL_TEMPLATE_ADD });
				let data = JSON.stringify({
					'mailType': mailType,
					'subject': subject,
					'content': content,
					'usertypeId': usertypeId,
					'pipelineId': parseInt(pipelineId)
				});

				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url:`${api}/mailconfigurations`,
					timeout: timeout,
					headers: { 
						'Content-Type': 'application/json', 
						'Authorization': `Bearer ${authtoken}`
					},
					data : data
				};

				axios.request(config)
					.then((response) => {
						if ( response.status === 200 || response.status === 201 ) {
							dispatch({ type : MAIL_TEMPLATE_ADD_SUCCESS });
							navigate(-1);
							Modal.success({ title:  langdata && langdata.Mailconfigurationaddedsuccessfully ? langdata.Mailconfigurationaddedsuccessfully :  'Mail configuration added successfully.' });
						} else {
							dispatch ({ type : MAIL_TEMPLATE_ADD_ERROR });
							Modal.error({ title:  langdata && langdata.Mailconfigurationnotadded ? langdata.Mailconfigurationnotadded :  'Mail configuration not added.' });
						}
					})
					.catch((error) => {
						dispatch ({ type : MAIL_TEMPLATE_ADD_ERROR });
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
							});
						}
						console.log(error);
					});
			}

		}
	});

	const NotificationType = [
		{
			value: 'Alarm Escalation',
			label: langdata && langdata.AlarmEscalation ? langdata.AlarmEscalation :  'Alarm Escalation',
		},
		{
			value: 'Attendance Report',
			label: langdata && langdata.AttendanceReport ? langdata.AttendanceReport : 'Attendance Report',
		},
	];

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		permission==='superadmin' || permission === 'admin' ? 

			<>
				{ state ?
					<>
						<Content>
							<Row style={{
								margin: '10px',
								paddingLeft: '10px',
								paddingRight: '10px',
								background: '#FFFFFF',
								alignItems:'center'
							}}>

								<Col span={8}>
									<Row>
										<Space>
											<MailOutlined style={{fontSize:'30px'}}/>
											{/* </Col> */}
										</Space>
										<Space>&nbsp;&nbsp;</Space>
					
										{/* <Col style={{marginLeft:'4px'}} span={19}> */}
										<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.AddMailConfiguration ? langdata.AddMailConfiguration : 'Add Mail Configuration'}</Title>
									</Row>
								</Col>
					
							</Row>
							<Row 
								style={{
									marginLeft:'15px',
									marginTop: '10px'
								}}            
							>
								<Breadcrumb
									items={[
										{
											href: '/dashboard',
											title: <HomeOutlined />,
										},
										{
											title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.MailConfiguration ? langdata.MailConfiguration : 'Mail Configuration'}</a> 
										},
										{
											title: langdata && langdata.AddMailConfiguration ? langdata.AddMailConfiguration : 'Add Mail Configuration',
										},
									]}
								/>
							</Row>
							<Row style={{
								margin: '10px',
								paddingLeft: '10px',
								paddingRight: '10px',
								alignItems:'center'
							}} >
								<span>
									<Tag color="blue">&#123;&#123;date_today&#125;&#125; { langdata && langdata.toreplacethedate ? langdata.toreplacethedate : 'to replace the date'}</Tag>
								</span>
								<span>
									<Tag color="blue">&#123;&#123;count&#125;&#125; { langdata && langdata.toreplacetheAlarmCount ? langdata.toreplacetheAlarmCount : 'to replace the Alarm Count'}</Tag>
								</span>
								<span>
									<Tag color="blue">&#123;&#123;hours&#125;&#125; { langdata && langdata.toreplacethehours ? langdata.toreplacethehours : 'to replace the hours'}</Tag>
								</span>
								<span>
									<Tag color="blue">&#123;&#123;asparagus_cid&#125;&#125; { langdata && langdata.toreplaceImage ? langdata.toreplaceImage : 'to replace Image'}</Tag>
								</span>
							</Row>
							<Row style={{
								margin: '10px',
								paddingLeft: '10px',
								paddingRight: '10px',
								alignItems:'center'
							}} >
								<Col span={12} >
									<Card style={{ height : height, minHeight:'750px'}} >
										<Form layout="vertical" onSubmit={addMailTemplateFormik.handleSubmit}>
											<Col span={24}>
												<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{ langdata && langdata.EmailType ? langdata.EmailType : 'Email Type'} :</Typography>
												<Select style={{ width : '100%'}} placeholder={ langdata && langdata.EmailType ? langdata.EmailType : 'Email Type'} options={NotificationType} onChange={(value) => addMailTemplateFormik.setFieldValue('mailType', value)} name = 'mailType' showSearch filterOption={filterOption}/>
											</Col>
                            &nbsp;
											<Col span={24}>
												<Typography><span style={{ color : 'red'}}>*&nbsp;</span> { langdata && langdata.Subject ? langdata.Subject : 'Subject'} :</Typography>
												<Input placeholder={ langdata && langdata.Subject ? langdata.Subject : 'Subject'} onChange={addMailTemplateFormik.handleChange} name = 'subject'/>
											</Col>
                            &nbsp;
											<Col span={24}>
												<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{ langdata && langdata.Content ? langdata.Content : 'Content'} :</Typography>
												<TextArea rows={20} placeholder={ langdata && langdata.Content ? langdata.Content : 'Content'} onChange={addMailTemplateFormik.handleChange} name='content'/>
											</Col>
                            &nbsp;
											<Col span={24} style={{ display : 'flex', justifyContent : 'flex-end'}}>
												<Button onClick={() => {navigate(-1);}}>{ langdata && langdata.Cancel ? langdata.Cancel : 'Cancel'}</Button>
												<Button type="primary" style={{ marginLeft : '2%'}} onClick={addMailTemplateFormik.handleSubmit}>{ langdata && langdata.AddConfiguration ? langdata.AddConfiguration : 'Add Configuration'}</Button>
											</Col>
										</Form>
									</Card>
								</Col>
								<Col span={12} style={{ height : '100%'}}>
									<Card ref={ref} style={{minHeight:'750px'}}>
										<h1>{ langdata && langdata.MailSubjectandContentPreview ? langdata.MailSubjectandContentPreview : 'Mail Subject and Content Preview'}</h1>
										<br />
										{ addMailTemplateFormik.values.subject || addMailTemplateFormik.values.content ? 
											<>
												<Typography style={{ fontSize : '25px'}}>{ langdata && langdata.Subject ? langdata.Subject : 'Subject'}: {addMailTemplateFormik.values.subject}</Typography>
												<br />
												<Typography
													dangerouslySetInnerHTML={{
														__html: addMailTemplateFormik.values.content,
													}}/>
											</>
											:
											(
												<Alert
													message={ langdata && langdata.SubjectandContentpreview ? langdata.SubjectandContentpreview : 'Subject and Content preview'}
													description={ langdata && langdata.PleaseAddsubjectorcontenttogetpreview ? langdata.PleaseAddsubjectorcontenttogetpreview : 'Please Add subject or content to get preview.'}
													type="info"
													showIcon
												/>
											)}
									</Card>
								</Col>
							</Row>
							<Spin fullscreen spinning={data.loading} />
						</Content>
					</> : null }

			</> : null 
	);
}