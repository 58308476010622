import { RELATED_ALARMS_REPORT, RELATED_ALARMS_REPORT_ERROR, RELATED_ALARMS_REPORT_SUCCESS, REPORT_ALARM_DETAILS, REPORT_ALARM_LIST, REPORT_ALARM_LIST_ERROR, REPORT_ALARM_LIST_SUCCESS } from './constants';

export default function reportdata(state, action) {

	switch (action.type) {
      
	case REPORT_ALARM_LIST:
		return { ...state, pending: true };
	case REPORT_ALARM_LIST_SUCCESS:
		return { ...state, alarm_data: action.payload, pending: false };
	case REPORT_ALARM_LIST_ERROR:
		return { ...state, error: true, pending: false };
	case REPORT_ALARM_DETAILS:
		return { ...state, details: action.payload, error: true };
	case RELATED_ALARMS_REPORT:
		return { ...state, pending: true, error: false };				
	case RELATED_ALARMS_REPORT_SUCCESS:
		return { ...state, pending: false, error: false };				
	case RELATED_ALARMS_REPORT_ERROR:
		return { ...state, pending: false, error: true };
      
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}