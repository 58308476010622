export const REALTIME_ALARM_LIST = 'REALTIME_ALARM_LIST';
export const REALTIME_ALARM_SUCCESS = 'REALTIME_ALARM_SUCCESS';
export const REALTIME_ALARM_ERROR = 'REALTIME_ALARM_ERROR';
export const REALTIME_ALARM_DETAILS = 'REALTIME_ALARM_DETAILS';
export const REALTIME_ALARM_UPDATE_DETAILS = 'REALTIME_ALARM_UPDATE_DETAILS';


export const REALTIME_GROUPED_ALARM_LIST = 'REALTIME_GROUPED_ALARM_LIST';
export const REALTIME_GROUPED_ALARM_SUCCESS = 'REALTIME_GROUPED_ALARM_SUCCESS';
export const REALTIME_GROUPED_ALARM_ERROR = 'REALTIME_GROUPED_ALARM_ERROR';


export const REALTIME_RELATED_ALARM_LIST = 'REALTIME_RELATED_ALARM_LIST';
export const REALTIME_RELATED_ALARM_SUCCESS = 'REALTIME_RELATED_ALARM_SUCCESS';
export const REALTIME_RELATED_ALARM_ERROR = 'REALTIME_RELATED_ALARM_ERROR';


export const REALTIME_REGION_ALARM_LIST = 'REALTIME_REGION_ALARM_LIST';
export const REALTIME_REGION_ALARM_SUCCESS = 'REALTIME_REGION_ALARM_SUCCESS';
export const REALTIME_REGION_ALARM_ERROR = 'REALTIME_REGION_ALARM_ERROR';

export const REALTIME_COMMENTS = 'REALTIME_COMMENTS';
export const REALTIME_COMMENTS_SUCCESS = 'REALTIME_COMMENTS_SUCCESS';
export const REALTIME_COMMENTS_ERROR = 'REALTIME_COMMENTS_ERROR';

export const REALTIME_POST_COMMENTS = 'REALTIME_POST_COMMENTS';
export const REALTIME_POST_COMMENTS_SUCCESS = 'REALTIME_POST_COMMENTS_SUCCESS';
export const REALTIME_POST_COMMENTS_ERROR = 'REALTIME_POST_COMMENTS_ERROR';

export const REALTIME_FILTER_ALARM = 'REALTIME_FILTER_ALARM';
export const REALTIME_FILTER_ALARM_SUCCESS = 'REALTIME_FILTER_ALARM_SUCCESS';
export const REALTIME_FILTER_ALARM_ERROR = 'REALTIME_FILTER_ALARM_ERROR';

export const REALTIME_PATCH_ALARM = 'REALTIME_PATCH_ALARM';
export const REALTIME_PATCH_ALARM_SUCCESS = 'REALTIME_PATCH_ALARM_SUCCESS';
export const REALTIME_PATCH_ALARM_ERROR = 'REALTIME_PATCH_ALARM_ERROR';


export const ACKNOWLEGEMENT_ALARM = 'ACKNOWLEGEMENT_ALARM';
export const ACKNOWLEGEMENT_ALARM_SUCCESS = 'ACKNOWLEGEMENT_ALARM_SUCCESS';
export const ACKNOWLEGEMENT_ALARM_ERROR = 'ACKNOWLEGEMENT_ALARM_ERROR';

export const ACKNOWLEGEMENT_ALARM_FILE_UPLOAD = 'ACKNOWLEGEMENT_ALARM_FILE_UPLOAD';
export const ACKNOWLEGEMENT_ALARM_FILE_UPLOAD_SUCCESS = 'ACKNOWLEGEMENT_ALARM_FILE_UPLOAD_SUCCESS';
export const ACKNOWLEGEMENT_ALARM_FILE_UPLOAD_ERROR = 'ACKNOWLEGEMENT_ALARM_FILE_UPLOAD_ERROR';

export const RESOLUTION_ALARM = 'RESOLUTION_ALARM';
export const RESOLUTION_ALARM_SUCCESS = 'RESOLUTION_ALARM_SUCCESS';
export const RESOLUTION_ALARM_ERROR = 'RESOLUTION_ALARM_ERROR';

export const TLP_ALARM = 'TLP_ALARM';
export const TLP_ALARM_SUCCESS = 'TLP_ALARM_SUCCESS';
export const TLP_ALARM_ERROR = 'TLP_ALARM_ERROR';

export const RELATED_ALARMS = 'RELATED_ALARMS';
export const RELATED_ALARMS_SUCCESS = 'RELATED_ALARMS_SUCCESS';
export const RELATED_ALARMS_ERROR = 'RELATED_ALARMS_ERROR';

export const REALTIME_ALARM_MAP_DATA = 'REALTIME_ALARM_MAP_DATA';
export const REALTIME_ALARM_MAP_DATA_SUCCESS = 'REALTIME_ALARM_MAP_DATA_SUCCESS';
export const REALTIME_ALARM_MAP_DATA_ERROR = 'REALTIME_ALARM_MAP_DATA_ERROR';