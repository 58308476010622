import React, { useEffect, useState, useReducer } from 'react';

import { Empty, Row, Col, Typography, Button, Space, Table, Form, Input, Modal, Spin, Popover, Avatar, Card, message } from 'antd';
import { Content } from 'antd/es/layout/layout';

import regionManagementReducer from './reducer';

import { ToolOutlined, EditOutlined, EyeOutlined, InfoOutlined } from '@ant-design/icons';
import { REGION_LIST, REGION_LIST_DETAILS, REGION_LIST_ERROR, REGION_LIST_SUCCESS, UPDATE_REGION, UPDATE_REGION_DATA, UPDATE_REGION_DATA_ERROR, UPDATE_REGION_DATA_SUCCESS, UPDATE_REGION_ERROR, UPDATE_REGION_SUCCESS } from './constants';
import axios from 'axios';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useAuth } from '../../Context';

const { Title } = Typography;

export default function RegionManagement() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = {region : [], pending: false, error: false, regionDetails : null, details:null };
	const [data, dispatch] = useReducer(regionManagementReducer, initalState);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const navigate = useNavigate();
	useAuth();

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const [showEditRegionModal, setShowEditRegionModal] = useState(false);

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	useEffect(() => {
		getRegion();
	},[authtoken]);

	useEffect(() => {
		if(featuresModule.regionMgmtEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 402);
	}, []);

	const getRegion = () => {
		dispatch({ type : REGION_LIST });
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/regioninfo?$limit=1000`,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: {
      
			}
		};
      
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch ({ type : REGION_LIST_SUCCESS, payload : response.data.data });
				} 
				else{
					dispatch({ type : REGION_LIST_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : REGION_LIST_ERROR });
			});
	};

	const loadRegionDetails = ((id) => {
		let details = null;

		if (data && data.region) {
			data.region && data.region.map((item) => {
				if (item.id === id) {
					details = item;
				}

				return true;
			});
      
			dispatch( { type: UPDATE_REGION_DATA });
    
			if (!details) {
				dispatch({ type: UPDATE_REGION_DATA_ERROR });
			} else {
				dispatch({ type: UPDATE_REGION_DATA_SUCCESS, payload: details });

			}
		} else {
			dispatch({ type: UPDATE_REGION_DATA_ERROR }); 
		}
	});

	const updateRegion = useFormik({
		enableReinitialize: true,
		initialValues: {
			id : data.regionDetails && data.regionDetails.id,
			regionName : data.regionDetails && data.regionDetails.regionName
		},
		onSubmit: (values) => {
			const id = updateRegion.values.id;
			const {regionName} = values;
			if(!regionName) {
				Modal.warning({ title: langdata && langdata.Pleaseentertheregionname ? langdata.Pleaseentertheregionname : 'Please enter the region name' });
				return false;
			}
			dispatch({ type : UPDATE_REGION });
			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/regioninfo/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					regionName : regionName
				}
			};
		
			axios
				.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch ({ type : UPDATE_REGION_SUCCESS});
						Modal.success({ title: langdata && langdata.Regionupdatedsuccessfully ? langdata.Regionupdatedsuccessfully : 'Region updated successfully.' });
						getRegion();
					} 
					else{
						dispatch({ type : UPDATE_REGION_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletoupdatetheregion ? langdata.Unabletoupdatetheregion : 'Unable to update the region.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : UPDATE_REGION_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
			closeEditRegionModal();
		}
	});

	const columns = [

		{
			title: langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex: 'regionName',
			key: 'regionName',
		},        
        
		{
			title: langdata && langdata.StartChainage ? langdata.StartChainage : 'Start Chainage',
			dataIndex: 'startChainage',
			key: 'startChainage',
  
		},
		{
			title: langdata && langdata.EndChainage ? langdata.EndChainage : 'End Chainage',
			dataIndex: 'endChainage',
			key: 'endChainage',
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (_,record) => (

				<Space size="middle">
					{permission === 'superadmin' ?
						<a title={langdata && langdata.EditRegion ? langdata.EditRegion : 'Edit Region'} onClick={openEditRegionModal}><EditOutlined/></a> : null }
					<a title={langdata && langdata.ViewRegion ? langdata.ViewRegion :'View Region'} onClick={()=>openDetailsRegionPage(record)} ><EyeOutlined/></a>
				</Space>
			)
		}
	];

	const openDetailsRegionPage=(data)=>{

		navigate(( '/region-details'),{ state: { data: data, path: 'region-management' } });
		dispatch({type:REGION_LIST_DETAILS, payload:data});

	};

	const openEditRegionModal = () => {
		setShowEditRegionModal(true);
	};

	const closeEditRegionModal = () => {
		setShowEditRegionModal(false);
	};

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p> {langdata && langdata.RegionManagementDescription1 ? langdata.RegionManagementDescription1 : 'Region management displays the details of different regions within the pipeline'} </p>
			{ permission === 'superadmin' ?
				<>
					< br/>
					<p>{langdata && langdata.RegionManagementDescription2 ? langdata.RegionManagementDescription2 : 'Region name can be modify from the edit button.'}</p> </> : null }
		</Card>
	);

	return (
	// permission==='superadmin' || permission === 'admin' ? 

		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center',
				}}
				>

					<Col span={8}>
						<Row>
							<Space>
								<ToolOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.RegionManagement ? langdata.RegionManagement : 'Region Management'}</Title>
						</Row>
					</Col>
					<Col span={16}>
						<Row justify="end">
							<Space>
								<Typography>
									<Popover title={langdata && langdata.RegionManagement ? langdata.RegionManagement : 'Region Management'}  content={infoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover>
								</Typography>
							</Space>
						</Row>
					</Col>
					
						
				</Row>
				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data && data.region.length > 0 ? (
							<Table
								scroll={{
									x: 900,
								}} 
								columns={columns} 
								dataSource={data && data.region} 
								onRow = {(record) => {
									return {
										onClick: () => { 
											loadRegionDetails(record.id);
										}
									};
								}} 
							/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>
			</Content>
			<Modal
				title={langdata && langdata.EditRegion ? langdata.EditRegion : 'Edit Region'}
				centered
				open={showEditRegionModal}
				onOk={{closeEditRegionModal}}
				onCancel={closeEditRegionModal}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeEditRegionModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{ permission === 'superadmin' || permission === 'admin' ? <Button type="primary" onClick={updateRegion.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button> : null }
					</Space>
				]}
				width={500}
			>
				<Form layout="vertical" onSubmit={updateRegion.handleSubmit}>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.RegionName ? langdata.RegionName : 'Region Name'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheregionname ? langdata.Pleaseentertheregionname : 'Please enter the region name' }]} required>
								<Input name="regionName" onChange={updateRegion.handleChange} placeholder={langdata && langdata.Pleaseentertheregionname ? langdata.Pleaseentertheregionname : 'Please enter the region name'} value={updateRegion.values.regionName} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Spin fullscreen spinning={data.pending} />
      

		</> 
	// : null
	);
}