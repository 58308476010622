import { WELL_TYPE_LIST, WELL_TYPE_LIST_SUCCESS, WELL_TYPE_LIST_ERROR, WELL_TYPE_DETAILS, WELL_TYPE_DETAILS_SUCCESS, WELL_TYPE_DETAILS_ERROR, UPDATE_WELL_TYPE, UPDATE_WELL_TYPE_SUCCESS, UPDATE_WELL_TYPE_ERROR } from './constants';

export default function welltypeManagementReducer(state, action) {
	let pagination = {}; 

	switch (action.type) {
	case WELL_TYPE_LIST:
		return { ...state, pending: true };
			
	case WELL_TYPE_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
		}

		return { ...state, welltype : action.payload, pagination: pagination,  pending: false, error: false };
				
	case WELL_TYPE_LIST_ERROR:
		return { ...state, pending: false, error: true };

	case WELL_TYPE_DETAILS:
		return { ...state, pending: true };

	case WELL_TYPE_DETAILS_SUCCESS:
		return { ...state, details: action.payload, pending: false, error: false };
	
	case WELL_TYPE_DETAILS_ERROR:
		return { ...state, pending: false, error: true };

	case UPDATE_WELL_TYPE:
		return { ...state, pending: true };
					
	case UPDATE_WELL_TYPE_SUCCESS:
		return { ...state, pending: false, error: false };
						
	case UPDATE_WELL_TYPE_ERROR:
		return { ...state, pending: false, error: true };
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}