import { CHAINAGES_LIST, CHAINAGES_LIST_SUCCESS, CHAINAGES_LIST_ERROR } from './constants';
import { CHAINAGE_DETAILS, CHAINAGE_DETAILS_SUCCESS, CHAINAGE_DETAILS_ERROR } from './constants';
import { UPDATE_CHAINAGE, UPDATE_CHAINAGE_SUCCESS, UPDATE_CHAINAGE_ERROR } from './constants';

export default function chaingeFilterReducer(state, action) {
	let chainages = [];
	let pagination = {};

	switch (action.type) {
	case CHAINAGES_LIST:
		return { ...state, loading: true, error: false };

	case CHAINAGES_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
			pagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.pipelineChainage = item.pipelineChainage || item.pipelineChainage === 0 ? item.pipelineChainage : '';
				data.ODMeter = item.ODMeter;
				data.latitude = item.latitude ? parseFloat(item.latitude) : null;
				data.longitude = item.longitude ? parseFloat(item.longitude) : null;
				data.rouMarker = item.rouMarker ? item.rouMarker : '';
				data.terrainClassification = item.terrainClassification ? item.terrainClassification  : '';
				data.vulnerablePoint = item.vulnerablePoint ? item.vulnerablePoint : false;
				data.isSnoozed = item.isSnoozed ? item.isSnoozed : false;
				data.createdAt = item.createdAt ? item.createdAt : null;
				data.updatedAt = item.updatedAt ? item.updatedAt : null;

				return chainages.push(data);
			});

			let sortedChainages = chainages.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, chainages: sortedChainages, pagination: pagination, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, chainages: chainages, pagination: pagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case CHAINAGES_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case CHAINAGE_DETAILS:
		return { ...state, loading: true, error: false };

	case CHAINAGE_DETAILS_SUCCESS:
		return { ...state, details: action.payload, loading: false, error: false };

	case CHAINAGE_DETAILS_ERROR:
		return { ...state, loading: false, error: true };

	case UPDATE_CHAINAGE:
		return { ...state, loading: true, error: false };

	case UPDATE_CHAINAGE_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case UPDATE_CHAINAGE_ERROR:
		return { ...state, loading: false, error: true };

	default: 
		throw Error('Unknown action: ' + action.type);
      
	}
}