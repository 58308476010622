import { React, useEffect, useReducer, useState } from 'react';

import { Row, Col, Typography, Space, Table, Tag, Tabs, Empty, message, Spin, Popover, Avatar, Card, Tooltip, Select } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { InfoOutlined, HistoryOutlined} from '@ant-design/icons';
import { RETRAIN_HISTORY_BATCH, RETRAIN_HISTORY_BATCH_ERROR, RETRAIN_HISTORY_BATCH_SUCCESS,  RETRAIN_HISTORY_DATA_ERROR} from './constants';
import axios from 'axios';
import modelReducer from './reducer';
import { datetimeCovertertolocal } from '../datetime-converter';
import TabPane from 'antd/es/tabs/TabPane';
import { useAuth } from '../Context';
import { useNavigate } from 'react-router-dom';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;

let model_data_pagination = {};


export default function RetrainingDataHistory() {


	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = { retrain_historydata:[], details: null, loading: false, error: false, modelDeploymentInfo:[]};
	const [data, dispatch] = useReducer(modelReducer, initalState);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const [tabValue, setTabValue] = useState(0);
	const { getPids, pidsData } = useAuth();
	const [pidsId, setPidsId] = useState(null);

	const navigate = useNavigate();

	let model_data= [];

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	if(data && data.batchData) {


		data && data.batchData.map((item)=>{

			const fromdatetime = datetimeCovertertolocal(item.createdAt);
			const fromdate = fromdatetime.localdate + ' ' +  fromdatetime.localtime;

			let pids = pidsData && pidsData.find((pid) => pid.id == item.pidsinfoId);
			if(pids != undefined || item.pidsinfoId == null) {
				model_data.push({
					id:item.id,
					fromDate : item.createdAt ? fromdate : '-',
					status: item.status,
					pidsinfo : pids && pids.name ? pids.name.toUpperCase(): item.pidsinfoId == null ? 'CENTRAL' : '',
					pidsId :  item.pidsinfoId
				});
			}
		});
	}

	const pidsList = [
		{
			value:null,
			label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
		},		
		{
			value:0,
			label:'CENTRAL'
		}];
	pidsData && pidsData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name.toUpperCase()
		});
	});
  
	const columns = [

		{
			title: `${langdata && langdata.Pids ? langdata.Pids:'Pids'}`,
			dataIndex: 'pidsinfo',
			key: 'pidsinfo',
			render: (name) => (
				<Space size="middle">
					{name != '-' ?
						<a title={langdata && langdata.ModelInfo ? langdata.ModelInfo:'Model Info'}>{name}</a>
						:
						<p>{name}</p> }
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)
		},
		{
			title: `${langdata && langdata.Date ? langdata.Date:'Date'}`,
			dataIndex: 'fromDate',
			key: 'fromDate',
		},
		
		{
			title: `${langdata && langdata.Status ? langdata.Status:'Status'}`,
			dataIndex: 'status',
			key: 'status',
			render: (_, status) => (
				<>
					{status &&
						<Tag color={status.status=='Retraining Started' ? 'gold' : status.status=='Retraining Completed' ? 'green' : 
							status.status=='Combining Started' ? 'gold' : status.status=='Combining Completed' ? 'green' : ''}>
							{status.status}
						</Tag>}
				</>
			),
			
		},
	];

	
	useEffect(() => {
		if(featuresModule.retrainingHistoryEnabled == false) {
			navigate('/dashboard');
		}
		if(!authtoken){
			navigate('/');
		}
		getPids(authtoken);
	},[authtoken]);

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 1132);

	}, []);
	
	useEffect(() => {
		modelData(1);
	}, [tabValue, pidsId]);


	const modelData = () => {

		
		let url = '';
		let params = '';
		if(pidsId || pidsId == 0){
			params +=`&pidsinfoId=${pidsId}`;
		}

		if(tabValue == '0'){
			url = `${api}/secondaryretrainingbatch?$sort[id]=-1${params}`;
		} else {
			url = `${api}/primaryretrainingbatch?$sort[id]=-1${params}`;
			// url = `${api}/primaryretraining-$sort[id]=-1&$limit=${_pagination.limit}&$skip=${_pagination.skip}${params}`;
		}

		dispatch({ type : RETRAIN_HISTORY_BATCH});
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					
					dispatch({ type : RETRAIN_HISTORY_BATCH_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : RETRAIN_HISTORY_BATCH_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : RETRAIN_HISTORY_DATA_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};
	
	
	const key = (value) => {
		if(tabValue == '0'){
			navigate(('/alarm-retraining-history'),{state : { data : value.pidsId, batchId : value.id }});
		} else {
			navigate(('/simulation-retraining-history'),{state : { data : value.pidsId, batchId : value.id }});
		}
	};

	const handleTab = (value) => {
		setTabValue(value);
	};

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.Thismoduleshowsdetailedlogsofprimaryandsecondaryretrainingfordifferentmachinelearningmodels ? langdata.Thismoduleshowsdetailedlogsofprimaryandsecondaryretrainingfordifferentmachinelearningmodels :'This module shows detailed logs of primary and secondary retraining for different machine learning models.'}</p>
		</Card>
	);

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		<>
			<Content>
				
				<Row 
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >

					<Col span={8}>
						<Row>

							<Space>
								<HistoryOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.RetrainingHistory ? langdata.RetrainingHistory:'Retraining History'}</Title>
						</Row>
					</Col>
					<Col span={16}>
						<Row justify="end">
							<Col>
								{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Region:</Text> */}
								<Tooltip placement="bottom" title={langdata && langdata.Filterbypids ? langdata.Filterbypids:'Filter by pids'}>
									<Space>
										<Select Item
											showSearch
											filterOption={filterOption}
											style={{ minWidth: '240px', maxWidth:'240px', margin:'5px'}}
											placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS:'Select PIDS'}
											optionFilterProp="children"
											// value={regionId}
											defaultValue={null}
											options={pidsList}
											onSelect={(e)=>setPidsId(e)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.RetrainingHistory ? langdata.RetrainingHistory:'Retraining History'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Tabs
					onChange={handleTab}
					defaultActiveKey = {tabValue}
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
					}} >
					<TabPane tab={langdata && langdata.AlarmRetrainingHistory ? langdata.AlarmRetrainingHistory:'Alarm Retraining History'} key="0" >
				
						<Row span={24} style={{
							// margin: '10px',
							// paddingLeft: '10px',
							// paddingRight: '10px',
							// marginTop:'0px',
							background: '#FFFFFF',
							alignItems:'center'
						}}>
							<Col span={24}>
								{model_data.length > 0 ?
									<Table 
										scroll={{
											x: 900,
										}}
										columns={columns} dataSource={model_data} pagination = {model_data.length > 0 ? model_data_pagination : 0}
										onChange = { modelData }
										onRow={(record) => {
											return {
												onClick: () => { 
													key(record);
												}
											};
										}
										}  />
									:
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
								}
							</Col>
						</Row>
					</TabPane>
					<TabPane tab={langdata && langdata.SimulationRetrainingHistory ? langdata.SimulationRetrainingHistory:'Simulation Retraining History'} key="1" >
						<Row span={24} style={{
							// margin: '10px',
							// paddingLeft: '10px',
							// paddingRight: '10px',
							// marginTop:'0px',
							background: '#FFFFFF',
							alignItems:'center'
						}}>
							<Col span={24}>
								{model_data.length > 0 ?
									<Table 
										scroll={{
											x: 900,
										}}
										columns={columns} dataSource={model_data} pagination = {model_data.length > 0 ? model_data_pagination : 0}
										onChange = { modelData }
										onRow={(record) => {
											return {
												onClick: () => { 
													key(record);
												}
											};
										}
										} />
									:
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
								}
							</Col>
						</Row>
					</TabPane>
				</Tabs>
											
			</Content>
			

			<Spin spinning={data.loading} fullscreen />
		</>
	);
}