/* eslint-disable react/react-in-jsx-scope */
import { FILTER_CHAINAGE, USER_ALLOCATION, USER_ALLOCATION_ERROR, USER_ALLOCATION_SUCCESS, 
	ROU_USER_DETAILS, ROU_USER_DETAILS_ERROR, ROU_USER_DETAILS_SUCCESS, CRO_USER_DETAILS, 
	CRO_USER_DETAILS_ERROR, CRO_USER_DETAILS_SUCCESS, USER_LIST, USER_LIST_ERROR, USER_LIST_SUCCESS, 
	SUPERVISOR_USER_DETAILS, SUPERVISOR_USER_DETAILS_SUCCESS, SUPERVISOR_USER_DETAILS_ERROR, LINEWALKER_USER_DETAILS, 
	LINEWALKER_USER_DETAILS_SUCCESS, LINEWALKER_USER_DETAILS_ERROR, NPV_USER_DETAILS, NPV_USER_DETAILS_SUCCESS, 
	NPV_USER_DETAILS_ERROR, EDIT_USER_ALLOCATION, EDIT_USER_ALLOCATION_SUCCESS, EDIT_USER_ALLOCATION_ERROR, ADD_ALLOCATION, 
	ADD_ALLOCATION_SUCCESS, ADD_ALLOCATION_ERROR, EDIT_ALLOCATION, EDIT_ALLOCATION_SUCCESS, EDIT_ALLOCATION_ERROR, 
	ALLOCATION_USER_DETAILS_SUCCESS, ALLOCATION_USER_DETAILS_ERROR, ALLOCATION_USER_DETAILS, ALLOCATION_SUB, 
	ALLOCATION_SUB_SUCCESS, ALLOCATION_SUB_ERROR, ALLOCATION_PIPELINE_PATH, ALLOCATION_PIPELINE_PATH_SUCCESS, ALLOCATION_PIPELINE_PATH_ERROR, EDIT_ALLOCATION_PIPELINE_PATH, EDIT_ALLOCATION_PIPELINE_PATH_SUCCESS, EDIT_ALLOCATION_PIPELINE_PATH_ERROR, INSPECTION_LIST, INSPECTION_LIST_SUCCESS, INSPECTION_LIST_ERROR, USERS_LIST, USERS_LIST_SUCCESS, USERS_LIST_ERROR } from './constants';

export default function AllocationReducer(state, action) {
	let pagination = {};
	let users = [];
	let rou = [];
	let cro = [];
	let allocation = [];
	let filterChainage = [];
	let supervisor = [];
	let linewalker = [];
	let npv = [];
	let edit_allocation = [];
	let allocateSubUser = [];
	let linewalkerAllocation = [];

	switch(action.type) {
	case USER_LIST :
		return { ...state, pending: true };
	case USER_LIST_SUCCESS :
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
			pagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map((item) => {
				if(item.usertypeId != 8) {
					users.push({
						id : item.id,
						name : `${item.firstName} ${item.lastName}`,
						email : item.email,
						cellPhone : item.cellPhone,
						usertype : item.usertype.userType,
						usertypeId : item.usertypeId,
						allocationsList : item.allocationsList ? Array.isArray(item.allocationsList) === true ?
							item.allocationsList.map((data) => { return( <>{data.regioninfo.regionName} ( {data.pipelineConfigFrom} to {data.pipelineConfigTo} )<br/></> ); }) 
							: `${item.allocationsList.regioninfo.regionName} ( ${item.allocationsList.pipelineConfigFrom} to ${item.allocationsList.pipelineConfigTo} )` 
							: 'No allocation'
					});
				}
			});
		}
		return { ...state, pending : false, error : false, users : users, pagination: pagination };
	case USER_LIST_ERROR :
		return { ...state, error: false, pending: false };
	case ROU_USER_DETAILS :
		return { ...state, pending: true };
	case ROU_USER_DETAILS_SUCCESS :
		
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map((item) => {
				rou.push({
					label : `${item.firstName} ${item.lastName}`,
					value : item.id
				});
			});
		} else {
			rou = [];
		}
		return { ...state, pending : false, error : false, rou: rou, rouUserDetails : action.payload.data ? action.payload.data : [] };
	case ROU_USER_DETAILS_ERROR :
		return { ...state, error: true, pending: false };
	case CRO_USER_DETAILS :
		return { ...state, pending: true };
	case CRO_USER_DETAILS_SUCCESS : 
		
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map((item) => {
				cro.push({
					label : `${item.firstName} ${item.lastName}`,
					value : item.id
				});
			});
		} else {
			cro = [];
		}
		return { ...state, pending : false, error : false, cro :  cro, croUserDetails : action.payload.data ? action.payload.data : []};
	case CRO_USER_DETAILS_ERROR : 
		return { ...state, error: true, pending: false };
	case USER_ALLOCATION :
		return { ...state, pending: true };
	case USER_ALLOCATION_SUCCESS : 
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map((item) => {
				allocation.push({
					label : `${item.pipelineConfigFrom} ( ${item.rouMarkerFrom} ) - ${item.pipelineConfigTo} ( ${item.rouMarkerTo})`,
					value : `${item.id} ${item.pipelineConfigFrom} ${item.pipelineConfigTo}`
				});
			});
		} else {
			allocation = [];
		}
		return { ...state, pending : false, error : false, allocation :  allocation};
	case USER_ALLOCATION_ERROR :
		return { ...state, error: true, pending: false };
	case FILTER_CHAINAGE :
		action.chainage && action.chainage.map((item) => {
			if (item.pipelineChainage >= parseInt(action.value[1]) && item.pipelineChainage <= parseInt(action.value[2])) {
				filterChainage.push({
					value : item.id,
					label : `${item.pipelineChainage} ( ${item.rouMarker} )`
				});
			}
		});
		return { ...state, pending : false, error : false, filterChainage :  filterChainage};
	case SUPERVISOR_USER_DETAILS :
		return { ...state, pending: true };
	case SUPERVISOR_USER_DETAILS_SUCCESS :
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map((item) => {
				supervisor.push({
					label : `${item.firstName} ${item.lastName}`,
					value : item.id
				});
			});
		} else {
			supervisor = [];
		}
		return { ...state, pending : false, error : false , supervisor : supervisor, supervisorUserDetails : action.payload.data ? action.payload.data : []};
	case SUPERVISOR_USER_DETAILS_ERROR :
		return { ...state, error: true, pending: false };
	case LINEWALKER_USER_DETAILS : 
		return { ...state, pending: true };
	case LINEWALKER_USER_DETAILS_SUCCESS :
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map((item) => {
				linewalker.push({
					label : `${item.firstName} ${item.lastName}`,
					value : item.id
				});
			});
		} else {
			linewalker = [];
		}
		return { ...state, pending : false, error : false , linewalker : linewalker, linewalkerUserDetails : action.payload.data ? action.payload.data : []};
	case LINEWALKER_USER_DETAILS_ERROR :
		return { ...state, error: true, pending: false };
	case NPV_USER_DETAILS :
		return { ...state, pending: true };
	case NPV_USER_DETAILS_SUCCESS :
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map((item) => {
				npv.push({
					label : `${item.firstName} ${item.lastName}`,
					value : item.id
				});
			});
		} else {
			npv = [];
		}
		return { ...state, pending : false, error : false , npv : npv, npvUserDetails : action.payload.data ? action.payload.data : [] };
	case NPV_USER_DETAILS_ERROR :
		return { ...state, error: true, pending: false };
	case EDIT_USER_ALLOCATION :
		return { ...state, pending: true };
	case EDIT_USER_ALLOCATION_SUCCESS :
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map((item) => {
				edit_allocation.push({
					label : `${item.pipelineConfigFrom} ( ${item.rouMarkerFrom} ) - ${item.pipelineConfigTo} ( ${item.rouMarkerTo})`,
					value : `${item.id} ${item.pipelineConfigFrom} ${item.pipelineConfigTo}`
				});
			});
		} else {
			edit_allocation = [];
		}
		return { ...state, pending : false, error : false, edit_allocation : edit_allocation, edit_details : action.payload.data };
	case EDIT_USER_ALLOCATION_ERROR :
		return { ...state, error: true, pending: false };
	case ADD_ALLOCATION :
		return { ...state, pending: true };
	case ADD_ALLOCATION_SUCCESS :
		return { ...state, pending: false };
	case ADD_ALLOCATION_ERROR :
		return { ...state, error: true, pending: false };
	case EDIT_ALLOCATION :
		return { ...state, pending: true };
	case EDIT_ALLOCATION_SUCCESS :
		return { ...state, pending: false };
	case EDIT_ALLOCATION_ERROR :
		return { ...state, error: true, pending: false };
	case ALLOCATION_USER_DETAILS :
		return { ...state, pending: true };
	case ALLOCATION_USER_DETAILS_SUCCESS :
		return { ...state, pending : false, error : false, userAllocationDetails : action.payload.data ? action.payload.data : [] };
	case ALLOCATION_USER_DETAILS_ERROR :
		return { ...state, error: true, pending: false };
	case ALLOCATION_SUB :
		return { ...state, pending: true };
	case ALLOCATION_SUB_SUCCESS :
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
		}
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map((item) => {
				allocateSubUser.push({
					id : item.id,
					name : `${item.firstName} ${item.lastName}`,
					email : item.email,
					cellPhone : item.cellPhone,
					usertype : item.usertype.userType,
					usertypeId : item.usertypeId,
					allocationsList : item.allocationsList ? Array.isArray(item.allocationsList) === true ?
						item.allocationsList.map((data) => { return( <>{data.regioninfo.regionName} ( {data.pipelineConfigFrom} to {data.pipelineConfigTo} )<br/></> ); }) 
						: `${item.allocationsList.regioninfo.regionName} ( ${item.allocationsList.pipelineConfigFrom} to ${item.allocationsList.pipelineConfigTo} )` 
						: 'No allocation'
				});
			});
		}
		return { ...state, pending: false, allocateSubUser : allocateSubUser };
	case ALLOCATION_SUB_ERROR :
		return { ...state, error: true, pending: false };
	case ALLOCATION_PIPELINE_PATH :
		return { ...state, pending: true };
	case ALLOCATION_PIPELINE_PATH_SUCCESS :
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map((item) => {
				linewalkerAllocation.push({
					label : `${item.pipelineConfigFrom} ( ${item.rouMarkerFrom} ) - ${item.pipelineConfigTo} ( ${item.rouMarkerTo})`,
					value : item.id,
					startChainageId : item.zoneFromDetails && item.zoneFromDetails.id,
					endChainageId : item.zoneToDetails && item.zoneToDetails.id,
					startChainage : item.pipelineConfigFrom,
					endChainage : item.pipelineConfigTo,
					regioninfoId : item.regioninfoId
				});
			});
		} else {
			linewalker = [];
		}
		return { ...state, pending: false , linewalkerDetails : action.payload.data, linewalkerAllocation : linewalkerAllocation};
	case ALLOCATION_PIPELINE_PATH_ERROR : 
		return { ...state, error: true, pending: false };

	case EDIT_ALLOCATION_PIPELINE_PATH :
		return { ...state, pending: true };
	case EDIT_ALLOCATION_PIPELINE_PATH_SUCCESS :
		return { ...state, pending: false };
	case EDIT_ALLOCATION_PIPELINE_PATH_ERROR :
		return { ...state, error: true, pending: false };
	case INSPECTION_LIST:
		return { ...state, pending: true };
	case INSPECTION_LIST_SUCCESS:
		return { ...state, inspection_data: action.payload, pending: false };
	case INSPECTION_LIST_ERROR:
		return { ...state, error: true };
	
	case USERS_LIST:
		return { ...state, pending: true };
	case USERS_LIST_SUCCESS :
		return { ...state, users_data: action.payload, pending: false };
	case USERS_LIST_ERROR:
		return { ...state, error: true };
	default : {
		throw Error('Unknown action: ' + action.type);
	}
	}
}