export const PIDS_INFO_LIST = 'PIDS_INFO_LIST';
export const PIDS_INFO_LIST_SUCCESS = 'PIDS_INFO_LIST_SUCCESS';
export const PIDS_INFO_LIST_ERROR = 'PIDS_INFO_LIST_ERROR';
export const PIDS_INFO_DETAILS = 'PIDS_INFO_DETAILS';

export const CHANNEL_INFO_LIST = 'CHANNEL_INFO_LIST';
export const CHANNEL_INFO_LIST_SUCCESS = 'CHANNEL_INFO_LIST_SUCCESS';
export const CHANNEL_INFO_LIST_ERROR = 'CHANNEL_INFO_LIST_ERROR';

export const PIDS_INFO_UPDATE = 'PIDS_INFO_UPDATE';
export const PIDS_INFO_UPDATE_SUCCESS = 'PIDS_INFO_UPDATE_SUCCESS';
export const PIDS_INFO_UPDATE_ERROR = 'PIDS_INFO_UPDATE_ERROR';
