import { DAS_GRAPH_AMP_DATA, DAS_GRAPH_AMP_DEPTH_DATA, DAS_GRAPH_AMP_ERROR, DAS_GRAPH_AMP_SUCCESS, DAS_GRAPH_AMP_TIME_DATA, DAS_GRAPH_DATA, DAS_GRAPH_DATA_ERROR, DAS_GRAPH_DATA_SUCCESS, DAS_GRAPH_TIME_DATA, DAS_GRAPH_TIME_ERROR, DAS_GRAPH_TIME_EVENT, DAS_GRAPH_TIME_SUCCESS, DTS_GRAPH, DTS_GRAPH_DATA, DTS_GRAPH_DATA_ERROR, DTS_GRAPH_DATA_SUCCESS, DTS_GRAPH_TEMP_DATA, DTS_GRAPH_TEMP_DEPTH_DATA, DTS_GRAPH_TEMP_ERROR, DTS_GRAPH_TEMP_SUCCESS, DTS_GRAPH_TEMP_TIME_DATA, DTS_GRAPH_TIME_DATA, DTS_GRAPH_TIME_ERROR, DTS_GRAPH_TIME_EVENT, DTS_GRAPH_TIME_SUCCESS } from './constants';


export default function reportWellReducer(state, action) {

	switch (action.type) {
	case DTS_GRAPH : 	
		return { ...state, dasData: action.dasData, dtsData : action.dtsData };

	case DAS_GRAPH_DATA : 	
		return { ...state, pending: true };
	case DAS_GRAPH_DATA_SUCCESS:
		return { ...state, das_data: action.payload, pending: false };
	case DAS_GRAPH_DATA_ERROR:
		return { ...state, error: true, pending:false };

	case DAS_GRAPH_TIME_DATA : 	
		return { ...state, pending: true };
	case DAS_GRAPH_TIME_SUCCESS:
		return { ...state, das_time_data: action.payload, pending: false };
	case DAS_GRAPH_TIME_ERROR:
		return { ...state, error: true, pending:false };

	case DAS_GRAPH_TIME_EVENT : 	
		return { ...state, dasTimeEventData: action.payload};
	
	case DTS_GRAPH_TIME_EVENT : 	
		return { ...state, dtsTimeEventData: action.payload};


	case DTS_GRAPH_DATA : 	
		return { ...state, pending: true };
	case DTS_GRAPH_DATA_SUCCESS:
		return { ...state, dts_data: action.payload, pending: false };
	case DTS_GRAPH_DATA_ERROR:
		return { ...state, error: true, pending:false };

	case DTS_GRAPH_TIME_DATA : 	
		return { ...state, pending: true };
	case DTS_GRAPH_TIME_SUCCESS:
		return { ...state, dts_time_data: action.payload, pending: false };
	case DTS_GRAPH_TIME_ERROR:
		return { ...state, error: true, pending:false };

	
	case DAS_GRAPH_AMP_DATA : 	
		return { ...state, pending: true };
	case DAS_GRAPH_AMP_SUCCESS:
		return { ...state, das_amp_data: action.payload, pending: false };
	case DAS_GRAPH_AMP_ERROR:
		return { ...state, error: true, pending:false };

	
	case DTS_GRAPH_TEMP_DATA : 	
		return { ...state, pending: true };
	case DTS_GRAPH_TEMP_SUCCESS:
		return { ...state, dts_temp_data: action.payload, pending: false };
	case DTS_GRAPH_TEMP_ERROR:
		return { ...state, error: true, pending:false };

		

	case DAS_GRAPH_AMP_TIME_DATA : 	
		return { ...state, dasTimeAmpData: action.payload};
	
	case DAS_GRAPH_AMP_DEPTH_DATA : 	
		return { ...state, dasDepthAmpData: action.payload};

	case DTS_GRAPH_TEMP_TIME_DATA : 	
		return { ...state, dtsTimeTempData: action.payload};
	
	case DTS_GRAPH_TEMP_DEPTH_DATA : 	
		return { ...state, dtsDepthTempData: action.payload};
		

	default: 
		throw Error('Unknown action: ' + action.type);
      
	}
}