import { React, useEffect, useReducer, useState } from 'react';

import { Row, Col, Typography, Space, Table, Tooltip, Modal, Tag, Tabs, Empty, Select, message, Spin, Breadcrumb } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { HomeOutlined, EyeOutlined, HistoryOutlined} from '@ant-design/icons';
// import { useNavigate } from 'react-router-dom';
import { MODEL_DEPLOYMENT_INFO, RETRAIN_HISTORY_DATA, RETRAIN_HISTORY_DATA_ERROR, RETRAIN_HISTORY_DATA_SUCCESS } from './constants';
import axios from 'axios';
import modelReducer from './reducer';
import { datetimeCovertertolocal } from '../datetime-converter';
import moment from 'moment';
import TabPane from 'antd/es/tabs/TabPane';
import { useAuth } from '../Context';
import { useLocation, useNavigate } from 'react-router-dom';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';


const { Title } = Typography;

let model_data_pagination = {};

export default function AlarmRetrainingHistory() {

	const { state } = useLocation();

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = { retrain_historydata:[], details: null, loading: false, error: false, modelDeploymentInfo:[]};
	const [data, dispatch] = useReducer(modelReducer, initalState);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const [openModal, setModelOpen] = useState(false);
	const { getPids, pidsData } = useAuth();
	// const [pidsId, setPidsId] = useState(null);
	const [status, setStatus] = useState(null);
	const [modelInfo, setModelInfo] = useState(null);
	const [tabDeploymentValue, setTabDeploymentValue] = useState('0');

	const navigate = useNavigate();

	let model_data= [];

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const statuslist = [

		{
			value: null,
			label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
		},
	
		{
			value:'Not Enough Samples',
			label:`${langdata && langdata.NotEnoughSamples ? langdata.NotEnoughSamples:'Not Enough Samples'}`
		},
		{
			value:'Combined',
			label:`${langdata && langdata.Combined ? langdata.Combined:'Combined'}`
		},
		{
			value:'Retrained',
			label:`${langdata && langdata.Retrained ? langdata.Retrained:'Retrained'}`
		}
	
	];


	data && data.retrain_historydata.map((item)=>{

		const fromdatetime = datetimeCovertertolocal(item.fromDate);
		const fromdate = fromdatetime.localdate + ' ' +  fromdatetime.localtime;

		const todatetime = datetimeCovertertolocal(item.toDate);
		const todate = todatetime.localdate + ' ' +  todatetime.localtime;

		const retrainingdatetime = datetimeCovertertolocal(item.retrainingDate ?  item.retrainingDate: '');
		const newretrainingdate = retrainingdatetime.localdate + ' ' +  retrainingdatetime.localtime;

		let pids = pidsData && pidsData.find((pid) => pid.id == item.pidsinfoId);

		model_data.push({
			id:item.id,
			totalSamples:item.totalSamples ? item.totalSamples : 0,
			fromDate : item.fromDate ? fromdate : '-',
			toDate : item.toDate ? todate : '-',
			modelName: item.modelinfo ?  `${item.modelinfo && item.modelinfo.modelDimension}_V1.${item.modelinfo && item.modelinfo.version}` : '-',
			status: item.status,
			utc_startdate: moment.utc(item.fromDate).format('YYYY-MM-DD'),
			utc_starttime: moment.utc(item.fromDate).format('HH:mm'),
			utc_enddate : moment.utc(item.toDate).format('YYYY-MM-DD'),
			utc_endtime : moment.utc(item.toDate).format('HH:mm'),
			testAccuracy : item.testAccuracy ? parseFloat(item.testAccuracy).toFixed(4) : '-',
			f1Score : item.f1Score ? parseFloat(item.f1Score).toFixed(4) : '-',
			pidsinfo : pids && pids.name ? pids.name.toUpperCase() : 'CENTRAL',
			retrainingDate : item.retrainingDate ? newretrainingdate : '-',
			modelinfo : item.modelinfo && item.modelinfo,
			modeldeploymenthistory : item.modeldeploymenthistory && item.modeldeploymenthistory,
			modelLevel : item.modelLevel && item.modelLevel == 'l2_threat' ? '3classMainModel' : item.modelLevel == 'l3_machinedigging' ? 'MachineDigging SubModel' : item.modelLevel == 'l3_manualdigging' ? 'ManualDigging SubModel' : item.modelLevel == 'l3_vehiclemovement' ? 'VehicleMovement Submodel' : item.modelLevel
		});
	});

	const pidsList = [
		{
			value:null,
			label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
		},		
		{
			value:0,
			label:'CENTRAL'
		}];
	pidsData && pidsData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name.toUpperCase()
		});
	});
  
	const columns = [

		{
			title: `${langdata && langdata.Pids ? langdata.Pids:'Pids'}`,
			dataIndex: 'pidsinfo',
			key: 'pidsinfo',
		},
		{
			title: `${langdata && langdata.DateConsidered ? langdata.DateConsidered:'Date Considered'}`,
			dataIndex: 'fromDate',
			key: 'fromDate',
			render: (date,data) => (
				<Space size="middle">
					<Tooltip placement="bottom" title={langdata && langdata.DateConsideredForRetrain ? langdata.DateConsideredForRetrain:'Date Considered For Retrain'}>
						<Tag color='orange'>
							<b>{data.fromDate}</b>
						</Tag>
						<Space>&nbsp;</Space>
						{langdata && langdata.to ? langdata.to:'to'} {' '}
						<Space>&nbsp;</Space>

						<Tag color='orange'>
							<b>{data.toDate}</b>	
						</Tag>
					</Tooltip>
				</Space>
			)
		},
		{
			title: `${langdata && langdata.ModelName ? langdata.ModelName:'Model Name'}`,
			dataIndex: 'modelLevel',
			key: 'modelLevel',
		},
		{
			title: `${langdata && langdata.RetrainingDate ? langdata.RetrainingDate:'Retraining Date'}`,
			dataIndex: 'retrainingDate',
			key: 'retrainingDate',
		},
		{
			title: `${langdata && langdata.TotalSamples ? langdata.TotalSamples:'Total Samples'}`,
			dataIndex: 'totalSamples',
			key: 'totalSamples',
			render: (totalSamples) => (
				<Space size="middle">
					<text>{JSON.stringify(totalSamples).replaceAll('{','').replaceAll('}','').replaceAll('"','').replaceAll(',', ', ')}</text>
				</Space>
			)
		},
		{
			title: `${langdata && langdata.Model ? langdata.Model:'Model'}`,
			dataIndex: 'modelName',
			key: 'modelName',
			render: (name, record) => (
				<Space size="middle">
					{name != '-' ?
						<a title={langdata && langdata.Moreinfo ? langdata.Moreinfo:'Model Info'} onClick={()=>{key(record);}}>{name}</a>
						:
						<p>{name}</p> }
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)
		},
		{
			title: `${langdata && langdata.TestAccuracy ? langdata.TestAccuracy:'Test Accuracy'}`,
			dataIndex: 'testAccuracy',
			key: 'testAccuracy',
		},
		{
			title: `${langdata && langdata.F1Score ? langdata.F1Score:'F1 Score'}`,
			dataIndex: 'f1Score',
			key: 'f1Score',
		},
		{
			title: `${langdata && langdata.Status ? langdata.Status:'Status'}`,
			dataIndex: 'status',
			key: 'status',
			render: (_, status) => (
				<>
					{status &&
						<Tag color={status.status=='Not Enough Samples' ? 'red' :
							status.status=='Combined' ? 'gold' : status.status=='Retrained' ? 'green' : ''}>
							{status.status}
						</Tag>}
				</>
			),
			
		},
		{
			title: `${langdata && langdata.AlarmDetails ? langdata.AlarmDetails:'Alarm Details'}`,
			dataIndex: 'alarmdetails',
			key: 'alarmdetails',
			align: 'center',
			render: (_, record) => (
				<Space style={{justifyContent:'center',display:'flex'}} size="middle">
					<a title="View" onClick={()=>{handleView(record);}}><EyeOutlined/></a>
				</Space>
			)
        
		}
	];

	const columnDeploymentInfo = [
		{
			title: `${langdata && langdata.PidsName ? langdata.PidsName:'Pids Name'}`,
			dataIndex: 'pidsName',
			key: 'pidsName',
		},
		{
			title: `${langdata && langdata.DeployedDate ? langdata.DeployedDate:'Deployed Date'}`,
			dataIndex: 'deployedDate',
			key: 'deployedDate',
		},
		{
			title: `${langdata && langdata.Status ? langdata.Status:'Status'}`,
			dataIndex: 'status',
			key: 'status',
			render: (_, status) => (
				<>
					{status &&
						<Tag color={status.status=='Failed' ? 'red' :
							status.status=='New' ? 'gold' : status.status=='Completed' ? 'green' : ''}>
							{status.status == 'Failed' ? 'Pending' : status.status}
						</Tag>}
				</>
			),
		},
	];
	
	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getPids(authtoken);
	},[authtoken]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	
	useEffect(() => {
		modelData(1);
	}, [status]);


	const modelData = (pagination) => {

		let _pagination = { };
		_pagination.total = model_data_pagination.total ? model_data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);
		
		let url = '';
		let params = '';
		params +=`&pidsinfoId=${state.data ? state.data : 0}&batchId=${state.batchId ? state.batchId : 0}`;
		if(status){
			params +=`&$and[0][status]=${status}`;
		}
		url = `${api}/secondaryretraining-list?$sort[id]=-1&$limit=${_pagination.limit}&$skip=${_pagination.skip}${params}`;
		// if(tabValue == '0'){
		// } else {
		// 	url = `${api}/primaryretraining-list?$sort[id]=-1&$limit=${_pagination.limit}&$skip=${_pagination.skip}${params}`;
		// }

		dispatch({ type : RETRAIN_HISTORY_DATA});
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					model_data_pagination.total = response.data.total ? response.data.total : 0;
					model_data_pagination.limit = response.data.limit ? response.data.limit : 10;
					model_data_pagination.skip = response.data.skip ? response.data.skip : 0;
					model_data_pagination.current = pagination.current ? pagination.current : 1;
					model_data_pagination.pageSize =  _pagination.pageSize;
					dispatch({ type : RETRAIN_HISTORY_DATA_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : RETRAIN_HISTORY_DATA_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : RETRAIN_HISTORY_DATA_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};
	
	
	// const key = (value) => {
	// 	setModelOpen(true);
	// 	dispatch({ type : MODEL_DATA});
	// 	let config = {
	// 		method: 'get',
	// 		maxBodyLength: Infinity,
	// 		url: `${api}/modelconfiguration/${value.id}`,
	// 		headers: { 
	// 			'Content-Type': 'application/json', 
	// 			'Authorization': `Bearer ${authtoken}`
	// 		},
	// 		data : data
	// 	};
    
	// 	axios.request(config)
	// 		.then((response) => {
	// 			if (response.status === 200) {
					
	// 				dispatch({ type : RETRAIN_HISTORY_DATA_DETAILS, payload : response.data});
	// 			}else {
	// 				dispatch({ type : MODEL_DATA_ERROR});
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 			dispatch({ type : MODEL_DATA_ERROR});
	// 		});
	// };

	let modeldeployment = [];

	const key = (value) => {
		setModelInfo(value.modelinfo);
		// setModelDeploymentHistory(value.modeldeploymenthistory);
		
		value.modeldeploymenthistory && value.modeldeploymenthistory.map((item) => {

			const datetime = datetimeCovertertolocal(item.deployedDate);
			const date = datetime.localdate + ' ' +  datetime.localtime;

			modeldeployment.push({
				pidsName : item.pidsinfo ? item.pidsinfo.name.toUpperCase() : 'CENTRAL',
				deployedDate : item.deployedDate ? date : '-',
				status : item.status
			});
		});
		dispatch({ type : MODEL_DEPLOYMENT_INFO, payload : modeldeployment});
		setModelOpen(true);
		setTabDeploymentValue('0');
	};


	const handleModelCancel = () => {
		setModelOpen(false);
		setTabDeploymentValue('0');
	};

	const handleView = (record)=>{
		if(record.status == 'Retrained'){
			record.retrainStatus = 3;
			navigate(( '/retraining-data'),{ state: { data: record, path: 'retrain-history'} });
		}
		else{
			record.retrainStatus = 4;
			navigate(( '/retraining-data'),{ state: { data: record, path: 'retrain-history'} });
		}
		localStorage.setItem(`${dashboardtype.toUpperCase()}_ACTIVE_MENU_ITEM`, '1131');

	};

	const handleDeploymentTab = (value) => {
		setTabDeploymentValue(value);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		<>
			<Content>
				<Row 
					style={{
						marginLeft:'10px',
						marginTop: '10px'
					}}            
				>
					<Breadcrumb
						items={[
							{
								href: '/dashboard',
								title: <HomeOutlined />,
							},
							{
								title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.RetrainingHistory ? langdata.RetrainingHistory:'Retraining History'}</a> 
							},
							{
								title: `${langdata && langdata.AlarmRetrainingHistory ? langdata.AlarmRetrainingHistory:'Alarm Retraining History'}`,
							},
						]}
					/>
				</Row>
				
				<Row 
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >

					<Col span={12}>
						<Row>

							<Space>
								<HistoryOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.AlarmRetrainingHistory ? langdata.AlarmRetrainingHistory:'Alarm Retraining History'}</Title>
						</Row>
					</Col>
					<Col style={{ display : 'flex', justifyContent : 'right'}} span={12} >
						<Row>
							<Col>
								{/* <Tooltip placement="bottom" title="Filter by pids">
									<Space>
										<Select Item
											showSearch
											filterOption={filterOption}
											style={{ minWidth: '240px', maxWidth:'240px', margin:'5px'}}
											placeholder="Select Pids"
											optionFilterProp="children"
											// value={regionId}
											defaultValue={'ALL'}
											options={pidsList}
											onSelect={(e)=>setPidsId(e)}
										/>
									</Space>
								</Tooltip> */}
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbystatus ? langdata.Filterbystatus:'Filter by status'}>
									<Space>
										<Select Item
											showSearch
											filterOption={filterOption}
											style={{ minWidth: '240px', maxWidth:'240px', margin:'5px'}}
											placeholder={langdata && langdata.SelectStatus ? langdata.SelectStatus:'Select Status'}
											optionFilterProp="children"
											// value={regionId}
											defaultValue={null}
											options={statuslist}
											onSelect={(e)=>setStatus(e)}
										/>
									</Space>
								</Tooltip>
								
							</Col>
						</Row>
					</Col>					
				</Row>

					
				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					// marginTop:'0px',
					background: '#FFFFFF',
					alignItems:'center'
				}}>
					<Col span={24}>
						{model_data.length > 0 ?
							<Table 
								scroll={{
									x: 900,
								}}
								columns={columns} dataSource={model_data} pagination = {model_data.length > 0 ? model_data_pagination : 0}
								onChange = { modelData } />
							:
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						}
					</Col>
				</Row>

				<Modal
					open={openModal}
					onCancel={handleModelCancel}
					width={720}
					footer={[

						// 		<Button key={'id'} type="primary" onClick={handleModelCancel}>
						// Close
						// 		</Button>

					]}
				>
					<Tabs
						activeKey = {tabDeploymentValue}
						onChange={handleDeploymentTab}
						style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							height: 350,
						}} >
						<TabPane tab={langdata && langdata.BasicInfo ? langdata.BasicInfo:'Basic Info'} key="0" >
							<Typography style={{ marginBottom : '4px'}}><b>{langdata && langdata.Model ? langdata.Model:'Model'} : </b>{modelInfo && modelInfo.modelDimension}_V1.{modelInfo && modelInfo.version}</Typography>
							<Typography style={{ marginBottom : '4px'}}><b>{langdata && langdata.MLAlgorithm ? langdata.MLAlgorithm:'ML Algorithm'} : </b>{modelInfo && modelInfo.modelDimension}</Typography>
							<Typography style={{ marginBottom : '4px'}}><b>{langdata && langdata.NumberOfClasses ? langdata.NumberOfClasses:'Number Of Classes'} : </b>{modelInfo && modelInfo.numOfClasses}</Typography>
							<Typography style={{ marginBottom : '4px'}}><b>{langdata && langdata.TestAccuracy ? langdata.TestAccuracy:'Test Accuracy'} : </b>{modelInfo && parseFloat(modelInfo.testAccuracy).toFixed(4)}</Typography>
							<Typography style={{ marginBottom : '4px'}}><b>{langdata && langdata.TestF1Score ? langdata.TestF1Score:'Test F1 Score'} : </b>{modelInfo && parseFloat(modelInfo.testF1Score).toFixed(4)}</Typography>
							<Typography style={{ marginBottom : '4px'}}><b>{langdata && langdata.TotalSampleCount ? langdata.TotalSampleCount:'Total Sample Count'} : </b>{modelInfo && modelInfo.totalSampleCount}</Typography>
							<Typography style={{ marginBottom : '4px'}}><b>{langdata && langdata.ValidationPassed ? langdata.ValidationPassed:'Validation Passed'} : </b>{modelInfo && modelInfo.validationPassed ? 'YES' : 'NO'}</Typography>
							{modelInfo && modelInfo.validationPassed == false ?
								<Typography style={{ marginBottom : '4px'}}><b>{langdata && langdata.Reason ? langdata.Reason:'Reason'} : </b> {langdata && langdata.Theretrainedmodeldidnotattaintherequiredbenchmarkaccuracy ? langdata.Theretrainedmodeldidnotattaintherequiredbenchmarkaccuracy:'The retrained model did not attain the required benchmark accuracy.'}</Typography>
								: null }
						</TabPane>
						<TabPane tab={langdata && langdata.DeploymentStatus ? langdata.DeploymentStatus:'Deployment Status'} key="1" >
							{data.modelDeploymentInfo.length > 0 ?
								<Table
									scroll={{
										y: 200,
									}} 								
									columns={columnDeploymentInfo} 
									dataSource={data.modelDeploymentInfo}
								/>
								:
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }
						</TabPane>
					</Tabs>
					
				</Modal>
						
					
			</Content>
			

			<Spin spinning={data.loading} fullscreen />
		</>
	);
}