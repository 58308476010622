import { REGION_LIST, REGION_LIST_DETAILS, REGION_LIST_ERROR, REGION_LIST_SUCCESS, UPDATE_REGION, UPDATE_REGION_DATA, UPDATE_REGION_DATA_ERROR, UPDATE_REGION_DATA_SUCCESS, UPDATE_REGION_ERROR, UPDATE_REGION_SUCCESS } from './constants';


export default function regionManagementReducer(state, action) {
	switch (action.type) {
	case REGION_LIST:
		return { ...state, pending: true };
			
	case REGION_LIST_SUCCESS:
		return { ...state,region : action.payload, pending: false, error: false };
				
	case REGION_LIST_ERROR:
		return { ...state, pending: false, error: true };

	case REGION_LIST_DETAILS:
		return { ...state, details:action.payload, pending: false, error: true };

	case UPDATE_REGION_DATA:
		return { ...state, pending: true };
				
	case UPDATE_REGION_DATA_SUCCESS:
		return { ...state,regionDetails : action.payload, pending: false, error: false };
					
	case UPDATE_REGION_DATA_ERROR:
		return { ...state, pending: false, error: true };
	case UPDATE_REGION:
		return { ...state, pending: true };
					
	case UPDATE_REGION_SUCCESS:
		return { ...state, pending: false, error: false };
						
	case UPDATE_REGION_ERROR:
		return { ...state, pending: false, error: true };
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}