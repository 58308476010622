/* eslint-disable react/react-in-jsx-scope */
import { Row, Col, Typography, Card, Breadcrumb, Space } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MailOutlined, HomeOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useAuth } from '../../Context';

const { Title } = Typography;

export default function AddMailTemplate() {

	const navigate = useNavigate();

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	useAuth();
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;


	const { state } = useLocation();

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
	},[authtoken]);

	useEffect(() => {
		if(!state) {
			navigate(-1);
		}
	},[authtoken]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	return (
		<>
			{ state ?
				<>
					<Content>
						<Row style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center'
						}}>
							<Col span={8}>
								<Row>
									<Space>
										<MailOutlined style={{fontSize:'30px'}}/>
										{/* </Col> */}
									</Space>
									<Space>&nbsp;&nbsp;</Space>
					
									{/* <Col style={{marginLeft:'4px'}} span={19}> */}
									<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.MailConfigurationDetails ? langdata.MailConfigurationDetails : 'Mail Configuration Details'}</Title>
								</Row>
							</Col>
						</Row>
						<Row 
							style={{
								marginLeft:'50px',
								marginTop: '10px'
							}}            
						>
							<Breadcrumb
								items={[
									{
										href: '/dashboard',
										title: <HomeOutlined />,
									},
									{
										title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.MailConfiguration ? langdata.MailConfiguration : 'Mail Configuration'}</a> 
									},
									{
										title: langdata && langdata.MailConfigurationDetails ? langdata.MailConfigurationDetails : 'Mail Configuration Details',
									},
								]}
							/>
						</Row>
						<Row
							gutter={{
								xs: 8,
								sm: 16,
								md: 24,
								lg: 32,
							}}                
						>
							<Card
								style={{
									marginLeft: '50px',
									marginRight: '50px',
									marginTop: '10px',
									padding: '20px',
									width : '100%',
									background: '#FFFFFF',
									// display: 'flex',
									// margin: '50px'
								}}>
								<Col span={24}>
									<Typography style={{ fontSize : '25px'}}>{langdata && langdata.MailType ? langdata.MailType : 'Mail Type'}: <b>{state.data.mailType} </b></Typography>
									<br />
									<Typography style={{ fontSize : '25px'}}>{langdata && langdata.Subject ? langdata.Subject : 'Subject'}: <b>{state.data.subject}</b></Typography>
									<br />
									<Typography
										dangerouslySetInnerHTML={{
											__html: state.data.content,
										}}/>
								</Col>
							</Card>
						</Row>
					</Content>
				</> : '' }
		</>
	);
}