/* eslint-disable react/prop-types */
import { Row } from 'antd';
import React from 'react';

function WellDiagram(props) {

	const difference = props.endDepth - props.startDepth;

	let array ;

	if(difference < 100 ){
		array = Array.from({ length: 10 }, (_, index) => {
			return  Math.round(props.startDepth + (props.endDepth - props.startDepth) * (index / (10 - 1)));
		});
	} else {
		array = Array.from({ length: 10 }, (_, index) => {
			return Math.round((props.startDepth + index * (props.endDepth - props.startDepth) / 9) / 10) * 10;
		});
	}
	
	const spacing = 46;
	const marginTop = 113; // Top margin
	const centerX = 25; // Center point for the lines and text
	const lineLength = 15; 
	const hashedMarginTop = 50; // Top margin
	const hashedSpacing = 50;
	const y = hashedMarginTop + hashedSpacing ;
	const ratio = props.endDepth / 429;
	
	return (
		<Row>
			<svg height="600px" version="1.1" style={{maxHeight:'600px', minHeight:'600px'}} viewBox="0 0 210 600" preserveAspectRatio='none'>
				{/* Left side - Hole size */}
				<rect x="65" y="110" width="15" height="140" fill="black" />
				{/* <rect x="65" y="150" width="15" height="50" fill="black" /> */}
				{/* <rect x="65" y="200" width="15" height="50" fill="black" /> */}
				<rect x="85" y="250" width="10" height="140" fill="black" />
				{/* <rect x="85" y="300" width="10" height="50" fill="black" />
			<rect x="85" y="350" width="10" height="50" fill="black" /> */}
				<rect x="105" y="390" width="5" height="140" fill="black" />
				{/* <rect x="105" y="450" width="5" height="50" fill="black" />
			<rect x="105" y="500" width="5" height="50" fill="black" /> */}

				{/* Right side - Hole size */}
				<rect x="165" y="110" width="15" height="140" fill="black" />
				{/* <rect x="165" y="150" width="15" height="50" fill="black" />
			<rect x="165" y="200" width="15" height="50" fill="black" /> */}
				<rect x="150" y="250" width="10" height="140" fill="black" />
				{/* <rect x="150" y="300" width="10" height="50" fill="black" />
			<rect x="150" y="350" width="10" height="50" fill="black" /> */}
				<rect x="135" y="390" width="5" height="140" fill="black" />
				{/* <rect x="135" y="450" width="5" height="50" fill="black" />
			<rect x="135" y="500" width="5" height="50" fill="black" /> */}

				{/* Connecting lines */}
				<line x1="65" y1="250" x2="95" y2="250" stroke="black" />
				<line x1="85" y1="390" x2="110" y2="390" stroke="black" />
				<line x1="180" y1="250" x2="150" y2="250" stroke="black" />
				<line x1="160" y1="390" x2="135" y2="390" stroke="black" />

				<line x1="40" y1="109" x2="205" y2="109" stroke="black" strokeDasharray="5, 5"  strokeWidth="4" />

				<line x1="123" y1="109" x2="123" y2="538" stroke="black" strokeDasharray="5, 5"  strokeWidth="2" />

				{props.dtsHoverValue && props.dtsHoverValue ?
					<line x1="40" y1={props.dtsHoverValue && props.dtsHoverValue ? (props.dtsHoverValue / ratio) + 110 : null} x2="205" y2={props.dtsHoverValue && props.dtsHoverValue  ? (props.dtsHoverValue / ratio) + 110 : null} stroke="#b32d1e55"  strokeWidth="10" />
					: null }

				{props.dasHoverValue && props.dasHoverValue ?
					<line x1="40" y1={props.dasHoverValue && props.dasHoverValue ? (props.dasHoverValue / ratio) + 110 : null} x2="205" y2={props.dasHoverValue && props.dasHoverValue  ? (props.dasHoverValue / ratio) + 110 : null} stroke="#17BECF55"  strokeWidth="10" />
					: null }


				{/* <line x1="105" y1="550" x2="140" y2="550" stroke="black" /> */}

				{/* Scale on the left side */}
				{/* <line x1="40" y1="111" x2="50" y2="111" stroke="black" />
				<text x="10" y="113" fontSize="12" fill="black">{array[0]}</text>
      
				<line x1="40" y1="150" x2="50" y2="150" stroke="black" />
				<text x="10" y="155" fontSize="12" fill="black">{array[1]}</text>
      
				<line x1="40" y1="200" x2="50" y2="200" stroke="black" />
				<text x="10" y="205" fontSize="12" fill="black">{array[2]}</text>
      
				<line x1="40" y1="250" x2="50" y2="250" stroke="black" />
				<text x="10" y="255" fontSize="12" fill="black">{array[3]}</text>
      
				<line x1="40" y1="295" x2="50" y2="295" stroke="black" />
				<text x="5" y="305" fontSize="12" fill="black">{array[4]}</text>
      
				<line x1="40" y1="350" x2="50" y2="350" stroke="black" />
				<text x="5" y="352" fontSize="12" fill="black">{array[5]}</text>
      
				<line x1="40" y1="400" x2="50" y2="400" stroke="black" />
				<text x="5" y="405" fontSize="12" fill="black">{array[6]}</text>
      
				<line x1="40" y1="447" x2="50" y2="447" stroke="black" />
				<text x="5" y="450" fontSize="12" fill="black">{array[7]}</text>

				<line x1="40" y1="488" x2="50" y2="488" stroke="black" />
				<text x="5" y="491" fontSize="12" fill="black">{array[8]}</text>

				<line x1="40" y1="530" x2="50" y2="530" stroke="black" />
				<text x="5" y="533" fontSize="12" fill="black">{array[9]}</text> */}

				{array.map((value, index) => (
					<g key={index}>
						<line x1="40" y1={marginTop + spacing * index}  x2="50" y2={marginTop + spacing * index}  stroke="black" style={{ display : index == 0 ? 'none' : ''}}/>
						<text x="10" y={marginTop + spacing * index + 2}  fontSize="12" fill="black" >{value}</text>
					</g>
				))}

				{/* {array.map((value, index) => {
					const y = marginTop + spacing * index;

					// Draw an angled hashed line
					return ( */}
				<g>
					{[...Array(12)].map((_, i) => (
						<line 
							key={i}
							x1={centerX + i * (lineLength)} 
							y1={y - 5} 
							x2={centerX + (i + 1) * (lineLength)} 
							y2={y + 10} 
							stroke="black" 
							strokeWidth="2"
						/>
					))}
					
				</g>
				{/* );
				})} */}
				{/* <line x1="40" y1="550" x2="50" y2="550" stroke="black" />
			<text x="15" y="555" fontSize="12" fill="black">500</text> */}

				{/* Unfilled circle at the end of the last line */}
				{/* <circle cx="122" cy="550" r="15" fill="none" stroke="black" strokeWidth="6" /> */}

				{/* Arc in opposite direction */}
				{/* <path 
					d="M 106 529 A 30 30 0 0 1 139 529" // Clockwise arc
					fill="none" 
					stroke="black" 
					strokeWidth="2" 
				/> */}

				{/* Arc in the opposite direction */}
				<path 
					d="M 106 530 A 30 30 0 0 0 139 530" // Counterclockwise arc
					fill="none" 
					stroke="black" // Different color to distinguish
					strokeWidth="3" 
				/>
			</svg>
		</Row>
	);
}

export default WellDiagram;
