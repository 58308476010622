export const REALTIME_EVENT = 'REALTIME_EVENT';
export const REALTIME_EVENT_SUCCESS = 'REALTIME_EVENT_SUCCESS';
export const REALTIME_EVENT_ERROR = 'REALTIME_EVENT_ERROR';
export const REALTIME_EVENT_DETAILS = 'REALTIME_EVENT_DETAILS';

export const REALTIME_MAP_DATA = 'REALTIME_MAP_DATA';
export const REALTIME_MAP_DATA_SUCCESS = 'REALTIME_MAP_DATA_SUCCESS';
export const REALTIME_MAP_DATA_ERROR = 'REALTIME_MAP_DATA_ERROR';

export const REALTIME_MAP_EVENT_DETAILS = 'REALTIME_MAP_EVENT_DETAILS';
