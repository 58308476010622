export const DTS_SOCKET_DATA= 'DTS_SOCKET_DATA';
export const DAS_SOCKET_DATA = 'DAS_SOCKET_DATA';
export const PRESS_SOCKET_DATA = 'PRESS_SOCKET_DATA';

export const FLOW_MONITORING_GRAPH_CONFIG = 'FLOW_MONITORING_GRAPH_CONFIG';

export const FLOW_MONITORING_DTS_CROSS_WELL_1 = 'FLOW_MONITORING_DTS_CROSS_WELL_1';
export const FLOW_MONITORING_DTS_CROSS_WELL_2 = 'FLOW_MONITORING_DTS_CROSS_WELL_2';
export const FLOW_MONITORING_DTS_CROSS_WELL_3 = 'FLOW_MONITORING_DTS_CROSS_WELL_3';
export const FLOW_MONITORING_DTS_CROSS_WELL_4 = 'FLOW_MONITORING_DTS_CROSS_WELL_4';
export const FLOW_MONITORING_DTS_CROSS_WELL_5 = 'FLOW_MONITORING_DTS_CROSS_WELL_5';
export const FLOW_MONITORING_DTS_CROSS_WELL_6 = 'FLOW_MONITORING_DTS_CROSS_WELL_6';
export const FLOW_MONITORING_DTS_CROSS_WELL_7 = 'FLOW_MONITORING_DTS_CROSS_WELL_7';
export const FLOW_MONITORING_DTS_CROSS_WELL_8 = 'FLOW_MONITORING_DTS_CROSS_WELL_8';


export const FLOW_MONITORING_DTS_CROSS_WELL_LOADING = 'FLOW_MONITORING_DTS_CROSS_WELL_LOADING';
export const FLOW_MONITORING_DTS_CROSS_WELL_LOADING_FALSE = 'FLOW_MONITORING_DTS_CROSS_WELL_LOADING_FALSE';

export const FLOW_MONITORING_DTS_CROSS_WELL_LOADING_1 = 'FLOW_MONITORING_DTS_CROSS_WELL_LOADING_1';
export const FLOW_MONITORING_DTS_CROSS_WELL_LOADING_2 = 'FLOW_MONITORING_DTS_CROSS_WELL_LOADING_2';
export const FLOW_MONITORING_DTS_CROSS_WELL_LOADING_3 = 'FLOW_MONITORING_DTS_CROSS_WELL_LOADING_3';
export const FLOW_MONITORING_DTS_CROSS_WELL_LOADING_4 = 'FLOW_MONITORING_DTS_CROSS_WELL_LOADING_4';
export const FLOW_MONITORING_DTS_CROSS_WELL_LOADING_5 = 'FLOW_MONITORING_DTS_CROSS_WELL_LOADING_5';
export const FLOW_MONITORING_DTS_CROSS_WELL_LOADING_6 = 'FLOW_MONITORING_DTS_CROSS_WELL_LOADING_6';
export const FLOW_MONITORING_DTS_CROSS_WELL_LOADING_7 = 'FLOW_MONITORING_DTS_CROSS_WELL_LOADING_7';
export const FLOW_MONITORING_DTS_CROSS_WELL_LOADING_8 = 'FLOW_MONITORING_DTS_CROSS_WELL_LOADING_8';


export const FLOW_MONITORING_DAS_CROSS_WELL_1 = 'FLOW_MONITORING_DAS_CROSS_WELL_1';
export const FLOW_MONITORING_DAS_CROSS_WELL_2 = 'FLOW_MONITORING_DAS_CROSS_WELL_2';
export const FLOW_MONITORING_DAS_CROSS_WELL_3 = 'FLOW_MONITORING_DAS_CROSS_WELL_3';
export const FLOW_MONITORING_DAS_CROSS_WELL_4 = 'FLOW_MONITORING_DAS_CROSS_WELL_4';
export const FLOW_MONITORING_DAS_CROSS_WELL_5 = 'FLOW_MONITORING_DAS_CROSS_WELL_5';
export const FLOW_MONITORING_DAS_CROSS_WELL_6 = 'FLOW_MONITORING_DAS_CROSS_WELL_6';
export const FLOW_MONITORING_DAS_CROSS_WELL_7 = 'FLOW_MONITORING_DAS_CROSS_WELL_7';
export const FLOW_MONITORING_DAS_CROSS_WELL_8 = 'FLOW_MONITORING_DAS_CROSS_WELL_8';


export const FLOW_MONITORING_DAS_CROSS_WELL_LOADING = 'FLOW_MONITORING_DAS_CROSS_WELL_LOADING';
export const FLOW_MONITORING_DAS_CROSS_WELL_LOADING_FALSE = 'FLOW_MONITORING_DAS_CROSS_WELL_LOADING_FALSE';

export const FLOW_MONITORING_DAS_CROSS_WELL_LOADING_1 = 'FLOW_MONITORING_DAS_CROSS_WELL_LOADING_1';
export const FLOW_MONITORING_DAS_CROSS_WELL_LOADING_2 = 'FLOW_MONITORING_DAS_CROSS_WELL_LOADING_2';
export const FLOW_MONITORING_DAS_CROSS_WELL_LOADING_3 = 'FLOW_MONITORING_DAS_CROSS_WELL_LOADING_3';
export const FLOW_MONITORING_DAS_CROSS_WELL_LOADING_4 = 'FLOW_MONITORING_DAS_CROSS_WELL_LOADING_4';
export const FLOW_MONITORING_DAS_CROSS_WELL_LOADING_5 = 'FLOW_MONITORING_DAS_CROSS_WELL_LOADING_5';
export const FLOW_MONITORING_DAS_CROSS_WELL_LOADING_6 = 'FLOW_MONITORING_DAS_CROSS_WELL_LOADING_6';
export const FLOW_MONITORING_DAS_CROSS_WELL_LOADING_7 = 'FLOW_MONITORING_DAS_CROSS_WELL_LOADING_7';
export const FLOW_MONITORING_DAS_CROSS_WELL_LOADING_8 = 'FLOW_MONITORING_DAS_CROSS_WELL_LOADING_8';


export const DTS_AVGZONE_DATA_SUCCESS = 'DTS_AVGZONE_DATA_SUCCESS';

export const DAS_AVGZONE_DATA_SUCCESS = 'DAS_AVGZONE_DATA_SUCCESS';



