export const INSPECTION_LIST = 'INSPECTION_LIST';
export const INSPECTION_LIST_SUCCESS = 'INSPECTION_LIST_SUCCESS';
export const INSPECTION_LIST_ERROR = 'INSPECTION_LIST_ERROR';

export const USERS_LIST = 'USERS_LIST';
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
export const USERS_LIST_ERROR = 'USERS_LIST_ERROR';

export const INSPECTION_DATA = 'INSPECTION_DATA';
export const INSPECTION_DATA_SUCCESS = 'INSPECTION_DATA_SUCCESS';
export const INSPECTION_DATA_ERROR = 'INSPECTION_DATA_ERROR';

export const INSPECTON_ALARM_DATA = 'INSPECTON_ALARM_DATA';
export const INSPECTON_ALARM_SUCCESS = 'INSPECTON_ALARM_SUCCESS';
export const INSPECTON_ALARM_ERROR = 'INSPECTON_ALARM_ERROR';
