export const DTS_SOCKET_DATA= 'DTS_SOCKET_DATA';
export const DAS_SOCKET_DATA = 'DAS_SOCKET_DATA';

export const LEAK_DETECTION_DAS_ZONE = 'LEAK_DETECTION_DAS_ZONE';
export const LEAK_DETECTION_DAS_ZONE_SUCCESS = 'LEAK_DETECTION_DAS_ZONE_SUCCESS';
export const LEAK_DETECTION_DAS_ZONE_ERROR = 'LEAK_DETECTION_DAS_ZONE_ERROR';

export const LEAK_DETECTION_DTS_ZONE = 'LEAK_DETECTION_DTS_ZONE';
export const LEAK_DETECTION_DTS_ZONE_SUCCESS = 'LEAK_DETECTION_DTS_ZONE_SUCCESS';
export const LEAK_DETECTION_DTS_ZONE_ERROR = 'LEAK_DETECTION_DTS_ZONE_ERROR';

export const LEAK_DETECTION_GRAPH_CONFIG = 'LEAK_DETECTION_GRAPH_CONFIG';