export const REALTIME_ALARM_LIST = 'REALTIME_ALARM_LIST';
export const REALTIME_ALARM_SUCCESS = 'REALTIME_ALARM_SUCCESS';
export const REALTIME_ALARM_ERROR = 'REALTIME_ALARM_ERROR';
export const REALTIME_ALARM_DETAILS = 'REALTIME_ALARM_DETAILS';

export const GRAPH_DATA = 'GRAPH_DATA';
export const GRAPH_DATA_SUCCESS = 'GRAPH_DATA_SUCCESS';
export const GRAPH_DATA_ERROR = 'GRAPH_DATA_ERROR';

export const ALARM_VIEW = 'ALARM_VIEW';
export const ALARM_VIEW_SUCCESS = 'ALARM_VIEW_SUCCESS';
export const ALARM_VIEW_ERROR = 'ALARM_VIEW_ERROR';

export const RELATED_ALARMS_ANALYTICS = 'RELATED_ALARMS_ANALYTICS';
export const RELATED_ALARMS_ANALYTICS_SUCCESS = 'RELATED_ALARMS_ANALYTICS_SUCCESS';
export const RELATED_ALARMS_ANALYTICS_ERROR = 'RELATED_ALARMS_ANALYTICS_ERROR';