import { Avatar, Breadcrumb, Button, Card, Col, DatePicker, Divider, Form, Input, message, Modal, Row, Select, Slider, Space, Spin, Switch, Tooltip, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useReducer, useState } from 'react';
import { AreaChartOutlined, HomeOutlined, FullscreenOutlined, FullscreenExitOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
// import * as d3 from 'd3';
// import Plot from 'react-plotly.js';
import { useAuth } from '../../Context';
import { useLocation, useNavigate } from 'react-router-dom';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import moment from 'moment';
import fractureData from './reducer';
import { FRACTURE_GRAPH_CONFIG, FRACTURING_LOCATION_DATA, FRACTURING_LOCATION_DATA_ERROR, FRACTURING_LOCATION_DATA_SUCCESS, MICROSEISMIC_GRAPH_DAS, MICROSEISMIC_GRAPH_DTS, MICROSEISMIC_GRAPH_PRESSURE } from './constants';
import axios from 'axios';
import { useFormik } from 'formik';

const { Title } = Typography;
const { RangePicker } = DatePicker;

// const unpack = (rows, key) => rows.map(row => row[key]);

export default function FractureMonitoring() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const { wellinfo, getWellInfo, wellzoneinfo,getWellZoneInfo, wellchanneldata, getWellChannels, wellFractureData, getWellFracture, getWellSeismicType, wellSeismicType, getAllEvents, eventsData} = useAuth();
	const { state } = useLocation();
	const navigate = useNavigate();
	// const [websocketdasdata, setWebsocketDASData] =useState({});
	const [fullsize, setfullSize] = useState(false);
	const [graph, setGraph] = useState(null);
	const [spin, setSpin] = useState(false);
	const [wellId, setWellId] = useState();
	const [dassocketurl, setDasSocketUrl] = useState(null);
	const [dtssocketurl, setDtsSocketUrl] = useState(null);
	const [zoneId, setZoneId] = useState(null);
	const [zonefrom, setZoneFrom] = useState(null);
	const [zoneto, setZoneTo] = useState(null);
	const [startDate, setStartDate] = useState(moment.utc(new Date().setHours(0,0,0,0)).subtract({'days' : 30}).toISOString());
	const [endDate, setEndDate] = useState(new Date().toISOString());
	const [channelId, setChannelId] = useState(null);
	const [fractureId, setFractureId] = useState(null);
	// const [websocketdtsdata, setWebsocketDTSData] =useState({});
	const [tempTh, setTempTH] =useState({'maxTH':null, 'minTH':null});
	const [presTh, setPresTH] =useState({'maxTH':null, 'minTH':null});
	const [ampTh, setAmpTH] =useState({'maxTH':null, 'minTH':null});

	const [wellFrom, setWellFrom] = useState(null);
	const [wellTo, setWellTo] = useState(null);
	const [depthRangeFrom, setDepthRangeFrom] = useState(null);
	const [depthRangeTo, setDepthRangeTo] = useState(null);
	const [pressocketurl, setPressSocketUrl] = useState(null);
	// const [websocketpressdata, setWebsocketPressData] =useState({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [drawerDepthFrom, setDrawerDepthFrom] = useState();
	const [drawerDepthTo, setDrawerDepthTo] = useState();

	const [dasSocket, setDasSocket] = useState();
	const [dtsSocket, setDtsSocket] = useState();
	const [pressSocket, setPressSocket] = useState();

	const [selectdActivity, setSelectedActivity] = useState();
	const [channelAndPidsData, setChannelAndPidsData] = useState();
	const [trainSystem, setTrainSystem] = useState();
	const[dtsClickableData, setDTSClickableData] = useState();
	const [isDTSDataModalOpen, setIsDTSDataModalOpen] = useState(false);

	const [graphScroll, setGraphScroll] = useState(false);

	let pressEnabled1 =false;
	let dtsEnabled1 =false;
	let dasEnabled1 =false;


	const initalState = {dasgraphData:[],dtsgraphData: [], pressuregraphData : [], loading: false, error: false, updateData : {},fracturedata:[], graphConfig:{} };
	const [fracture, dispatch] = useReducer(fractureData, initalState);

	let tempWellId;
	let tempdasurl;
	let tempdtsurl;

	let tempstartDepth;
	let tempendDepth;

	let tempdtsminth; 
	let tempdtsmaxth;
	let tempdasminth;
	let tempdasmaxth; 
	let temppresminth;
	let temppresmaxth;

	let temppressurl;
	let tempchannelId;
	// let temppressEnabled;
	// let tempdtsEnabled;
	// let tempdasEnabled;

	let socketDTS = null;
	let socketDAS = null;
	let socketPress = null;
	
	const allevents = [];
	eventsData && eventsData.map((item) => {
		allevents.push({
			value : item.id,
			label: item.eventType
		});
	});

	const handleSetWell = (e , options) =>{		

		tempWellId=e;
		setWellId(e);
		// setWebsocketDASData([]);
		// setWebsocketDTSData([]);
		if(dasSocket){
			dasSocket.close();
		}
		if(dtsSocket) {
			dtsSocket.close();
		}
		if(pressSocket) {
			pressSocket.close();
		}

		setDasSocketUrl(`ws://${options.dasip}:${options.dasport}`);
		setDtsSocketUrl(`ws://${options.dtsip}:${options.dtsport}`);
		setPressSocketUrl(`ws://${options.pressip}:${options.pressport}`);


		setDepthRangeFrom(options.startDepth);
		setDepthRangeTo(options.endDepth);
		setZoneId(null);
		setWellFrom(options.startDepth);
		setWellTo(options.endDepth);

		setTempTH({'maxTH':options.dtsmaxth, 'minTH':options.dtsminth});
		setPresTH({'maxTH':options.presmaxth, 'minTH':options.presminth});
		setAmpTH({'maxTH':options.dasmaxth, 'minTH':options.dasminth});

		setpressEnabled(options.pressEnabled);
		setdtsEnabled(options.dtsEnabled);
		setdasEnabled(options.dasEnabled);
		
	};

	const welldata =[];

	wellinfo && wellinfo.map((item,index) => {

		if(item&&item.fracturemonitoringconfig){
			welldata.push({
				value : item.id,
				label : item.name.toUpperCase(),
				startDepth : item.startDepth,
				endDepth : item.endDepth,
				dasip: item && item.fracturemonitoringconfig &&item.fracturemonitoringconfig.dasip,
				dasport : item && item.fracturemonitoringconfig &&item.fracturemonitoringconfig.dasport,
				dtsip: item && item.fracturemonitoringconfig &&item.fracturemonitoringconfig.dtsip,
				dtsport : item && item.fracturemonitoringconfig &&item.fracturemonitoringconfig.dtsport,
				dtsminth : item && item.fracturemonitoringconfig && item.fracturemonitoringconfig.tempThresholdsOverride ?  item.fracturemonitoringconfig && item.fracturemonitoringconfig.tempThresholds && item.fracturemonitoringconfig.tempThresholds.minThreshold : item.fracturemonitoringconfig && item.fracturemonitoringconfig.tempThresholds && item.fracturemonitoringconfig.tempThresholds.defaultminThreshold ,
				dtsmaxth : item && item.fracturemonitoringconfig && item.fracturemonitoringconfig.tempThresholdsOverride ?  item.fracturemonitoringconfig && item.fracturemonitoringconfig.tempThresholds && item.fracturemonitoringconfig.tempThresholds.maxThreshold : item.fracturemonitoringconfig && item.fracturemonitoringconfig.tempThresholds && item.fracturemonitoringconfig.tempThresholds.defaultmaxThreshold,
				dasminth : item && item.fracturemonitoringconfig && item.fracturemonitoringconfig.ampThresholdsOverride ?  item.fracturemonitoringconfig && item.fracturemonitoringconfig.ampThresholds && item.fracturemonitoringconfig.ampThresholds.minThreshold : item.fracturemonitoringconfig && item.fracturemonitoringconfig.ampThresholds && item.fracturemonitoringconfig.ampThresholds.defaultminThreshold,
				dasmaxth : item && item.fracturemonitoringconfig && item.fracturemonitoringconfig.ampThresholdsOverride ?  item.fracturemonitoringconfig && item.fracturemonitoringconfig.ampThresholds && item.fracturemonitoringconfig.ampThresholds.maxThreshold : item.fracturemonitoringconfig && item.fracturemonitoringconfig.ampThresholds && item.fracturemonitoringconfig.ampThresholds.defaultmaxThreshold,
				presminth :item && item.fracturemonitoringconfig && item.fracturemonitoringconfig.pressThresholdsOverride ?  item.fracturemonitoringconfig && item.fracturemonitoringconfig.pressThresholds && item.fracturemonitoringconfig.pressThresholds.minThreshold : item.fracturemonitoringconfig && item.fracturemonitoringconfig.pressThresholds && item.fracturemonitoringconfig.pressThresholds.defaultminThreshold,
				presmaxth : item && item.fracturemonitoringconfig && item.fracturemonitoringconfig.pressThresholdsOverride ?  item.fracturemonitoringconfig && item.fracturemonitoringconfig.pressThresholds && item.fracturemonitoringconfig.pressThresholds.maxThreshold : item.fracturemonitoringconfig && item.fracturemonitoringconfig.pressThresholds && item.fracturemonitoringconfig.pressThresholds.defaultmaxThreshold,
				pressip: item && item.fracturemonitoringconfig &&item.fracturemonitoringconfig.pressureip,
				pressport : item && item.fracturemonitoringconfig &&item.fracturemonitoringconfig.pressureport,
				pressEnabled : item.pressureEnabled,
				dtsEnabled : item.dtsEnabled,
				dasEnabled : item.dasEnabled
			});
			if (index === 0) {			
				tempWellId = item.id;
				tempstartDepth = item.startDepth;
				tempendDepth = item.endDepth;
				tempdasurl = `ws://${item && item.fracturemonitoringconfig &&item.fracturemonitoringconfig.dasip}:${item && item.fracturemonitoringconfig &&item.fracturemonitoringconfig.dasport}`;
				tempdtsurl = `ws://${item && item.fracturemonitoringconfig &&item.fracturemonitoringconfig.dtsip}:${item && item.fracturemonitoringconfig &&item.fracturemonitoringconfig.dtsport}`;
				tempdtsminth = item && item.fracturemonitoringconfig && item.fracturemonitoringconfig.tempThresholdsOverride ?  item.fracturemonitoringconfig && item.fracturemonitoringconfig.tempThresholds && item.fracturemonitoringconfig.tempThresholds.minThreshold : item.fracturemonitoringconfig && item.fracturemonitoringconfig.tempThresholds && item.fracturemonitoringconfig.tempThresholds.defaultminThreshold;
				tempdtsmaxth = item && item.fracturemonitoringconfig && item.fracturemonitoringconfig.tempThresholdsOverride ?  item.fracturemonitoringconfig && item.fracturemonitoringconfig.tempThresholds && item.fracturemonitoringconfig.tempThresholds.maxThreshold : item.fracturemonitoringconfig && item.fracturemonitoringconfig.tempThresholds && item.fracturemonitoringconfig.tempThresholds.defaultmaxThreshold;
				tempdasminth = item && item.fracturemonitoringconfig && item.fracturemonitoringconfig.ampThresholdsOverride ?  item.fracturemonitoringconfig && item.fracturemonitoringconfig.ampThresholds && item.fracturemonitoringconfig.ampThresholds.minThreshold : item.fracturemonitoringconfig && item.fracturemonitoringconfig.ampThresholds && item.fracturemonitoringconfig.ampThresholds.defaultminThreshold;
				tempdasmaxth = item && item.fracturemonitoringconfig && item.fracturemonitoringconfig.ampThresholdsOverride ?  item.fracturemonitoringconfig && item.fracturemonitoringconfig.ampThresholds && item.fracturemonitoringconfig.ampThresholds.maxThreshold : item.fracturemonitoringconfig && item.fracturemonitoringconfig.ampThresholds && item.fracturemonitoringconfig.ampThresholds.defaultmaxThreshold;
				temppresminth = item && item.fracturemonitoringconfig && item.fracturemonitoringconfig.pressThresholdsOverride ?  item.fracturemonitoringconfig && item.fracturemonitoringconfig.pressThresholds && item.fracturemonitoringconfig.pressThresholds.minThreshold : item.fracturemonitoringconfig && item.fracturemonitoringconfig.pressThresholds && item.fracturemonitoringconfig.pressThresholds.defaultminThreshold;
				temppresmaxth = item && item.fracturemonitoringconfig && item.fracturemonitoringconfig.pressThresholdsOverride ?  item.fracturemonitoringconfig && item.fracturemonitoringconfig.pressThresholds && item.fracturemonitoringconfig.pressThresholds.maxThreshold : item.fracturemonitoringconfig && item.fracturemonitoringconfig.pressThresholds && item.fracturemonitoringconfig.pressThresholds.defaultmaxThreshold;
				temppressurl = `ws://${item && item.fracturemonitoringconfig &&item.fracturemonitoringconfig.pressureip}:${item && item.fracturemonitoringconfig &&item.fracturemonitoringconfig.pressureport}`;
				pressEnabled1 = item.pressureEnabled;
				dtsEnabled1 = item.dtsEnabled;
				dasEnabled1 = item.dasEnabled;
			}
		}
	});
	
	const [pressEnabled, setpressEnabled] = useState();
	const [dtsEnabled, setdtsEnabled] = useState();
	const [dasEnabled, setdasEnabled] = useState();	
	
	useEffect(() => {
		setpressEnabled(pressEnabled1);
		setdtsEnabled(dtsEnabled1);
		setdasEnabled(dasEnabled1);
		setDepthRangeFrom(tempstartDepth);
		setDepthRangeTo(tempendDepth);
	},[pressEnabled1, dtsEnabled1, dasEnabled1, tempstartDepth, tempendDepth]);

	const zonelist =[{
		value:null,label:'ALL',zoneFrom:wellFrom ,zoneTo:wellTo	}];

	wellzoneinfo && wellzoneinfo.map((item)=>{
		zonelist.push({
			value : item.id,
			label : item.zoneName.toUpperCase(),
			zoneFrom:item.zoneFrom,
			zoneTo:item.zoneTo
		});
	});

	const channellist =[{
		value:null,label:'ALL'
	}];

	wellchanneldata && wellchanneldata.map((item,index)=>{
		channellist.push({
			value: item.id,
			label : item.name

		});
		if(index==0){
			tempchannelId =  item.id;
		}
	});

	let seismictypeId;
	wellSeismicType && wellSeismicType.map((item) => {
		if(item.name.toLowerCase() == 'fracture' || item.name.toLowerCase() == 'fractures'){
			seismictypeId = item.id;
		}
	});	

	useEffect(() => {
		getAllEvents(authtoken);
		if(seismictypeId) {
			getWellFracture(authtoken, seismictypeId);
		}
	},[seismictypeId]);
	

	const fracturelist =[{
		value:null, label:'ALL'
	}];
	let tempFractureid;
	wellFractureData && wellFractureData.data.map((item, index) => {
		fracturelist.push({ value : item.id, label : item.name });
		if(index == 0) {
			tempFractureid = item.id;
		}
	});

	useEffect(() => {
		getWellSeismicType(authtoken);
		getWellInfo(authtoken);
		getWellZoneInfo(authtoken,wellId ? wellId : tempWellId);
		if(tempWellId || wellId){
			getWellChannels(authtoken, null,wellId ? wellId : tempWellId);
			if(seismictypeId){
				loadFractureLocation();
			}

		}


	},[wellId,dassocketurl,dtssocketurl,tempWellId, channelId, tempchannelId, pressEnabled,dasEnabled,dtsEnabled, tempTh,ampTh,presTh,tempdtsminth, tempdtsmaxth, tempdasminth, tempdasmaxth, temppresminth,temppresmaxth, seismictypeId, startDate, endDate, fractureId]);


	const loadFractureLocation =()=>{

		dispatch({type:FRACTURING_LOCATION_DATA});

		let params='';

		if(wellId || tempWellId){
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		}
		if(startDate){
			params += `&datetimeFrom=${startDate}`;
		}
		if(endDate){
			params += `&datetimeTo=${endDate}`;
		}
		if(channelId || tempchannelId){
			params += `&channelId=${channelId ? channelId : tempchannelId}`;
		}
		// if(status){
		// 	params += `status=${wellId ? wellId : tempWellId}`;
		// }
		if(seismictypeId){
			params += `&seismicTypeId=${seismictypeId}`;
		}
		if(fractureId){
			params += `&id=${fractureId}`;
		}


		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellfracturingdata?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type:FRACTURING_LOCATION_DATA_SUCCESS, payload:response.data});
					
				}else{
					dispatch({type:FRACTURING_LOCATION_DATA_ERROR, payload:[]});
				}
			})
			.catch((error) => {
				dispatch({type:FRACTURING_LOCATION_DATA_ERROR, payload:[]});
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});
	};


	const webSocketDataDAS = () =>{
		// const socket1 = new WebSocket(dassocketurl ? dassocketurl : tempdasurl); // Replace with your WebSocket URL
		// socket1.close();
		if(socketDAS == null) {
			socketDAS = new WebSocket(dassocketurl ? dassocketurl : tempdasurl); // Replace with your WebSocket URL
			setDasSocket(socketDAS);
			setSpin(true);		
	
			socketDAS.onopen = () => {
				console.log('WebSocket connection opened');
				setSpin(true);
			};
	
			socketDAS.onmessage = (event) => {
				try {
					const newData = JSON.parse(event.data);
				
					// setWebsocketDASData(newData);
					dispatch({ type : MICROSEISMIC_GRAPH_DAS, payload : newData });

					setSpin(false);
	
				} catch (error) {

					setSpin(false);
					console.error('Error parsing data:', error);
				}
			};
	
			socketDAS.onerror = (error) => {
				setSpin(false);
				console.error('WebSocket error:', error);
			};
	
			socketDAS.onclose = () => {
			// setTimeout(setSpin(false), 5000);	
			// setTimeout(()=> {
			// 	webSocketDataDAS();
			// }, 10000);	
				setSpin(false);
				dispatch({ type : MICROSEISMIC_GRAPH_DAS, payload : [] });
				console.log('WebSocket connection closed');
			};		
	
			const handlePopState = () => {			
				socketDAS.close();
				dispatch({ type : MICROSEISMIC_GRAPH_DAS, payload : [] });
			};

			const handleUrlChange = () => {
				if (socketDAS) {
					socketDAS.close();
					dispatch({ type : MICROSEISMIC_GRAPH_DAS, payload : [] });
				}
			};
		
			window.addEventListener('popstate', handlePopState);

			const originalPushState = history.pushState;
			const originalReplaceState = history.replaceState;

			history.pushState = function (...args) {
				originalPushState.apply(this, args);
				handleUrlChange(); // Close socket on push
			};

			history.replaceState = function (...args) {
				originalReplaceState.apply(this, args);
				handleUrlChange(); // Close socket on replace
			};
		
			return () => {
				window.removeEventListener('popstate', handlePopState);
				socketDAS.close();
				dispatch({ type : MICROSEISMIC_GRAPH_DAS, payload : [] });
				history.pushState = originalPushState; // Restore original pushState
				history.replaceState = originalReplaceState; // Restore original replaceState
			};
		}

	};

	useEffect(() => {

		if((dassocketurl || tempdasurl) && (dasEnabled == true || dasEnabled1 == true)){						

			webSocketDataDAS();
		}
	
	}, [dassocketurl,tempdasurl, wellId]);
	
	
	useEffect(() => {

		if((dtssocketurl || tempdtsurl) && (dtsEnabled == true || dtsEnabled1 == true)){						

			webSocketDataDTS();
		}
	
	}, [dtssocketurl,tempdtsurl, wellId]);
	


	const webSocketDataDTS =()=>{
		// const socket = new WebSocket('ws://192.168.1.41:7891'); // Replace with your WebSocket URL
		// const socket1 = new WebSocket(dtssocketurl ? dtssocketurl : tempdtsurl); // Replace with your WebSocket URL
		// socket1.close();
		if(socketDTS == null) {
			socketDTS = new WebSocket(dtssocketurl ? dtssocketurl : tempdtsurl);
			setDtsSocket(socketDTS);
			setSpin(true);		

			socketDTS.onopen = () => {
				console.log('WebSocket connection opened');
				setSpin(true);
			};

			socketDTS.onmessage = (event) => {
				try {
					const newData = JSON.parse(event.data);
					// console.log('new Data', newData);	
				
					dispatch({ type : MICROSEISMIC_GRAPH_DTS, payload : newData });
			
					// setWebsocketDTSData(newData);
					setSpin(false);

				} catch (error) {

					setSpin(false);
					console.error('Error parsing data:', error);
				}
			};

			socketDTS.onerror = (error) => {
				setSpin(false);
				console.error('WebSocket error:', error);
			};

			socketDTS.onclose = () => {
				// setTimeout(setSpin(false), 5000);
			// setTimeout(()=> {
			// 	webSocketDataDTS();
			// }, 10000);		
				setSpin(false);
				dispatch({ type : MICROSEISMIC_GRAPH_DTS, payload : [] });
				console.log('WebSocket connection closed');
			};		

			const handlePopState = () => {			
				socketDTS.close();
				dispatch({ type : MICROSEISMIC_GRAPH_DTS, payload : [] });
			};

			const handleUrlChange = () => {
				if (socketDTS) {
					socketDTS.close();
					dispatch({ type : MICROSEISMIC_GRAPH_DTS, payload : [] });
				}
			};
		
			window.addEventListener('popstate', handlePopState);

			const originalPushState = history.pushState;
			const originalReplaceState = history.replaceState;

			history.pushState = function (...args) {
				originalPushState.apply(this, args);
				handleUrlChange(); // Close socket on push
			};

			history.replaceState = function (...args) {
				originalReplaceState.apply(this, args);
				handleUrlChange(); // Close socket on replace
			};
		
			return () => {
				window.removeEventListener('popstate', handlePopState);
				socketDTS.close();
				dispatch({ type : MICROSEISMIC_GRAPH_DTS, payload : [] });
				history.pushState = originalPushState; // Restore original pushState
				history.replaceState = originalReplaceState; // Restore original replaceState
			};
		}
	};

	useEffect(() => {
		
		if((pressocketurl || temppressurl) && (pressEnabled == true || pressEnabled1 == true)){						

			webSocketDataPress();
		}
	
	}, [pressocketurl,temppressurl, wellId]);
	


	const webSocketDataPress =()=>{
		// const socket = new WebSocket('ws://192.168.1.41:7891'); // Replace with your WebSocket URL
		// const socket1 = new WebSocket(pressocketurl ? pressocketurl : temppressurl); // Replace with your WebSocket URL
		// socket1.close();
		if(socketPress == null){
			socketPress = new WebSocket(pressocketurl ? pressocketurl : temppressurl);
			setPressSocket(socketPress);
			setSpin(true);		

			socketPress.onopen = () => {
				console.log('WebSocket connection opened');
				setSpin(true);
			};

			socketPress.onmessage = (event) => {
				try {
					const newData = JSON.parse(event.data);
					dispatch({ type : MICROSEISMIC_GRAPH_PRESSURE, payload : newData });
			
					// setWebsocketPressData(newData);
					setSpin(false);

				} catch (error) {

					setSpin(false);
					console.error('Error parsing data:', error);
				}
			};

			socketPress.onerror = (error) => {
				setSpin(false);
				console.error('WebSocket error:', error);
			};

			socketPress.onclose = () => {
				// setTimeout(setSpin(false), 5000);
			// setTimeout(()=> {
			// 	webSocketDataDTS();
			// }, 10000);		
				setSpin(false);
				dispatch({ type : MICROSEISMIC_GRAPH_PRESSURE, payload : [] });
				console.log('WebSocket connection closed');
			};		

			const handlePopState = () => {			
				socketPress.close();
				dispatch({ type : MICROSEISMIC_GRAPH_PRESSURE, payload : [] });
			};

			const handleUrlChange = () => {
				if (socketPress) {
					socketPress.close();
					dispatch({ type : MICROSEISMIC_GRAPH_PRESSURE, payload : [] });
				}
			};
		
			window.addEventListener('popstate', handlePopState);

			const originalPushState = history.pushState;
			const originalReplaceState = history.replaceState;

			history.pushState = function (...args) {
				originalPushState.apply(this, args);
				handleUrlChange(); // Close socket on push
			};

			history.replaceState = function (...args) {
				originalReplaceState.apply(this, args);
				handleUrlChange(); // Close socket on replace
			};
		
			return () => {
				window.removeEventListener('popstate', handlePopState);
				socketPress.close();
				dispatch({ type : MICROSEISMIC_GRAPH_PRESSURE, payload : [] });
				history.pushState = originalPushState; // Restore original pushState
				history.replaceState = originalReplaceState; // Restore original replaceState
			};
		}
	};

	// const [dasData, setDASData] = useState([]);
	// const [dtsData, setDTSData] = useState([]);


	// const [pressureLayout] = useState({
	// 	title:{
	// 		text : langdata && langdata.PressurePumpData ? langdata.PressurePumpData : 'Pressure Pump Data',
	// 		font: {
	// 			size: 20,
	// 			// family: 'Arial, sans-serif',
	// 			// color: 'black',
	// 			weight: 'bold'
	// 		},
	// 	},
	// 	xaxis: {
	// 		title:  langdata && langdata.Time ? langdata.Time : 'Time',
	// 		type: 'date',
	// 		tickangle: -45,
	// 	},
	// 	yaxis: {
	// 		title: langdata && langdata.Pressure ? langdata.Pressure :  'Pressure',
	// 		type: 'linear',

	// 		// autorange: 'reversed',   
	// 	}
	// });

	const pressureLayout = {
		title: {
			text: langdata && langdata.Pressure ? langdata.Pressure : 'Pressure',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			title: langdata && langdata.Time ? langdata.Time : 'Time',
			type: 'date'
		},
		yaxis: {
			title: langdata && langdata.Pressure ? langdata.Pressure : 'Pressure',
			// autorange: true,
			// range: [86.8700008333, 138.870004167],
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeFrom , depthRangeTo ] : [ tempstartDepth , tempendDepth ],
			type: 'linear',
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
		},
		autosize: true,
		height: graphScroll == true ? 10000 :0,

		shapes: [
			{
				type: 'line',
				xref: 'paper',
				yref: 'x',
				x0: 0,
				y0: presTh.minTH || presTh.minTH == 0 ? presTh.minTH :temppresminth,
				x1: 1,
				y1: presTh.minTH || presTh.minTH == 0 ? presTh.minTH :temppresminth,
				line:{
					color: '#19b083',
					width: 2,
					dash:'dot'
				}
			},

			{
				type: 'line',
				xref: 'paper',
				yref: 'x',
				x0: 0,
				y0: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresmaxth,
				x1: 1,
				y1: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresmaxth,
				line:{
					color: '#19b083',
					width: 2,
					dash:'dot'
				}
			},

			//max threhold

			{

				type: 'rect',
		
				yref: 'x',
		
				xref: 'paper',
		
				x0: 0,
		
				y0: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresmaxth,
		
				x1: 1,
		
				y1: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresmaxth,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text:  langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
					font: { size: 10, color: 'red' },
		
					textposition: 'bottom center',
		
				},
		
			},

			// {

			// 	type: 'rect',
		
			// 	yref: 'x',
		
			// 	xref: 'paper',
		
			// 	x0: 0,
		
			// 	y0: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresmaxth,
		
			// 	x1: 0,
		
			// 	y1: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresmaxth,
		
			// 	fillcolor: 'transparent',
		
			// 	opacity: 0.2,
		
			// 	// editable: true,
		
			// 	line: {
		
			// 		width: 0,
		
			// 	},
		
			// 	label: {
		
			// 		text:  langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
			// 		font: { size: 10, color: 'red', marginLeft:'100px' },
		
			// 		textposition: 'left center',
		
			// 	},
		
			// },


			//min threshold


			{

				type: 'rect',
		
				yref: 'x',
		
				xref: 'paper',
		
				x0: 0,
		
				y0: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresminth,
		
				x1: 1,
		
				y1: presTh.maxTH || presTh.maxTH == 0 ? presTh.maxTH : temppresminth,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text:  langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',
		
					font: { size: 10, color: 'red' },
		
					textposition: 'bottom center',
		
				},
		
			},

		],
	};	

	const CreatePressureData = () => {
		// const { dates, highValues, lowValues } = generatePressureRandomData();		
	
		// const trace1 = {
		// 	type: 'scatter',
		// 	mode: 'lines',
		// 	x: dates,
		// 	y: highValues,
		// 	line: { color: '#17BECF' },
		// 	name: langdata && langdata.PressureValve1 ? langdata.PressureValve1 :  'Pressure Valve 1'
		// };
	
		// const trace2 = {
		// 	type: 'scatter',
		// 	mode: 'lines',
		// 	x: dates,
		// 	y: lowValues,
		// 	line: { color: '#7F7F7F' },
		// 	name: langdata && langdata.PressureValve2 ? langdata.PressureValve2 : 'Pressure Valve 2'
		// };
	
		// setPressureData([trace1, trace2]);
	};

	useEffect(() => {
		CreatePressureData(); // Initial data update
	
		// Update data every 5 seconds
		const interval = setInterval(() => {
			CreatePressureData();
		}, 1000); // 5000 milliseconds = 5 seconds
	
		return () => clearInterval(interval); // Cleanup interval on component unmount
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [fullsize]);

	const dasData = [
		{
			type: 'scatter',
			mode: 'lines',
			x: fracture && fracture.dasgraphData && fracture.dasgraphData.line_data,
			y: fracture && fracture.dasgraphData && fracture.dasgraphData.depths,
			line: { color: '#17BECF' },
			orientation: graphScroll == true ? 'h' : ''
		}
	];	

	const pressureData = [{
		type: 'scatter',
		mode: 'lines',
		x: fracture && fracture.pressuregraphData && fracture.pressuregraphData.times,
		y: fracture && fracture.pressuregraphData && fracture.pressuregraphData.line_data,
		line: { color: '#B32D1E' },
		name: langdata && langdata.Pressure ? langdata.Pressure : 'Pressure',
		orientation: graphScroll == true ? 'h' : ''
	},
	{
		type: 'scatter',
		mode: 'lines',
		x: fracture && fracture.pressuregraphData && fracture.pressuregraphData.times,
		y: fracture && fracture.pressuregraphData && fracture.pressuregraphData.depths,
		line: { color: '#17873A' },
		name: langdata && langdata.Pressure ? langdata.Pressure : 'Pressure',
	}];

	const dtsData = [{
		type: 'scatter',
		mode: 'lines',
		x: fracture && fracture.dtsgraphData && fracture.dtsgraphData.line_data,
		y: fracture && fracture.dtsgraphData && fracture.dtsgraphData.depths,
		line: { color: '#b32d1e' },
		name: 'Temperature',
		orientation: graphScroll == true ? 'h' : ''
        
	}];
	

	const AcousticWaterfallPlotData = [{
		z:fracture && fracture.dasgraphData && fracture.dasgraphData.data,
		y:fracture && fracture.dasgraphData && fracture.dasgraphData.depths,
		x:fracture && fracture.dasgraphData && fracture.dasgraphData.times,
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Jet',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap',
		hovertemplate : 'Time : %{x} <br> Depth : %{y} <br> Amplitude : %{z} <extra></extra>',
		colorbar: {x: 1,   thickness: 10,
			// thicknessmode: 'pixels',
			// lenmode: 'fraction',
			outlinewidth: 0},
		orientation: graphScroll == true ? 'h' : ''
	}];


	let dasshapes=[];


	fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times && fracture.dasgraphData.anomaly_times.map((item, index) => {
		dasshapes.push(

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times ? fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times[index] : [],
				y0: 0,
				x1:fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times ? fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times[index] : [],
				y1: 1,
				line:{
					color: 'black',
					width: 2,
					dash:'dot'
				},
			},

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times ? fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times[index] : [],
		
				y0: 0,
		
				x1: fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times ? fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times[index] : [],
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text:  fracture && fracture.dasgraphData && fracture && fracture.dasgraphData.anomaly_type ? fracture.dasgraphData.anomaly_type : '',
		
					font: { size: 12, color: 'black', margin:'20px' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'bottom',
		
				},
		
			},

			// {
			// 	// type: 'scatter',
			// 	// z:websocketdata.data,
			// 	x: fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times ? [fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times[index], fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times[index]] : [],
			// 	y: fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times ? [0, depthRangeTo] : [],
			// 	mode: 'lines',
			// 	line: {
			// 		color: 'blue',
			// 		width: 2,
			// 		dash: 'dot',
			// 	},				
			// 	name: fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_type ? fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_type : null, // Legend entry name for scatter plot
			// 	// hoverlabel: {
			// 	// 	bgcolor: 'white', // Set the tooltip background color
			// 	// // font: {
			// 	// //   color: 'white', // Set the text color
			// 	// // }
			// 	// }
			// },
			// {
			// 	// type: 'scatter',
			// 	// z:websocketdata.data,
			// 	x: fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times ? [fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times[index], fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times[index]] : [],
			// 	y: fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_times ? [depthRangeFrom + 50, depthRangeTo] : [],
			// 	text:  fracture && fracture.dasgraphData && fracture.dasgraphData.anomaly_type ? [fracture.dasgraphData.anomaly_type] : '', 
			// 	mode: 'text',
			// 	// line: {
			// 	// 	color: 'blue',
			// 	// 	width: 2,
			// 	// 	dash: 'dot',
			// 	// },				
			// 	// name: fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_type ? fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_type : null, // Legend entry name for scatter plot
			// 	// hoverlabel: {
			// 	// 	bgcolor: 'white', // Set the tooltip background color
			// 	// // font: {
			// 	// //   color: 'white', // Set the text color
			// 	// // }
			// 	// }
			// }
		);});

	const AcousticWaterfallLayout = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.AcousticWaterfall ? langdata.AcousticWaterfall : 'Acoustic Waterfall',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
		yaxis: { 
			title: langdata && langdata.Depth ? langdata.Depth : 'Depth',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],			// autorange: 'reversed',   
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
		},
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area,
		shapes:dasshapes,
		height: graphScroll == true ? 10000 :0,
	};

	const TemperatureWaterfallPlotData = [{
		z:fracture && fracture.dtsgraphData && fracture.dtsgraphData.data,
		y:fracture && fracture.dtsgraphData && fracture.dtsgraphData.depths,
		x: fracture && fracture.dtsgraphData && fracture.dtsgraphData.times,
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Hot',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap',
		reversescale:true,
		hovertemplate : 'Time : %{x} <br> Depth : %{y} <br> Temperature : %{z} <extra></extra>',
		colorbar: {x: 1,   thickness: 10,
			// thicknessmode: 'pixels',
			// lenmode: 'fraction',
			outlinewidth: 0},
		orientation: graphScroll == true ? 'h' : ''

	}];
	
	let dtsshapes=[];
	
	fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times && fracture.dtsgraphData.anomaly_times.map((item, index) => {
		dtsshapes.push(

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times ? fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times[index] : [],
				y0: 0,
				x1:fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times ? fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times[index] : [],
				y1: 1,
				line:{
					color: 'black',
					width: 2,
					dash:'dot'
				},
			},

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times ? fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times[index] : [],
		
				y0: 0,
		
				x1: fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times ? fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times[index] : [],
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text:  fracture && fracture.dasgraphData && fracture && fracture.dasgraphData.anomaly_type ? fracture.dasgraphData.anomaly_type : '',
		
					font: { size: 12, color: 'black', margin:'20px' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'bottom',
		
				},
		
			},
			// {
			// 	// type: 'scatter',
			// 	// z:websocketdata.data,
			// 	x: fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times ? [fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times[index], fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times[index]] : [],
			// 	y: fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times ? [0, depthRangeTo] : [],
			// 	mode: 'lines',
			// 	line: {
			// 		color: 'blue',
			// 		width: 2,
			// 		dash: 'dot',
			// 	},				
			// 	name: fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_type ? fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_type : null, // Legend entry name for scatter plot
			// 	// hoverlabel: {
			// 	// 	bgcolor: 'white', // Set the tooltip background color
			// 	// // font: {
			// 	// //   color: 'white', // Set the text color
			// 	// // }
			// 	// }
			// },
			// {
			// 	// type: 'scatter',
			// 	// z:websocketdata.data,
			// 	x: fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times ? [fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times[index], fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times[index]] : [],
			// 	y: fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_times ? [depthRangeFrom + 50, depthRangeTo] : [],
			// 	text:  fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_type ? [fracture.dtsgraphData.anomaly_type] : '', 
			// 	mode: 'text',
			// 	// line: {
			// 	// 	color: 'blue',
			// 	// 	width: 2,
			// 	// 	dash: 'dot',
			// 	// },				
			// 	// name: fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_type ? fracture && fracture.dtsgraphData && fracture.dtsgraphData.anomaly_type : null, // Legend entry name for scatter plot
			// 	// hoverlabel: {
			// 	// 	bgcolor: 'white', // Set the tooltip background color
			// 	// // font: {
			// 	// //   color: 'white', // Set the text color
			// 	// // }
			// 	// }
			// }
		);});

	const TemperatureWaterfallLayout = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.TemperatureWaterfall ? langdata.TemperatureWaterfall : 'Temperature Waterfall',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
		yaxis: { 
			title: langdata && langdata.Depth ? langdata.Depth : 'Depth', 
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [tempendDepth , tempstartDepth ],
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
			// autorange: 'reversed',   
		},
		showlegend:false,
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
		shapes:dtsshapes,
		height: graphScroll == true ? 10000 :0,
	};

	// const [layoutDAS] = useState({
	// 	title: {
	// 		text: langdata && langdata.Amplitude ? langdata.Amplitude : 'Amplitude',
	// 		font: {
	// 			size: 20,
	// 			// family: 'Arial, sans-serif',
	// 			// color: 'black',
	// 			weight: 'bold'
	// 		},
	// 	},
	// 	xaxis: {
	// 		// range: ['2016-07-01', '2016-12-31'],
	// 		type: 'linear',
	// 		title: langdata && langdata.Amplitude ? langdata.Amplitude :  'Amplitude'
	// 	},
	// 	yaxis: {
	// 		// autorange: true,
	// 		// range: [86.8700008333, 138.870004167],
	// 		type: 'linear',
	// 		title: langdata && langdata.Depth ? langdata.Depth :  'Depth',
	// 		autorange: 'reversed',   
	// 	}
	// });

	const layoutDAS = {
		title: {
			text: langdata && langdata.Amplitude ? langdata.Amplitude : 'Amplitude',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			title: langdata && langdata.Amplitude ? langdata.Amplitude : 'Amplitude',
			type: 'linear'
		},
		yaxis: {
			title: langdata && langdata.Depth ? langdata.Depth :'Depth',
			// autorange: true,
			// range: [70, 160],
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
			type: 'linear',
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
		},
		autosize: true,
		height: graphScroll == true ? 10000 :0,
		shapes: [
			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
				y0: 0,
				x1: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
				y1: 1,
				line:{
					color: '#14877c',
					width: 2,
					dash:'dot'
				}
			},

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
				y0: 0,
				x1: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
				y1: 1,
				line:{
					color: '#14877c',
					width: 2,
					dash:'dot'
				}
			},
			//max threhold

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
		
				y0: 0,
		
				x1: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text:  langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
					font: { size: 10, color: 'red' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'top',
		
				},
		
			},

			// {

			// 	type: 'rect',
		
			// 	xref: 'x',
		
			// 	yref: 'paper',
		
			// 	x0: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
		
			// 	y0: 0,
		
			// 	x1: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
		
			// 	y1: 1,
		
			// 	fillcolor: 'transparent',
		
			// 	opacity: 0.2,
		
			// 	editable: true,
		
			// 	line: {
		
			// 		width: 0,
		
			// 	},
		
			// 	label: {
		
			// 		text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
			// 		font: { size: 10, color: 'red' },
		
			// 		textposition: 'top center',
		
			// 	},
		
			// },


			//min threshold

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
		
				y0: 0,
		
				x1: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',
		
					font: { size: 10, color: 'red' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'bottom',
		
				},
		
			},

			// {

			// 	type: 'rect',
		
			// 	xref: 'x',
		
			// 	yref: 'paper',
		
			// 	x0: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
		
			// 	y0: 0,
		
			// 	x1:ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
		
			// 	y1: 1,
		
			// 	fillcolor: 'transparent',
		
			// 	opacity: 0.2,
		
			// 	editable: true,
		
			// 	line: {
		
			// 		width: 0,
		
			// 	},
		
			// 	label: {
		
			// 		text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',
		
			// 		font: { size: 10, color: 'red' },
		
			// 		textposition: 'top center',
		
			// 	},
		
			// },

		],
	};

	// const [layoutDTS] = useState({
	// 	title: {
	// 		text: langdata && langdata.Temperature ? langdata.Temperature : 'Temperature',
	// 		font: {
	// 			size: 20,
	// 			// family: 'Arial, sans-serif',
	// 			// color: 'black',
	// 			weight: 'bold'
	// 		},
	// 	},
	// 	xaxis: {
	// 		// range: ['2016-07-01', '2016-12-31'],
	// 		type: 'linear',
	// 		title: langdata && langdata.Temperature ? langdata.Temperature : 'Temperature'
	// 	},
	// 	yaxis: {
	// 		autorange: 'reversed',   
	// 		// range: [86.8700008333, 138.870004167],
	// 		type: 'linear',
	// 		title: langdata && langdata.Depth ? langdata.Depth : 'Depth'
	// 	}
	// });

	const layoutDTS = {
		title: {
			text: langdata && langdata.Temperature ? langdata.Temperature : 'Temperature vs ' + langdata && langdata.Depth ? langdata.Depth :'Depth',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			title: langdata && langdata.Temperature ? langdata.Temperature : 'Temperature',
			type: 'linear'
		},
		yaxis: {
			title: langdata && langdata.Depth ? langdata.Depth :'Depth',
			// autorange: true,
			// range: [70, 160],
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
			type: 'linear',
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
		},
		autosize: true,
		height: graphScroll == true ? 10000 :0,
		shapes: [
			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
				y0: 0,
				x1: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
				y1: 1,
				line:{
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash:'dot'
				}
			},

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
				y0: 0,
				x1: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
				y1: 1,
				line:{
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash:'dot'
				}
			},

			//max threhold

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
		
				y0: 0,
		
				x1: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text:  langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
					font: { size: 10, color: 'red' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'top',
		
				},
		
			},

			// {

			// 	type: 'rect',
		
			// 	xref: 'x',
		
			// 	yref: 'paper',
		
			// 	x0: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
		
			// 	y0: 0,
		
			// 	x1: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
		
			// 	y1: 1,
		
			// 	fillcolor: 'transparent',
		
			// 	opacity: 0.2,
		
			// 	editable: true,
		
			// 	line: {
		
			// 		width: 0,
		
			// 	},
		
			// 	label: {
		
			// 		text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
			// 		font: { size: 10, color: 'red' },
		
			// 		textposition: 'top center',
		
			// 	},
		
			// },


			//min threshold

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
		
				y0: 0,
		
				x1: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',
		
					font: { size: 10, color: 'red' },
		
					// textposition: 'bottom center',
					textangle: 90,
					yanchor: 'bottom',
		
				},
		
			},

			// {

			// 	type: 'rect',
		
			// 	xref: 'x',
		
			// 	yref: 'paper',
		
			// 	x0: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
		
			// 	y0: 0,
		
			// 	x1:tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
		
			// 	y1: 1,
		
			// 	fillcolor: 'transparent',
		
			// 	opacity: 0.2,
		
			// 	editable: true,
		
			// 	line: {
		
			// 		width: 0,
		
			// 	},
		
			// 	label: {
		
			// 		text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',
		
			// 		font: { size: 10, color: 'red' },
		
			// 		textposition: 'top center',
		
			// 	},
		
			// },

		],
	};	

	const dotData = [
		{
			type: 'scatter',
			x: fracture && fracture.fracturedata && fracture.fracturedata.fractureLocations && fracture.fracturedata.fractureLocations && fracture.fracturedata.fractureLocations.Depth,
			y: fracture && fracture.fracturedata && fracture.fracturedata.fractureLocations && fracture.fracturedata.fractureLocations && fracture.fracturedata.fractureLocations.Distance,
			mode: 'markers',
			transforms: [{
				type: 'filter',
				target: 'y',
				operation: '>',
			// value: 4
			}],
			name: langdata && langdata.DistanceandDepth ? langdata.DistanceandDepth :  'Distance and Depth', // Legend entry name for scatter plot
			marker: {
				size: 10, // Increase the size of the points
				color: 'blue', // Optional: Change point color if needed
			},
		//   hoverlabel: {
		//     bgcolor: 'white', // Set the tooltip background color
		//     // font: {
		//     //   color: 'white', // Set the text color
		//     // }
		//   }
		},
		{
			type: 'scatter',
			x: [0, 0], // Use a data point to ensure the vertical line is at 'Moe'
			y: [0, depthRangeTo], // Y range for the vertical line
			mode: 'lines',
			line: {
				color: 'red',
				width: 5,
			},
			name: langdata && langdata.OilWell ? langdata.OilWell : 'Oil Well', // Legend entry name for the vertical line
			hoverinfo: 'text' // Show only the text in the tooltip
		}
	];

	const dotLayout = {
		title:{
			text : langdata && langdata.FracturelocationMap ? langdata.FracturelocationMap : 'Fracture location Map',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			title: langdata && langdata.Distancemfromthefiber ? langdata.Distancemfromthefiber : 'Distance (m) from the fiber',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [-depthRangeTo , depthRangeTo ] : [ -tempendDepth , tempendDepth ]

			// tickvals: [-2000, -1000, 0, 1000, 2000, 3000, 4000], // Ensure these match your x-axis values
			// ticktext: [-2000, -1000, 0, 1000, 2000, 3000, 4000] // Ensure these match your x-axis labels
		},
		yaxis: {
			title:  langdata && langdata.Depthm ? langdata.Depthm :  'Depth (m)',
			// range: [0, 10], // Adjust as necessary to ensure the vertical line is visible
			// autorange: 'reversed',   
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ]
		},
		legend: {
			x: 1, // Position legend horizontally
			y: 1, // Position legend vertically
			traceorder: 'normal',
			orientation: 'v' // Vertical orientation
		}
	};

	const handleRangefrom = (e) => {				
		if(e.target.value) {
			setDepthRangeFrom(parseInt(e.target.value));
			setDrawerDepthFrom(parseInt(e.target.value));

		} else {
			setDepthRangeFrom();
			setDrawerDepthFrom();
		}
	};

	const handleRangeTo = (e) => {
		if(e.target.value) {
			setDepthRangeTo(e.target.value);
			setDrawerDepthTo(e.target.value);

		} else {
			setDepthRangeTo();
			setDrawerDepthTo();
		}
	};		

	const handlefullSize = (size,graph) =>{
		setfullSize(size);
		setGraph(graph);
	};		
			
	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handleSetZone = (e,options)=>{
		
		setZoneId(e);
		setZoneFrom(options.zoneFrom);
		setDepthRangeFrom(options.zoneFrom || options.zoneFrom == 0 ? options.zoneFrom : tempstartDepth);
		setZoneTo(options.zoneTo);
		setDepthRangeTo(options.zoneTo || options.zoneTo == 0 ? options.zoneTo : tempendDepth);

		setDrawerDepthFrom(options.zoneFrom || options.zoneFrom == 0 ? options.zoneFrom : tempstartDepth);
		setDrawerDepthTo(options.zoneTo || options.zoneTo == 0 ? options.zoneTo : tempendDepth);


	};

	const disabledRangeTime = (currentDate) => {
		if (!currentDate) {
			return {};
		}
		
		const now = moment();
		
		// Disable future time slots on the current date
		if (currentDate.isSame(now, 'day')) {
			return {
				disabledHours: () => {
					const currentHour = now.hour();
					// Disable hours after the current hour
					return Array.from({ length: 24 }, (_, i) => (i > currentHour ? i : null)).filter(i => i !== null);
				},
				// disabledMinutes: () => {
				// 	const currentHour = now.hour();
				// 	const currentMinute = now.minute();
				// 	// Disable minutes after the current minute in the current hour
				// 	if (currentHour === now.hour()) {
				// 		return Array.from({ length: 60 }, (_, i) => (i > currentMinute ? i : null)).filter(i => i !== null);
				// 	}
				// 	return [];
				// },
				// disabledSeconds: () => {
				// 	const currentHour = now.hour();
				// 	const currentMinute = now.minute();
				// 	const currentSecond = now.second();
				// 	// Disable seconds after the current second in the current minute
				// 	if (currentHour === now.hour() && currentMinute === now.minute()) {
				// 		return Array.from({ length: 60 }, (_, i) => (i > currentSecond ? i : null)).filter(i => i !== null);
				// 	}
				// 	return [];
				// },
			};
		}
		
		// For future dates, allow all hours, minutes, and seconds
		return {};
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const handleSetDate = (e) =>{
		if(e) {
			setStartDate(moment.utc(new Date(e[0] && e[0].$d)).toISOString());
			setEndDate(moment.utc(new Date(e[1] && e[1].$d)).toISOString());  
		} else {
			setStartDate(null);
			setEndDate(null);
		}
	};
	
	const handleSetChannel = (value) => {
		setChannelId(value);
	};

	const handleSetFracture = (value) => {
		setFractureId(value);
	};

	const handleFractureList = () => {
		navigate(('/fracture-monitoring-details'), { state : { data : { channelId : channelId ? channelId : tempchannelId, wellId : wellId == undefined ? tempWellId : wellId, fractureId : fractureId ? fractureId : tempFractureid, startDate : startDate, endDate : endDate, path : 'fracture-monitoring-visualization'}}});
	};

	useEffect(() => {
		let params = '';		
		if(wellId || tempWellId) {
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/fracturemonitoringconfig?${params}`,
				headers: { 
					Authorization:`Bearer ${authtoken}`	
				}
			};
		
			axios.request(config)
				.then((response) => {
					if(response.status == 200) {
						dispatch({ type : FRACTURE_GRAPH_CONFIG, payload : response.data });
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	},[wellId, tempWellId]);

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
		setIsDTSDataModalOpen(false);
		setTrainSystem(null);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setIsDTSDataModalOpen(false);
		setTrainSystem(null);
	};

	const handleDepthChange = (value) => {
		setDrawerDepthFrom(value[0]);
		setDrawerDepthTo(value[1]);
	};

	const handleInputDepthRangefrom = (e) => {
		setDrawerDepthFrom(parseInt(e.target.value));
		setDrawerDepthTo(drawerDepthTo ? drawerDepthTo : depthRangeTo);
	};

	const handleInputDepthRangeTo = (e) => {
		setDrawerDepthTo(parseInt(e.target.value));	
		setDrawerDepthFrom(drawerDepthFrom ? drawerDepthFrom : depthRangeFrom);
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues : {
			id : fracture.graphConfig && fracture.graphConfig.id,
			WellId : fracture.graphConfig && fracture.graphConfig.wellinfoId,
			minAmpThreshold : fracture.graphConfig && fracture.graphConfig.ampThresholds && fracture.graphConfig.ampThresholds.minThreshold,
			maxAmpThreshold : fracture.graphConfig && fracture.graphConfig.ampThresholds && fracture.graphConfig.ampThresholds.maxThreshold,
			minAmpSNThresholds : fracture.graphConfig && fracture.graphConfig.ampSNThresholds && fracture.graphConfig.ampSNThresholds.minThreshold,
			maxAmpSNThresholds : fracture.graphConfig && fracture.graphConfig.ampSNThresholds && fracture.graphConfig.ampSNThresholds.maxThreshold,
			minAmpFreqThresholds : fracture.graphConfig && fracture.graphConfig.ampFreqThresholds && fracture.graphConfig.ampFreqThresholds.minThreshold,
			maxAmpFreqThresholds : fracture.graphConfig && fracture.graphConfig.ampFreqThresholds && fracture.graphConfig.ampFreqThresholds.maxThreshold,
			minTempThreshold : fracture.graphConfig && fracture.graphConfig.tempThresholds && fracture.graphConfig.tempThresholds.minThreshold,
			maxTempThreshold : fracture.graphConfig && fracture.graphConfig.tempThresholds && fracture.graphConfig.tempThresholds.maxThreshold,
			minTempSNThresholds : fracture.graphConfig && fracture.graphConfig.tempSNThresholds && fracture.graphConfig.tempSNThresholds.minThreshold,
			maxTempSNThresholds : fracture.graphConfig && fracture.graphConfig.tempSNThresholds && fracture.graphConfig.tempSNThresholds.maxThreshold,
			minTempFreqThresholds : fracture.graphConfig && fracture.graphConfig.tempFreqThresholds && fracture.graphConfig.tempFreqThresholds.minThreshold,
			maxTempFreqThresholds : fracture.graphConfig && fracture.graphConfig.tempFreqThresholds && fracture.graphConfig.tempFreqThresholds.maxThreshold,
			minPressureThreshold : fracture.graphConfig && fracture.graphConfig.pressThresholds && fracture.graphConfig.pressThresholds.minThreshold,
			maxPressureThreshold : fracture.graphConfig && fracture.graphConfig.pressThresholds && fracture.graphConfig.pressThresholds.maxThreshold,
			minPressSNThresholds : fracture.graphConfig && fracture.graphConfig.pressSNThresholds && fracture.graphConfig.pressSNThresholds.minThreshold,
			maxPressSNThresholds : fracture.graphConfig && fracture.graphConfig.pressSNThresholds && fracture.graphConfig.pressSNThresholds.maxThreshold,
			minPressFreqThresholds : fracture.graphConfig && fracture.graphConfig.pressFreqThresholds && fracture.graphConfig.pressFreqThresholds.minThreshold,
			maxPressFreqThresholds : fracture.graphConfig && fracture.graphConfig.pressFreqThresholds && fracture.graphConfig.pressFreqThresholds.maxThreshold,
			ampThresholdsOverride : fracture.graphConfig && fracture.graphConfig.ampThresholdsOverride,
			tempThresholdsOverride : fracture.graphConfig && fracture.graphConfig.tempThresholdsOverride,
			pressThresholdsOverride : fracture.graphConfig && fracture.graphConfig.pressThresholdsOverride,
			ampSNThresholdsOverride : fracture.graphConfig && fracture.graphConfig.ampSNThresholdsOverride,
			tempSNThresholdsOverride : fracture.graphConfig && fracture.graphConfig.tempSNThresholdsOverride,
			pressSNThresholdsOverride : fracture.graphConfig && fracture.graphConfig.pressSNThresholdsOverride,
			ampFreqThresholdsOverride : fracture.graphConfig && fracture.graphConfig.ampFreqThresholdsOverride,
			tempFreqThresholdsOverride : fracture.graphConfig && fracture.graphConfig.tempFreqThresholdsOverride,
			pressFreqThresholdsOverride : fracture.graphConfig && fracture.graphConfig.pressFreqThresholdsOverride,
			defaultminAmpThreshold : fracture.graphConfig && fracture.graphConfig.ampThresholds && fracture.graphConfig.ampThresholds.defaultminThreshold,
			defaultmaxAmpThreshold : fracture.graphConfig && fracture.graphConfig.ampThresholds && fracture.graphConfig.ampThresholds.defaultmaxThreshold,
			defaultminAmpSNThresholds : fracture.graphConfig && fracture.graphConfig.ampSNThresholds && fracture.graphConfig.ampSNThresholds.defaultminThreshold,
			defaultmaxAmpSNThresholds : fracture.graphConfig && fracture.graphConfig.ampSNThresholds && fracture.graphConfig.ampSNThresholds.defaultmaxThreshold,
			defaultminAmpFreqThresholds : fracture.graphConfig && fracture.graphConfig.ampFreqThresholds && fracture.graphConfig.ampFreqThresholds.defaultminThreshold,
			defaultmaxAmpFreqThresholds : fracture.graphConfig && fracture.graphConfig.ampFreqThresholds && fracture.graphConfig.ampFreqThresholds.defaultmaxThreshold,
			defaultminTempThreshold : fracture.graphConfig && fracture.graphConfig.tempThresholds && fracture.graphConfig.tempThresholds.defaultminThreshold,
			defaultmaxTempThreshold : fracture.graphConfig && fracture.graphConfig.tempThresholds && fracture.graphConfig.tempThresholds.defaultmaxThreshold,
			defaultminTempSNThresholds : fracture.graphConfig && fracture.graphConfig.tempSNThresholds && fracture.graphConfig.tempSNThresholds.defaultminThreshold,
			defaultmaxTempSNThresholds : fracture.graphConfig && fracture.graphConfig.tempSNThresholds && fracture.graphConfig.tempSNThresholds.defaultmaxThreshold,
			defaultminTempFreqThresholds : fracture.graphConfig && fracture.graphConfig.tempFreqThresholds && fracture.graphConfig.tempFreqThresholds.defaultminThreshold,
			defaultmaxTempFreqThresholds : fracture.graphConfig && fracture.graphConfig.tempFreqThresholds && fracture.graphConfig.tempFreqThresholds.defaultmaxThreshold,
			defaultminPressureThreshold : fracture.graphConfig && fracture.graphConfig.pressThresholds && fracture.graphConfig.pressThresholds.defaultminThreshold,
			defaultmaxPressureThreshold : fracture.graphConfig && fracture.graphConfig.pressThresholds && fracture.graphConfig.pressThresholds.defaultmaxThreshold,
			defaultminPressSNThresholds : fracture.graphConfig && fracture.graphConfig.pressSNThresholds && fracture.graphConfig.pressSNThresholds.defaultminThreshold,
			defaultmaxPressSNThresholds : fracture.graphConfig && fracture.graphConfig.pressSNThresholds && fracture.graphConfig.pressSNThresholds.defaultmaxThreshold,
			defaultminPressFreqThresholds : fracture.graphConfig && fracture.graphConfig.pressFreqThresholds && fracture.graphConfig.pressFreqThresholds.defaultminThreshold,
			defaultmaxPressFreqThresholds : fracture.graphConfig && fracture.graphConfig.pressFreqThresholds && fracture.graphConfig.pressFreqThresholds.defaultmaxThreshold,
		},
		onSubmit: () => {
			
			const id = parseInt(formik.values.id);
			let data = JSON.stringify({
				'ampThresholds': {
					'maxThreshold': formik.values.maxAmpThreshold,
					'minThreshold': formik.values.minAmpThreshold,
					'defaultmaxThreshold': formik.values.defaultmaxAmpThreshold,
					'defaultminThreshold': formik.values.defaultminAmpThreshold
				},
				'ampThresholdsOverride': formik.values.ampThresholdsOverride ,
				'tempThresholds': {
					'maxThreshold': formik.values.maxTempThreshold,
					'minThreshold': formik.values.minTempThreshold,
					'defaultmaxThreshold': formik.values.defaultmaxTempThreshold,
					'defaultminThreshold': formik.values.defaultminTempThreshold
				},
				'tempThresholdsOverride': formik.values.tempThresholdsOverride ,
				// 'ampSNThresholds': {
				// 	'maxThreshold': formik.values.maxAmpSNThresholds,
				// 	'minThreshold': formik.values.minAmpSNThresholds,
				// 	'defaultmaxThreshold': formik.values.defaultmaxAmpSNThresholds,
				// 	'defaultminThreshold': formik.values.defaultminAmpSNThresholds
				// },
				// 'ampSNThresholdsOverride': formik.values.ampSNThresholdsOverride ,
				// 'tempSNThresholds': {
				// 	'maxThreshold': formik.values.maxTempSNThresholds,
				// 	'minThreshold': formik.values.minTempSNThresholds,
				// 	'defaultmaxThreshold': formik.values.defaultmaxTempSNThresholds,
				// 	'defaultminThreshold': formik.values.defaultminTempSNThresholds
				// },
				// 'tempSNThresholdsOverride': formik.values.tempSNThresholdsOverride ,
				// 'ampFreqThresholds': {
				// 	'maxThreshold': formik.values.maxAmpFreqThresholds,
				// 	'minThreshold': formik.values.minAmpFreqThresholds,
				// 	'defaultmaxThreshold': formik.values.defaultmaxAmpFreqThresholds,
				// 	'defaultminThreshold': formik.values.defaultminAmpFreqThresholds
				// },
				// 'ampFreqThresholdsOverride': formik.values.ampFreqThresholdsOverride ,
				// 'tempFreqThresholds': {
				// 	'maxThreshold': formik.values.maxTempFreqThresholds,
				// 	'minThreshold': formik.values.minTempFreqThresholds,
				// 	'defaultmaxThreshold': formik.values.defaultmaxTempFreqThresholds,
				// 	'defaultminThreshold': formik.values.defaultminTempFreqThresholds
				// },
				// 'tempFreqThresholdsOverride': formik.values.tempFreqThresholdsOverride ,
				'pressThresholds': {
					'maxThreshold': formik.values.maxPressureThreshold,
					'minThreshold': formik.values.minPressureThreshold,
					'defaultmaxThreshold': formik.values.defaultmaxPressureThreshold,
					'defaultminThreshold': formik.values.defaultminPressureThreshold
				},
				'pressThresholdsOverride': formik.values.pressThresholdsOverride ,	
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/fracturemonitoringconfig/${id}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`	
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201) {
						getWellInfo(authtoken);
						Modal.success({ title: langdata && langdata.Graphdetailssuccessfullyupdated ? langdata.Graphdetailssuccessfullyupdated : 'Graph details successfully updated.' });
						setIsModalOpen(false);
						setDepthRangeFrom(drawerDepthFrom);
						setDepthRangeTo(drawerDepthTo);
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdategraphdetails ? langdata.Unabletoupdategraphdetails : 'Unable to update graph details' });

					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdategraphdetails ? langdata.Unabletoupdategraphdetails : 'Unable to update graph details' });
					}
				});
		}
	});

	const depthMarks = {
		[wellFrom ? wellFrom : tempstartDepth] : langdata && langdata.Min ? langdata.Min : 'Min',
		[wellTo ? wellTo : tempendDepth] :langdata && langdata.Max ? langdata.Max : 'Max', 
		// [drawerDepthFrom] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{drawerDepthFrom}</span>},
		// [drawerDepthTo] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{drawerDepthTo}</span>},
	};	

	const ampMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		// [formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold}</span>},
		// [formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold}</span>}
	};		


	const tempMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		// [formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold}</span>},
		// [formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold}</span>}
	};	

	const pressureMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		// [formik.values.pressThresholdsOverride == true ? formik.values.minPressureThreshold : formik.values.defaultminPressureThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.pressThresholdsOverride == true ? formik.values.minPressureThreshold : formik.values.defaultminPressureThreshold}</span>},
		// [formik.values.pressThresholdsOverride == true ? formik.values.maxPressureThreshold : formik.values.defaultmaxPressureThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.pressThresholdsOverride == true ? formik.values.maxPressureThreshold : formik.values.defaultmaxPressureThreshold}</span>}
	};

	const handleReset = () => {
		formik.resetForm();
		setDrawerDepthFrom(wellFrom ? wellFrom : tempstartDepth);
		setDrawerDepthTo(wellTo ? wellTo : tempendDepth);
	};

	const handleDTSClick = (value, system) => {		
		setTrainSystem(system);
		setDTSClickableData( value.points[0]);
		setIsDTSDataModalOpen(true);
		if(system == 'dts'){
			setChannelAndPidsData({
				channel_id : fracture && fracture.dtsgraphData && fracture.dtsgraphData.channel_id,
				channel_name : fracture && fracture.dtsgraphData && fracture.dtsgraphData.channel_name,
				pids_id : fracture && fracture.dtsgraphData && fracture.dtsgraphData.pids_id,
				pids_name : fracture && fracture.dtsgraphData && fracture.dtsgraphData.pids_name,
				wellinfoName : fracture && fracture.dtsgraphData && fracture.dtsgraphData.well_name
			});
		} else {
			setChannelAndPidsData({
				channel_id : fracture && fracture.dasgraphData && fracture.dasgraphData.channel_id,
				channel_name : fracture && fracture.dasgraphData && fracture.dasgraphData.channel_name,
				pids_id : fracture && fracture.dasgraphData && fracture.dasgraphData.pids_id,
				pids_name : fracture && fracture.dasgraphData && fracture.dasgraphData.pids_name,
				wellinfoName : fracture && fracture.dasgraphData && fracture.dasgraphData.well_name
			});
		}
	};		
	
	
	const handleSelectEvent = (value) => {
		setSelectedActivity(value);
	};

	const handleNavigateSimulation = () => {
		navigate(( '/add-simulations'),{ state: {time : dtsClickableData && dtsClickableData.x, depth : Math.trunc(dtsClickableData && dtsClickableData.y ), 
			activity : selectdActivity, wellId : wellId ? wellId : tempWellId, system : trainSystem, channelAndPidsData : channelAndPidsData} });
	};
	
	const handleSelectGraphScroll = (value) => {
		setGraphScroll(value);
	};

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={8}>
						<Row>
							<Space>
								<AreaChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.HydraulicFracturingAnalysis ? langdata.HydraulicFracturingAnalysis : 'Hydraulic Fracturing Analysis'}</Title>
							<Space>&nbsp;&nbsp;</Space>
						</Row>
					</Col>
					
					<Col span={16}>
						<Row justify="end">
							<Col>
								<Tooltip placement="bottom" title={langdata && langdata.FilterbyWell ? langdata.FilterbyWell : 'Filter by well'}>
									<Space>
										<Select
											style={{ width: '180px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectWell ? langdata.SelectWell : 'Select Well'}
											optionFilterProp="children"
											value={wellId ? wellId : tempWellId}
											options={welldata}
											onSelect={(e, options)=>handleSetWell(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyzone ? langdata.Filterbyzone : 'Filter by zone'}>
									<Space>
										<Select
											style={{ width: '180px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectZone ? langdata.SelectZone : 'Select Zone'}
											optionFilterProp="children"
											defaultValuevalue={null}
											value={zoneId}
											options={zonelist}
											onSelect={(e, options)=>handleSetZone(e, options)}
										/>
									</Space>
								</Tooltip>

								{dasEnabled == true || dtsEnabled == true || pressEnabled == true ?
									<>
										<Space>&nbsp;</Space>
										<Tooltip placement="bottom" title={langdata && langdata.EnterDepthRange ? langdata.EnterDepthRange:'Enter Depth Range'}>
											<Space.Compact size="middle" style={{ width : '180px', margin:'5px'}}>
												<Input type='number' value={depthRangeFrom} max={zonefrom} min={zoneto}  placeholder={langdata && langdata.DepthRangeFrom ? langdata.DepthRangeFrom: 'Depth Range From'} onChange={handleRangefrom} />
												<Input type='number' value={depthRangeTo}  max={zonefrom} min={zoneto}  placeholder={langdata && langdata.DepthRangeTo ? langdata.DepthRangeTo: 'Depth Range To'} onChange={handleRangeTo}/>
											</Space.Compact>
										</Tooltip>
										<Space>&nbsp;</Space>
										<Space>
											<Button style ={{margin:'5px'}} type='primary' onClick={showModal}><FilterOutlined /></Button>
										</Space>
									</>
									: null }
								
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					// marginTop : '5px',
					// paddingLeft: '10px',
					// paddingRight: '10px',
					// background: '#FFFFFF',
					alignItems:'center',
				}} >
					<Col span={18}>
						<Row>
							<Breadcrumb
								items={[
									{
										href: '/dashboard',
										title: <HomeOutlined />,
									},
									{
										title: ( state && state.path === 'well-visualization' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a> :  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a>)
									},
									{
										title: `${langdata && langdata.HydraulicFracturingAnalysis ? langdata.HydraulicFracturingAnalysis : 'Hydraulic Fracturing Analysis'}`,
									},
								]}
								// style={{ marginBottom : '10px',marginLeft:'20px'}}
							/>
						</Row>
					</Col>
					<Col span={6} style={{ display : 'flex', justifyContent : 'flex-end', marginLeft:'-10px'}}>
						<Tooltip title={langdata && langdata.Graphlayout ? langdata.Graphlayout:'Graph layout'}>
							<Switch style={{backgroundColor: graphScroll == false ? '' : 'green'}} checkedChildren={langdata && langdata.Scroll ? langdata.Scroll:'Scroll'} unCheckedChildren={langdata && langdata.Fixed ? langdata.Fixed:'Fixed'} checked={graphScroll} onClick={handleSelectGraphScroll}/>
						</Tooltip>
					</Col>
				</Row>
				{fullsize==false ?
					<>
				
						{ dasEnabled == true ?
							<Card style={{ minHeight: '500px', margin: '10px', padding : '5px' }}>
								<Row style={{
									// margin: '10px',
									// marginBottom : '0px',
									// marginTop : '10px',
									// paddingLeft: '10px',
									// paddingRight: '10px',
									// backgroundColor: '#ffffff',
									alignItems:'center',
								}} >
									<Col span={16} style={{ minHeight : '450px'}}>
										<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
											<button
												style={{ marginTop : '5px', marginRight : '25px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'acousticWaterfall');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>

											<p style={{marginTop:'-15px',marginLeft:'-15px', fontSize : '10px'}}
												// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
												className="wellInfo" >
												{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
												<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
												<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {fracture && fracture.dasgraphData && fracture.dasgraphData.channel_name} <br />
											</p>
											<Col style={{overflowY: graphScroll == true ? 'scroll' : '', minHeight : '450px'}}>
												<Plot
													data={AcousticWaterfallPlotData}
													layout={AcousticWaterfallLayout}
													style={{ width: '100%', height: '450px', minHeight : '450px' }}
													onClick={(value) => {handleDTSClick(value, 'das');}}
													config={{ 
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
	
													}}
												/>
											</Col>
										</Spin>
									</Col>
									<Col span={8} style={{ minHeight : '450px', backgroundColor: '#ffffff'}}>
										<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
											<button
												style={{ marginTop : '6px', marginRight : '1px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'amplitude');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Col style={{overflowY: graphScroll == true ? 'scroll' : '', minHeight : '450px'}}>
												<Plot
													data={dasData}
													layout={layoutDAS}
													config={{ 
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
	
													}}
													style={{ width: '100%', height: '450px' , minHeight : '450px' }}
												/>
											</Col>
										</Spin>
									</Col>
									{/* <Space>&nbsp;</Space> */}
								</Row>
							</Card>
							: null }
						{dtsEnabled == true ?
							<Card style={{ minHeight: '500px', margin: '10px', padding : '5px' }}>
								<Row style={{
									// margin: '10px',
									// marginBottom : '0px',
									// marginTop : '10px',
									// paddingLeft: '10px',
									// paddingRight: '10px',
									// backgroundColor: '#ffffff',
									alignItems:'center',
								}} >
									<Col span={16} style={{ minHeight : '450px', backgroundColor: '#ffffff'}}>
										<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
											<button
												style={{ marginTop : '5px', marginRight : '25px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'temperatureWaterfall');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>

											<p style={{marginTop:'-15px',marginLeft:'-15px', fontSize : '10px'}}
												// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
												className="wellInfo" >
												{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
												<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
												<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {fracture && fracture.dtsgraphData && fracture.dtsgraphData.channel_name} <br />
											</p>
											<Col style={{overflowY: graphScroll == true ? 'scroll' : '', minHeight : '450px'}}>
												<Plot
													data={TemperatureWaterfallPlotData}
													layout={TemperatureWaterfallLayout}
													style={{ width: '100%', height: '450px' , minHeight : '450px' }}
													onClick={(value) => {handleDTSClick(value, 'dts');}}
													config={{ 
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
	
													}}
												/>
											</Col>
										</Spin>
									</Col>
									<Col span={8} style={{ minHeight : '450px', backgroundColor: '#ffffff'}}>
										<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
											<button
												style={{ marginTop : '6px', marginRight : '1px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'temperature');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Col style={{overflowY: graphScroll == true ? 'scroll' : '', minHeight : '450px'}}>
												<Plot
													data={dtsData}
													layout={layoutDTS}
													config={{ 
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
	
													}}
													style={{ width: '100%', height: '450px' , minHeight : '450px' }}
												/>
											</Col>
										</Spin>
									</Col>
								</Row>
							</Card>
							: null }

						<Col span={24} style={{display : 'flex', justifyContent : 'flex-end', marginRight : '10px', backgroundColor : '#ffffff', padding : '10px', marginTop : '10px', marginBottom : '5px', marginLeft : '10px'}}>
							<Space>&nbsp;</Space>
							<Tooltip placement="bottom" title={langdata && langdata.Filterbychannel ? langdata.Filterbychannel : 'Filter by channel'}>
								<Space>
									<Select
										style={{ width: '180px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectChannel ? langdata.SelectChannel : 'Select Channel'}
										optionFilterProp="children"
										defaultValuevalue={null}
										value={channelId ?  channelId : tempchannelId}
										options={channellist}
										onSelect={(value)=>handleSetChannel(value)}
									/>
								</Space>
							</Tooltip>
							<Space>&nbsp;&nbsp;&nbsp;</Space>
							<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}>
								<RangePicker 
									showTime ={{hideDisabledOptions: true, format : 'YYYY-MM-DD HH'}} 
									style={{ width: '350px'}}
									defaultValue={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
									format={settingsdateformat + ' ' + settingstimeformat}
									placeholder={[langdata && langdata.Startdateandtime ? langdata.Startdateandtime : 'Start date and time', langdata && langdata.Enddateandtime ? langdata.Enddateandtime : 'End date and time']}
									disabledDate={disabledDate}
									disabledTime={disabledRangeTime}
									onChange={handleSetDate}
									allowClear={true} />
							</Tooltip>
							<Space>&nbsp;&nbsp;&nbsp;</Space>
							<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectfracture ? langdata.Pleaseselectfracture :'Please select fracture'}>
								<Select
									style={{ width: '180px'}}
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Selectfracture ? langdata.Selectfracture : 'Select fracture'}
									optionFilterProp="children"
									defaultValuevalue={null}
									value={fractureId}
									options={fracturelist}
									onSelect={(value)=>handleSetFracture(value)}
								/>
							</Tooltip>
							<Space>&nbsp;&nbsp;&nbsp;</Space>
							{/* <Tooltip placement="bottom" title={langdata && langdata.AddNew ? langdata.AddNew:'Add New'}> */}
							<Button
								// style={{ marginLeft : '10px'}}
								type='primary'
								onClick={handleFractureList}
							>{langdata && langdata.Fracture ? langdata.Fracture:'Fracture'}</Button>
							{/* </Tooltip> */}
						</Col>
						<Row style={{
							margin: '10px',
							// marginBottom : '0px',
							// marginTop : '10px',
							// paddingLeft: '10px',
							// paddingRight: '10px',
							// backgroundColor: '#ffffff',
							alignItems:'center',
						}} >
							{/* <Col span={1}></Col> */}
							<Col span={24} style={{ minHeight : '450px'}}>
								<Card style={{ minHeight: '500px'}}>
									<Spin  spinning={fracture.loading} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
										<button
											style={{ marginTop : '11px', marginRight : '6px'}}
											onClick={()=>{handlefullSize(fullsize === false ? true:false,'fracturelocationMap');}} 
											className="graphZoomButton" ><FullscreenOutlined /></button>
										<Plot
											data={dotData}
											layout={dotLayout}
											config={{ 
												responsive: true,
												// displayModeBar: true,
												displaylogo : false,
												modeBarButtonsToRemove: [
													'pan2d',
													'zoom2d',
													'select2d',
													'zoomIn2d',
													'zoomOut2d',
													'autoScale2d',
													'resetScale2d',
													'hoverClosestCartesian',
													'hoverCompareCartesian',
													'zoom3d',
													'pan3d',
													'resetViews3d',
													'hoverClosest3d',
													'hoverCompare3d',
													'orbitRotation',
													'tableRotation',
													'resetCameraDefault3d',
													'resetCameraLastSave3d',
													'lasso2d'
												],

											}}
											style={{ width: '100%', height: '100%', minHeight : '450px' }}
										/>
									</Spin>
								</Card>
							</Col>
						</Row>

						{pressEnabled==true ?
							<Row style={{
								margin: '10px',
								// marginBottom : '0px',
								// marginTop : '10px',
								// paddingLeft: '10px',
								// paddingRight: '10px',
								// backgroundColor: '#ffffff',
								alignItems:'center',
							}} >
								<Col span={24} style={{ minHeight : '450px', paddingRight : '5px'}}>
									<Card style={{ minHeight: '500px'}}>
										<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
											<button
												style={{ marginTop : '11px', marginRight : '6px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'pressurePumpData');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>

											<p style={{marginTop:'-15px',marginLeft:'-15px', fontSize : '10px'}}
												// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
												className="wellInfo" >
												{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
												<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
												<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {fracture && fracture.pressuregraphData && fracture.pressuregraphData.channel_name} <br />
											</p>
											<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
												<Plot
													data={pressureData}
													layout={pressureLayout}
													config={{ 
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],

													}}
													style={{ width: '100%', height: '450px', minHeight : '450px' }}
												/>
											</Col>
										</Spin>
									</Card>

								</Col>
							</Row> : null 
						}
					</>
					: 
					graph =='acousticWaterfall' ? 
						<Col span={24} style={{padding:'10px'}}>
							<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
								<button
									// style={{ marginTop : '35px', marginRight : '15px'}}
									onClick={()=>{handlefullSize(fullsize === false ? true:false,'acousticWaterfall');}} 
									className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
								<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
									// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
									className="wellInfo" >
									{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
									<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
									<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {fracture && fracture.dasgraphData && fracture.dasgraphData.channel_name} <br />
								</p>
								<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
									<Plot
										data={AcousticWaterfallPlotData}
										layout={AcousticWaterfallLayout}
										style={{ width: '100%', height: '800px' }}
										onClick={(value) => {handleDTSClick(value, 'das');}}
										config={{ 
											responsive: true,
											// displayModeBar: true,
											displaylogo : false,
											modeBarButtonsToRemove: [
												'pan2d',
												'zoom2d',
												'select2d',
												'zoomIn2d',
												'zoomOut2d',
												'autoScale2d',
												'resetScale2d',
												'hoverClosestCartesian',
												'hoverCompareCartesian',
												'zoom3d',
												'pan3d',
												'resetViews3d',
												'hoverClosest3d',
												'hoverCompare3d',
												'orbitRotation',
												'tableRotation',
												'resetCameraDefault3d',
												'resetCameraLastSave3d',
												'lasso2d'
											],
	
										}}
									/>
								</Col>
							</Spin>
						</Col>
						: 
						graph =='amplitude' ? 
							<Col span={24} style={{padding:'10px'}}>
								<button
									style={{margin : '10px'}}
									onClick={()=>{handlefullSize(fullsize === false ? true:false,'amplitude');}} 
									className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
								<p style={{marginTop:'10px',marginLeft:'40px', fontSize : '10px'}}
									// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
									className="wellInfo" >
									{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
									<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
									<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {fracture && fracture.dasgraphData && fracture.dasgraphData.channel_name} <br />
								</p>
								<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
									<Plot
										data={dasData}
										layout={layoutDAS}
										style={{ width: '100%', height: '800px' }}
										config={{ 
											responsive: true,
											// displayModeBar: true,
											displaylogo : false,
											modeBarButtonsToRemove: [
												'pan2d',
												'zoom2d',
												'select2d',
												'zoomIn2d',
												'zoomOut2d',
												'autoScale2d',
												'resetScale2d',
												'hoverClosestCartesian',
												'hoverCompareCartesian',
												'zoom3d',
												'pan3d',
												'resetViews3d',
												'hoverClosest3d',
												'hoverCompare3d',
												'orbitRotation',
												'tableRotation',
												'resetCameraDefault3d',
												'resetCameraLastSave3d',
												'lasso2d'
											],
	
										}}
									/>
								</Col>
							</Col>
							:
							graph =='temperatureWaterfall' ? 
								<Col span={24} style={{padding:'10px'}}>
									<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}>
										<button
											// style={{ marginTop : '35px', marginRight : '15px'}}
											onClick={()=>{handlefullSize(fullsize === false ? true:false,'temperatureWaterfall');}} 
											className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
										<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
											// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
											className="wellInfo" >
											{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
											<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
											<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {fracture && fracture.dtsgraphData && fracture.dtsgraphData.channel_name} <br />
										</p>
										<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
											<Plot
												data={TemperatureWaterfallPlotData}
												layout={TemperatureWaterfallLayout}
												style={{ width: '100%', height: '800px' }}
												onClick={(value) => {handleDTSClick(value, 'dts');}}
												config={{ 
													responsive: true,
													// displayModeBar: true,
													displaylogo : false,
													modeBarButtonsToRemove: [
														'pan2d',
														'zoom2d',
														'select2d',
														'zoomIn2d',
														'zoomOut2d',
														'autoScale2d',
														'resetScale2d',
														'hoverClosestCartesian',
														'hoverCompareCartesian',
														'zoom3d',
														'pan3d',
														'resetViews3d',
														'hoverClosest3d',
														'hoverCompare3d',
														'orbitRotation',
														'tableRotation',
														'resetCameraDefault3d',
														'resetCameraLastSave3d',
														'lasso2d'
													],
	
												}}
											/>
										</Col>
									</Spin>
								</Col>
								:
								graph =='temperature' ? 
									<Col span={24} style={{padding:'10px'}}>
										<button
											style={{margin : '10px'}}
											onClick={()=>{handlefullSize(fullsize === false ? true:false,'temperature');}} 
											className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
										<p style={{marginTop:'10px',marginLeft:'40px', fontSize : '10px'}}
											// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
											className="wellInfo" >
											{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
											<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
											<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {fracture && fracture.dtsgraphData && fracture.dtsgraphData.channel_name} <br />
										</p>
										<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
											<Plot
												data={dtsData}
												layout={layoutDTS}
												style={{ width: '100%', height: '800px' }}
												config={{ 
													responsive: true,
													// displayModeBar: true,
													displaylogo : false,
													modeBarButtonsToRemove: [
														'pan2d',
														'zoom2d',
														'select2d',
														'zoomIn2d',
														'zoomOut2d',
														'autoScale2d',
														'resetScale2d',
														'hoverClosestCartesian',
														'hoverCompareCartesian',
														'zoom3d',
														'pan3d',
														'resetViews3d',
														'hoverClosest3d',
														'hoverCompare3d',
														'orbitRotation',
														'tableRotation',
														'resetCameraDefault3d',
														'resetCameraLastSave3d',
														'lasso2d'
													],
	
												}}
											/>
										</Col>
									</Col>
									:
									graph =='pressurePumpData' ? 
										<Col span={24} style={{padding:'10px'}}>
											<button
												style={{margin : '10px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'pressurePumpData');}} 
												className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
											<p style={{marginTop:'10px',marginLeft:'40px', fontSize : '10px'}}
												// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
												className="wellInfo" >
												{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
												<b style={{fontSize : '12px'}}>{langdata && langdata.CurrentlyViewing ? langdata.CurrentlyViewing : 'Currently Viewing'}</b>
												<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {fracture && fracture.pressuregraphData && fracture.pressuregraphData.channel_name} <br />
											</p>
											<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
												<Plot
													data={pressureData}
													layout={pressureLayout}
													config={{ 
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],

													}}
													style={{ width: '100%', height: '800px' }}
												/>
											</Col>
										</Col>
										:
										graph =='fracturelocationMap' ? 
											<>
												<Col span={24} style={{ display : 'flex', justifyContent : 'flex-end', marginRight : '10px', backgroundColor : '#ffffff', padding : '10px', marginLeft : '10px', marginTop : '10px'}}> 
													<Space>&nbsp;</Space>
													<Tooltip placement="bottom" title={langdata && langdata.Filterbychannel ? langdata.Filterbychannel : 'Filter by channel'}>
														<Space>
															<Select
																style={{ width: '200px'}}
																showSearch
																filterOption={filterOption}
																placeholder={langdata && langdata.SelectChannel ? langdata.SelectChannel : 'Select Channel'}
																optionFilterProp="children"
																defaultValuevalue={null}
																value={channelId ?  channelId : tempchannelId}													
																options={channellist}
																onSelect={(value)=>handleSetChannel(value)}
															/>
														</Space>
													</Tooltip>
													<Space>&nbsp;</Space>
													<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}>
														<RangePicker 
															showTime ={{hideDisabledOptions: true, format : 'YYYY-MM-DD HH'}} 
															style={{ width: '350px', marginLeft : '10px'}}
															defaultValue={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
															format={settingsdateformat + ' ' + settingstimeformat}
															placeholder={[langdata && langdata.Startdateandtime ? langdata.Startdateandtime : 'Start date and time', langdata && langdata.Enddateandtime ? langdata.Enddateandtime : 'End date and time']}
															disabledDate={disabledDate}
															disabledTime={disabledRangeTime}
															onChange={handleSetDate}
															allowClear={true} />
													</Tooltip>
													<Space>&nbsp;</Space>
													<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectfracture ? langdata.Pleaseselectfracture :'Please select fracture'}>
														<Select
															style={{ width: '180px', marginLeft : '10px'}}
															showSearch
															filterOption={filterOption}
															placeholder={langdata && langdata.Selectfracture ? langdata.Selectfracture : 'Select fracture'}
															optionFilterProp="children"
															defaultValuevalue={null}
															value={fractureId ? fractureId : tempFractureid}
															options={fracturelist}
															onSelect={(value)=>handleSetFracture(value)}
														/>
													</Tooltip>
												</Col>
												<Col span={24} style={{padding:'10px'}}>
													<button
														style={{margin : '10px'}}
														onClick={()=>{handlefullSize(fullsize === false ? true:false,'fracturelocationMap');}} 
														className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
													<Plot
														data={dotData}
														layout={dotLayout}
														config={{ 
															responsive: true,
															// displayModeBar: true,
															displaylogo : false,
															modeBarButtonsToRemove: [
																'pan2d',
																'zoom2d',
																'select2d',
																'zoomIn2d',
																'zoomOut2d',
																'autoScale2d',
																'resetScale2d',
																'hoverClosestCartesian',
																'hoverCompareCartesian',
																'zoom3d',
																'pan3d',
																'resetViews3d',
																'hoverClosest3d',
																'hoverCompare3d',
																'orbitRotation',
																'tableRotation',
																'resetCameraDefault3d',
																'resetCameraLastSave3d',
																'lasso2d'
															],

														}}
														style={{ width: '100%', height: '800px' }}
													/>
												</Col>
											</>
											:
											null
				}

				{/* </Row> */}

				<Modal
					title={langdata && langdata.GraphConfigurations ? langdata.GraphConfigurations: 'Graph Configurations' }
					open={isModalOpen} 
					onOk={handleOk} 
					onCancel={handleCancel}
					maskClosable={false}
					footer={[
						<>
							<Row style={{
								margin: '10px',
								paddingLeft: '10px',
								paddingRight: '10px',
								background: '#FFFFFF',
								alignItems: 'center',
								marginTop : '10px'
							}}
							>
								<Col span={8}>
									{/* <Row>
										<Space>
											<Button onClick={handleReset}>RESET</Button>
										</Space>
									</Row> */}
								</Col>
								<Col span={16}>
									<Row justify="end">
										<Col>
											{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>User Type:</Text> */}
											<Space>
												<Button style={{ margin : '5px'}} onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
											</Space>
											<Space>&nbsp;</Space>
											<Space>
												<Button onClick={formik.handleSubmit} type="primary" style={{ margin : '5px'}} >{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
											</Space>
										</Col>
									</Row>
								</Col>
							</Row>
						</>
					]}
					width={900}
				>		
					<Divider/>
					<Row gutter={24}>
						<Col span={23}>
							{dasEnabled == true ?
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DAS ? langdata.DAS:'DAS'}</b></Typography>
								: 
								dtsEnabled == true ?
									<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS:'DTS'}</b></Typography>
									:
									<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.Pressure ? langdata.Pressure:'Pressure'}</b></Typography>
							}
						</Col>
						<Col  justify="end"  span={1}>
							<Tooltip placement="bottom" title={langdata && langdata.Reset ? langdata.Reset : 'Reset'}>
								<Avatar size="small" style={{ backgroundColor : '#1677FF'}}>							
									<ReloadOutlined onClick={handleReset} />	
								</Avatar>
							</Tooltip>
						</Col>
					</Row>
					{dasEnabled == true ? 
						<>
							<Row gutter={24} >
								<Col span={24}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.AmplitudeV ? langdata.AmplitudeV: 'Amplitude (V)'}&nbsp;&nbsp;&nbsp;
										<Tooltip placement="bottom" title={langdata && langdata.Enteramplituderange ? langdata.Enteramplituderange:'Enter amplitude range'}>
											<Input 
												type='number'
												style={{width : '15%', height  :'25px'}}
												disabled = {formik.values.ampThresholdsOverride == true ? false : true}
												value={formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold}
												onChange={(e) => {formik.setFieldValue('minAmpThreshold', e.target.value);
												}}
											/>
											{' '} <span > -  </span>{' '}
											<Input  
												type='number'
												style={{width : '15%', height  :'25px'}}
												disabled = {formik.values.ampThresholdsOverride == true ? false : true}
												value={formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold}
												onChange={(e) => {formik.setFieldValue('maxAmpThreshold', e.target.value);
												}}
											/>
										</Tooltip>
									</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minAmpThreshold', value[0]);formik.setFieldValue('maxAmpThreshold', value[1]);}} 
										marks={ampMarks} 
										disabled = {formik.values.ampThresholdsOverride == true ? false : true}
										value={[formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold, formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold]}/>
								</Col>
								{/* <Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.SNRatio ? langdata.SNRatio: 'S/N Ratio'}</Typography>
									<Slider 
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minAmpSNThresholds', value[0]);formik.setFieldValue('maxAmpSNThresholds', value[1]);}} 
										marks={dasSNRatioMarks}
										disabled = {formik.values.ampSNThresholdsOverride == true ? false : true}
										value={[formik.values.ampSNThresholdsOverride == true ? formik.values.minAmpSNThresholds : formik.values.defaultminAmpSNThresholds, formik.values.ampSNThresholdsOverride == true ? formik.values.maxAmpSNThresholds : formik.values.defaultmaxAmpSNThresholds]}/>
								</Col> */}
								{/* <Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.CutoffFrequencyHz ? langdata.CutoffFrequencyHz: 'Cutoff Frequency (Hz)'}</Typography>
									<Slider 
										range step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minAmpFreqThresholds', value[0]);formik.setFieldValue('maxAmpFreqThresholds', value[1]);}} 
										marks={dasFrequencyMarks}
										disabled = {formik.values.ampFreqThresholdsOverride == true ? false : true}
										value={[formik.values.ampFreqThresholdsOverride == true ? formik.values.minAmpFreqThresholds : formik.values.defaultminAmpFreqThresholds, formik.values.ampFreqThresholdsOverride == true ? formik.values.maxAmpFreqThresholds : formik.values.defaultmaxAmpFreqThresholds]}/>
								</Col> */}
								<Col span={24}>
									<Row>
										<Space>
											<Tooltip  placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small" 
													onClick={(value) => {formik.setFieldValue('ampThresholdsOverride', value);}}
													checked={formik.values.ampThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
								{/* <Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small" 
													onClick={(value) => {formik.setFieldValue('ampSNThresholdsOverride', value);}}
													checked={formik.values.ampSNThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col> */}
								{/* <Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small" 
													onClick={(value) => {formik.setFieldValue('ampFreqThresholdsOverride', value);}}
													checked={formik.values.ampFreqThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col> */}
							</Row>
							<Divider/>
						</>
						: null }
					{dtsEnabled == true ?
						<>
							{dasEnabled == true ?
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS:'DTS'}</b></Typography>
								: null }
							<Row gutter={24} >
								<Col span={24}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.TemperatureC ? langdata.TemperatureC: 'Temperature (°C)'}&nbsp; &nbsp;
										<Tooltip placement="bottom" title={langdata && langdata.Entertemperaturerange ? langdata.Entertemperaturerange:'Enter temperature range'}>
											<Input 
												type='number'
												style={{width : '15%', height  :'25px'}}
												disabled = {formik.values.tempThresholdsOverride == true ? false : true}
												value={formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold}
												onChange={(e) => {formik.setFieldValue('minTempThreshold', e.target.value);
												}}
											/>
											{' '} <span > -  </span>{' '}
											<Input  
												type='number'
												style={{width : '15%', height  :'25px'}}
												disabled = {formik.values.tempThresholdsOverride == true ? false : true}
												value={formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold}
												onChange={(e) => {formik.setFieldValue('maxTempThreshold', e.target.value);
												}}
											/>
										</Tooltip>
									</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minTempThreshold', value[0]);formik.setFieldValue('maxTempThreshold', value[1]);}} 
										marks={tempMarks}
										disabled = {formik.values.tempThresholdsOverride == true ? false : true}
										value={[formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold, formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold]}
									/>
								</Col>
								{/* <Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.SNRatio ? langdata.SNRatio:  'S/N Ratio'}</Typography>
									<Slider 
										range 
										step={10} 
										max={20000}  
										onChange={(value) => {formik.setFieldValue('minTempSNThresholds', value[0]);formik.setFieldValue('maxTempSNThresholds', value[1]);}} 
										marks={dtsSNRatioMarks}
										disabled = {formik.values.tempSNThresholdsOverride == true ? false : true}
										value={[formik.values.tempSNThresholdsOverride == true ? formik.values.minTempSNThresholds : formik.values.defaultminTempSNThresholds, formik.values.tempSNThresholdsOverride == true ? formik.values.maxTempSNThresholds : formik.values.defaultmaxTempSNThresholds]}
									/>
								</Col> */}
								{/* <Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.CutoffFrequencyHz ? langdata.CutoffFrequencyHz: 'Cutoff Frequency (Hz)'}</Typography>
									<Slider 
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minTempFreqThresholds', value[0]);formik.setFieldValue('maxTempFreqThresholds', value[1]);}} 
										marks={dtsFrequencyMarks}
										disabled = {formik.values.tempFreqThresholdsOverride == true ? false : true}
										value={[formik.values.tempFreqThresholdsOverride == true ? formik.values.minTempFreqThresholds : formik.values.defaultminTempFreqThresholds, formik.values.tempFreqThresholdsOverride == true ? formik.values.maxTempFreqThresholds : formik.values.defaultmaxTempFreqThresholds]}
									/>
								</Col> */}
								<Col span={24}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch size="small"
													onClick={(value) => {formik.setFieldValue('tempThresholdsOverride', value);}}
													checked={formik.values.tempThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
								{/* <Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small"
													onClick={(value) => {formik.setFieldValue('tempSNThresholdsOverride', value);}}
													checked={formik.values.tempSNThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col> */}
								{/* <Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small"
													onClick={(value) => {formik.setFieldValue('tempFreqThresholdsOverride', value);}}
													checked={formik.values.tempFreqThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col> */}
							</Row>
							<Divider/>
						</>
						: null }
					{pressEnabled == true ? 
						<>
							{dasEnabled == true || dtsEnabled == true ?
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.Pressure ? langdata.Pressure:'Pressure'}</b></Typography>
								: 
								null
							}
							<Row gutter={24} >
								<Col span={24}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.Pressure ? langdata.Pressure: 'Pressure'}&nbsp;&nbsp;&nbsp;
										<Tooltip placement="bottom" title={langdata && langdata.Enterpressurerange ? langdata.Enterpressurerange:'Enter pressure range'}>
											<Input 
												type='number'
												style={{width : '15%', height  :'25px'}}
												disabled = {formik.values.pressThresholdsOverride == true ? false : true}
												value={formik.values.pressThresholdsOverride == true ? formik.values.minPressureThreshold : formik.values.defaultminPressureThreshold}
												onChange={(e) => {formik.setFieldValue('minPressureThreshold', e.target.value);
												}}
											/>
											{' '} <span > -  </span>{' '}
											<Input  
												type='number'
												style={{width : '15%', height  :'25px'}}
												disabled = {formik.values.pressThresholdsOverride == true ? false : true}
												value={formik.values.pressThresholdsOverride == true ? formik.values.maxPressureThreshold : formik.values.defaultmaxPressureThreshold}
												onChange={(e) => {formik.setFieldValue('maxPressureThreshold', e.target.value);
												}}
											/>
										</Tooltip>
									</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minPressureThreshold', value[0]);formik.setFieldValue('maxPressureThreshold', value[1]);}} 
										marks={pressureMarks}
										disabled = {formik.values.pressThresholdsOverride == true ? false : true}
										value={[formik.values.pressThresholdsOverride == true ? formik.values.minPressureThreshold : formik.values.defaultminPressureThreshold, formik.values.pressThresholdsOverride == true ? formik.values.maxPressureThreshold : formik.values.defaultmaxPressureThreshold]}
									/>
								</Col>					
								<Col span={24}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch size="small"
													onClick={(value) => {formik.setFieldValue('pressThresholdsOverride', value);}}
													checked={formik.values.pressThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>								
							</Row>
							<Divider/>
						</>
						: null 
					}
					<Row gutter={24} >
						<Col span={24}>
							<Typography style={{ marginTop : '15px'}}>{langdata && langdata.Depth ? langdata.Depth +' (m)' : 'Depth (m)'}&nbsp;&nbsp;&nbsp;
								<Tooltip placement="bottom" title={langdata && langdata.Enterdepthrange ? langdata.Enterdepthrange:'Enter depth range'}>
									<Input 
										type='number' 
										style={{width : '15%', height  :'25px'}}
										value={drawerDepthFrom ? drawerDepthFrom : depthRangeFrom} 
										max={zonefrom} 
										min={zoneto} 
										placeholder={langdata && langdata.DepthRangeFrom ? langdata.DepthRangeFrom: 'Depth Range From'} 
										onChange={handleInputDepthRangefrom} 
									/>
									{' '} <span > -  </span>{' '}
									<Input 
										type='number' 
										style={{width : '15%', height  :'25px'}}
										value={drawerDepthTo ? drawerDepthTo : depthRangeTo} 
										max={zonefrom} min={zoneto} 
										placeholder={langdata && langdata.DepthRangeTo ? langdata.DepthRangeTo: 'Depth Range To'} 
										onChange={handleInputDepthRangeTo}
									/>
								</Tooltip>
							</Typography>
							<Slider style={{marginTop:'20px'}} range max={wellFrom ? wellFrom : tempendDepth} onChange={handleDepthChange} marks={depthMarks} value={[drawerDepthFrom ? drawerDepthFrom : depthRangeFrom, drawerDepthTo ? drawerDepthTo : depthRangeTo]} />
						</Col>
					</Row>
				</Modal>

				<Modal
					title={langdata && langdata.SaveforTraining ? langdata.SaveforTraining : 'Save for Training'}
					open={isDTSDataModalOpen} 
					onOk={handleOk} 
					onCancel={handleCancel}
					maskClosable={false}
					footer={[
						<Space key ='options'>
							<Button style={{ margin : '5px'}} onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
							<Button  type="primary" onClick={handleNavigateSimulation} style={{ margin : '5px'}} >{langdata && langdata.NEXT ? langdata.NEXT : 'NEXT'}</Button>
						</Space>
					]}
					// width={600}
				>
					<Row gutter={24}>
						<Col span={24}>
							{/* <Form.Item label={langdata && langdata.Time ? langdata.Time :  'Time'} >
									<Input value={dtsClickableData && dtsClickableData.x} disabled/>
								</Form.Item> */}
							<b>Time : </b>{dtsClickableData && dtsClickableData.x}
						</Col>
						<Col span={24} style={{ marginTop : '5px'}}>
							{/* <Form.Item label={langdata && langdata.Depth ? langdata.Depth :  'Depth'} >
									<Input value={dtsClickableData && dtsClickableData.y} disabled/>
								</Form.Item> */}
							<b>Depth : </b>{dtsClickableData && dtsClickableData.y}
						</Col>
						<Col span={24} style={{ marginTop : '5px'}}>
							{/* <Form.Item label={langdata && langdata.Temperature ? langdata.Temperature :  'Temperature' + ':'} >
									<Input value={dtsClickableData && dtsClickableData.z} disabled/>
								</Form.Item> */}
							<b>Temperature : </b>{dtsClickableData && dtsClickableData.z}
						</Col>
						<Col span={24} style={{ marginTop : '10px'}}>
							<Form.Item label={langdata && langdata.Label ? langdata.Label :  'Label'} >
								<Select Item
									style={{ width: '360px'}}
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.SelectEvent ? langdata.SelectEvent :'Select Event'}
									optionFilterProp="children"
											
									// defaultValue={['all']}
									options={allevents}
									onSelect={(value, options) => {handleSelectEvent(options);}}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Modal>
			</Content>

			<Spin fullscreen spinning={false} />

			{/* Add Vulnerable Point Details Modal Starts */}
			
			{/* Delete User Modal Ends */}
		</>
	);
}