import React, { useEffect, useReducer } from 'react';

import { Row, Col, Typography, Space, Table, Empty, Spin, message} from 'antd';
import { Content } from 'antd/es/layout/layout';

import { RedoOutlined } from '@ant-design/icons';
import axios from 'axios';

import modelReducer from './reducer';
import { MODEL_CLASSIFICATIONS, MODEL_CLASSIFICATIONS_ERROR, MODEL_CLASSIFICATIONS_SUCCESS } from './constants';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;


export default function ModelClassification() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const initalState = { loading: false, error: false, modelClassificationDate : []};
	const [data, dispatch] = useReducer(modelReducer, initalState);
	const navigate = useNavigate();
	
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	useEffect(() => {

		if(!authtoken){
			navigate('/');
		}

		dispatch({ type : MODEL_CLASSIFICATIONS });

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/modelclassifications`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization':  `Bearer ${authtoken}`
			},
		};
    
		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					dispatch({ type : MODEL_CLASSIFICATIONS_SUCCESS, payload : response.data});
				} else {
					dispatch({ type : MODEL_CLASSIFICATIONS_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				dispatch({ type : MODEL_CLASSIFICATIONS_ERROR});
			});
    
	},[authtoken]);

	const columns = [
		{
			title: 'Model Level',
			dataIndex: 'modelLevel',
			key: 'modelLevel'
		},
		{
			title: 'Parent Model Level',
			dataIndex: 'parentmodelLevel',
			key: 'parentmodelLevel',
		},
	];


	return (

		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center',
				}}
				>

					<Col span={5}>
						<Row>
							<Space>
								<RedoOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> Model Classifications</Title>
						</Row>
					</Col>
					
				</Row>

				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data && data.modelClassificationDate.length > 0 ? (
							<Table
								scroll={{
									x: 900,
								}} 
								columns={columns} 
								dataSource={data && data.modelClassificationDate} 
							/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>
				
			</Content>

			<Spin fullscreen spinning={data.loading} />
      
			{/* Add Fiber Management Starts */}
		</>
	);
}