import React, { useState, useEffect, useReducer } from 'react';
import { useAuth } from '../Context';

import { Empty, Row, Col, Select, Typography, Space, Table, Spin, Button, Form, Modal, Input, Checkbox, Tooltip, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PlusOutlined, EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';

import { CHAINAGES_LIST, CHAINAGES_LIST_SUCCESS, CHAINAGES_LIST_ERROR } from './constants';
import { AVAILABLE_CHAINAGES_LIST, AVAILABLE_CHAINAGES_LIST_SUCCESS, AVAILABLE_CHAINAGES_LIST_ERROR } from './constants';
import { SNOOZED_POINTS_LIST, SNOOZED_POINTS_LIST_SUCCESS, SNOOZED_POINTS_LIST_ERROR } from './constants';
import { SNOOZED_POINT_DETAILS, SNOOZED_POINT_DETAILS_SUCCESS, SNOOZED_POINT_DETAILS_ERROR } from './constants';
import { SAVE_SNOOZED_POINT, SAVE_SNOOZED_POINT_SUCCESS, SAVE_SNOOZED_POINT_ERROR } from './constants';
import { UPDATE_SNOOZED_POINT, UPDATE_SNOOZED_POINT_SUCCESS, UPDATE_SNOOZED_POINT_ERROR } from './constants';
import { DELETE_SNOOZED_POINT, DELETE_SNOOZED_POINT_SUCCESS, DELETE_SNOOZED_POINT_ERROR } from './constants';
import { FileProtectOutlined, InfoOutlined } from '@ant-design/icons';

import snoozedPointsReducer from './reducer.js';

import { useFormik } from 'formik';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;

export default function SnoozedPoints() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const [regionId, setRegionId] = useState(null);
	const [rouMarker, setRouMarker] = useState(null);
	const [allocationId, setAllocationId] = useState(null);
	const [startCh, setStartCh] = useState(null);
	const [endCh, setEndCh] = useState(null);
	
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
	const initalState = {chainages: [], availableChainages: [], snoozedPoints: [], pagination: { total: 0, limit: 10, skip: 0, current:1 }, details: null, loading: false, error: false };
	const [data, dispatch] = useReducer(snoozedPointsReducer, initalState);

	const [showAddSnoozedPointModal, setShowAddSnoozedPointModal] = useState(false);
	const [showSnoozedPointModal, setShowSnoozedPointModal] = useState(false);
	const [showDeleteSnoozedPointModal, setShowDeleteSnoozedPointModal] = useState(false);
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const navigate = useNavigate();

	let regions = [ { value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'} ];
	let roumarkers = [
		{ value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'}, 
		{ value: 'CH', label: langdata && langdata.CH ? langdata.CH : 'CH'},
		{ value: 'KMP', label: langdata && langdata.KMP ? langdata.KMP : 'KMP'},
		{ value: 'TP', label: langdata && langdata.TP ? langdata.TP : 'TP'},
		{ value: 'TLP', label: langdata && langdata.TLP ? langdata.TLP : 'TLP'},
		{ value: 'WB', label: langdata && langdata.WB ? langdata.WB : 'WB'}
	];

	let chainages = [];
	let availableChainages = [];

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	let columns = [];

	{ permission === 'superadmin' || permission === 'admin' ? 
		columns = [
			{
				title: langdata && langdata.PipelineChainage ? langdata.PipelineChainage : 'Pipeline Chainage',
				dataIndex: 'pipelineChainage',
				key: 'pipelineChainage',
			},
			{
				title: langdata && langdata.ODMeter ? langdata.ODMeter : 'OD Meter',
				dataIndex: 'ODMeter',
				key: 'ODMeter',
			},
			{
				title: langdata && langdata.ROUMarker ? langdata.ROUMarker : 'ROU Marker',
				dataIndex: 'rouMarker',
				key: 'rouMarker'
			},
			{
				title: langdata && langdata.Latitude ? langdata.Latitude : 'Latitude',
				dataIndex: 'latitude',
				key: 'latitude',
				render: (text) => (
					<text>{text.toFixed(5)}</text>
				)
			},
			{
				title: langdata && langdata.Longitude ? langdata.Longitude : 'Longitude',
				dataIndex: 'longitude',
				key: 'longitude',
				render: (text) => (
					<text>{text.toFixed(5)}</text>
				)
			},
			{
				title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
				key: 'action',
				render: () => (
					permission === 'superadmin' || permission === 'admin' ? (
						<Space size="middle">
							<a title="View Snoozed Point" onClick={openSnoozedPointModal}><EditOutlined /></a>
							<a title="Delete Snoozed Point" onClick={openDeleteSnoozedPointModal}><DeleteOutlined /></a>
						</Space>
					) : (
						<Space size="middle">
							<a title="View Snoozed Point" onClick={openSnoozedPointModal}><EyeOutlined /></a>
						</Space>
					)
				)
			}
		] :
		columns = [
			{
				title: langdata && langdata.PipelineChainage ? langdata.PipelineChainage : 'Pipeline Chainage',
				dataIndex: 'pipelineChainage',
				key: 'pipelineChainage',
			},
			{
				title: langdata && langdata.ODMeter ? langdata.ODMeter : 'OD Meter',
				dataIndex: 'ODMeter',
				key: 'ODMeter',
			},
			{
				title: langdata && langdata.ROUMarker ? langdata.ROUMarker : 'ROU Marker',
				dataIndex: 'rouMarker',
				key: 'rouMarker'
			},
			{
				title: langdata && langdata.Latitude ? langdata.Latitude : 'Latitude',
				dataIndex: 'latitude',
				key: 'latitude',
				render: (text) => (
					<text>{text.toFixed(5)}</text>
				)
			},
			{
				title: langdata && langdata.Longitude ? langdata.Longitude : 'Longitude',
				dataIndex: 'longitude',
				key: 'longitude',
				render: (text) => (
					<text>{text.toFixed(5)}</text>
				)
			},
		];
	}
  
	const openAddSnoozedPointModal = () => {
		setShowAddSnoozedPointModal(true);
	};

	const closeAddSnoozedPointModal = () => {
		setShowAddSnoozedPointModal(false);
	};

	const openSnoozedPointModal = () => {
		setShowSnoozedPointModal(true);
	};

	const closeSnoozedPointModal = () => {
		setShowSnoozedPointModal(false);
	};

	const openDeleteSnoozedPointModal = () => {
		setShowDeleteSnoozedPointModal(true);
	};

	const closeDeleteSnoozedPointModal = () => {
		setShowDeleteSnoozedPointModal(false);
	};

	if (admin_roles.includes(permission)) {
		regiondata && regiondata.map((region) => {
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region) => {
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	useEffect(() => {
		if(featuresModule.snoozedPointsEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 93);
	}, []);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		if (admin_roles.includes(permission)) {
			getRegion(authtoken);
		} else{
			getUserAllocation(authtoken);
		}

		loadChainages();
		loadAvailableChainages();
		loadSnoozedPoints(1);
	}, [allocationId, regionId, rouMarker]);

	data && data.chainages.map((chainage) => {
		return chainages.push({ value: chainage.id, label: chainage.pipelineChainage.toString() });
	});

	data && data.availableChainages.map((chainage) => {
		return availableChainages.push({ value: chainage.id, label: chainage.pipelineChainage.toString() });
	});

	const handleSetRegion = (e, options) => {
		localStorage.setItem(`${dashboardtype}_REGION_ID`, e);
		
		if (admin_roles.includes(permission)) {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
			setStartCh(options.pipelineConfigFrom);
			setEndCh(options.pipelineConfigTo);
		}
	};

	const handleSetROUMarker = (e) => {
		setRouMarker(e);
	};

	const loadChainages = () => {
		const limit = 100000;
		const skip = 0;

		let filterByregionIdPart = '';
		let filterVulnerablePart = '';
		let pipelineChainageparams = `&pipelineChainage[$gte]=${startCh}&pipelineChainage[$lte]=${endCh}`;

		if (regionId && regionId !== null) {
			filterByregionIdPart = `&regioninfoId=${regionId}`;
		} else {
			filterByregionIdPart = '';
		}

		let url = `${api}/pipelineconfig?$limit=${limit}&$skip=${skip}${filterByregionIdPart}${filterVulnerablePart}${ endCh && endCh || endCh == 0 ? pipelineChainageparams:''}&$sort[pipelineChainage]=+1`;

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		dispatch( { type: CHAINAGES_LIST });

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: CHAINAGES_LIST_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: CHAINAGES_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: CHAINAGES_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadAvailableChainages = () => {
		const limit = 100000;
		const skip = 0;

		let filterByregionIdPart = '';
		let filterVulnerablePart = '&isSnoozed=false';
		let pipelineChainageparams = `&pipelineChainage[$gte]=${startCh}&pipelineChainage[$lte]=${endCh}`;

		if (regionId && regionId !== null) {
			filterByregionIdPart = `&regioninfoId=${regionId}`;
		} else {
			filterByregionIdPart = '';
		}

		let url = `${api}/pipelineconfig?$limit=${limit}&$skip=${skip}${filterByregionIdPart}${filterVulnerablePart}${ endCh && endCh || endCh == 0 ? pipelineChainageparams:''}&$sort[pipelineChainage]=+1`;

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		dispatch( { type: AVAILABLE_CHAINAGES_LIST });

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: AVAILABLE_CHAINAGES_LIST_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: AVAILABLE_CHAINAGES_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: AVAILABLE_CHAINAGES_LIST_ERROR });
		});
	};
  
	const loadSnoozedPoints = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		let filterByregionIdPart = '';
		let filterByROUMarkerPart = '';
		let filterSnoozedPart = '&isSnoozed=true';
		let pipelineChainageparams = `&pipelineChainage[$gte]=${startCh}&pipelineChainage[$lte]=${endCh}`;

		if (regionId && regionId !== null) {
			filterByregionIdPart = `&regioninfoId=${regionId}`;
		} else {
			filterByregionIdPart = '';
		}

		if (rouMarker && rouMarker !== null) {
			filterByROUMarkerPart = `&rouMarker=${rouMarker}`;
		} else {
			filterByROUMarkerPart = '';
		}

		let url = `${api}/pipelineconfig?$limit=${_pagination.limit}&$skip=${_pagination.skip}${filterSnoozedPart}${filterByregionIdPart}${filterByROUMarkerPart}${ endCh && endCh || endCh == 0 ? pipelineChainageparams:''}&$sort[pipelineChainage]=+1`;

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};
    
		dispatch( { type: SNOOZED_POINTS_LIST });

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: SNOOZED_POINTS_LIST_SUCCESS, payload: response.data, page:pagination.current, pageSize : pagination.pageSize  });
			} else {
				dispatch( { type: SNOOZED_POINTS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: SNOOZED_POINTS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadSnoozedPointDetails = ((id) => {
		let details = null;

		dispatch({ type: SNOOZED_POINT_DETAILS });
    
		if (data && data.snoozedPoints) {
			data.snoozedPoints && data.snoozedPoints.map((item) => {
				if (item.id === id) {
					details = item;
				}
				return true;
			});
      
			dispatch({ type: SNOOZED_POINT_DETAILS_SUCCESS, payload: details });
		} else {
			dispatch({ type: SNOOZED_POINT_DETAILS_ERROR });
		}
	});

	const saveSnoozedPointFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			chainage: null
		},
		onSubmit: (values) => {
			const chainage = values.chainage ? values.chainage : null;
      
			if (!chainage || chainage === null) {
				Modal.warning({ title: langdata && langdata.Pleaseselectavalidchainage ? langdata.Pleaseselectavalidchainage : 'Please select a valid chainage.' });
			} else {
				let config = {
					method: 'patch',
					maxBodyLength: Infinity,
					url: `${api}/pipelineconfig/${chainage}`,
					timeout: timeout,
					headers: {
						accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization:`Bearer ${authtoken}`
					},
					data: {
						isSnoozed: true
					}
				};
  
				dispatch( { type: SAVE_SNOOZED_POINT });
        
				axios
					.request(config)
					.then((response) => {
						if (response.status === 200 || response.status === 201) {
							dispatch({type: SAVE_SNOOZED_POINT_SUCCESS, payload:response});
							Modal.success({ title: langdata && langdata.Snoozedpointsavedsuccessfully ? langdata.Snoozedpointsavedsuccessfully : 'Snoozed point saved successfully.' });
							closeAddSnoozedPointModal();
							loadSnoozedPoints({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
							loadAvailableChainages();
						} else {
							dispatch({ type: SAVE_SNOOZED_POINT_ERROR });
							Modal.warning({ title: langdata && langdata.Unabletosavethesnoozedpoint ? langdata.Unabletosavethesnoozedpoint : 'Unable to save the snoozed point.' });  
						}
					})
					.catch((error) => {
						console.log(error);
						dispatch({ type: SAVE_SNOOZED_POINT_ERROR });
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
							});
						}
					});
			}
		}
	});

	const updateSnoozedPointFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			id: data.details ? data.details.id : null,
			pipelineChainage: data.details ? data.details.pipelineChainage : null,
			rouMarker: data.details ? data.details.rouMarker : '',
			latitude: data.details ? data.details.latitude : null,
			longitude: data.details ? data.details.longitude : null,
			isSnoozed: data.details ? data.details.isSnoozed : false,
		},
		onSubmit: (values) => {
			const id = data.details ? data.details.id : 0;
			const isSnoozed = values.isSnoozed ? values.isSnoozed : false;
      
			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/pipelineconfig/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					isSnoozed: isSnoozed
				}
			};

			dispatch( { type: UPDATE_SNOOZED_POINT });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: UPDATE_SNOOZED_POINT_SUCCESS, payload: response});
						Modal.success({ title: langdata && langdata.Snoozedpointdetailsupdatedsuccessfully ? langdata.Snoozedpointdetailsupdatedsuccessfully : 'Snoozed point details updated successfully.' });
						closeSnoozedPointModal();
						loadSnoozedPoints({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
						loadAvailableChainages();
					} else {
						dispatch({ type: UPDATE_SNOOZED_POINT_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletoupdatethesnoozedpointdetails ? langdata.Unabletoupdatethesnoozedpointdetails : 'Unable to update the snoozed point details.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: UPDATE_SNOOZED_POINT_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}  
				});
		}
	});

	const deleteSnoozedPoint = () => {
		let id = data.details ? data.details.id : 0;
    
		if (!id || parseInt(id) <= 0) {
			Modal.warning({ title: langdata && langdata.TheidisinvalidPleasetryagain ? langdata.TheidisinvalidPleasetryagain : 'The id is invalid. Please try again.' });
			return;
		} else {
			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/pipelineconfig/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					isSnoozed: false
				}
			};

			dispatch( { type: DELETE_SNOOZED_POINT });
      
			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: DELETE_SNOOZED_POINT_SUCCESS, payload:response});
						Modal.success({ title: langdata && langdata.Snoozedpointdeletedsuccessfully ? langdata.Snoozedpointdeletedsuccessfully : 'Snoozed point deleted successfully.' });
						closeDeleteSnoozedPointModal();
						loadSnoozedPoints({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
						loadAvailableChainages();
					} else {
						dispatch({ type: DELETE_SNOOZED_POINT_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletodeletethesnoozedpoint ? langdata.Unabletodeletethesnoozedpoint : 'Unable to delete the snoozed point.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: DELETE_SNOOZED_POINT_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} 
				});
		}
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p> {langdata && langdata.SnoozeContent1 ? langdata.SnoozeContent1 : 'The Snooze feature offers users the ability to temporarily disable alarm generation for specific chainages or locations. This feature empowers users to manage alarms effectively  by avoiding repeated unnecessary alarms generated in a known location.' }</p>
		</Card>
	);

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={8}>
						<Row>
							<Space>
								<FileProtectOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.SnoozedPoints ? langdata.SnoozedPoints : 'Snoozed Points'}</Title>
						</Row>
					</Col>
					
					<Col span={16}>
						<Row justify="end">
							<Col>
								<Tooltip placement="bottom" title= {langdata && langdata.Filterbyregion ? langdata.Filterbyregion : 'Filter by region'}>
									<Space>
										<Select
											style={{ width: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
											optionFilterProp="children"
											defaultValue={null}
											options={regions}
											onSelect={(e, options)=>handleSetRegion(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.FilterbyROUmarker ? langdata.FilterbyROUmarker : 'Filter by ROU marker'}>
									<Space>
										<Select
											style={{ width: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectROUmarker ? langdata.SelectROUmarker : 'Select ROU marker'}
											optionFilterProp="children"
											defaultValue={null}
											options={roumarkers}
											onSelect={(e, options)=>handleSetROUMarker(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									{ permission === 'superadmin' || permission === 'admin' ? <Button type="primary" onClick={openAddSnoozedPointModal} style={{margin:'5px'}} ><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button> : null }
								</Space>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.SnoozedPoints ? langdata.SnoozedPoints : 'Snoozed Points'} content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data.snoozedPoints && data.snoozedPoints.length > 0 ? (
							<Table 
								scroll={{
									x: 900,
								}}
								columns={columns} 
								dataSource={data.snoozedPoints} 
								pagination = {data.snoozedPoints.length > 0 ? data.pagination : 0}
								onChange = { loadSnoozedPoints }
								onRow = {(record) => {
									return {
										onClick: () => { 
											loadSnoozedPointDetails(record.id);
										}
									};
								}} 
							/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>
			</Content>

			<Spin fullscreen spinning={data.loading} />

			{/* Add Snoozed Point Details Modal Starts */}
			<Modal
				title={langdata && langdata.AddSnoozedPoint ? langdata.AddSnoozedPoint : 'Add Snoozed Point'}
				centered
				destroyOnClose={true}
				open={showAddSnoozedPointModal}
				onOk={{closeAddSnoozedPointModal}}
				onCancel={closeAddSnoozedPointModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeAddSnoozedPointModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{ permission === 'superadmin' || permission === 'admin' ? <Button key="submit" type="primary" onClick={saveSnoozedPointFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
					</Space>
				]}
				width={500}
			>
				<Form layout="vertical" onSubmit={saveSnoozedPointFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Chainage ? langdata.Chainage : 'Chainage'} required>
								<Select
									placeholder= {langdata && langdata.SelectChainage ? langdata.SelectChainage : 'Select Chainage'}
									optionFilterProp="children"
									options={chainages}
									onChange={(value) => saveSnoozedPointFormik.setFieldValue('chainage', value)}
									showSearch
									filterOption={filterOption}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* Add Snoozed Point Details Modal Ends */}

			{/* Vulnerable Point Details Modal Starts */}
			<Modal
				title= {langdata && langdata.SnoozedPointDetails ? langdata.SnoozedPointDetails : 'Snoozed Point Details'}
				centered
				destroyOnClose={true}
				open={showSnoozedPointModal}
				onOk={{closeSnoozedPointModal}}
				onCancel={closeSnoozedPointModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeSnoozedPointModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{ permission === 'superadmin' || permission === 'admin' ? <Button key="submit" type="primary" onClick={updateSnoozedPointFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" onSubmit={updateSnoozedPointFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Chainage ? langdata.Chainage : 'Chainage'} required>
								<Input name="pipelineChainage" placeholder={langdata && langdata.Chainage ? langdata.Chainage : 'Chainage'} defaultValue={ updateSnoozedPointFormik.values.pipelineChainage } value={ updateSnoozedPointFormik.values.pipelineChainage } onChange={updateSnoozedPointFormik.handleChange} disabled />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ROUMarker ? langdata.ROUMarker : 'ROU Marker'} required>
								<Input name="rouMarker" placeholder={langdata && langdata.ROUMarker ? langdata.ROUMarker : 'ROU Marker'} defaultValue={ updateSnoozedPointFormik.values.rouMarker } value={ updateSnoozedPointFormik.values.rouMarker } onChange={updateSnoozedPointFormik.handleChange} disabled />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label= {langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'} required>
								<Input name="latitude" placeholder={langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'} defaultValue={ updateSnoozedPointFormik.values.latitude } value={ updateSnoozedPointFormik.values.latitude } onChange={updateSnoozedPointFormik.handleChange} disabled />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'} required>
								<Input name="longitude" placeholder={langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'} defaultValue={ updateSnoozedPointFormik.values.longitude } value={ updateSnoozedPointFormik.values.longitude } onChange={updateSnoozedPointFormik.handleChange} disabled />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label= {langdata && langdata.IsSnoozed ? langdata.IsSnoozed : 'Is Snoozed?'} >
								<Checkbox name="isSnoozed" checked={ updateSnoozedPointFormik.values.isSnoozed } value={ updateSnoozedPointFormik.values.isSnoozed } onChange={updateSnoozedPointFormik.handleChange} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			{/* Vulnerable Point Details Modal Ends */}

			{/* Delete Modal Starts */}
			<Modal title= {langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={showDeleteSnoozedPointModal} onOk={deleteSnoozedPoint} onCancel={closeDeleteSnoozedPointModal}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeDeleteSnoozedPointModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button key="submit" type="primary" onClick={deleteSnoozedPoint}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} 
				>
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>{langdata && langdata.Areyousureyouwanttodeletethissnoozedpoint ? langdata.Areyousureyouwanttodeletethissnoozedpoint : 'Are you sure you want to delete this snoozed point?'}</Col>
				</Row>
			</Modal>
			{/* Delete Modal Ends */}
		</>
	);
}