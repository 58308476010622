export const PIPELINE_CONFIG_LIST = 'PIPELINE_CONFIG_LIST';
export const PIPELINE_CONFIG_LIST_SUCCESS = 'PIPELINE_CONFIG_LIST_SUCCESS';
export const PIPELINE_CONFIG_LIST_ERROR = 'PIPELINE_CONFIG_LIST_ERROR';

export const TLPS_LIST = 'TLPS_LIST';
export const TLPS_LIST_SUCCESS = 'TLPS_LIST_SUCCESS';
export const TLPS_LIST_ERROR = 'TLPS_LIST_ERROR';

export const TLP_DETAILS = 'TLP_DETAILS';
export const TLP_DETAILS_SUCCESS = 'TLP_DETAILS_SUCCESS';
export const TLP_DETAILS_ERROR = 'TLP_DETAILS_ERROR';

export const SAVE_TLP = 'SAVE_TLP';
export const SAVE_TLP_SUCCESS = 'SAVE_TLP_SUCCESS';
export const SAVE_TLP_ERROR = 'SAVE_TLP_ERROR';

export const UPDATE_TLP = 'UPDATE_TLP';
export const UPDATE_TLP_SUCCESS = 'UPDATE_TLP_SUCCESS';
export const UPDATE_TLP_ERROR = 'UPDATE_TLP_ERROR';

export const DELETE_TLP = 'DELETE_TLP';
export const DELETE_TLP_SUCCESS = 'DELETE_TLP_SUCCESS';
export const DELETE_TLP_ERROR = 'DELETE_TLP_ERROR';

export const REPLACE_TLP = 'REPLACE_TLP';
export const REPLACE_TLP_SUCCESS = 'REPLACE_TLP_SUCCESS';
export const REPLACE_TLP_ERROR = 'REPLACE_TLP_ERROR';

export const SET_TRU_VOLTAGE = 'SET_TRU_VOLTAGE';
export const SET_TRU_VOLTAGE_SUCCESS = 'SET_TRU_VOLTAGE_SUCCESS';
export const SET_TRU_VOLTAGE_ERROR = 'SET_TRU_VOLTAGE_ERROR';

export const UPDATE_TLP_FOTA = 'UPDATE_TLP_FOTA';
export const UPDATE_TLP_FOTA_SUCCESS = 'UPDATE_TLP_FOTA_SUCCESS';
export const UPDATE_TLP_FOTA_ERROR = 'UPDATE_TLP_FOTA_ERROR';

export const CANCEL_MANUAL_OVERRIDE = 'CANCEL_MANUAL_OVERRIDE';
export const CANCEL_MANUAL_OVERRIDE_SUCCESS = 'CANCEL_MANUAL_OVERRIDE_SUCCESS';
export const CANCEL_MANUAL_OVERRIDE_ERROR = 'CANCEL_MANUAL_OVERRIDE_ERROR';