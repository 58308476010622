import { Breadcrumb, Button, Col, DatePicker, Empty, Form, Input, message, Modal, Row, Select, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useReducer, useState } from 'react';
import { AreaChartOutlined, HomeOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

import { useAuth } from '../../Context';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import moment from 'moment';
import { useFormik } from 'formik';
import axios from 'axios';
import fractureData from './reducer';
import { FRACTURING_CREATE, FRACTURING_CREATE_ERROR, FRACTURING_CREATE_SUCCESS, FRACTURING_DELETE, FRACTURING_DELETE_ERROR, FRACTURING_DELETE_SUCCESS, FRACTURING_LIST, FRACTURING_LIST_ERROR, FRACTURING_LIST_SUCCESS } from './constants';

const { Title } = Typography;
const { RangePicker } = DatePicker;

// const unpack = (rows, key) => rows.map(row => row[key]);

export default function FractureDetails () {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	
	const initalState = {microSeismic_data:[], loading: false, error: false, updateData : {} };
	const [fracture, dispatch] = useReducer(fractureData, initalState);

	const { state } = useLocation();
	const navigate = useNavigate();

	// const [spin, setSpin] = useState(false);

	const [startDate, setStartDate] = useState(state && state.data && state.data.startDate ? state.data.startDate: moment.utc(new Date().setHours(0,0,0,0)).subtract({'days' : 30}).toISOString());
	const [endDate, setEndDate] = useState(state && state.data && state.data.endDate ? state.data.endDate: new Date().toISOString());
    
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [seismicStartDate, setSeismicStartDate] = useState();
	const [seismicEndDate, setSeismicEndDate] = useState();
	const [deleteId, setDeleteId] = useState();
	const [showDeleteModal, setShowDeleteModal] = useState(false);	


	const { wellinfo, getWellInfo, getWellSeismicType, wellSeismicType, wellchanneldata, getWellChannels} = useAuth();		

	const seismicFilterData = [];
	seismicFilterData.push({
		value : null,
		label : 'ALL'
	});
	const seismicData = [];
	let fractureid;
	wellSeismicType && wellSeismicType.map((item) => {
		if(item.name.toLowerCase() == 'fracture' || item.name.toLowerCase() == 'fractures'){
			fractureid = item.id;
		}
		
		seismicFilterData.push({
			value : item.id,
			label : item.name.toUpperCase()
		});
		seismicData.push({
			value : item.id,
			label : item.name.toUpperCase()
		});
	});	

	const welldata =[];

	wellinfo && wellinfo.map((item) => {
		welldata.push({
			value : item.id,
			label : item.name.toUpperCase(),
			ip:item.ip,
			port :item.port
		});
		
	});
	
	useEffect(() => {
		loadFractures();		
	},[fractureid, startDate, endDate]);

	const loadFractures = () => {
		dispatch({ type : FRACTURING_LIST });
		let params = '';
		if(fractureid) {
			params += `&seismicTypeId=${fractureid}`;
		}
		if(startDate && endDate){
			params += `&dateTimeFrom[$gte]=${startDate}&dateTimeTo[$lte]=${endDate}`;
		}
		if(state && state.data && state.data.channelId){
			params += `&channelId=${state && state.data && state.data.channelId}`;
		}
		if(state && state.data && state.data.wellId){
			params += `&wellinfoId=${state && state.data && state.data.wellId}`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url:  `${api}/wellfracturing?$sort[id]=1${params}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};
		
		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					dispatch({ type : FRACTURING_LIST_SUCCESS, payload : response.data});
				} else {
					dispatch({ type : FRACTURING_LIST_ERROR });
				}
			})
			.catch((error) => {
				dispatch({ type : FRACTURING_LIST_ERROR });
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};
	

	useEffect(() => {
		getWellInfo(authtoken);
		getWellSeismicType(authtoken);
		
	},[]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	
	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());


	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
		setShowDeleteModal(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setShowDeleteModal(false);
	};

	const disabledRangeTime = (currentDate) => {
		if (!currentDate) {
			return {};
		}
		
		const now = moment();
		
		// Disable future time slots on the current date
		if (currentDate.isSame(now, 'day')) {
			return {
				disabledHours: () => {
					const currentHour = now.hour();
					// Disable hours after the current hour
					return Array.from({ length: 24 }, (_, i) => (i > currentHour ? i : null)).filter(i => i !== null);
				},
				// disabledMinutes: () => {
				// 	const currentHour = now.hour();
				// 	const currentMinute = now.minute();
				// 	// Disable minutes after the current minute in the current hour
				// 	if (currentHour === now.hour()) {
				// 		return Array.from({ length: 60 }, (_, i) => (i > currentMinute ? i : null)).filter(i => i !== null);
				// 	}
				// 	return [];
				// },
				// disabledSeconds: () => {
				// 	const currentHour = now.hour();
				// 	const currentMinute = now.minute();
				// 	const currentSecond = now.second();
				// 	// Disable seconds after the current second in the current minute
				// 	if (currentHour === now.hour() && currentMinute === now.minute()) {
				// 		return Array.from({ length: 60 }, (_, i) => (i > currentSecond ? i : null)).filter(i => i !== null);
				// 	}
				// 	return [];
				// },
			};
		}
		
		// For future dates, allow all hours, minutes, and seconds
		return {};
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const handleSetDate = (e) =>{
		if(e) {
			setStartDate(moment.utc(new Date(e[0] && e[0].$d)).toISOString());
			setEndDate(moment.utc(new Date(e[1] && e[1].$d)).toISOString());  
		} else {
			setStartDate(null);
			setEndDate(null);
		}
	};

	// const channellist =[{
	// 	value:1,label:'Channel-1'
	// },{
	// 	value:2,label:'Channel-2'
	// }];

	const channellist =[];

	wellchanneldata && wellchanneldata.map((item)=>{
		channellist.push({
			value: item.id,
			label : item.name

		});
		
	});	

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: null,
			depthFrom: null,
			depthTo: null,
			dateTimeFrom : null,
			dateTimeTo : null,
			channelId : state && state.data && state.data.channelId,
			wellinfoId : state && state.data && state.data.wellId,
		},
		onSubmit: (value) => {

			const {name, depthFrom, depthTo, channelId, wellinfoId} = value;
			if(name == null || depthFrom == null || depthTo == null || fractureid == null || seismicStartDate == undefined || seismicEndDate == undefined) {
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}

			dispatch({ type : FRACTURING_CREATE });

			let data = JSON.stringify({
				'name': name,
				'depthFrom': depthFrom,
				'depthTo': depthTo,
				'channelId': parseInt(channelId),
				'wellinfoId': parseInt(wellinfoId),
				'status': 'New',
				'seismicTypeId': parseInt(fractureid),
				'pipelineId': parseInt(pipelineId),
				'dateTimeFrom' : seismicStartDate,
				'dateTimeTo' : seismicEndDate
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/wellfracturing`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`				
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201 ){
						Modal.success({ title: langdata && langdata.Fracturemonitoringsavedsuccessfully ? langdata.Fracturemonitoringsavedsuccessfully : 'Fracture monitoring saved successfully.' });
						dispatch({ type : FRACTURING_CREATE_SUCCESS });
						loadFractures();
					} else {
						Modal.warning({ title: langdata && langdata.Fracturemonitoringnotsaved ? langdata.Fracturemonitoringnotsaved : 'Fracture monitoring not saved' });					
						dispatch({ type : FRACTURING_CREATE_ERROR });
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : FRACTURING_CREATE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Fracturemonitoringnotsaved ? langdata.Fracturemonitoringnotsaved : 'Fracture monitoring not saved' });					
					}
				});

			setIsModalOpen(false);
			
		}
	});	

	useEffect(()=>{
		if( state.data.wellId || formik.values.wellinfoId){
			getWellChannels(authtoken, null,formik.values.wellinfoId ? formik.values.wellinfoId : state.data.wellId);

		}
	},[formik.values.wellinfoId,state.data.wellId]);

	const columns = [
		{
			title: `${langdata && langdata.Name ? langdata.Name:'Name'}`,
			dataIndex: 'name',
			key: 'name',
			//   render: (_,text) => <a onClick={showEditDrawer}>{text}</a>

		},
		{
			title: `${langdata && langdata.StartDateandTime ? langdata.StartDateandTime:'Start Date and Time'}`,
			dataIndex: 'dateTimeFrom',
			key: 'dateTimeFrom',
		},
		{
			title: `${langdata && langdata.EndDateandTime ? langdata.EndDateandTime:'End Date and Time'}`,
			dataIndex: 'dateTimeTo',
			key: 'dateTimeTo',
		},
		{
			title: `${langdata && langdata.WellName ? langdata.WellName:'Well Name'}`,
			dataIndex: 'wellinfo',
			key: 'wellinfo',
		},
		{
			title: `${langdata && langdata.ChannelName ? langdata.ChannelName:'Channel Name'}`,
			dataIndex: 'channelName',
			key: 'channelName',
		},
		{
			title: `${langdata && langdata.WellSeismicType ? langdata.WellSeismicType:'Well Seismic Type'}`,
			dataIndex: 'wellseismictypes',
			key: 'wellseismictypes',
		},
		{
			title: `${langdata && langdata.StartDepth ? langdata.StartDepth:'Start Depth'}`,
			dataIndex: 'depthFrom',
			key: 'depthFrom',
		},
		{
			title: `${langdata && langdata.EndDepth ? langdata.EndDepth:'End Depth'}`,
			dataIndex: 'depthTo',
			key: 'depthTo',
		},
		{
			title: `${langdata && langdata.Status ? langdata.Status:'Status'}`,
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: `${langdata && langdata.Actions ? langdata.Actions:'Actions'}`,
			key: 'action',
			align:'center',
			render: (_, record) => (
				<Space style={{justifyContent:'center',display:'flex'}} size="middle">
					<Button title={langdata && langdata.DeleteFractreMonitoring ? langdata.DeleteFractreMonitoring:'Delete Fractre Monitoring'} type="link" disabled = {record.status == 'New' ? false : true}  onClick={() => {handleDeleteMicroSeismic(record);}}><DeleteOutlined/></Button>
				</Space>
			)
		}
    
	];

	const handleSetStartDate = (e) =>{
		if(e) {
			setSeismicStartDate(moment.utc(new Date(e && e.$d)).toISOString());
		} else {
			setSeismicStartDate(null);
		}
	};

	const handleSetEndDate = (e) =>{
		if(e) {
			setSeismicEndDate(moment.utc(new Date(e && e.$d)).toISOString());
		} else {
			setSeismicEndDate(null);
		}
	};

	const handleDeleteMicroSeismic = (value) => {
		setDeleteId(value.id);
		setShowDeleteModal(true);
	};	
	
	const handleDelete = () => {
		dispatch({ type : FRACTURING_DELETE });
		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/wellfracturing/${deleteId}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				Authorization:`Bearer ${authtoken}`				
			},
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 || response.status == 201){
					loadFractures();
					dispatch({ type : FRACTURING_DELETE_SUCCESS });
					Modal.success({ title: langdata && langdata.Fracturemonitoringdeletedsuccessfully ? langdata.Fracturemonitoringdeletedsuccessfully : 'Fracture monitoring deleted successfully.' });					
				} else {
					dispatch({ type : FRACTURING_DELETE_ERROR });
					Modal.warning({ title: langdata && langdata.Fracturemonitoringnotdeleted ? langdata.Fracturemonitoringnotdeleted : 'Fracture monitoring not deleted' });					
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : FRACTURING_DELETE_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				} else {
					Modal.warning({ title: langdata && langdata.Fracturemonitoringnotdeleted ? langdata.Fracturemonitoringnotdeleted : 'Fracture monitoring not deleted' });
				}
			});
		setShowDeleteModal(false);
	};	
		
	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={6}>
						<Row>
							<Space>
								<AreaChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.FractureDetails ? langdata.FractureDetails : 'Fracture Details'}</Title>
							<Space>&nbsp;&nbsp;</Space>
						</Row>
					</Col>
					<Col span={18}>
						<Row justify="end">
							<Col>
								{/* <Tooltip placement="bottom" title={langdata && langdata.FilterbyWell ? langdata.FilterbyWell : 'Filter by well'}>
									<Space>
										<Select
											style={{minWidth: '150px', maxWidth:'150px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectWell ? langdata.SelectWell : 'Select Well'}
											optionFilterProp="children"
											value={wellId}
											options={welldata}
											onSelect={(e, options)=>handleSetWell(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;</Space>	
								<Tooltip placement="bottom" title={langdata && langdata.Filterbychannel ? langdata.Filterbychannel : 'Filter by channel'}>
									<Select
										style={{ minWidth: '150px', maxWidth:'150px', margin:'5px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectChannel ? langdata.SelectChannel : 'Select Channel'}
										optionFilterProp="children"
										defaultValuevalue={null}
										value={channelId}
										options={channellist}
										onSelect={(value)=>handleSetChannel(value)}
									/>
								</Tooltip>
								<Space>&nbsp;</Space> */}
								<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}>
									<RangePicker 
										showTime ={{hideDisabledOptions: true, format : 'YYYY-MM-DD HH'}} 
										style={{ width: '300px',margin:'5px'}}
										defaultValue={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
										format={settingsdateformat + ' ' + settingstimeformat}
										placeholder={[langdata && langdata.Startdateandtime ? langdata.Startdateandtime : 'Start date and time', langdata && langdata.Enddateandtime ? langdata.Enddateandtime : 'End date and time']}
										disabledDate={disabledDate}
										disabledTime={disabledRangeTime}
										onChange={handleSetDate}
										allowClear={true} />
								</Tooltip>
								<Space>&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.AddNew ? langdata.AddNew:'Add New'}>
									<Button
										style={{ margin:'5px' }}
										type='primary'
										onClick={showModal}
									><PlusOutlined/>{langdata && langdata.New ? langdata.New:'New'}</Button>
								</Tooltip>					
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					// marginTop : '5px',
					// paddingLeft: '10px',
					// paddingRight: '10px',
					// background: '#FFFFFF',
					alignItems:'center',
				}}  >
					<Col>
						<Breadcrumb
							items={[
								{
									href: '/dashboard',
									title: <HomeOutlined />,
								},
								{
									title: ( state && state.path === 'well-visualization' ? <a onClick={()=>{navigate(-2);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a> :  <a onClick={()=>{navigate(-2);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a>)
								},
								{
									title: ( state && state.path === 'microseismic-monitoring-visualization' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.HydraulicFracturingAnalysis ? langdata.HydraulicFracturingAnalysis:'Hydraulic Fracturing Analysis'}</a> :  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.HydraulicFracturingAnalysis ? langdata.HydraulicFracturingAnalysis:'Hydraulic Fracturing Analysis'}</a>)
								},
								{
									title: `${langdata && langdata.FractureDetails ? langdata.FractureDetails : 'Fracture Details'}`,
								},
							]}
							// style={{ marginBottom : '10px'}}
						/>
					</Col>
				</Row>

				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{fracture.microSeismic_data.length > 0 ?
							<Table 
								scroll={{
									x: 900,
								}}
								columns={columns} dataSource={fracture.microSeismic_data}/>
							: 
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)
						}
					</Col>
				</Row>
				
			</Content>
			<Modal 
				title={langdata && langdata.FractureMonitoring ? langdata.FractureMonitoring : 'Fracture Monitoring' } 
				destroyOnClose= {true}
				maskClosable= {false}
				open={isModalOpen} 
				width={800}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={formik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}
				onOk={handleOk} 
				onCancel={handleCancel}>
				<Form layout="vertical" onSubmit={formik.handleSubmit} >
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Name ? langdata.Name : 'Name'} name='name' rules={[{ required: true, message : langdata && langdata.Enterthename ? langdata.Enterthename : 'Enter the name' }]}> 
								<Input 
									type='text' 
									placeholder={langdata && langdata.Enterthename ? langdata.Enterthename : 'Enter the name'}
									onChange={formik.handleChange}
								/>		
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.WellName ? langdata.WellName : 'Well Name'} name="wellName" rules={[{ required: true, message : langdata && langdata.SelecttheWellname ? langdata.SelecttheWellname : 'Select the Well name' }]}> 
								{/* <Input 
									placeholder={langdata && langdata.Selecttheseismictype ? langdata.Selecttheseismictype : 'Select the seismic type'}
									onChange={formik.handleChange}
								/> */}
								<Select
									// style={{ width: '180px',margin:'5px'}}
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.SelecttheWellname ? langdata.SelecttheWellname : 'Select the Well name'}
									optionFilterProp="children"
									defaultValue={formik.values.wellinfoId}
									options={welldata}
									onChange={(value) => {formik.setFieldValue('wellinfoId', value);}}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} name="channel" rules={[{ required: true, message : langdata && langdata.Selectthechannelname ? langdata.Selectthechannelname : 'Select the channel name' }]}> 
								{/* <Input 
									placeholder={langdata && langdata.Selecttheseismictype ? langdata.Selecttheseismictype : 'Select the seismic type'}
									onChange={formik.handleChange}
								/> */}
								<Select
									// style={{ width: '180px',margin:'5px'}}
									name="channelId"
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Selectthechannelname ? langdata.Selectthechannelname : 'Select the channel name'}
									optionFilterProp="children"
									defaultValue={formik.values.channelId}
									options={channellist}
									onChange={(value) => formik.setFieldValue('channelId', value)}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.DepthFrom ? langdata.DepthFrom : 'Depth From'} name="depthFrom" rules={[{ required: true, message : langdata && langdata.Enterthedepthrangefrom ? langdata.Enterthedepthrangefrom : 'Enter the depth range from' }]}> 
								<Input 
									type='number' 
									placeholder={langdata && langdata.Enterthedepthrangefrom ? langdata.Enterthedepthrangefrom : 'Enter the depth range from'}
									onChange={formik.handleChange}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.DepthTo ? langdata.DepthTo : 'Depth To'} name="depthTo" rules={[{ required: true, message : langdata && langdata.Enterthedepthrangeto ? langdata.Enterthedepthrangeto : 'Enter the depth range to' }]}> 
								<Input 
									type='number' 
									placeholder={langdata && langdata.Enterthedepthrangeto ? langdata.Enterthedepthrangeto : 'Enter the depth range to'}
									onChange={formik.handleChange}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.DateFrom ? langdata.DateFrom : 'Date From'} name="dateTimeFrom" rules={[{ required: true, message : langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time' }]}> 
								<DatePicker
									style={{ width : '100%'}}
									showTime
									placeholder={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}
									onChange={handleSetStartDate}
									// onOk={onOk}
									disabledDate={disabledDate}
									disabledTime={disabledRangeTime}
								/>								
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.DateTo ? langdata.DateTo : 'Date To'} name="dateTimeTo" rules={[{ required: true, message : langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time' }]}> 
								<DatePicker
									style={{ width : '100%'}}
									showTime
									placeholder={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}
									onChange={handleSetEndDate}
									disabledDate={disabledDate}
									disabledTime={disabledRangeTime}
									// onOk={onOk}
								/>									
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={showDeleteModal} onOk={handleOk} onCancel={handleCancel}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}</Button>
						<Button key="submit" type="primary" onClick={handleDelete}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>{ langdata && langdata.Areyousureyouwanttodeletethisfracture ? langdata.Areyousureyouwanttodeletethisfracture :'Are you sure you want to delete this fracture?'}</Col>
				</Row>
			</Modal>
			<Spin fullscreen spinning={fracture.loading} />

			{/* Add Vulnerable Point Details Modal Starts */}
			
			{/* Delete User Modal Ends */}
		</>
	);
}