export const CHAINAGES_LIST = 'CHAINAGES_LIST';
export const CHAINAGES_LIST_SUCCESS = 'CHAINAGES_LIST_SUCCESS';
export const CHAINAGES_LIST_ERROR = 'CHAINAGES_LIST_ERROR';

export const AVAILABLE_CHAINAGES_LIST = 'AVAILABLE_CHAINAGES_LIST';
export const AVAILABLE_CHAINAGES_LIST_SUCCESS = 'AVAILABLE_CHAINAGES_LIST_SUCCESS';
export const AVAILABLE_CHAINAGES_LIST_ERROR = 'AVAILABLE_CHAINAGES_LIST_ERROR';

export const VULNERABLE_POINTS_LIST = 'VULNERABLE_POINTS_LIST';
export const VULNERABLE_POINTS_LIST_SUCCESS = 'VULNERABLE_POINTS_LIST_SUCCESS';
export const VULNERABLE_POINTS_LIST_ERROR = 'VULNERABLE_POINTS_LIST_ERROR';

export const VULNERABLE_POINT_DETAILS = 'VULNERABLE_POINT_DETAILS';
export const VULNERABLE_POINT_DETAILS_SUCCESS = 'VULNERABLE_POINT_DETAILS_SUCCESS';
export const VULNERABLE_POINT_DETAILS_ERROR = 'VULNERABLE_POINT_DETAILS_ERROR';

export const SAVE_VULNERABLE_POINT = 'SAVE_VULNERABLE_POINT';
export const SAVE_VULNERABLE_POINT_SUCCESS = 'SAVE_VULNERABLE_POINT_SUCCESS';
export const SAVE_VULNERABLE_POINT_ERROR = 'SAVE_VULNERABLE_POINT_ERROR';

export const UPDATE_VULNERABLE_POINT = 'UPDATE_VULNERABLE_POINT';
export const UPDATE_VULNERABLE_POINT_SUCCESS = 'UPDATE_VULNERABLE_POINT_SUCCESS';
export const UPDATE_VULNERABLE_POINT_ERROR = 'UPDATE_VULNERABLE_POINT_ERROR';

export const DELETE_VULNERABLE_POINT = 'DELETE_VULNERABLE_POINT';
export const DELETE_VULNERABLE_POINT_SUCCESS = 'DELETE_VULNERABLE_POINT_SUCCESS';
export const DELETE_VULNERABLE_POINT_ERROR = 'DELETE_VULNERABLE_POINT_ERROR';