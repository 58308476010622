/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useReducer, useState } from 'react';

import { Row, Col,  Typography, Button, Space, Table, Tag, Radio,  Modal, Spin, Divider, Select, DatePicker, Card, Empty, notification, message, Popover, Avatar, Tooltip} from 'antd';
import { Content } from 'antd/es/layout/layout';
import '../../../App.css';

import { useAuth } from '../../Context';
import axios from 'axios';
import { GRAPH_DATA, GRAPH_DATA_ERROR, GRAPH_DATA_SUCCESS, REALTIME_ALARM_DETAILS, REALTIME_ALARM_ERROR, REALTIME_ALARM_LIST, REALTIME_ALARM_SUCCESS, RELATED_ALARMS_ANALYTICS, RELATED_ALARMS_ANALYTICS_ERROR, RELATED_ALARMS_ANALYTICS_SUCCESS } from './constants';
import realtimealarmdatareducer from './reducer';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import Chart from 'react-apexcharts';
import useResizeObserver from 'use-resize-observer';

// import VehicleModal from '../../../images/vehicleModal.png';
// import DrumModal from '../../../images/drumModal.png';
// import ClampingModal from '../../../images/clampingModal.png';
// import mapImg from '../../../images/mapImg.png';
// import mapImgDeep from '../../../images/ManualDeep.png';
// import mapImgMachine from '../../../images/JCBMap.png';
// import mapImgTunneling from '../../../images/TunnelingMap.png';
// import modalDefault from '../../../images/modalDefault.png';
// import modalMan from '../../../images/ManWalking.png';
import dayjs from 'dayjs';
import { BatchDownloader } from './downloader';
import { SearchOutlined, DownloadOutlined, FileTextOutlined, InfoOutlined } from '@ant-design/icons';
import { datetimeCovertertolocal } from '../../datetime-converter';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';


const { Title } = Typography;

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

let alarm_data_pagination = {};

export default function AlarmReport() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const [view, setSize] = useState('List');
	const {getRegion, regiondata, getAllEvents, eventsData, handleGetMapdata, mapdata, getUserAllocation ,getUserAllocationData} = useAuth();
	const initalState = {relatimealarmdata : [], relatimealarmdata_details:[],status_patch_details:[], alarm_view:[], details: null, pending: false, error: false, graph1PipelineChainage : [], graph1AlarmCount : [], graph2Date : [], graph2AlarmCount : [] };
	const [alarmdata, dispatch] = useReducer(realtimealarmdatareducer, initalState);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [url, setURL] = useState(null);
	const [showFetchAndDownloadModal, setShowFetchAndDownloadModal] = useState(false);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [allocationId, setAllocationId] = useState(null);
	const [startCh, setStartCh] = useState(null);
	const [endCh, setEndCh] = useState(null);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const [alarmdetails, setAlarmDetails] = useState({});
	const [relatedAlarms, setRelatedAlarms] = useState([]);
	const [activeExpRow, setActiveExpRow] = useState([]);
	const [showRelatedAlarm, setShowRelatedAlarm] = useState(false);
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));

	const { ref, width } = useResizeObserver();

	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);
	// const [series, setSeries] = useState([]);

	// const [date, setDate] = useState(new Date().toLocaleDateString('en-UK', {
	// 	year: 'numeric',
	// 	month: '2-digit',
	// 	day: '2-digit',
	// }));

	// console.log(date);

	const [modelOpen, setModelOpen] = useState(false);
	const navigate = useNavigate();
	const [reportFilter, setFilter] = useState({regioninfoId:null, event:['all'], severity:'all', 
		related_alarm:false, status:null, searchby:'chainage', startchainage:null, endchainage:null, startODMeter:null, endODMeter:null
	}); 

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));
  
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const searchValue = [

		{
			value: 'chainage',
			label: `${langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}`,
		},
		{
			value: 'od',
			label: `${langdata && langdata.ODMeter ? langdata.ODMeter :'ODMeter'}`,
		}
	];
	
	const severity = [
		{
			value: 'all',
			label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`,
		},
		{
			value: 2,
			label: `${langdata && langdata.AMBER ? langdata.AMBER:'AMBER'}`,
		},
		{
			value: 0,
			label: `${langdata && langdata.CLEAR ? langdata.CLEAR:'CLEAR'}`,
		},
		{
			value: 1,
			label: `${langdata && langdata.GREEN ? langdata.GREEN:'GREEN'}`,
		},
		{
			value: 3,
			label: `${langdata && langdata.RED ? langdata.RED:'RED'}`,
		},
	];
	
	
	const status = [
		{
			value: null,
			label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`,
		},
		{
			value: 'Acknowledged',
			label: `${langdata && langdata.ACKNOWLEDGED ? langdata.ACKNOWLEDGED:'ACKNOWLEDGED'}`,
		},
		{
			value: false,
			label: `${langdata && langdata.NOTRESOLVED ? langdata.NOTRESOLVED:'NOT RESOLVED'}`,
		},
		{
			value: 'Resolved',
			label: `${langdata && langdata.RESOLVED ? langdata.RESOLVED:'RESOLVED'}`,
		},
	];
	
	const related_alarm = [
	
		{
			value: true,
			label: `${langdata && langdata.Yes ? langdata.Yes:'Yes'}`,
		},
		{
			value: false,
			label: `${langdata && langdata.No ? langdata.No:'No'}`,
		},
	];

	let realtimeAlarmList = [];
	let pipelineChainages = []; // For chart x-axis
	
	alarmdata.relatimealarmdata && alarmdata.relatimealarmdata.map((item, index) => {
		const datetime = datetimeCovertertolocal(item.datetime);
		const newdate = datetime.localdate + ' ' +  datetime.localtime;
		realtimeAlarmList.push({
			key: index.toString(),
			id: item.alarm_prm_id,
			activity: item.eventType,
			description:item.ruleDesc,
			date:newdate,
			duration:item.duration ? (item.duration/60).toFixed(2) + ` ${langdata && langdata.Min ? langdata.Min:' Min'}` : 0 + ` ${langdata && langdata.Min ? langdata.Min:' Min'}`,
			channel: item.channel_name,
			eventDate: newdate,
			chainage: item.locationDetails ? item.locationDetails.info : null,
			fibrelength: item.odMeter,
			status:item.status,
			pidsinfoId:item.pidsinfoId,
			ackdetDetails:item.ackdetDetails,
			alarmSeverity:item.alarmSeverity,
			pidsname:item.pidname.toUpperCase(),
			region : regiondata && regiondata.map((region) => { if (item.regioninfoId === region.id){ return region.regionName.toUpperCase();} }),


		});
	});

	let contenttext = '';
	if(alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details){

		const datetime = datetimeCovertertolocal(alarmdata.relatimealarmdata_details.datetime);
		const newdate = datetime.localdate + ' ' +  datetime.localtime;
		let regionObject = regiondata && regiondata.find((region) => region.id == alarmdata.relatimealarmdata_details.regioninfoId);
		let regionName;
		if(regionObject !== undefined){
			regionName= regionObject.regionName.toUpperCase();
		}
		contenttext = `${langdata && langdata.Id ? langdata.Id:'Id'}: ${alarmdata.relatimealarmdata_details.alarm_prm_id}\n${langdata && langdata.Activity ? langdata.Activity:'Activity'}: ${alarmdata.relatimealarmdata_details.eventType}\n${langdata && langdata.Date ? langdata.Date:'Date'}: ${newdate}\n${langdata && langdata.Region ? langdata.Region:'Region'}: ${regionName}\n${langdata && langdata.PIDS ? langdata.PIDS:'PIDS'}: ${alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.pidname ? alarmdata.relatimealarmdata_details.pidname.toUpperCase() : ''}\n${langdata && langdata.Channel ? langdata.Channel:'Channel'}: ${alarmdata.relatimealarmdata_details.channel_name}\n${langdata && langdata.Chainage ? langdata.Chainage:'Chainage'}: ${alarmdata.relatimealarmdata_details.locationDetails ? alarmdata.relatimealarmdata_details.locationDetails.info : null}\n${langdata && langdata.Fiberlength ? langdata.Fiberlength:'Fiber Length'}: ${alarmdata.relatimealarmdata_details.odMeter}\n${langdata && langdata.Latitude ? langdata.Latitude:'Latitude'}:${alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.Lat}\n${langdata && langdata.Longitude ? langdata.Longitude:'Longitude'}: ${alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.Long}`;
	}

	let regions=[{value:null,
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	let allevents = [{value:'all',
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	eventsData && eventsData.map((allevent)=>{
		allevents.push({
			value:allevent.eventType,
			label:allevent.eventType});
	});

	const columns = [
		
		{
			title: `${langdata && langdata.Id ? langdata.Id :'Id'}`,
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			render: (text, record) => (
				<Space size="middle">
					{ record  && 
			<span>
				<span style={{ float: 'left', backgroundColor: record.alarmSeverity==3 ? 'red' :
					record.alarmSeverity==2 ? 'gold' : record.alarmSeverity==1 ? 'green' : record.alarmSeverity==0 ? 'violet' : '', 
				width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} />
			</span>
					}
					<text>{text}</text>
				</Space>
			)
		},
		{
			title: `${langdata && langdata.Activity ? langdata.Activity :'Activity'}`,
			dataIndex: 'activity',
			key: 'activity',

			render: (_, record) => (
				<Space size="middle">
					<a title={langdata && langdata.Moreinfo ? langdata.Moreinfo :'More info'} onClick={()=>{key(record.id);}}>{record.activity}</a>
				</Space>
			)

		},
		{
			title: `${langdata && langdata.Region ? langdata.Region :'Region'}`,
			dataIndex: 'region',
			key: 'region',
		},
		{
			title: `${langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}`,
			dataIndex: 'pidsname',
			key: 'pidsname',
			
		},
		{
			title: `${langdata && langdata.Channel ? langdata.Channel :'Channel'}`,
			dataIndex: 'channel',
			key: 'channel',
		},
		{
			title: `${langdata && langdata.Description ? langdata.Description :'Description'}`,
			dataIndex: 'description',
			key: 'description',
			render: (text) => (
				<Popover title={langdata && langdata.Description ? langdata.Description :'Description'} content={text} trigger="hover">
					<Typography.Paragraph
						ellipsis={
							{
								rows: 2,
								expandable: false,
								symbol: ''
							}
						}
					>
						{text}
					</Typography.Paragraph>
				</Popover>
			)
		},
		{
			title: `${langdata && langdata.Date ? langdata.Date :'Date'}`,
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: `${langdata && langdata.Duration ? langdata.Duration :'Duration'}`,
			dataIndex: 'duration',
			key: 'duration',
		},
		
		{
			title: `${langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}`,
			dataIndex: 'chainage',
			key: 'chainage',
			render: (text) => (
				<Popover title={langdata && langdata.Chainage ? langdata.Chainage :'Chainage'} content={text} trigger="hover">
					<Typography.Paragraph
						ellipsis={
							{
								rows: 2,
								expandable: false,
								symbol: ''
							}
						}
					>
						{text}
					</Typography.Paragraph>
				</Popover>
			)
		},
		{
			title: `${langdata && langdata.FiberLength ? langdata.FiberLength :'Fiber Length (m)'}`,
			dataIndex: 'fibrelength',
			key: 'fibrelength',
		},
		{
			title: `${langdata && langdata.Status ? langdata.Status :'Status'}`,
			dataIndex: 'status',
			key: 'status',
			render: (_, status) => (
				<>
					{status &&
						<Tag color={status.alarmSeverity==3 ? 'red' :
							status.alarmSeverity==2 ? 'gold' : status.alarmSeverity==1 ? 'green' : status.alarmSeverity==0 ? 'violet' : ''}>
							{status.status}
						</Tag>}
				</>
			),
		},

	];
  
	let series = [{
		name: `${langdata && langdata.AlarmCount ? langdata.AlarmCount :'Alarm Count'}`,
		data: alarmdata.graph1AlarmCount
	}];
	
	let options = {
		dataLabels: {
			enabled: true
		},
		title: {
			text: `${langdata && langdata.Alarmcountbychainage ? langdata.Alarmcountbychainage :'Alarm count by chainage'}`,
			align: 'left'
		},
		chart: {
			toolbar: {
				show: false
			}
		},
		legend: {
			show: true,
			position: 'top',
			horizontalAlign: 'center',
			onItemClick: {
				toggleDataSeries: true
			},
			onItemHover: {
				highlightDataSeries: true
			}
		},
		xaxis: {
			title: {
				text: `${langdata && langdata.CHAINAGE ? langdata.CHAINAGE :'CHAINAGE'}`
			},
			categories: alarmdata.graph1PipelineChainage,
			labels: {
				show: true,
				showDuplicates: true,
				formatter: function (val) {
					return val;
				}
			}
		},
		yaxis: {
			title: {
				text: `${langdata && langdata.COUNT ? langdata.COUNT :'COUNT'}`
			}
		},
		markers: { size: 5, shape: 'square' },
	  	stroke: { curve: 'smooth', width: 2 }
	};

	let series2 = [{
		name: `${langdata && langdata.AlarmCount ? langdata.AlarmCount :'Alarm Count'}`,
		data: alarmdata.graph2AlarmCount
	}];

	let options2 = {

		title: {
			text: `${langdata && langdata.Alarmcountbydate ? langdata.Alarmcountbydate :'Alarm count by date'}`,
			align: 'left'
		},
		chart: {
			toolbar: {
				show: false
			}
		},

	  plotOptions: {
			bar: {
		  horizontal: false,
		  columnWidth: '20px',
		  endingShape: 'rounded'
			},
	  },
	  dataLabels: {
			enabled: false
	  },
	  markers: { size: 5, shape: 'square' },
	  stroke: { curve: 'smooth', width: 2 },
	  xaxis: {
			title: {
				text: `${langdata && langdata.DATE ? langdata.DATE :'DATE'}`
			},
			categories: alarmdata.graph2Date,
			labels: {
				show: true,
				showDuplicates: true,
				rotate: -60,
			}
		},
		yaxis: {
			title: {
				text: `${langdata && langdata.COUNT ? langdata.COUNT :'COUNT'}`
			}
		},
	  fill: {
			opacity: 1
	  }
	  };

	useEffect(() => {
		if(featuresModule.alarmAnalyticsEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 151);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
			getRegion(authtoken);

		}
		getAllEvents(authtoken);
		handleGetMapdata(authtoken,reportFilter.regioninfoId,startCh,endCh);
		
	},[authtoken,reportFilter.regioninfoId,allocationId]);

	useEffect(()=>{
		getAlarm(1);
		loadGraph();
	},[authtoken]);

	
	let pipelineChainage = [];
	
	let odmeter = [];

	mapdata &&  mapdata.map((item) => {
		pipelineChainage.push({ value:item.pipelineChainage, label:item.pipelineChainage.toString() });
		pipelineChainages.push( (parseFloat(item.pipelineChainage/1000).toFixed(2)) + ' Kms');
	});

	mapdata &&  mapdata.map((item)=>{

		odmeter.push({
			value:item.ODMeter,
			label:item.ODMeter.toString()
		});
	});


	const getAlarm = (pagination)=>{

		setActiveExpRow([]);

		const related_alarm = reportFilter.related_alarm == false ? '&isparent=true' : '';
		
		let _pagination = { };
		_pagination.total = alarm_data_pagination.total ? alarm_data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		let data ={
			alarmSeverity: reportFilter.severity,
			allocationId: allocationId,
			endeventtime: `${moment.utc(endDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
			eventenddate: moment.utc(endDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
			eventstartdate: moment.utc(startDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
			eventtype: reportFilter.event && reportFilter.event.length > 0 ? reportFilter.event : ['all'],
			region_id: reportFilter.regioninfoId,
			starteventtime: `${moment.utc(startDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
			status: reportFilter.status,
			resolutionAdded:reportFilter.status==false ? false : null,
			// frequency: reportFilter.frequency,
			eventchannel: 'all',
			startChainage: reportFilter.startchainage,
			endChainage: reportFilter.endchainage,
			searchBy: reportFilter.searchby,
			firstOD:reportFilter.startODMeter,
			secondOD:reportFilter.endODMeter,
		};

		setURL({base: `${api}/alarm`, params: `sort[eventdate]=-1&sort[eventtime]=-1${related_alarm}`, data:data});
		dispatch({ type : REALTIME_ALARM_LIST});
		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/alarm?sort[eventdate]=-1&sort[eventtime]=-1${related_alarm}&limit=${_pagination.limit}&skip=${_pagination.skip}`,
			timeout: extendTimeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					alarm_data_pagination.total = response.data.total ? response.data.total : 0;
					alarm_data_pagination.limit = response.data.limit ? response.data.limit : 10;
					alarm_data_pagination.skip = response.data.skip ? response.data.skip : 0;
					alarm_data_pagination.current = pagination.current ? pagination.current : 1;
					alarm_data_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;
					dispatch({ type : REALTIME_ALARM_SUCCESS, payload : response.data.data});
					data = response.data;
				}else {
					dispatch({ type : REALTIME_ALARM_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : REALTIME_ALARM_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						  });
				}
			});
	};

	const key = (value) => {
		setModelOpen(true);
		dispatch({ type : REALTIME_ALARM_DETAILS, payload : value});
	};

	const handleModelCancel = () => {
		setModelOpen(false);
	};

	const handleModelOk = (value) => { 
		navigate(`alarmDetails/${value}`);
	};


	// Alarm Filter Starts

	const handleSearchBy = (e)=>{
		// if(e=='chainage'){
		// 	setFilter({...reportFilter,startODMeter:null});
		// 	setFilter({...reportFilter,endODMeter:null});
		// }
		// else{
		// 	setFilter({...reportFilter,startchainage:null});
		// 	setFilter({...reportFilter,endchainage:null});
		// }
		// setFilter({...reportFilter,searchby:e});
		setFilter({...reportFilter, searchby:e,startchainage:null,endchainage:null, startODMeter:null, endODMeter:null});

	};

	const handleSetregion = (e,options) =>{
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(admin_roles.includes(permission)) {
			setAllocationId(null);
			setFilter({...reportFilter,regioninfoId:e,startchainage:null,endchainage:null, startODMeter:null, endODMeter:null});

		} else {
			setFilter({...reportFilter,regioninfoId:options.region, startchainage:null,endchainage:null, startODMeter:null, endODMeter:null});
			setAllocationId(e);
			setStartCh(options.pipelineConfigFrom);
			setEndCh(options.pipelineConfigTo);
		}
	};

	const handleSelectEvent = (e)=>{
		setFilter({...reportFilter,event:e});
	};
	

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(new Date(dates[0] && dates[0].$d));
			setEndDate(new Date(dates[1] && dates[1].$d));
		} else {
			console.log('Clear');
		}
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const handleSetStatus = (e)=>{
		setFilter({...reportFilter,status:e});
	};

	const handleSetSeverity = (e)=>{
		setFilter({...reportFilter,severity:e});
	};

	const handleSetRelated = (e)=>{
		setFilter({...reportFilter,related_alarm:e});
	};

	const handleSetStartCH = (e)=>{
		setFilter({...reportFilter,startchainage:e});
	};

	const handleSetEndCH = (e)=>{
		setFilter({...reportFilter,endchainage:e});
	};

	const handleSetStartODMeter = (e)=>{
		setFilter({...reportFilter,startODMeter:e});
	};

	const handleSetEndODMeter = (e)=>{
		setFilter({...reportFilter,endODMeter:e});
	};

	const openShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(true);
	};
  
	const closeShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(false);
	};

	const HandleSearch = () =>{
		if(reportFilter.related_alarm == false) {
			setShowRelatedAlarm(false);
		} else {
			setShowRelatedAlarm(true);
		}
		getAlarm(1);
		loadGraph();
	};

	const loadGraph =()=> {
		dispatch({ type : GRAPH_DATA});
		let data ={
			alarmSeverity: reportFilter.severity,
			allocationId: allocationId,
			endeventtime: `${moment.utc(endDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
			eventenddate: moment.utc(endDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
			eventstartdate: moment.utc(startDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
			eventtype: reportFilter.event && reportFilter.event.length > 0 ? reportFilter.event : ['all'],
			// pidsId: reportFilter.pidsinfoId,
			region_id: reportFilter.regioninfoId,
			starteventtime: `${moment.utc(startDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
			status: reportFilter.status,
			resolutionAdded:false,
			// frequency: reportFilter.frequency,
			eventchannel: 'all',
			startChainage: reportFilter.startchainage,
			endChainage: reportFilter.endchainage,
			searchBy: reportFilter.searchby,
			firstOD:reportFilter.startODMeter,
			secondOD:reportFilter.endODMeter,
		};
		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url:  `${api}/alarm-analytics?limit=1000&isparent=true`,
			timeout: extendTimeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`,
				'Content-Type': 'application/json'
			},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if(response.status === 200) {
					dispatch({ type : GRAPH_DATA_SUCCESS, payload : response.data});
				} else {
					dispatch({ type : GRAPH_DATA_ERROR });
				}
			})
			.catch((error) => {
				dispatch({ type : GRAPH_DATA_ERROR });
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						  });
				}
			});
	};

	let minChartWidth = parseInt(alarmdata.graph1PipelineChainage && alarmdata.graph1PipelineChainage.length/20).toFixed(2);

	minChartWidth = (minChartWidth < 1) ? 1 : minChartWidth;

	let minChartWidth2 = parseInt(alarmdata.graph2Date && alarmdata.graph2Date.length/20).toFixed(2);

	minChartWidth2 = (minChartWidth2 < 1) ? 1 : minChartWidth2;


	// const handleGenerateView = () =>{

	// 	dispatch({ type : ALARM_VIEW});

	// 	let config = {
	// 		method: 'get',
	// 		maxBodyLength: Infinity,
	// 		url: `${api}/createalarmview
	//     `,
	// 		headers: { 
	// 			'Content-Type': 'application/json', 
	// 			'Authorization': `Bearer ${authtoken}`
	// 		},
	// 		data : ''
	// 	};
      
	// 	axios.request(config)
	// 		.then((response) => {
	// 			if (response.status === 200 || response.status === 201 || response.status === 204) {
	// 				Modal.success({
	// 					title: 'Alarm view generated' ,
	// 			  });
	// 				dispatch({ type : ALARM_VIEW_SUCCESS, payload : response.data});
	// 			}else {
	// 				dispatch({ type : ALARM_VIEW_ERROR});
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 			dispatch({ type : ALARM_VIEW_ERROR});
	// 		});
	// };

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const expandedRowRender = () => {
		if(related_alarms_datas.length > 0){
	
			const columns = [
				{
					title: 'Alarm Id',
					dataIndex: 'alarmId',
					key: 'alarmId',
					render: (text, record) => (
						<Space size="middle">
							{ record  && 
						<span>
							<span style={{ float: 'left', backgroundColor: record.alarmSeverity==3 ? 'red' :
								record.alarmSeverity==2 ? 'gold' : record.alarmSeverity==1 ? 'green' : record.alarmSeverity==0 ? 'violet' : '', 
							width: '3px', height: '20px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} />
						</span>
							}
							<text>{text}</text>
						</Space>
					),
				},
				{
					title: 'Activity',
					dataIndex: 'activity',
					key: 'activity',
				},
				{
					title: 'Description',
					dataIndex: 'description',
					key: 'description',
					render: (text) => (
						<Popover title="Description" content={text} trigger="hover">
							<Typography.Paragraph
								ellipsis={
									{
										rows: 2,
										expandable: false,
										symbol: ''
									}
								}
							>
								{text}
							</Typography.Paragraph>
						</Popover>
					)
				},
					
				{
					title: 'Date',
					dataIndex: 'date',
					key: 'date',
					// render: (_, date) => (
					// 	<Space size="middle">
					// 		<p title="Edit Alarm">{ new Date(date.date).toLocaleDateString('en-UK', {
					// 			day: '2-digit',
					// 			month: '2-digit',
					// 			year: 'numeric'
					// 		})}</p>
					// 	</Space>
					// )
				},
					
				{
					title: 'Chainage',
					dataIndex: 'chainage',
					key: 'chainage',
					render: (text) => (
						<Popover title="Chainage" content={text} trigger="hover">
							<Typography.Paragraph
								ellipsis={
									{
										rows: 2,
										expandable: false,
										symbol: ''
									}
								}
							>
								{text}
							</Typography.Paragraph>
						</Popover>
					)
				},
				{
					title: 'Duration',
					dataIndex: 'duration',
					key: 'duration',
				},
				{
					title: 'Fiber Length (m)',
					dataIndex: 'fibrelength',
					key: 'fibrelength',
				},
					
					
			];
	
			return <Table onRow={() => ({
				style: {
					background: '#faf7f0',
				}
			})} columns={columns} dataSource={related_alarms_datas} pagination={false} 
			scroll={{
				y: 240,
			}} />;
		}else{
			return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
	
		}
			
	};
	
	const handleRealtedAlarm = (e,options) =>{
		setRelatedAlarms([]);
		activeExpRow === options.key ? setActiveExpRow() : setActiveExpRow(options.key);
		if(e==true){
			setAlarmDetails({id : options.id, pidsinfoId: options.pidsinfoId});
		}
	};
	
	useEffect(()=>{
		if((alarmdetails && alarmdetails.id != undefined) && (alarmdetails && alarmdetails.id !== null) && (alarmdetails && alarmdetails.id != 'undefined')){
			dispatch({ type: RELATED_ALARMS_ANALYTICS });
			let data = JSON.stringify({
				id: alarmdetails.id,
				pidsinfoId: alarmdetails.pidsinfoId,
				regioninfoId:null,
				allocationId: null,
				
			});
		
			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/relatedalarm`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
			
			axios.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch({ type : RELATED_ALARMS_ANALYTICS_SUCCESS});
						setRelatedAlarms(response.data);
							
					}else {
						dispatch({ type : RELATED_ALARMS_ANALYTICS_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : RELATED_ALARMS_ANALYTICS_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});
	
		}
	
	},[alarmdetails]);
	
	let related_alarms_datas = [];
		
	if(relatedAlarms.length > 0){
		relatedAlarms.map( (item, index) => {
			const datetime = datetimeCovertertolocal(item.datetime);
			const newdate = datetime.localdate + ' ' +  datetime.localtime;
			related_alarms_datas.push({
				key: index.toString(),
				alarmId: item.alarm_prm_id,
				severity:item.alarmSeverity && item.alarmSeverity==3 ? 'RED' :  item.alarmSeverity==2 ? 'AMBER' : item.alarmSeverity==1 ? 'GREEN' : item.alarmSeverity==0 ? 'CLEAR' : '',
				activity: item.eventType,
				description:item.ruleDesc,
				date:newdate,
				duration:item.duration ? (item.duration/60).toFixed(2) + ' min' : null,
				channel: item.channel_name,
				chainage: item.locationDetails ? item.locationDetails.info : null,
				locationDetails:item.locationDetails && item.locationDetails,
				alarmSeverity:item.alarmSeverity,
				// pidsname:pidsData && pidsData.map((pids) => { if (item.pidsinfoId === pids.id){ return pids.name.toUpperCase();} }),
				region : regiondata && regiondata.map((region) => { if (item.regioninfoId[0] === region.id){ return region.regionName.toUpperCase();} }),
				fibrelength: item.odMeter,
			});
	
		});
	}else{
		related_alarms_datas = [];
	}

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.alarmanalyticscontent1 ? langdata.alarmanalyticscontent1:'This feature allow the user to sort and filter alarm details by chainage and fiber length wise for precise analysis. Dive deeper with graphical representations showcasing alarm counts per chainage and day, providing invaluable insights at a glance.'}</p>
		</Card>
	);

	return (
		<>
			<Row style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center'
			}} >

				<Col span={12}>
					<Row>
						<Space>
							<FileTextOutlined style={{fontSize:'30px'}}/>
							{/* </Col> */}
						</Space>
						<Space>&nbsp;&nbsp;</Space>
					
						{/* <Col style={{marginLeft:'4px'}} span={19}> */}
						<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.AlarmAnalytics ? langdata.AlarmAnalytics:'Alarm Analytics'}</Title>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="end">
						<Col>
							{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Region:</Text> */}
							<Space>
								<Typography>
									<Popover title={langdata && langdata.AlarmAnalytics ? langdata.AlarmAnalytics:'Alarm Analytics'}  content={infoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover>
								</Typography>
							</Space>
						</Col>
					</Row>
				</Col>
				
			</Row>



			<Row style={{
				marginTop: '0px',
				marginBottom: '0px',
				marginLeft: '10px',
				marginRight: '10px',
				// paddingTop: '10px',
				paddingBottom: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				justifyContent:'left', display:'flex', alignItems:'left'
			}}>



				<Row style={{ marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

								<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									{langdata && langdata.Searchby ? langdata.Searchby:'Search by'}:
								</Space>
								<Space>&nbsp;</Space>

								<Space>
									<Select Item
										style={{ minWidth: '180px', maxWidth:'180px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Select ? langdata.Select:'Select'}
										optionFilterProp="children"
										value={reportFilter.searchby}
										options={searchValue}
										onSelect={handleSearchBy}
									/>
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row>




				<Row style={{marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}} >


					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>

						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
								<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									{langdata && langdata.Allocations ? langdata.Allocations:'Allocations'}:
								</Space>
								<Space>&nbsp;</Space>

								<Space>
									<Select Item
										style={{ minWidth: '180px', maxWidth:'180px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion:'Select Region'}
										optionFilterProp="children"
										defaultValue={null}
										options={regions}
										onSelect={(e, options)=>handleSetregion(e, options)}
									/>
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
						
					</Col>	

				</Row>



				<Row style={{ marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>


								{reportFilter && reportFilter.searchby == 'chainage' ? ( 
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>{langdata && langdata.StartChainage ? langdata.StartChainage:'Start Chainage'}:</Space>
								) : (
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>{langdata && langdata.StartODMeter ? langdata.StartODMeter:'Start ODMeter'}:</Space>
								)}
								<Space>&nbsp;</Space>

								{reportFilter && reportFilter.searchby == 'chainage' ? ( 
									<Space>
										<Select Item
											style={{ minWidth: '180px', maxWidth:'180px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectStartChainage ? langdata.SelectStartChainage:'Select Start Chainage'}
											optionFilterProp="children"
											value={reportFilter.startchainage}
											options={pipelineChainage}
											onSelect={handleSetStartCH}
										/>	
									</Space>
								) : (
									<Space>
										<Select Item
											style={{ minWidth: '180px', maxWidth:'180px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectStartODMeter ? langdata.SelectStartODMeter:'Select Start ODMeter'}
											optionFilterProp="children"
											value={reportFilter.startODMeter}
											options={odmeter}
											onSelect={handleSetStartODMeter}
										/>	
									</Space>
								)}
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row>



				<Row style={{ marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

								{reportFilter && reportFilter.searchby == 'chainage' ? ( 
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>{langdata && langdata.EndChainage ? langdata.EndChainage:'End Chainage'}:</Space>
								) : (
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>{langdata && langdata.EndODMeter ? langdata.EndODMeter:'End ODMeter'}:</Space>
								)}
								<Space>&nbsp;</Space>

								<Space>
									{reportFilter && reportFilter.searchby == 'chainage' ? ( 
										<Select
											style={{ minWidth: '180px', maxWidth:'180px' }}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectEndChainage ? langdata.SelectEndChainage:'Select End Chainage'}
											optionFilterProp="children"
											value={reportFilter.endchainage}
											options={pipelineChainage}
											onSelect={handleSetEndCH} />
									) : (
										<Select
											style={{ minWidth: '180px', maxWidth:'180px' }}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectEndODMeter ? langdata.SelectEndODMeter:'Select End ODMeter'}
											optionFilterProp="children"
											value={reportFilter.endODMeter}
											options={odmeter}
											onSelect={handleSetEndODMeter} />
									)}
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row>




				<Row style={{marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}} >


					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>

						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
								<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									{langdata && langdata.Activity ? langdata.Activity:'Activity'}:
								</Space>
								<Space>&nbsp;</Space>

								<Space>
									<Select Item
										style={{ minWidth: '180px', maxWidth:'180px'}}
										mode="multiple"
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectEvent ? langdata.SelectEvent:'Select Events'}
										optionFilterProp="children"
										options={allevents}
										onChange={handleSelectEvent}               
										defaultValue={['all']}
									/>	
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
						
					</Col>	

				</Row>



				<Row style={{ marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

								<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									{langdata && langdata.AnomalyStatus ? langdata.AnomalyStatus :'Anomaly Status'}:
								</Space>
								<Space>&nbsp;</Space>

								<Space>
									<Select Item
										style={{ minWidth: '180px', maxWidth:'180px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectStatus ? langdata.SelectStatus :'Select Status'}
										optionFilterProp="children"
										value={reportFilter.status}
										options={status}
										onSelect={handleSetStatus}
									/>
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row>



				<Row style={{ marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

								<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									{langdata && langdata.AnomalySeverity ? langdata.AnomalySeverity :'Anomaly Severity'}:
								</Space>
								<Space>&nbsp;</Space>

								<Space>
									<Select Item
										style={{ minWidth: '180px', maxWidth:'180px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectSeverity ? langdata.SelectSeverity :'Select Severity'}
										optionFilterProp="children"
										value={reportFilter.severity}
										onSelect={handleSetSeverity}                  
										options={severity}
									/>
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row>




				<Row style={{marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}} >


					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>

						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
								<Space style={{textAlign:'right', width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>
									{langdata && langdata.Includerelatedalarms ? langdata.Includerelatedalarms:'Include related alarms'}:
								</Space>
								<Space>&nbsp;</Space>

								<Space>
									<Select Item
										style={{ minWidth: '180px', maxWidth:'180px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectAlarmType ? langdata.SelectAlarmType :'Select Alarm Type'}
										optionFilterProp="children"
										value={reportFilter.related_alarm}
										options={related_alarm}
										onSelect={handleSetRelated}
									/>
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
						
					</Col>	

				</Row>



				<Row style={{ marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

								<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									{langdata && langdata.Date ? langdata.Date:'Date'}:
								</Space>
								<Space>&nbsp;</Space>

								<Space>
									<Tooltip title={moment(startDate).format(settingsdateformat) + ' - ' + moment(endDate).format(settingsdateformat)} placement='bottom'>
										<RangePicker onChange={onRangeChange} disabledDate={disabledDate} allowClear={false} format={settingsdateformat}
											defaultValue={[dayjs(moment().format('YYYY-MM-DD'), dateFormat), dayjs(moment().format('YYYY-MM-DD'), dateFormat)]} 
											style={{ minWidth: '180px', maxWidth:'180px'}} />
									</Tooltip>
								</Space>
									
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row>


				{/* <Row style={{marginTop:'30px', justifyContent:'left', display:'flex', alignItems:'left'}} >


					<Col style={{justifyContent:'left', display:'flex', alignItems:'left'}} span={24}
					>

						<Space>
							<Row  style={{justifyContent:'left', display:'flex', alignItems:'left'}}>

								<Space style={{width:'160px', justifyContent:'left', display:'flex', alignItems:'left'}}>

									&nbsp;
								</Space>
								<Space>&nbsp;</Space>

								<Space style={{width:'360px', justifyContent:'left', display:'flex', alignItems:'left'}}>
									<Button type="primary" title="Search Report" onClick={HandleSearch}><SearchOutlined />Search</Button>

								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>

					</Col>	

				</Row> */}
				{/* <Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

								<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									&nbsp;
								</Space>
								<Space>&nbsp;</Space>

								<Space style={{width:'360px'}}>
									<Button type="primary" title="Search Report" onClick={HandleSearch}><SearchOutlined />Search</Button>

								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row> */}


				<Col span={24}>

					

					<Row style={{ marginTop:'20px', justifyContent:'left', display:'flex', alignItems:'left'}}>
						<Col style={{justifyContent:'left', display:'flex', alignItems:'left'}} span={24}
						>
							<Space>
								<Row  style={{justifyContent:'left', display:'flex', alignItems:'left'}}>

									<Space style={{width:'160px', justifyContent:'left', display:'flex', alignItems:'left'}}>

									&nbsp;
									</Space>
									<Space>&nbsp;</Space>

									<Space style={{justifyContent:'left', display:'flex', alignItems:'left', width:'360px'}}>
										<Button type="primary" title="Search Report" onClick={HandleSearch}><SearchOutlined />{langdata && langdata.Search ? langdata.Search:'Search'}</Button>


									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						</Col>	
					</Row>

				</Col>

			</Row>

			

			{view && view === 'List' ?
				<Content>
					<Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
					style={{
						margin: '10px',
						marginBottom:'0px',
						marginTop:'10px',
						// paddingLeft: '10px',
						// paddingRight: '10px',
						padding:'10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
					>
						<Col className="gutter-row" span={8}>
							<Radio.Group value={view} onChange={(e) => setSize(e.target.value)}>
								<Radio.Button value="List">{langdata && langdata.List ? langdata.List:'List'}</Radio.Button>
								<Radio.Button value="Graph">{langdata && langdata.Graph ? langdata.Graph:'Graph'}</Radio.Button>
							</Radio.Group>
						</Col>
						<Col span={16}>
           					&nbsp;
						</Col>
					</Row>

					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24} style={{ margin : '10px'}}>
							{showRelatedAlarm == false ?
								<Table 
									scroll={{
										x: 900,
									}}
									columns={columns} dataSource={realtimeAlarmList}
									pagination = { realtimeAlarmList.length > 0 ? alarm_data_pagination : 0 }
									onChange = { getAlarm }
									expandable={{
										expandedRowKeys: activeExpRow,
										expandedRowRender,
										onExpand:(record,event)=>{handleRealtedAlarm(record,event);},
									}} />
								: 
								<Table 
									scroll={{
										x: 900,
									}}
									columns={columns} dataSource={realtimeAlarmList}
									pagination = { realtimeAlarmList.length > 0 ? alarm_data_pagination : 0 }
									onChange = { getAlarm }
								/>
							}
						</Col>
					</Row>

					<Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
						justifyContent:'center',
						display:'flex'
					}} 
					>
						<Col style={{
							alignItems:'left',
							justifyContent:'left',
							display:'flex',
							padding:'10px'
						}}  className="gutter-row" span={24}>
							<Button disabled={realtimeAlarmList && realtimeAlarmList.length > 0 ? false : true} type="primary" title={langdata && langdata.DownloadReport ? langdata.DownloadReport:'Download Report'} onClick={openShowFetchAndDownloadModal}><DownloadOutlined />{langdata && langdata.Download ? langdata.Download:'Download'}</Button>
						</Col>

					</Row>
					{/* Fetch and Download Modal Starts */}
					<Modal
						title={langdata && langdata.DownloadReport ? langdata.DownloadReport:'Download Report'}
						centered
						destroyOnClose={true}
						open={showFetchAndDownloadModal}
						onOk={closeShowFetchAndDownloadModal}
						onCancel={closeShowFetchAndDownloadModal}
						footer={[

						]}
						width={540}
					>
						<Row gutter={16}>
							<Col span={24}>
								<Typography>
									{langdata && langdata.Totalnumberofrecords ? langdata.Totalnumberofrecords :'Total number of records'} : {alarm_data_pagination.total}
								</Typography>
							</Col>
						</Row>

						{alarm_data_pagination.total > 0 ? (
							<BatchDownloader total={alarm_data_pagination.total} url={url}/>
						) : (
							<>
								<Row gutter={16} style={{
									backgroundColor: '#d3d3d3',
									padding: '5px',
									marginTop: '10px'
								}}>
									<Col span={24}>
										<Typography>{langdata && langdata.Norecordsfound ? langdata.Norecordsfound :'No records found.'}</Typography>
									</Col>
								</Row>
								<br/>
							</>
          
						)}
					</Modal>
					{/* Fetch and Download Modal Modal Ends */}


					<Modal
						open={modelOpen}
						title={langdata && langdata.AlarmDetails ? langdata.AlarmDetails :'Alarm Details'}
						onOk={handleModelOk}
						onCancel={handleModelCancel}
						footer={[
							<Row style={{marginTop:'25px'}} key={'id'} >
								<Col style={{justifyContent:'left', display:'flex'}} span={6}>
									<Button  key={'id'}
										onClick={() => {
											navigator.clipboard.writeText(contenttext);
											const args = {
												message: `${langdata && langdata.Alarmdetailscopiedtoclipboard ? langdata.Alarmdetailscopiedtoclipboard:'Alarm details copied to clipboard'}`,
												description: '',
												duration: 1,
											};
											notification.open(args);
										}}
									
									>
										{langdata && langdata.CopyToClipboard ? langdata.CopyToClipboard:'Copy To Clipboard'}
									</Button>
								</Col>
								<Col span={18}>
									{
										// eslint-disable-next-line react/jsx-key
									}
									<Button onClick={handleModelCancel}>
										{langdata && langdata.Close ? langdata.Close:'Close'}
									</Button>
									{
										// eslint-disable-next-line react/jsx-key
									}
									<Button style={{marginLeft:'10px'}} type="primary" onClick={() => {navigate(( '/alarm-details'),{ state: { data: alarmdata.relatimealarmdata_details, path: 'alarm-analytics' } });}}>
										{langdata && langdata.Moreinfo ? langdata.Moreinfo:'More info'}
									</Button>
								</Col>

							</Row>
						]}
					>
						<Divider/>
						<Row
							gutter={{
								xs: 8,
								sm: 16,
								md: 24,
								lg: 32,
							}}>

							<Col span={12}>          
								<Typography>{langdata && langdata.Id ? langdata.Id:'Id'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.alarm_prm_id}</Typography>
								<Typography>{langdata && langdata.Activity ? langdata.Activity:'Activity'}: <b>{alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType}</b></Typography>
								<Typography>{langdata && langdata.Date ? langdata.Date:'Date'}: <b>{alarmdata && alarmdata.relatimealarmdata_details && 
								moment.utc(alarmdata.relatimealarmdata_details.datetime).local().format(settingsdateformat + ' ' + settingstimeformat)
								}
								</b>
								</Typography>
								<Typography>{langdata && langdata.Latitude ? langdata.Latitude:'Latitude'}: {alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.Lat}</Typography>
								<Typography>{langdata && langdata.Longitude ? langdata.Longitude:'Longitude'}: {alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.Long}</Typography>
								<Typography>{langdata && langdata.Region ? langdata.Region:'Region'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.regioninfoId && regiondata && regiondata.map((region) => { if (alarmdata.relatimealarmdata_details.regioninfoId === region.id) { return region.regionName.toUpperCase();} })}</Typography>
								<Typography>{langdata && langdata.PIDS ? langdata.PIDS:'PIDS'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.pidsinfoId && allPidsData && allPidsData.map((pids) => { if (alarmdata.relatimealarmdata_details.pidsinfoId === pids.value){ return pids.label.toUpperCase();} }) }</Typography>
								<Typography>{langdata && langdata.Channel ? langdata.Channel:'Channel'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.channel_name}</Typography>
								<Typography>{langdata && langdata.Chainage ? langdata.Chainage:'Chainage'}: {alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.info}</Typography>
								<Typography>{langdata && langdata.Fiberlength ? langdata.Fiberlength:'Fiber Length'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.odMeter}{' m'}</Typography>
								<Typography>{langdata && langdata.Duration ? langdata.Duration:'Duration'}: {alarmdata && alarmdata.relatimealarmdata_details && (alarmdata.relatimealarmdata_details.duration / 60).toFixed(2)} {' '}Min</Typography>
								<Typography style={{color:alarmdata.relatimealarmdata_details.alarmSeverity === 0 ? 'CLEAR':
									alarmdata.relatimealarmdata_details.alarmSeverity === 1 ? 'green':
										alarmdata.relatimealarmdata_details.alarmSeverity === 2 ? '#fa9e0a' :
											alarmdata.relatimealarmdata_details.alarmSeverity === 3 ? 'red' : 
												'black'}}>{langdata && langdata.Severity ? langdata.Severity:'Severity'}: {alarmdata && alarmdata.relatimealarmdata_details && 
          (alarmdata.relatimealarmdata_details.alarmSeverity === 0 ? 'CLEAR':alarmdata.relatimealarmdata_details.alarmSeverity === 1 ? 'GREEN':alarmdata.relatimealarmdata_details.alarmSeverity === 2 ? 'AMBER' :alarmdata.relatimealarmdata_details.alarmSeverity === 3 ? 'RED' : '' )
									}</Typography>
								<Typography>{langdata && langdata.EventProbability ? langdata.EventProbability:'Event Probability'}: <b>{alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventprobablity ? ((alarmdata.relatimealarmdata_details.eventprobablity)*100).toFixed(2): 0}%</b></Typography>
								<Typography>{langdata && langdata.Resolutionstatus ? langdata.Resolutionstatus:'Resolution status'}: <b>{alarmdata && alarmdata.relatimealarmdata_details && (alarmdata.relatimealarmdata_details.status)}</b></Typography>
								<Typography>{langdata && langdata.Remark ? langdata.Remark:'Remark'}: {alarmdata && alarmdata.relatimealarmdata_details && (alarmdata.relatimealarmdata_details.remarks)}</Typography>
							</Col>
							<Col span={12}> 
								<img src={alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.activityUrl} alt={`${alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType}`} align="right" />
							</Col>
							{/* <Col span={12}> 
								{alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType === 'Manual Digging' ? 
									<img src={mapImg} alt="manual digging" align="right" /> : 
									alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType === 'Machine Digging' ? 
										<img src={mapImgMachine} alt="Machine" align="right" /> : 
										alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType === 'Tunneling' ? 
											<img src={mapImgTunneling} alt="Tunneling" align="right" /> :
											alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType === 'Vehicle Movement' ? 
												<img src={VehicleModal} alt="Tunneling" align="right" /> : 
												alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType === 'Drums Beating' ? 
													<img src={DrumModal} alt="Tunneling" align="right" /> : 
													alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType === 'Clamping' ? 
														<img src={ClampingModal} alt="Tunneling" align="right" /> : 
														alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType === 'Manual Digging - Deeper (1.5 to 3 feets)' ? 
															<img width="300px" src={mapImgDeep} alt="Manual Digging - Deeper (1.5 to 3 feets)" align="right" /> : 
															alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType === 'Man Movement' ? 
																<img src={modalMan} alt="Man Movement" align="right" /> : 
																<img src={modalDefault} alt="Unclassified" align="right" /> }
							</Col> */}
						</Row>
					</Modal>
					<Spin fullscreen spinning={alarmdata.pending} />


				</Content>
  
				:


			//   Map View Starts

				<>
					<Content>
						<Row gutter={{
							xs: 8,
							sm: 16,
							md: 24,
							lg: 32,
						}}
						style={{
							margin: '10px',
							marginTop:'10px',
							//   paddingLeft: '10px',
							//   paddingRight: '10px',
							padding:'10px',
							// paddingTop:'0px',
							background: '#FFFFFF',
							alignItems:'center',
						}}
						>
							<Col className="gutter-row" span={12}>

								<Radio.Group value={view} onChange={(e) => setSize(e.target.value)}>
									<Radio.Button value="List">{langdata && langdata.List ? langdata.List:'List'}</Radio.Button>
									<Radio.Button value="Graph">{langdata && langdata.Graph ? langdata.Graph:'Graph'}</Radio.Button>
								</Radio.Group>
							</Col>
        
							<Col className="gutter-row" span={12}>
        &nbsp;
							</Col>
							{/* </Space> */}
						</Row>

				



						<div className="App" style={{textAlign: 'left', margin : '10px'}}>
							
							<div ref={ref}>
								<Card className="card-Shadow-dashboard" style={{overflow: 'scroll'}}>
									{ alarmdata && alarmdata.relatimealarmdata.length > 0 ? (
										<Chart height={500} width={(width - 40) * minChartWidth} options={options} series={series} type="line" />
									) : (
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
								</Card>
								<Card className="card-Shadow-dashboard" style={{overflow: 'scroll'}}>
									{ alarmdata && alarmdata.relatimealarmdata.length > 0 ? (
										<Chart height={500}  width={(width - 40) * minChartWidth2} options={options2} series={series2} type='bar'/>
									) : (
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
								</Card>
							</div>

						</div>


						
						<Spin fullscreen spinning={alarmdata.pending} />

					</Content>

				</>
    

      
			}
            


		</>
	);
}