import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR }  from './constants';
import { NOTIFICATIONS_LIST, NOTIFICATIONS_LIST_SUCCESS, NOTIFICATIONS_LIST_ERROR } from './constants';
import { NOTIFICATION_DETAILS, NOTIFICATION_DETAILS_SUCCESS, NOTIFICATION_DETAILS_ERROR } from './constants';
import { DELETE_NOTIFICATION, DELETE_NOTIFICATION_SUCCESS, DELETE_NOTIFICATION_ERROR } from './constants';

import dayjs from 'dayjs';

export default function notificationsReducer(state, action) {
	const dateFormat = 'YYYY/MM/DD';

	let notifications = [];
	let tlps = [];
	let pagination = {};
  
	switch (action.type) {
	case TLPS_LIST:
		return { ...state, loading: true, error: false };

	case TLPS_LIST_SUCCESS:
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.deviceId = item.deviceId ? item.deviceId : 0;
				data.pipelineChainage = item.pipelineChainage ? parseFloat(item.pipelineChainage)/1000 : 0;

				tlps.push(data);
			});

			let sortedTLPS = tlps.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, tlps: sortedTLPS, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length == 0) {
				return { ...state, tlps: tlps, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case TLPS_LIST_ERROR:
		return { ...state, loading: false, error: true };
      
	case NOTIFICATIONS_LIST:
		return { ...state, pending: true };
    
	case NOTIFICATIONS_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
		}

		if (action && action.payload) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.regioninfoId = item.regioninfoId ? item.regioninfoId : null;
				data.deviceId = item.deviceId ? item.deviceId : null;
				data.isNew = item.isNew ? 'YES' : 'NO';
				data.notificationType = item.notificationType ? parseInt(item.notificationType) : null;
				data.message = item.message ? item.message : '<Not Set>';
				data.createdAt = item.createdAt ? dayjs(item.createdAt).format(dateFormat) : '';
				data.updatedAt = item.updatedAt ? dayjs(item.updatedAt).format(dateFormat) : '';

				notifications.push(data);
			});
        
			return { ...state, notifications: notifications, pagination: pagination, pending: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length == 0) {
				return { ...state, notifications: notifications, pagination: pagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}
    
	case NOTIFICATIONS_LIST_ERROR:
		return { ...state, pending: false, error: true };
      
	case NOTIFICATION_DETAILS:
		return { ...state, pending: true, error: false };

	case NOTIFICATION_DETAILS_SUCCESS:
		return { ...state, details: action.payload, pending: false, error: false };

	case NOTIFICATION_DETAILS_ERROR:
		return { ...state, pending: false, error: true };

	case DELETE_NOTIFICATION:
		return { ...state, pending: false };

	case DELETE_NOTIFICATION_SUCCESS:
		return { ...state, pending: false, error: false };

	case DELETE_NOTIFICATION_ERROR:
		return { ...state, pending: false, error: true };

	default: 
		throw Error('Unknown action: ' + action.type);
      
	}
}