
export const WELL_ALGORITHM_INFO_UPDATE_DATA = 'WELL_ALGORITHM_INFO_UPDATE_DATA';

export const WELL_ALGORITHM_INFO_UPDATE = 'WELL_ALGORITHM_INFO_UPDATE';
export const WELL_ALGORITHM_INFO_UPDATE_SUCCESS = 'WELL_ALGORITHM_INFO_UPDATE_SUCCESS';
export const WELL_ALGORITHM_INFO_UPDATE_ERROR = 'WELL_ALGORITHM_INFO_UPDATE_ERROR';

export const WELL_ALGORITHM_INFO_CREATE = 'WELL_ALGORITHM_INFO_CREATE';
export const WELL_ALGORITHM_INFO_CREATE_SUCCESS = 'WELL_ALGORITHM_INFO_CREATE_SUCCESS';
export const WELL_ALGORITHM_INFO_CREATE_ERROR = 'WELL_ALGORITHM_INFO_CREATE_ERROR';

export const WELL_ALGORITHM_INFO_DELETE = 'WELL_ALGORITHM_INFO_DELETE';
export const WELL_ALGORITHM_INFO_DELETE_SUCCESS = 'WELL_ALGORITHM_INFO_DELETE_SUCCESS';
export const WELL_ALGORITHM_INFO_DELETE_ERROR = 'WELL_ALGORITHM_INFO_DELETE_ERROR';