import React, { useEffect, useReducer, useState } from 'react';

import { Row, Col, Button, Table, Modal, Divider, Spin, Typography, Breadcrumb, Space, message} from 'antd';
import { Content } from 'antd/es/layout/layout';

import '../../../App.css';
import 'leaflet/dist/leaflet.css';

// eslint-disable-next-line no-unused-vars
import { REALTIME_EVENT, REALTIME_EVENT_DETAILS, REALTIME_EVENT_ERROR, REALTIME_EVENT_SUCCESS } from './constants';
import axios from 'axios';
import reducer from './reducer';
import moment from 'moment';

// import VehicleModal from '../../../images/vehicleModal.png';
// import DrumModal from '../../../images/drumModal.png';
// import ClampingModal from '../../../images/clampingModal.png';
// import mapImg from '../../../images/mapImg.png';
// import mapImgDeep from '../../../images/ManualDeep.png';
// import mapImgMachine from '../../../images/JCBMap.png';
// import mapImgTunneling from '../../../images/TunnelingMap.png';
// import modalDefault from '../../../images/modalDefault.png';
// import modalMan from '../../../images/ManWalking.png';
import { useLocation, useNavigate } from 'react-router-dom';
import {BatchDownloader} from './downloader';
import { DownloadOutlined, HomeOutlined } from '@ant-design/icons';
import { useAuth } from '../../Context';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';




export default function EventsReport() {
	const [modelOpen, setModelOpen] = useState(false);
	const sort= -1;
	const navigate = useNavigate();
	const { state } = useLocation();

	const initalState = { realtimeevents: [], events: null, pending: false, error: false, realtime_events_details: null, pagination: { total: 0, limit: 10, skip: 0, current:1 } };
	const [data, dispatch] = useReducer(reducer, initalState);
  
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const [showFetchAndDownloadModal, setShowFetchAndDownloadModal] = useState(false);
	const [url, setURL] = useState(null);
	const {getRegion, regiondata, getPids, pidsData } = useAuth();

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		// getAllEvents(authtoken);
		loadEvents(1);
		getRegion(authtoken);
		getPids(authtoken);

	},[authtoken]);

	const loadEvents = (pagination) => {

		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		// const end = state && state.endTime != null ? state.endTime : moment(moment('00:00'), 'HH:mm').format('HH:mm');
		// const start = state && state.startTime ? state.startTime :  moment('00:00')
		// 	.subtract('minutes', 10)
		// 	.format('HH:mm');
		// const startDate = state && state.startDate ? state.startDate : moment().format('YYYY-MM-DD');
		// const Enddate = state && state.endDate ? state.endDate : moment().format('YYYY-MM-DD');
    
		// const starttime = moment(start, 'hh:mm:ss a').format('HH:mm:ss');
		// const endtime = moment(end, 'hh:mm:ss a').format('HH:mm:ss');
		// const utcstringstart = `${startDate} ${starttime}`;
		// const utcstringend = `${Enddate} ${endtime}`;

		// const dateutcstart = moment(utcstringstart, 'YYYY-MM-DD HH:mm:ss')
		// 	.utc(0)
		// 	.format('HH:mm');
		// const dateutcend = moment(utcstringend, 'YYYY-MM-DD HH:mm:ss')
		// 	.utc(0)
		// 	.format('HH:mm');
		// const dateutcenddate = moment(utcstringend, 'YYYY-MM-DD HH:mm:ss')
		// 	.utc(0)
		// 	.format('YYYY-MM-DD');
		// const dateutcstartdate = moment(utcstringstart, 'YYYY-MM-DD HH:mm:ss')
		// 	.utc(0)
		// 	.format('YYYY-MM-DD');

		let body = JSON.stringify({
			eventenddate: state && state.endDate ? state.endDate : moment.utc(new Date().setHours(23,59,59,999)).format('YYYY-MM-DD'),
			eventstartdate: state && state.startDate ? state.startDate : moment.utc(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD'),
			eventchannel: state && state.channel ? state.channel : 'all',
			eventtype: state && state.activity && state.activity.length > 0  ? state.activity: ['all'] ,
			starteventtime: state && state.startTime ? `${state.startTime}.000000` : `${moment.utc(new Date().setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
			endeventtime: state && state.endTime ? `${state.endTime}.000000` : `${moment.utc(new Date().setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
			region_id: state && state.regionId ? state.regionId : null,
			frequency: state && state.anomalyFrequency ? state.anomalyFrequency : null,
			allocationId : state && state.allocation ? state.allocation : null,
			pidsId:state && state.pids ? state.pids : null,

		});

		dispatch({ type : REALTIME_EVENT});
		setURL({base: `${api}/events`, params: `&sort=${sort}`, data:body});

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/events?limit=${_pagination.limit}&skip=${_pagination.skip}&sort=${sort}`,
			timeout: extendTimeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : body
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : REALTIME_EVENT_SUCCESS, payload : response.data, page:pagination.current});
				}else {
					dispatch({ type : REALTIME_EVENT_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : REALTIME_EVENT_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};

	const handleModelCancel = () => {
		setModelOpen(false);
	};

	const handleModelOk = (value) => { 
		navigate(`eventDetails/${value}`);
	};

	const columns = [
		{
			title: `${langdata && langdata.Id ? langdata.Id :'Id'}`,
			dataIndex: 'id',
			key: 'id',
			render: (_, record) => (

				<>	
					<Space size="middle">

						{ record  && 
			<span>
				<span style={{ float: 'left', backgroundColor:record.eventConfidence >= 0.9 && record.eventConfidence <= 1 ? 'red' :
					record.eventConfidence >= 0.8 && record.eventConfidence < 0.9 ? 'gold' : record.eventConfidence >= 0.5 && record.eventConfidence < 0.8 ? 'green' : 'violet' , 
				width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} />
			</span>

						}
						<text >
							<a title={langdata && langdata.Moreinfo ? langdata.Moreinfo:'More info'} style={{ color : 'black'}} onClick={()=>{key(record.id);}}>{record.id}</a>
							{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
						</text>
					</Space>
				</>
				// <Space size="middle">
				// 	<a title="More Info" style={{ color : 'black'}} onClick={()=>{key(record.id);}}>{record.id}</a>
				// 	{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				// </Space>
			)
		},
		{
			title: `${langdata && langdata.Activity ? langdata.Activity :'Activity'}`,
			dataIndex: 'eventType',
			key: 'eventType',
			render: (_, record) => (
				<Space size="middle">
					<a title={langdata && langdata.Moreinfo ? langdata.Moreinfo :'More Info'} onClick={()=>{key(record.id);}}>{record.eventType}</a>
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)
		},
		{
			title: `${langdata && langdata.Region ? langdata.Region :'Region'}`,
			dataIndex: 'region',
			key: 'region',
			render: (_, record) => (
				<>
					{regiondata && regiondata.map((region) => { if (record.regioninfoId === region.id){ return region.regionName.toUpperCase();} })}
				</>
			)
		},
		{
			title: `${langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}`,
			dataIndex: 'pids',
			key: 'pids',
			render: (_, record) => (
				<>
					{pidsData && pidsData.map((pids) => { if (record.pidsinfoId === pids.id){ return pids.name.toUpperCase();} })}
				</>
			)
		},
		{
			title: `${langdata && langdata.Channel ? langdata.Channel :'Channel'}`,
			dataIndex: 'eventChannel',
			key: 'eventChannel',
		},
		{
			title: `${langdata && langdata.Date ? langdata.Date :'Date'}`,
			dataIndex: 'eventDate',
			key: 'eventDate',   
		},
		{
			title: `${langdata && langdata.FiberLength ? langdata.FiberLength :'Fiber Length (m)'}`,
			dataIndex: 'odInMeter',
			key: 'odInMeter',
		},
		
		{
			title: `${langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}`,
			dataIndex: 'chainage',
			key: 'chainage',
		},
	];
  

	const key = (value) => {
		dispatch({ type : REALTIME_EVENT_DETAILS, payload : value});
		setModelOpen(true);
	};

	const openShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(true);
	};

	const closeShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(false);
	};

	return (
		<>
			<Content>
				<Row 
					style={{
						marginLeft:'15px',
						marginTop: '10px'
					}}            
				>
					<Breadcrumb
						items={[
							{
								href: '/dashboard',
								title: <HomeOutlined />,
							},
							{
								title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.EventsReport ? langdata.EventsReport :'Events Report'}</a> 
							},
							{
								title: `${langdata && langdata.EventsTable ? langdata.EventsTable :'Events Table'}`,
							},
						]}
					/>
				</Row>

				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24} style={{ margin : '10px'}}>
						<Table 
							scroll={{
								x: 900,
							}}
							columns={columns} dataSource={data.realtimeevents} pagination = { data.realtimeevents.length > 0 ? data.pagination : 0 } onChange={loadEvents} 
							// onRow={(record) => {
							// 	return {
							// 		onClick: () => { 
							// 			key(record.id);
							// 		}
							// 	};
							// }} 
						/>
					</Col>
					<Col style={{
						alignItems:'left',
						justifyContent:'left',
						display:'flex',
						padding:'10px'
					}}  className="gutter-row" span={24}>
						<Button disabled={data.realtimeevents && data.realtimeevents.length > 0 ? false : true} type="primary" title={langdata && langdata.DownloadReport ? langdata.DownloadReport :'Download Report'} onClick={openShowFetchAndDownloadModal}><DownloadOutlined />{langdata && langdata.Download ? langdata.Download :'Download'}</Button>
					</Col>
				</Row>
			</Content>
			<Modal
				open={modelOpen}
				title={langdata && langdata.EventDetails ? langdata.EventDetails :'Event Details'}
				onOk={handleModelOk}
				onCancel={handleModelCancel}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Button onClick={handleModelCancel}>
						{langdata && langdata.Close ? langdata.Close :'Close'}
					</Button>,
					// <Button type="primary" onClick={()=>handleModelOk(data.realtime_events_details.id)}>
					//   More info
					// </Button>,
					// eslint-disable-next-line react/jsx-key
					<Button type="primary" onClick={() => {navigate(( '/eventDetails'),{ state: { data: data.realtime_events_details, path: 'events' } });}}>
						{langdata && langdata.Moreinfo ? langdata.Moreinfo :'More info'}
					</Button>,
				]}
			>
				<Divider/>
				<Row
					gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}>
					<Col span={12}>          
						<Typography>{langdata && langdata.Id ? langdata.Id :'Id'}: {data && data.realtime_events_details && data.realtime_events_details.id}</Typography>
						<Typography>{langdata && langdata.Activity ? langdata.Activity :'Activity'}: <b>{data && data.realtime_events_details && data.realtime_events_details.eventType}</b></Typography>
						<Typography>{langdata && langdata.Date ? langdata.Date :'Date'}: <b>{data && data.realtime_events_details && data.realtime_events_details.eventDate }</b>
						</Typography>
						<Typography>{langdata && langdata.Region ? langdata.Region :'Region'}: {data && data.realtime_events_details && data.realtime_events_details.regioninfoId && regiondata && regiondata.map((region) => { if (data.realtime_events_details.regioninfoId === region.id) { return region.regionName.toUpperCase();} })}</Typography>
						<Typography>{langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}: {data && data.realtime_events_details && data.realtime_events_details.pidsinfoId && allPidsData && allPidsData.map((pids) => { if (data.realtime_events_details.pidsinfoId === pids.value){ return pids.label.toUpperCase();} }) }</Typography>
						<Typography>{langdata && langdata.Channel ? langdata.Channel :'Channel'}: {data && data.realtime_events_details && data.realtime_events_details.eventChannel}</Typography>
						<Typography>{langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}: {data && data.realtime_events_details && data.realtime_events_details.chainage}</Typography>
						<Typography>{langdata && langdata.Fiberlength ? langdata.Fiberlength :'Fiber Length'}: {data && data.realtime_events_details && data.realtime_events_details.odInMeter}{' m'}</Typography>
						<Typography>{langdata && langdata.Duration ? langdata.Duration :'Duration'}: {data && data.realtime_events_details && (data.realtime_events_details.duration / 60).toFixed(2)} Min</Typography>
						<Typography>{langdata && langdata.EventProbability ? langdata.EventProbability :'Event Probability'}: <b>{data && data.realtime_events_details && ((data.realtime_events_details.eventConfidence)*100).toFixed(2)}%</b></Typography>
					</Col>
					<Col span={12}> 
						<img src={data && data.realtime_events_details && data.realtime_events_details.activityUrl} alt={`${data && data.realtime_events_details && data.realtime_events_details.eventType}`} align="right" />
					</Col>
					{/* <Col span={12}> 
						{data && data.realtime_events_details && data.realtime_events_details.eventType === 'Manual Digging' ? 
							<img src={mapImg} alt="manual digging" align="right" /> : 
							data && data.realtime_events_details && data.realtime_events_details.eventType === 'Machine Digging' ? 
								<img src={mapImgMachine} alt="Machine" align="right" /> : 
								data && data.realtime_events_details && data.realtime_events_details.eventType === 'Tunneling' ? 
									<img src={mapImgTunneling} alt="Tunneling" align="right" /> : 
									data && data.realtime_events_details && data.realtime_events_details.eventType === 'Vehicle Movement' ? 
										<img src={VehicleModal} alt="Tunneling" align="right" /> : 
										data && data.realtime_events_details && data.realtime_events_details.eventType === 'Drums Beating' ? 
											<img src={DrumModal} alt="Tunneling" align="right" /> : 
											data && data.realtime_events_details && data.realtime_events_details.eventType === 'Clamping' ? 
												<img src={ClampingModal} alt="Tunneling" align="right" /> : 
												data && data.realtime_events_details && data.realtime_events_details.eventType === 'Manual Digging - Deeper (1.5 to 3 feets)' ? 
													<img width="300px" src={mapImgDeep} alt="Manual Digging - Deeper (1.5 to 3 feets)" align="right" /> : 
													data && data.realtime_events_details && data.realtime_events_details.eventType === 'Man Movement' ? 
														<img src={modalMan} alt="Man Movement" align="right" /> : 
														<img src={modalDefault} alt="Unclassified" align="right" /> }
					</Col> */}
				</Row>
			</Modal>

			{/* Fetch and Download Modal Starts */}
			<Modal
				title={langdata && langdata.DownloadReport ? langdata.DownloadReport :'Download Report'}
				centered
				destroyOnClose={true}
				open={showFetchAndDownloadModal}
				onOk={{closeShowFetchAndDownloadModal}}
				onCancel={closeShowFetchAndDownloadModal}
				footer={[

				]}
				width={540}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Typography>
							{langdata && langdata.Totalnumberofrecords ? langdata.Totalnumberofrecords :'Total number of records'} : {data.pagination && data.pagination.total}
						</Typography>
					</Col>
				</Row>

				{data.pagination && data.pagination.total > 0 ? (
					<BatchDownloader total={data.pagination && data.pagination.total} url={url}/>
				) : (
					<>
						<Row gutter={16} style={{
							backgroundColor: '#d3d3d3',
							padding: '5px',
							marginTop: '10px'
						}}>
							<Col span={24}>
								<Typography>{langdata && langdata.Norecordsfound ? langdata.Norecordsfound :'No records found.'}</Typography>
							</Col>
						</Row>
						<br/>
					</>
          
				)}
			</Modal>
			{/* Fetch and Download Modal Modal Ends */}

			<Spin fullscreen spinning={data.pending} tip={langdata && langdata.largeloadingmsg ? langdata.largeloadingmsg :'This process will take 2-3 minutes to complete. Please wait a moment.'}/>
		</>
	);
} 