import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Form,  Modal, Row, Select, Steps, TimePicker } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Context';
import dayjs from 'dayjs';
import moment from 'moment';
import { useFormik } from 'formik';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const steps = [
	{
		title: 'Before Simulation',
	},
	{
		title: 'Date & Time',
	},
	{
		title: 'After Simulation',
	},
];

export default function ViewSimulations () {
	// const { token } = theme.useToken();
	const navigate = useNavigate();
	const { state } = useLocation();
	const [current, setCurrent] = useState(0);
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	// const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	// const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const { getAllEvents, eventsData, getPids, pidsData, getChannels, channelData,  handleGetMapdata, mapdata } = useAuth();

	const yesOrNoOptions = [];
	const pidsList = [];
	const eventsList = [];
	const chainage = [];
	const odmeter = [];
	const channelList = [];

	const [intervalId, setIntervalId] = useState(null);
	const [nextButtonStatus, setNextButtonStatus] = useState(true);
	const [minimumDuration, setMinimumDuration] = useState(false);
	const [maximumDuration, setMaximumDuration] = useState(false);

	const simulationId = state.simulationId ? state.simulationId : null;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getAllEvents(authtoken);
		getPids(authtoken);
		handleGetMapdata(authtoken);
		getChannels(authtoken,state.simulation_data.pidsinfoId);
	},[authtoken, simulationId]);

	const next = () => {
		setCurrent(current + 1);
	};
	const prev = () => {
		setCurrent(current - 1);
	};
	const items = steps.map((item) =>{

		let translatedtitle = '';
		if(item.title=='After Simulation'){
			translatedtitle = langdata && langdata.AfterSimulation ? langdata.AfterSimulation : 'After Simulation';
		}
		else if(item.title=='Before Simulation'){
			translatedtitle = langdata && langdata.BeforeSimulation ? langdata.BeforeSimulation : 'Before Simulation';
		}
		else if(item.title=='Date & Time'){
			translatedtitle = langdata && langdata.DateandTime ? langdata.DateandTime : 'Date and Time';
		}

		return({
			key: item.title,
			title: translatedtitle,
		});
	} 
	);
	const contentStyle = {
		lineHeight: '460px',
		marginTop: 16,
		fontSize : '30px'
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handlePids = (value) => {
		formik.setFieldValue('channelId', ''); formik.setFieldValue('channelinfoName', null);
		getChannels(authtoken,value);
	};

	yesOrNoOptions.push({
		value : true,
		label : `${langdata && langdata.YES ? langdata.YES:'YES'}`
	},
	{
		value : false,
		label : `${langdata && langdata.NO ? langdata.NO:'NO'}`
	});

	pidsData && pidsData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name
		});
	});

	eventsData && eventsData.map((item) => {
		eventsList.push({
			value : item.eventType,
			label: item.eventType
		});
	});

	mapdata && mapdata.map((item) => {
		chainage.push({
			value : item.id,
			label : `${item.pipelineChainage} ( ${item.rouMarker} )`
		});
		odmeter.push({
			value:item.ODMeter,
			label:item.ODMeter.toString()
		});
	});

	channelData && channelData.map((item) => {
		channelList.push({
			value : item.channelId,
			label : item.name
		});
	});

	const handleCloseMinimumDuration = () => {
		setMinimumDuration(false);
		clearInterval(intervalId);
		setIntervalId(null);
		setNextButtonStatus(true);
	};

	const handleCloseMinimumModal = () => {
		setMinimumDuration(false);
	};

	const handleCloseMaximumDuration = () => {
		setMaximumDuration(false);
		clearInterval(intervalId);
		setIntervalId(null);
		setNextButtonStatus(true);
	};

	const handleCloseMaximumModal = () => {
		setMaximumDuration(false);
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues : {
			isChannelFreezed : state.simulation_data ? state.simulation_data.isChannelFreezed : null,
			isWaterfallobserved : state.simulation_data ? state.simulation_data.isWaterfallobserved : null ,
			isObservedSignalStrong : state.simulation_data ? state.simulation_data.isObservedSignalStrong : null ,
			activityType : state.simulation_data ? state.simulation_data.activityType : null ,
			activityName : state.simulation_data ? state.simulation_data.activityName : null ,
			channelId : state.simulation_data ? state.simulation_data.channelId : null ,
			channelinfoName : state.simulation_data ? state.simulation_data.channelinfoName : null ,
			pidsinfoId : state.simulation_data ? state.simulation_data.pidsinfoId : null,
			pidsinfoName : state.simulation_data ? state.simulation_data.pidsinfoName : null,
			startChainage : state.simulation_data ? state.simulation_data.startChainage : null,
			endChainage : state.simulation_data ? state.simulation_data.endChainage : null,
			startODMeter : state.simulation_data ? state.simulation_data.startODMeter : null,
			endODMeter : state.simulation_data ? state.simulation_data.endODMeter : null,
			alarmGenerated : state.simulation_data ? state.simulation_data.alarmGenerated : null,
			eventsGenerated : state.simulation_data ? state.simulation_data.eventsGenerated : null,
			activityPerformedContinuously : state.simulation_data ? state.simulation_data.activityPerformedContinuously : null,
			performedActivityIsSame : state.simulation_data ? state.simulation_data.performedActivityIsSame : null,

		},
		onSubmit : (values) => {
			console.log('val', values);

		}
	});

	return (
		<>
			<Content>
				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					// background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24} style={{ display : 'flex', justifyContent : 'center'}}>
						<Card style={{ width : '1400px', margin : '50px'}}>
							<Steps current={current} items={items} />
							<div style={contentStyle}>
								{steps[current].title == 'Before Simulation' ? 
									<Form layout="vertical" onSubmit={formik.handleSubmit}>
										<Row gutter={16}>
											<Col span={12}>
												<Form.Item  label={langdata && langdata.Channelfreezed ? langdata.Channelfreezed:'Channel freezed?'} name="isChannelFreezed" rules={[{ required: true }]}>
													<Select
														name="isChannelFreezed" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.Isthechannelfreezed ? langdata.Isthechannelfreezed:'Is the channel freezed?'}
														optionFilterProp="children"
														options= {yesOrNoOptions}
														onChange={(value) => formik.setFieldValue('isChannelFreezed', value)}
														value={formik.values.isChannelFreezed}
														defaultValue={formik.values.isChannelFreezed}
														disabled={true}
														required
													/>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label={langdata && langdata.Signalobservedinwaterfalldisplaypoststamping ? langdata.Signalobservedinwaterfalldisplaypoststamping:'Signal observed in waterfall display post-stamping'} name="isWaterfallobserved" rules={[{ required: true }]}>
													<Select
														name="isWaterfallobserved" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.Signalobservedinwaterfalldisplaypoststamping ? langdata.Signalobservedinwaterfalldisplaypoststamping:'Signal observed in waterfall display post-stamping'}
														optionFilterProp="children"
														options= {yesOrNoOptions}
														onChange={(value) => formik.setFieldValue('isWaterfallobserved', value)}
														value={formik.values.isWaterfallobserved}
														defaultValue={formik.values.isWaterfallobserved}
														disabled={true}
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={16}>
											<Col span={12}>
												{formik.values.isWaterfallobserved !== false ?
													<Form.Item label={langdata && langdata.Doestheobservedsignalpossessadequatesignalstrength ? langdata.Doestheobservedsignalpossessadequatesignalstrength:'Does the observed signal possess adequate signal strength?'} name="isObservedSignalStrong" rules={[{ required: true }]}>
														<Select
															name="isObservedSignalStrong" 
															showSearch
															filterOption={filterOption}
															placeholder={langdata && langdata.Doestheobservedsignalpossessadequatesignalstrength ? langdata.Doestheobservedsignalpossessadequatesignalstrength:'Does the observed signal possess adequate signal strength?'}
															optionFilterProp="children"
															options= {yesOrNoOptions}
															onChange={(value) => formik.setFieldValue('isObservedSignalStrong', value)}
															value={formik.values.isObservedSignalStrong}
															defaultValue={formik.values.isObservedSignalStrong}
															disabled={true}
														/>												
													</Form.Item>
													: null }
											</Col>
											<Col span={12}>
												<Form.Item label={langdata && langdata.Activitytype ? langdata.Activitytype:'Activity type'} name="activityType" rules={[{ required: true }]}>
													<Select
														name="activityType" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.Activitytype ? langdata.Activitytype:'Activity type'}
														optionFilterProp="children"
														options={eventsList}
														onChange={(value, item) => {formik.setFieldValue('activityType', value); formik.setFieldValue('activityName', item.label);}}
														value={formik.values.activityType}
														defaultValue={formik.values.activityName}
														disabled={true}
													/>												
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={16}>
											<Col span={12}>
												<Form.Item label={langdata && langdata.PIDS ? langdata.PIDS:'PIDS'} name="pidsinfoId" rules={[{ required: true }]}>
													<Select
														name="pidsinfoId" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.PIDS ? langdata.PIDS:'PIDS'}
														optionFilterProp="children"
														options={pidsList}
														onSelect={handlePids}
														onChange={(value, item) => {formik.setFieldValue('pidsinfoId', value); formik.setFieldValue('pidsinfoName', item.label);}}
														value={formik.values.pidsinfoId}
														defaultValue={formik.values.pidsinfoId}
														disabled={true}
													/>												
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label={langdata && langdata.Channeldetails ? langdata.Channeldetails:'Channel details'} name="channelId" rules={[{ required: true }]}>
													<Select
														name="channelId" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.Channeldetails ? langdata.Channeldetails:'Channel details'}
														optionFilterProp="children"
														options={channelList}
														onChange={(value, item) => {formik.setFieldValue('channelId', value); formik.setFieldValue('channelinfoName', item.label);}}
														value={formik.values.channelId}
														defaultValue={formik.values.channelId}
														disabled={true}
													/>												
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={16}>
											<Col span={12}>
												<Form.Item label={langdata && langdata.StartingChainage ? langdata.StartingChainage:'Starting Chainage'} name="startChainage" rules={[{ required: true }]}>
													<Select
														name="startChainage" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.StartingChainage ? langdata.StartingChainage:'Starting Chainage'}
														optionFilterProp="children"
														options={chainage}
														onChange={(value) => formik.setFieldValue('startChainage', value)}
														value={formik.values.startChainage}
														defaultValue={formik.values.startChainage}
														disabled={true}
													/>												
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label={langdata && langdata.EndingChainage ? langdata.EndingChainage:'Ending Chainage'} name="endChainage" rules={[{ required: true }]}>
													<Select
														name="endChainage" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.EndingChainage ? langdata.EndingChainage:'Ending Chainage'}
														optionFilterProp="children"
														options={chainage}
														onChange={(value) => formik.setFieldValue('endChainage', value)}
														value={formik.values.endChainage}
														defaultValue={formik.values.endChainage}
														disabled={true}
													/>												
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={16}>
											<Col span={12}>
												<Form.Item label={langdata && langdata.ODMeterFiberlengthStart ? langdata.ODMeterFiberlengthStart:'OD Meter (Fiber length) Start'} name="startODMeter" rules={[{ required: true }]}>
													<Select
														name="startODMeter" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.ODMeterFiberlengthStart ? langdata.ODMeterFiberlengthStart:'OD Meter (Fiber length) Start'}
														optionFilterProp="children"
														options={odmeter}
														onChange={(value) => formik.setFieldValue('startODMeter', value)}
														value={formik.values.startODMeter}
														defaultValue={formik.values.startODMeter}
														disabled={true}
													/>					
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label={langdata && langdata.ODMeterFiberlengthEnd ? langdata.ODMeterFiberlengthEnd:'OD Meter (Fiber length) End'} name="endODMeter" rules={[{ required: true }]}>
													<Select
														name="endODMeter" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.ODMeterFiberlengthEnd ? langdata.ODMeterFiberlengthEnd:'OD Meter (Fiber length) End'}
														optionFilterProp="children"
														options={odmeter}
														onChange={(value) => formik.setFieldValue('endODMeter', value)}
														value={formik.values.endODMeter}
														defaultValue={formik.values.endODMeter}
														disabled={true}
													/>												
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={16}>
											{/* <Col span={12}>
											<Form.Item label="Activity date" name='activityDate' >
												<DatePicker style={{
													width: '100%',
												}}
												name='activityDate' 
												onChange={(value) => formik.setFieldValue('activityDate', value)}
												/>									
											</Form.Item>
										</Col> */}
											
										</Row>
									</Form>
									: 
									steps[current].title == 'Date & Time' ?
										<>
						
											<Form layout="vertical" style={{ marginTop : '10px'}} onSubmit={formik.handleSubmit}>
												<Row gutter={16}>
													<Col span={12}>
														<Form.Item  label={langdata && langdata.StartDate ? langdata.StartDate:'Start Date'} rules={[{ required: true }]}>
															<DatePicker
																allowClear={false} format={settingsdateformat}
																defaultValue={state.simulation_data.startTime ? dayjs(moment(state.simulation_data.startTime).format(settingsdateformat), settingsdateformat) : null} 
																// value={startDate ? dayjs(moment(startDate).format(process.env.REACT_APP_DATE_FORMAT), process.env.REACT_APP_DATE_FORMAT) : null}
																// onChange={handleStartDate}
																disabled={true}
																style={{
																	width: '100%',
																}} />
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item label={langdata && langdata.StartTime ? langdata.StartTime:'Start Time'} >
															<TimePicker 
																// value={startTime ? dayjs(startTime, 'HH:mm:ss') : null} 
																defaultValue={state.simulation_data.startTime ? dayjs(moment(state.simulation_data.startTime).format('HH:mm:ss'), 'HH:mm:ss') : null}
																// onChange={handleStartTime}
																disabled={true}
																style={{
																	width: '100%',
																}}  />
														</Form.Item>
													</Col>
												</Row>
												<Row gutter={16}>
													<Col span={12}>
														<Form.Item label={langdata && langdata.EndDate ? langdata.EndDate:'End Date'} rules={[{ required: true }]}>
															<DatePicker
																allowClear={false} format={settingsdateformat}
																disabled={true}
																defaultValue={state.simulation_data.endTime ? dayjs(moment(state.simulation_data.endTime).format(settingsdateformat), settingsdateformat) : null} 

																// value={endDate ? dayjs(moment(endDate).format(process.env.REACT_APP_DATE_FORMAT), process.env.REACT_APP_DATE_FORMAT) : null}
																// onChange={handleEndDate}
																style={{
																	width: '100%',
																}} />										
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item label={langdata && langdata.EndTime ? langdata.EndTime:'End Time'} rules={[{ required: true }]} >
															<TimePicker 
																defaultValue={state.simulation_data.endTime ? dayjs(moment(state.simulation_data.endTime).format('HH:mm:ss'), 'HH:mm:ss') : null}

																// value={endTime ? dayjs(endTime, 'HH:mm:ss') : null} 
																disabled={true}

																// onChange={handleEndTime}
																style={{
																	width: '100%',
																}}  />										
														</Form.Item>
													</Col>
												</Row>
											</Form>
										</>
										:
										steps[current].title == 'After Simulation' ?
											<Form layout="vertical" onSubmit={formik.handleSubmit}>
												<Row gutter={16}>
													<Col span={12}>
														<Form.Item  label={langdata && langdata.Alarmgeneratedornot ? langdata.Alarmgeneratedornot:'Alarm generated or not?'} name="alarmGenerated" rules={[{ required: true }]}>
															<Select
																name="alarmGenerated" 
																showSearch
																filterOption={filterOption}
																placeholder={langdata && langdata.Alarmgeneratedornot ? langdata.Alarmgeneratedornot:'Alarm generated or not?'}
																optionFilterProp="children"
																options= {yesOrNoOptions}
																onChange={(value) => formik.setFieldValue('alarmGenerated', value)}
																value={formik.values.alarmGenerated}
																defaultValue={formik.values.alarmGenerated}
																disabled={true}

															/>
														</Form.Item>
													</Col>
													{formik.values.alarmGenerated !== true ?
														<Col span={12}>
															<Form.Item label={langdata && langdata.Eventsgeneratedornot ? langdata.Eventsgeneratedornot:'Events generated or not?'} name="eventsGenerated" rules={[{ required: true }]}>
																<Select
																	name="eventsGenerated" 
																	showSearch
																	filterOption={filterOption}
																	placeholder={langdata && langdata.Eventsgeneratedornot ? langdata.Eventsgeneratedornot:'Events generated or not?'}
																	optionFilterProp="children"
																	options= {yesOrNoOptions}
																	onChange={(value) => formik.setFieldValue('eventsGenerated', value)}
																	value={formik.values.eventsGenerated}
																	defaultValue={formik.values.eventsGenerated}
																	disabled={true}

																/>
															</Form.Item>
														</Col>
														: null }
												</Row>
												<Row gutter={16}>
													{formik.values.alarmGenerated === true ||  formik.values.eventsGenerated === true ?
														<Col span={12}>
															<Form.Item label={langdata && langdata.Generatedactivityissameasselected ? langdata.Generatedactivityissameasselected:'Generated activity is same as selected '} name="performedActivityIsSame" rules={[{ required: true }]}>
																<Select
																	name="performedActivityIsSame" 
																	showSearch
																	filterOption={filterOption}
																	placeholder={langdata && langdata.Generatedactivityissameasselected ? langdata.Generatedactivityissameasselected:'Generated activity is same as selected '}
																	optionFilterProp="children"
																	options= {yesOrNoOptions}
																	onChange={(value) => formik.setFieldValue('performedActivityIsSame', value)}
																	value={formik.values.performedActivityIsSame}
																	defaultValue={formik.values.performedActivityIsSame}
																	disabled={true}
																/>												
															</Form.Item>														
														</Col>
														: null }
													<Col span={12}>
														<Form.Item label={langdata && langdata.Activitycontinuouslyperformedornot ? langdata.Activitycontinuouslyperformedornot:'Activity continuously performed or not?'} name="activityPerformedContinuously" rules={[{ required: true }]}>
															<Select
																name="activityPerformedContinuously" 
																showSearch
																filterOption={filterOption}
																placeholder={langdata && langdata.Activitycontinuouslyperformedornot ? langdata.Activitycontinuouslyperformedornot:'Activity continuously performed or not?'}
																optionFilterProp="children"
																options= {yesOrNoOptions}
																onChange={(value) => formik.setFieldValue('activityPerformedContinuously', value)}
																value={formik.values.activityPerformedContinuously}
																defaultValue={formik.values.activityPerformedContinuously}
																disabled={true}

															/>												
														</Form.Item>
													</Col>
												</Row>
											</Form>
											: null
								}
                            
							</div>
							<div
								style={{
									marginTop: 24,
									display:'flex',
									justifyContent : 'center'
								}}
							>
								{current < steps.length - 1  && nextButtonStatus === true && (
									<Button style={{
										marginBottom : '20px',
										margin: '0 8px',
									}} onClick={() => navigate(-1)}>
										{langdata && langdata.Cancel ? langdata.Cancel:'Cancel'}
									</Button>
								)}

								{current === steps.length - 1 && (
									<Button style={{
										margin: '0 8px',
										marginBottom : '20px'
									}} onClick={() => navigate(-1)}>
										{langdata && langdata.Cancel ? langdata.Cancel:'Cancel'}
									</Button>
								)}

								{current > 0  && (
									<Button
										style={{
											margin: '0 8px',
											marginBottom : '20px'
										}}
										onClick={() => prev()}
									>
										{langdata && langdata.Previous ? langdata.Previous:'Previous'}
									</Button>
								)}
								{current < steps.length - 1  && nextButtonStatus === true && (
									<Button style={{
										margin: '0 8px',
										marginBottom : '20px'
									}} type="primary" onClick={() => next()}>
										{langdata && langdata.Next ? langdata.Next:'Next'}
									</Button>
								)}
								
								
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
			<Modal 
				open={minimumDuration} 
				onOk={handleCloseMinimumDuration} 
				onCancel={handleCloseMinimumModal}
			>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={23}>
						{langdata && langdata.Minimumsimulationactivitydurationis3minutesDoyoureallywanttostopthissimulation ? langdata.Minimumsimulationactivitydurationis3minutesDoyoureallywanttostopthissimulation:'Minimum simulation activity duration is 3 minutes. Do you really want to stop this simulation?'}
					</Col>
				</Row>
			</Modal>
			<Modal 
				open={maximumDuration} 
				onOk={handleCloseMaximumDuration} 
				onCancel={handleCloseMaximumModal}
			>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={23}>
						{langdata && langdata.Maximumsimulationactivitydurationis60minutesPleaseadjustthestartandstoptime ? langdata.Maximumsimulationactivitydurationis60minutesPleaseadjustthestartandstoptime:'Maximum simulation activity duration is 60 minutes. Please adjust the start and stop time.'}
					</Col>
				</Row>
			</Modal>
		</>
	);
}
