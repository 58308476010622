import { ACKNOWLEGEMENT_ALARM, ACKNOWLEGEMENT_ALARM_ERROR, ACKNOWLEGEMENT_ALARM_FILE_UPLOAD, ACKNOWLEGEMENT_ALARM_FILE_UPLOAD_ERROR, ACKNOWLEGEMENT_ALARM_FILE_UPLOAD_SUCCESS, ACKNOWLEGEMENT_ALARM_SUCCESS, REALTIME_ALARM_DETAILS, REALTIME_ALARM_ERROR, REALTIME_ALARM_LIST, REALTIME_ALARM_MAP_DATA, REALTIME_ALARM_MAP_DATA_ERROR, REALTIME_ALARM_MAP_DATA_SUCCESS, REALTIME_ALARM_SUCCESS, REALTIME_ALARM_UPDATE_DETAILS, REALTIME_COMMENTS, REALTIME_COMMENTS_ERROR, REALTIME_COMMENTS_SUCCESS, REALTIME_GROUPED_ALARM_ERROR, REALTIME_GROUPED_ALARM_LIST, REALTIME_GROUPED_ALARM_SUCCESS, REALTIME_PATCH_ALARM, REALTIME_PATCH_ALARM_ERROR, REALTIME_PATCH_ALARM_SUCCESS, REALTIME_POST_COMMENTS, REALTIME_POST_COMMENTS_ERROR, REALTIME_POST_COMMENTS_SUCCESS, REALTIME_REGION_ALARM_ERROR, REALTIME_REGION_ALARM_LIST, REALTIME_REGION_ALARM_SUCCESS, REALTIME_RELATED_ALARM_ERROR, REALTIME_RELATED_ALARM_LIST, REALTIME_RELATED_ALARM_SUCCESS, RELATED_ALARMS, RELATED_ALARMS_ERROR, RELATED_ALARMS_SUCCESS, RESOLUTION_ALARM, RESOLUTION_ALARM_ERROR, RESOLUTION_ALARM_SUCCESS, TLP_ALARM, TLP_ALARM_ERROR, TLP_ALARM_SUCCESS } from './constants';
import activityicons from '../activitytypes';

export default function alarmdata(state, action) {
	let data = [];
	switch (action.type) {
      
	case REALTIME_ALARM_LIST:
		return { ...state, pending: true };
	case REALTIME_ALARM_SUCCESS:
		return { ...state, relatimealarmdata: action.payload, pending: false };
	case REALTIME_ALARM_ERROR:
		return { ...state, error: true, pending:false };
	case REALTIME_ALARM_DETAILS:
		// eslint-disable-next-line array-callback-return
		state.mapdatas.map((item) => {
			if (item.newid == action.payload) {
				activityicons && activityicons.map((eventactivity)=>{
					if(item.eventType==eventactivity.type){
						data = item;
						data.activityUrl= eventactivity.type && item.eventType==eventactivity.type ? eventactivity.severity[item.alarmSeverity] : '-';
					}});
			}
		});
		return { ...state, relatimealarmdata_details : data};
	case REALTIME_ALARM_UPDATE_DETAILS:
		return { ...state, details: action.payload, pending: false };


	case REALTIME_GROUPED_ALARM_LIST:
		return { ...state, pending: true };
	case REALTIME_GROUPED_ALARM_SUCCESS:
		return { ...state, grouped_alarmdata_details: action.payload, pending: false };
	case REALTIME_GROUPED_ALARM_ERROR:
		return { ...state, error: true, pending: false };


	case REALTIME_RELATED_ALARM_LIST:
		return { ...state, pending: true };
	case REALTIME_RELATED_ALARM_SUCCESS:
		return { ...state, related_alarmdata_details: action.payload, pending: false };
	case REALTIME_RELATED_ALARM_ERROR:
		return { ...state, error: true, pending: false };

	case REALTIME_REGION_ALARM_LIST:
		return { ...state, pending: true };
	case REALTIME_REGION_ALARM_SUCCESS:
		return { ...state, region_alarmdata_details: action.payload, pending: false };
	case REALTIME_REGION_ALARM_ERROR:
		return { ...state, error: true, pending: false };


	case REALTIME_COMMENTS:
		return { ...state, pending: true };
	case REALTIME_COMMENTS_SUCCESS:
		return { ...state, region_comment_details: action.payload, pending: false };
	case REALTIME_COMMENTS_ERROR:
		return { ...state, error: true, pending: false };

	case REALTIME_POST_COMMENTS:
		return { ...state, pending: true };
	case REALTIME_POST_COMMENTS_SUCCESS:
		return { ...state, comment_post_details: action.payload, pending: false };
	case REALTIME_POST_COMMENTS_ERROR:
		return { ...state, error: true, pending: false };

	case REALTIME_PATCH_ALARM:
		return { ...state, pending: true };
	case REALTIME_PATCH_ALARM_SUCCESS:
		return { ...state, status_patch_details: action.payload, pending: false };
	case REALTIME_PATCH_ALARM_ERROR:
		return { ...state, error: true, pending: false };

	case ACKNOWLEGEMENT_ALARM:
		return { ...state, pending: true };
	case ACKNOWLEGEMENT_ALARM_SUCCESS:
		return { ...state, acknowlegement_alarm_details: action.payload, pending: false };
	case ACKNOWLEGEMENT_ALARM_ERROR:
		return { ...state, error: true, pending: false };

	case ACKNOWLEGEMENT_ALARM_FILE_UPLOAD:
		return { ...state, pending: true };
	case ACKNOWLEGEMENT_ALARM_FILE_UPLOAD_SUCCESS:
		return { ...state, acknowlegement_fileupload_details: action.payload, pending: false };
	case ACKNOWLEGEMENT_ALARM_FILE_UPLOAD_ERROR:
		return { ...state, error: true, pending: false };

	case RESOLUTION_ALARM:
		return { ...state, pending: true };
	case RESOLUTION_ALARM_SUCCESS:
		return { ...state, resolution_details: action.payload, pending: false };
	case RESOLUTION_ALARM_ERROR:
		return { ...state, error: true, pending: false };

	case TLP_ALARM:
		return { ...state, pending: true };
	case TLP_ALARM_SUCCESS:
		return { ...state, tlp_alarm: action.payload, pending: false };
	case TLP_ALARM_ERROR:
		return { ...state, error: true, pending: false };

	case RELATED_ALARMS:
		return { ...state, pending: true, error: false };
				
	case RELATED_ALARMS_SUCCESS:
		return { ...state, pending: false, error: false };
				
	case RELATED_ALARMS_ERROR:
		return { ...state, pending: false, error: true };

	case REALTIME_ALARM_MAP_DATA :
		return { ...state, pending: true };
	case REALTIME_ALARM_MAP_DATA_SUCCESS :
		return { ...state, pending: false, mapdatas : action.payload};
	case REALTIME_ALARM_MAP_DATA_ERROR :
		return { ...state, error: true, pending: false };

	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}