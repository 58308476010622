import moment from 'moment';
import { EVENTS, EVENTS_ERROR, EVENTS_SUCCESS, REALTIME_EVENT, REALTIME_EVENT_DETAILS, REALTIME_EVENT_ERROR, REALTIME_EVENT_SUCCESS } from './constants';
import activityicons from '../../activitytypes';


export default function RealtimeEventReducer(state, action) {
	let data = [];
	let pagination = {};
	let events = [];
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);
	switch (action.type) {
	case REALTIME_EVENT:
		return { ...state, pending: true };
	case REALTIME_EVENT_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
		}
		if (action.payload && action.payload.data && action.payload.data.length > 0){
			action.payload.data.map((item) => {
				const date = moment.utc(item.datetime).toDate();
				date.toString();
				const newdate = moment(date, 'YYYY-MM-DD HH:mm:ss').format(settingsdateformat + ' ' + settingstimeformat);
				events.push({
					key: item.id,
					id: item.id,
					eventType: item.eventType,
					eventChannel: item.channel_name,
					eventDate: newdate,
					chainage: item.locationDetails ? item.locationDetails.info : null,
					odInMeter: item.odMeter,
					duration: item.duration,
					eventConfidence: item.eventConfidence,
					latitude : item.locationDetails ? item.locationDetails.Lat : null,
					longitude : item.locationDetails ? item.locationDetails.Long : null,
					datetime:item.datetime,
					regioninfoId : item.regioninfoId,
					pidsinfoId : item.pidsinfoId
				});
			});
			return { ...state, realtimeevents: events, pending: false, pagination: pagination };
		} else {
			return { ...state, pending : false, error : true};
		}
	case REALTIME_EVENT_ERROR:
		return { ...state, error: true, pending : false };
	case REALTIME_EVENT_DETAILS:
		// eslint-disable-next-line array-callback-return
		state.realtimeevents.map((item) => {
			if (item.id == action.payload) {
				activityicons && activityicons.map((eventactivity)=>{
					if(item.eventType==eventactivity.type){
						let severity = item.eventConfidence >= 0.9 &&item.eventConfidence <= 1 ? 3 : item.eventConfidence >= 0.8 && item.eventConfidence < 0.9 ? 2 : item.eventConfidence >= 0.5 && item.eventConfidence < 0.8 ? 1 : 0;
						data = item;
						data.activityUrl= eventactivity.type && item.eventType==eventactivity.type ? eventactivity.severity[parseInt(severity)] : '-';
					}});
			}
		});
		return { ...state, realtime_events_details : data};
	case EVENTS:
		return { ...state, pending: true };
	case EVENTS_SUCCESS:
		return { ...state, events: action.payload, pending: false };
	case EVENTS_ERROR:
		return { ...state, error: true, pending : false };
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}