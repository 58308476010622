export const CHAINAGES_LIST = 'CHAINAGES_LIST';
export const CHAINAGES_LIST_SUCCESS = 'CHAINAGES_LIST_SUCCESS';
export const CHAINAGES_LIST_ERROR = 'CHAINAGES_LIST_ERROR';

export const AVAILABLE_CHAINAGES_LIST = 'AVAILABLE_CHAINAGES_LIST';
export const AVAILABLE_CHAINAGES_LIST_SUCCESS = 'AVAILABLE_CHAINAGES_LIST_SUCCESS';
export const AVAILABLE_CHAINAGES_LIST_ERROR = 'AVAILABLE_CHAINAGES_LIST_ERROR';

export const SNOOZED_POINTS_LIST = 'SNOOZED_POINTS_LIST';
export const SNOOZED_POINTS_LIST_SUCCESS = 'SNOOZED_POINTS_LIST_SUCCESS';
export const SNOOZED_POINTS_LIST_ERROR = 'SNOOZED_POINTS_LIST_ERROR';

export const SNOOZED_POINT_DETAILS = 'SNOOZED_POINT_DETAILS';
export const SNOOZED_POINT_DETAILS_SUCCESS = 'SNOOZED_POINT_DETAILS_SUCCESS';
export const SNOOZED_POINT_DETAILS_ERROR = 'SNOOZED_POINT_DETAILS_ERROR';

export const SAVE_SNOOZED_POINT = 'SAVE_SNOOZED_POINT';
export const SAVE_SNOOZED_POINT_SUCCESS = 'SAVE_SNOOZED_POINT_SUCCESS';
export const SAVE_SNOOZED_POINT_ERROR = 'SAVE_SNOOZED_POINT_ERROR';

export const UPDATE_SNOOZED_POINT = 'UPDATE_SNOOZED_POINT';
export const UPDATE_SNOOZED_POINT_SUCCESS = 'UPDATE_SNOOZED_POINT_SUCCESS';
export const UPDATE_SNOOZED_POINT_ERROR = 'UPDATE_SNOOZED_POINT_ERROR';

export const DELETE_SNOOZED_POINT = 'DELETE_SNOOZED_POINT';
export const DELETE_SNOOZED_POINT_SUCCESS = 'DELETE_SNOOZED_POINT_SUCCESS';
export const DELETE_SNOOZED_POINT_ERROR = 'DELETE_SNOOZED_POINT_ERROR';