import { VULNERABLE_DETAILS, VULNERABLE_INSPECTION_LIST, VULNERABLE_INSPECTION_LIST_ERROR, VULNERABLE_INSPECTION_LIST_SUCCESS, VULNERABLE_LIST, VULNERABLE_LIST_ERROR, VULNERABLE_LIST_SUCCESS } from './constants';

export default function vulnerablepatchdata(state, action) {

	switch (action.type) {
      
	case VULNERABLE_LIST:
		return { ...state, pending: true };
	case VULNERABLE_LIST_SUCCESS:
		return { ...state, vulnerable_patch_data: action.payload, pending: false };
	case VULNERABLE_DETAILS:
		return { ...state, details: action.payload, pending: false };
	case VULNERABLE_LIST_ERROR:
		return { ...state, error: true, pending: false };

	case VULNERABLE_INSPECTION_LIST:
		return { ...state, pending: true };
	case VULNERABLE_INSPECTION_LIST_SUCCESS:
		return { ...state, inspection_data: action.payload, pending: false };
	case VULNERABLE_INSPECTION_LIST_ERROR:
		return { ...state, error: true, pending: false };

      
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}