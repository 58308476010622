import { Breadcrumb, Button, Card, Checkbox, Col,Input, Modal, Row, Space, Spin, Switch, Tooltip, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useReducer, useState } from 'react';
import { AreaChartOutlined, HomeOutlined, PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
// import * as d3 from 'd3';
import Plot from 'react-plotly.js';
import { useAuth } from '../../Context';
import { useLocation, useNavigate } from 'react-router-dom';
// import {  FLOW_MONITORING_DTS_CROSS_WELL_LOADING } from './constants';
import flowMonitoringData from './reducer';
// import axios from 'axios';
// import { FLOW_MONITORING_DTS_CROSS_WELL } from './constants';
// import flowMonitoringData from './reducer';

const { Title } = Typography;

// const unpack = (rows, key) => rows.map(row => row[key]);

export default function DASCrossWellFlowMonitoring () {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	// const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	// const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	// const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	// const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	// const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	// const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	// const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	// const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const initalState = {details: null, pending: false, error: false, dasCrossData1:null, dasData:null, pressData:null, graphConfig : {}, dasCrossData3 : null, loading1 : false,
		loading2 : false,loading3 : false,loading4 : false,loading5 : false,loading6 : false,loading7 : false,loading8 : false };
	const [data, dispatch] = useReducer(flowMonitoringData, initalState);	
    
	const { wellinfo, getWellInfo} = useAuth();
	const { state } = useLocation();

	const [depthRangeFrom, setDepthRangeFrom] = useState(null);
	const [depthRangeTo, setDepthRangeTo] = useState(null);
	const [openWellModal, setWellModal] = useState(false);
	const [checked, setChecked] = useState([state.data && state.data.wellId]);
	// const [cardIndexArray, setCardIndexArray] = useState([]);
	const [index, setIndex] = useState();	
	const [crossWelldata, setCrossWellData] = useState({});
	const [dasSocket, setDasSocket] = useState([]);
	const [action, setAction] = useState();
	// const [spin, setSpin] = useState(false);
	const [graphScroll, setGraphScroll] = useState(false);

	const navigate = useNavigate();

	const array = [1,2,3,4,5,6,7,8];

	let tempWellId;

	// let tempstartDepth;
	// let tempendDepth;
	let tempdasurl;
	let wellId = state.data && state.data.wellId;    

	


	// let temppressurl;
	// let temppressEnabled;
	// let tempdtsEnabled;
	let dasEnabled1 =false;
	let socketDAS = null;
	let tempWellName;    
	
	const welldata =[];

	wellinfo && wellinfo.map((item) => {
		
		
		if(item&&item.flowmonitoringconfig){

		
			welldata.push({
				value : item.id,
				label : item.name.toUpperCase(),
				startDepth : item.startDepth,
				endDepth : item.endDepth,
				dasip: item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dasip,
				dasport : item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dasport,
				dtsip: item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dtsip,
				dtsport : item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dtsport,
				dtsminth : item && item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.minThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.defaultminThreshold ,
				dtsmaxth : item && item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.maxThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.defaultmaxThreshold,
				dasminth : item && item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.minThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.defaultminThreshold,
				dasmaxth : item && item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.maxThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.defaultmaxThreshold,
				presminth :item && item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.minThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.defaultminThreshold,
				presmaxth : item && item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.maxThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.defaultmaxThreshold,
				pressip: item && item.flowmonitoringconfig &&item.flowmonitoringconfig.pressureip,
				pressport : item && item.flowmonitoringconfig &&item.flowmonitoringconfig.pressureport,
				pressEnabled : item.pressureEnabled,
				dtsEnabled : item.dtsEnabled,
				dasEnabled : item.dasEnabled,
				wellname : item.name.toUpperCase()
			});
			if (item.id == wellId) {			
				tempWellId = item.id;
				// tempstartDepth = item.startDepth;
				// tempendDepth = item.endDepth;
				tempdasurl = `ws://${item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dasip}:${item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dasport}`;
				// tempdtsurl = `ws://${item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dtsip}:${item && item.flowmonitoringconfig &&item.flowmonitoringconfig.dtsport}`;
				// tempdtsminth = item && item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.minThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.defaultminThreshold;
				// tempdtsmaxth = item && item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.maxThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.tempThresholds && item.flowmonitoringconfig.tempThresholds.defaultmaxThreshold;
				// tempdasminth = item && item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.minThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.defaultminThreshold;
				// tempdasmaxth = item && item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.maxThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.ampThresholds && item.flowmonitoringconfig.ampThresholds.defaultmaxThreshold;
				// temppresminth = item && item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.minThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.defaultminThreshold;
				// temppresmaxth = item && item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholdsOverride ?  item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.maxThreshold : item.flowmonitoringconfig && item.flowmonitoringconfig.pressThresholds && item.flowmonitoringconfig.pressThresholds.defaultmaxThreshold;
				// temppressurl = `ws://${item && item.flowmonitoringconfig &&item.flowmonitoringconfig.pressureip}:${item && item.flowmonitoringconfig &&item.flowmonitoringconfig.pressureport}`;
				// pressEnabled1 = item.pressureEnabled;
				// dtsEnabled1 = item.dtsEnabled;
				tempWellName = item.name.toUpperCase();
				dasEnabled1 = item.dasEnabled;
			// setpressEnabled(item.pressureEnabled);
			// setdtsEnabled(item.dtsEnabled);
			// setdasEnabled(item.dasEnabled);
			}
	
		}

	});

	const [crossWellName, setWellName] = useState(tempWellName);
	const flowWellIds = JSON.parse(localStorage.getItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA')) ? JSON.parse(localStorage.getItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA')) :[tempWellId];
	const cardIndexArray = JSON.parse(localStorage.getItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_CARD_INDEX_ARRAY')) ? JSON.parse(localStorage.getItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_CARD_INDEX_ARRAY')) : [1];

	const crossWellDataObject = JSON.parse(localStorage.getItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA_OBJECT')) ? JSON.parse(localStorage.getItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA_OBJECT')) : [{
		wellname : tempWellName,
		wellId : tempWellId,
		wellUrl : tempdasurl,
		cardIndex : 1
	}];

	useEffect(() =>{
		setWellName(tempWellName);
	},[tempWellName]);

	useEffect(() => {
		
		if((tempdasurl) && (dasEnabled1 == true) && (crossWellName != null)){				

			webSocketDataDAS();
		}
	
	}, [tempdasurl, crossWellName]);		

	const webSocketDataDAS =()=>{
		// const socket = new WebSocket('ws://192.168.1.41:7891'); // Replace with your WebSocket URL
		// const socket1 = new WebSocket(dtssocketurl ? dtssocketurl : tempdtsurl); // Replace with your WebSocket URL
		// socket1.close();

		// dispatch({ type : FLOW_MONITORING_DTS_CROSS_WELL_LOADING});

		if(socketDAS==null){

			let dasArray = [];
			crossWellDataObject && crossWellDataObject.map((item) => {		
				
				dispatch({type: `FLOW_MONITORING_DAS_CROSS_WELL_LOADING_${item.cardIndex}`});

				// socketDTS = new WebSocket(dtssocketurl ? dtssocketurl : tempdtsurl);
				socketDAS = new WebSocket(item.wellUrl ? item.wellUrl : tempdasurl);
				// if(socket.readyState == WebSocket.OPEN){
				// 	socket.close();
				// }
				// setDtsSocket(...dtsSocket, socketDTS);
				// setDasSocket(oldArray => [...oldArray,socketDAS]);
				dasArray.push(socketDAS);
				// setSpin(true);		

				socketDAS.onopen = () => {
					console.log('WebSocket connection opened');
				// setSpin(true);
				};

				socketDAS.onmessage = (event) => {
					try {
						const newData = JSON.parse(event.data);
						// console.log('new Data', newData);
						dispatch({type: `FLOW_MONITORING_DAS_CROSS_WELL_${item.cardIndex}`, payload:newData});                    
			
						// setWebsocketDTSData(newData);
						// setSpin(false);

					} catch (error) {

						// setSpin(false);
						dispatch({type: `FLOW_MONITORING_DAS_CROSS_WELL_${item.cardIndex}`, payload:[]});                    
						console.error('Error parsing data:', error);
					}
				};

				socketDAS.onerror = (error) => {
				// setSpin(false);
					dispatch({type: `FLOW_MONITORING_DAS_CROSS_WELL_${item.cardIndex}`, payload:[]});                    
					console.error('WebSocket error:', error);
				};

				socketDAS.onclose = () => {
				// setTimeout(setSpin(false), 5000);
				// setTimeout(()=> {
				// 	webSocketDataDTS();
				// }, 10000);		
				// setSpin(false);
					dispatch({type: `FLOW_MONITORING_DAS_CROSS_WELL_${item.cardIndex}`, payload:[]});
					console.log('WebSocket connection closed');
				};		

				const handlePopState = () => {			
					// socketDAS.close();
					dasArray && dasArray.map((item) => {							
						item.close();
					});
					dispatch({type: `FLOW_MONITORING_DAS_CROSS_WELL_${item.cardIndex}`, payload:[]});
				};

				const handleUrlChange = () => {
					if (socketDAS) {
						// socketDAS.close();
						dasArray && dasArray.map((item) => {							
							item.close();
						});
						dispatch({type: `FLOW_MONITORING_DAS_CROSS_WELL_${item.cardIndex}`, payload:[]});
					}
				};
		
				window.addEventListener('popstate', handlePopState);

				const originalPushState = history.pushState;
				const originalReplaceState = history.replaceState;

				history.pushState = function (...args) {
					originalPushState.apply(this, args);
					handleUrlChange(); // Close socket on push
				};

				history.replaceState = function (...args) {
					originalReplaceState.apply(this, args);
					handleUrlChange(); // Close socket on replace
				};
		
				return () => {
					window.removeEventListener('popstate', handlePopState);
					// socketDAS.close();
					dasArray && dasArray.map((item) => {							
						item.close();
					});
					dispatch({type: `FLOW_MONITORING_DAS_CROSS_WELL_${item.cardIndex}`, payload:[]});
					history.pushState = originalPushState; // Restore original pushState
					history.replaceState = originalReplaceState; // Restore original replaceState
				};
			});

			setDasSocket(dasArray);
		}		
		// dispatch({ type : FLOW_MONITORING_DTS_CROSS_WELL_LOADING_FALSE});
	};

    


	// useEffect(() => {
	// 	setDepthRangeFrom(tempstartDepth);
	// 	setDepthRangeTo(tempendDepth);
	// },[ tempstartDepth, tempendDepth]);


	// useEffect(() => {
	// 	let params = '';		
	// 	if(wellId || tempWellId) {
	// 		params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		
	// 		let config = {
	// 			method: 'get',
	// 			maxBodyLength: Infinity,
	// 			url: `${api}/flowmonitoringconfig?${params}`,
	// 			headers: { 
	// 				Authorization:`Bearer ${authtoken}`	
	// 			}
	// 		};
		
	// 		axios.request(config)
	// 			.then((response) => {
	// 				if(response.status == 200) {
	// 					dispatch({ type : FLOW_MONITORING_DTS_CROSS_WELL, payload : response.data });                        
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				console.log(error);
	// 			});
	// 	}
	// },[wellId, tempWellId]);


	useEffect(() => {
		getWellInfo(authtoken);
	},[tempWellId]);


	

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);	


	const handleRangefrom = (e) => {				
		if(e.target.value) {
			setDepthRangeFrom(parseInt(e.target.value));

		} else {
			setDepthRangeFrom();
		}
	};

	const handleRangeTo = (e) => {
		if(e.target.value) {
			setDepthRangeTo(e.target.value);

		} else {
			setDepthRangeTo();
		}
	};		


	// const plotDtsData = [{

	// 	z:data && data.dtsCrossData1 && data.dtsCrossData1.data,
	// 	y:data && data.dtsCrossData1 && data.dtsCrossData1.depths,
	// 	// y:websocketdata && websocketdata.data ? [...websocketdata.data.keys()]: [10],
	// 	// // colorscale: customColorscale, // Use custom color scale
	// 	x: data && data.dtsCrossData1 && data.dtsCrossData1.times,
	// 	// z: data.z,
	// 	// colorscale: customColorscale, // Use custom color scale
	// 	// x: [1,2,3,4,5,6,7,8,9],
	// 	colorscale: 'Hot',
	// 	// colorbar: {
	// 	//   title: 'Intensity',
	// 	//   tickvals: [0, 0.25, 0.5, 0.75, 1],
	// 	//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
	// 	// },
	// 	type: 'heatmap',
	// 	reversescale:true,
	// 	hovertemplate : 'Time : %{x} <br> Depth : %{y} <br> Temperature : %{z} <extra></extra>'
	// },
	// ];

	// data && data.dtsCrossData1 && data.dtsCrossData1.anomaly_times  && data.dtsCrossData1.anomaly_times.map((item, index) => {
	// 	plotDtsData.push(
	// 		{
	// 			// type: 'scatter',
	// 			// z:websocketdata.data,
	// 			x: data && data.dtsCrossData1 && data.dtsCrossData1.anomaly_times ? [data && data.dtsCrossData1 && data.dtsCrossData1.anomaly_times[index], data && data.dtsCrossData1 && data.dtsCrossData1.anomaly_times[index]] : [],
	// 			y: data && data.dtsCrossData1 && data.dtsCrossData1.anomaly_times ? [0, depthRangeTo] : [],
	// 			// text: data && data.dtsData && data.dtsData.anomaly_type ? [data.dtsData.anomaly_type] : '', 
	// 			mode: 'lines',
	// 			line: {
	// 				color: 'blue',
	// 				width: 2,
	// 				dash: 'dot',
	// 			},				
	// 			// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
				
						
	// 			// hoverlabel: {
	// 			// 	bgcolor: 'white', // Set the tooltip background color
	// 			// // font: {
	// 			// //   color: 'white', // Set the text color
	// 			// // }
	// 			// }
	// 		},
	// 		{
	// 			// type: 'scatter',
	// 			// z:websocketdata.data,
	// 			x: data && data.dtsCrossData1 && data.dtsCrossData1.anomaly_times ? [data && data.dtsCrossData1 && data.dtsCrossData1.anomaly_times[index], data && data.dtsCrossData1 && data.dtsCrossData1.anomaly_times[index]] : [],
	// 			y: data && data.dtsCrossData1 && data.dtsCrossData1.anomaly_times ? [depthRangeFrom + 50, depthRangeTo] : [],
	// 			text: data && data.dtsCrossData1 && data.dtsCrossData1.anomaly_type ? [data.dtsCrossData1.anomaly_type] : '', 
	// 			mode: 'text',			
	// 			// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
				
						
	// 			// hoverlabel: {
	// 			// 	bgcolor: 'white', // Set the tooltip background color
	// 			// // font: {
	// 			// //   color: 'white', // Set the text color
	// 			// // }
	// 			// }
	// 		}
	// 	);});

	const daslayout = {
		// title: 'Flow Monitoring',
		// title: {
		// 	text: langdata && langdata.DASSoundWaves ? langdata.DASSoundWaves :  'DAS Sound Waves',
		// 	font: {
		// 		size: 20,
		// 		// family: 'Arial, sans-serif',
		// 		// color: 'black',
		// 		weight: 'bold'
		// 	},
		// },
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
		yaxis: { title: langdata && langdata.Depth ? langdata.Depth :'Depth', 
			autorange: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? '' : 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ ],
			tick0: graphScroll == true ? 0 : null,           // Start the ticks at 0 (adjust as needed)
			dtick: graphScroll == true ? 50 : null,
		},
		showlegend:false,
		paper_bgcolor: 'transparent', // Remove background color of the entire chart
		plot_bgcolor: 'transparent',   // Remove background color of the plot area,
		height: graphScroll == true ? 10000 :0,
	};

	const handleWellSelect = (index) => {
		setIndex(index);
		setWellModal(true);        
	};    

	const closeshowPidsModal = () => {
		setWellModal(false);
		setIndex(null);
	};

	const onChange = (checkedValues) => {   
		setWellName(null);     		
		setChecked(checkedValues);			
	};
	
	const saveWellData = () => {
		if(action == true) {
			if(flowWellIds && flowWellIds.includes(crossWelldata.value)){
				Modal.warning({ title:  langdata && langdata.Pleaseselectanewwell ? langdata.Pleaseselectanewwell : 'Please select a new well.' });
			}
			else{
				dasSocket && dasSocket.map((item) => {
					item.close();
				});
				setDasSocket([]);
				// console.log('checked.pop()', checked.pop());
				const lastWellId = checked.pop();
				if(!flowWellIds.includes(lastWellId)){
					flowWellIds.push(lastWellId);
				}
				
				localStorage.setItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA' , JSON.stringify(flowWellIds));
				// setCardIndexArray([...cardIndexArray, index]);
				if(!cardIndexArray.includes(index)){
					cardIndexArray.push(index);
					localStorage.setItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_CARD_INDEX_ARRAY' , JSON.stringify(cardIndexArray));
					crossWellDataObject.push({
						wellname : crossWelldata.wellname,
						wellId : crossWelldata.value,
						wellUrl : `ws://${crossWelldata && crossWelldata.dasip}:${crossWelldata && crossWelldata.dasport}`,
						cardIndex : cardIndexArray.slice(-1)[0]
					});
					localStorage.setItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA_OBJECT' , JSON.stringify(crossWellDataObject));			
				}
				
				setWellName(crossWelldata.wellname);
			}
		} 			
		else {
			dasSocket && dasSocket.map((item) => {
				item.close();
			});
			setDasSocket([]);			
			const difference =  flowWellIds.filter(x => !checked.includes(x));	
									
			localStorage.setItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA' , JSON.stringify(checked));
			difference && difference.map((item) => {				
				if(!checked.includes(item)){
					const result = crossWellDataObject.find(({ wellId }) => wellId == item);		
					const removeCardIndex = result && result.cardIndex;		
					const index1 = cardIndexArray.indexOf(removeCardIndex);		
					cardIndexArray.splice(index1, 1);				
					crossWellDataObject.splice(crossWellDataObject.findIndex(a => a.wellId == item) , 1);
				}
			});		
			checked && checked.map((wellId) => {
				if(!flowWellIds.includes(wellId)) {
					if(!cardIndexArray.includes(index)){
						cardIndexArray.push(index);
						// localStorage.setItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_CARD_INDEX_ARRAY' , JSON.stringify(cardIndexArray));
						crossWellDataObject.push({
							wellname : crossWelldata.wellname,
							wellId : crossWelldata.value,
							wellUrl : `ws://${crossWelldata && crossWelldata.dasip}:${crossWelldata && crossWelldata.dasport}`,
							cardIndex : cardIndexArray.slice(-1)[0]
						});
						// localStorage.setItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA_OBJECT' , JSON.stringify(crossWellDataObject));			
					}
				}
			});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_CARD_INDEX_ARRAY' , JSON.stringify(cardIndexArray));			
			localStorage.setItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA_OBJECT' , JSON.stringify(crossWellDataObject));	
		
			setWellName(crossWelldata.wellname);
			// }
		}
		// crossWellDataObject && crossWellDataObject.map((item) => {
		// 	dispatch({type: `FLOW_MONITORING_DAS_CROSS_WELL_LOADING_${item.cardIndex}`, payload:{}});                    
		// });
		socketDAS = null;
		setWellModal(false);
	};

	const onChangeCheckBox = (value, options) => {		
		setAction(value.target.checked);
		if(value.target.checked == true){
			setCrossWellData(options);		
		}
	};	
	
	const handleCloseWell = (value) => {
		dasSocket && dasSocket.map((item) => {
			item.close();
		});
		setDasSocket([]);	
		const result = crossWellDataObject.find(({ cardIndex }) => cardIndex == value);		
		setWellName(result.wellname);
		const removeCardIndex = result && result.cardIndex;		
		const index1 = cardIndexArray.indexOf(removeCardIndex);		
		cardIndexArray.splice(index1, 1);				
		crossWellDataObject.splice(crossWellDataObject.findIndex(a => a.cardIndex == value) , 1);
		const removeWellIndex = result && result.wellId;		
		const index2 = flowWellIds.indexOf(removeWellIndex);		
		flowWellIds.splice(index2, 1);	
		localStorage.setItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_CARD_INDEX_ARRAY' , JSON.stringify(cardIndexArray));			
		localStorage.setItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA_OBJECT' , JSON.stringify(crossWellDataObject));	
		localStorage.setItem(dashboardtype.toUpperCase()+ '_FLOW_MONITORING_CROSS_WELL_DATA' , JSON.stringify(flowWellIds));
		webSocketDataDAS();
	};

	const handleSelectGraphScroll = (value) => {
		setGraphScroll(value);
	};
	
	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={8}>
						<Row>
							<Space>
								<AreaChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.CrossWellMonitoring ? langdata.DAS + ' ' + langdata.CrossWellMonitoring : 'DAS Cross Well Monitoring'}</Title>
							<Space>&nbsp;&nbsp;</Space>
						</Row>
					</Col>
					<Col span={16}>
						<Row justify="end">
							<Col>
								<>
									<Space>&nbsp;</Space>
									<Tooltip placement="bottom" title={langdata && langdata.EnterDepthRange ? langdata.EnterDepthRange:'Enter Depth Range'}>
										<Space.Compact size="middle" style={{ width : '180px', margin:'5px'}}>
											<Input type='number' value={depthRangeFrom} max={depthRangeFrom} min={depthRangeTo}  placeholder={langdata && langdata.DepthRangeFrom ? langdata.DepthRangeFrom: 'Depth Range From'} onChange={handleRangefrom} />
											<Input type='number' value={depthRangeTo} max={depthRangeFrom} min={depthRangeTo}  placeholder={langdata && langdata.DepthRangeTo ? langdata.DepthRangeTo: 'Depth Range To'} onChange={handleRangeTo}/>
										</Space.Compact>
									</Tooltip>
								</>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					// marginTop : '5px',
					paddingLeft: '10px',
					// paddingRight: '10px',
					// background: '#FFFFFF',
					alignItems:'center',
				}}  >
					<Col span={18}>
						<Breadcrumb
							items={[
								{
									href: '/dashboard',
									title: <HomeOutlined />,
								},
								{
									title: <a onClick={()=>{navigate(-2);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a>
								},
								{
									title: ( state && state.path === 'flow-monitoring' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.FlowMonitoring ? langdata.FlowMonitoring:'Flow Monitoring'}</a> :  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.FlowMonitoring ? langdata.FlowMonitoring:'Flow Monitoring'}</a>)
								},
								{
									title: `${langdata && langdata.CrossWellMonitoring ? langdata.CrossWellMonitoring : 'Cross Well Monitoring'}`,
								},
							]}
							// style={{ marginBottom : '10px'}}
						/>
					</Col>
					<Col span={6} style={{ display : 'flex', justifyContent : 'flex-end', marginLeft:'-10px'}}>
						<Tooltip title={langdata && langdata.Graphlayout ? langdata.Graphlayout:'Graph layout'}>
							<Switch style={{backgroundColor: graphScroll == false ? '' : 'green'}} checkedChildren={langdata && langdata.Scroll ? langdata.Scroll:'Scroll'} unCheckedChildren={langdata && langdata.Fixed ? langdata.Fixed:'Fixed'} checked={graphScroll} onClick={handleSelectGraphScroll}/>
						</Tooltip>
					</Col>

				</Row>
				<Row gutter={{
					xs: 8,
					sm: 16,
					md: 24,
					lg: 32,
				}} style={{
					marginTop:'20px',
					marginLeft:'0px',
					marginRight:'0px',
					marginBottom: '20px',
				}}>
					{data && array.map((item, index) => {
						const plotDasData = [{

							z:data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].data,
							y:data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].depths,
							// y:websocketdata && websocketdata.data ? [...websocketdata.data.keys()]: [10],
							// // colorscale: customColorscale, // Use custom color scale
							x: data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].times,
							// z: data.z,
							// colorscale: customColorscale, // Use custom color scale
							// x: [1,2,3,4,5,6,7,8,9],
							colorscale: 'Jet',
							// colorbar: {
							//   title: 'Intensity',
							//   tickvals: [0, 0.25, 0.5, 0.75, 1],
							//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
							// },
							type: 'heatmap',
							hovertemplate : 'Time : %{x} <br> Depth : %{y} <br> Temperature : %{z} <extra></extra>',
							// showscale:false,
							colorbar: {x: .98,   thickness: 10,
								// thicknessmode: 'pixels',
								// lenmode: 'fraction',
								outlinewidth: 0},
							orientation: graphScroll == true ? 'h' : '',
						},
						];
                    
						// data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].anomaly_times  && data[`dasCrossData${index+1}`].anomaly_times.map((item, itemIndex) => {
						// 	plotDasData.push(
						// 		{
						// 			// type: 'scatter',
						// 			// z:websocketdata.data,
						// 			x: data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].anomaly_times ? [data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].anomaly_times[itemIndex], data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].anomaly_times[itemIndex]] : [],
						// 			y: data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].anomaly_times ? [0, depthRangeTo] : [],
						// 			// text: data && data.dtsData && data.dtsData.anomaly_type ? [data.dtsData.anomaly_type] : '', 
						// 			mode: 'lines',
						// 			line: {
						// 				color: 'blue',
						// 				width: 2,
						// 				dash: 'dot',
						// 			},				
						// 			// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
                                    
                                            
						// 			// hoverlabel: {
						// 			// 	bgcolor: 'white', // Set the tooltip background color
						// 			// // font: {
						// 			// //   color: 'white', // Set the text color
						// 			// // }
						// 			// }
						// 		},
						// 		{
						// 			// type: 'scatter',
						// 			// z:websocketdata.data,
						// 			x: data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].anomaly_times ? [data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].anomaly_times[itemIndex], data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].anomaly_times[itemIndex]] : [],
						// 			y: data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].anomaly_times ? [depthRangeFrom + 50, depthRangeTo] : [],
						// 			text: data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].anomaly_type ? [data[`dasCrossData${index+1}`].anomaly_type] : '', 
						// 			mode: 'text',			
						// 			// name: data && data.dtsData && data.dtsData.anomaly_type ? data.dtsData.anomaly_type : '', // Legend entry name for scatter plot
                                    
                                            
						// 			// hoverlabel: {
						// 			// 	bgcolor: 'white', // Set the tooltip background color
						// 			// // font: {
						// 			// //   color: 'white', // Set the text color
						// 			// // }
						// 			// }
						// 		}
						// 	);});
                    
						return (
							<Space key = {index}>
								<Col style={{marginBottom:'20px'}} span={6}> 
									<Card hoverable
										style={{ minWidth:'370px', minHeight:'600px', maxWidth:'370px', maxHeight:'600px', cursor: 'pointer'}}
									>
										{/* <Card.Grid style={{minWidth:'370px', minHeight:'600px', maxWidth:'370px', maxHeight:'600px',}}> */}
										<Spin style={{ minWidth:'340px', minHeight:'500px', maxWidth:'340px', maxHeight:'500px',}} spinning={data[`loading${index+1}`]} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
											{cardIndexArray.includes(index+1) ?
												cardIndexArray && cardIndexArray.map((innerItem) => {														
													if(innerItem == index + 1) {	
														// if(data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`] ){																																											
														
														return (
															data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`]  ? 
															
																<>
																	<button
																		style={{ marginTop : '40px', marginRight : '15px'}}
																		onClick={()=>{handleCloseWell(innerItem);}} 
																		className="graphCloseButton" ><CloseOutlined /></button>


																	<p style={{fontSize : '10px', marginTop : '-20px'}}
																		// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
																	>
																		{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
																		<br /> {langdata && langdata.ChannelName ? langdata.ChannelName : 'Channel Name'} : {data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].channel_name}
																		<br /> {langdata && langdata.WellName ? langdata.WellName : 'Well Name'} : {data && data[`dasCrossData${index+1}`] && data[`dasCrossData${index+1}`].well_name}<br />
																	</p>
																	<Row>
																		<Col style={{overflowY: graphScroll == true ? 'scroll' : ''}}>
																			<Plot
																				key={innerItem}
																				data={plotDasData}
																				layout={daslayout}
																				style={{ width: graphScroll == true ? '390px' : '420px', height: graphScroll == true ? '600px' : '650px', marginLeft:'-50px', marginTop:'-85px' }}
																				config={{
																					displayModeBar: false,
																					displaylogo : false,
																					modeBarButtonsToRemove: [
																						'pan2d',
																						'zoom2d',
																						'select2d',
																						'zoomIn2d',
																						'zoomOut2d',
																						'autoScale2d',
																						'resetScale2d',
																						'hoverClosestCartesian',
																						'hoverCompareCartesian',
																						'zoom3d',
																						'pan3d',
																						'resetViews3d',
																						'hoverClosest3d',
																						'hoverCompare3d',
																						'orbitRotation',
																						'tableRotation',
																						'resetCameraDefault3d',
																						'resetCameraLastSave3d',
																						'lasso2d'
																					],
						
																				}}
																			/>
																		</Col>
																	</Row>
																</>
																: 
																null
														// <Row>
														// 	<Col span={24} style={{ alignItems : 'center', justifyContent : 'center', display : 'flex', minHeight:'500px'}}>
														// 		<PlusCircleOutlined style={{ fontSize : '50px'}} onClick={() => {handleWellSelect(index + 1);}}/>
														// 	</Col>
														// </Row>
														);
														// }
													} else {
														null;
													}
												}) :
												

												<Row key = {index}>
													<Col span={24} style={{ alignItems : 'center', justifyContent : 'center', display : 'flex', minHeight:'500px'}} onClick={() => {handleWellSelect(index + 1);}}>

														<Row>
															<Col span={24} style={{ alignItems : 'center', justifyContent : 'center', display : 'flex'}}>
																<Typography>{langdata && langdata.AddWell ? langdata.AddWell:'Add Well'}</Typography>
															</Col>
															<Col span={24} style={{ alignItems : 'center', justifyContent : 'center', display : 'flex', marginTop:'10px'}}>
																<PlusCircleOutlined style={{ fontSize : '50px', color:'#1677FF'}} />
															</Col>
														</Row>

													</Col>	
												</Row>
											
											}
								

											{/* </>
												: 
												<Row>
													<Col span={24} style={{ alignItems : 'center', justifyContent : 'center', display : 'flex', minHeight:'500px'}}>
														<PlusCircleOutlined style={{ fontSize : '50px'}} onClick={() => {handleWellSelect(index + 1);}}/>
													</Col>
												</Row> */}
											
										</Spin>
										{/* </Card.Grid> */}
									</Card>
								</Col>
							</Space>
						);
                                    
					})}

				</Row>

				<Modal
					title={langdata && langdata.CrossWellMonitoring ? langdata.CrossWellMonitoring :'Cross Well Monitoring'}
					centered
					destroyOnClose={true}
					open={openWellModal}
					onOk={{closeshowPidsModal}}
					onCancel={closeshowPidsModal}
					footer={[
						// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeshowPidsModal}>
								{langdata && langdata.CANCEL ? langdata.CANCEL :'CANCEL'}
							</Button>
							<Button key="submit" type="primary" onClick={saveWellData}>
								{langdata && langdata.SAVE ? langdata.SAVE :'SAVE'}
							</Button>
						</Space>
					]}
					width={540}
				>
					<Row gutter={16}>
						<Col span={6}>
							<Typography>
								{langdata && langdata.WellInfo ? langdata.WellInfo :'Well Info'}:
							</Typography>
						</Col>
						<Col span={18} style={{alignItems:'flex-end', justifyContent:'flex-end', display:'flex'}}>
							<Typography style={{color:' #CC3300'}}>
								{langdata && langdata.Onlythelastselectedwelldatawillbevisibleingraph ? langdata.Onlythelastselectedwelldatawillbevisibleingraph :'Only the last selected well data will be visible in graph.'}
							</Typography>
						</Col>
					</Row>

					<>
						<Row gutter={16} style={{
							backgroundColor: '#d3d3d3',
							padding: '5px',
							marginTop: '10px'
						}}>
							<Checkbox.Group
								style={{
									width: '100%',
								}}
								onChange={onChange}
								defaultValue={flowWellIds ? flowWellIds : checked}
								// options={welldata}
							>
								{ welldata && welldata ?
									welldata && welldata.map((data)=>(

										<Checkbox key={data.value} style={{padding:'10px'}} 
										// options={welldata}
											value={data.value} onChange={(value) => {onChangeCheckBox(value, data);}}
										>{data.wellname}</Checkbox>
									))
									:
									<Col span={24}>
										<Typography>{langdata && langdata.Nowellfound ? langdata.Nowellfound :'No well found.'}</Typography>
									</Col>}
							</Checkbox.Group>
						</Row>
						<br/>
					</>
          
  
				</Modal>
				{/* <Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
				</Spin> */}
			</Content>

			{/* <Spin fullscreen spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`}/> */}

			{/* Add Vulnerable Point Details Modal Starts */}
			
			{/* Delete User Modal Ends */}
		</>
	);
}