import { EVENT_TYPES_LIST, EVENT_TYPES_LIST_SUCCESS, EVENT_TYPES_LIST_LIST_ERROR, 
	EVENT_TYPE_DETAILS, EVENT_TYPE_DETAILS_SUCCESS, EVENT_TYPE_DETAILS_ERROR, 
	ADD_EVENT_TYPE_DETAILS, ADD_EVENT_TYPE_DETAILS_SUCCESS, ADD_EVENT_TYPE_DETAILS_ERROR,
	UPDATE_EVENT_TYPE, UPDATE_EVENT_TYPE_SUCCESS, UPDATE_EVENT_TYPE_ERROR,
	DELETE_EVENT_TYPE, DELETE_EVENT_TYPE_SUCCESS, DELETE_EVENT_TYPE_ERROR
} from './constants';

export default function eventTypesReducer(state, action) {
	let eventTypes = [];

	switch (action.type) {
	case EVENT_TYPES_LIST:
		return { ...state, pending: true };
      
	case EVENT_TYPES_LIST_SUCCESS:
        
		if (action.payload && action.payload.length > 0) {
			action.payload.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.eventType = item.eventType ? item.eventType : '';
				data.isSynced = item.isSynced ? true : false;
				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';
				data.escalationMetrics = item.escalationMetrics ? item.escalationMetrics : '';
				data.eventConfidenceCap = item.eventConfidenceCap;
				data.eventEscalationEnabled = item.eventEscalationEnabled;

				return eventTypes.push(data);
			});
          
			return { ...state, eventTypes: eventTypes, pending: false, error: false };
		} else {
			if (action.payload && action.payload.length === 0) {
				return { ...state, eventTypes: eventTypes, pending: false, error: false };
			} else {
				return { ...state, pending: false, error: true };
			}
		}
      
	case EVENT_TYPES_LIST_LIST_ERROR:
		return { ...state, pending: false, error: true };

	case EVENT_TYPE_DETAILS:
		return { ...state, pending: true };

	case EVENT_TYPE_DETAILS_SUCCESS:
		return { ...state, details: action.payload, pending: false, error: false };

	case EVENT_TYPE_DETAILS_ERROR:
		return { ...state, pending: false, error: true };

	case ADD_EVENT_TYPE_DETAILS:
		return { ...state, pending: false };

	case ADD_EVENT_TYPE_DETAILS_SUCCESS:
		return { ...state, pending: false, error: false };

	case ADD_EVENT_TYPE_DETAILS_ERROR:
		return { ...state, pending: false, error: true };

	case UPDATE_EVENT_TYPE:
		return { ...state, pending: false };

	case UPDATE_EVENT_TYPE_SUCCESS:
		return { ...state, pending: false, error: false };

	case UPDATE_EVENT_TYPE_ERROR:
		return { ...state, pending: false, error: true };
		
	case DELETE_EVENT_TYPE:
		return { ...state, pending: false };

	case DELETE_EVENT_TYPE_SUCCESS:
		return { ...state, pending: false, error: false };

	case DELETE_EVENT_TYPE_ERROR:
		return { ...state, pending: false, error: true };

	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}