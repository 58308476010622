import React, { useState, useEffect, useReducer } from 'react';
import { useAuth } from '../Context';
import { useNavigate } from 'react-router-dom';

import { Typography, Row, Col, Button, Form, Modal, Select, Empty, Spin, Space, Checkbox, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';

import { CHAINAGES_LIST, CHAINAGES_LIST_SUCCESS, CHAINAGES_LIST_ERROR } from './constants';

import chaingeFilterReducer from './reducer.js';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

import { useFormik } from 'formik';
import axios from 'axios';
import { FileProtectOutlined, InfoOutlined } from '@ant-design/icons';

const { Title } = Typography;

export default function ChainageFilter() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const [regionId, setRegionId] = useState(null);
	const [allocationId, setAllocationId] = useState(null);
	const [startCh, setStartCh] = useState(null);
	const [endCh, setEndCh] = useState(null);
	
	const navigate = useNavigate();
  
	const [selectChainageRange, setSelectChainageRange] = useState(false);
	const [startingChainage, setStartingChainage] = useState(null);
	const [endingChainage, setEndingChainage] = useState(null);
	const initalState = {chainages: [], details: null, loading: false, error: false };

	const [data, dispatch] = useReducer(chaingeFilterReducer, initalState);
  
	let regions = [ { value: null, label: 'ALL'} ];
	let chainages = [];
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const loadChainages = () => {
		const limit = 100000;
		const skip = 0;

		let filterByregionIdPart = '';
		let pipelineChainageparams = `&pipelineChainage[$gte]=${startCh}&pipelineChainage[$lte]=${endCh}`;

		if (regionId && regionId !== null) {
			filterByregionIdPart = `&regioninfoId=${regionId}`;
		} else {
			filterByregionIdPart = '';
		}

		let url = `${api}/pipelineconfig?$limit=${limit}&$skip=${skip}${filterByregionIdPart}${ endCh && endCh || endCh == 0 ? pipelineChainageparams:''}&$sort[pipelineChainage]=+1`;

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		dispatch( { type: CHAINAGES_LIST });

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: CHAINAGES_LIST_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: CHAINAGES_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: CHAINAGES_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	if (admin_roles.includes(permission)) {
		regiondata && regiondata.map((region) => {
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region) => {
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	useEffect(() => {
		if(featuresModule.chainageFilterEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 91);
	}, []);
	
	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		if (admin_roles.includes(permission)) {
			getRegion(authtoken);
		} else{
			getUserAllocation(authtoken);
		}

		loadChainages();
	}, [allocationId, regionId]);

	data && data.chainages && data.chainages.map((chainage) => {
		return chainages.push({ value: chainage.pipelineChainage, label: chainage.pipelineChainage + ' - CH' });
	});

	const handleSetRegion = (e, options) => {
		localStorage.setItem(`${dashboardtype}_REGION_ID`, e);
		
		if (admin_roles.includes(permission)) {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
			setStartCh(options.pipelineConfigFrom);
			setEndCh(options.pipelineConfigTo);
			setStartingChainage(options.pipelineConfigFrom);
			setEndingChainage(options.pipelineConfigTo);
		}

	};

	const handleSelectChainageRange = () => {
		let newValue = !selectChainageRange;

		setSelectChainageRange(newValue);
	};

	const handleChangeStartChainage = (e) => {
		setStartingChainage(e);
	};

	const handleChangeEndChainage = (e) => {
		if (parseInt(startingChainage) > parseInt(e)) {
			Modal.error({ title: 'The ending chainage should be greater than the starting chainage.' });
		} else {
			setEndingChainage(e);
		}
	};

	const editChaingeFilterFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			regionId: regionId,
			selectChainageRange: selectChainageRange,
			startingChainage: startingChainage,
			endingChainage: endingChainage
		},

		onSubmit: (values) => {
			if (values && values.selectChainageRange === true) {
				if (values.startingChainage === null) {
					Modal.warning({ title: langdata && langdata.Pleaseselectavalidstartingchainage ? langdata.Pleaseselectavalidstartingchainage : 'Please select a valid starting chainage.' });
					return false;
				}

				if (values.endingChainage === null) {
					Modal.warning({ title: langdata && langdata.Pleaseselectavalidendingchainage ? langdata.Pleaseselectavalidendingchainage : 'Please select a valid ending chainage.' });
					return false;
				}

				if(values.startingChainage > values.endingChainage) {
					Modal.warning({title : langdata && langdata.Endchainageshouldbegreaterthanstartchainage ? langdata.Endchainageshouldbegreaterthanstartchainage : 'End chainage should be greater than start chainage'});
					return false;
				}
			}

			navigate(( '/chainage-listing'),{ state: { region: values.regionId, start: values.startingChainage, end: values.endingChainage } });
		}
	});
  
	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.ChainageFilterContent1 ? langdata.ChainageFilterContent1 :  'View the chainages and corresponding OD Meter, ROU Marker, Latitude and Longitude details. Filter options available to filter by allocation and by ROU Marker.'}</p>
			<br />
			<p>{langdata && langdata.ChainageFiterContent2 ? langdata.ChainageFiterContent2 :  'Edit action provides option to edit Chainage, ROU Marker, Latitude, Longitude and also to enable or disable vulnerable and snoozed setting for the chainages.'}</p>
		</Card>
	);
		
	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={8}>
						<Row>
							<Space>
								<FileProtectOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.ChainageFilter ? langdata.ChainageFilter :  'Chainage Filter'}</Title>
						</Row>
					</Col>
					<Col style={{ display : 'flex', justifyContent : 'right'}} span={16} >
						<Row>
							<Col>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.ChainageFilter ? langdata.ChainageFilter :  'Chainage Filter'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>

				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24} style={{ marginTop: '20px'}}>
						{ data ? (
							<Form layout="vertical" onSubmit={editChaingeFilterFormik.handleSubmit}>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}}>
									<Col span={12}>
										<Form.Item label={langdata && langdata.Region ? langdata.Region :  'Region'} >
											<Select
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
												optionFilterProp="children"
												defaultValue={'ALL'}
												options={regions}
												onSelect={(e, options)=>handleSetRegion(e, options)}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label={langdata && langdata.SelectChainageRange ? langdata.SelectChainageRange : 'Select Chainage Range'} >
											<Checkbox
												checked = {selectChainageRange}
												onChange={handleSelectChainageRange}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}}>
									<Col span={12}>
										<Form.Item label={langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage'} >
											<Select
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage'}
												optionFilterProp="children"
												disabled={!selectChainageRange}
												options={chainages}
												onSelect={handleChangeStartChainage}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label= {langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage'} >
											<Select
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage'}
												optionFilterProp="children"
												disabled={!selectChainageRange}
												options={chainages}
												onSelect={handleChangeEndChainage}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32}} style={{marginTop: '20px', marginBottom: '20px'}}>
									<Col className="gutter-row" span={24} style={{ textAlign : 'center'}} >
										<Button key="submit" type="primary" onClick={editChaingeFilterFormik.handleSubmit}>{langdata && langdata.VIEWCHAINAGE ? langdata.VIEWCHAINAGE : 'VIEW CHAINAGE'}</Button>
									</Col>  
								</Row>
							</Form>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>
			</Content>

			<Spin fullscreen spinning={data.loading} />
		</>
	);
}