import { DAS_SOCKET_DATA, DTS_AVGZONE_DATA_SUCCESS, DTS_SOCKET_DATA, FLOW_MONITORING_DAS_CROSS_WELL_1, FLOW_MONITORING_DAS_CROSS_WELL_2, FLOW_MONITORING_DAS_CROSS_WELL_3, FLOW_MONITORING_DAS_CROSS_WELL_4, FLOW_MONITORING_DAS_CROSS_WELL_5, FLOW_MONITORING_DAS_CROSS_WELL_6, FLOW_MONITORING_DAS_CROSS_WELL_7, FLOW_MONITORING_DAS_CROSS_WELL_8, FLOW_MONITORING_DAS_CROSS_WELL_LOADING, FLOW_MONITORING_DAS_CROSS_WELL_LOADING_1, FLOW_MONITORING_DAS_CROSS_WELL_LOADING_2, FLOW_MONITORING_DAS_CROSS_WELL_LOADING_3, FLOW_MONITORING_DAS_CROSS_WELL_LOADING_4, FLOW_MONITORING_DAS_CROSS_WELL_LOADING_5, FLOW_MONITORING_DAS_CROSS_WELL_LOADING_6, FLOW_MONITORING_DAS_CROSS_WELL_LOADING_7, FLOW_MONITORING_DAS_CROSS_WELL_LOADING_8, FLOW_MONITORING_DAS_CROSS_WELL_LOADING_FALSE, FLOW_MONITORING_DTS_CROSS_WELL_1, FLOW_MONITORING_DTS_CROSS_WELL_2, FLOW_MONITORING_DTS_CROSS_WELL_3, FLOW_MONITORING_DTS_CROSS_WELL_4, FLOW_MONITORING_DTS_CROSS_WELL_5, FLOW_MONITORING_DTS_CROSS_WELL_6, FLOW_MONITORING_DTS_CROSS_WELL_7, FLOW_MONITORING_DTS_CROSS_WELL_8, FLOW_MONITORING_DTS_CROSS_WELL_LOADING, FLOW_MONITORING_DTS_CROSS_WELL_LOADING_1, FLOW_MONITORING_DTS_CROSS_WELL_LOADING_2, FLOW_MONITORING_DTS_CROSS_WELL_LOADING_3, FLOW_MONITORING_DTS_CROSS_WELL_LOADING_4, FLOW_MONITORING_DTS_CROSS_WELL_LOADING_5, FLOW_MONITORING_DTS_CROSS_WELL_LOADING_6, FLOW_MONITORING_DTS_CROSS_WELL_LOADING_7, FLOW_MONITORING_DTS_CROSS_WELL_LOADING_8, FLOW_MONITORING_DTS_CROSS_WELL_LOADING_FALSE, FLOW_MONITORING_GRAPH_CONFIG, PRESS_SOCKET_DATA } from './constants';


export default function flowMonitoringData(state, action) {

	switch (action.type) {
	case DTS_SOCKET_DATA : 	
		return { ...state, dtsData: action.payload };

	case DAS_SOCKET_DATA : 	
		return { ...state, dasData: action.payload };

	case PRESS_SOCKET_DATA : 	
		return { ...state, pressData: action.payload};

	case FLOW_MONITORING_GRAPH_CONFIG :
		return { ...state, graphConfig : action.payload && action.payload.data ? action.payload.data[0] : {}};

	case FLOW_MONITORING_DTS_CROSS_WELL_LOADING:
		return { ...state, loading1 : true, loading2 : true, loading3 : true, loading4 : true, loading5 : true, loading6 : true, loading7 : true, loading8 : true};

	case FLOW_MONITORING_DTS_CROSS_WELL_1 :
		return {...state, dtsCrossData1 : action.payload, loading1 : false };

	case FLOW_MONITORING_DTS_CROSS_WELL_2 :		
		return {...state, dtsCrossData2 : action.payload, loading2 : false };

	case FLOW_MONITORING_DTS_CROSS_WELL_3 :
		return {...state, dtsCrossData3 : action.payload, loading3 : false };

	case FLOW_MONITORING_DTS_CROSS_WELL_4 :
		return {...state, dtsCrossData4 : action.payload, loading4 : false };

	case FLOW_MONITORING_DTS_CROSS_WELL_5 :
		return {...state, dtsCrossData5 : action.payload, loading5 : false };

	case FLOW_MONITORING_DTS_CROSS_WELL_6 :
		return {...state, dtsCrossData6 : action.payload, loading6 : false };

	case FLOW_MONITORING_DTS_CROSS_WELL_7 :
		return {...state, dtsCrossData7 : action.payload, loading7 : false };

	case FLOW_MONITORING_DTS_CROSS_WELL_8 :
		return {...state, dtsCrossData8 : action.payload, loading8 : false };
	
	case FLOW_MONITORING_DTS_CROSS_WELL_LOADING_FALSE :
		return { ...state, loading1 : false, loading2 : false, loading3 : false, loading4 : false, loading5 : false, loading6 : false, loading7 : false, loading8 : false};

	case FLOW_MONITORING_DTS_CROSS_WELL_LOADING_1 :
		return {...state, dtsCrossData1 : action.payload, loading1 : true };
	
	case FLOW_MONITORING_DTS_CROSS_WELL_LOADING_2 :		
		return {...state, dtsCrossData2 : action.payload, loading2 : true };
	
	case FLOW_MONITORING_DTS_CROSS_WELL_LOADING_3 :
		return {...state, dtsCrossData3 : action.payload, loading3 : true };
	
	case FLOW_MONITORING_DTS_CROSS_WELL_LOADING_4 :
		return {...state, dtsCrossData4 : action.payload, loading4 : true };
	
	case FLOW_MONITORING_DTS_CROSS_WELL_LOADING_5 :
		return {...state, dtsCrossData5 : action.payload, loading5 : true };
	
	case FLOW_MONITORING_DTS_CROSS_WELL_LOADING_6 :
		return {...state, dtsCrossData6 : action.payload, loading6 : true };
	
	case FLOW_MONITORING_DTS_CROSS_WELL_LOADING_7 :
		return {...state, dtsCrossData7 : action.payload, loading7 : true };
	
	case FLOW_MONITORING_DTS_CROSS_WELL_LOADING_8 :
		return {...state, dtsCrossData8 : action.payload, loading8 : true };


	case FLOW_MONITORING_DAS_CROSS_WELL_LOADING:
		return { ...state, loading1 : true, loading2 : true, loading3 : true, loading4 : true, loading5 : true, loading6 : true, loading7 : true, loading8 : true};

	case FLOW_MONITORING_DAS_CROSS_WELL_1 :
		return {...state, dasCrossData1 : action.payload, loading1 : false };

	case FLOW_MONITORING_DAS_CROSS_WELL_2 :		
		return {...state, dasCrossData2 : action.payload, loading2 : false };

	case FLOW_MONITORING_DAS_CROSS_WELL_3 :
		return {...state, dasCrossData3 : action.payload, loading3 : false };

	case FLOW_MONITORING_DAS_CROSS_WELL_4 :
		return {...state, dasCrossData4 : action.payload, loading4 : false };

	case FLOW_MONITORING_DAS_CROSS_WELL_5 :
		return {...state, dasCrossData5 : action.payload, loading5 : false };

	case FLOW_MONITORING_DAS_CROSS_WELL_6 :
		return {...state, dasCrossData6 : action.payload, loading6 : false };

	case FLOW_MONITORING_DAS_CROSS_WELL_7 :
		return {...state, dasCrossData7 : action.payload, loading7 : false };

	case FLOW_MONITORING_DAS_CROSS_WELL_8 :
		return {...state, dasCrossData8 : action.payload, loading8 : false };
	
	case FLOW_MONITORING_DAS_CROSS_WELL_LOADING_FALSE :
		return { ...state, loading1 : false, loading2 : false, loading3 : false, loading4 : false, loading5 : false, loading6 : false, loading7 : false, loading8 : false};

	case FLOW_MONITORING_DAS_CROSS_WELL_LOADING_1 :
		return {...state, dasCrossData1 : action.payload, loading1 : true };
	
	case FLOW_MONITORING_DAS_CROSS_WELL_LOADING_2 :		
		return {...state, dasCrossData2 : action.payload, loading2 : true };
	
	case FLOW_MONITORING_DAS_CROSS_WELL_LOADING_3 :
		return {...state, dasCrossData3 : action.payload, loading3 : true };
	
	case FLOW_MONITORING_DAS_CROSS_WELL_LOADING_4 :
		return {...state, dasCrossData4 : action.payload, loading4 : true };
	
	case FLOW_MONITORING_DAS_CROSS_WELL_LOADING_5 :
		return {...state, dasCrossData5 : action.payload, loading5 : true };
	
	case FLOW_MONITORING_DAS_CROSS_WELL_LOADING_6 :
		return {...state, dasCrossData6 : action.payload, loading6 : true };
	
	case FLOW_MONITORING_DAS_CROSS_WELL_LOADING_7 :
		return {...state, dasCrossData7 : action.payload, loading7 : true };
	
	case FLOW_MONITORING_DAS_CROSS_WELL_LOADING_8 :
		return {...state, dasCrossData8 : action.payload, loading8 : true };

	case DTS_AVGZONE_DATA_SUCCESS :
		return{...state, avgdtszonedata : action.payload};

	default: 
		throw Error('Unknown action: ' + action.type);
      
	}
}