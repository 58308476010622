import { INSPECTION_DATA, INSPECTION_DATA_ERROR, INSPECTION_DATA_SUCCESS, INSPECTION_LIST, INSPECTION_LIST_ERROR, INSPECTION_LIST_SUCCESS, INSPECTON_ALARM_DATA, INSPECTON_ALARM_ERROR, INSPECTON_ALARM_SUCCESS, USERS_LIST, USERS_LIST_ERROR, USERS_LIST_SUCCESS } from './constants';

export default function inspectiondata(state, action) {

	switch (action.type) {
      
	case INSPECTION_LIST:
		return { ...state, pending: true };
	case INSPECTION_LIST_SUCCESS:
		return { ...state, inspection_data: action.payload, pending: false };
	case INSPECTION_LIST_ERROR:
		return { ...state, error: true, pending: false };

	case USERS_LIST:
		return { ...state, pending: true };
	case USERS_LIST_SUCCESS:
		return { ...state, users_data: action.payload, pending: false };
	case USERS_LIST_ERROR:
		return { ...state, error: true, pending: false };

	case INSPECTION_DATA:
		return { ...state, pending: true };
	case INSPECTION_DATA_SUCCESS:
		return { ...state, patch_data: action.payload, pending: false };
	case INSPECTION_DATA_ERROR:
		return { ...state, error: true, pending: false };

	case INSPECTON_ALARM_DATA:
		return { ...state, pending: true };
	case INSPECTON_ALARM_SUCCESS:
		return { ...state, inspection_alarm: action.payload, pending: false };
	case INSPECTON_ALARM_ERROR:
		return { ...state, error: true, pending: false };


      
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}