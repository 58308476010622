export const USER_LIST = 'USER_LIST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_ERROR = 'USER_LIST_ERROR';

export const ROU_USER_DETAILS = 'ROU_USER_DETAILS';
export const ROU_USER_DETAILS_SUCCESS = 'ROU_USER_DETAILS_SUCCESS';
export const ROU_USER_DETAILS_ERROR = 'ROU_USER_DETAILS_ERROR';

export const CRO_USER_DETAILS = 'CRO_USER_DETAILS';
export const CRO_USER_DETAILS_SUCCESS = 'CRO_USER_DETAILS_SUCCESS';
export const CRO_USER_DETAILS_ERROR = 'CRO_USER_DETAILS_ERROR';

export const USER_ALLOCATION = 'USER_ALLOCATION';
export const USER_ALLOCATION_SUCCESS = 'USER_ALLOCATION_SUCCESS';
export const USER_ALLOCATION_ERROR = 'USER_ALLOCATION_ERROR';

export const FILTER_CHAINAGE = 'FILTER_CHAINAGE';

export const SUPERVISOR_USER_DETAILS = 'SUPERVISOR_USER_DETAILS';
export const SUPERVISOR_USER_DETAILS_SUCCESS = 'SUPERVISOR_USER_DETAILS_SUCCESS';
export const SUPERVISOR_USER_DETAILS_ERROR = 'SUPERVISOR_USER_DETAILS_ERROR';

export const LINEWALKER_USER_DETAILS = 'LINEWALKER_USER_DETAILS';
export const LINEWALKER_USER_DETAILS_SUCCESS = 'LINEWALKER_USER_DETAILS_SUCCESS';
export const LINEWALKER_USER_DETAILS_ERROR = 'LINEWALKER_USER_DETAILS_ERROR';

export const NPV_USER_DETAILS = 'NPV_USER_DETAILS';
export const NPV_USER_DETAILS_SUCCESS = 'NPV_USER_DETAILS_SUCCESS';
export const NPV_USER_DETAILS_ERROR = 'NPV_USER_DETAILS_ERROR';

export const EDIT_USER_ALLOCATION = 'EDIT_USER_ALLOCATION';
export const EDIT_USER_ALLOCATION_SUCCESS = 'EDIT_USER_ALLOCATION_SUCCESS';
export const EDIT_USER_ALLOCATION_ERROR = 'EDIT_USER_ALLOCATION_ERROR';

export const ADD_ALLOCATION = 'ADD_ALLOCATION';
export const ADD_ALLOCATION_SUCCESS = 'ADD_ALLOCATION_SUCCESS';
export const ADD_ALLOCATION_ERROR = 'ADD_ALLOCATION_ERROR';

export const EDIT_ALLOCATION = 'EDIT_ALLOCATION';
export const EDIT_ALLOCATION_SUCCESS = 'EDIT_ALLOCATION_SUCCESS';
export const EDIT_ALLOCATION_ERROR = 'EDIT_ALLOCATION_ERROR';

export const ALLOCATION_USER_DETAILS = 'ALLOCATION_USER_DETAILS';
export const ALLOCATION_USER_DETAILS_SUCCESS = 'ALLOCATION_USER_DETAILS_SUCCESS';
export const ALLOCATION_USER_DETAILS_ERROR = 'ALLOCATION_USER_DETAILS_ERROR';

export const ALLOCATION_SUB = 'ALLOCATION_SUB';
export const ALLOCATION_SUB_SUCCESS = 'ALLOCATION_SUB_SUCCESS';
export const ALLOCATION_SUB_ERROR = 'ALLOCATION_SUB_ERROR';

export const ALLOCATION_PIPELINE_PATH = 'ALLOCATION_PIPELINE_PATH';
export const ALLOCATION_PIPELINE_PATH_SUCCESS = 'ALLOCATION_PIPELINE_PATH_SUCCESS';
export const ALLOCATION_PIPELINE_PATH_ERROR = 'ALLOCATION_PIPELINE_PATH_ERROR';

export const EDIT_ALLOCATION_PIPELINE_PATH = 'EDIT_ALLOCATION_PIPELINE_PATH';
export const EDIT_ALLOCATION_PIPELINE_PATH_SUCCESS = 'EDIT_ALLOCATION_PIPELINE_PATH_SUCCESS';
export const EDIT_ALLOCATION_PIPELINE_PATH_ERROR = 'EDIT_ALLOCATION_PIPELINE_PATH_ERROR';

export const INSPECTION_LIST = 'INSPECTION_LIST';
export const INSPECTION_LIST_SUCCESS = 'INSPECTION_LIST_SUCCESS';
export const INSPECTION_LIST_ERROR = 'INSPECTION_LIST_ERROR';

export const USERS_LIST = 'USERS_LIST';
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
export const USERS_LIST_ERROR = 'USERS_LIST_ERROR';
