export const TLPS_LIST = 'TLPS_LIST';
export const TLPS_LIST_SUCCESS = 'TLPS_LIST_SUCCESS';
export const TLPS_LIST_ERROR = 'TLPS_LIST_ERROR';

export const REALTIME_CPS_LIST = 'REALTIME_CPS_LIST';
export const REALTIME_CPS_LIST_SUCCESS = 'REALTIME_CPS_LIST_SUCCESS';
export const REALTIME_CPS_LIST_ERROR = 'REALTIME_CPS_LIST_ERROR';

export const REALTIME_CPS_DETAILS = 'REALTIME_CPS_DETAILS';
export const REALTIME_CPS_DETAILS_SUCCESS = 'REALTIME_CPS_DETAILS_SUCCESS';
export const REALTIME_CPS_DETAILS_ERROR = 'REALTIME_CPS_DETAILS_ERROR';

export const TLP_LOGS = 'TLP_LOGS';
export const TLP_LOGS_SUCCESS = 'TLP_LOGS_SUCCESS';
export const TLP_LOGS_ERROR = 'TLP_LOGS_ERROR';

export const TLP_BATTERY_REPLACEMENT_LOGS = 'TLP_BATTERY_REPLACEMENT_LOGS';
export const TLP_BATTERY_REPLACEMENT_LOGS_SUCCESS = 'TLP_BATTERY_REPLACEMENT_LOGS_SUCCESS';
export const TLP_BATTERY_REPLACEMENT_LOGS_ERROR = 'TLP_BATTERY_REPLACEMENT_LOGS_ERROR';

export const TLP_CONFIG_LOGS = 'TLP_CONFIG_LOGS';
export const TLP_CONFIG_LOGS_SUCCESS = 'TLP_CONFIG_LOGS_SUCCESS';
export const TLP_CONFIG_LOGS_ERROR = 'TLP_CONFIG_LOGS_ERROR';
