import { SPEED_CLASSIFICATIONS_LIST, SPEED_CLASSIFICATIONS_LIST_SUCCESS, SPEED_CLASSIFICATIONS_LIST_ERROR } from './constants';
import { SPEED_CLASSIFICATION_DETAILS, SPEED_CLASSIFICATION_DETAILS_SUCCESS, SPEED_CLASSIFICATION_DETAILS_ERROR } from './constants';
import { ADD_SPEED_CLASSIFICATION_DETAILS, ADD_SPEED_CLASSIFICATION_DETAILS_SUCCESS, ADD_SPEED_CLASSIFICATION_DETAILS_ERROR } from './constants';
import { UPDATE_SPEED_CLASSIFICATION, UPDATE_SPEED_CLASSIFICATION_SUCCESS, UPDATE_SPEED_CLASSIFICATION_ERROR } from './constants';
import { DELETE_SPEED_CLASSIFICATION, DELETE_SPEED_CLASSIFICATION_SUCCESS, DELETE_SPEED_CLASSIFICATION_ERROR } from './constants';

export default function speedClassificationReducer(state, action) {
	let speedClassifications = [];

	switch (action.type) {
	case SPEED_CLASSIFICATIONS_LIST:
		return { ...state, pending: true };
      
	case SPEED_CLASSIFICATIONS_LIST_SUCCESS:
        
		if (action.payload && action.payload.length > 0) {
			action.payload.map( (item) => {
            
				let data = {};

				data.id = item.id ? item.id : 0;
				data.pipelineId = item.pipelineId ? item.pipelineId : 0;
				data.activityName = item.activityName ? item.activityName : '';
				data.upperspeedLimit = item.upperspeedLimit ? item.upperspeedLimit : 0;
				data.lowerspeedLimit = item.lowerspeedLimit ? item.lowerspeedLimit : 0;
				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';

				return speedClassifications.push(data);
			});
          
			return { ...state, speedClassifications: speedClassifications, pending: false, error: false };
		} else {
			if (action.payload && action.payload.length === 0) {
				return { ...state, speedClassifications: speedClassifications, pending: false, error: false };
			} else {
				return { ...state, pending: false, error: true };
			}
		}
      
	case SPEED_CLASSIFICATIONS_LIST_ERROR:
		return { ...state, pending: false, error: true };

	case SPEED_CLASSIFICATION_DETAILS:
		return { ...state, pending: true };

	case SPEED_CLASSIFICATION_DETAILS_SUCCESS:
		return { ...state, details: action.payload, pending: false, error: false };

	case SPEED_CLASSIFICATION_DETAILS_ERROR:
		return { ...state, pending: false, error: true };

	case ADD_SPEED_CLASSIFICATION_DETAILS:
		return { ...state, pending: false };

	case ADD_SPEED_CLASSIFICATION_DETAILS_SUCCESS:
		return { ...state, pending: false, error: false };

	case ADD_SPEED_CLASSIFICATION_DETAILS_ERROR:
		return { ...state, pending: false, error: true };

	case UPDATE_SPEED_CLASSIFICATION:
		return { ...state, pending: false };

	case UPDATE_SPEED_CLASSIFICATION_SUCCESS:
		return { ...state, pending: false, error: false };

	case UPDATE_SPEED_CLASSIFICATION_ERROR:
		return { ...state, pending: false, error: true };
      
	case DELETE_SPEED_CLASSIFICATION:
		return { ...state, pending: false };

	case DELETE_SPEED_CLASSIFICATION_SUCCESS:
		return { ...state, pending: false, error: false };

	case DELETE_SPEED_CLASSIFICATION_ERROR:
		return { ...state, pending: false, error: true };

	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}