import { WELL_INFO, WELL_INFO_ERROR, WELL_INFO_SUCCESS, WELL_INFO_UPDATE, WELL_INFO_UPDATE_DATA, WELL_INFO_UPDATE_ERROR, WELL_INFO_UPDATE_SUCCESS } from './constants';

export default function wellInfoReducer(state, action) {
	let wellInfo = [];
	switch (action.type) {
	case WELL_INFO :
		return { ...state, loading : true, error : false};
	case WELL_INFO_SUCCESS :
		action.payload.data && action.payload.data.map((item) => {
			wellInfo.push({
				id : item.id,
				name : item.name,
				dasip : item.dasip,
				dasport : item.dasport,
				dtsip : item.dtsip,
				dtsport : item.dtsport,
				wellTypeName : item.welltypes.name,
				startDepth : item.startDepth,
				endDepth : item.endDepth,
				length : item.length,
				width : item.width,
				dtsEnabled : item.dtsEnabled == true ? true : false,
				dasEnabled : item.dasEnabled ==true ? true : false,
				maxTempThreshold : item.maxTempThreshold,
				minTempThreshold : item.minTempThreshold,
				maxAmpThreshold : item.maxAmpThreshold,
				minAmpThreshold : item.minAmpThreshold,
				maxPressureThreshold : item.maxPressureThreshold,
				minPressureThreshold : item.minPressureThreshold,
				pressureip : item.pressureip,
				pressureport : item.pressureport,
				pressureEnabled : item.pressureEnabled == true ? true : false,
				wellTypeId : item.wellTypeId,
				latitude : item.latitude,
				longitude : item.longitude,
				startUpperZoneDepth: item.startUpperZoneDepth,
				endUpperZoneDepth : item.endUpperZoneDepth,
				startInterZoneDepth:item.startInterZoneDepth,
				endInterZoneDepth : item.endInterZoneDepth,
				startReservoirZoneDepth: item.startReservoirZoneDepth,
				endReservoirZoneDepth : item.endReservoirZoneDepth,
			
			});
		});
		return { ...state, loading : false, error : false, wellInfo : wellInfo};
	case WELL_INFO_ERROR :
		return { ...state, loading : false, error : true };
	case WELL_INFO_UPDATE :
		return { ...state, loading : true, error : false};
	case WELL_INFO_UPDATE_SUCCESS :
		return { ...state, loading : false, error : false};
	case WELL_INFO_UPDATE_ERROR :
		return { ...state, loading : false, error : true};
	case WELL_INFO_UPDATE_DATA :
		return { ...state, updateData : action.payload};
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}