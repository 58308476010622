import moment from 'moment';


export function startdatetimeCoverter(datetime) {

	// datetime format --- Wed Jan 17 2024 00:00:00 GMT+0530 (India Standard Time)
	const utctime = moment.utc(datetime).format('HH:mm:ss');  // 00:00:00  HH:mm:ss
	const utcdate = moment.utc(datetime).format('YYYY-MM-DD');    // 2024-01-01  'YYYY-MM-DD'
	const datetime_data = {};
	datetime_data.utcdate=utcdate;
	datetime_data.utctime=utctime;

	return datetime_data;
}

export function enddatetimeCoverter(datetime) {

	// datetime format --- Wed Jan 17 2024 00:00:00 GMT+0530 (India Standard Time)
	const utctime = moment.utc(datetime).format('HH:mm:ss');  // 23:59:59  HH:mm:ss
	const utcdate = moment.utc(datetime).format('YYYY-MM-DD');    // 2024-01-01  'YYYY-MM-DD'
	const datetime_data = {};
	datetime_data.utcdate=utcdate;
	datetime_data.utctime=utctime;

	return datetime_data;
}


export function datetimeCovertertolocal(datetime) {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);


	// datetime format --- 2024-01-30T05:31:12Z
	// const datetime1= new Date(datetime);
	const localtime = moment.utc(datetime).local().format(settingstimeformat);
	const localdate = moment.utc(datetime).local().format(settingsdateformat);

	const datetime_data = {};
	datetime_data.localdate=localdate;
	datetime_data.localtime=localtime;

	return datetime_data;
}