import { CHANNELINFO_DETAILS, CHANNELINFO_LIST, CHANNELINFO_LIST_ERROR, CHANNELINFO_LIST_SUCCESS, CHANNELWIDTH_DETAILS, CHANNEL_CONVERTION_DETAILS, CHANNEL_MATRICS_DETAILS, GET_EVENT_RULES, GET_EVENT_RULES_DETAILS, GET_EVENT_RULES_ERROR, GET_EVENT_RULES_SUCCESS, GET_TERRAIN, GET_TERRAIN_ERROR, GET_TERRAIN_SUCCESS, MODEL_NAMES } from './constants';

export default function channelInfoReducer(state, action) {
	const channelInfo = [];
	switch (action.type) {
	case CHANNELINFO_LIST :
		return { ...state, pending: true };
	case CHANNELINFO_LIST_SUCCESS :
		action.payload && action.payload.map((item) => {
			channelInfo.push({
				id : item.id,
				name : item.name ? item.name : '-',
				binSize : item.binSize ? parseFloat(item.binSize).toFixed(4) : '-',
				startPos : item.startPos,
				amplitudeNumber : item.amplitudeNumber ? item.amplitudeNumber : '-',
				pidsinfoId : item.pidsinfo ? item.pidsinfo.name : '-',
				channelId : item.channelId ? item.channelId : '-',
				profile : item.profile ? item.profile : '-',
				regioninfoId : item.regioninfo ? item.regioninfo.regionName : '-',
				modelTypeOverride : item.modelTypeOverride || item.modelTypeOverride == false ? item.modelTypeOverride : '-',
				modelPredictionLevel : item.modelPredictionLevel ? item.modelPredictionLevel : '-',
				predictionDecisionThreshold : item.predictionDecisionThreshold || item.predictionDecisionThreshold == 0 ? item.predictionDecisionThreshold : 1,
				l1Model : item.l1Model ? item.l1Model : '-',
				l2ModelThreat : item.l2ModelThreat ? item.l2ModelThreat : '-',
				l2ModelNonthreat : item.l2ModelNonthreat ? item.l2ModelNonthreat : '-',
				l3ModelMachinedigging : item.l3ModelMachinedigging ? item.l3ModelMachinedigging : '-',
				l3ModelManualdigging : item.l3ModelManualdigging ? item.l3ModelManualdigging : '-',
				l3ModelVehiclemovement : item.l3ModelVehiclemovement ? item.l3ModelVehiclemovement : '-',
				overrideLowerLimit : item.overrideLowerLimit || item.overrideLowerLimit == 0 ? item.overrideLowerLimit : 0,
				overrideUpperLimit : item.overrideUpperLimit || item.overrideUpperLimit == 0 ? item.overrideUpperLimit : 3,
				predictedEventConfirmation : item.predictedEventConfirmation ? item.predictedEventConfirmation : '-'
			});
		});
		return { ...state, channelInfo: channelInfo, pending: false };
	case CHANNELINFO_LIST_ERROR :
		return { ...state, error: true, pending: false };
	case CHANNELINFO_DETAILS :
		return { ...state,channelDetails : action.payload, error: true, pending: false };
	case CHANNELWIDTH_DETAILS :
		return { ...state, channelWidth : action.payload };
	case CHANNEL_CONVERTION_DETAILS :
		return { ...state, channelConvertion : action.payload };
	case CHANNEL_MATRICS_DETAILS :
		return { ...state, channelMatrics : action.payload };
	case MODEL_NAMES :
		return { ...state, modelNames : action.payload };

	case GET_TERRAIN : 
		return { ...state, loading: true, error: false };

	case GET_TERRAIN_SUCCESS:
		if (action.payload) {
			// pagination.total = action.payload.total ? action.payload.total : 0;
			// pagination.limit = action.payload.limit ? action.payload.limit : 10;
			// pagination.skip = action.payload.skip ? action.payload.skip : 0;
			// pagination.current = action.page ? action.page : 1;
		}
		return { ...state, terrains: action.payload, loading: false };

	case GET_TERRAIN_ERROR:
		return { ...state, loading: false, error: true };

	case GET_EVENT_RULES : 
		return { ...state, loading: true, error: false };

	case GET_EVENT_RULES_SUCCESS:

		return { ...state, eventrules: action.payload, loading: false };

	case GET_EVENT_RULES_ERROR:
		return { ...state, loading: false, error: true };
	
	case GET_EVENT_RULES_DETAILS :
		return { ...state, eventRluesDetails : action.payload };

	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}