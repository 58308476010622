export const CHAINAGES_LIST = 'CHAINAGES_LIST';
export const CHAINAGES_LIST_SUCCESS = 'CHAINAGES_LIST_SUCCESS';
export const CHAINAGES_LIST_ERROR = 'CHAINAGES_LIST_ERROR';

export const CHAINAGE_DETAILS = 'CHAINAGE_DETAILS';
export const CHAINAGE_DETAILS_SUCCESS = 'CHAINAGE_DETAILS_SUCCESS';
export const CHAINAGE_DETAILS_ERROR = 'CHAINAGE_DETAILS_ERROR';

export const UPDATE_CHAINAGE = 'UPDATE_CHAINAGE';
export const UPDATE_CHAINAGE_SUCCESS = 'UPDATE_CHAINAGE_SUCCESS';
export const UPDATE_CHAINAGE_ERROR = 'UPDATE_CHAINAGE_ERROR';