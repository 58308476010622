/* eslint-disable react/react-in-jsx-scope */
import { Breadcrumb, Button, Card, Col, Form, Modal, Row, Select, Spin, Typography, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useReducer, useState } from 'react';
import { useAuth } from '../Context';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import reducer from './reducer';
import { FILTER_CHAINAGE, USER_ALLOCATION, USER_ALLOCATION_ERROR, USER_ALLOCATION_SUCCESS, ROU_USER_DETAILS, ROU_USER_DETAILS_ERROR, ROU_USER_DETAILS_SUCCESS, CRO_USER_DETAILS, CRO_USER_DETAILS_ERROR, CRO_USER_DETAILS_SUCCESS, SUPERVISOR_USER_DETAILS, SUPERVISOR_USER_DETAILS_SUCCESS, SUPERVISOR_USER_DETAILS_ERROR, LINEWALKER_USER_DETAILS, LINEWALKER_USER_DETAILS_SUCCESS, LINEWALKER_USER_DETAILS_ERROR, NPV_USER_DETAILS, NPV_USER_DETAILS_SUCCESS, NPV_USER_DETAILS_ERROR, ADD_ALLOCATION, ADD_ALLOCATION_SUCCESS, ADD_ALLOCATION_ERROR } from './constants';
import { HomeOutlined } from '@ant-design/icons';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;

export default function AddAllocation() {
	const { getRegion, regiondata, handleGetMapdata, mapdata} = useAuth();

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);


	const initalState = { pending: false, error: false, rou : [], cro : [], allocation : [], npvUserDetails : [], npv : [],
		filterChainage : [], croUserDetails : [], linewalkerUserDetails : [], linewalker : []};
	const [data, dispatch] = useReducer(reducer, initalState);
	const { state } = useLocation();

	const [allocationTo, setAllocationTo] = useState( state && state.user.usertypeId == 3 ? 'CRO' : state && state.user.usertypeId == 4 ? 'linewalker' : state && state.user.usertypeId == 5 ? 'supervisor' :  state && state.user.usertypeId == 6 ? 'ROU' : state && state.user.usertypeId == 7 ? 'nightpatrol' : permission === 'ROU' ? 'CRO' : permission === 'CRO' ? 'supervisor' : 'ROU');
	const [regionId, setRegionId] = useState();
	const [startChainageRouMarker, setStartChainageRouMarker] = useState();
	const [endChainageRouMarker, setEndChainageRouMarker] = useState();
	const [allocationId, setAllocationId] = useState();
	const [startChainage, setStartChainage] = useState(null);
	const [endChainage, setEndChainage] = useState(null);
	const [rouId, setRouId] = useState();
	const [rouUserTypeId, setRouUserTypeId] = useState();
	const [croId, setCroId] = useState();
	const [croUserTypeId, setCroUserTypeId] = useState();
	const [supervisorId, setSupervisorId] = useState();
	const [supervisorUserTypeId, setSupervisorUserTypeId] = useState();
	const [linewalkerId, setLinewalkerId] = useState();
	const [linewalkerUserTypeId, setLinewalkerUserTypeId] = useState();
	const [walkingTypeValue, setWalkTypeValue] = useState();
	const [routeTypeValue, setRouteTypeValue] = useState();
	const [npvId, setNpvId] = useState();
	const [npvUserTypeId, setNpvUserTypeId] = useState();
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const [showAllocations, setShowAllocations] = useState();
	const [showStartChainage, setShowStartChainage] = useState(null);
	const [showEndChainage, setShowEndChainage] = useState(null);

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const navigate = useNavigate();

	const walkingType = [
		{
			value : 'UNIDIRECTIONAL',
			label : langdata && langdata.UNIDIRECTIONAL ? langdata.UNIDIRECTIONAL : 'UNIDIRECTIONAL'
		},
		{
			value : 'BIDIRECTIONAL',
			label : langdata && langdata.BIDIRECTIONAL ? langdata.BIDIRECTIONAL : 'BIDIRECTIONAL'
		}
	];
	
	const routeType = [
		{
			value : 'OPTIONAL',
			label : langdata && langdata.OPTIONAL ? langdata.OPTIONAL : 'OPTIONAL'
		},
		{
			value : 'MANDATORY',
			label : langdata && langdata.MANDATORY ? langdata.MANDATORY : 'MANDATORY'
		}
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getRegion(authtoken);
		dispatch({ type : ROU_USER_DETAILS});
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/users?isDeleted=false&$and[0][permissions]=ROU`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};
          
		axios.request(config)
			.then((response) => {
				if (response.status === 200){
					dispatch({ type : ROU_USER_DETAILS_SUCCESS, payload : response.data});
				} else {
					dispatch({ type : ROU_USER_DETAILS_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : ROU_USER_DETAILS_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	},[authtoken]);

	useEffect(() => {
		handleGetMapdata(authtoken,regionId,null,null);
	},[authtoken, regionId]);

	const userDetails = () => {
		if (allocationTo === 'CRO' || allocationTo === 'supervisor') {
			dispatch ({ type : CRO_USER_DETAILS });
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/users?isDeleted=false&$and[0][permissions]=CRO`,
				timeout: timeout,
				headers: { 
					'Authorization': `Bearer ${authtoken}`
				}
			};

			axios.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch ({ type : CRO_USER_DETAILS_SUCCESS, payload : response.data});
					}
					else {
						dispatch ({ type : CRO_USER_DETAILS_ERROR });
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : CRO_USER_DETAILS_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
		if (allocationTo === 'supervisor' || allocationTo === 'linewalker') {
			dispatch({ type : SUPERVISOR_USER_DETAILS });
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/users?isDeleted=false&$and[0][permissions]=supervisor`,
				timeout: timeout,
				headers: { 
					'Authorization': `Bearer ${authtoken}`
				}
			};

			axios.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch({ type : SUPERVISOR_USER_DETAILS_SUCCESS, payload : response.data });
					} else {
						dispatch({ type : SUPERVISOR_USER_DETAILS_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : SUPERVISOR_USER_DETAILS_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
		if ( allocationTo === 'linewalker') {
			dispatch({ type : LINEWALKER_USER_DETAILS });
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/users?isDeleted=false&$and[0][permissions]=linewalker`,
				timeout: timeout,
				headers: { 
					'Authorization': `Bearer ${authtoken}`
				}
			};

			axios.request(config)
				.then((response) => {
					if ( response.status === 200) {
						dispatch({ type : LINEWALKER_USER_DETAILS_SUCCESS, payload : response.data});
					} else {
						dispatch({ type : LINEWALKER_USER_DETAILS_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : LINEWALKER_USER_DETAILS_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});

		}
		if (allocationTo === 'nightpatrol') {
			dispatch ({ type : NPV_USER_DETAILS });
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/users?isDeleted=false&$and[0][permissions]=nightpatrol`,
				timeout: timeout,
				headers: { 
					'Authorization': `Bearer ${authtoken}`
				}
			};

			axios.request(config)
				.then((response) => {
					if ( response.status === 200) {
						dispatch ({ type : NPV_USER_DETAILS_SUCCESS, payload : response.data});
					} else {
						dispatch({ type : NPV_USER_DETAILS_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : NPV_USER_DETAILS_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				}); 
		}
	};

	useEffect(() => {
		if (regionId) {
			dispatch ({ type : USER_ALLOCATION });
			let url; 
			if ( (allocationTo === 'CRO' || allocationTo === 'nightpatrol') &&  rouId){
				url = `${api}/userallocation?userId=${rouId}&regioninfoId=${regionId}`;
			} else if ( allocationTo === 'supervisor' && croId ) {
				url = `${api}/userallocation?userId=${croId}&regioninfoId=${regionId}`;
			} else if ( allocationTo === 'linewalker' && supervisorId) {
				url = `${api}/userallocation?userId=${supervisorId}&regioninfoId=${regionId}`;
			}
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: url,
				timeout: timeout,
				headers: { 
					'Authorization': `Bearer ${authtoken}`
				}
			};

			axios.request(config)
				.then((response) => {
					if ( response.status === 200) {
						dispatch({ type : USER_ALLOCATION_SUCCESS, payload : response.data});
					} else {
						dispatch({ type : USER_ALLOCATION_ERROR });
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : USER_ALLOCATION_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		} 

	},[authtoken, rouId, croId, allocationTo, regionId, supervisorId]);

	useEffect(() => {
		userDetails();
	},[allocationTo]);

	const userType = [];
	// eslint-disable-next-line array-callback-return
	if(admin_roles.includes(permission)) {
		userType.push({
			label : langdata && langdata.AllotROU ? langdata.AllotROU : 'Allot ROU',
			value : 'ROU'
		});
	} 
	if (admin_roles.includes(permission) || permission == 'ROU') {
		userType.push({
			label : langdata && langdata.AllotCRO ? langdata.AllotCRO : 'Allot CRO',
			value : 'CRO'
		});
	}

	userType.push({
		label :  langdata && langdata.AllotSupervisor ? langdata.AllotSupervisor : 'Allot Supervisor',
		value : 'supervisor'
	},
	{
		label : langdata && langdata.AllotLinewalker ? langdata.AllotLinewalker : 'Allot Linewalker',
		value : 'linewalker'
	},
	);

	if (admin_roles.includes(permission) || permission == 'ROU') {
		userType.push(
			{
				label :  langdata && langdata.AllotNightpatrol ? langdata.AllotNightpatrol : 'Allot Nightpatrol',
				value : 'nightpatrol'
			});
	}

	const regions = [];
	// eslint-disable-next-line array-callback-return
	regiondata && regiondata.map((item)=> {
		regions.push({
			value:item.id,
			label:item.regionName.toUpperCase()
		});
	});

	const startRouChainage = [];
	// eslint-disable-next-line array-callback-return
	mapdata && mapdata.map((item) => {
		startRouChainage.push({
			value : item.id,
			label : `${item.pipelineChainage} ( ${item.rouMarker} )`
		});
	});

	const endRouChainage = [];
	// eslint-disable-next-line array-callback-return
	mapdata && mapdata.map((item) => {
		endRouChainage.push({
			value : item.id,
			label : `${item.pipelineChainage} ( ${item.rouMarker} )`
		});
	});

	const handleUserRole = (value) => {
		setAllocationTo(value);
		setRegionId(null);
		setStartChainageRouMarker(null);
		setEndChainageRouMarker(null);
		setShowAllocations(null);
		setStartChainage(null);
		setEndChainage(null);
		setRouId(null);
		setRouUserTypeId(null);
		setCroId(null);
		setCroUserTypeId(null);
		setSupervisorId(null);
		setSupervisorUserTypeId(null);
		setLinewalkerId(null);
		setLinewalkerUserTypeId(null);
		setWalkTypeValue(null);
		setRouteTypeValue(null);
		setNpvId(null);
		setNpvUserTypeId(null);
		setShowStartChainage(null);
		setShowEndChainage(null);
	};

	const handleRegion = (value) => {
		setRegionId(value);
		setStartChainageRouMarker(null);
		setEndChainageRouMarker(null);
		setShowAllocations(null);
		setStartChainage(null);
		setEndChainage(null);
		setRouId(null);
		setRouUserTypeId(null);
		setCroId(null);
		setCroUserTypeId(null);
		setSupervisorId(null);
		setSupervisorUserTypeId(null);
		setLinewalkerId(null);
		setLinewalkerUserTypeId(null);
		setWalkTypeValue(null);
		setRouteTypeValue(null);
		setNpvId(null);
		setNpvUserTypeId(null);
		setShowStartChainage(null);
		setShowEndChainage(null);
	};

	const handleROU = (value) => {
		// eslint-disable-next-line array-callback-return
		data.rouUserDetails && data.rouUserDetails.map((item) => {
			if(item.id === value) {
				setRouId(item.id);
				setRouUserTypeId(item.usertypeId);
			}
		});
		setShowAllocations(null);
		setStartChainage(null);
		setEndChainage(null);
		setCroId(null);
		setCroUserTypeId(null);
		setSupervisorId(null);
		setSupervisorUserTypeId(null);
		setLinewalkerId(null);
		setLinewalkerUserTypeId(null);
		setWalkTypeValue(null);
		setRouteTypeValue(null);
		setNpvId(null);
		setNpvUserTypeId(null);
		setShowStartChainage(null);
		setShowEndChainage(null);
	};

	const handleStartChainage = (value) => {
		// eslint-disable-next-line array-callback-return
		mapdata && mapdata.map((item) => {
			if (item.id === value) {
				setStartChainageRouMarker(item.rouMarker);
				setStartChainage(item.pipelineChainage);
			}
		});
		setShowStartChainage(value);
	};

	const handleEndChainage = (value) => {
		// eslint-disable-next-line array-callback-return
		mapdata && mapdata.map((item) => {
			if (item.id === value) {
				setEndChainageRouMarker(item.rouMarker);
				setEndChainage(item.pipelineChainage);
			}
		});
		setShowEndChainage(value);
	};

	const handleAllocate = () => {
		let body;
		dispatch({ type : ADD_ALLOCATION });
		if (!allocationTo) {
			Modal.warning({ title:  langdata && langdata.Pleasespecifytheusertype ? langdata.Pleasespecifytheusertype : 'Please specify the user type.' });
			dispatch({ type : ADD_ALLOCATION_ERROR });
			return false;
		}
		if (!regionId) {
			Modal.warning({ title: langdata && langdata.Pleasespecifytheregion ? langdata.Pleasespecifytheregion :  'Please specify the region.' });
			dispatch({ type : ADD_ALLOCATION_ERROR });
			return false;
		}
		if( startChainage === endChainage && startChainage !== null && endChainage !== null) {
			Modal.warning({ title: langdata && langdata.Startchainageandendchainagecantbesame ? langdata.Startchainageandendchainagecantbesame : 'Start chainage and end chainage can\'t be same.' });
			dispatch({ type : ADD_ALLOCATION_ERROR });
			return false;
		}
		if (allocationTo === 'ROU') {
			if (!rouId) {
				Modal.warning({ title: langdata && langdata.PleasespecifytheROU ? langdata.PleasespecifytheROU :  'Please specify the ROU.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (startChainage === null) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythestartchainage ? langdata.Pleasespecifythestartchainage : 'Please specify the start chainage.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (endChainage === null) {
				Modal.warning({ title:  langdata && langdata.Pleasespecifytheendchainage ? langdata.Pleasespecifytheendchainage : 'Please specify the end chainage.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			body = JSON.stringify({
				'currentPath': 'default',
				'pipelineConfigFrom': startChainage,
				'pipelineConfigTo': endChainage,
				'pipelineId': localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`),
				'regioninfoId': regionId,
				'rouMarkerFrom': startChainageRouMarker,
				'rouMarkerTo': endChainageRouMarker,
				'userId': rouId,
				'usertypeId': rouUserTypeId
			});
		} else if (allocationTo === 'CRO') {
			if (!rouId) {
				Modal.warning({ title:  langdata && langdata.PleasespecifytheROU ? langdata.PleasespecifytheROU : 'Please specify the ROU.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (!croId) {
				Modal.warning({ title: langdata && langdata.PleasespecifytheCRO ? langdata.PleasespecifytheCRO : 'Please specify the CRO.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (startChainage === null) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythestartchainage ? langdata.Pleasespecifythestartchainage :  'Please specify the start chainage.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (endChainage === null) {
				Modal.warning({ title:  langdata && langdata.Pleasespecifytheendchainage ? langdata.Pleasespecifytheendchainage : 'Please specify the end chainage.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			body = JSON.stringify({
				'currentPath': 'default',
				'parentAllocation' : allocationId,
				'pipelineConfigFrom': startChainage,
				'pipelineConfigTo': endChainage,
				'pipelineId': localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`),
				'regioninfoId': regionId,
				'rouId' : rouId,
				'rouMarkerFrom': startChainageRouMarker,
				'rouMarkerTo': endChainageRouMarker,
				'userId': croId,
				'usertypeId': croUserTypeId
			});
		} else if ( allocationTo === 'supervisor' ) {
			if (!rouId) {
				Modal.warning({ title: langdata && langdata.PleasespecifytheROU ? langdata.PleasespecifytheROU : 'Please specify the ROU.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (!croId) {
				Modal.warning({ title: langdata && langdata.PleasespecifytheCRO ? langdata.PleasespecifytheCRO : 'Please specify the CRO.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (!supervisorId) {
				Modal.warning({ title:  langdata && langdata.PleasespecifytheSupervisor ? langdata.PleasespecifytheSupervisor : 'Please specify the Supervisor.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (startChainage === null) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythestartchainage ? langdata.Pleasespecifythestartchainage : 'Please specify the start chainage.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (endChainage === null) {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheendchainage ? langdata.Pleasespecifytheendchainage : 'Please specify the end chainage.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			body = JSON.stringify({
				'crmId' : croId,
				'currentPath': 'default',
				'parentAllocation' : allocationId,
				'pipelineConfigFrom': startChainage,
				'pipelineConfigTo': endChainage,
				'pipelineId': localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`),
				'regioninfoId': regionId,
				'rouMarkerFrom': startChainageRouMarker,
				'rouMarkerTo': endChainageRouMarker,
				'rouId': rouId, 
				'userId': supervisorId,
				'usertypeId': supervisorUserTypeId
			});
		} else if ( allocationTo === 'linewalker') {
			if (!supervisorId) {
				Modal.warning({ title: langdata && langdata.PleasespecifytheSupervisor ? langdata.PleasespecifytheSupervisor : 'Please specify the Supervisor.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (!linewalkerId) {
				Modal.warning({ title:  langdata && langdata.PleasespecifytheLinewalker ? langdata.PleasespecifytheLinewalker : 'Please specify the Linewalker.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (startChainage === null) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythestartchainage ? langdata.Pleasespecifythestartchainage : 'Please specify the start chainage.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (endChainage === null) {
				Modal.warning({ title:  langdata && langdata.Pleasespecifytheendchainage ? langdata.Pleasespecifytheendchainage : 'Please specify the end chainage.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (!walkingTypeValue) {
				Modal.warning({ title: langdata && langdata.Pleasespecifythewalkingtype ? langdata.Pleasespecifythewalkingtype : 'Please specify the walking type.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (!routeTypeValue) {
				Modal.warning({ title:  langdata && langdata.Pleasespecifytheroutetype ? langdata.Pleasespecifytheroutetype : 'Please specify the route type.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			body = JSON.stringify({
				'supervisorId' : supervisorId,
				'currentPath': 'default',
				'parentAllocation' : allocationId,
				'pipelineConfigFrom': startChainage,
				'pipelineConfigTo': endChainage,
				'pipelineId': localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`),
				'regioninfoId': regionId,
				'rouMarkerFrom': startChainageRouMarker,
				'rouMarkerTo': endChainageRouMarker,
				'userId': linewalkerId,
				'usertypeId': linewalkerUserTypeId,
				'routeType' : routeTypeValue,
				'walkType' : walkingTypeValue
			});
		} else if ( allocationTo === 'nightpatrol') {
			if (!rouId) {
				Modal.warning({ title:  langdata && langdata.PleasespecifytheROU ? langdata.PleasespecifytheROU : 'Please specify the ROU.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (!npvUserTypeId) {
				Modal.warning({ title:  langdata && langdata.PleasespecifytheNightpatrol ? langdata.PleasespecifytheNightpatrol :  'Please specify the Nightpatrol.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (startChainage === null) {
				Modal.warning({ title:  langdata && langdata.Pleasespecifythestartchainage ? langdata.Pleasespecifythestartchainage : 'Please specify the start chainage.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			if (endChainage === null) {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheendchainage ? langdata.Pleasespecifytheendchainage : 'Please specify the end chainage.' });
				dispatch({ type : ADD_ALLOCATION_ERROR });
				return false;
			}
			body = JSON.stringify({
				'rouId' : rouId,
				'currentPath': 'default',
				'parentAllocation' : allocationId,
				'pipelineConfigFrom': startChainage,
				'pipelineConfigTo': endChainage,
				'pipelineId': localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`),
				'regioninfoId': regionId,
				'rouMarkerFrom': startChainageRouMarker,
				'rouMarkerTo': endChainageRouMarker,
				'userId': npvId,
				'usertypeId': npvUserTypeId,
			});
		} else {
			dispatch({ type : ADD_ALLOCATION_ERROR });
			Modal.error({ title:  langdata && langdata.Theusersallocationwasnotsuccessful ? langdata.Theusersallocationwasnotsuccessful : 'The user\'s allocation was not successful.' });
			navigate('/allocations');
		}
		if(body) {
			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/userallocation`,
				timeout: extendTimeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : body
			};
        
			axios.request(config)
				.then((response) => {
					if ( response.status === 201) {
						dispatch ({ type : ADD_ALLOCATION_SUCCESS });
						Modal.success({ title: langdata && langdata.Userallottedsuccessfully ? langdata.Userallottedsuccessfully :'User allotted successfully.' });
						navigate('/allocations');
					}else {
						dispatch({ ADD_ALLOCATION_ERROR });
						Modal.warning({ title: langdata && langdata.Sameallocationalreadyexist ? langdata.Sameallocationalreadyexist : 'Same allocation already exist!' });
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  'It seems the network is unreachable. Please refresh the application.',
						});
					}
					if(error.code == 'ERR_BAD_REQUEST'){
						Modal.warning({ title: langdata && langdata.Sameallocationalreadyexist ? langdata.Sameallocationalreadyexist : 'Same allocation already exist!' });
					}
					dispatch({ type : ADD_ALLOCATION_ERROR });
				});
		}
          
	};

	const handleAllocationChainage = (value) => {
		let split_value = value.split(' ');
		setAllocationId(parseInt(split_value[0]));
		dispatch({ type : FILTER_CHAINAGE, value : split_value, chainage : mapdata});
		setShowAllocations(value);
		setStartChainage(null);
		setEndChainage(null);
		setWalkTypeValue(null);
		setRouteTypeValue(null);
		setShowStartChainage(null);
		setShowEndChainage(null);
	};

	const handleCRO = (value) => {
		// eslint-disable-next-line array-callback-return
		data.croUserDetails && data.croUserDetails.map((item) => {
			if(item.id === value) {
				setCroId(item.id);
				setCroUserTypeId(item.usertypeId);
			}
		});
		setShowAllocations(null);
		setStartChainage(null);
		setEndChainage(null);
		setSupervisorId(null);
		setSupervisorUserTypeId(null);
		setLinewalkerId(null);
		setLinewalkerUserTypeId(null);
		setShowStartChainage(null);
		setShowEndChainage(null);
	};

	const handleSupervisor = (value) => {
		// eslint-disable-next-line array-callback-return
		data.supervisorUserDetails && data.supervisorUserDetails.map((item) => {
			if(item.id === value) {
				setSupervisorId(item.id);
				setSupervisorUserTypeId(item.usertypeId);
			}
		});
		setShowAllocations(null);
		setStartChainage(null);
		setEndChainage(null);
		setLinewalkerId(null);
		setLinewalkerUserTypeId(null);
		setWalkTypeValue(null);
		setRouteTypeValue(null);
		setNpvId(null);
		setNpvUserTypeId(null);
		setShowStartChainage(null);
		setShowEndChainage(null);
	};

	const handleLinewalker = (value) => {
		// eslint-disable-next-line array-callback-return
		data.linewalkerUserDetails && data.linewalkerUserDetails.map((item) => {
			if(item.id === value) {
				setLinewalkerId(item.id);
				setLinewalkerUserTypeId(item.usertypeId);
			}
		});
		setShowAllocations(null);
		setStartChainage(null);
		setEndChainage(null);
		setWalkTypeValue(null);
		setRouteTypeValue(null);
		setShowStartChainage(null);
		setShowEndChainage(null);
	};

	const handleWalkingType = (value) => {
		setWalkTypeValue(value);
	};

	const handleRoutetype = (value) => {
		setRouteTypeValue(value);
	};

	const handleNPV = (value) => {
		data.npvUserDetails && data.npvUserDetails.map((item) => {
			if(item.id === value) {
				setNpvId(item.id);
				setNpvUserTypeId(item.usertypeId);
			}
		});
		setShowAllocations(null);
		setStartChainage(null);
		setEndChainage(null); 
		setShowStartChainage(null);
		setShowEndChainage(null);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		<Content>
			<Form>
				<Row 
					style={{
						marginLeft:'50px',
						marginTop: '10px'
					}}            
				>
					<Breadcrumb
						items={[
							{
								href: '/dashboard',
								title: <HomeOutlined />,
							},
							{
								title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'}</a> 
							},
							{
								title: langdata && langdata.AddAllocation ? langdata.AddAllocation : 'Add Allocation',
							},
						]}
					/>
				</Row>
				<Card  style={{
					marginLeft: '50px',
					marginRight: '50px',
					marginTop: '10px',
					padding: '20px',
					background: '#FFFFFF',
					// display: 'flex',
					// width : '100%'
					// margin: '50px'
				}}>
					<Row
						gutter={{
							xs: 8,
							sm: 16,
							md: 24,
							lg: 32,
						}}                
					>
						<Col className="gutter-row" span={24}>
							<Title level={4} style={{ paddingTop:'10px', justifyContent : 'center', display : 'flex'}}>{langdata && langdata.AddUserAllocation ? langdata.AddUserAllocation : 'Add User Allocation'}</Title>
						</Col>
						<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
							<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Allotto ? langdata.Allotto : 'Allot to'} :</Typography>
						</Col>
						<Col className="gutter-row" span={12} style={{ textAlign : 'left', marginTop : '25px'}} >
							<Select
								style={{ minWidth: '100%'}}
								showSearch
								filterOption={filterOption}
								placeholder={langdata && langdata.Allotto ? langdata.Allotto : 'Allot to'}
								optionFilterProp="children"
								options={userType}
								onSelect={handleUserRole}
								value={allocationTo}
								defaultValue={userType[0]}
							/>
						</Col>
						<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
							<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Region ? langdata.Region : 'Region'} :</Typography>
						</Col>
						<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
							<Select
								style={{ minWidth: '100%'}}
								showSearch
								filterOption={filterOption}
								placeholder={langdata && langdata.Region ? langdata.Region : 'Region'}
								optionFilterProp="children"
								options={regions}
								onSelect={handleRegion}
								value={regionId}

							/>
						</Col>
						{allocationTo === 'ROU' || allocationTo === 'CRO' || allocationTo === 'supervisor' || allocationTo === 'nightpatrol' ?
							<>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.ROU ? langdata.ROU : 'ROU'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.ROU ? langdata.ROU : 'ROU'}
										optionFilterProp="children"
										options={data.rou}
										onSelect={handleROU}
										value={rouId}
									/>
								</Col>
							</>
							: null }
						{ allocationTo === 'ROU' ? 
							<>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage '}
										optionFilterProp="children"
										options={startRouChainage}
										onSelect={handleStartChainage}
										value={showStartChainage}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage'}
										optionFilterProp="children"
										options={endRouChainage}
										onSelect={handleEndChainage}
										value={showEndChainage}
									/>
								</Col>
							</>
							: null }
						{ allocationTo === 'CRO' ? 
							<>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.CRO ? langdata.CRO : 'CRO'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.CRO ? langdata.CRO : 'CRO '}
										optionFilterProp="children"
										options={data.cro}
										onSelect={handleCRO}
										value={croId}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.ROUAllocations ? langdata.ROUAllocations :  'ROU Allocations'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.ROUAllocations ? langdata.ROUAllocations : 'ROU Allocations '}
										optionFilterProp="children"
										options={data.allocation}
										onSelect={handleAllocationChainage}
										value={showAllocations}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage '}
										optionFilterProp="children"
										options={data.filterChainage}
										onSelect={handleStartChainage}
										value={showStartChainage}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage '}
										optionFilterProp="children"
										options={data.filterChainage}
										onSelect={handleEndChainage}
										value={showEndChainage}
									/>
								</Col>
							</>
							: null }
						{allocationTo === 'supervisor' ? 
							<>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.CRO ? langdata.CRO : 'CRO'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.CRO ? langdata.CRO : 'CRO '}
										optionFilterProp="children"
										options={data.cro}
										onSelect={handleCRO}
										value={croId}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Supervisor ? langdata.Supervisor : 'Supervisor'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Supervisor ? langdata.Supervisor : 'Supervisor '}
										optionFilterProp="children"
										options={data.supervisor}
										onSelect={handleSupervisor}
										value={supervisorId}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.CROAllocations ? langdata.CROAllocations : 'CRO Allocations'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.CROAllocations ? langdata.CROAllocations : 'CRO Allocations '}
										optionFilterProp="children"
										options={data.allocation}
										onSelect={handleAllocationChainage}
										value={showAllocations}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage '}
										optionFilterProp="children"
										options={data.filterChainage}
										onSelect={handleStartChainage}
										value={showStartChainage}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage '}
										optionFilterProp="children"
										options={data.filterChainage}
										onSelect={handleEndChainage}
										value={showEndChainage}
									/>
								</Col>
							</>
							: null }
						{ allocationTo === 'linewalker' ?
							<>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Supervisor ? langdata.Supervisor : 'Supervisor'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Supervisor ? langdata.Supervisor : 'Supervisor '}
										optionFilterProp="children"
										options={data.supervisor}
										onSelect={handleSupervisor}
										value={supervisorId}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.Linewalker ? langdata.Linewalker : 'Linewalker'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Linewalker ? langdata.Linewalker : 'Linewalker'}
										optionFilterProp="children"
										options={data.linewalker}
										onSelect={handleLinewalker}
										value={linewalkerId}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.SupervisorAllocations ? langdata.SupervisorAllocations : 'Supervisor Allocations'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SupervisorAllocations ? langdata.SupervisorAllocations : 'Supervisor Allocations '}
										optionFilterProp="children"
										options={data.allocation}
										onSelect={handleAllocationChainage}
										value={showAllocations}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage '}
										optionFilterProp="children"
										options={data.filterChainage}
										onSelect={handleStartChainage}
										value={showStartChainage}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage '}
										optionFilterProp="children"
										options={data.filterChainage}
										onSelect={handleEndChainage}
										value={showEndChainage}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.WalkingType ? langdata.WalkingType : 'Walking Type'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.WalkingType ? langdata.WalkingType : 'Walking Type '}
										optionFilterProp="children"
										options={walkingType}
										onSelect={handleWalkingType}
										value={walkingTypeValue}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.RouteType ? langdata.RouteType : 'Route Type'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.RouteType ? langdata.RouteType : 'Route Type '}
										optionFilterProp="children"
										options={routeType}
										onSelect={handleRoutetype}
										value={routeTypeValue}
									/>
								</Col>
							</>
							: null }
						{ allocationTo === 'nightpatrol' ? 
							<>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.NPV ? langdata.NPV : 'NPV'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.NPV ? langdata.NPV : 'NPV '}
										optionFilterProp="children"
										options={data.npv}
										onSelect={handleNPV}
										value={npvId}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.ROUAllocations ? langdata.ROUAllocations : 'ROU Allocations'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.ROUAllocations ? langdata.ROUAllocations : 'ROU Allocations '}
										optionFilterProp="children"
										options={data.allocation}
										onSelect={handleAllocationChainage}
										value={showAllocations}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.StartingChainage ? langdata.StartingChainage : 'Starting Chainage '}
										optionFilterProp="children"
										options={data.filterChainage}
										onSelect={handleStartChainage}
										value={showStartChainage}
									/>
								</Col>
								<Col className="gutter-row" span={7} style={{ textAlign : 'right',marginTop : '25px'}} >
									<Typography><span style={{ color : 'red'}}>*&nbsp;</span>{langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage'} :</Typography>
								</Col>
								<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
									<Select
										style={{ minWidth: '100%'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.EndingChainage ? langdata.EndingChainage : 'Ending Chainage '}
										optionFilterProp="children"
										options={data.filterChainage}
										onSelect={handleEndChainage}
										value={showEndChainage}
									/>
								</Col>
							</>
							: null }
						<Col className="gutter-row" span={12} style={{ textAlign : 'right',marginTop : '25px'}} >
							<Button type='primary' onClick={handleAllocate}>{langdata && langdata.Allot ? langdata.Allot : 'Allot'}</Button>
						</Col>
						<Col className="gutter-row" span={12} style={{ textAlign : 'left',marginTop : '25px'}} >
							<Button onClick={() => {navigate('/allocations');}}>{langdata && langdata.Cancel ? langdata.Cancel : 'Cancel'}</Button>
						</Col>
					</Row>       
				</Card>
			</Form>
			<Spin fullscreen spinning={data.pending} tip={langdata && langdata.largeloadingmsg ? langdata.largeloadingmsg : 'This process will take 2-3 minutes to complete. Please wait a moment.'}/>
		</Content>
	);
}