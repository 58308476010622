import React, { useEffect, useState, useReducer } from 'react';
import { useAuth } from '../../Context';

import { Empty, Row, Col, Typography, Button, Space, Table, Form, Input, Modal, Spin, Tooltip, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PlusOutlined, EditOutlined, EyeOutlined, DeleteOutlined, InfoOutlined } from '@ant-design/icons';


import gradeManagementReducer from './reducer';

import { useFormik } from 'formik';
import axios from 'axios';
import { ToolOutlined } from '@ant-design/icons';
import { GRADE_MANAGEMENT_LIST } from './constants';
import { GRADE_MANAGEMENT_LIST_SUCCESS } from './constants';
import { GRADE_MANAGEMENT_LIST_ERROR } from './constants';
import { GRADE_MANAGEMENT_DETAILS } from './constants';
import { DELETE_GRADE_MANAGEMENT } from './constants';
import { DELETE_GRADE_MANAGEMENT_SUCCESS } from './constants';
import { DELETE_GRADE_MANAGEMENT_ERROR } from './constants';
import { ADD_GRADE_MANAGEMENT_DETAILS } from './constants';
import { ADD_GRADE_MANAGEMENT_DETAILS_ERROR } from './constants';
import { ADD_GRADE_MANAGEMENT_DETAILS_SUCCESS } from './constants';
import { UPDATE_GRADE_MANAGEMENT } from './constants';
import { UPDATE_GRADE_MANAGEMENT_ERROR } from './constants';
import { UPDATE_GRADE_MANAGEMENT_SUCCESS } from './constants';
import { useNavigate } from 'react-router-dom';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

const { Title } = Typography;

export default function GradeManagemnt() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = {grademanagement : [], details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(gradeManagementReducer, initalState);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [showAddGradeClassificationModal, setShowAddGradeClassificationModal] = useState(false);
	const [showEditGradeClassificationModal, setShowEditGradeClassificationModal] = useState(false);
	const [showDeleteGradeClassificationModal, setShowDeleteGradeClassificationModal] = useState(false);

	const {getRegion, regiondata} = useAuth();
	const navigate = useNavigate();


	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const columns = [
		{
			title: langdata && langdata.MinRequiredPatch ? langdata.MinRequiredPatch : 'Min Required Patch (%)',
			dataIndex: 'patchPercentage',
			key: 'patchPercentage',
			render: (text) => <span onClick={openEditGradeClassificationModal}>{text}</span>,
		},
		{
			title: langdata && langdata.MaxAverageGradekmh ? langdata.MaxAverageGradekmh : 'Max Average Grade (km/h)',
			dataIndex: 'averageSpeed',
			key: 'averageSpeed',
		},
		{
			title:  langdata && langdata.MaxIdleTimemins ? langdata.MaxIdleTimemin : 'Max Idle Time (min)',
			dataIndex: 'idleTime',
			key: 'idleTime',
		},
		{
			title: langdata && langdata.MaxNumberViolations ? langdata.MaxNumberViolations : 'Max Number Violations',
			dataIndex: 'speedViolatioNumber',
			key: 'speedViolatioNumber',
		},
		{
			title: langdata && langdata.TimeToleranceForDistanceCalculation ? langdata.TimeToleranceForDistanceCalculation : 'Time Tolerance For Distance (sec)',
			dataIndex: 'distanceTimeTolerance',
			key: 'distanceTimeTolerance',
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: () => (
				permission === 'superadmin' || permission === 'admin' ? (
					<Space size="middle">
						<a title={langdata && langdata.EditGradeClassification ? langdata.EditGradeClassification : 'Edit Grade Classification'} onClick={openEditGradeClassificationModal}><EditOutlined/></a>
						<a title={langdata && langdata.DeleteGradeClassification ? langdata.DeleteGradeClassification : 'Delete Grade Classification'} onClick={openDeleteGradeClassificationModal}><DeleteOutlined /></a>
					</Space>
				) : (
					<Space size="middle">
						<a title={langdata && langdata.EditGradeClassification ? langdata.EditGradeClassification : 'Edit Grade Classification'} onClick={openEditGradeClassificationModal}><EyeOutlined/></a>
					</Space>
				)
			)
		}
	];

	const openAddGradeClassificationModal = () => {
		setShowAddGradeClassificationModal(true);
	};

	const cloaseAddGradeClassificationModal = () => {
		setShowAddGradeClassificationModal(false);
	};

	const openEditGradeClassificationModal = () => {
		setShowEditGradeClassificationModal(true);
	};

	const closeEditGradeClassificationModal = () => {
		setShowEditGradeClassificationModal(false);
	};

	const openDeleteGradeClassificationModal = () => {
		setShowDeleteGradeClassificationModal(true);
	};

	const deleteGradeClassification = () => {
		const id = parseInt(data.details.id);
    
		dispatch({ type: DELETE_GRADE_MANAGEMENT });

		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/grademanagement/${id}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			}
		};

		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type: DELETE_GRADE_MANAGEMENT_SUCCESS, payload: response});
					setShowDeleteGradeClassificationModal(false);   
					loadGrademanagent();       
				} else {
					dispatch({type:DELETE_GRADE_MANAGEMENT_ERROR});  
				}
			})
			.catch((error) => {
				dispatch({type:DELETE_GRADE_MANAGEMENT_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const closeDeleteGradeClassificationModal = () => {
		setShowDeleteGradeClassificationModal(false);
	};

	useEffect(() => {
		if(featuresModule.gradeMgmtEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 403);
	}, []);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		else{
			dispatch( { type: GRADE_MANAGEMENT_LIST });
		
			getRegion(authtoken);
			loadGrademanagent();
		}
	}, []);

	const loadGrademanagent = () => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/grademanagement`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type:GRADE_MANAGEMENT_LIST_SUCCESS, payload: response.data.data});
				}
			})
			.catch((error) => {
				dispatch({type:GRADE_MANAGEMENT_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	let regions = [
		{ value: null, label: 'ALL'}
	];

	regiondata && regiondata.map((region) => {
		return regions.push({ value: region.id, label: region.regionName.toUpperCase()});
	});

	const loadGradeManagementDetails = ((record) => {
		dispatch({ type: GRADE_MANAGEMENT_DETAILS, payload: record });	
	});

	const addGradeClassificationFormik = useFormik({
		initialValues: {
			patchPercentage: null,
			averageSpeed: null,
			idleTime: null,
			speedViolatioNumber:null
		},
		onSubmit: (values) => {

			const patchPercentage = values.patchPercentage || values.patchPercentage == 0 ? values.patchPercentage : null;
			const averageSpeed = values.averageSpeed || values.averageSpeed ==0 ? values.averageSpeed : null;
			const idleTime = values.idleTime || values.idleTime==0 ? values.idleTime : null;
			const speedViolatioNumber = values.speedViolatioNumber || values.speedViolatioNumber == 0 ? values.speedViolatioNumber : null;
			const distanceTimeTolerance = values.distanceTimeTolerance || values.distanceTimeTolerance == 0 ? values.distanceTimeTolerance : null;

			if(patchPercentage == null || averageSpeed == null || idleTime == null || speedViolatioNumber == null || distanceTimeTolerance == null ) {
				Modal.warning({title : langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields : 'Please fill all the mandatory fields'});
				return true;
			}

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/grademanagement`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					patchPercentage: patchPercentage,
					averageSpeed: averageSpeed,
					idleTime: idleTime,
					speedViolatioNumber:speedViolatioNumber,
					distanceTimeTolerance:parseInt(distanceTimeTolerance),
					pipelineId: parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`)),
				}
			};

			dispatch( { type: ADD_GRADE_MANAGEMENT_DETAILS });
      
			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: ADD_GRADE_MANAGEMENT_DETAILS_SUCCESS, payload: response});
						Modal.success({ title: langdata && langdata.Grademanagementsavedsuccessfully ? langdata.Grademanagementsavedsuccessfully : 'Grade management saved successfully.' });
						setShowAddGradeClassificationModal(false);
						addGradeClassificationFormik.values.patchPercentage=null;
						addGradeClassificationFormik.values.averageSpeed=null;
						addGradeClassificationFormik.values.idleTime=null;
						addGradeClassificationFormik.values.speedViolatioNumber=null;
						addGradeClassificationFormik.values.distanceTimeTolerance=null;
						loadGrademanagent();
					} else {
						dispatch({type: ADD_GRADE_MANAGEMENT_DETAILS_ERROR});
						Modal.warning({ title: langdata && langdata.UnabletosavetheGrademanagement ? langdata.UnabletosavetheGrademanagement : 'Unable to save the Grade management.' });  
					}
				})
				.catch((error) => {
					dispatch({type: ADD_GRADE_MANAGEMENT_DETAILS_ERROR});
					Modal.warning({ title: langdata && langdata.UnabletosavetheGrademanagement ? langdata.UnabletosavetheGrademanagement : 'Unable to save the Grade management.' });
					setShowAddGradeClassificationModal(false);

					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
	});

	const updateGradeClassificationFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			patchPercentage: data.details ? data.details.patchPercentage : 0,
			averageSpeed: data.details ? data.details.averageSpeed : 0,
			idleTime: data.details ? data.details.idleTime : 0,
			speedViolatioNumber: data.details ? data.details.speedViolatioNumber : 0,
			distanceTimeTolerance:data.details ? data.details.distanceTimeTolerance: 0

		},
		onSubmit: (values) => {
            
			const id = data.details.id;
			const patchPercentage = values.patchPercentage || values.patchPercentage === 0 ? values.patchPercentage : null;
			const averageSpeed = values.averageSpeed || values.averageSpeed === 0 ? values.averageSpeed : null;
			const idleTime = values.idleTime || values.idleTime===0 ? values.idleTime : null;
			const speedViolatioNumber = values.speedViolatioNumber || values.speedViolatioNumber === 0 ? values.speedViolatioNumber : null;
			const distanceTimeTolerance = values.distanceTimeTolerance || values.distanceTimeTolerance === 0 ? values.distanceTimeTolerance : null;

			if(patchPercentage == null || patchPercentage === '' || averageSpeed == null || averageSpeed === '' || idleTime == null || idleTime === '' || speedViolatioNumber == null || speedViolatioNumber === '' || distanceTimeTolerance == null || distanceTimeTolerance === '') {
				Modal.warning({title : langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields : 'Please fill all the mandatory fields'});
				return true;
			}
            
			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/grademanagement/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					patchPercentage: patchPercentage,
					averageSpeed: averageSpeed,
					idleTime: idleTime,
					speedViolatioNumber:speedViolatioNumber,
					distanceTimeTolerance:parseInt(distanceTimeTolerance)
				}
			};

			dispatch( { type: UPDATE_GRADE_MANAGEMENT });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: UPDATE_GRADE_MANAGEMENT_SUCCESS, payload:response});
						Modal.success({ title: langdata && langdata.Grademanagementupdatedsuccessfully ? langdata.Grademanagementupdatedsuccessfully : 'Grade management updated successfully.' });
						setShowEditGradeClassificationModal(false);
						loadGrademanagent();
					} else {
						dispatch({type: UPDATE_GRADE_MANAGEMENT_ERROR});
						Modal.warning({ title: langdata && langdata.Unabletoupdatethegrademanagement ? langdata.Unabletoupdatethegrademanagement : 'Unable to update the grade management.' });  
					}
				})
				.catch((error) => {
					dispatch({type: UPDATE_GRADE_MANAGEMENT_ERROR});
					setShowEditGradeClassificationModal(false);
					Modal.warning({ title: langdata && langdata.Unabletoupdatethegrademanagement ? langdata.Unabletoupdatethegrademanagement : 'Unable to update the grade management.' });  

					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication : 'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
	});

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.Grademanagementdetails ? langdata.Grademanagementdetails : 'Grade management offers settings for the grading system (STS) and manages its details. New configurations can only be added if the existing configuration is empty.'}</p>
		</Card>
	);

	return (
		permission==='superadmin' || permission === 'admin' ? 

			<>
				<Content>
					<Row style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
					>

						<Col span={12}>
							<Row>
								<Space>
									<ToolOutlined style={{fontSize:'30px'}}/>
									{/* </Col> */}
								</Space>
								<Space>&nbsp;&nbsp;</Space>
					
								{/* <Col style={{marginLeft:'4px'}} span={19}> */}
								<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.STSGradeManagement ? langdata.STSGradeManagement : 'STS Grade Management' }</Title>
							</Row>
						</Col>
						<Col span={12}>
							<Row justify="end">
								{data && data.grademanagement.length == 0 ?
									<Col>
										<Tooltip placement="bottom" title={langdata && langdata.Add ? langdata.Add : 'Add'}>
											<Space>
												{ permission === 'superadmin' || permission === 'admin' ? <Button type="primary" onClick={openAddGradeClassificationModal} ><PlusOutlined/>Add New</Button> : null }
											</Space>
										</Tooltip>
									</Col>
									: null }
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.GradeManagement ? langdata.GradeManagement : 'Grade Management'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Row>
						</Col>
					</Row>
					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24}>
							{ data && data.grademanagement.length > 0 ? (
								<Table
									scroll={{
										x: 900,
									}} 
									columns={columns} 
									dataSource={data && data.grademanagement} 
									onRow = {(record) => {
										return {
											onClick: () => { 
												loadGradeManagementDetails(record);
											}
										};
									}} />
							)
								:
								(
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
								)}
						</Col>
					</Row>
					<Spin fullscreen spinning={data.pending} />
				</Content>

      
				{/* Add Grade Classification Starts */}
				<Modal
					title={langdata && langdata.AddGradeManagement ? langdata.AddGradeManagement : 'Add Grade Management'}
					centered
					destroyOnClose={true}
					maskClosable={false}
					open={showAddGradeClassificationModal}
					onOk={{cloaseAddGradeClassificationModal}}
					onCancel={cloaseAddGradeClassificationModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={cloaseAddGradeClassificationModal}>CLOSE</Button>
							{ permission === 'superadmin' || permission === 'admin' ? <Button type="primary" onClick={addGradeClassificationFormik.handleSubmit}>SAVE</Button> : null }
						</Space>
					]}
					width={800}
				>
					<Form layout="vertical" onSubmit={addGradeClassificationFormik.handleSubmit}>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.MinimumRequiredPatch ? langdata.MinimumRequiredPatch : 'Minimum Required Patch (%)'} rules={[{ required: true, message: langdata && langdata.Pleaseenterminimumrequiredpatch ? langdata.Pleaseenterminimumrequiredpatch : 'Please enter required patch'}]} >
									<Input type='number' min={0} max={100} name="patchPercentage" onChange={addGradeClassificationFormik.handleChange} placeholder={langdata && langdata.Pleaseenterminimumrequiredpatch ? langdata.Pleaseenterminimumrequiredpatch : 'Please enter required patch' }/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.MaximumAverageSpeed ? langdata.MaximumAverageSpeed : 'Maximum Average Speed (km/h)'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumaveragespeed ? langdata.Pleaseenterthemaximumaveragespeed :  'Please enter the maximum average speed' }]} >
									<Input name="averageSpeed" min={0} max={450}  onChange={addGradeClassificationFormik.handleChange} type="number" placeholder={langdata && langdata.Pleaseenterthemaximumaveragespeed ? langdata.Pleaseenterthemaximumaveragespeed : 'Please enter the maximum average speed'}/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.MaximumIdleTime ? langdata.MaximumIdleTime : 'Maximum Idle Time (min)'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumidletime ? langdata.Pleaseenterthemaximumidletime : 'Please enter the maximum idle time' }]} >
									<Input name="idleTime" min={0} max={1000} onChange={addGradeClassificationFormik.handleChange} type="number" placeholder={langdata && langdata.Pleaseenterthemaximumidletime ? langdata.Pleaseenterthemaximumidletime : 'Please enter the maximum idle time'}/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.MaximumNumberofViolations ? langdata.MaximumNumberofViolations : 'Maximum Number of Violations'} rules={[{ required: true, message:langdata && langdata.Pleaseenterthemaximumnumberofviolations ? langdata.Pleaseenterthemaximumnumberofviolations :  'Please enter the maximum number of violations' }]} >
									<Input  name="speedViolatioNumber" min={0} max={500} onChange={addGradeClassificationFormik.handleChange} type="number" placeholder={langdata && langdata.Pleaseenterthemaximumnumberofviolations ? langdata.Pleaseenterthemaximumnumberofviolations : 'Please enter the maximum number of violations' }/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.TimeToleranceForTravelledDistance ? langdata.TimeToleranceForTravelledDistance : 'Time Tolerance For Traveled Distance (sec)'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthetimetolerancetocalculatetraveleddistance ? langdata.Pleaseenterthetimetolerancetocalculatetraveleddistance : 'Please enter the time tolerance to calculate the traveled distance' }]} required>
									<Input  name="distanceTimeTolerance" min={0} max={1000} onChange={addGradeClassificationFormik.handleChange} type="number" placeholder={langdata && langdata.Pleaseenterthetimetolerancetocalculatetraveleddistance ? langdata.Pleaseenterthetimetolerancetocalculatetraveleddistance :'Please enter the time tolerance to calculate traveled distance'} />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
				{/* Add Grade Classification Ends */}

				{/* Edit Grade Classification Starts */}
				<Modal
					title={langdata && langdata.EditGradeManagement ? langdata.EditGradeManagement : 'Edit Grade Management'}
					centered
					destroyOnClose= {true}
					open={showEditGradeClassificationModal}
					onOk={{closeEditGradeClassificationModal}}
					onCancel={closeEditGradeClassificationModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeEditGradeClassificationModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
							{ permission === 'superadmin' || permission === 'admin' ? <Button type="primary" onClick={updateGradeClassificationFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
						</Space>
					]}
					width={800}
				>
					<Form layout="vertical" onSubmit={updateGradeClassificationFormik.handleSubmit}>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.MinimumRequiredPatch ? langdata.MinimumRequiredPatch : 'Minimum Required Patch (%)'} rules={[{ required: true, message: langdata && langdata.Pleaseenterminimumrequiredpatch ? langdata.Pleaseenterminimumrequiredpatch : 'Please enter required patch' }]} required>
									<Input type='number' min={0} max={100} name="patchPercentage" onChange={updateGradeClassificationFormik.handleChange} placeholder={langdata && langdata.Pleaseenterminimumrequiredpatch ? langdata.Pleaseenterminimumrequiredpatch : 'Please enter required patch'} defaultValue={data.details ? data.details.patchPercentage : 0} />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.MaximumAverageSpeed ? langdata.MaximumAverageSpeed : 'Maximum Average Speed (km/h)'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumaveragespeed ? langdata.Pleaseenterthemaximumaveragespeed :'Please enter the maximum average speed' }]} required>
									<Input name="averageSpeed" min={0} max={450}  onChange={updateGradeClassificationFormik.handleChange} type="number" placeholder={langdata && langdata.Pleaseenterthemaximumaveragespeed ? langdata.Pleaseenterthemaximumaveragespeed :'Please enter the maximum average speed'} defaultValue={data.details ? data.details.averageSpeed : 0} />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.MaximumIdleTime ? langdata.MaximumIdleTime : 'Maximum Idle Time (min)'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumidletime ? langdata.Pleaseenterthemaximumidletime : 'Please enter the maximum idle time' }]} required>
									<Input name="idleTime" min={0} max={1000} onChange={updateGradeClassificationFormik.handleChange} type="number" placeholder={langdata && langdata.Pleaseenterthemaximumidletime ? langdata.Pleaseenterthemaximumidletime : 'Please enter the maximum idle time'} defaultValue={data.details ? data.details.idleTime : 0} />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.MaximumNumberofViolations ? langdata.MaximumNumberofViolations : 'Maximum Number of Violations'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthemaximumnumberofviolations ? langdata.Pleaseenterthemaximumnumberofviolations : 'Please enter the maximum number of violations' }]} required>
									<Input  name="speedViolatioNumber" min={0} max={500} onChange={updateGradeClassificationFormik.handleChange} type="number" placeholder={langdata && langdata.Pleaseenterthemaximumnumberofviolations ? langdata.Pleaseenterthemaximumnumberofviolations :'Please enter the maximum number of violations'} defaultValue={data.details ? data.details.speedViolatioNumber : 0 } />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={langdata && langdata.TimeToleranceForTravelledDistance ? langdata.TimeToleranceForTravelledDistance : 'Time Tolerance For Traveled Distance (sec)'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthetimetolerancetocalculatetraveleddistance ? langdata.Pleaseenterthetimetolerancetocalculatetraveleddistance : 'Please enter the time tolerance to calculate the traveled distance' }]} required>
									<Input  name="distanceTimeTolerance" min={0} max={1000} onChange={updateGradeClassificationFormik.handleChange} type="number" placeholder={langdata && langdata.Pleaseenterthetimetolerancetocalculatetraveleddistance ? langdata.Pleaseenterthetimetolerancetocalculatetraveleddistance :'Please enter the time tolerance to calculate traveled distance'} defaultValue={data.details ? data.details.distanceTimeTolerance : 0 } />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
				{/* Edit Grade Classification Ends */}

				{/* Delete Grade Classification Starts */}
				<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={showDeleteGradeClassificationModal} onOk={deleteGradeClassification} onCancel={closeDeleteGradeClassificationModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeDeleteGradeClassificationModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
							<Button type="primary" onClick={deleteGradeClassification}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
						</Space>
					]}>
					<Row style={{
						marginTop: '10px',
						marginBottom: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={1}>
							<DeleteOutlined  style={{ fontSize: '14px'}}/>
						</Col>
						<Col span={23}>
							{langdata && langdata.Areyousureyouwanttodeletethisgrademanagement ? langdata.Areyousureyouwanttodeletethisgrademanagement : 'Are you sure you want to delete this grade management?'}
						</Col>
					</Row>
				</Modal>
				<Spin fullscreen spinning={data.pending} />

				{/* Delete Grade Classification Ends */}
			</> : null
	);
}