export const REGION_LIST = 'REGION_LIST';
export const REGION_LIST_SUCCESS = 'REGION_LIST_SUCCESS';
export const REGION_LIST_ERROR = 'REGION_LIST_ERROR';
export const REGION_LIST_DETAILS = 'REGION_LIST_DETAILS';

export const UPDATE_REGION_DATA = 'UPDATE_REGION_DATA';
export const UPDATE_REGION_DATA_SUCCESS = 'UPDATE_REGION_DATA_SUCCESS';
export const UPDATE_REGION_DATA_ERROR = 'UPDATE_REGION_DATA_ERROR';

export const UPDATE_REGION = 'UPDATE_REGION';
export const UPDATE_REGION_SUCCESS = 'UPDATE_REGION_SUCCESS';
export const UPDATE_REGION_ERROR = 'UPDATE_REGION_ERROR';