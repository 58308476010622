import moment from 'moment';
import { MARKED_FOR_RETRAINING_LIST, MARKED_FOR_RETRAINING_LIST_SUCCESS, MARKED_FOR_RETRAINING_LIST_ERROR, MODEL_DATA, MODEL_DATA_SUCCESS, MODEL_DATA_ERROR, RETRAIN_HISTORY_DATA, RETRAIN_HISTORY_DATA_SUCCESS, RETRAIN_HISTORY_DATA_ERROR, RETRAIN_HISTORY_DATA_DETAILS, RETAINED_LIST, RETAINED_LIST_SUCCESS, RETAINED_LIST_ERROR, RETRAINED_DETAILS_SUCCESS, INPROGRESS_LIST, INPROGRESS_LIST_SUCCESS, INPROGRESS_LIST_ERROR, MODELCONFIG_LIST, MODELCONFIG_LIST_SUCCESS, MODELCONFIG_LIST_ERROR, MODEL_DETAILS, MODEL_DETAILS_SUCCESS, MODEL_DETAILS_ERROR, MODELCONFIG_DETAILS, MODELCONFIG_DETAILS_SUCCESS, MODELCONFIG_DETAILS_ERROR, RELATED_ALARMS, RELATED_ALARMS_SUCCESS, RELATED_ALARMS_ERROR, RETRAIN_TYPE_DETAILS, MODEL_HISTORY, MODEL_HISTORY_SUCCESS, MODEL_HISTORY_ERROR, MODEL_INFO_DETAILS, MODEL_DEPLOYMENT_INFO, MODEL_CLASSIFICATIONS_SUCCESS, MODEL_CLASSIFICATIONS_ERROR, MODEL_CLASSIFICATIONS, RETRAIN_HISTORY_BATCH, RETRAIN_HISTORY_BATCH_SUCCESS, RETRAIN_HISTORY_BATCH_ERROR, RETRAIN_MODEL_TYPE, RETRAIN_MODEL_TYPE_SUCCESS, RETRAIN_MODEL_TYPE_ERROR } from './constants';
import { APPROVED_LIST, APPROVED_LIST_SUCCESS, APPROVED_LIST_ERROR  } from './constants';
import { REJECTED_LIST, REJECTED_LIST_SUCCESS, REJECTED_LIST_ERROR   } from './constants';
import { MARKED_FOR_RETRAINING_DETAILS, MARKED_FOR_RETRAINING_DETAILS_SUCCESS, MARKED_FOR_RETRAINING_DETAILS_ERROR } from './constants';
import { APPROVED_DETAILS, APPROVED_DETAILS_SUCCESS, APPROVED_DETAILS_ERROR } from './constants';
import { REJECTED_DETAILS, REJECTED_DETAILS_SUCCESS, REJECTED_DETAILS_ERROR } from './constants';


export default function retrainingDataReducer(state, action) {
	let markedForRetraining = [];
	let markedForRetrainingPagination = {};
	let approved = [];
	let approvedPagination = {};
	let rejected = [];
	let rejectedPagination = {};
	let retrained = [];
	let retrainedPagination = {};
	let inProgress = [];
	let inProgressPagination = {};
	let modelConfigData = [];
	let modelConfigPagination = {};
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	switch (action.type) {
	case MARKED_FOR_RETRAINING_LIST:
		return { ...state, loading: true };
    
	case MARKED_FOR_RETRAINING_LIST_SUCCESS:
		if (action && action.payload) {
			markedForRetrainingPagination.total = action.payload.total ? action.payload.total : 0;
			markedForRetrainingPagination.limit = action.payload.limit ? action.payload.limit : 10;
			markedForRetrainingPagination.skip = action.payload.skip ? action.payload.skip : 0;
			markedForRetrainingPagination.current = action.page ? action.page : 1;
			markedForRetrainingPagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}

		if (action && action.payload) {
			action.payload.data.map( (item,index) => {
				let data = {};
				const date = moment.utc(item.datetime).toDate();
				date.toString();
				const newdate = moment(date, 'YYYY-MM-DD').format(settingsdateformat);
				const newTime = moment(date, 'HH:mm:ss').format(settingstimeformat);
				const resolutiontime = moment.utc(item.resolutiontime).toDate();
				date.toString();
				const newResolutiontime = moment(resolutiontime, 'YYYY-MM-DD').format(settingsdateformat);
				data.key= index.toString();
				data.id = item.id ? item.id : 0;
				data.alarm_prm_id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.eventdate = item.datetime ? newdate + ' ' + newTime : null;
				data.resolutiontime = item.resolutiontime ? newResolutiontime : null;
				data.actualevent = item.actualEvent ? item.actualEvent : '<Not Set>';
				data.typeofEvent = item.resolutionDetails.predictedEvent ? item.resolutionDetails.predictedEvent : null;
				data.resolutionId = item.resolutionId ? item.resolutionId : null;
				data.resolutionStatus = item.status ? item.status : null;
				data.remarks = item.resolutionDetails.remarks ? item.resolutionDetails.remarks : null;
				data.pidname = item.pidname ? item.pidname : null;
				data.alarmSeverity = item.alarmSeverity;
				data.resolutionuser = item.resolutionuser;
				data.pidsinfoId = item.pidsinfoId;
				data.eventType = item.eventType ? item.eventType : '-';
				data.locationDetails = item.locationDetails ? item.locationDetails : '-';
				data.regioninfoId = item.regioninfoId;
				data.channel_name = item.channel_name;
				data.Chainage = item.locationDetails && item.locationDetails.info ? item.locationDetails.info : '-';
				data.odMeter = item.odMeter;
				data.duration = item.duration;
				data.eventprobablity = item.eventprobablity;
          
				if (data.typeofEvent == null) {
					data.typeofEvent = data.actualevent;
				}

				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';

				markedForRetraining.push(data);
			});
        
			return { ...state, markedForRetraining: markedForRetraining, markedForRetrainingPagination: markedForRetrainingPagination, loading: false, error: false, retraingData : action.payload.data };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length == 0) {
				return { ...state, markedForRetraining: markedForRetraining, markedForRetrainingPagination: markedForRetrainingPagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}
    
	case MARKED_FOR_RETRAINING_LIST_ERROR:
		return { ...state, loading: false, error: true };
      
	case APPROVED_LIST:
		return { ...state, loading: true, error: false };

	case APPROVED_LIST_SUCCESS:
		if (action && action.payload) {
			approvedPagination.total = action.payload.total ? action.payload.total : 0;
			approvedPagination.limit = action.payload.limit ? action.payload.limit : 10;
			approvedPagination.skip = action.payload.skip ? action.payload.skip : 0;
			approvedPagination.current = action.page ? action.page : 1;
			approvedPagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}

		if (action && action.payload) {
			action.payload.data.map( (item,index) => {
				let data = {};
				const date = moment.utc(item.datetime).toDate();
				date.toString();
				const newdate = moment(date, 'YYYY-MM-DD').format(settingsdateformat);
				const newTime = moment(date, 'HH:mm:ss').format(settingstimeformat);
				const resolutiontime = moment.utc(item.resolutiontime).toDate();
				date.toString();
				const newResolutiontime = moment(resolutiontime, 'YYYY-MM-DD').format(settingsdateformat);
				data.key= index.toString();
				data.id = item.id ? item.id : 0;
				data.alarm_prm_id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.eventdate = item.datetime ? newdate + ' ' + newTime : null;
				data.resolutiontime = item.resolutiontime ? newResolutiontime : null;
				data.actualevent = item.actualEvent ? item.actualEvent : '<Not Set>';
				data.typeofEvent = item.resolutionDetails.predictedEvent ? item.resolutionDetails.predictedEvent : null;
				data.resolutionId = item.resolutionId ? item.resolutionId : null;
				data.resolutionStatus = item.status ? item.status : null;
				data.remarks = item.resolutionDetails.remarks ? item.resolutionDetails.remarks : null;
				data.pidname = item.pidname ? item.pidname : null;
				data.alarmSeverity = item.alarmSeverity;
				data.resolutionuser = item.resolutionuser;
				data.pidsinfoId = item.pidsinfoId;
				data.eventType = item.eventType ? item.eventType : '-';
				data.locationDetails = item.locationDetails ? item.locationDetails : '-';
				data.regioninfoId = item.regioninfoId;
				data.channel_name = item.channel_name;
				data.Chainage = item.locationDetails && item.locationDetails.info ? item.locationDetails.info : '-';
				data.odMeter = item.odMeter;
				data.duration = item.duration;
				data.eventprobablity = item.eventprobablity;

          
				if (data.typeofEvent == null) {
					data.typeofEvent = data.actualevent;
				}

				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';

				approved.push(data);
			});
        
			return { ...state, approved: approved, approvedPagination: approvedPagination, loading: false, error: false, approvedData : action.payload.data };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length == 0) {
				return { ...state, approved: approved, approvedPagination: approvedPagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case APPROVED_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case REJECTED_LIST:
		return { ...state, loading: true, error: false };

	case REJECTED_LIST_SUCCESS:
		if (action && action.payload) {
			rejectedPagination.total = action.payload.total ? action.payload.total : 0;
			rejectedPagination.limit = action.payload.limit ? action.payload.limit : 10;
			rejectedPagination.skip = action.payload.skip ? action.payload.skip : 0;
			rejectedPagination.current = action.page ? action.page : 1;
			rejectedPagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}

		if (action && action.payload) {
			action.payload.data.map( (item,index) => {
				let data = {};

				const date = moment.utc(item.datetime).toDate();
				date.toString();
				const newdate = moment(date, 'YYYY-MM-DD').format(settingsdateformat);
				const newTime = moment(date, 'HH:mm:ss').format(settingstimeformat);
				const resolutiontime = moment.utc(item.resolutiontime).toDate();
				date.toString();
				const newResolutiontime = moment(resolutiontime, 'YYYY-MM-DD').format(settingsdateformat);
				data.key= index.toString();
				data.id = item.id ? item.id : 0;
				data.alarm_prm_id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.eventdate = item.datetime ? newdate + ' ' + newTime : null;
				data.resolutiontime = item.resolutiontime ? newResolutiontime : null;
				data.actualevent = item.actualEvent ? item.actualEvent : '<Not Set>';
				data.typeofEvent = item.resolutionDetails.predictedEvent ? item.resolutionDetails.predictedEvent : null;
				data.resolutionId = item.resolutionId ? item.resolutionId : null;
				data.resolutionStatus = item.status ? item.status : null;
				data.remarks = item.resolutionDetails.remarks ? item.resolutionDetails.remarks : null;
				data.pidname = item.pidname ? item.pidname : null;
				data.alarmSeverity = item.alarmSeverity;
				data.resolutionuser = item.resolutionuser;
				data.pidsinfoId = item.pidsinfoId;
				data.eventType = item.eventType ? item.eventType : '-';
				data.locationDetails = item.locationDetails ? item.locationDetails : '-';
				data.regioninfoId = item.regioninfoId;
				data.channel_name = item.channel_name;
				data.Chainage = item.locationDetails && item.locationDetails.info ? item.locationDetails.info : '-';
				data.odMeter = item.odMeter;
				data.duration = item.duration;
				data.eventprobablity = item.eventprobablity;

          
				if (data.typeofEvent == null) {
					data.typeofEvent = data.actualevent;
				}

				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';

				rejected.push(data);
			});
        
			return { ...state, rejected: rejected, rejectedPagination: rejectedPagination, loading: false, error: false, rejectedData : action.payload.data };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length == 0) {
				return { ...state, rejected: rejected, rejectedPagination: rejectedPagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case REJECTED_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case MARKED_FOR_RETRAINING_DETAILS:
		return { ...state, loading: true, error: false };

	case MARKED_FOR_RETRAINING_DETAILS_SUCCESS:
		return { ...state, loading: false, markedForRetrainingDetails: action.payload, error: false };

	case MARKED_FOR_RETRAINING_DETAILS_ERROR:
		return { ...state, loading: false, error: true };

	case APPROVED_DETAILS:
		return { ...state, loading: true, error: false };

	case APPROVED_DETAILS_SUCCESS:
		return { ...state, loading: false, markedForRetrainingDetails: action.payload, error: false };

	case APPROVED_DETAILS_ERROR:
		return { ...state, loading: false, error: true };

	case REJECTED_DETAILS:
		return { ...state, loading: true, error: false };

	case REJECTED_DETAILS_SUCCESS:
		return { ...state, loading: false, markedForRetrainingDetails: action.payload, error: false };

	case REJECTED_DETAILS_ERROR:
		return { ...state, loading: false, error: true };

	case MODEL_DATA:
		return { ...state, loading: true, error: false };
	
	case MODEL_DATA_SUCCESS:
		return { ...state, loading: false, modeldata: action.payload, error: false };
	
	case MODEL_DATA_ERROR:
		return { ...state, loading: false, error: true };

	case RETRAIN_HISTORY_DATA:
		return { ...state, loading: true, error: false };
		
	case RETRAIN_HISTORY_DATA_SUCCESS:
		return { ...state, loading: false, retrain_historydata: action.payload, error: false };
		
	case RETRAIN_HISTORY_DATA_ERROR:
		return { ...state, loading: false, error: true };
	
	case RETRAIN_HISTORY_DATA_DETAILS:
		return { ...state, details:action.payload, loading: false, error: true };
	
	case RETAINED_LIST:
		return { ...state, loading: true, error: false };

	case RETAINED_LIST_SUCCESS:
		if (action && action.payload) {
			retrainedPagination.total = action.payload.total ? action.payload.total : 0;
			retrainedPagination.limit = action.payload.limit ? action.payload.limit : 10;
			retrainedPagination.skip = action.payload.skip ? action.payload.skip : 0;
			retrainedPagination.current = action.page ? action.page : 1;
			retrainedPagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}
		if (action && action.payload.data) {
			action.payload.data.map( (item,index) => {
				let data = {};

				const date = moment.utc(item.datetime).toDate();
				date.toString();
				const newdate = moment(date, 'YYYY-MM-DD').format(settingsdateformat);
				const newTime = moment(date, 'HH:mm:ss').format(settingstimeformat);
				const resolutiontime = moment.utc(item.resolutiontime).toDate();
				date.toString();
				const newResolutiontime = moment(resolutiontime, 'YYYY-MM-DD').format(settingsdateformat);
				data.key= index.toString();
				data.id = item.id ? item.id : 0;
				data.alarm_prm_id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.eventdate = item.datetime ? newdate + ' ' + newTime : null;
				data.resolutiontime = item.resolutiontime ? newResolutiontime : null;
				data.actualevent = item.actualEvent ? item.actualEvent : '<Not Set>';
				data.typeofEvent = item.resolutionDetails.predictedEvent ? item.resolutionDetails.predictedEvent : null;
				data.resolutionId = item.resolutionId ? item.resolutionId : null;
				data.resolutionStatus = item.status ? item.status : null;
				data.remarks = item.resolutionDetails.remarks ? item.resolutionDetails.remarks : null;
				data.pidname = item.pidname ? item.pidname : null;
				data.alarmSeverity = item.alarmSeverity;
				data.resolutionuser = item.resolutionuser;
				data.pidsinfoId = item.pidsinfoId;
				data.eventType = item.eventType ? item.eventType : '-';
				data.locationDetails = item.locationDetails ? item.locationDetails : '-';
				data.regioninfoId = item.regioninfoId;
				data.channel_name = item.channel_name;
				data.Chainage = item.locationDetails && item.locationDetails.info ? item.locationDetails.info : '-';
				data.odMeter = item.odMeter;
				data.duration = item.duration;
				data.eventprobablity = item.eventprobablity;

          
				if (data.typeofEvent == null) {
					data.typeofEvent = data.actualevent;
				}

				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';

				retrained.push(data);
			});
        
			return { ...state, retrained: retrained, retrainedPagination: retrainedPagination, loading: false, error: false, rejectedData : action.payload.data };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length == 0) {
				return { ...state, retrained: retrained, retrainedPagination: retrainedPagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case RETAINED_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case RETRAINED_DETAILS_SUCCESS:
		return { ...state, loading: false, markedForRetrainingDetails: action.payload, error: false };
	
	case INPROGRESS_LIST:
		return { ...state, loading: true, error: false };

	case INPROGRESS_LIST_SUCCESS:
		if (action && action.payload) {
			inProgressPagination.total = action.payload.total ? action.payload.total : 0;
			inProgressPagination.limit = action.payload.limit ? action.payload.limit : 10;
			inProgressPagination.skip = action.payload.skip ? action.payload.skip : 0;
			inProgressPagination.current = action.page ? action.page : 1;
		}

		if (action && action.payload) {
			action.payload.data.map( (item,index) => {
				let data = {};

				const date = moment.utc(item.datetime).toDate();
				date.toString();
				const newdate = moment(date, 'YYYY-MM-DD').format(settingsdateformat);
				const newTime = moment(date, 'HH:mm:ss').format(settingstimeformat);
				const resolutiontime = moment.utc(item.resolutiontime).toDate();
				date.toString();
				const newResolutiontime = moment(resolutiontime, 'YYYY-MM-DD').format(settingsdateformat);
				data.key= index.toString();
				data.id = item.id ? item.id : 0;
				data.alarm_prm_id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.eventdate = item.datetime ? newdate + ' ' + newTime : null;
				data.resolutiontime = item.resolutiontime ? newResolutiontime : null;
				data.actualevent = item.actualEvent ? item.actualEvent : '<Not Set>';
				data.typeofEvent = item.resolutionDetails.predictedEvent ? item.resolutionDetails.predictedEvent : null;
				data.resolutionId = item.resolutionId ? item.resolutionId : null;
				data.resolutionStatus = item.status ? item.status : null;
				data.remarks = item.resolutionDetails.remarks ? item.resolutionDetails.remarks : null;
				data.pidname = item.pidname ? item.pidname : null;
				data.alarmSeverity = item.alarmSeverity;
				data.resolutionuser = item.resolutionuser;
				data.pidsinfoId = item.pidsinfoId;
				data.inProgressStatus = item.inProgressStatus;
				data.eventType = item.eventType ? item.eventType : '-';
				data.locationDetails = item.locationDetails ? item.locationDetails : '-';
				data.regioninfoId = item.regioninfoId;
				data.channel_name = item.channel_name;
				data.Chainage = item.locationDetails && item.locationDetails.info ? item.locationDetails.info : '-';
				data.odMeter = item.odMeter;
				data.duration = item.duration;
				data.eventprobablity = item.eventprobablity;
          
				if (data.typeofEvent == null) {
					data.typeofEvent = data.actualevent;
				}

				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';

				inProgress.push(data);
			});
        
			return { ...state, inProgress: inProgress, inProgressPagination: inProgressPagination, loading: false, error: false, rejectedData : action.payload.data };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length == 0) {
				return { ...state, inProgress: inProgress, inProgressPagination: inProgressPagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case INPROGRESS_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case MODELCONFIG_LIST:
		return { ...state, loading: true, error: false };

	case MODELCONFIG_LIST_SUCCESS:																																																																																																																																																																																																																																																																																		 

		if (action && action.payload) {
			modelConfigPagination.total = action.payload.total ? action.payload.total : 0;
			modelConfigPagination.limit = action.payload.limit ? action.payload.limit : 10;
			modelConfigPagination.skip = action.payload.skip ? action.payload.skip : 0;
			modelConfigPagination.current = action.page ? action.page : 1;
		}

		if (action && action.payload) {
			action.payload.map( (item) => {
				let data = {};
				let events = [];
				let eventLength = item.eventTypes.length - 1;
				item.eventTypes &&  item.eventTypes.map((item, index) => {
					events.push(item);
					if(index != eventLength){
						events.push(',  ');
					}
				}),
				data.id = item.id,
				data.eventTypes = events,
				data.events = item.eventTypes,
				data.pidsinfoId = item.pidsinfoId,
				data.numOfClasses = item.numOfClasses,
				data.datasetType = item.datasetType,
				data.interpolationRequired = item.interpolationRequired,
				data.sampleLength = item.sampleLength,
				data.modelDimension = item.modelDimension,
				// data.modelType = item.modelType,
				data.primaryTotalSampleCount = item.primaryTotalSampleCount ? item.primaryTotalSampleCount : 0,
				data.secondaryTotalSampleCount = item.secondaryTotalSampleCount ? item.secondaryTotalSampleCount : 0,
				data.primaryMinSampleCount = item.primaryMinSampleCount ? item.primaryMinSampleCount : 0,
				data.secondaryMinSampleCount = item.secondaryMinSampleCount ? item.secondaryMinSampleCount : 0,
				data.primarySampleWeightage = item.primarySampleWeightage ? item.primarySampleWeightage : 0,
				data.secondarySampleWeightage = item.secondarySampleWeightage ? item.secondarySampleWeightage : 0,
				data.primaryLearningRate = item.primaryLearningRate ? item.primaryLearningRate : 0,
				data.secondaryLearningRate = item.secondaryLearningRate ? item.secondaryLearningRate : 0,
				data.primaryTrainAccuracy = item.primaryTrainAccuracy ? item.primaryTrainAccuracy : 0,
				data.secondaryTrainAccuracy = item.secondaryTrainAccuracy ? item.secondaryTrainAccuracy : 0,
				data.primaryTestAccuracy = item.primaryTestAccuracy ? item.primaryTestAccuracy : 0,
				data.secondaryTestAccuracy = item.secondaryTestAccuracy ? item.secondaryTestAccuracy : 0,
				data.primaryTrainF1Score = item.primaryTrainF1Score ? item.primaryTrainF1Score : 0,
				data.secondaryTrainF1Score = item.secondaryTrainF1Score ? item.secondaryTrainF1Score : 0,
				data.primaryTestF1Score = item.primaryTestF1Score ? item.primaryTestF1Score : 0,
				data.secondaryTestF1Score = item.secondaryTestF1Score ? item.secondaryTestF1Score : 0,
				data.primaryEpochs = item.primaryEpochs ? item.primaryEpochs : 0,
				data.secondaryEpochs = item.secondaryEpochs ? item.secondaryEpochs : 0,
				data.primaryBatchSize = item.primaryBatchSize ? item.primaryBatchSize : 0,
				data.secondaryBatchSize = item.secondaryBatchSize ? item.secondaryBatchSize : 0,
				data.modelType = item.modeltype && item.modeltype.name ? item.modeltype.name : '-';
				data.modelClassfications = item.modelclassifications && item.modelclassifications.alias ? item.modelclassifications.alias : item.modelclassifications.modelLevel ? item.modelclassifications.modelLevel : '-';
				data.modelclassificationsId=item && item.modelclassificationsId;
				data.modeltypeId=item && item.modeltypeId;

				modelConfigData.push(data);
			});
        
			return { ...state, modelConfigData: modelConfigData, modelConfigPagination: modelConfigPagination, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length == 0) {
				return { ...state, modelConfigData: modelConfigData, modelConfigPagination: modelConfigPagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}
	case MODELCONFIG_LIST_ERROR:
		return { ...state, loading: false, error: true };
	case MODEL_DETAILS:
		return { ...state, loading: true, error: false };
			
	case MODEL_DETAILS_SUCCESS:
		return { ...state, loading: false, modelDetails: action.payload, error: false };
			
	case MODEL_DETAILS_ERROR:
		return { ...state, loading: false, error: true };
	case MODELCONFIG_DETAILS:
		return { ...state, loading: true, error: false };
				
	case MODELCONFIG_DETAILS_SUCCESS:
		return { ...state, loading: false, modelConfigDetails: action.payload, error: false };
				
	case MODELCONFIG_DETAILS_ERROR:
		return { ...state, loading: false, error: true };

	case RELATED_ALARMS:
		return { ...state, loading: true, error: false };
				
	case RELATED_ALARMS_SUCCESS:
		return { ...state, loading: false, error: false };
				
	case RELATED_ALARMS_ERROR:
		return { ...state, loading: false, error: true };

	case RETRAIN_TYPE_DETAILS:
		return { ...state, retraintype_details:action.payload, loading: false, error: true };

	case MODEL_HISTORY:
		return { ...state, loading: true, error: false };
		
	case MODEL_HISTORY_SUCCESS:
		return { ...state, loading: false, modeldata: action.payload, error: false };
		
	case MODEL_HISTORY_ERROR:
		return { ...state, loading: false, error: true };
	case MODEL_INFO_DETAILS : 
		return { ...state, modelInfoDetails: action.payload, loading: false, error: true };
	case MODEL_DEPLOYMENT_INFO:
		return { ...state, modelDeploymentInfo : action.payload};
	
	case MODEL_CLASSIFICATIONS:
		return { ...state, loading: true, error: false };
					
	case MODEL_CLASSIFICATIONS_SUCCESS:
		return { ...state, loading: false, modelClassificationDate : action.payload.data, error: false };
					
	case MODEL_CLASSIFICATIONS_ERROR:
		return { ...state, loading: false, error: true };

	case RETRAIN_HISTORY_BATCH:
		return { ...state, loading: true, error: false };
	case RETRAIN_HISTORY_BATCH_SUCCESS :
		return { ...state, batchData : action.payload, loading: false, error: false };
	case RETRAIN_HISTORY_BATCH_ERROR :
		return { ...state, loading: false, error: true };

	case RETRAIN_MODEL_TYPE:
		return { ...state, loading: true, error: false };
						
	case RETRAIN_MODEL_TYPE_SUCCESS:
		return { ...state, loading: false, modeltype : action.payload, error: false };
						
	case RETRAIN_MODEL_TYPE_ERROR:
		return { ...state, loading: false, error: true };
	
	default: 
		throw Error('Unknown action: ' + action.type);
      
	}
}