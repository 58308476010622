import { Breadcrumb, Button, Col, Empty, Form, Input, Modal, Row, Select, Space, Table, Tooltip, Typography, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useReducer, useState } from 'react';
import { SplitCellsOutlined, HomeOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import channelInfoReducer from './reducer';
import { useAuth } from '../../Context';
import { useFormik } from 'formik';
import axios from 'axios';
import { CHANNEL_CONVERTION_DETAILS } from './constants';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

const { Title } = Typography;

export default function Convertion () {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const navigate = useNavigate();
	const { state } = useLocation();

	const initalState = {channelConvertion : [], pending: false, error: false};
	const [data, dispatch] = useReducer(channelInfoReducer, initalState);
	const {getAllEvents, eventsData} = useAuth();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalAddOpen, setIsModalAddOpen] = useState(false);
	const [convertionDetails, setConvertionDetails] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteActivity, setDeleteActivity] = useState();

	const convertion = [];

	const id = parseInt(state.id);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const eventData = [];
	eventsData && eventsData.map((item) => {
		eventData.push({
			value : item.eventType,
			label : item.eventType
		});
	});

	for (const [key, value] of Object.entries(convertionDetails ? convertionDetails : state && state.data)) {
		convertion.push({
			activity : key,
			value : value[0],
			mappedActivity : value[1]
		});
	}

	useEffect(() => {
		getAllEvents(authtoken);
	},[authtoken]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const columns = [
		{
			title: langdata && langdata.Activity ? langdata.Activity : 'Activity',
			dataIndex: 'activity',
			key: 'activity',
		},
		{
			title: langdata && langdata.Width ? langdata.Width : 'Width',
			dataIndex: 'value',
			key: 'min',
		},
		{
			title: langdata && langdata.MappedActivity ? langdata.MappedActivity : 'Mapped Activity',
			dataIndex: 'mappedActivity',
			key: 'mappedActivity',
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (record) => (
				<Space size="middle">
					<a title={langdata && langdata.Editchannelconvertion ? langdata.Editchannelconvertion : 'Edit channel convertion'} onClick={showModal}><EditOutlined /></a>
					<a title={langdata && langdata.Deletechannelconvertion ? langdata.Deletechannelconvertion : 'Delete channel convertion'} onClick={() => {openDeleteModal(record);}}><DeleteOutlined /></a>
				</Space>
			)
		}
	];

	const openDeleteModal = (value) => {
		setDeleteActivity(value.activity);
		setShowDeleteModal(true);
	};

	const handleDeleteOk = () => {
		setShowDeleteModal(false);
	};

	const handleDelete = () => {
		let convertionData = {};

		for (const [key, value] of Object.entries(convertionDetails ? convertionDetails : state.data)){
			if(key != deleteActivity) {
				convertionData[key] = [value[0],value[1]];
			}
		}

		let data = JSON.stringify({
			'id': id,
			'convertion': convertionData
		});

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/channel-profile-convertion`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 ){
					Modal.success({ title: langdata && langdata.Channelprofileconvertionsuccessfullydeleted ? langdata.Channelprofileconvertionsuccessfullydeleted :'Channel profile convertion successfully deleted' });
					setConvertionDetails(response.data.convertion);
				} else {
					Modal.warning({ title:langdata && langdata.Channelprofileconvertionnotdeleted ? langdata.Channelprofileconvertionnotdeleted : 'Channel profile convertion not deleted' });
				}
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
		setShowDeleteModal(false);
	};


	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			activity: data.channelConvertion && data.channelConvertion.activity ? data.channelConvertion.activity : '-' ,
			width: data.channelConvertion && data.channelConvertion.value,
			mappedActivity: data.channelConvertion && data.channelConvertion.mappedActivity,
		},
		onSubmit: (values) => {
			const {activity, width, mappedActivity} = values;
			let convertionData = {};

			for (const [key, value] of Object.entries(convertionDetails ? convertionDetails : state.data)){
				if(key == activity) {
					convertionData[key] = [width,mappedActivity];
				}else {
					convertionData[key] = [value[0],value[1]];

				}
			}

			let data = JSON.stringify({
				'id': id,
				'convertion': convertionData
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/channel-profile-convertion`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 ){
						Modal.success({ title: langdata && langdata.Channelprofileconvertionupdatedsuccessfully ? langdata.Channelprofileconvertionupdatedsuccessfully : 'Channel profile convertion updated successfully' });
						setConvertionDetails(response.data.convertion);
					} else {
						Modal.warning({ title: langdata && langdata.Channelprofileconvertionnotupdated ? langdata.Channelprofileconvertionnotupdated : 'Channel profile convertion not updated' });
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
			setIsModalOpen(false);
		}
	});

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const showAddModal = () => {
		setIsModalAddOpen(true);
	};

	const handleAddOk = () => {
		setIsModalAddOpen(false);
	};

	const handleAddCancel = () => {
		setIsModalAddOpen(false);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const loadChannelConvertionDetails = ((details) => {
		dispatch({ type : CHANNEL_CONVERTION_DETAILS, payload : details });		
	});

	const addFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			activity: null ,
			width: null,
			mappedActivity: null,
		},
		onSubmit: (values) => {
			const {activity, width, mappedActivity} = values;
			if(activity == null || width == null || mappedActivity == null) {
				Modal.warning({title : langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields : 'Please fill all the mandatory fields'});
				return true;
			} else {

				let convertionData = {};

				for (const [key, value] of Object.entries(convertionDetails ? convertionDetails : state.data)){
					if(key == activity) {
						Modal.warning({title : langdata && langdata.Alreadyexist ? langdata.Alreadyexist : 'Already exist'});
						return true;
					}else {
						convertionData[key] = [value[0],value[1]];
					}
					convertionData[activity] = [width,mappedActivity];
				}

				let data = JSON.stringify({
					'id': id,
					'convertion': convertionData
				});

				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: `${api}/channel-profile-convertion`,
					timeout: timeout,
					headers: { 
						'Content-Type': 'application/json', 
						'Authorization': `Bearer ${authtoken}`
					},
					data : data
				};

				axios.request(config)
					.then((response) => {
						if(response.status == 200 ){
							Modal.success({ title: langdata && langdata.Channelprofileconvertionsuccessfullyadded ? langdata.Channelprofileconvertionsuccessfullyadded : 'Channel profile convertion successfully added' });
							setConvertionDetails(response.data.convertion);
							addFormik.values.activity = null;
							addFormik.values.width = null;
							addFormik.values.mappedActivity = null;
						} else {
							Modal.warning({ title: langdata && langdata.Channelprofileconvertionnotadded ? langdata.Channelprofileconvertionnotadded : 'Channel profile convertion not added' });
						}
					})
					.catch((error) => {
						console.log(error);
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
							});
						}
					});
			}
			setIsModalAddOpen(false);
		}
	});
    
	return (
		<Content>
			<Row 
				style={{
					marginLeft:'10px',
					marginTop: '10px'
				}}            
			>
				<Breadcrumb
					items={[
						{
							href: '/dashboard',
							title: <HomeOutlined />,
						},
						{
							title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.ChannelInfo ? langdata.ChannelInfo : 'Channel Info'} / {state.channel}</a> 
						},
						{
							title: langdata && langdata.EventConversion ? langdata.EventConversion : 'Event Conversion',
						},
					]}
				/>
			</Row>
			<Row style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center',
			}}
			>

				<Col span={8}>
					<Row>
						<Space>
							<SplitCellsOutlined style={{fontSize:'30px'}}/>
							{/* </Col> */}
						</Space>
						<Space>&nbsp;&nbsp;</Space>
					
						{/* <Col style={{marginLeft:'4px'}} span={19}> */}
						<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.EventConversion ? langdata.EventConversion : 'Event Conversion'}</Title>
					</Row>
				</Col>
				<Col style={{ display : 'flex', justifyContent : 'right'}} span={16} >
					<Row>
						<Col>								
							<Tooltip placement="bottom" title={langdata && langdata.Newchannelconvertion ? langdata.Newchannelconvertion : 'New channel convertion'}>
								<Space>

									<Button
										style={{ margin:'5px'}}
										type='primary'
										onClick={showAddModal}
									><PlusOutlined/>{langdata && langdata.New ? langdata.New : 'New'}</Button>
								</Space>
							</Tooltip>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row span={24} style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center'
			}} >
				<Col span={24}>
					{ convertion.length > 0 ? (
						<Table
							scroll={{
								x: 900,
							}} 
							columns={columns} 
							dataSource={convertion} 
							onRow = {(record) => {
								return {
									onClick: () => { 
										loadChannelConvertionDetails(record);
									}
								};
							}}
						/>
					)
						:
						(
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						)}
				</Col>
			</Row>
			<Modal title={langdata && langdata.EditChannelConvertion ? langdata.EditChannelConvertion : 'Edit Channel Convertion'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} destroyOnClose= {true} maskClosable={false}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={formik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}>
				<Form layout="vertical" 
					onSubmit={formik.handleSubmit} 
					// form={form} 
				// onValuesChange={handleFormValuesChange}
				>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Activity ? langdata.Activity : 'Activity'} name="activity" rules={[{ required: true }]}>
								<Select
									// name="activity" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Activity ? langdata.Activity : 'Activity'}
									optionFilterProp="children"
									options={eventData}
									defaultValue={data.channelConvertion && data.channelConvertion.activity}
									onChange={(value) => formik.setFieldValue('activity', value)}
								/>												
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Width ? langdata.Width : 'Width'} name="width" rules={[{ required: true }]}>
								<Input type='number' defaultValue={data.channelConvertion && data.channelConvertion.value} min={0} onChange={formik.handleChange}/>											
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.MappedActivity ? langdata.MappedActivity : 'Mapped activity'} name="mappedActivity" rules={[{ required: true }]}>
								<Select
									// name="activity" 
									showSearch
									filterOption={filterOption}
									placeholder={ langdata && langdata.MappedActivity ? langdata.MappedActivity :'Mapped activity'} 
									optionFilterProp="children"
									options={eventData}
									defaultValue={data.channelConvertion && data.channelConvertion.mappedActivity}
									onChange={(value) => formik.setFieldValue('mappedActivity', value)}
								/>												
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			<Modal title={ langdata && langdata.AddChannelConvertion ? langdata.AddChannelConvertion : 'Add Channel Convertion'} open={isModalAddOpen} onOk={handleAddOk} onCancel={handleAddCancel} destroyOnClose= {true} maskClosable={false}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleAddCancel}>{ langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={addFormik.handleSubmit}>{ langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}>
				<Form layout="vertical" 
					onSubmit={addFormik.handleSubmit} 
					// form={form} 
				// onValuesChange={handleFormValuesChange}
				>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={ langdata && langdata.Activity ? langdata.Activity : 'Activity'} name="activity" rules={[{ required: true }]}>
								<Select
									// name="activity" 
									showSearch
									filterOption={filterOption}
									placeholder={ langdata && langdata.Activity ? langdata.Activity : 'Activity'}
									optionFilterProp="children"
									options={eventData}
									// defaultValue={data.channelConvertion && data.channelConvertion.activity}
									onChange={(value) => addFormik.setFieldValue('activity', value)}
								/>												
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={ langdata && langdata.Width ? langdata.Width : 'Width'} name="width" rules={[{ required: true }]}>
								<Input type='number' placeholder={ langdata && langdata.Width ? langdata.Width : 'Width'} min={0} onChange={addFormik.handleChange}/>											
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={ langdata && langdata.MappedActivity ? langdata.MappedActivity :'Mapped activity'} name="mappedActivity" rules={[{ required: true }]}>
								<Select
									// name="activity" 
									showSearch
									filterOption={filterOption}
									placeholder={ langdata && langdata.MappedActivity ? langdata.MappedActivity :'Mapped activity'}
									optionFilterProp="children"
									options={eventData}
									// defaultValue={data.channelConvertion && data.channelConvertion.mappedActivity}
									onChange={(value) => addFormik.setFieldValue('mappedActivity', value)}
								/>												
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			<Modal title={ langdata && langdata.Confirmdelete ? langdata.Confirmdelete :'Confirm delete'} open={showDeleteModal} onOk={handleDelete} onCancel={handleDeleteOk}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleDeleteOk}>{ langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={handleDelete}>{ langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>
						{ langdata && langdata.Areyousureyouwanttodeletethischanneleventconvertion ? langdata.Areyousureyouwanttodeletethischanneleventconvertion : 'Are you sure you want to delete this channel event convertion?'}
					</Col>
				</Row>
			</Modal>
		</Content>
	);
}