import { CHAINAGES_LIST, CHAINAGES_LIST_SUCCESS, CHAINAGES_LIST_ERROR, GET_TERRAIN, GET_TERRAIN_SUCCESS, GET_TERRAIN_ERROR, UPDATE_TERRAIN, UPDATE_TERRAIN_SUCCESS, UPDATE_TERRAIN_ERROR, ADD_TERRAIN, ADD_TERRAIN_SUCCESS, ADD_TERRAIN_ERROR, DELETE_TERRAIN, DELETE_TERRAIN_SUCCESS, DELETE_TERRAIN_ERROR } from './constants';
import { TERRAIN_CLASSIFICATION_LIST, TERRAIN_CLASSIFICATION_LIST_SUCCESS, TERRAIN_CLASSIFICATION_LIST_ERROR } from './constants';
import { TERRAIN_CLASSIFICATION_DETAILS, TERRAIN_CLASSIFICATION_DETAILS_SUCCESS, TERRAIN_CLASSIFICATION_DETAILS_ERROR } from './constants';
import { UPDATE_TERRAIN_CLASSIFICATION, UPDATE_TERRAIN_CLASSIFICATION_SUCCESS, UPDATE_TERRAIN_CLASSIFICATION_ERROR } from './constants';

export default function terrainClassificationReducer(state, action) {
	let chainages = [];
	let terrainClassifications = [];
	let pagination = {};
  
	switch (action.type) {
	case CHAINAGES_LIST:
		return { ...state, loading: true, error: false };

	case CHAINAGES_LIST_SUCCESS:
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.pipelineChainage = item.pipelineChainage ? item.pipelineChainage : '';
          
				return chainages.push(data);
			});

			let sortedChainages = chainages.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, chainages: sortedChainages, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, chainages: chainages, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case CHAINAGES_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case TERRAIN_CLASSIFICATION_LIST:
		return { ...state, loading: true, error: false };

	case TERRAIN_CLASSIFICATION_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
			pagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.pipelineChainage = item.pipelineChainage || item.pipelineChainage === 0 ? item.pipelineChainage : '';
				data.ODMeter = item.ODMeter;
				data.latitude = item.latitude ? parseFloat(item.latitude) : null;
				data.longitude = item.longitude ? parseFloat(item.longitude) : null;
				data.rouMarker = item.rouMarker ? item.rouMarker : '';
				data.terrainClassification = item.terrainClassification ? item.terrainClassification  : '';
				data.landmarkDescription = item.landmarkDescription ? item.landmarkDescription  : '';
				data.createdAt = item.createdAt ? item.createdAt : null;
				data.updatedAt = item.updatedAt ? item.updatedAt : null;

				return terrainClassifications.push(data);
			});

			let sortedTerrainClassifications = terrainClassifications.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, terrainClassifications: sortedTerrainClassifications, pagination: pagination, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length === 0) {
				return { ...state, terrainClassifications: terrainClassifications, pagination: pagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case TERRAIN_CLASSIFICATION_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case TERRAIN_CLASSIFICATION_DETAILS:
		return { ...state, loading: true, error: false };

	case TERRAIN_CLASSIFICATION_DETAILS_SUCCESS:
		return { ...state, details: action.payload, loading: false, error: false };

	case TERRAIN_CLASSIFICATION_DETAILS_ERROR:
		return { ...state, loading: false, error: true };

	case UPDATE_TERRAIN_CLASSIFICATION:
		return { ...state, loading: true, error: false };

	case UPDATE_TERRAIN_CLASSIFICATION_SUCCESS:
		return { ...state, details: action.payload, loading: false };

	case UPDATE_TERRAIN_CLASSIFICATION_ERROR:
		return { ...state, loading: false, error: true };

	case GET_TERRAIN : 
		return { ...state, loading: true, error: false };

	case GET_TERRAIN_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
		}
		return { ...state, terrains: action.payload, pagination: pagination, loading: false };

	case GET_TERRAIN_ERROR:
		return { ...state, loading: false, error: true };

	case UPDATE_TERRAIN : 
		return { ...state, loading: true, error: false };

	case UPDATE_TERRAIN_SUCCESS:
		return { ...state, loading: false };

	case UPDATE_TERRAIN_ERROR:
		return { ...state, loading: false, error: true };

	case ADD_TERRAIN : 
		return { ...state, loading: true, error: false };

	case ADD_TERRAIN_SUCCESS :
		return { ...state, loading: false };

	case ADD_TERRAIN_ERROR:
		return { ...state, loading: false, error: true };

	case DELETE_TERRAIN : 
		return { ...state, loading: true, error: false };

	case DELETE_TERRAIN_SUCCESS :
		return { ...state, loading: false };

	case DELETE_TERRAIN_ERROR :
		return { ...state, loading: false, error: true };



	default: 
		throw Error('Unknown action: ' + action.type);
      
	}
}