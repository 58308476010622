export const CHAINAGES_LIST = 'CHAINAGES_LIST';
export const CHAINAGES_LIST_SUCCESS = 'CHAINAGES_LIST_SUCCESS';
export const CHAINAGES_LIST_ERROR = 'CHAINAGES_LIST_ERROR';

export const TERRAIN_CLASSIFICATION_LIST = 'TERRAIN_CLASSIFICATION_LIST';
export const TERRAIN_CLASSIFICATION_LIST_SUCCESS = 'TERRAIN_CLASSIFICATION_LIST_SUCCESS';
export const TERRAIN_CLASSIFICATION_LIST_ERROR = 'TERRAIN_CLASSIFICATION_LIST_ERROR';

export const TERRAIN_CLASSIFICATION_DETAILS = 'TERRAIN_CLASSIFICATION_DETAILS';
export const TERRAIN_CLASSIFICATION_DETAILS_SUCCESS = 'TERRAIN_CLASSIFICATION_DETAILS_SUCCESS';
export const TERRAIN_CLASSIFICATION_DETAILS_ERROR = 'TERRAIN_CLASSIFICATION_DETAILS_ERROR';

export const UPDATE_TERRAIN_CLASSIFICATION = 'UPDATE_TERRAIN_CLASSIFICATION';
export const UPDATE_TERRAIN_CLASSIFICATION_SUCCESS = 'UPDATE_TERRAIN_CLASSIFICATION_SUCCESS';
export const UPDATE_TERRAIN_CLASSIFICATION_ERROR = 'UPDATE_TERRAIN_CLASSIFICATION_ERROR';

export const ADD_TERRAIN = 'ADD_TERRAIN';
export const ADD_TERRAIN_SUCCESS = 'ADD_TERRAIN_SUCCESS';
export const ADD_TERRAIN_ERROR = 'ADD_TERRAIN_ERROR';

export const GET_TERRAIN = 'GET_TERRAIN';
export const GET_TERRAIN_SUCCESS = 'GET_TERRAIN_SUCCESS';
export const GET_TERRAIN_ERROR = 'GET_TERRAIN_ERROR';

export const UPDATE_TERRAIN = 'UPDATE_TERRAIN';
export const UPDATE_TERRAIN_SUCCESS = 'UPDATE_TERRAIN_SUCCESS';
export const UPDATE_TERRAIN_ERROR = 'UPDATE_TERRAIN_ERROR';

export const DELETE_TERRAIN = 'DELETE_TERRAIN';
export const DELETE_TERRAIN_SUCCESS = 'DELETE_TERRAIN_SUCCESS';
export const DELETE_TERRAIN_ERROR = 'DELETE_TERRAIN_ERROR';