export const ALARM_RULE = 'ALARM_RULE';
export const ALARM_RULE_SUCCESS = 'ALARM_RULE_SUCCESS';
export const ALARM_RULE_ERROR = 'ALARM_RULE_ERROR';

export const ADD_ALARM_RULE = 'ADD_ALARM_RULE';
export const ADD_ALARM_RULE_SUCCESS = 'ADD_ALARM_RULE_SUCCESS';
export const ADD_ALARM_RULE_ERROR = 'ADD_ALARM_RULE_ERROR';

export const DELETE_ALARM_RULE = 'DELETE_ALARM_RULE';
export const DELETE_ALARM_RULE_SUCCESS = 'DELETE_ALARM_RULE_SUCCESS';
export const DELETE_ALARM_RULE_ERROR = 'DELETE_ALARM_RULE_ERROR';

export const UPDATE_ALARM_RULE = 'UPDATE_ALARM_RULE';
export const UPDATE_ALARM_RULE_SUCESS = 'UPDATE_ALARM_RULE_SUCESS';
export const UPDATE_ALARM_RULE_ERROR = 'UPDATE_ALARM_RULE_ERROR';
