import { ALARM_VIEW, ALARM_VIEW_ERROR, ALARM_VIEW_SUCCESS, GRAPH_DATA, GRAPH_DATA_ERROR, GRAPH_DATA_SUCCESS, REALTIME_ALARM_DETAILS, REALTIME_ALARM_ERROR, REALTIME_ALARM_LIST, REALTIME_ALARM_SUCCESS, RELATED_ALARMS_ANALYTICS, RELATED_ALARMS_ANALYTICS_ERROR, RELATED_ALARMS_ANALYTICS_SUCCESS } from './constants';
import activityicons from '../../activitytypes';

export default function alarmdata(state, action) {
	let data = [];
	let graph1PipelineChainage = [];
	let graph1AlarmCount = [];
	let graph2Date = [];
	let graph2AlarmCount = [];
	let temp = '';
	let date;
	let count = 0;
	let length = 0;

	switch (action.type) {
      
	case REALTIME_ALARM_LIST:
		return { ...state, pending: true };
	case REALTIME_ALARM_SUCCESS:
		return { ...state, relatimealarmdata: action.payload, pending: false };
	case REALTIME_ALARM_ERROR:
		return { ...state, error: true, pending:false };
	case REALTIME_ALARM_DETAILS:
		// eslint-disable-next-line array-callback-return
		state.relatimealarmdata.map((item) => {
			if (item.id == action.payload) {
				activityicons && activityicons.map((eventactivity)=>{
					if(item.eventType==eventactivity.type){
						data = item;
						data.activityUrl= eventactivity.type && item.eventType==eventactivity.type ? eventactivity.severity[item.alarmSeverity] : '-';
					}});
			}
		});
		return { ...state, relatimealarmdata_details : data};
	case GRAPH_DATA :
		return { ...state, pending: true };
	case GRAPH_DATA_SUCCESS :
		
		if(action.payload) {
			action.payload.graph1Data.map((item) => {
				graph1PipelineChainage.push(item.pipelineChainage);
				graph1AlarmCount.push(item.alarmsCount);
			});

			length = action.payload.graph2Data.length;
			action.payload.graph2Data.map((item, index) => {
				date = new Date(item.datetime).toLocaleDateString('en-UK', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric'
				});
				if(temp === date) {
					temp = date;
					count = count + parseInt(item.alarmsCount);
					if (index === (length -1)) {
						graph2Date.push(date);
						graph2AlarmCount.push(count);
					}
				}
				else {
					if ( index === 0) {
						count = parseInt(item.alarmsCount);
						temp = date;
						if (length === 1) {
							graph2Date.push(date);
							graph2AlarmCount.push(count);
						}
					}
					else {
						graph2Date.push(temp);
						graph2AlarmCount.push(count);
						temp = date;
						count = parseInt(item.alarmsCount);
					}
				}								
			});
		}
		return { ...state, graph1PipelineChainage: graph1PipelineChainage,graph1AlarmCount : graph1AlarmCount,graph2Date : graph2Date, graph2AlarmCount : graph2AlarmCount, pending: false };
	case GRAPH_DATA_ERROR :
		return { ...state, error: true, pending:false };

	case ALARM_VIEW:
		return { ...state, pending: true };
	case ALARM_VIEW_SUCCESS:
		return { ...state, alarm_view: action.payload, pending: false };
	case ALARM_VIEW_ERROR:
		return { ...state, error: true, pending:false };
	case RELATED_ALARMS_ANALYTICS:
		return { ...state, pending: true, error: false };				
	case RELATED_ALARMS_ANALYTICS_SUCCESS:
		return { ...state, pending: false, error: false };				
	case RELATED_ALARMS_ANALYTICS_ERROR:
		return { ...state, pending: false, error: true };
	
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}