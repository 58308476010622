export const SPEED_CLASSIFICATIONS_LIST = 'SPEED_CLASSIFICATIONS_LIST';
export const SPEED_CLASSIFICATIONS_LIST_SUCCESS = 'SPEED_CLASSIFICATIONS_LIST_SUCCESS';
export const SPEED_CLASSIFICATIONS_LIST_ERROR = 'SPEED_CLASSIFICATIONS_LIST_ERROR';

export const SPEED_CLASSIFICATION_DETAILS = 'SPEED_CLASSIFICATION_DETAILS';
export const SPEED_CLASSIFICATION_DETAILS_SUCCESS = 'SPEED_CLASSIFICATION_DETAILS_SUCCESS';
export const SPEED_CLASSIFICATION_DETAILS_ERROR = 'SPEED_CLASSIFICATION_DETAILS_ERROR';

export const ADD_SPEED_CLASSIFICATION_DETAILS = 'ADD_SPEED_CLASSIFICATION_DETAILS';
export const ADD_SPEED_CLASSIFICATION_DETAILS_SUCCESS = 'ADD_SPEED_CLASSIFICATION_DETAILS_SUCCESS';
export const ADD_SPEED_CLASSIFICATION_DETAILS_ERROR = 'ADD_SPEED_CLASSIFICATION_DETAILS_ERROR';

export const UPDATE_SPEED_CLASSIFICATION = 'UPDATE_SPEED_CLASSIFICATION';
export const UPDATE_SPEED_CLASSIFICATION_SUCCESS = 'UPDATE_SPEED_CLASSIFICATION_SUCCESS';
export const UPDATE_SPEED_CLASSIFICATION_ERROR = 'UPDATE_SPEED_CLASSIFICATION_ERROR';

export const DELETE_SPEED_CLASSIFICATION = 'DELETE_SPEED_CLASSIFICATION';
export const DELETE_SPEED_CLASSIFICATION_SUCCESS = 'DELETE_SPEED_CLASSIFICATION_SUCCESS';
export const DELETE_SPEED_CLASSIFICATION_ERROR = 'DELETE_SPEED_CLASSIFICATION_ERROR';
