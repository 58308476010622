export const CREATE_SIMULATION = 'CREATE_SIMULATION';
export const CREATE_SIMULATION_SUCCESS = 'CREATE_SIMULATION_SUCCESS';
export const CREATE_SIMULATION_ERROR = 'CREATE_SIMULATION_ERROR';

export const UPDATE_SIMULATION = 'UPDATE_SIMULATION';
export const UPDATE_SIMULATION_SUCCESS = 'UPDATE_SIMULATION_SUCCESS';
export const UPDATE_SIMULATION_ERROR = 'UPDATE_SIMULATION_ERROR';

export const SIMULATION_LIST = 'SIMULATION_LIST';
export const SIMULATION_LIST_SUCCESS = 'SIMULATION_LIST_SUCCESS';
export const SIMULATION_LIST_ERROR = 'SIMULATION_LIST_ERROR';


export const SIMULATION_EDIT = 'SIMULATION_EDIT';
export const SIMULATION_EDIT_SUCCESS = 'SIMULATION_EDIT_SUCCESS';
export const SIMULATION_EDIT_ERROR = 'SIMULATION_EDIT_ERROR';