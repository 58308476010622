import React, { useEffect, useReducer, useState } from 'react';

import { Button, Empty, Row, Col, Form, Typography, Input, Space, Table, Modal, Spin, Popover, Avatar, Card, message } from 'antd';
import { Content } from 'antd/es/layout/layout';

import welltypeManagementReducer from './reducer';

import { ToolOutlined, EditOutlined, InfoOutlined } from '@ant-design/icons';
import { WELL_TYPE_LIST, WELL_TYPE_LIST_SUCCESS, WELL_TYPE_LIST_ERROR, WELL_TYPE_DETAILS, WELL_TYPE_DETAILS_SUCCESS, WELL_TYPE_DETAILS_ERROR, UPDATE_WELL_TYPE, UPDATE_WELL_TYPE_SUCCESS, UPDATE_WELL_TYPE_ERROR } from './constants';
import axios from 'axios';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useAuth } from '../../Context';

const { Title } = Typography;

export default function WellTypeManagement() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = {welltype : [], pagination: { total: 0, limit: 10, skip: 0, current:1 }, pending: false, error: false, details : null };
	const [data, dispatch] = useReducer(welltypeManagementReducer, initalState);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const navigate = useNavigate();
	
	useAuth();

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const [showEditWellTypeModal, setShowEditWellTypeModal] = useState(false);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	useEffect(() => {
		getWellTypes(1);
	},[authtoken]);

	useEffect(() => {
		if(featuresModule.wellVisualizationEnabled == false) {
			navigate('/dashboard');
		}

		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 404);
	}, []);

	const getWellTypes = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		dispatch({ type : WELL_TYPE_LIST });
		
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/welltypes?$limit=${_pagination.limit}&$skip=${_pagination.skip}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: {
			}
		};
      
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch ({ type : WELL_TYPE_LIST_SUCCESS, payload : response.data.data });
				} else {
					dispatch({ type : WELL_TYPE_LIST_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : WELL_TYPE_LIST_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const loadWellTypeDetails = ((id) =>  {
		let details = null;

		dispatch({ type: WELL_TYPE_DETAILS });

		if (data && data.welltype) {
			data.welltype && data.welltype.map((item) => {
				if (item.id === id) {
					details = item;
				}

				return true;
			});
      
			dispatch({ type: WELL_TYPE_DETAILS_SUCCESS, payload: details });
		} else {
			dispatch({ type: WELL_TYPE_DETAILS_ERROR });
		}
	});

	const columns = [
		{
			title: langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex: 'name',
			key: 'name',
		}
	];
	permission === 'superadmin' ?
		columns.push(
			{
				title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
				key: 'action',
				render: () => (
					<Space size="middle">
						{ permission === 'superadmin' ? <a title={langdata && langdata.EditWellType ? langdata.EditWellType : 'Edit Well Type'} onClick={openEditWellTypeModal}><EditOutlined/></a> : null }
					</Space>
				)
			}
		)
		: null ;

	const openEditWellTypeModal = () => {
		setShowEditWellTypeModal(true);
	};

	const closeEditWellTypeModal = () => {
		setShowEditWellTypeModal(false);
	};

	const updateWellType = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: data.details ? data.details.name : '',
		},
		onSubmit: (values) => {
			const id = data.details ? data.details.id : 0;
			const name = values.name ? values.name : null;
      
			if (!name || name == null) {
				Modal.warning({ title: langdata && langdata.Pleaseprovideavalidwelltypename ? langdata.Pleaseprovideavalidwelltypename :  'Please provide a valid  well type name.' });
				return false;
			}

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/welltypes/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					name: name
				}
			};

			dispatch( { type: UPDATE_WELL_TYPE });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: UPDATE_WELL_TYPE_SUCCESS, payload: response});
						Modal.success({ title: langdata && langdata.Welltypeupdatedsuccessfully ? langdata.Welltypeupdatedsuccessfully : 'Well type updated successfully.' });
						closeEditWellTypeModal();
						getWellTypes({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					} else {
						dispatch({ type: UPDATE_WELL_TYPE_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletoupdatethewelltype ? langdata.Unabletoupdatethewelltype : 'Unable to update the well type.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: UPDATE_WELL_TYPE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}  
				});
		}
	});

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p> {langdata && langdata.WellTypeManagementDescription1 ? langdata.WellTypeManagementDescription1 : 'Well type management displays the details of different well types within company'} </p>
			{ permission === 'superadmin' ?
				<>
					< br/>
					<p>{langdata && langdata.WellTypeManagementDescription2 ? langdata.WellTypeManagementDescription2 : 'Well type name can be modify using the edit button.'}</p> </> : null }
		</Card>
	);

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center',
				}}
				>
					<Col span={8}>
						<Row>
							<Space>
								<ToolOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.WellTypeManagement ? langdata.WellTypeManagement : 'Well Type Management'}</Title>
						</Row>
					</Col>
					<Col span={16}>
						<Row justify="end">
							<Space>
								<Typography>
									<Popover title={langdata && langdata.WellTypeManagement ? langdata.WellTypeManagement : 'Well Type  Management'}  content={infoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover>
								</Typography>
							</Space>
						</Row>
					</Col>
				</Row>
				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data && data.welltype.length > 0 ? (
							<Table 
								scroll={{
									x: 900,
								}}
								columns = { columns } 
								dataSource = { data.welltype } 
								pagination = { data.welltype.length > 0 ? data.pagination : 0 }
								onRow = { (record) => {
									return {
										onClick: () => { 
											loadWellTypeDetails(record.id);
										}
									};
								}} />
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>
			</Content>

			<Modal
				title={langdata && langdata.EditWellType ? langdata.EditWellType : 'Edit Well Type'}
				centered
				open={showEditWellTypeModal}
				onOk={{closeEditWellTypeModal}}
				onCancel={closeEditWellTypeModal}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeEditWellTypeModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{ permission === 'superadmin' || permission === 'admin' ? <Button type="primary" onClick={updateWellType.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button> : null }
					</Space>
				]}
				width={500}
			>
				<Form layout="vertical" onSubmit={updateWellType.handleSubmit}>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.WellTypeName ? langdata.WellTypeName : 'Well Type Name'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthewelltypename ? langdata.Pleaseenterthewelltypename : 'Please enter the well type name' }]} required>
								<Input name="name" onChange={updateWellType.handleChange} placeholder={langdata && langdata.Pleaseenterthewelltypename ? langdata.Pleaseenterthewelltypename : 'Please enter the well type name'} value={updateWellType.values.name} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Spin fullscreen spinning={data.pending} />
		</>
	);
}