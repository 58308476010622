export const MICROSEISMIC_WELL_FRACTURING_LIST = 'MICROSEISMIC_WELL_FRACTURING_LIST';
export const MICROSEISMIC_WELL_FRACTURING_LIST_SUCCESS = 'MICROSEISMIC_WELL_FRACTURING_LIST_SUCCESS';
export const MICROSEISMIC_WELL_FRACTURING_LIST_ERROR = 'MICROSEISMIC_WELL_FRACTURING_LIST_ERROR';

export const MICROSEISMIC_WELL_FRACTURING_CREATE = 'MICROSEISMIC_WELL_FRACTURING_CREATE';
export const MICROSEISMIC_WELL_FRACTURING_CREATE_SUCCESS = 'MICROSEISMIC_WELL_FRACTURING_CREATE_SUCCESS';
export const MICROSEISMIC_WELL_FRACTURING_CREATE_ERROR = 'MICROSEISMIC_WELL_FRACTURING_CREATE_ERROR';

export const UPDATE_MICROSEISMIC_WELL_FRACTURING_DATA = 'UPDATE_MICROSEISMIC_WELL_FRACTURING_DATA';

export const MICROSEISMIC_WELL_FRACTURING_DELETE = 'MICROSEISMIC_WELL_FRACTURING_DELETE';
export const MICROSEISMIC_WELL_FRACTURING_DELETE_SUCCESS = 'MICROSEISMIC_WELL_FRACTURING_DELETE_SUCCESS';
export const MICROSEISMIC_WELL_FRACTURING_DELETE_ERROR = 'MICROSEISMIC_WELL_FRACTURING_DELETE_ERROR';

export const MICROSEISMIC_GRAPH = 'MICROSEISMIC_GRAPH';

export const FRACTURING_LOCATION_DATA = 'FRACTURING_LOCATION_DATA';
export const FRACTURING_LOCATION_DATA_SUCCESS = 'FRACTURING_LOCATION_DATA_SUCCESS';
export const FRACTURING_LOCATION_DATA_ERROR = 'FRACTURING_LOCATION_DATA_ERROR';

export const MICRO_SEISMIC_INGRSS_GRAPH_CONFIG = 'MICRO_SEISMIC_INGRSS_GRAPH_CONFIG';
