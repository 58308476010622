import React, { useEffect, useReducer, useState } from 'react';

import { Empty, Row, Col, Select, Typography, Button, Space, Table, Form, Input, Modal, Tooltip, message, Spin, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { EditOutlined, DeleteOutlined,LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import escalationmatrix from './reducer';
import { ESCALATION_MATRIX_DELETE, ESCALATION_MATRIX_DELETE_ERROR, ESCALATION_MATRIX_DELETE_SUCCESS, ESCALATION_MATRIX_DETAILS, ESCALATION_MATRIX_LIST, ESCALATION_MATRIX_LIST_ERROR, ESCALATION_MATRIX_LIST_SUCCESS, ESCALATION_MATRIX_UPDATE, ESCALATION_MATRIX_UPDATE_ERROR, ESCALATION_MATRIX_UPDATE_SUCCESS, SMTP_LIST, SMTP_LIST_ERROR, SMTP_LIST_SUCCESS, SMTP_UPDATE, SMTP_UPDATE_ERROR, SMTP_UPDATE_SUCCESS, USER_LIST, USER_LIST_ERROR, USER_LIST_SUCCESS } from './constants';
import { useAuth } from '../Context';
import { PicRightOutlined, PlusOutlined, InfoOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;

// const pids =[
// 	{
// 		value: 'id1',
// 		label: '1',
// 	},
// 	{
// 		value: 'id2',
// 		label: '2',
// 	},
// ];


let matirx_data_pagination = {};


export default function GradeSystem() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const [openEditDrawer, setOpenEditDrawer] = useState(false);
	const [openSMTPEditDrawer, setOpenSMTPEditDrawer] = useState(false);
	const [openAddMatrixEditDrawer, setOpenAddMatrixDrawer] = useState(false);
	// const [value, setValue] = useState(1);
	const [mailtype, setMailType] = useState('Alarm Escalation');
	const navigate = useNavigate();
	const initalState = {escalation_matrix:[], users:[],smtp_data:null, details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(escalationmatrix, initalState);
	const {getUserTypes, userTypeData} = useAuth();
	const [userstypeId, setUserType] = useState({value:'CRO', label:'CRO', time:'Upto 6 Hours', id:3});
	const [emaildata, setEmaildata] = useState({});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteid, setDeleteId] = useState(null);
	const [passwordVisible, setPasswordVisible] = React.useState(false);
	const [settings, setSMTPSettings] = useState({method:'SMTP',server:'',port:'',username:'',password:''});

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [filterMailType, setFilterMailType]= useState(null);
	const [search, setSearch] = useState('');

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';
	const oneviewtypeLabel = oneviewType && oneviewType.toLowerCase() == 'pipeline' ? langdata && langdata.Pipeline : oneviewType.toLowerCase() == 'airport' ? langdata && langdata.Airport : oneviewType.toLowerCase() == 'border' ? langdata && langdata.Border : 'pipeline';

	const Method=[
		{
			value:'SMTP',
			label: langdata && langdata.SMTP ? langdata.SMTP : 'SMTP'
		}
	];

	const NotificationType = [
		{
			value: 'Alarm Escalation',
			label:  langdata && langdata.AlarmEscalation ? langdata.AlarmEscalation : 'Alarm Escalation',
		},
		{
			value: 'Attendance Report',
			label: langdata && langdata.AttendanceReport ? langdata.AttendanceReport : 'Attendance Report',
		},
		{
			value: 'Server Health',
			label: langdata && langdata.ServerHealth ? langdata.ServerHealth : 'Server Health',
		}
	];
	
	const NotificationFilterType = [
		{
			value: null,
			label: langdata && langdata.ALL ? langdata.ALL : 'ALL',
		},
		{
			value: 'Alarm Escalation',
			label: langdata && langdata.AlarmEscalation ? langdata.AlarmEscalation : 'Alarm Escalation',
		},
		{
			value: 'Attendance Report',
			label: langdata && langdata.AttendanceReport ? langdata.AttendanceReport : 'Attendance Report',
		},
		{
			value: 'Server Health',
			label: langdata && langdata.ServerHealth ? langdata.ServerHealth : 'Server Health',
		}
	];

	const columns = [
		{
			title: langdata && langdata.UserType ? langdata.UserType : 'User Type',
			dataIndex: 'type',
			key: 'type',
			render: (type) =>(
				<Space>
					<text>{type.toUpperCase()}</text>
				</Space>
			)

		},
		{
			title: langdata && langdata.NotificationType ? langdata.NotificationType : 'Notification Type',
			dataIndex: 'notificationtype',
			key: 'notificationtype',
		},
    
		{
			title: langdata && langdata.Email ? langdata.Email : 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (email, record) => (
				(permission==='superadmin' || permission === 'admin' ? 

					<Space style={{justifyContent:'left',display:'flex'}} size="middle">
						<a title={langdata && langdata.Email ? langdata.Email : 'email'} onClick={()=>{showEditDrawer(record);}}>{email}</a>
						{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
					</Space> : <a>{email}</a> )
			)
		},
		{
			title: langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex: 'name',
			key: 'name',
		},

		{
			title: langdata && langdata.Hours ? langdata.Hours :  'Hours',
			dataIndex: 'hours',
			key: 'hours',
		},
		// {
		// 	title: 'Pipeline',
		// 	dataIndex: 'pipeline',
		// 	key: 'pipeline',
		// },

		(permission==='superadmin' || permission === 'admin' ? 
			{
				title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
				key: 'action',
				render: (_, record) => (
					<Space stzyle={{justifyContent:'left',display:'flex'}} size="middle">
						<a title={langdata && langdata.EditEscalationMatrix ? langdata.EditEscalationMatrix : 'Edit Escalation Matrix'} onClick={()=>{showEditDrawer(record);}}><EditOutlined/></a>
						<a title={langdata && langdata.Delete ? langdata.Delete : 'Delete'} onClick={()=>{HandleDelete(record);}}><DeleteOutlined/></a>
						{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
					</Space>
				)
			} : {}),
	];

	useEffect(() => {
		if(featuresModule.escalationMatrixEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 10);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		else{
    
			getEscalationList(1);
			getUserTypes(authtoken);
			getUsers();
			getSMTPDetails();

		}

	},[authtoken,filterMailType, search]);

	useEffect(()=>{
		getUsers();

	},[authtoken,userstypeId]);


	const usertype = [];
	userTypeData && userTypeData.data.map((item) => {
		if( oneviewtypeLabel.toLowerCase() == 'pipeline') {
			if(item.id==3 || item.id==6 || item.id==9 || item.id==10 || item.id==11 || item.id==12 || item.id==2){

				if(item.id==3){
					usertype.push({
						value : item.userType,
						label : item.userType.toUpperCase(),
						time: 'Upto 6 Hours',
						id:item.id
					});
				}
				else if(item.id==6){
					usertype.push({
						value : item.userType,
						label : item.userType.toUpperCase(),
						time: '6 Hours and up to next 18 hours',
						id:item.id
					});
				}else if(item.id==9){
					usertype.push({
						value : item.userType,
						label : item.userType.toUpperCase(),
						time: '24 Hours and up to next 24 hours',
						id:item.id
					});
				}else if(item.id==10){
					usertype.push({
						value : item.userType,
						label : item.userType.toUpperCase(),
						time: '48 Hours and up to next 24 hours',
						id:item.id
					});
				}else if(item.id==11){
					usertype.push({
						value : item.userType,
						label : item.userType.toUpperCase(),
						time: '72 Hours and up to next 24 hours',
						id:item.id
					});
				}else if(item.id==12){
					usertype.push({
						value : item.userType,
						label : item.userType.toUpperCase(),
						time: '96 Hours',
						id:item.id
					});
				}else if(item.id==2){
					usertype.push({
						value : item.userType,
						label : item.userType.toUpperCase(),
						time: '96 Hours',
						id:item.id
					});
				}
			} 
		} else {
			if(item.id==3 || item.id==6 || item.id==2){

				if(item.id==3){
					usertype.push({
						value : item.userType,
						label : item.userType.toUpperCase(),
						time: 'Upto 6 Hours',
						id:item.id
					});
				}
				else if(item.id==6){
					usertype.push({
						value : item.userType,
						label : item.userType.toUpperCase(),
						time: '6 Hours and up to next 18 hours',
						id:item.id
					});
				}else if(item.id==2){
					usertype.push({
						value : item.userType,
						label : item.userType.toUpperCase(),
						time: '96 Hours',
						id:item.id
					});
				}
			} 
		}
	});



	const showEditDrawer = (record) => {
		dispatch({type:ESCALATION_MATRIX_DETAILS, payload:record});
		setMailType(record.notificationtype);
		setUserType({label: record.type == 3 ? 'CRO' : record.type== 6 ? 'ROU' : record.type == 9 ? 'Station-In-Charge' : record.type == 10 ? 'Pipeline-HO' 
			: record.type == 11 ? 'Pipeline-In-Charge' : record.type == 12 ? 'HQO-HSE' : record.type == 2 ? 'ADMIN' 
				: record.type.toUpperCase(), value: record.type == 3 ? 'CRO' : record.type== 6 ? 'ROU' : record.type == 9 ? 'Station-In-Charge' : record.type == 10 ? 'Pipeline-HO' 
			: record.type == 11 ? 'Pipeline-In-Charge' : record.type == 12 ? 'HQO-HSE' : record.type == 2 ? 'ADMIN'    
				: record.type, time:record.hours});
		setEmaildata({label:record.email, value:record.email});
		setOpenEditDrawer(true);
	};

	const onCloseEditDrawer = () => {
		setOpenEditDrawer(false);
		setOpenSMTPEditDrawer(false);
		setOpenAddMatrixDrawer(false);
		setMailType('Alarm Escalation');
	};

	const onSaveEditDrawer = () => {

		if (!emaildata.label || emaildata.label == null || emaildata.label == '') {
			Modal.warning({ title: langdata && langdata.Pleaseselectavalidemail ? langdata.Pleaseselectavalidemail :  'Please select a valid email.' });
			return false;
		}

		if (!mailtype || mailtype == null || emaildata == '') {
			Modal.warning({ title: langdata && langdata.Pleaseselectamailtype ? langdata.Pleaseselectamailtype : 'Please select a mail type' });
			return false;
		}

		let hour =[];
		const usertypevalue = mailtype && mailtype == 'Alarm Escalation' ? userstypeId.value : userstypeId.value;
    
		if(mailtype == 'Alarm Escalation' && userstypeId.time == ''){
      
			usertype && usertype.map((item)=>{
				if(item.value==userstypeId.value){
					hour.push(item.time);
				}
			});
      
		}
		const hoursdata = mailtype && mailtype == 'Alarm Escalation' ? userstypeId.time && userstypeId.time ? userstypeId.time : hour[0] : '';

		let config = {
			method: 'patch',
			maxBodyLength: Infinity,
			url: `${api}/escalationmatrix/${data.details && data.details.id}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: {
				notificationType: mailtype,
				usertype: usertypevalue,
				firstName: emaildata.firstName,
				lastName:emaildata.lastName,
				usersId:emaildata.usersId,
				pidinfoIds:[],
				pipelineId:emaildata.pipelineId,
				hours:hoursdata,
				email:emaildata.value



			}
		};

		dispatch( { type: ESCALATION_MATRIX_UPDATE });

		axios
			.request(config)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					dispatch({type: ESCALATION_MATRIX_UPDATE_SUCCESS, payload:response});
					getEscalationList({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					Modal.success({ title: langdata && langdata.EscalationMatrixupdatedsuccessfully ? langdata.EscalationMatrixupdatedsuccessfully : 'Escalation Matrix updated successfully.' });
					setOpenEditDrawer(false);
					// setEmaildata({})
					// setUserType({})
				} else {
					dispatch({type: ESCALATION_MATRIX_UPDATE_ERROR});
					Modal.warning({ title:  langdata && langdata.UnabletoupdatetheEscalationMatrix ? langdata.UnabletoupdatetheEscalationMatrix : 'Unable to update the Escalation Matrix.' });  
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({type: ESCALATION_MATRIX_UPDATE_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});



		setOpenEditDrawer(false);
		setOpenSMTPEditDrawer(false);
		setOpenAddMatrixDrawer(false);
		setMailType('Alarm Escalation');

	};



	const onSaveAddMatrixDrawer = () => {

		if (!emaildata.label || emaildata.label == null || emaildata.label == '') {
			Modal.warning({ title:  langdata && langdata.Pleaseselectavalidemail ? langdata.Pleaseselectavalidemail : 'Please select a valid email.' });
			return false;
		}

		if (!mailtype || mailtype == null || mailtype == '') {
			Modal.warning({ title: langdata && langdata.Pleaseselectamailtype ? langdata.Pleaseselectamailtype : 'Please select a mail type' });
			return false;
		}

		let hour =[];
		const usertypevalue = mailtype && mailtype == 'Alarm Escalation' ? userstypeId.value : userstypeId.value;

		if (!usertypevalue || usertypevalue == null || usertypevalue == '') {
			Modal.warning({ title: langdata && langdata.Pleaseselectausertype ? langdata.Pleaseselectausertype :  'Please select a user type' });
			return false;
		}
    
		if(mailtype == 'Alarm Escalation' && userstypeId.time == ''){
      
			usertype && usertype.map((item)=>{
				if(item.value==userstypeId.value){
					hour.push(item.time);
				}
			});
      
		}
		const hoursdata = mailtype && mailtype == 'Alarm Escalation' ? userstypeId.time && userstypeId.time ? userstypeId.time : hour[0] : '';

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/escalationmatrix`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: {
				notificationType: mailtype,
				usertype: usertypevalue,
				firstName: emaildata.firstName,
				lastName:emaildata.lastName,
				usersId:emaildata.usersId,
				pidinfoIds:[],
				pipelineId:emaildata.pipelineId,
				hours:hoursdata,
				email:emaildata.value

			}
		};

		dispatch( { type: ESCALATION_MATRIX_UPDATE });

		axios
			.request(config)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					dispatch({type: ESCALATION_MATRIX_UPDATE_SUCCESS, payload:response});
					getEscalationList({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					Modal.success({ title: langdata && langdata.EscalationMatrixaddedsuccessfully ? langdata.EscalationMatrixaddedsuccessfully :  'Escalation Matrix added successfully.' });
					setOpenEditDrawer(false);
					// setEmaildata({})
					// setUserType({})
				} else {
					dispatch({type: ESCALATION_MATRIX_UPDATE_ERROR});
					Modal.warning({ title: langdata && langdata.UnabletoaddtheEscalationMatrix ? langdata.UnabletoaddtheEscalationMatrix : 'Unable to add the Escalation Matrix.' });  
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({type: ESCALATION_MATRIX_UPDATE_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}  
			});



		setOpenEditDrawer(false);
		setOpenSMTPEditDrawer(false);
		setOpenAddMatrixDrawer(false);
		setMailType('Alarm Escalation');

	};


	const HandleDelete = (record) => {
		setShowDeleteModal(true);
		setDeleteId(record.id);

	};

	const handleAddSMTP =() =>{
		setOpenSMTPEditDrawer(true);
		setSMTPSettings({method:data.smtp_data && data.smtp_data.method ? data.smtp_data.method : 'SMTP', server:data.smtp_data && data.smtp_data.server, port:data.smtp_data && data.smtp_data.port,
			username:data.smtp_data && data.smtp_data.username,password:data.smtp_data && data.smtp_data.password
		});
	};

	const handleAddMatrix =() =>{
		setOpenAddMatrixDrawer(true);
		setEmaildata({});
		setUserType({value:'CRO', label:'CRO', time:'Upto 6 Hours', id:3});
	};

	// const onChange = (e) => {
	// 	setValue(e.target.value);
	// };

	const handleChange = (e) => {
		setMailType(e);
	};

	const handleChangeUserType = (value,option) => {
		setEmaildata({value:'', label:''});
		setUserType({label:option.label, value:option.value, time:option.time, id:option.id });
	};

	const getUsers = () =>{
		dispatch({ type : USER_LIST});
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/users?isDeleted=false&permissions=${userstypeId ? userstypeId.value : ''}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};
          
		axios.request(config)
			.then((response) => {
				if (response.status === 200){
					dispatch({ type : USER_LIST_SUCCESS, payload : response.data.data});
				} else {
					dispatch({ type : USER_LIST_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : USER_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};


	const handleSelectEmail = (e,value) =>{
		setEmaildata({value:value.label, label:value.label, usersId:value.id, usertype:value.type, 
			piplineId:value.pipeline, firstName:value.firstName, lastName:value.lastName});
	};


	const getEscalationList = (pagination) =>{

		let _pagination = { };
		_pagination.total = matirx_data_pagination.total ? matirx_data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		let params = '';
		if(oneviewtypeLabel.toLowerCase() != 'pipeline') {
			params = '&usertype[$nin][]=HQO-HSE&usertype[$nin][]=Pipeline-In-Charge&usertype[$nin][]=Pipeline-HO&usertype[$nin][]=Station-In-Charge';
		}
		
		dispatch({ type : ESCALATION_MATRIX_LIST});
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/escalationmatrix?$limit=${_pagination.limit}&$skip=${_pagination.skip}&$sort[id]=1${filterMailType && filterMailType?`&notificationType=${filterMailType}`:''}${search && search.length > 0 ?`&email[$ilike]=%${search}%` : ''}${params}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					matirx_data_pagination.total = response.data.total ? response.data.total : 0;
					matirx_data_pagination.limit = response.data.limit ? response.data.limit : 10;
					matirx_data_pagination.skip = response.data.skip ? response.data.skip : 0;
					matirx_data_pagination.current = pagination.current ? pagination.current : 1;
					dispatch({ type : ESCALATION_MATRIX_LIST_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : ESCALATION_MATRIX_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : ESCALATION_MATRIX_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});


	};
  
  
	const closeDeleteModal = ()=>{
		setShowDeleteModal(false);
	};

	const HandleDeleteModal = ()=>{

		dispatch({ type : ESCALATION_MATRIX_DELETE});
		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/escalationmatrix/${deleteid}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					Modal.success({ title: 'Successfully deleted.' });
					dispatch({ type : ESCALATION_MATRIX_DELETE_SUCCESS, payload : response.data});
					getEscalationList({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					setDeleteId(null);
				}else {
					dispatch({ type : ESCALATION_MATRIX_DELETE_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : ESCALATION_MATRIX_DELETE_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});


		setShowDeleteModal(false);
	};


	const handleMethod = (e) =>{
		setSMTPSettings({...settings,method:e});
	}; 

	const handleServer = (e) =>{
		setSMTPSettings({...settings,server:e.target.value});
	};
   
	const handlePort = (e) =>{
		setSMTPSettings({...settings,port:e.target.value});
	};

	const handleUser = (e) =>{
		setSMTPSettings({...settings,username:e.target.value});
	};

	const handlePassword = (e) =>{
		setSMTPSettings({...settings,password:e.target.value});
	};

	const onSaveSMTPDrawer = ()=>{

		if (!settings.method || settings.method == null || settings.method == '') {
			Modal.warning({ title:  langdata && langdata.Pleaseselectavalidmethod ? langdata.Pleaseselectavalidmethod : 'Please select a valid method.' });
			return false;
		}

		if (!settings.password || settings.password == null || settings.password == '') {
			Modal.warning({ title:  langdata && langdata.Passwordisrequired ? langdata.Passwordisrequired : 'Password is required.' });
			return false;
		}

		if (!settings.port || settings.port == null || settings.port == '') {
			Modal.warning({ title:  langdata && langdata.Pleaseaddaport ? langdata.Pleaseaddaport : 'Please add a port.' });
			return false;
		}

		if (!settings.username || settings.username == null || settings.username == '') {
			Modal.warning({ title: langdata && langdata.Usernameisrequired ? langdata.Usernameisrequired : 'User name is required.' });
			return false;
		}

		if (!settings.server || settings.server == null || settings.server == '') {
			Modal.warning({ title:  langdata && langdata.Serverisrequired ? langdata.Serverisrequired : 'Server is required.' });
			return false;
		}


		if(data.smtp_data && data.smtp_data == null || data.smtp_data == undefined ){

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/emailsettings`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					method:settings.method ,
					password:settings.password ,
					port:settings.port ,
					server:settings.server,
					username:settings.username,
					pipelineId:parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`))
				}
			};
  
			dispatch( { type: SMTP_UPDATE });
  
			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: SMTP_UPDATE_SUCCESS, payload:response});
						getEscalationList({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
						Modal.success({ title: langdata && langdata.SMTPdetailsaddedsuccessfully ? langdata.SMTPdetailsaddedsuccessfully : 'SMTP details added successfully.' });
						setOpenEditDrawer(false);
						getSMTPDetails();
						// setEmaildata({})
						// setUserType({})
					} else {
						dispatch({type: SMTP_UPDATE_ERROR});
						Modal.warning({ title: langdata && langdata.UnabletoaddtheSMTPdetails ? langdata.UnabletoaddtheSMTPdetails : 'Unable to add the SMTP details.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({type: SMTP_UPDATE_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});



		}else{

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/emailsettings/${data.smtp_data && data.smtp_data.id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					method:settings.method ,
					password:settings.password ,
					port:settings.port ,
					server:settings.server,
					username:settings.username,
				}
			};
  
			dispatch( { type: SMTP_UPDATE });
  
			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: SMTP_UPDATE_SUCCESS, payload:response});
						getEscalationList({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
						Modal.success({ title: langdata && langdata.SMTPdetailsupdatedsuccessfully ? langdata.SMTPdetailsupdatedsuccessfully : 'SMTP details updated successfully.' });
						setOpenEditDrawer(false);
						getSMTPDetails();
						// setEmaildata({})
						// setUserType({})
					} else {
						dispatch({type: SMTP_UPDATE_ERROR});
						Modal.warning({ title: langdata && langdata.UnabletoupdatetheSMTPdetails ? langdata.UnabletoupdatetheSMTPdetails : 'Unable to update the SMTP details.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({type: SMTP_UPDATE_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});

		}



		setOpenSMTPEditDrawer(false);
	};


	const getSMTPDetails = ()=>{

		dispatch({ type : SMTP_LIST});
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/emailsettings`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : SMTP_LIST_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : SMTP_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : SMTP_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});

	};

	const handleFilterMailType = (e) =>{
		setFilterMailType(e);
	};

	const handleSearch = (e) => {
		const searchString = e.target.value;

		setSearch(searchString);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.EscalationmatricInfo1 ? langdata.EscalationmatricInfo1 : 'The users that need to receive mails from the OneView System can be configured here. \
				Details like the User type, Notification type, email, name details are displayed. \
				Existing configurations can be edited using the edit button in Actions. Users can be \
				searched by email and filter option available to filter users by the Notification type.'}</p>
			< br/>
			<p>{langdata && langdata.EscalationmatricInfo2 ? langdata.EscalationmatricInfo2 : 'New mailing recipients can be added using the Add New button selecting the Notification type and email.'}</p>
			<br />
			<p>{langdata && langdata.EscalationmatricInfo3 ? langdata.EscalationmatricInfo3 : 'Email Setting button provide the global SMTP configuration for setting the email server, port,  \
					user name and password details from which the mail will be triggered to the recipients added.'}</p>
		</Card>
	);

	return (
		admin_roles.includes(permission) ? 
			<>
				<Content>
					<Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
					style={{
						margin: '10px',
						// paddingLeft: '10px',
						// paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
					>

						<Col span={6}>
							<Row>
								<Space>
									<PicRightOutlined style={{fontSize:'30px'}}/>
									{/* </Col> */}
								</Space>
								<Space>&nbsp;&nbsp;</Space>
					
								{/* <Col style={{marginLeft:'4px'}} span={19}> */}
								<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.EscalationMatrix ? langdata.EscalationMatrix : 'Escalation Matrix'}</Title>
							</Row>
						</Col>
					
						<Col span={18}>
							<Row justify="end">
								<Col>
									<Space>
										<Search
											style={{margin:'5px', verticalAlign:'middle', marginTop:'2px',paddingRight:'10px',  minWidth: '200px', maxWidth:'200px'}}
											placeholder={langdata && langdata.Searchbyemail ? langdata.Searchbyemail : 'Search by email'}
											allowClear
											enterButton
											value = {search}
											onChange={handleSearch}
											onPressEnter={handleSearch}
										/>
									</Space>

									<Tooltip placement="bottom" title={langdata && langdata.Filterbyemailtypes ? langdata.Filterbyemailtypes : 'Filter by email types'}>
										<Space>
											<Select
												style={{ minWidth: '190px', margin:'5px'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.FilterBy ? langdata.FilterBy : 'Filter By'}
												optionFilterProp="children"
												options={NotificationFilterType}
												onSelect={handleFilterMailType}
												defaultValue={null}
											/>		
										</Space>
										<Space>&nbsp;&nbsp;</Space>
									</Tooltip>

									{permission==='superadmin' || permission === 'admin' ? 

										<Tooltip placement="bottom" title={langdata && langdata.Addmatrix ? langdata.Addmatrix : 'Add matrix'}>
											<Space>

												<Button style={{margin:'5px'}} type ={'primary'} onClick={handleAddMatrix} ><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button>
											</Space>
											<Space>&nbsp;&nbsp;</Space>
										</Tooltip> : null }

									{permission==='superadmin' ?
										<Tooltip placement="bottom" title={langdata && langdata.Addemailsettings ? langdata.Addemailsettings : 'Add email settings'}>
											<Space>

												<Button style={{margin:'5px'}} type ={'primary'} onClick={handleAddSMTP} >{langdata && langdata.EmailSettings ? langdata.EmailSettings : 'Email Settings'}</Button>
											</Space>
										</Tooltip> : null }
									<Space>&nbsp;&nbsp;</Space>
									<Space>
										<Typography>
											<Popover title={langdata && langdata.EscalationMatrix ? langdata.EscalationMatrix : 'Escalation Matrix'}  content={infoContent} trigger="click">
												<Avatar size="small"><InfoOutlined /></Avatar>
											</Popover>
										</Typography>
									</Space>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24}>
							{ data.escalation_matrix.length > 0 ? (
								<Table 
									scroll={{
										x: 900,
									}}
									columns={columns} dataSource={data.escalation_matrix} pagination = { data.escalation_matrix.length > 0 ? matirx_data_pagination : 0 }  onChange = { getEscalationList }/>
							)
								:
								(
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
								)}
						</Col>
					</Row>


				</Content>
            

				{/* Edit grade Starts */}
				<Modal
					title={langdata && langdata.EditMatrix ? langdata.EditMatrix : 'Edit Matrix'}
					width={720}
					open={openEditDrawer}
					destroyOnClose={true}
					onOk={onSaveEditDrawer}
					onCancel={onCloseEditDrawer}
					footer={[
						// eslint-disable-next-line react/jsx-key
						<Space>
	
							<Button onClick={onCloseEditDrawer}>
								{langdata && langdata.Cancel ? langdata.Cancel : 'Cancel'}
							</Button>
							<Button onClick={onSaveEditDrawer} type="primary">
								{langdata && langdata.Update ? langdata.Update : 'Update'}
							</Button>
	
						</Space>
					]}
        
				>
					<Form layout="vertical">
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="name" label={langdata && langdata.NotificationType ? langdata.NotificationType : 'Notification Type'} rules={[{ required: true, message: 'Notification Type' }]} >
									<Select value={mailtype} onSelect={handleChange} defaultValue={mailtype} options={NotificationType} showSearch filterOption={filterOption}/>
								</Form.Item>
							</Col>
						</Row>
  
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="usertype" label={langdata && langdata.UserType ? langdata.UserType : 'User Type'} rules={[{ required: true, message: 'User Type' }]} >
									<Select value={userstypeId.value} defaultValue={userstypeId.value}  options={usertype}
										onSelect={(value,option)=>handleChangeUserType(value,option)} showSearch filterOption={filterOption}/>
								</Form.Item>
							</Col>
						</Row>
						{/* {
          mailtype && mailtype==='Alarm Escalation' ?

        
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="pids" label="Pids" rules={[{ required: true, message: 'Pids' }]} >
              <Radio.Group name="radiogroup" defaultValue={1} onChange={onChange} value={value}>
                <Radio value={1}>All</Radio>
                <Radio value={2}>Select</Radio>

            </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          : null }

          {
            value && value===2 ? 
            <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="pids" label="Select Pids" rules={[{ required: true, message: 'Select Pids' }]} >
                    <Select defaultValue={pids[0]} options={pids} />
              </Form.Item>
            </Col>
          </Row>
            
            :
            
            null
          } */}

						{
							mailtype && mailtype==='Alarm Escalation' ?
								usertype && usertype.map((item)=>{
									if(item.value===userstypeId.value){
										return(
											<Row key={item.value} gutter={24}>
												<Col span={24}>
													<Form.Item name="hours" label={langdata && langdata.Unresolvedalarmescalationhours ? langdata.Unresolvedalarmescalationhours : 'Unresolved alarm escalation hours'} rules={[{ required: true, message: langdata && langdata.Unresolvedalarmescalationhours ? langdata.Unresolvedalarmescalationhours :'Unresolved alarm escalation hours' }]} >
														<Select disabled={true} defaultValue={item.time} showSearch filterOption={filterOption}/>
													</Form.Item>
												</Col>
											</Row>
										); 
									}
								}):

								null
          
						}

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="email" label={langdata && langdata.Email ? langdata.Email :'Email'} rules={[{ required: true, message: langdata && langdata.Email ? langdata.Email :'Email'  }]} >
									<Select options={data.users} defaultValue={emaildata.value}
										onSelect={(value, option)=> handleSelectEmail(value, option)} showSearch filterOption={filterOption}/>
								</Form.Item>
							</Col>
						</Row>


					</Form>
				</Modal>
				{/* Edit grade ends */}

				{/* Add matrx modal Starts */}
				<Modal
					title={langdata && langdata.AddMatrix ? langdata.AddMatrix : 'Add Matrix'}
					width={720}
					open={openAddMatrixEditDrawer}
					destroyOnClose={true}
					onOk={onSaveAddMatrixDrawer}
					onCancel={onCloseEditDrawer}
					styles={{
						body: {
						},
					}}

					footer={[
						// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={onCloseEditDrawer}>
								{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}
							</Button>
							<Button onClick={onSaveAddMatrixDrawer} type="primary">
								{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE' }
							</Button>
						</Space>
					]}
				>
					<Form layout="vertical">
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="name" label={langdata && langdata.NotificationType ? langdata.NotificationType : 'Notification Type'} rules={[{ required: true, message: langdata && langdata.NotificationType ? langdata.NotificationType :  'Notification Type' }]} >
									<Select value={mailtype} onSelect={handleChange} defaultValue={mailtype} options={NotificationType} showSearch filterOption={filterOption}/>
								</Form.Item>
							</Col>
						</Row>
  
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="usertype" label={langdata && langdata.UserType ? langdata.UserType : 'User Type'} rules={[{ required: true, message: langdata && langdata.UserType ? langdata.UserType : 'User Type' }]} >
									<Select value={userstypeId.value} defaultValue={userstypeId.value}  options={usertype}
										onSelect={(value,option)=>handleChangeUserType(value,option)} showSearch filterOption={filterOption}/>
								</Form.Item>
							</Col>
						</Row>
						{/* {
          mailtype && mailtype==='Alarm Escalation' ?

        
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="pids" label="Pids" rules={[{ required: true, message: 'Pids' }]} >
              <Radio.Group name="radiogroup" defaultValue={1} onChange={onChange} value={value}>
                <Radio value={1}>All</Radio>
                <Radio value={2}>Select</Radio>

            </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          : null }

          {
            value && value===2 ? 
            <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="pids" label="Select Pids" rules={[{ required: true, message: 'Select Pids' }]} >
                    <Select defaultValue={pids[0]} options={pids} />
              </Form.Item>
            </Col>
          </Row>
            
            :
            
            null
          } */}

						{
							mailtype && mailtype==='Alarm Escalation' ?
								usertype && usertype.map((item)=>{
									if(item.value===userstypeId.value){
										return(
											<Row key={item.value} gutter={24}>
												<Col span={24}>
													<Form.Item name="hours" label={langdata && langdata.Unresolvedalarmescalationhours ? langdata.Unresolvedalarmescalationhours :'Unresolved alarm escalation hours'} rules={[{ required: true, message: langdata && langdata.Unresolvedalarmescalationhours ? langdata.Unresolvedalarmescalationhours : 'Unresolved alarm escalation hours' }]} >
														<Select disabled={true} defaultValue={item.time} showSearch filterOption={filterOption}/>
													</Form.Item>
												</Col>
											</Row>
										); 
									}
								}):
          
								null
						}

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="email" label={langdata && langdata.Email ? langdata.Email :'Email'} rules={[{ required: true, message:  langdata && langdata.Email ? langdata.Email : 'Add email' }]} >
									<Select options={data.users} defaultValue={emaildata.value}
										onSelect={(value, option)=> handleSelectEmail(value, option)} showSearch filterOption={filterOption}/>
								</Form.Item>
							</Col>
						</Row>


					</Form>
				</Modal>

				{/* Add matrix Modal Ends */}


				{/* Delete Modal Starts */}
				<Modal
					title={langdata && langdata.DeleteMatrix ? langdata.DeleteMatrix : 'Delete Matrix'}
					centered
					destroyOnClose={true}
					open={showDeleteModal}
					onOk={HandleDeleteModal}
					onCancel={closeDeleteModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>

							<Button onClick={closeDeleteModal}>
								{langdata && langdata.Cancel ? langdata.Cancel : 'Cancel'}
							</Button>
							<Button onClick={HandleDeleteModal} type="primary">
								{langdata && langdata.Delete ? langdata.Delete : 'Delete'}
							</Button>

						</Space>
					]}
					width={540}
				>
					<Row gutter={16}>
						<Col span={24}>
							<Typography>
								{langdata && langdata.Areyousureyouwanttodeletethis ? langdata.Areyousureyouwanttodeletethis : 'Are you sure you want to delete this ?'}     
							</Typography>
						</Col>
					</Row>

				</Modal>
				{/* Delete  Modal Ends */}

				{/* SMTP Modal Starts */}

				<Modal
					title={langdata && langdata.SMTPSettings ? langdata.SMTPSettings : 'SMTP Settings'}
					width={720}
					open={openSMTPEditDrawer}
					destroyOnClose={true}
					onOk={onSaveSMTPDrawer}
					onCancel={onCloseEditDrawer}

					styles={{
						body: {
						},
					}}
					footer={[
						// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={onCloseEditDrawer}>
								{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}
							</Button>
							<Button onClick={onSaveSMTPDrawer} type="primary">
								{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}
							</Button>
						</Space>
					]}
				>
					<Form layout="vertical">


						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="method" label={langdata && langdata.Method ? langdata.Method : 'Method'} rules={[{ required: true, message: langdata && langdata.Method ? langdata.Method : 'Method:' }]} >
									<Select  defaultValue={Method[0]} value={settings && settings.method} options={Method}
										onSelect={handleMethod} showSearch filterOption={filterOption}/>
								</Form.Item>
							</Col>
						</Row>
  
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="server" label={langdata && langdata.Server ? langdata.Server : 'Server'} rules={[{ required: true, message: langdata && langdata.Server ? langdata.Server :  'Server' }]} >
									<Input defaultValue={settings && settings.server} value={settings && settings.server} placeholder="Server" 
										onChange={handleServer} />
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="port" label={langdata && langdata.Port ? langdata.Port : 'Port'} rules={[{ required: true, message: langdata && langdata.Port ? langdata.Port : 'Port' }]} >
									<Input type="number" placeholder="Port" defaultValue={settings && settings.port} value={settings && settings.port}
										onChange={handlePort} />
								</Form.Item>
							</Col>
						</Row>


						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="username" label={langdata && langdata.UserName ? langdata.UserName : 'User Name'} rules={[{ required: true, message: langdata && langdata.UserName ? langdata.UserName : 'User Name' }]} >
									<Input placeholder="User Name" defaultValue={settings && settings.username} value={settings && settings.username} 
										onChange={handleUser} />
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="password" label={langdata && langdata.Password ? langdata.Password : 'Password'} rules={[{ required: true, message: langdata && langdata.Password ? langdata.Password : 'Password' }]} >
									<Input.Password placeholder="Password"
										defaultValue={settings && settings.password} value={settings && settings.password}
										visibilityToggle={{
											visible: passwordVisible,
											onVisibleChange: setPasswordVisible,
										}} onChange={handlePassword} prefix={<LockOutlined /> } />
								</Form.Item>
							</Col>
						</Row>

					</Form>
				</Modal>

				{/* SMTP modal ends */}
				<Spin spinning={data.pending} fullscreen />
			</> : null
	);
}