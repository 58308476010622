import React, { useEffect, useReducer, useState } from 'react';


import { Empty, Row, Col, Typography, Space, Table, DatePicker, Tag, Breadcrumb, Tooltip, message} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { EyeOutlined} from '@ant-design/icons';
import { useAuth } from '../Context';
import inspectiondata from './reducer';
import { INSPECTION_LIST, INSPECTION_LIST_ERROR, INSPECTION_LIST_SUCCESS } from './constants';
import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs';
import { UserOutlined, HomeOutlined} from '@ant-design/icons';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;


const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

let inspection_data_pagination = {};

export default function InspectionView() {

  

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const initalState = {inspection_data:[],users_data:[], details: null, pending: false, error: false };
	const [inspection_data, dispatch] = useReducer(inspectiondata, initalState);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const navigate = useNavigate();
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const { state } = useLocation();
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(new Date(dates[0] && dates[0].$d));
			setEndDate(new Date(dates[1] && dates[1].$d));
		} else {
			console.log('Clear');
		}
	};
	

	const columns = [

		{
			title: langdata && langdata.Date ? langdata.Date : 'Date',
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: langdata && langdata.StartTime ? langdata.StartTime : 'Start Time',
			dataIndex: 'starttime',
			key: 'duration',
		},
		{
			title: langdata && langdata.EndTime ? langdata.EndTime : 'End Time',
			dataIndex: 'endtime',
			key: 'endtime',
		},
		{
			title: langdata && langdata.Status ? langdata.Status : 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (_, { status }) => (
				<>
					<Tag color={status === 'Completed' ?'green' :'red'} key={status}>
						{status.toUpperCase()}
					</Tag>
				</>
			),
		},

		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (_, record) => (
				<Space style={{justifyContent:'center',display:'flex'}} size="middle">
					<a title={langdata && langdata.ViewInspection ? langdata.ViewInspection : 'View Inspection'} onClick={()=>{showMap(record);}} ><EyeOutlined/></a>
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)
		}
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!state) {
			navigate(-1);
		}
	},[authtoken]);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		else{
			if(admin_roles.includes(permission)){
				getRegion(authtoken);
			}
			else{
				getUserAllocation(authtoken);
			}
			getInspectiondata(1);
		}

	},[authtoken, startDate, endDate]);

  

  

	let Inspectiondata = [];

	inspection_data.inspection_data && inspection_data.inspection_data.map((item) => {
		Inspectiondata.push({
			key: item.id,
			id: item.id,
			name: state.name,
			type:item.permissions,
			date:new Date(item.startTimestamp).toLocaleDateString('en-UK', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric'
			}),
			starttime: new Date(item.startTimestamp).toLocaleTimeString('en-US'),
			endtime: new Date(item.endTimestamp).toLocaleTimeString('en-US'),
			status: item.status,
			regioninfoId:item.regioninfoId,
			userId:item.userId

		});
	});

	let regions=[{value:null,
		label:'ALL'
	}];

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	let users=[];

	inspection_data.users_data && inspection_data.users_data.map((user)=>{
		users.push({
			value:user.id,
			label:user.firstName.toUpperCase()+ user.lastName.toUpperCase()

		});
	});


	const showMap = (inspection) => {
		navigate(( '/inspection-details'),{ state: { data: inspection, path: 'linewalkerstable' } });
	};



	const getInspectiondata = (pagination) => {
		let _pagination = { };
		_pagination.total = inspection_data_pagination.total ? inspection_data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		dispatch({ type : INSPECTION_LIST});		

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/inspection?userId=${parseInt(state && state.userId)}&$sort[id]=-1&$limit=${_pagination.limit}&$skip=${_pagination.skip}&startTimestamp[$gte]=${moment.utc(startDate.setHours(0,0,0,0)).format('YYYY-MM-DD HH:mm:ss')}&startTimestamp[$lte]=${moment.utc(endDate.setHours(23,59,59,999)).format('YYYY-MM-DD HH:mm:ss')}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};
          
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					inspection_data_pagination.total = response.data.total ? response.data.total : 0;
					inspection_data_pagination.limit = response.data.limit ? response.data.limit : 10;
					inspection_data_pagination.skip = response.data.skip ? response.data.skip : 0;
					inspection_data_pagination.current = pagination.current ? pagination.current : 1;
					dispatch({ type : INSPECTION_LIST_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : INSPECTION_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : INSPECTION_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	return (
		<>
			{state ?
				<>
					<Row 
						style={{
							marginLeft:'10px',
							marginTop: '10px'
						}}            
					>
						<Breadcrumb
							items={[
								{
									href: '/dashboard',
									title: <HomeOutlined />,
								},
								{
									title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'}</a> 
								},
								{
									title: langdata && langdata.LineWalkerInspections ? langdata.LineWalkerInspections : 'Line Walker Inspections',
								},
							]}
						/>
					</Row>
					<Content>
						<Row style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center'
						}}>

							<Col span={12}>
								<Space>
									<UserOutlined style={{fontSize:'30px'}}/>
										&nbsp;
									<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.LineWalkerInspections ? langdata.LineWalkerInspections : 'Line Walker Inspections'}</Title>
								</Space>
							</Col>
					
							<Col span={12}>
								<Row justify="end">
									<Col>
										<Space>
											{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>User Type:</Text> */}
											<Tooltip placement="bottom" title={langdata && langdata.Filterbydate ? langdata.Filterbydate :'Filter by date'}>
												<RangePicker allowClear={false} onChange={onRangeChange}  format={settingsdateformat}
													defaultValue={[dayjs(moment().format('YYYY-MM-DD'), dateFormat), dayjs(moment().format('YYYY-MM-DD'), dateFormat)]} 
													style={{ width: '360px', margin : '5px'}} />
											</Tooltip>
										</Space>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row span={24} style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col span={24}>
								{ Inspectiondata.length > 0 ? (
									<Table scroll={{
										x: 300,
									}}columns={columns} dataSource={Inspectiondata}
									pagination = { Inspectiondata.length > 0 ? inspection_data_pagination : 0 }
									onChange = { getInspectiondata }
									/>
								)
									:
									(
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
							</Col>
						</Row>


					</Content>
				</>
				: '' } 

		</>
	);
}