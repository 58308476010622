import React, { useEffect, useState, useReducer } from 'react';

import { Empty, Row, Col, Typography, Button, Space, Table, Form, Input, Modal, Spin, Tooltip, message, Switch, InputNumber, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { EVENT_TYPES_LIST, EVENT_TYPES_LIST_SUCCESS, EVENT_TYPES_LIST_LIST_ERROR, EVENT_TYPE_DETAILS, EVENT_TYPE_DETAILS_SUCCESS, EVENT_TYPE_DETAILS_ERROR, DELETE_EVENT_TYPE, DELETE_EVENT_TYPE_SUCCESS, DELETE_EVENT_TYPE_ERROR, ADD_EVENT_TYPE_DETAILS, ADD_EVENT_TYPE_DETAILS_SUCCESS, ADD_EVENT_TYPE_DETAILS_ERROR, UPDATE_EVENT_TYPE, UPDATE_EVENT_TYPE_SUCCESS, UPDATE_EVENT_TYPE_ERROR } from './constants';
import { PlusOutlined, EditOutlined, DeleteOutlined, InfoOutlined } from '@ant-design/icons';

import eventTypesReducer from './reducer';

import { useFormik } from 'formik';
import axios from 'axios';
import { ToolOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useAuth } from '../../Context';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

export default function EventTypes() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = {eventTypes : [], details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(eventTypesReducer, initalState);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	
	const [showAddEventTypeModal, setShowAddEventTypeModal] = useState(false);
	const [showEditEventTypeModal, setShowEditEventTypeModal] = useState(false);
	const [showDeleteEventTypeModal, setShowDeleteEventTypeModal] = useState(false);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const [disableValue, setDisableValue] = useState(true);
	useAuth();
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));
	const navigate = useNavigate();

	const columns = [
		{
			title: langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex: 'eventType',
			key: 'eventType',
			render: (text) => <span onClick={openEditEventTypeModal}>{text}</span>,
		},
		{
			title: langdata && langdata.EventConfidenceCap ? langdata.EventConfidenceCap : 'Event Confidence Cap',
			dataIndex: 'eventConfidenceCap',
			key: 'eventConfidenceCap',
		},
		{
			title: langdata && langdata.Red ? langdata.Red : 'Red',
			dataIndex: 'escalationMetrics',
			key: 'escalationMetrics',
			render : (text) => {
				let value = `${text.Red}`;
				return (
					value.replace(',',' - ')
				);
			}
		},
		{
			title: langdata && langdata.Amber ? langdata.Amber : 'Amber',
			dataIndex: 'escalationMetrics',
			key: 'escalationMetrics',
			render : (text) => {
				let value = `${text.Amber}`;
				return (
					value.replace(',',' - ')
				);
			}
		},
		{
			title: langdata && langdata.Green ? langdata.Green : 'Green',
			dataIndex: 'escalationMetrics',
			key: 'escalationMetrics',
			render : (text) => {
				let value = `${text.Green}`;
				return (
					value.replace(',',' - ')
				);
			}
		},
		{
			title: langdata && langdata.Clear ? langdata.Clear : 'Clear',
			dataIndex: 'escalationMetrics',
			key: 'escalationMetrics',
			render : (text) => {
				let value = `${text.Clear}`;
				return (
					value.replace(',',' - ')
				);
			}
		},
		{
			title: langdata && langdata.Unclassified ? langdata.Unclassified :'Unclassified',
			dataIndex: 'escalationMetrics',
			key: 'escalationMetrics',
			render : (text) => {
				let value = `${text.Unclassified}`;
				return (
					value.replace(',',' - ')
				);
			}
		},
		{
			title:  langdata && langdata.Enabled ? langdata.Enabled : 'Enabled',
			dataIndex: 'eventEscalationEnabled',
			key: 'eventEscalationEnabled',
			render : (text) => (
				text == true ? 'YES' : 'NO'
			)
		},
		{
			title:  langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: () => (
				permission === 'superadmin' ? (
					<Space size="middle">
						<a title={langdata && langdata.EditEventType ? langdata.EditEventType : 'Edit Event Type'} onClick={openEditEventTypeModal}><EditOutlined/></a>
						<a title={langdata && langdata.DeleteEventType ? langdata.DeleteEventType :'Delete Event Type'} onClick={openDeleteEventTypeModal}><DeleteOutlined /></a>
					</Space>
				) : (
					<Space size="middle">
					</Space>
				)
			)
		}
	];

	const openAddEventTypeModal = () => {
		setShowAddEventTypeModal(true);
	};

	const closeAddEventTypeModal = () => {
		setShowAddEventTypeModal(false);
	};

	const openEditEventTypeModal = () => {
		setShowEditEventTypeModal(true);
	};

	const closeEditEventTypeModal = () => {
		setShowEditEventTypeModal(false);
	};

	const openDeleteEventTypeModal = () => {
		setShowDeleteEventTypeModal(true);
	};

	const deleteEventType = () => {
		const id = parseInt(data.details.id);
    
		dispatch({ type: DELETE_EVENT_TYPE });

		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/allevents/${id}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			}
		};

		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type: DELETE_EVENT_TYPE_SUCCESS, payload: response});
					setShowDeleteEventTypeModal(false);
					loadEventTypes();
					Modal.success({ title: langdata && langdata.Eventtypesuccessfullydeleted ? langdata.Eventtypesuccessfullydeleted :'Event type successfully deleted.' });
				} else {
					dispatch({type: DELETE_EVENT_TYPE_ERROR}); 
					Modal.warning({ title: langdata && langdata.Eventtypenotdeleted ? langdata.Eventtypenotdeleted : 'Event type not deleted.' }); 
				}
			})
			.catch((error) => {
				dispatch({type: DELETE_EVENT_TYPE_ERROR});
				Modal.warning({ title: 'Event type not deleted.' }); 
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const closeDeleteEventTypeModal = () => {
		setShowDeleteEventTypeModal(false);
	};

	useEffect(() => {
		if(featuresModule.eventTypesEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 48);
	}, []);

	useEffect(() => {
		dispatch( { type: EVENT_TYPES_LIST });
      
		loadEventTypes();
	}, []);

	const loadEventTypes = () => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/allevents`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type: EVENT_TYPES_LIST_SUCCESS, payload: response.data});
				}
			})
			.catch((error) => {
				dispatch({type: EVENT_TYPES_LIST_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const loadEventTypeDetails = ((id) => {
		let details = null;

		if (data && data.eventTypes) {
			data.eventTypes && data.eventTypes.map((item) => {
				if (item.id === id) {
					details = item;
				}

				return true;
			});
      
			dispatch( { type: EVENT_TYPE_DETAILS });
    
			if (!details) {
				dispatch({ type: EVENT_TYPE_DETAILS_ERROR });
			} else {
				dispatch({ type: EVENT_TYPE_DETAILS_SUCCESS, payload: details });

			}
		} else {
			dispatch({ type: EVENT_TYPE_DETAILS_ERROR }); 
		}
	});

	const addEventTypeFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			eventType: '',
			eventConfidenceCap : null,
			redLower : null,
			redUpper : null,
			amberLower : null,
			amberUpper : null,
			greenLower : null,
			greenUpper : null,
			clearLower : null,
			clearUpper : null,
			unclassifiedLower : null,
			unclassifiedUpper : null
		},
		onSubmit: (values) => {
			// const eventType = values.eventType ? values.eventType : '';
			const {eventType,eventConfidenceCap,redLower,redUpper,amberLower,amberUpper,greenLower,greenUpper,clearLower,clearUpper,unclassifiedLower,unclassifiedUpper} = values;

			if(eventConfidenceCap==  null || redLower==  null || redUpper==  null || amberLower==  null || amberUpper==  null || greenLower==  null || greenUpper==  null || clearLower==  null || clearUpper==  null || unclassifiedLower==  null || unclassifiedUpper ==  null) {
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}

			if (!eventType || eventType == null || eventType === '') {
				Modal.warning({ title: langdata && langdata.Pleaseprovideavalideventtypename ? langdata.Pleaseprovideavalideventtypename :  'Please provide a valid event type name.' });
				return false;
			}

			const metrics = [];

			metrics.push({
				'Red' : [redLower, redUpper],
				'Amber' : [amberLower, amberUpper],
				'Green' : [greenLower, greenUpper],
				'Clear' : [clearLower, clearUpper],
				'Unclassified' : [unclassifiedLower, unclassifiedUpper]
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/allevents`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					eventType: eventType,
					eventConfidenceCap : eventConfidenceCap,
					escalationMetrics : metrics && metrics[0],
					eventEscalationEnabled : disableValue
				}
			};

			dispatch( { type: ADD_EVENT_TYPE_DETAILS });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: ADD_EVENT_TYPE_DETAILS_SUCCESS, payload: response});
						Modal.success({ title: langdata && langdata.Eventtypesavedsuccessfully ? langdata.Eventtypesavedsuccessfully : 'Event type saved successfully.' });
						setShowAddEventTypeModal(false);
						loadEventTypes();
					} else {
						dispatch({type: ADD_EVENT_TYPE_DETAILS_ERROR});
						Modal.warning({ title: langdata && langdata.Unabletosavetheeventtype ? langdata.Unabletosavetheeventtype : 'Unable to save the event type.' });  
					}
				})
				.catch((error) => {
					dispatch({type: ADD_EVENT_TYPE_DETAILS_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
	});

	const updateEventTypeFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			eventType: data.details ? data.details.eventType : '',
			eventConfidenceCap : data.details && data.details.eventConfidenceCap,
			redLower : data.details && data.details.escalationMetrics.Red[0],
			redUpper : data.details && data.details.escalationMetrics.Red[1],
			amberLower : data.details && data.details.escalationMetrics.Amber[0],
			amberUpper : data.details && data.details.escalationMetrics.Amber[1],
			greenLower : data.details && data.details.escalationMetrics.Green[0],
			greenUpper :  data.details && data.details.escalationMetrics.Green[1],
			clearLower :  data.details && data.details.escalationMetrics.Clear[0],
			clearUpper :  data.details && data.details.escalationMetrics.Clear[1],
			unclassifiedLower : data.details && data.details.escalationMetrics.Unclassified[0],
			unclassifiedUpper : data.details && data.details.escalationMetrics.Unclassified[1],
			eventEscalationEnabled : data.details && data.details.eventEscalationEnabled && data.details.eventEscalationEnabled == false
		},

		onSubmit: (values) => {

			const {eventType,eventConfidenceCap,redLower,redUpper,amberLower,amberUpper,greenLower,greenUpper,clearLower,clearUpper,unclassifiedLower,unclassifiedUpper, eventEscalationEnabled} = values;

			if(eventType == null || eventConfidenceCap==  null || redLower==  null || redUpper==  null || amberLower==  null || amberUpper==  null || greenLower==  null || greenUpper==  null || clearLower==  null || clearUpper==  null || unclassifiedLower==  null || unclassifiedUpper ==  null) {
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}

			const metrics = [];

			metrics.push({
				'Red' : [redLower, redUpper],
				'Amber' : [amberLower, amberUpper],
				'Green' : [greenLower, greenUpper],
				'Clear' : [clearLower, clearUpper],
				'Unclassified' : [unclassifiedLower, unclassifiedUpper]
			});

			const id = data.details.id;
			
			if (!eventType || eventType == null || eventType === '') {
				Modal.warning({ title: langdata && langdata.Pleaseprovideavalideventtypename ? langdata.Pleaseprovideavalideventtypename : 'Please provide a valid event type name.' });
				return false;
			}

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/allevents/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					eventType: eventType,
					eventConfidenceCap : eventConfidenceCap,
					escalationMetrics : metrics && metrics[0],
					eventEscalationEnabled : eventEscalationEnabled
				}
			};

			dispatch( { type: UPDATE_EVENT_TYPE });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: UPDATE_EVENT_TYPE_SUCCESS, payload:response});
						Modal.success({ title: langdata && langdata.Eventtypeupdatedsuccessfully ? langdata.Eventtypeupdatedsuccessfully :  'Event type updated successfully.' });
						setShowEditEventTypeModal(false);
						loadEventTypes();
					} else {
						dispatch({type: UPDATE_EVENT_TYPE_ERROR});
						Modal.warning({ title: langdata && langdata.Unabletoupdatetheeventtype ? langdata.Unabletoupdatetheeventtype : 'Unable to update the event type.' });  
					}
				})
				.catch((error) => {
					dispatch({type: UPDATE_EVENT_TYPE_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
	});

	const onEnable = (value) => {
		setDisableValue(value);
	};

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{ langdata && langdata.EventTypeInfo1 ? langdata.EventTypeInfo1 : 'The event type details like Event name, Event confidence Cap which can be used to reduce the event  \
				confidence of any event types if required and the Event confidence range for each alarm severity are displayed.  \
				The details can be edited using the edit button in the Actions column.'}</p>
			{ permission === 'superadmin' || permission === 'admin' ?
				<>
					< br/>
					<p>{ langdata && langdata.EventTypeInfo2 ? langdata.EventTypeInfo2 : 'New Event Types can be added using the Add New button.'}</p> </> : null }
		</Card>
	);

	return (
		permission==='superadmin' ? 
			<>
				<Content>
					<Row style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
					>

						<Col span={12}>
							<Row>
								<Space>
									<ToolOutlined style={{fontSize:'30px'}}/>
								</Space>
								<Space>&nbsp;&nbsp;</Space>
								<Title level={4} style={{ paddingTop:'10px'}}>{ langdata && langdata.EventTypes ? langdata.EventTypes : 'Event Types'}</Title>
							</Row>
						</Col>
					
						<Col span={12}>
							<Row justify="end">
								<Col>
									<Tooltip placement="bottom" title={ langdata && langdata.Add ? langdata.Add : 'Add'}>
										<Space>
											{ permission === 'superadmin' ? <Button type="primary" onClick={openAddEventTypeModal} style={{margin:'5px'}}><PlusOutlined/>{ langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button> : null }
										</Space>
									</Tooltip>
									<Space>&nbsp;&nbsp;</Space>
									<Space>
										<Typography>
											<Popover title={ langdata && langdata.EventTypes ? langdata.EventTypes :'Event Types'}  content={infoContent} trigger="click">
												<Avatar size="small"><InfoOutlined /></Avatar>
											</Popover>
										</Typography>
									</Space>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24}>
							{ data && data.eventTypes.length > 0 ? (
								<Table
									scroll={{
										x: 900,
									}} 
									columns={columns} 
									dataSource={data && data.eventTypes} 
									onRow = {(record) => {
										return {
											onClick: () => { 
												loadEventTypeDetails(record.id);
											}
										};
									}} />
							)
								:
								(
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
								)}
						</Col>
					</Row>
				</Content>

				<Spin fullscreen spinning={data.pending} />

				{/* Add Event Type Starts */}
				<Modal
					title={ langdata && langdata.AddEventType ? langdata.AddEventType : 'Add Event Type'}
					destroyOnClose={true}
					maskClosable={false}
					centered
					open={showAddEventTypeModal}
					onOk={{closeAddEventTypeModal}}
					onCancel={closeAddEventTypeModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeAddEventTypeModal}>{ langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
							{ permission === 'superadmin' ? <Button type="primary" onClick={addEventTypeFormik.handleSubmit}>{ langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
						</Space>
					]}
					width={700}
				>
					<Form layout="vertical" onSubmit={updateEventTypeFormik.handleSubmit}>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label={ langdata && langdata.Name ? langdata.Name : 'Name'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheeventtypename ? langdata.Pleaseentertheeventtypename : 'Please enter the event type name' }]} required>
									<Input name="eventType" onChange={addEventTypeFormik.handleChange} placeholder={ langdata && langdata.Pleaseentertheeventtypename ? langdata.Pleaseentertheeventtypename :'Please enter the event type name'} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.EventConfidenceCap ? langdata.EventConfidenceCap : 'Event Confidence Cap'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheeventconfidencecap ? langdata.Pleaseentertheeventconfidencecap : 'Please enter the event confidence cap' }]} required>
									{/* <Input type='number' min={0} max={1} name="eventConfidenceCap" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the event confidence cap" value={updateEventTypeFormik.values.eventConfidenceCap} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0.1"
										max="1"
										step="0.1"  
										name="eventConfidenceCap" 
										onChange={(value) => addEventTypeFormik.setFieldValue('eventConfidenceCap', value)}
										placeholder={langdata && langdata.Pleaseentertheeventconfidencecap ? langdata.Pleaseentertheeventconfidencecap : 'Please enter the event confidence cap'}
										// defaultValue={data.details && data.details.eventConfidenceCap} 
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.RedLowerLimit ? langdata.RedLowerLimit : 'Red Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheredlowerlimit ? langdata.Pleaseentertheredlowerlimit : 'Please enter the red lower limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="redLower" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the red lower limit" value={updateEventTypeFormik.values.redLower} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="redLower" 
										onChange={(value) => addEventTypeFormik.setFieldValue('redLower', value)}
										placeholder={langdata && langdata.Pleaseentertheredlowerlimit ? langdata.Pleaseentertheredlowerlimit : 'Please enter the red lower limit'}
										// defaultValue={data.details && data.details.escalationMetrics.Red[0]} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.RedUpperLimit ? langdata.RedUpperLimit : 'Red Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheredupperlimit ? langdata.Pleaseentertheredupperlimit : 'Please enter the red upper limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="redUpper" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the red upper limit" value={updateEventTypeFormik.values.redUpper} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="redUpper" 
										onChange={(value) => addEventTypeFormik.setFieldValue('redUpper', value)}
										placeholder={langdata && langdata.Pleaseentertheredupperlimit ? langdata.Pleaseentertheredupperlimit : 'Please enter the red upper limit'}
										// defaultValue={data.details && data.details.escalationMetrics.Red[1]} 
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.AmberLowerLimit ? langdata.AmberLowerLimit : 'Amber Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheamberlowerlimit ? langdata.Pleaseentertheamberlowerlimit : 'Please enter the amber lower limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="amberLower" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the amber lower limit" value={updateEventTypeFormik.values.amberLower} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="amberLower" 
										onChange={(value) => addEventTypeFormik.setFieldValue('amberLower', value)}
										placeholder={langdata && langdata.Pleaseentertheamberlowerlimit ? langdata.Pleaseentertheamberlowerlimit : 'Please enter the amber lower limit'}
										// defaultValue={data.details && data.details.escalationMetrics.Amber[0]} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.AmberUpperLimit ? langdata.AmberUpperLimit : 'Amber Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheamberupperlimit ? langdata.Pleaseentertheamberupperlimit : 'Please enter the amber upper limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="amberUpper" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the amber upper limit" value={updateEventTypeFormik.values.amberUpper} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="amberUpper" 
										onChange={(value) => addEventTypeFormik.setFieldValue('amberUpper', value)}
										placeholder={langdata && langdata.Pleaseentertheamberupperlimit ? langdata.Pleaseentertheamberupperlimit : 'Please enter the amber upper limit'}
										// defaultValue={data.details && data.details.escalationMetrics.Amber[1]} 
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.GreenLowerLimit ? langdata.GreenLowerLimit : 'Green Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthegreenlowerlimit ? langdata.Pleaseenterthegreenlowerlimit : 'Please enter the green lower limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="greenLower" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the green lower limit" value={updateEventTypeFormik.values.greenLower} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="greenLower" 
										onChange={(value) => addEventTypeFormik.setFieldValue('greenLower', value)}
										placeholder={langdata && langdata.Pleaseenterthegreenlowerlimit ? langdata.Pleaseenterthegreenlowerlimit : 'Please enter the green lower limit'}
										// defaultValue={data.details && data.details.escalationMetrics.Green[0]} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.GreenUpperLimit ? langdata.GreenUpperLimit : 'Green Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthegreenupperlimit ? langdata.Pleaseenterthegreenupperlimit : 'Please enter the green upper limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="greenUpper" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the green upper limit" value={updateEventTypeFormik.values.greenUpper} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="greenUpper" 
										onChange={(value) => addEventTypeFormik.setFieldValue('greenUpper', value)}
										placeholder={langdata && langdata.Pleaseenterthegreenupperlimit ? langdata.Pleaseenterthegreenupperlimit : 'Please enter the green upper limit'}
										// defaultValue={data.details && data.details.escalationMetrics.Green[1]} 
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.ClearLowerLimit ? langdata.ClearLowerLimit : 'Clear Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheclearlowerlimit ? langdata.Pleaseentertheclearlowerlimit : 'Please enter the clear lower limit' }]} required>
									{/* <Input type='number' min={0} max={1} maxLength={1} name="clearLower" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the clear lower limit" value={updateEventTypeFormik.values.clearLower} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="clearLower" 
										onChange={(value) => addEventTypeFormik.setFieldValue('clearLower', value)}
										placeholder={langdata && langdata.Pleaseentertheclearlowerlimit ? langdata.Pleaseentertheclearlowerlimit : 'Please enter the clear lower limit' }
										// defaultValue={data.details && data.details.escalationMetrics.Clear[0]} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.ClearUpperLimit ? langdata.ClearUpperLimit : 'Clear Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheclearupperlimit ? langdata.Pleaseentertheclearupperlimit : 'Please enter the clear upper limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="clearUpper" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the clear upper limit" value={updateEventTypeFormik.values.clearUpper} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="clearUpper" 
										onChange={(value) => addEventTypeFormik.setFieldValue('clearUpper', value)}
										placeholder={langdata && langdata.Pleaseentertheclearupperlimit ? langdata.Pleaseentertheclearupperlimit : 'Please enter the clear upper limit' }
										// defaultValue={data.details && data.details.escalationMetrics.Clear[1]} 
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.UnclassifiedLowerLimit ? langdata.UnclassifiedLowerLimit : 'Unclassified Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheunclassifiedlowerlimit ? langdata.Pleaseentertheunclassifiedlowerlimit : 'Please enter the unclassified lower limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="unclassifiedLower" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the unclassified lower limit" value={updateEventTypeFormik.values.unclassifiedLower} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="unclassifiedLower" 
										onChange={(value) => addEventTypeFormik.setFieldValue('unclassifiedLower', value)}
										placeholder={langdata && langdata.Pleaseentertheunclassifiedlowerlimit ? langdata.Pleaseentertheunclassifiedlowerlimit : 'Please enter the unclassified lower limit' }
										// defaultValue={data.details && data.details.escalationMetrics.Unclassified[0]} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.UnclassifiedUpperLimit ? langdata.UnclassifiedUpperLimit : 'Unclassified Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheunclassifiedupperlimit ? langdata.Pleaseentertheunclassifiedupperlimit : 'Please enter the unclassified upper limit' }]} required>
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="unclassifiedUpper" 
										onChange={(value) => addEventTypeFormik.setFieldValue('unclassifiedUpper', value)}
										placeholder={langdata && langdata.Pleaseentertheunclassifiedupperlimit ? langdata.Pleaseentertheunclassifiedupperlimit : 'Please enter the unclassified upper limit' }
										// defaultValue={data.details && data.details.escalationMetrics.Unclassified[1]} 
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								{/* <label>Enabled</label>&nbsp; */}
								<Switch checkedChildren={langdata && langdata.Enabled ? langdata.Enabled : 'Enabled'} unCheckedChildren = {langdata && langdata.Disabled ? langdata.Disabled :'Disabled'} defaultChecked = {disableValue} onChange={onEnable} />
							</Col>
						</Row>
					</Form>
				</Modal>
				{/* Add Event Type Ends */}

				{/* Edit Event Type Starts */}
				<Modal
					title={langdata && langdata.EditEventType ? langdata.EditEventType : 'Edit Event Type'}
					centered
					open={showEditEventTypeModal}
					onOk={{closeEditEventTypeModal}}
					onCancel={closeEditEventTypeModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeEditEventTypeModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
							{ permission === 'superadmin' ? <Button type="primary" onClick={updateEventTypeFormik.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button> : null }
						</Space>
					]}
					width={800}
				>
					<Form layout="vertical" onSubmit={updateEventTypeFormik.handleSubmit}>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.Name ? langdata.Name : 'Name'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheeventtypename ? langdata.Pleaseentertheeventtypename : 'Please enter the event type name' }]} required>
									<Input name="eventType" onChange={updateEventTypeFormik.handleChange} placeholder={langdata && langdata.Pleaseentertheeventtypename ? langdata.Pleaseentertheeventtypename : 'Please enter the event type name'} value={updateEventTypeFormik.values.eventType} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.EventConfidenceCap ? langdata.EventConfidenceCap : 'Event Confidence Cap'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheeventconfidencecap ? langdata.Pleaseentertheeventconfidencecap : 'Please enter the event confidence cap' }]} required >
									{/* <Input type='number' min={0} max={1} name="eventConfidenceCap" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the event confidence cap" value={updateEventTypeFormik.values.eventConfidenceCap} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0.1"
										max="1"
										step="0.1"  
										name="eventConfidenceCap" 
										onChange={(value) => updateEventTypeFormik.setFieldValue('eventConfidenceCap', value)}
										placeholder={langdata && langdata.Pleaseentertheeventconfidencecap ? langdata.Pleaseentertheeventconfidencecap : 'Please enter the event confidence cap'}
										defaultValue={data.details && data.details.eventConfidenceCap} 
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.RedLowerLimit ? langdata.RedLowerLimit :'Red Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheredlowerlimit ? langdata.Pleaseentertheredlowerlimit : 'Please enter the red lower limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="redLower" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the red lower limit" value={updateEventTypeFormik.values.redLower} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="redLower" 
										onChange={(value) => updateEventTypeFormik.setFieldValue('redLower', value)}
										placeholder={langdata && langdata.Pleaseentertheredlowerlimit ? langdata.Pleaseentertheredlowerlimit : 'Please enter the red lower limit'}
										defaultValue={data.details && data.details.escalationMetrics.Red[0]} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.RedUpperLimit ? langdata.RedUpperLimit : 'Red Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheredupperlimit ? langdata.Pleaseentertheredupperlimit : 'Please enter the red upper limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="redUpper" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the red upper limit" value={updateEventTypeFormik.values.redUpper} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="redUpper" 
										onChange={(value) => updateEventTypeFormik.setFieldValue('redUpper', value)}
										placeholder={langdata && langdata.Pleaseentertheredupperlimit ? langdata.Pleaseentertheredupperlimit : 'Please enter the red upper limit'}
										defaultValue={data.details && data.details.escalationMetrics.Red[1]} 
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.AmberLowerLimit ? langdata.AmberLowerLimit : 'Amber Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheamberlowerlimit ? langdata.Pleaseentertheamberlowerlimit : 'Please enter the amber lower limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="amberLower" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the amber lower limit" value={updateEventTypeFormik.values.amberLower} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="amberLower" 
										onChange={(value) => updateEventTypeFormik.setFieldValue('amberLower', value)}
										placeholder={langdata && langdata.Pleaseentertheamberlowerlimit ? langdata.Pleaseentertheamberlowerlimit : 'Please enter the amber lower limit'}
										defaultValue={data.details && data.details.escalationMetrics.Amber[0]} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.AmberUpperLimit ? langdata.AmberUpperLimit : 'Amber Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheamberupperlimit ? langdata.Pleaseentertheamberupperlimit : 'Please enter the amber upper limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="amberUpper" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the amber upper limit" value={updateEventTypeFormik.values.amberUpper} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="amberUpper" 
										onChange={(value) => updateEventTypeFormik.setFieldValue('amberUpper', value)}
										placeholder={langdata && langdata.Pleaseentertheamberupperlimit ? langdata.Pleaseentertheamberupperlimit :'Please enter the amber upper limit'}
										defaultValue={data.details && data.details.escalationMetrics.Amber[1]} 
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.GreenLowerLimit ? langdata.GreenLowerLimit :'Green Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthegreenlowerlimit ? langdata.Pleaseenterthegreenlowerlimit : 'Please enter the green lower limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="greenLower" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the green lower limit" value={updateEventTypeFormik.values.greenLower} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="greenLower" 
										onChange={(value) => updateEventTypeFormik.setFieldValue('greenLower', value)}
										placeholder={langdata && langdata.Pleaseenterthegreenlowerlimit ? langdata.Pleaseenterthegreenlowerlimit : 'Please enter the green lower limit'}
										defaultValue={data.details && data.details.escalationMetrics.Green[0]} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.GreenUpperLimit ? langdata.GreenUpperLimit : 'Green Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthegreenupperlimit ? langdata.Pleaseenterthegreenupperlimit : 'Please enter the green upper limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="greenUpper" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the green upper limit" value={updateEventTypeFormik.values.greenUpper} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="greenUpper" 
										onChange={(value) => updateEventTypeFormik.setFieldValue('greenUpper', value)}
										placeholder={langdata && langdata.Pleaseenterthegreenupperlimit ? langdata.Pleaseenterthegreenupperlimit : 'Please enter the green upper limit'}
										defaultValue={data.details && data.details.escalationMetrics.Green[1]} 
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.ClearLowerLimit ? langdata.ClearLowerLimit : 'Clear Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheclearlowerlimit ? langdata.Pleaseentertheclearlowerlimit : 'Please enter the clear lower limit' }]} required>
									{/* <Input type='number' min={0} max={1} maxLength={1} name="clearLower" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the clear lower limit" value={updateEventTypeFormik.values.clearLower} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="clearLower" 
										onChange={(value) => updateEventTypeFormik.setFieldValue('clearLower', value)}
										placeholder={langdata && langdata.Pleaseentertheclearlowerlimit ? langdata.Pleaseentertheclearlowerlimit : 'Please enter the clear lower limit' }
										defaultValue={data.details && data.details.escalationMetrics.Clear[0]} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.ClearUpperLimit ? langdata.ClearUpperLimit : 'Clear Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheclearupperlimit ? langdata.Pleaseentertheclearupperlimit : 'Please enter the clear upper limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="clearUpper" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the clear upper limit" value={updateEventTypeFormik.values.clearUpper} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="clearUpper" 
										onChange={(value) => updateEventTypeFormik.setFieldValue('clearUpper', value)}
										placeholder={langdata && langdata.Pleaseentertheclearupperlimit ? langdata.Pleaseentertheclearupperlimit : 'Please enter the clear upper limit' }
										defaultValue={data.details && data.details.escalationMetrics.Clear[1]} 
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.UnclassifiedLowerLimit ? langdata.UnclassifiedLowerLimit : 'Unclassified Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheunclassifiedlowerlimit ? langdata.Pleaseentertheunclassifiedlowerlimit : 'Please enter the unclassified lower limit' }]} required>
									{/* <Input type='number' min={0} max={1} name="unclassifiedLower" onChange={updateEventTypeFormik.handleChange} placeholder="Please enter the unclassified lower limit" value={updateEventTypeFormik.values.unclassifiedLower} /> */}
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="unclassifiedLower" 
										onChange={(value) => updateEventTypeFormik.setFieldValue('unclassifiedLower', value)}
										placeholder={langdata && langdata.Pleaseentertheunclassifiedlowerlimit ? langdata.Pleaseentertheunclassifiedlowerlimit : 'Please enter the unclassified lower limit' }
										defaultValue={data.details && data.details.escalationMetrics.Unclassified[0]} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.UnclassifiedUpperLimit ? langdata.UnclassifiedUpperLimit : 'Unclassified Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheunclassifiedupperlimit ? langdata.Pleaseentertheunclassifiedupperlimit : 'Please enter the unclassified upper limit' }]} required>
									<InputNumber 
										style={{
											width: '100%',
										}}
										min="0"
										max="1"
										step="0.1"  
										name="unclassifiedUpper" 
										onChange={(value) => updateEventTypeFormik.setFieldValue('unclassifiedUpper', value)}
										placeholder={langdata && langdata.Pleaseentertheunclassifiedupperlimit ? langdata.Pleaseentertheunclassifiedupperlimit : 'Please enter the unclassified upper limit' }
										defaultValue={data.details && data.details.escalationMetrics.Unclassified[1]} 
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								{/* <label>Enabled</label>&nbsp; */}
								<Switch checkedChildren= {langdata && langdata.Enabled ? langdata.Enabled :'Enabled'} unCheckedChildren ={langdata && langdata.Disabled ? langdata.Disabled :'Disabled'} defaultChecked = {data.details && data.details.eventEscalationEnabled} onChange={(value) => updateEventTypeFormik.setFieldValue('eventEscalationEnabled', value)} />
							</Col>
						</Row>
					</Form>
				</Modal>
				{/* Edit Event Type Ends */}

				{/* Delete Event Type Starts */}
				<Modal title= {langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={showDeleteEventTypeModal} onOk={deleteEventType} onCancel={closeDeleteEventTypeModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeDeleteEventTypeModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
							<Button type="primary" onClick={deleteEventType}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
						</Space>
					]}>
					<Row style={{
						marginTop: '10px',
						marginBottom: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={1}>
							<DeleteOutlined  style={{ fontSize: '14px'}}/>
						</Col>
						<Col span={23}>{langdata && langdata.Areyousureyouwanttodeletethiseventtype ? langdata.Areyousureyouwanttodeletethiseventtype : 'Are you sure you want to delete this event type?'}</Col>
					</Row>
				</Modal>
				{/* Delete Event Type Ends */}
			</> : null
	);
}