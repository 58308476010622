/* eslint-disable react/react-in-jsx-scope */
import { Breadcrumb, Card,  Col, Divider, message, Modal, Row, Spin, Tabs, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useLocation } from 'react-router-dom';

import { LayersControl, MapContainer, Marker, Pane, Polyline, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'leaflet';
import TabPane from 'antd/es/tabs/TabPane';
import { useAuth } from '../../Context';
// import regionManagementReducer from './reducer';
// import axios from 'axios';
import { FullscreenOutlined,FullscreenExitOutlined, HomeOutlined } from '@ant-design/icons';
import GoogleMap from '../../Map/map';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

import Papa from 'papaparse';
import Plot from 'react-plotly.js';
import axios from 'axios';

// const linecolor = { color: 'green' };
const pipecolor = { color: 'black' };
const odmetercolor = { color: '#036CD6' };
const optionalcolor ={color:'orange'};
const bordercolor = { color: '#fc8383'};
// const pidsicon = new Icon({
// 	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
// 	iconUrl:require('../../../images/refinery.png'),
// 	iconSize:[45,50],
// 	iconAnchor:  [25, 50]});


const pidsicon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../../images/Refinery1.png'),
	iconSize: [30, 30],
	iconAnchor:[18, 30]});
const CHicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/ch.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const WBicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/wb.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const KMPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/kmp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const TLPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/tlp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const TPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/tp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
	
const wellicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/oil-well.png'),
	iconSize: [40, 40],
	iconAnchor:[18, 36]
});

export default function RegionDetails() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const { state } = useLocation();
	const navigate = useNavigate();
	const {handleGetMapdata, mapdata, optionalpipedata,handleOptionalPipelinedata, pidsRegionData, getPidsRegion, wellinfo, getWellInfo} = useAuth();
	const [size, setSize] = useState('200px');

	const [wellId, setWellId] = useState({id:null,name:''});
	const [wellModal, setWellModal] = useState(false);
	const [wellCoordinatesurl, setWellCoordinatesurl] = useState(null);	
	const [meshData, setMeshData] = useState({ x: [], y: [], z: [], color: [] });
	const [meshSpin, setMeshSpin] = useState(false);	
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;
	// const initalState = {channel_data:[],details: null, pending: false, error: false };
	// const [data, dispatch] = useReducer(pidsdata, initalState);
	const pipeline = [];
	const center=[];
	// const odmeter=[];
	// const optionalpath = [];
	const markers = [];
	// const points = [];
	// const pidsmarker=[{geocode:[state.data.latitude, state.data.longitude],pop:state.data.regionName.toUpperCase()}];
	// const line = [];
	const KMP = [];
	const WB = [];
	const Turn = [];
	const TLP = [];
	const map_type= localStorage.getItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const oneviewType = process.env.REACT_APP_ONEVIEW_TYPE ? process.env.REACT_APP_ONEVIEW_TYPE : 'pipeline';
	const oneviewtypeLabel = oneviewType &&  oneviewType.toLowerCase() == 'pipeline' ? langdata && langdata.Pipeline : oneviewType.toLowerCase() == 'airport' ? langdata && langdata.Airport : oneviewType.toLowerCase() == 'border' ? langdata && langdata.Border : 'pipeline';

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	useAuth();

	mapdata &&  mapdata.map((item,index)=>{
		if(index==0){
			center.push(item.latitude,item.longitude);
		}
		pipeline.push([item.latitude,item.longitude]);
	});
	// mapdata &&  mapdata.map((item)=>{

	// 	odmeter.push([item.fibreLatitude,item.fibreLongitude]);
	// });
  
	// optionalpipedata &&  optionalpipedata.map((item)=>{

	// 	optionalpath.push([item.walkingLatitude,item.walkingLongitude]);
	// });

	const tempMapArray = {};
	const teampOptionalMapArray = {};

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});

	optionalpipedata && optionalpipedata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!teampOptionalMapArray[fieldValue]) {
			teampOptionalMapArray[fieldValue] = [];
		}
		teampOptionalMapArray[fieldValue].push(item);
	});

	mapdata &&  mapdata.map((item)=>{

		if(item.rouMarker=='CH'){
			markers.push({
				geocode:[item.latitude,item.longitude],
				pop:'CH'
			},);
		}
		else if(item.rouMarker=='WB'){
			WB.push({
				geocode:[item.latitude,item.longitude],
				pop:'WB'
			},);
		}

		else if(item.rouMarker=='TLP'){
			TLP.push({
				geocode:[item.latitude,item.longitude],
				pop:'TLP'
			},);
		}

		else if(item.rouMarker=='KMP'){
			KMP.push({
				geocode:[item.latitude,item.longitude],
				pop:'KMP'
			},);
		}

		else if(item.rouMarker=='TP'){
			Turn.push({
				geocode:[item.latitude,item.longitude],
				pop:'TP'
			},);
		}


	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		if(map_type && map_type === 'leaflet') {
			handleGetMapdata(authtoken,state.data.id,state.data && state.data.startChainage ? state.data.startChainage : 0 ,state.data && state.data.endChainage ? state.data.endChainage : 0);
			handleOptionalPipelinedata(authtoken,pipelineId,state.data.id,state.data && state.data.startChainage ? state.data.startChainage : 0 ,state.data && state.data.endChainage ? state.data.endChainage : 0);
		}
		getPidsRegion(authtoken,state.data.id);   
		if(oneviewType.toLowerCase() == 'well'){
			getWellInfo(authtoken);
		}
	},[authtoken]);

	let pidsList = [];
	pidsRegionData && pidsRegionData.map((item) => {
		pidsList.push({
			value : item.id,
			pop : item.name,
			geocode:[item.latitude,item.longitude]
		});
	});

	const wells = [];

	wellinfo && wellinfo.map((item) => {
		if(item.latitude && item.longitude){			
			wells.push({
				id:item.id,
				geocode:[item.latitude,item.longitude],
				pop: item.name.toUpperCase()
			},);	
		}
	});

	const handleModelCancel = () => {
		setWellModal(false);

	};

	const mapKey = (value, type,name) => {
		if(type=='well'){
			setWellModal(true);
			setWellId({id:value, name:name});
		}

	};
	
	
	useEffect(() => {
		if(wellId.id){

			getWellCoordinates();
		}

		// setWellId(tempWellId);
		// setSocketUrl(tempurl);

	},[wellId]);

	const getWellCoordinates =()=>{		

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellcooridnatesdata?wellId=${wellId.id}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					
					setWellCoordinatesurl(response.data.url);
				}else{
					
					setWellCoordinatesurl(null);
				}
			})
			.catch((error) => {
				setWellCoordinatesurl(null);
				
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});
	};

	useEffect(() => {
		setMeshSpin(true);
		
		if(wellCoordinatesurl){

		
			// Load cylinder mesh coordinates with Jet colors
			Papa.parse(wellCoordinatesurl, {
				download: true,
				header: true,
				complete: (results) => {
					const data = results.data;
					setMeshData({
						x: data.map(row => parseFloat(row.x)),
						y: data.map(row => parseFloat(row.y)),
						z: data.map(row => parseFloat(row.z)),
						color: data.map(row => `rgb(${Math.round(parseFloat(row.r) * 255)}, ${Math.round(parseFloat(row.g) * 255)}, ${Math.round(parseFloat(row.b) * 255)})`)
					});
					setMeshSpin(false);
				}
			});
			
		}else{
			setMeshSpin(false);
			setMeshData({});
		}
	}, [wellCoordinatesurl,wellId]);

	const numPointsPerCircle = 100;
	const numCircles = 100;
	const i = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => Math.floor(index / numPointsPerCircle) * numPointsPerCircle + index % numPointsPerCircle);
	const j = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => Math.floor(index / numPointsPerCircle) * numPointsPerCircle + (index % numPointsPerCircle + 1) % numPointsPerCircle);
	const k = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => i[index] + numPointsPerCircle);
	// const l = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => j[index] + numPointsPerCircle);

	const wellData = [
		{
			type: 'mesh3d',
			x: meshData.x,
			y: meshData.y,
			z: meshData.z,
			i: i,
			j: j,
			k: k,
			facecolor: meshData.color,  // Apply color
			colorscale: 'Jet',  // Ensure this is the correct scale for your color data
			showscale: true,
			hoverinfo: 'none',  // Customize the hover info displayed
			text:meshData &&  meshData.z && meshData.z.map(Math.floor),
			// hoverlabel: {
			// 	bgcolor: meshData.color,  // Background color of the tooltip
			// 	font: {
			// 		color: 'white'  // Text color in the tooltip
			// 	}
			// },
			hovertemplate : 'Depth : %{text}<extra></extra>',
			// reversescale:true
		},
		
		// {
		//   type: 'scatter3d',
		//   mode: 'markers',
		//   x: leakData.x,
		//   y: leakData.y,
		//   z: leakData.z,
		//   marker: { size: 5, color: 'red' },
		//   name: 'Leak Detection'
		// }
	];

	const wellLayout = {
		scene: {
			xaxis: { title: 'X' },
			yaxis: { title: 'Y' },
			zaxis: { title: 'Depth (m)', 
				autorange: 'reversed',
				tickmode: 'auto', tickvals:  'auto',
				// dtick : depthRangeFrom && depthRangeTo ? (depthRangeFrom - depthRangeTo)/10 : null,
				// nticks : 10,
				// ticktext: ['3000 m', '2000 m', '1000 m', '0 m'],
				// range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ 3000 , 0 ],
			},
			camera:{
				
				center: {
					'x': 0,
					'y': 0,
					'z': 0
				},
				eye: {
					'x': 1.4,
					'y': 1,
					'z': 0
				},
				up: {
					'x': 0,
					'y': 0,
					'z': 1
				}

			}
		},
		title: {
			text: wellId && wellId.name,
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	
	};

	const wellKey = (value) => {		
		setWellId({id:value.id, name:value.pop});
		setWellModal(true);
	};

	return (
		<>
			{/* <Row style={{justifyContent:'right', display:'flex'}}>

				<Button type='primary' onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} style={{marginRight:'20px'}} >View Map</Button>
			</Row> */}
			{size && size === '200px' ? 
				<Content>

					{/* map filter starts  */}
					{/* {map_type && map_type !== 'leaflet' ?

						<Row span={24} style={{ color:'#000000a6', margin : '10px'}}>
							<Col span={24}>
								<Checkbox style={{ color:'#000000a6'}}>FIBER</Checkbox>
								<Checkbox style={{ color:'#000000a6'}} defaultChecked>PIPELINE</Checkbox>
								<Checkbox style={{ color:'#000000a6'}}>PIPELINE BORDER</Checkbox>
								<Checkbox style={{ color:'#000000a6'}}>KMP</Checkbox>
								<Checkbox style={{ color:'#000000a6'}}>CH</Checkbox>
								<Checkbox style={{ color:'#000000a6'}}>WB</Checkbox>
								<Checkbox style={{ color:'#000000a6'}}>Turning Point</Checkbox>
								<Checkbox style={{ color:'#000000a6'}}>TLP</Checkbox>
							</Col>
						</Row>

						: null } */}

					{/* map filter ends */}

					<Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}} style={{margin:'10px'}} >

						<Col className="gutter-row" span={24}>
      

							<Card>
								<Breadcrumb
									items={[
										{
											href: '/dashboard',
											title: <HomeOutlined />,
										},
										{
											title: ( state.path === 'region-management' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.RegionManagement ? langdata.RegionManagement : 'Region Management'}</a> : <a onClick={()=>{navigate(-1);}}>{langdata && langdata.Region ? langdata.Region : 'Region'}</a> )
										},
										{
											title: langdata && langdata.RegionDetails ? langdata.RegionDetails : 'Region Details',
										},
									]}
									style={{ marginBottom : '10px'}}
								/>


								{/* map starts */}
								{map_type && map_type !== 'leaflet' ?

									< GoogleMap regionId = {state.data && state.data.id} regionPids = {pidsRegionData} height = {'30vh'}  onClick={mapKey}/>

									: 	 mapdata && mapdata ? 
										<div className="App" style={{textAlign: 'left'}}>
        
											<MapContainer center={center} zoom={14} scrollWheelZoom={false} style={{height:size}}>
												<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenOutlined /></button>
												<TileLayer
													attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
													url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
												/>
												{/* {view && view==='view' ? 
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        :
    
        <TileLayer
              url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
              maxZoom= {20}
              subdomains={['mt1','mt2','mt3']}
              
              />
              } */}
    
												{/* <TileLayer
          attribution='Stamen Watercolor'
          url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
        /> */}
    
												<LayersControl position="topright" >

													{oneviewType.toLowerCase() == 'well' ? 
														<>
															<LayersControl.Overlay  checked name={langdata && langdata.WELL ? langdata.WELL :'WELL'}>
																<MarkerClusterGroup>
	
																	{wells && wells.map((marker,index)=>
																		<Marker key={`well-${index}`} position={marker && marker.geocode} icon={wellicon}
																			eventHandlers={{
																				click: () => {
																					wellKey(marker);
																				},
																			}}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>
	
																	)}       
																</MarkerClusterGroup>
	
															</LayersControl.Overlay>
															<LayersControl.Overlay  checked name={langdata && langdata.PIDS ? langdata.PIDS : 'PIDS'}>
																<MarkerClusterGroup>
																	{pidsList && pidsList.map((marker,index)=>
																		<Marker key={`pidsList-${index}`} position={marker.geocode} icon={pidsicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>
    
																	)}       
																</MarkerClusterGroup>
															</LayersControl.Overlay>
														</>
														:
														<>
															<LayersControl.Overlay  checked name={langdata && langdata.PIDS ? langdata.PIDS : 'PIDS'}>
																<MarkerClusterGroup>
																	{pidsList && pidsList.map((marker,index)=>
																		<Marker key={`pidsList-${index}`} position={marker.geocode} icon={pidsicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>
    
																	)}       
																</MarkerClusterGroup>
															</LayersControl.Overlay>
    
    
															<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP : 'KMP'}>
																<MarkerClusterGroup>
    
																	{KMP && KMP.map((marker,index)=>
																		<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>
    
																	)}       
																</MarkerClusterGroup>
    
															</LayersControl.Overlay>
    
    
															<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB : 'WB'}>
																<MarkerClusterGroup>
    
																	{WB && WB.map((marker,index)=>
																		<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>
    
																	)}       
																</MarkerClusterGroup>
    
															</LayersControl.Overlay>
    
    
    
    
															<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP : 'TP'}>
																<MarkerClusterGroup>
    
																	{Turn && Turn.map((marker,index)=>
																		<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>
    
																	)}       
																</MarkerClusterGroup>
    
															</LayersControl.Overlay>
    
    
															<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP : 'TLP'}>
																<MarkerClusterGroup>
    
																	{TLP && TLP.map((marker,index)=>
																		<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>
    
																	)}       
																</MarkerClusterGroup>
    
															</LayersControl.Overlay>
    
    
															{/* <LayersControl.Overlay  name="POINTS">
										<MarkerClusterGroup>
    
											{points && points.slice(-100).map((marker,index)=>
												<Marker key={`points-${index}`} position={marker.geocode} icon={markericon}>
													<Popup>
														{marker.pop}
													</Popup>
													<Tooltip>{marker.pop}</Tooltip>
												</Marker>
    
											)}       
										</MarkerClusterGroup>
    
									</LayersControl.Overlay> */}
    
    
    
															<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH : 'CH'}>
																<MarkerClusterGroup>
																	{markers && markers.map((marker,index)=>
																		<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>
    
																	)}
    
																</MarkerClusterGroup>
															</LayersControl.Overlay>
    
    
															{/* <LayersControl.Overlay  checked name="LINE">
										<Polyline pathOptions={linecolor} weight={7} positions={line} />
									</LayersControl.Overlay> */}
    
    
															<LayersControl.Overlay  checked name={oneviewtypeLabel ? oneviewtypeLabel : 'Pipeline'}>
																<MarkerClusterGroup>
																	<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
																		{tempMapArray && Object.values(tempMapArray).map((item) => {
																			let pipeline = [];
																			item.map((data) => {
																				pipeline.push({lat : data.latitude,lng : data.longitude});
																			});
																			return (
																				<>
																					<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
																				</>
																			);

																		})}
																	</Pane>
																</MarkerClusterGroup>
															</LayersControl.Overlay>

															<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder : 'Pipeline Border'}>
																<MarkerClusterGroup>
																	{tempMapArray && Object.values(tempMapArray).map((item) => {
																		let pipelineBorder = [];
																		item.map((data) => {
																			pipelineBorder.push({lat : data.latitude,lng : data.longitude});
																		});
																		return (
																			<>
																				<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
																			</>
																		);

																	})}
																</MarkerClusterGroup>

															</LayersControl.Overlay>
    
															<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber : 'Fiber'}>
																<MarkerClusterGroup>
																	{tempMapArray && Object.values(tempMapArray).map((item) => {
																		let odmeter = [];
																		item.map((data) => {
																			odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
																		});
																		return (
																			<>
																				<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
																			</>
																		);

																	})}
																</MarkerClusterGroup>
															</LayersControl.Overlay>
															<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath : 'Optional Path'}>
																<MarkerClusterGroup>
																	{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
																		let optionalpath = [];
																		item.map((data) => {
																			optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
																		});
																		return (
																			<>
																				<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
																			</>
																		);
																	})}
																</MarkerClusterGroup>
															</LayersControl.Overlay>
														</>
    
													}
												</LayersControl>
											</MapContainer>
    
										</div>
										:null}


								{/* map view ends */}

								{/* Tab view starts */}

								<Tabs defaultActiveKey="1">
									<TabPane tab={langdata && langdata.RegionDetails ? langdata.RegionDetails : 'Region Details'} key="1">
      
										<Row gutter={{
											xs: 8,
											sm: 16,
											md: 24,
											lg: 32,
										}}
										style={{
											// margin: '10px',
											marginTop:'0px', paddingTop:'0px',
											// padding:'10px',
											background: '#FFFFFF',
											alignItems:'center',
										}}
										>
											<Col style={{marginTop:'0px', paddingTop:'0px'}} className="gutter-row" span={24}>    
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.Name ? langdata.Name : 'Name'}</span>: {state.data && state.data && state.data.regionName}</Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.StartChainage ? langdata.StartChainage : 'Start Chainage'}</span>: {state.data && state.data.startChainage || state.data.startChainage === 0 ? state.data.startChainage :'-'}</Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.EndChainage ? langdata.EndChainage : 'End Chainage'}</span>: {state.data && state.data.endChainage || state.data.endChainage === 0 ? state.data.endChainage :'-'}</Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.StartChainageLatitude ? langdata.StartChainageLatitude : 'Start Chainage Latitude'}</span>: {state.data && state.data.startLatitude || state.data.startLatitude === 0 ? state.data.startLatitude :'-'}</Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.StartChainageLongitude ? langdata.StartChainageLongitude : 'Start Chainage Longitude'}</span>: {state.data && state.data.startLongitude || state.data.startLongitude === 0 ? state.data.startLongitude :'-'}</Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.EndChainageLatitude ? langdata.EndChainageLatitude : 'End Chainage Latitude'}</span>: {state.data && state.data.endLatitude || state.data.endLatitude === 0 ? state.data.endLatitude :'-'}</Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.EndChainageLongitude ? langdata.EndChainageLongitude : 'End Chainage Longitude'}</span>: {state.data && state.data.endLongitude || state.data.endLongitude === 0 ? state.data.endLongitude :'-'}</Typography>
											</Col>

										</Row>    
									</TabPane>
								</Tabs>								

							</Card>
						</Col>

					</Row>

					


				</Content>
          
				:
				mapdata && mapdata ?  
					<div className="App" style={{textAlign: 'left', margin : '10px'}}>
						<MapContainer center={center} zoom={14} scrollWheelZoom={false}>
							<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenExitOutlined /></button>
							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
							{/* {view && view==='view' ? 
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    :

    <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
          
          />
          } */}

							{/* <TileLayer
      attribution='Stamen Watercolor'
      url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
    /> */}

							<LayersControl position="topright" >
								{oneviewType.toLowerCase() == 'well' ? 
									<>
										<LayersControl.Overlay  checked name={langdata && langdata.WELL ? langdata.WELL :'WELL'}>
											<MarkerClusterGroup>

												{wells && wells.map((marker,index)=>
													<Marker key={`well-${index}`} position={marker && marker.geocode} icon={wellicon}
														eventHandlers={{
															click: () => {
																wellKey(marker);
															},
														}}>
														<Popup>
															{marker.pop}
														</Popup>
														{/* <Tooltip>{marker.pop}</Tooltip> */}
													</Marker>

												)}       
											</MarkerClusterGroup>

										</LayersControl.Overlay>

										<LayersControl.Overlay  checked name={langdata && langdata.PIDS ? langdata.PIDS : 'PIDS'}>
											<MarkerClusterGroup>
												{pidsList && pidsList.map((marker,index)=>
													<Marker key={`pidsList-${index}`} position={marker.geocode} icon={pidsicon}>
														<Popup>
															{marker.pop}
														</Popup>
														{/* <Tooltip>{marker.pop}</Tooltip> */}
													</Marker>

												)}       
											</MarkerClusterGroup>
										</LayersControl.Overlay>

									</>
									:
									<>
										<LayersControl.Overlay  checked name={langdata && langdata.PIDS ? langdata.PIDS : 'PIDS'}>
											<MarkerClusterGroup>
												{pidsList && pidsList.map((marker,index)=>
													<Marker key={`pidsList-${index}`} position={marker.geocode} icon={pidsicon}>
														<Popup>
															{marker.pop}
														</Popup>
														{/* <Tooltip>{marker.pop}</Tooltip> */}
													</Marker>

												)}       
											</MarkerClusterGroup>
										</LayersControl.Overlay>


										<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP : 'KMP'}>
											<MarkerClusterGroup>

												{KMP && KMP.map((marker,index)=>
													<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
														<Popup>
															{marker.pop}
														</Popup>
														{/* <Tooltip>{marker.pop}</Tooltip> */}
													</Marker>

												)}       
											</MarkerClusterGroup>

										</LayersControl.Overlay>

    
										<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB : 'WB'}>
											<MarkerClusterGroup>

												{WB && WB.map((marker,index)=>
													<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
														<Popup>
															{marker.pop}
														</Popup>
														{/* <Tooltip>{marker.pop}</Tooltip> */}
													</Marker>

												)}       
											</MarkerClusterGroup>

										</LayersControl.Overlay>




										<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP : 'TP'}>
											<MarkerClusterGroup>

												{Turn && Turn.map((marker,index)=>
													<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
														<Popup>
															{marker.pop}
														</Popup>
														{/* <Tooltip>{marker.pop}</Tooltip> */}
													</Marker>

												)}       
											</MarkerClusterGroup>

										</LayersControl.Overlay>

  
										<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP : 'TLP'}>
											<MarkerClusterGroup>

												{TLP && TLP.map((marker,index)=>
													<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
														<Popup>
															{marker.pop}
														</Popup>
														{/* <Tooltip>{marker.pop}</Tooltip> */}
													</Marker>

												)}       
											</MarkerClusterGroup>

										</LayersControl.Overlay>


										{/* <LayersControl.Overlay  name="POINTS">
								<MarkerClusterGroup>

									{points && points.map((marker,index)=>
										<Marker key={`points-${index}`} position={marker.geocode} icon={markericon}>
											<Popup>
												{marker.pop}
											</Popup>
											<Tooltip>{marker.pop}</Tooltip>
										</Marker>

									)}       
								</MarkerClusterGroup>

							</LayersControl.Overlay> */}



										<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH : 'CH'}>
											<MarkerClusterGroup>
												{markers && markers.map((marker,index)=>
													<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
														<Popup>
															{marker.pop}
														</Popup>
														{/* <Tooltip>{marker.pop}</Tooltip> */}
													</Marker>

												)}

											</MarkerClusterGroup>
										</LayersControl.Overlay>

       
										{/* <LayersControl.Overlay  checked name="LINE">
								<Polyline pathOptions={linecolor} weight={7} positions={line} />
							</LayersControl.Overlay> */}

      
										<LayersControl.Overlay  checked name={oneviewtypeLabel ? oneviewtypeLabel : 'Pipeline'}>
											<MarkerClusterGroup>
												<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
													{tempMapArray && Object.values(tempMapArray).map((item) => {
														let pipeline = [];
														item.map((data) => {
															pipeline.push({lat : data.latitude,lng : data.longitude});
														});
														return (
															<>
																<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
															</>
														);
													})}
												</Pane>
											</MarkerClusterGroup>
										</LayersControl.Overlay>

										<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder : 'Pipeline Border'}>
											<MarkerClusterGroup>
												{tempMapArray && Object.values(tempMapArray).map((item) => {
													let pipelineBorder = [];
													item.map((data) => {
														pipelineBorder.push({lat : data.latitude,lng : data.longitude});
													});
													return (
														<>
															<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
														</>
													);
												})}

											</MarkerClusterGroup>
										</LayersControl.Overlay>

										<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber : 'Fiber'}>
											<MarkerClusterGroup>
												{tempMapArray && Object.values(tempMapArray).map((item) => {
													let odmeter = [];
													item.map((data) => {
														odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
													});
													return (
														<>
															<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
														</>
													);
												})}
											</MarkerClusterGroup>
										</LayersControl.Overlay>

										<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath : 'Optional Path'}>
											<MarkerClusterGroup>
												{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
													let optionalpath = [];
													item.map((data) => {
														optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
													});
													return (
														<>
															<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
														</>
													);
												})}
											</MarkerClusterGroup>
										</LayersControl.Overlay>
									</>
								}

							</LayersControl>
						</MapContainer>

					</div> : null
        
        
			}
			{/* well modal start */}

			<Modal
				open={wellModal}
				title={langdata && langdata.Well3d ? langdata.Well3d :'3d Well'}
				onOk={handleModelCancel}
				onCancel={handleModelCancel}
				footer={[
					// eslint-disable-next-line react/jsx-key
					// <Button type="primary" onClick={handleModelCancel}>
					// 	{langdata && langdata.Close ? langdata.Close :'Close'}
					// </Button>
				]}
				style={{backgroundColor:'transparent'}}
				width={800}
			>
				<Divider/>

				<Row
					gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}>
					<Col span={24} style={{ minHeight :'450px', display : 'flex', justifyContent : 'center' }}>          
									
						{/* <Card style={{width:'100%', height : '100%'}}> */}
			
						{/* {data3d && data3d && data3d.length > 0 ?
										
			<> */}
						<Spin  spinning={meshSpin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
						
							{/* <button style={{marginTop:'10px', marginRight:'5px'}}
															onClick={()=>{handlefullSize(fullsize === false ? true:false,'3dwell');}} 
															className="graphZoomButton" ><FullscreenOutlined /></button> */}
							{(Object.keys(meshData).length !== 0) ?
								<Plot
									style={{ width: '750px', height: '600px',minHeight:'450px' }}
									config={{
										responsive: true,
										// displayModeBar: true,
										displaylogo : false,
										modeBarButtonsToRemove: [
											'pan2d',
											'zoom2d',
											'select2d',
											'zoomIn2d',
											'zoomOut2d',
											'autoScale2d',
											'resetScale2d',
											'hoverClosestCartesian',
											'hoverCompareCartesian',
											'zoom3d',
											'pan3d',
											'resetViews3d',
											'hoverClosest3d',
											'hoverCompare3d',
											'orbitRotation',
											'tableRotation',
											'resetCameraDefault3d',
											'resetCameraLastSave3d',
											'lasso2d'
										],
			
									}}
									data={wellData}
									layout={wellLayout}
								/>
								: 
														
								<Typography>{langdata && langdata.NoData ? langdata.NoData : 'No Data'}</Typography>
							}
						</Spin>
						{/* </>
			:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '500px', minHeight:'500px', backgroundColor:'#FFFFFF' }} />} */}
			
			
						{/* </Card> */}
			
					</Col>
							
				</Row>
			</Modal>

			{/* well modal end */}	
			{/* <Spin spinning={data.pending} fullscreen /> */}
		</>
	);
}